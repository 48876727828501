/**
 * @flow
 */
import React from 'react';
import Barcode from 'react-barcode';

class AEHLabel extends React.Component {
  render() {
    const {printData: {printFormData, blData} = {}} = this.props;
    if (!printFormData) {
      return null;
    }
    const {jvessel: {carrier}, jroute: {departure: origin, destination}} = blData;
    let {
      piecesMaster, piecesHouse, mawbNo, hawbNo, labels, forwarderName,
      showCarrier, showNumber, showHawbNo
    } = printFormData;
    showCarrier = showCarrier === undefined ? true : showCarrier?.[0] === 'on';
    showNumber = showNumber === undefined ? true : showNumber?.[0] === 'on';
    showHawbNo = showHawbNo === undefined ? true : showHawbNo?.[0] === 'on';
    const items = [];
    for (let i = 0; i < piecesMaster; ++i) items.push(i + 1);
    // console.log('hello', items, {carrier, piecesMaster, piecesHouse, mawbNo, hawbNo, destination, origin, labels, forwarderName, showCarrier, showNumber, showHawbNo});
    return (
      <div style={{color: 'black', fontSize: 16}}>
        {items.map((number) => {
          const data = {
            number, carrier, piecesMaster, piecesHouse,
            mawbNo, hawbNo, destination, origin, labels,
            forwarderName, showCarrier, showNumber, showHawbNo
          };
          return this.renderItem(data);
        })}
      </div>
    );
  }
  renderItem(data) {
    const {
      number, carrier, piecesMaster, piecesHouse,
      mawbNo, hawbNo, destination, origin, labels,
      forwarderName, showCarrier, showNumber, showHawbNo
    } = data;
    function pad(num, size) {
      num = num.toString();
      while (num.length < size) num = '0' + num;
      return num;
    }
    const labelSize = 40;
    const valueSize = 50;
    return (
      <div key={number} className={'page-break mb-4'}>
        {showCarrier && (
          <div className={'flex center middle w-full fb-50 mb-2'} style={{height: '2.5em', color: 'white', background: 'black', fontFamily: 'monospace', fontSize: 70, letterSpacing: 10}}>
            {carrier}
          </div>
        )}
        <div className={'flex-column center middle w-full mb-2'}>
          <Barcode value={`${mawbNo.replaceAll('-', '')}0${pad(number, 4)}`} displayValue={false} height={150} width={4} />
          <div style={{fontSize: 40}}>{`${mawbNo} 0 ${pad(number, 4)}`}</div>
        </div>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td colSpan={2}>
              <div style={{fontSize: labelSize}}>Air Waybill No.</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{mawbNo}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div style={{fontSize: labelSize}}>Destination</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{destination}</div>
            </td>
            <td width={'50%'}>
              <div style={{fontSize: labelSize}}>Total No of Pieces</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{showNumber ? piecesMaster : ''}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table mb-2'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td width={'70%'}>
              <div style={{fontSize: labelSize}}>Name of Forwarder</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{forwarderName}</div>
            </td>
            <td width={'30%'}>
              <div style={{fontSize: labelSize}}>Origin</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{origin}</div>
            </td>
          </tr>
          <tr>
            <td width={'70%'}>
              <div style={{fontSize: labelSize}}>HAWB No.</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{showHawbNo ? hawbNo : ''}</div>
            </td>
            <td width={'30%'}>
              <div style={{fontSize: labelSize}}>HAWB Pc.</div>
              <div className={'px-16 py-8'} style={{fontSize: valueSize, fontWeight: 'bold'}}>{showNumber ? piecesHouse : ''}</div>
            </td>
          </tr>
          </tbody>
        </table>
        {showHawbNo && (
          <div className={'flex center middle w-full mx-2'}>
            <div style={{fontSize: valueSize, fontWeight: 'bold', marginRight: 50}}>{destination}</div>
            <Barcode value={`H${hawbNo}+`} displayValue={false} height={150} width={4} />
          </div>
        )}
      </div>
    );
  }
}

export default AEHLabel;
