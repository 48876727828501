/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';

const FN = {
  ID: 'id',
  JOB_ID: 'jobId',
  NAME: 'sname',
  DIVISION: 'division',
  PHONE: 'phone',
  CELL: 'cell',
  FAX: 'fax',
  EMAIL: 'email',
  REMARK: 'remark',
  REP: 'isrep',
};

const SN = {
  ID: 'id',
  JOB_ID: 'job_id',
  NAME: 'sname',
  DIVISION: 'division',
  PHONE: 'phone',
  CELL: 'cell',
  FAX: 'fax',
  EMAIL: 'email',
  REMARK: 'remark',
  REP: 'isrep',
};

class ContactInfoFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  constructor() {
    super({
      prefix: 'contact-info-form',
      fields: [
        {name: FN.ID, serverName: SN.ID, label: ''},
        {name: FN.JOB_ID, serverName: SN.JOB_ID, label: ''},
        {name: FN.NAME, serverName: SN.NAME, label: ''},
        {name: FN.DIVISION, serverName: SN.DIVISION, label: ''},
        {name: FN.PHONE, serverName: SN.PHONE, label: ''},
        {name: FN.CELL, serverName: SN.CELL, label: ''},
        {name: FN.FAX, serverName: SN.FAX, label: ''},
        {name: FN.EMAIL, serverName: SN.EMAIL, label: ''},
        {name: FN.REMARK, serverName: SN.REMARK, label: ''},
        {name: FN.REP, serverName: SN.REP, label: '', type: 'checkbox'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    return (
      <>
        <div className={'flex w-full ml-8'}>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Name</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Division</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Phone</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Cell</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Fax</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Email</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Remark</span></div>
          <div style={{flex: 0.7}}><span className={'f-11 pl-8'}>{' '}</span></div>
        </div>
        <div className={'flex w-full ml-8'}>
          <div className={'flex-1 mr-8'}>{_r(FN.NAME)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.DIVISION)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.PHONE)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.CELL)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.FAX)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.EMAIL)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.REMARK)}</div>
          <div className={'flex'} style={{flex: 0.7}}>
            <div style={{width: 30, marginLeft: 8, marginTop: 4}}>{_r(FN.REP)}</div>
            <span>Rep.</span>
          </div>
        </div>
      </>
    );
  };
  onValidate = (values) => {
    // TODO
    return values;
  };
}

export default ContactInfoFormManager;
