/**
 * @flow
 */
import React from 'react';
import FormManager from "../../../../lib/FormManager";
import {util} from '../../../../services/service';
import {AccountTypeSearchInput} from '../../../../components/SearchInput';
import {useSimpleGrid} from '../../../../components/SimpleGrid';
import {DefaultButton} from '../../../../components/buttons';
import {apiCourier} from "../../../../services/newCoship/service";
import {ColDef} from "ag-grid-community";
import {unitOptions} from "../../../field-defs/newCoship";

class CourierHouseBLAccountAddItemFormManager extends FormManager {
  invId;
  accType;
  totalAmount;
  grid;
  currency;
  constructor() {
    super({
      prefix: `courier-account-invoice-add-item-form`,
      fields: [
        {name: 'job_id', serverName: 'job_id', label: ''},
        {name: 'atype_id', serverName: 'atype_id', label: ''},
        {name: 'description', serverName: 'description', label: 'Account Type'},
        {name: 'unit', serverName: 'unit', label: 'Unit', options: unitOptions, defaultValue: 'CNTR', noDefOption: true},
        {name: 'rate', serverName: 'rate', label: 'Rate'},
        {name: 'volume', serverName: 'volume', label: 'Volume', defaultValue: 1, mask: util.MASK_NUMBER_DECIMAL_SIX},
        {name: 'dc_rate', serverName: 'dc_rate', label: 'CUR. Rate', defaultValue: 1, mask: util.MASK_NUMBER_DECIMAL_SIX},
        {name: 'remark', serverName: 'remark', label: 'Remark'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  setData(id, accType) {
    this.invId = id;
    this.accType = accType;
  }

  setDefaultCurrency(currency) {
    this.currency = currency;
  }

  calculateAmount = (rows) => {
    const arrAmount = rows.map((data) => data.amount);
    this.totalAmount = util.arraySum(arrAmount);
  };

  onRender = () => {
    const [gridMode, setGridMode] = React.useState('add');
    const [isScrollToBottom, setIsScrollToBottom] = React.useState(false);
    const {_r, _f, _v, _n, _c, formik, fields, errors} = this.getRenderProps();

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data);
      }
    };

    const onCellClicked = (e) => {
      _c('job_id', e.data.job_id);
      _c('atype_id', e.data.atype_id);
      _c('description', e.data.description);
      _c('unit', e.data.unit);
      _c('rate', e.data.rate);
      _c('volume', e.data.volume);
      _c('amount', e.data.amount);
      this.accType === 'AR' && _c('dc_rate', e.data.dc_rate);
      _c('remark', e.data.remark);
      setGridMode('edit');
    };

    const grid = useItemGrid(onAction, onCellClicked, isScrollToBottom, this.accType);
    this.grid = grid;

    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atype_id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;

    const onSearch = () => {
      apiCourier.getAccountItems({inv_id: this.invId}).then((res) => {
        if (res) {
          const rows = res.data;
          grid.setRows(rows);
          const arrAmount = rows.map((data) => data.amount);
          this.totalAmount = util.arraySum(arrAmount);
        }
      });
    };

    const onAdd = () => {
      const rows = this.grid.rows ?? [];
      let {job_id = 0, atype_id, description, unit = 'CNTR', rate = 0, volume = 1, dc_rate = 1, remark = ''} = this.getValues();
      if(atype_id < 0 || !description) {
        return util.showWarning('Please enter a valid Account Type data!');
      }
      if(isNaN(rate)) {
        return util.showWarning('Please enter a valid Rate data!');
      }
      if(isNaN(volume)) {
        return util.showWarning('Please enter a valid Volume data!');
      }
      if (this.accType === 'AR') {
        if (isNaN(dc_rate)) {
          return util.showWarning('Please enter a valid CUR Rate data!');
        }
      }

      const newRow = {
        job_id, atype_id, description, unit, remark,
        rate: rate ? util.toFloat(rate) : 1,
        volume: volume ? util.toFloat(volume) : 1,
        dc_rate: dc_rate ? util.toFloat(dc_rate) : 1,
        amount: (rate && volume) ? util.toFloat(rate * volume) : 0,
      };
      let newRows;
      if(gridMode === 'add') {
        newRows = [...rows, newRow];
      } else if(gridMode === 'edit') {
        const rows = grid.rows;
        const rowIndex = rows.findIndex(i => i.job_id === job_id);
        newRows = [...rows];
        newRows[rowIndex] = newRow;
      }
      // grid.setRows(newRows);
      this.clearValues();
      this.calculateAmount(newRows);
      setIsScrollToBottom(gridMode === 'add');
      setGridMode('add');
      this.setFocus('description');
      const payload = {
        ...newRow,
        inv_id: this.invId,
      };
      apiCourier.setAccountItem(payload).then(res => {
        if (res) {
          onSearch();
        }
      });
    };

    const onDelete = (data) => {
      const payload = {
        inv_id: data.inv_id,
        job_id: data.job_id,
        acc_type: this.accType,
      };
      if (payload.inv_id) {
        util.showConfirm('Are you sure to delete?', async () => {
          apiCourier.delAccountItem(payload).then((res) => {
            if (res) {
              util.showSuccess('Account has been deleted successfully!');
              onSearch();
            }
          });
        });
      }
    }

    const onCancelEdit = () => {
      setGridMode('add');
      this.clearValues();
    };

    React.useEffect(() => {
      _c('unit', 'CNTR');
    }, []);

    React.useEffect(() => {
      onSearch();
    }, []);

    return (
      <div className={'w-full'}>
        <div className={'flex between w-full'}>
          <label className={'flex-1'}><b className={'ml-12'}>{'Invoice Items'}</b></label>
          <div className={'flex-2 flex right pr-20'}>
            <span className={'mr-12'}>Total:</span>
            <span className={'mr-12'}>{util.formatCurrency(util.toFloat(this.totalAmount)) ?? '$0.00'}</span>
          </div>
        </div>
        {grid.render()}
        <div className={'flex w-full'}>
          <div className={'flex-3'}>{_at('description')}</div>
          <div className={'flex-2'}>{_r('unit')}</div>
          <div className={'flex-2'}>{_r('rate')}</div>
          <div className={'flex-2'}>{_r('volume')}</div>
          {this.accType === 'AR' && <div className={'flex-2'}>{_r('dc_rate')}</div>}
          <div className={'ml-20'}>{''}</div>
          {gridMode === 'add' ?
            <div className={'ml-20'}><DefaultButton label={'Add'} onClick={onAdd} noMargin /></div> :
            (
              <div className={'ml-20'}>
                <DefaultButton label={'Edit'} onClick={onAdd} />
                <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
              </div>
            )
          }
        </div>
        <div>{_r('remark')}</div>
      </div>
    );
  };

  onValidate = (values) => {
    return values;
  };
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function useItemGrid(onAction, onCellClicked, isScrollToBottom, accType) {
  function getColumns(): ColDef[] {
    if (accType === 'AR') {
      return [
        {field: 'description', headerName: 'Account Type', width: 150},
        {field: 'unit', headerName: 'Unit', width: 100},
        {field: 'rate', headerName: 'Rate', width: 100},
        {field: 'volume', headerName: 'Volume', width: 100},
        {field: 'dc_rate', headerName: 'CUR. Rate', width: 120},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    } else {
      return [
        {field: 'description', headerName: 'Account Type', width: 150},
        {field: 'unit', headerName: 'Unit', width: 100},
        {field: 'rate', headerName: 'Rate', width: 100},
        {field: 'volume', headerName: 'Volume', width: 100},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    }
  };
  return useSimpleGrid({
    columns: getColumns(),
    height: 300,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: onAction,
    isScrollToBottom,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      }
    }
  });
}

export default CourierHouseBLAccountAddItemFormManager;
