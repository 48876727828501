/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from '../../components/DataGridView';
import {apiAccount, util} from '../../services/service';

export default function useAccountCommissionGridView(cargoType, detailModal) {
  const queryInfo = React.useRef();
  function openDetailModal(data) {
    const {partner_id: partnerId} = data;
    const {fromDate, toDate} = queryInfo.current ?? {};
    detailModal.open({...data, partnerId, fromDate, toDate}, 'commission');
  }
  return useDataGridView({
    async onAction(action, data) {
      if (action === 'detail') {
        openDetailModal(data);
      }
    },
    async onQuery(gridInfo) {
      queryInfo.current = gridInfo;
      return apiAccount.commissionList(gridInfo);
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      // if (headerName !== 'Actions') {
      //   openHistoryModal(data);
      // }
    },
    name: `DropzoneList_${cargoType}`,
    label: 'Dropzone List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: cargoType},
    menuLabel: {label: 'List Dropzone'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['detail'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    initialSearchLastMonth: true,
    showBranch: true,
    columns: [
      {field: 'partner_id', hide: true},
      {field: 'partner_name', headerName: 'Drop Zone', flex: 1},
      {field: 'phones', headerName: 'Phone #', flex: 1},
      {field: 'city', headerName: 'City', width: 150},
      {field: 'pickup_str', headerName: 'Schedule', valueFormatter: scheduleFormatter, width: 150},
      {field: 'packages', headerName: 'Packages', width: 140},
      {field: 'currency', headerName: 'Currency', width: 140},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 140},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 140},
      {field: 'commission', headerName: 'Commission', valueFormatter: util.currencyFormatter, width: 140},
    ],
  });
}

function scheduleFormatter(p) {
  return p.value ? p.value.replace(/\'/g, '').toUpperCase() : '';
}
