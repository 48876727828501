/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from "../media/coship_logo.png";

class InvoiceVDPrint extends React.Component {
  cntCustomers = 0;
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountData, accountInvoiceAddItemData, accountVDDataDetailData} = printData;
    const totalAmount = accountInvoiceAddItemData.totAmount;
    const paidAmount = accountInvoiceAddItemData.paidAmount;
    const payAmount = totalAmount - paidAmount;
    const disCount = accountData.dcRate2 ? `${accountData.dcRate2 * 100}% DISCOUNT APPLIED` : '';
    const arrVDDetailData = util.chunk(accountVDDataDetailData, 54);
    return (
      <div style={{color: 'black'}}>
        <div className={'page-break'}>
          {this.renderHeader(accountData, user)}
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'}>W/O Date</td>
              <td className={'no-border-lr right invoice_label'}>CUSTOMERS</td>
              <td className={'no-border-lr right invoice_label'}>AMOUNT</td>
            </tr>
            {this.renderInvoiceItems(printData.accountGridData)}
            {this.renderEmptyItems(printData.accountGridData)}
            </tbody>
          </table>
          <hr />
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            {this.renderInvoiceItemsTotal('', 'TOTAL DUE', '', util.formatCurrency(totalAmount))}
            {this.renderInvoiceItemsTotal(`${this.cntCustomers} CUSTOMERS`, 'PAID AMOUNT', '', util.formatCurrency(paidAmount))}
            {this.renderInvoiceItemsTotal(disCount, 'PLEASE PAY THIS AMOUNT', 'CAD', util.formatCurrency(payAmount))}
            </tbody>
          </table>
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            {this.renderInvoiceExtraInfo('MEMO', accountData.memo)}
            {this.renderInvoiceExtraInfo('BANK INFO', accountData.bankInfo)}
            </tbody>
          </table>
          <hr className={'line'} />
        </div>
        {/*<div className={'page-break'} style={{marginTop: '2cm'}}>*/}
        {/*  <table className={'print-table mt-20'} style={{width: '100%'}}>*/}
        {/*    <tbody>*/}
        {/*    <tr>*/}
        {/*      <td className={'no-border-lr invoice_label'} width={'20%'}>DATE</td>*/}
        {/*      <td className={'no-border-lr invoice_label'} width={'20%'}>TRACKING NO.</td>*/}
        {/*      <td className={'no-border-lr right invoice_label'} width={'20%'}>CUSTOMER</td>*/}
        {/*      <td className={'no-border-lr right invoice_label'} width={'20%'}>WEIGHT</td>*/}
        {/*      <td className={'no-border-lr right invoice_label'} width={'20%'}>AMOUNT</td>*/}
        {/*    </tr>*/}
        {/*    {this.renderCustomerDetailItems(arrVDDetailData)}*/}
        {/*    </tbody>*/}
        {/*  </table>*/}
        {/*</div>*/}
        {this.renderCustomerDetailItems(arrVDDetailData)}
      </div>
    );
  }
  renderHeader(accountData, user) {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all right invoice_label'}><h1>INVOICE</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_right'}>
                  <h3 className={'invoice_label'}>INVOICE No. : {accountData.invoiceNo}</h3>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>BILL TO :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{accountData.billTo}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'invoice_label'}>INVOICE DATE</td>
                <td>{accountData.invoiceDate}</td>
              </tr>
              <tr>
                <td className={'invoice_label'}>TERMS</td>
                <td>{accountData.terms}</td>
              </tr>
              <tr>
                <td className={'invoice_label'}>DUE DATE</td>
                <td>{accountData.dueDate}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceItems(items) {
    this.cntCustomers = 0;
    return items?.map((item, index) => {
      this.cntCustomers += item['Customers'];
      return (
        <tr key={index}>
          <td className={'no-border-all'}>{util.formatD(item['PostDate'])}</td>
          <td className={'no-border-all right'}>{item['Customers']}</td>
          <td className={'no-border-all right'}>{util.formatCurrency(item['Amount'])}</td>
        </tr>
      );
    });
  }
  renderEmptyItems(items) {
    const empty = [];
    let length = 30 - items.length;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={3} className={'no-border-all'}>&nbsp;</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceItemsTotal(label1, label2, value1, value2) {
    return (
      <tr>
        <td className={'no-border-all invoice_label'}>{label1}</td>
        <td className={'no-border-all right invoice_label'}>{label2}</td>
        <td className={'no-border-all right invoice_label'}>{value1}</td>
        <td className={'no-border-all right invoice_label'}>{value2}</td>
      </tr>
    );
  }
  renderInvoiceExtraInfo(label, value) {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-line', minHeight: '150px'}}>{value}</div></td>
      </tr>
    );
  }
  // renderCustomerDetailItems(items) {
  //   return items?.map((item, index) => {
  //     return (
  //       <tr key={index}>
  //         <td className={'no-border-lr'} width={'20%'}>{util.formatD(item.post_date)}</td>
  //         <td className={'no-border-lr'} width={'20%'}>{item.tracking_no}</td>
  //         <td className={'no-border-lr right'} width={'20%'}>{item.customer}</td>
  //         <td className={'no-border-lr right'} width={'20%'}>{item.weight}</td>
  //         <td className={'no-border-lr right'} width={'20%'}>{item.amount}</td>
  //       </tr>
  //     )
  //   });
  // }
  renderCustomerDetailItems(arrItems) {
    return arrItems.map((items) => {
      return (
        <div className={'page-break'} style={{marginTop: '2cm'}}>
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'} width={'20%'}>DATE</td>
              <td className={'no-border-lr invoice_label'} width={'20%'}>TRACKING NO.</td>
              <td className={'no-border-lr right invoice_label'} width={'20%'}>CUSTOMER</td>
              <td className={'no-border-lr right invoice_label'} width={'20%'}>WEIGHT</td>
              <td className={'no-border-lr right invoice_label'} width={'20%'}>AMOUNT</td>
            </tr>
            {items?.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={'no-border-lr'} width={'20%'}>{util.formatD(item.post_date)}</td>
                  <td className={'no-border-lr'} width={'20%'}>{item.tracking_no}</td>
                  <td className={'no-border-lr right'} width={'20%'}>{item.customer}</td>
                  <td className={'no-border-lr right'} width={'20%'}>{item.weight}</td>
                  <td className={'no-border-lr right'} width={'20%'}>{util.formatCurrency(item.amount)}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      );
    });
  }
}

export function useInvoiceVDPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountFormRef = React.useRef();
  const accountSearchFormRef = React.useRef();
  const accountInvoiceAddItemRef = React.useRef();
  const accountVDDataDetailRef = React.useRef();
  const printRef = React.useRef();
  const invoiceNoRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: invoiceNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceVDPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountFormRef.current.getValues(),
        accountSearchData: accountSearchFormRef.current.getValues(),
        accountInvoiceAddItemData: accountInvoiceAddItemRef.current.getValues(),
        accountGridData: accountInvoiceAddItemRef.current.getGridData(),
        accountVDDataDetailData: accountVDDataDetailRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 0);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (accountForm, accountSearchForm, accountInvoiceAddItem, accountVDDataDetail) => {
      accountFormRef.current = accountForm;
      accountSearchFormRef.current = accountSearchForm;
      accountInvoiceAddItemRef.current = accountInvoiceAddItem;
      accountVDDataDetailRef.current = accountVDDataDetail;
      invoiceNoRef.current = accountForm.getValue('invoiceNo');
      modal.open();
    },
    print,
  };
}

export default InvoiceVDPrint;
