/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {PortSearchInput, TradePartnerSearchInput} from '../../components/SearchInput';

const FN = {
  ID: 'id',
  IS_VESSEL: 'isVessel',
  FILING_NO: 'filingNo',
  CARRIER_ID: 'carrierId',
  CARRIER: 'carrier',
  CRAFT_NO: 'craftNo',
  ETD_DATE: 'etdDate',
  ETD_TIME: 'etdTime',
  ETA_DATE: 'etaDate',
  ETA_TIME: 'etaTime',
  DEPARTURE: 'departure',
  DESTINATION: 'destination',
  FIRST_TO: 'firstTo',
  TRANS_1_TO: 'trans1To',
  TRANS_1_FLIGHT: 'trans1Flight',
  TRANS_2_TO: 'trans2To',
  TRANS_2_FLIGHT: 'trans2Flight',
  TRANS_3_TO: 'trans3To',
  TRANS_3_FLIGHT: 'trans3Flight',
  ISSUING_CARRIER: 'issuingCarrier',
  BILLING_CARRIER: 'billingCarrier',
  ETA_FPOE_DATE: 'etaOfFpoeDate',
  ETA_FPOE_TIME: 'etaOfFpoeTime',
  FINAL_ETA_DATE: 'finalEtaDate',
  FINAL_ETA_TIME: 'finalEtaTime',
};

const SN = {
  ID: 'id',
  IS_VESSEL: 'isvessel',
  FILING_NO: 'filing_no',
  CARRIER_ID: 'carrier_id',
  CARRIER: 'carrier_name',
  CRAFT_NO: 'craft_no',
  ETD_DATE: 'etd_date',
  ETD_TIME: 'etd_time',
  ETA_DATE: 'eta_date',
  ETA_TIME: 'eta_time',
  DEPARTURE: 'departure',
  DESTINATION: 'destination',
  FIRST_TO: 'firstTo',
  TRANS_1_TO: 'trans1To',
  TRANS_1_FLIGHT: 'trans1Flight',
  TRANS_2_TO: 'trans2To',
  TRANS_2_FLIGHT: 'trans2Flight',
  TRANS_3_TO: 'trans3To',
  TRANS_3_FLIGHT: 'trans3Flight',
  ISSUING_CARRIER: 'issuingCarrier',
  BILLING_CARRIER: 'billingCarrier',
  ETA_FPOE_DATE: 'etaOfFpoeDate',
  ETA_FPOE_TIME: 'etaOfFpoeTime',
  FINAL_ETA_DATE: 'finalEtaDate',
  FINAL_ETA_TIME: 'finalEtaTime',
};

class FlightFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'flight-form',
      fields: [
        {name: FN.ID, serverName: SN.ID, label: '', required: false, },
        {name: FN.IS_VESSEL, serverName: SN.IS_VESSEL, label: '', required: false, },
        {name: FN.FILING_NO, serverName: SN.FILING_NO, label: 'Filing No.', required: false, },
        {name: FN.CARRIER_ID, serverName: SN.CARRIER_ID, label: '', required: false, },
        {name: FN.CARRIER, serverName: SN.CARRIER, label: 'Carrier', required: true, },
        {name: FN.CRAFT_NO, serverName: SN.CRAFT_NO, label: 'Flight No.', required: false, },
        {name: FN.ETD_DATE, serverName: SN.ETD_DATE, label: 'ETD', type: 'date', required: false, },
        {name: FN.ETD_TIME, serverName: SN.ETD_TIME, label: '', required: false, },
        {name: FN.ETA_DATE, serverName: SN.ETA_DATE, label: 'ETA', type: 'date', required: false, },
        {name: FN.ETA_TIME, serverName: SN.ETA_TIME, label: '', required: false, },
        {name: FN.DEPARTURE, serverName: SN.DEPARTURE, label: 'Departure', required: true, },
        {name: FN.DESTINATION, serverName: SN.DESTINATION, label: 'Destination', required: true, },
        {name: FN.FIRST_TO, serverName: SN.FIRST_TO, label: 'First To', required: false, },
        {name: FN.TRANS_1_TO, serverName: SN.TRANS_1_TO, label: 'Trans1', required: false, },
        {name: FN.TRANS_1_FLIGHT, serverName: SN.TRANS_1_FLIGHT, label: '', required: false, placeholder: 'Flight #', },
        {name: FN.TRANS_2_TO, serverName: SN.TRANS_2_TO, label: 'Trans2', required: false, },
        {name: FN.TRANS_2_FLIGHT, serverName: SN.TRANS_2_FLIGHT, label: '', required: false, placeholder: 'Flight #', },
        {name: FN.TRANS_3_TO, serverName: SN.TRANS_3_TO, label: 'Trans3', required: false, },
        {name: FN.TRANS_3_FLIGHT, serverName: SN.TRANS_3_FLIGHT, label: '', required: false, placeholder: 'Flight #', },
        {name: FN.ISSUING_CARRIER, serverName: SN.ISSUING_CARRIER, label: 'Issuing Carrier', required: false, },
        {name: FN.BILLING_CARRIER, serverName: SN.BILLING_CARRIER, label: 'Billing Carrier', required: false, },
        {name: FN.ETA_FPOE_DATE, serverName: SN.ETA_FPOE_DATE, label: 'ETA of FPOE', type: 'date', required: false, },
        {name: FN.ETA_FPOE_TIME, serverName: SN.ETA_FPOE_TIME, label: '', required: false, },
        {name: FN.FINAL_ETA_DATE, serverName: SN.FINAL_ETA_DATE, label: 'Final ETA', type: 'date', required: false, },
        {name: FN.FINAL_ETA_TIME, serverName: SN.FINAL_ETA_TIME, label: '', required: false, },
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const _tp = (name) => <TradePartnerSearchInput idField={_n(`${name}Id`)} displayField={_n(name)} formik={formik} fields={fields} errors={errors} />;
    const _pt = (name) => <PortSearchInput idField={_n(`${name}Id`)} displayField={_n(name)} formik={formik} fields={fields} errors={errors} />
    return (
      <div className={'flex mr-4'}>
        <div className={'flex-1'}>
          {_tp(FN.CARRIER)}
          {_tp(FN.ISSUING_CARRIER)}
          {_r(FN.CRAFT_NO)}
          {_tp(FN.BILLING_CARRIER)}
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.ETD_DATE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.ETD_TIME)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.ETA_FPOE_DATE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.ETA_FPOE_TIME)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.ETA_DATE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.ETA_TIME)}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.FINAL_ETA_DATE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.FINAL_ETA_TIME)}</div>
          </div>
          {_pt(FN.DEPARTURE)}
          {_pt(FN.FIRST_TO)}
          <div className={'flex'}>
            <div className={'flex-3'}>{_pt(FN.TRANS_1_TO)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.TRANS_1_FLIGHT)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_pt(FN.TRANS_2_TO)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.TRANS_2_FLIGHT)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_pt(FN.TRANS_3_TO)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.TRANS_3_FLIGHT)}</div>
          </div>
          {_pt(FN.DESTINATION)}
        </div>
      </div>
    );
  };
  onValidate = (values) => values;
}

export default FlightFormManager;
