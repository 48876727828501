/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {kindOptions} from '../field-defs/bl';
import {oceanAirOptionsForBLList} from '../helper';
import {useUserBranch} from '../../redux/reducers/userReducer';
import {TradePartnerSearchInput} from "../../components/SearchInput";
import {util} from '../../services/service';

class BLReportSearchFormManager extends FormManager {
  onSearch;
  constructor() {
    super({
      prefix: 'bl-list-search',
      fields: [
        // eslint-disable-next-line max-len
        {name: 'kind', serverName: 'kind', label: 'Service', smallLabel: true, smallMargin: true, options: addAllOptions(kindOptions), noDefOption: true},
        // eslint-disable-next-line max-len
        {name: 'carrierType', serverName: 'carrierType', label: 'Type', smallLabel: true, smallMargin: true, options: addAllOptions(oceanAirOptionsForBLList), noDefOption: true},
        {name: 'branchId', serverName: 'branchId', label: 'Branch', smallLabel: true, smallMargin: true, noDefOption: true},
        {name: 'partner', serverName: 'partner', label: 'Partner'},
        {name: 'partnerId', serverName: 'partnerId', label: ''},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const branchId = _v('branchId');
    const {readyToSearch} = useUserBranch(this, 'branchId', () => {
      const {type, partner_id, partner_name} = util.getQS();
      if (type !== undefined) _c('carrierType', type);
      if (partner_id !== undefined) _c('partnerId', partner_id);
      if (partner_name !== undefined) _c('partner', partner_name);
      setTimeout(() => this.onSearch(), 0);
    }, true);
    const kind = _v('kind');
    const carrierType = _v('carrierType');
    const partnerId = _v('partnerId');
    React.useEffect(() => {
      if (readyToSearch) {
        this.onSearch();
      }
    }, [kind, carrierType, branchId, partnerId]);
    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      vertical forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;
    return (
      <>
        {_r('kind')}
        {_r('carrierType')}
        {_r('branchId')}
        {_tp('partner')}
      </>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

function addAllOptions(options: any[]) {
  return [{value: '', label: 'ALL'}, ...options];
}

export default BLReportSearchFormManager;
