/**
 * @flow
 */
import {useSimpleEditModal} from '../../components/SimpleModal';
import StaffHolidayEditFormManager from '../../forms/staff/StaffHolidayEditFormManager';
import {useUser} from "../../redux/reducers/userReducer";

const editForm = new StaffHolidayEditFormManager();

export default function useHolidayEditModal(onSave) {
  const user = useUser();
  const modal = useSimpleEditModal({
    title: 'HOLIDAY',
    centered: true,
    width: 600,
    form: editForm,
    onSave,
    isDirector: user.isDirector,
  });
  return {...modal};
}
