/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {portTypeOptions} from '../helper';
import {util} from '../../services/service';

class VehicleFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'vehicle-form',
      fields: [
        {name: 'id', serverName: 'id', label: 'ID'},
        {name: 'vName', serverName: 'vName', label: 'Name', required: true},
        {name: 'remark', serverName: 'remark', label: 'Remark'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    return (
      <div className={'w-full mr-24'}>
        {_r('vName')}
        {_r('remark')}
      </div>
    );
  };
  onValidate = (values) => {
    const {vName} = values;
    if (!vName) {
      util.showWarning('Please enter the vehicle name');
      return;
    }
    return values;
  };
}

export default VehicleFormManager;
