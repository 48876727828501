/**
 * @flow
 */
import React from 'react';
import API from "./API";

class APISettings extends API {
  async hawbList() {
    return this.sendList('settings/ListData', {action: 'ListHAWB', data: {}});
  }

  async setHAWB(data) {
    return this.sendList('settings/CUD', {action: 'SetHAWB', data});
  }

  async defaultCommissionList() {
    return this.sendList('settings/GetData', {action: 'DefaultCommission', data: {}});
  }

  async setDefaultCommission(data) {
    return this.sendList('settings/CUD', {action: 'SetDefaultCommission', data});
  }

  async commissionList() {
    return this.sendList('settings/ListData', {action: 'ListCommission', data: {}});
  }

  async setCommission(data) {
    return this.sendList('settings/CUD', {action: 'SetCommission', data});
  }

  async courierPriceList() {
    return this.sendList('settings/ListData', {action: 'ListCourierPrice', data: {}});
  }

  async getCourierPrice(partnerId, carrierType) {
    return this.sendList('settings/GetData', {action: 'GetCourierPrice', data: {partnerId, carrierType}});
  }

  async setCourierPrice(data) {
    return this.sendList('settings/CUD', {action: 'SetCourierPrice', data});
  }

  async hsCodeList(data) {
    return this.sendList('settings/ListData', {action: 'ListHsCode', data});
  }

  async setHSCode(data) {
    return this.sendList('settings/CUD', {action: 'SetHsCode', data});
  }

  async delHSCode(hsCode) {
    return this.sendList('settings/CUD', {action: 'DelHsCode', data: {hsCode}});
  }

  async updateTXT(data) {
    const parts = data.split(',');
    return this.sendBase64('file/UpKPostal', parts[1]);
  }

  async getLastUpdated() {
    return this.sendList('settings/GetData', {action: 'GetLastUpdated', data: {}});
  }

  async delHAWBNumber(data) {
    return this.sendList('settings/CUD', {action: 'DelHAWB', data});
  }

  async listEmail(branchId) {
    return this.sendList('settings/ListData', {action: 'ListEmail', data: {branchId}});
  }

  async setEmail(data) {
    return this.sendList('settings/CUD', {action: 'SetEmail', data});
  }

  async getEmailSubject() {
    return this.sendList('settings/GetData', {action: 'GetEmailSubject', data: {}});
  }

  useEmailSubjectList() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getEmailSubject().then(({data}) => {
        const newOptions = data.map((c) => ({value: c[0], label: `[${c[1]}] ${c[2]}`}));
        setOptions(newOptions);
      }, []);
    });
    return [...options];
  }

  async getEmailTemplate(data) {
    return this.sendList('settings/GetData', {action: 'GetEmailTemplate', data});
  }

  async sendEmail(data) {
    return this.send('api/EmailSend', data);
  }

  async getLogEmail(data) {
    return this.sendList('logs/GetLogEmail', {action: 'GetLogEmail', data});
  }

  async listInventory() {
    return this.sendList('settings/ListData', {action: 'ListInventory', data: {}});
  }

  async setInventory(data) {
    return this.sendList('settings/CUD', {action: 'SetInventory', data});
  }
}

export default APISettings;
