/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions} from '../forms/field-defs/bl';

class AIHPClaim extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return null;
    }
    const {
      shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, hblNo, hawbNo, mblNo, mawbNo, subMblNo, amsNo, customerRefNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime,
      polLabel, podLabel, fDestEta, delEta,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      to, noticeToAddr, shortage,
      delivery, final, podFull, polFull, porFull,
    } = printData;
    const container = containers?.[0]; // 현재 첫번째 컨테이너 정보를 표시해줌. 컨테이너가 많으면???
    const {renderNameValue: _nv} = this;
    return (
      <div className={'px-48'} style={{color: 'black', fontSize: 16, marginTop: '1cm'}}>
        <div className={'flex-column center mb-48'}>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
          <div>{address}</div>
          <div>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>PRELIMINARY CLAIM ACKNOWLEDGEMENT</div>
        </div>
        <div className={'flex w-full mb-4'}>
          <div className={'w-128'}>Date :</div>
          <div className={'flex-1'}>{util.formatD(Date.now())}</div>
        </div>
        <div className={'flex w-full mb-24'}>
          <div className={'w-128'}>{' '}</div>
          <div className={'flex-1'} style={{whiteSpace: 'pre-line'}}>{noticeToAddr}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'w-128'}>Re :</div>
          <div className={'flex-1 flex w-full'}>
            <div className={'flex-1'}>
              {_nv('Vessel', carrier)}
              {_nv('Voyage/Flight', flightNo)}
              {_nv('House B/L', hawbNo)}
              {_nv('Your Ref No.', filingNo)}
              {_nv('From / Via / To', `${polFull} / ${podFull}`)}
              {_nv('ETD / ETA', `${util.formatD(flightDate)} ${flightTime} / ${util.formatD(arrivalDate)} ${arrivalTime}`)}
              {_nv('Piece Count', packageCount)}
              {_nv('Weight', util.weightForPrint(grossWeightKg, 'KG'))}
              {_nv('Measurement', '')}
            </div>
            <div className={'flex-1'}>
              {_nv('Container', '')}
              <div className={'py-1'}>{' '}</div>
              {_nv('Master B/L', mawbNo)}
            </div>
          </div>
        </div>
        <p style={{color: 'black'}}>Dear <b>Sir or Madam</b></p>
        <p style={{color: 'black'}}>This is to inform you that our client suffered <b>shortage/damage</b> to the shipment referenced above.<br/>Preliminary investigation reveals the following <b>shortage/damage : </b></p>
        <p style={{color: 'black', minHeight: 200}}>{shortage}</p>
        <p style={{color: 'black'}}>We are holding you responsible and our formal claim statement will follow once complete details of the shortage/damage are ascertained. Not dealing with this claim at this stage is not to be construed as a waiver of our rights.</p>
        <p style={{color: 'black'}}>This is a preliminary notice of claim and may be amended. Please acknowledge receipt of this claim notification.</p>
        <p style={{color: 'black'}}>Thank you for your cooperation.</p>
        <p style={{color: 'black'}}>Sincerely,</p>
      </div>
    );
  }
  renderNameValue(name, value) {
    return (
      <div className={'flex py-1'}>
        <div className={'w-128'}>{name}</div>
        <div className={'mr-16'}>:</div>
        <div>{value}</div>
      </div>
    );
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers, serverData} = blData;
    const {shipperAddr = '', consigneeAddr = '', forwardingAgentAddr = '', notifyAddr = '', cBrokerAddr = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = '', exportRefNo = '', hblNo, hawbNo, mblNo, mawbNo, subMblNo, amsNo, customerRefNo = ''} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime} = jvessel;
    const {polLabel, podLabel, fDestEta, delEta} = jroute;
    const {route} = serverData;
    // const {emptyPickup, forwardAgent, partner} = bladdress;
    // const {doc, pickupDate, portOpen, rail, vgm} = cutoff;
    const {delivery, final, podFull, polFull, porFull} = route;
    let {freight, orgBlType, svcTerm1, svcTerm2, package: packageCount, packageType, grossWeightKg, chargeableWeightKg} = jshipment;
    const {desc, mark, itNo, date: itDate, itLoc, pubRemark} = jmark ?? {};
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.labelFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.labelFromOptions(svcTerm2, svcTypeOptions);
    // const blankLines = [];
    // const blankCount = 10 - containers.length;
    // for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    const {to, noticeToAddr, shortage} = printFormData;
    return {
      shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, hblNo, hawbNo, mblNo, mawbNo, subMblNo, amsNo, customerRefNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime,
      polLabel, podLabel, fDestEta, delEta,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      // blankLines,
      company, address, email, fax, phone, pic,
      to, noticeToAddr, shortage,
      // emptyPickup, forwardAgent, partner,
      // doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
    };
  }
}

export default AIHPClaim;
