/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {DefaultButton} from '../../components/buttons';
import {apiSettings, util} from "../../services/service";
import {useDataGridView} from "../../components/DataGridView";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class MasterHSCodeFormManager extends FormManager {
  grid;
  constructor() {
    super({
      prefix: `master-HSCode-add-item-form`,
      fields: [
        {name: 'hsCode', serverName: 'hsCode', label: ''},
        {name: 'country', serverName: 'country', label: '', mask: util.MASK_COUNTRY},
        {name: 'keywords', serverName: 'keywords', label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onRender = () => {
    const title = 'HS Code';
    const [mode, setMode] = React.useState('add');
    const grid = useHSCodeItemGrid(
      (action, data) => {
        if (action === 'delete') {
          const hsCode = data.hs_code;
          if(hsCode) {
            const res = apiSettings.delHSCode(hsCode);
            if(res) {
              setMode('add');
              grid.query();
            }
          }
        }
      },
        (data) => {
        this.setValues({...data, hsCode: data.hs_code});
        setMode('edit');
      }
    );
    this.grid = grid;
    const {_r, _c, _v} = this.getRenderProps();
    const onSave = async () => {
      const values = this.getValues(undefined, true);
      if (values) {
        let {hsCode, country, keywords} = values;
        if(typeof keywords === 'string') {
          keywords = util.getRemoveSpace(keywords).toUpperCase().split(',');
        }
        const params = {hsCode, country, keywords: keywords};
        let res = await apiSettings.setHSCode(params);
        if (res) {
          this.clearValues();
          setMode('add');
          grid.query();
        }
      }
    };
    const onCancelEdit = () => {
      setMode('add');
      this.clearValuesFast();
    };
    const country = _v('country');
    React.useEffect(() => {
      if(!country) {
        _c('country', 'KR');
      }
      grid.query();
    }, [country]);

    function useHSCodeItemGrid(onActionCb, onCellClicked) {
      return useDataGridView({
        async onAction(action, data) {
          await onActionCb(action, data);
        },
        async onQuery(gridInfo) {
          return await apiSettings.hsCodeList(gridInfo);
        },
        onCellValueChange(e) {
        },
        onCellClick({data, column: {colDef: {headerName}}}) {
          if (headerName !== 'Actions') {
            onCellClicked(data);
          }
        },
        name: `ListHSCode`,
        label: 'HSCode List',
        addLabel: '',
        editLabel: '',
        sortCol: 'hs_code',
        sortDesc: true,
        pageRows: 20,
        actions: ['delete'],
        actionWidth: 70,
        showDateSearch: false,
        showDeleteButton: false,
        hideSearchInput: true,
        showRowSearch: false,
        sortableColumns: {},
        useExternalForm: true,
        useModalDataGrid: true,
        modalDataGridHeight: 250,
        columns: [
          {field: 'hs_code', headerName: 'HS Code', width: 150},
          {field: 'country', headerName: 'Country', width: 150},
          {field: 'keywords', headerName: 'Keywords', flex: 1},
        ],
      });
    }

    return (
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
            </div>
            {grid.render()}
            <div className={'flex w-full'}>
              <div className={'flex-1 mr-40'}>
                <div>HS Code</div>
                <div>{_r('hsCode')}</div>
              </div>
              <div className={'flex-1 mr-40'}>
                <div>Country</div>
                <div>{_r('country')}</div>
              </div>
              <div className={'flex-12'}>
                <div>Keywords</div>
                <div>{_r('keywords')}</div>
              </div>
              <div className={'ml-20'}>{''}</div>
              {mode === 'add'
                ? (
                  <div className={'mt-20 ml-20'}><DefaultButton label={'Add'} onClick={onSave} noMargin /></div>
                )
                : (
                  <div className={'mt-20 ml-20'}>
                    <DefaultButton label={'Edit'} onClick={onSave} />
                    <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
                  </div>
                )
              }
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  onValidate = (values) => {
    const {hsCode} = values;
    if (!hsCode) {
      util.showWarning('Please enter a valid HS Code!');
      return null;
    }
    return values;
  };

  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

export default MasterHSCodeFormManager;
