/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {PrimaryButton} from "../../../../components/buttons";
import {FD, FN} from "../../../field-defs/newCoship";
import {TradePartnerSearchInput} from "../../../../components/SearchInput";
import {useSelector} from "react-redux";
import {util} from "../../../../services/service";
import {apiCourier} from "../../../../services/newCoship/service";
import {setCBM, setPKG} from "../../../../redux/reducers/newCoshipReducer";
import CourierHouseBLItemListFormManager from "./CourierHouseBLItemListFormManager";

const ItemListForm = new CourierHouseBLItemListFormManager();

class CourierHouseBLCustomerFormManager extends FormManager {
  isShowForm;
  houseBLForm;
  customerForm;
  workDetailsForm;
  constructor() {
    super({
      prefix: `courier-house-customer-form`,
      fields: [
        FD.REMARK, FD.REMARK_D, FD.WORK_ORDER, FD.WORK_ORDER_D,
        FD.SHIPPER_ID, FD.SHIPPER_LOCAL, FD.SHIPPER, FD.SHIPPER_ADDR, FD.SHIPPER_ADDR_ENG, FD.SHIPPER_EMAIL, FD.SHIPPER_PHONE1, FD.SHIPPER_PHONE2, FD.SHIPPER_POST,
        FD.CONSIGNEE_ID, FD.CONSIGNEE_LOCAL, FD.CONSIGNEE, FD.CONSIGNEE_ADDR_LOCAL, FD.CONSIGNEE_ADDR, FD.CONSIGNEE_EMAIL, FD.CONSIGNEE_PHONE1, FD.CONSIGNEE_PHONE2, FD.CONSIGNEE_POST,
        {...FD.PACKAGE, defaultValue: 1}, FD.GROSS_WEIGHT_KG, FD.VOLUME_WEIGHT_KG, FD.CBM, FD.TOTAL_PRICE, FD.DISC_RATE, FD.CURRENCY, FD.PAID_BY, FD.ENT_DATE, FD.VISA_TYPE, FD.TAPE,
        FD.VOL_LENGTH, FD.VOL_WEIGHT, FD.VOL_HEIGHT, FD.VISA_TYPE, FD.ENT_DATE, FD.MEMO, FD.PARTNER_ID, FD.PARTNER, FD.PARTNER_LOCAL, FD.PARTNER_LOCAL_ID, FD.PICKUP_TYPE,
        FD.DOCUMENT, FD.LITHIUM, FD.CUSTOMS_NO,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  getBranchName(branchId) {
    let branchOptions;
    try {
      branchOptions = this.houseBLForm.getField('branchId').options;
    } catch (error) {
      branchOptions = [];
    }
    return branchOptions.find(i => i.value === parseInt(branchId))?.label;
  }
  setData = (data) => {
    ItemListForm.setData(data);
    ItemListForm.houseBLForm = this.houseBLForm;
    ItemListForm.customerForm = this.customerForm;
    ItemListForm.workDetailsForm = this.workDetailsForm;
  };
  onRender = () => {
    const {renderField: _r, setValue: _c, getValue: _v, getField: _f, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const [partnerData, setPartnerData] = React.useState();
    const shipperPostRef = React.useRef();
    const consigneePostRef = React.useRef();
    const {branch, kind, carrierType} = useSelector(state => state['newCoship']);
    const isSeoul = branch === 'SEOUL';
    const isCourier = kind === 'C';
    _f(FN.SHIPPER_ADDR_ENG).label = isSeoul ? ' ' : '';
    _f(FN.CONSIGNEE_ADDR).label = isSeoul ? '' : ' ';
    _f(FN.SHIPPER_ADDR).placeholder = isSeoul ? 'Korean address' : 'English address';
    _f(FN.CONSIGNEE_ADDR_LOCAL).placeholder = isSeoul ? 'English address' : 'Korean address';
    _f(FN.SHIPPER_EMAIL).label = 'Contact';
    _f(FN.CONSIGNEE_EMAIL).label = 'Contact';
    _f(FN.SHIPPER_POST).label = 'Contact';
    _f(FN.CONSIGNEE_POST).label = 'Contact';
    _f(FN.SHIPPER_PHONE2).label = '';
    _f(FN.CONSIGNEE_PHONE2).label = '';
    _f(FN.REMARK).label = '';
    _f(FN.REMARK).textAreaRows = 3;
    _f(FN.REMARK_D).textAreaRows = 3;
    _f(FN.WORK_ORDER).textAreaRows = 3;
    _f(FN.WORK_ORDER_D).textAreaRows = 3;
    _f(FN.MEMO).label = 'Memo (D.Message)';
    _f(FN.MEMO).textAreaRows = 3;
    _f(FN.CURRENCY).label = 'Currency';
    _f(FN.PACKAGE).label = 'PKGS';
    _f(FN.GROSS_WEIGHT_KG).label = 'Gross W.';
    _f(FN.VOL_LENGTH).label = 'Vol Cal.';
    _f(FN.VOLUME_WEIGHT_KG).disabled = true;
    _f(FN.VOLUME_WEIGHT_KG).label = <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => onCalculateVolume()}>Vol W.</div>;
    _f(FN.TOTAL_PRICE).label = <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => onCalculateTotalPrice()}>Total Price</div>;
    _f(FN.DOCUMENT).onChange = (name, value, e) => onChangeDocument(e);
    _f(FN.DISC_RATE).onEnter = (name, value) => onChangeDiscRate(value);
    _f(FN.VOL_LENGTH).onEnter = () => onCalculateVolume();
    _f(FN.VOL_WEIGHT).onEnter = () => onCalculateVolume();
    _f(FN.VOL_HEIGHT).onEnter = () => onCalculateVolume();
    _f(FN.CBM).onChange = (_, value) => setCBM(util.dispatch, value);
    _f(FN.PACKAGE).onChange = (_, value) => setPKG(util.dispatch, value ? util.toInt(value) : '');

    const onCalculateVolume = () => {
      const length = _v(FN.VOL_LENGTH);
      const weight = _v(FN.VOL_WEIGHT);
      const height = _v(FN.VOL_HEIGHT);
      if (length && weight && height) {
        const volume = (util.toFloat(length) * util.toFloat(weight) * util.toFloat(height)) / 5000;
        _c(FN.VOLUME_WEIGHT_KG, volume.toFixed(2));
      } else {
        _c(FN.VOLUME_WEIGHT_KG, '');
      }
    };

    const onCalculateTotalPrice = () => {
      const grossWeightKg = _v(FN.GROSS_WEIGHT_KG);
      const volumeWeightKg = _v(FN.VOLUME_WEIGHT_KG);
      const payload = {portType: carrierType, grossWeightKg, volumeWeightKg};
      apiCourier.getCourierPrice(payload).then((res) => {
        if (res) {
          _c(FN.TOTAL_PRICE, res.data.price);
        }
      });
    };

    const onChangeDocument = (e) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        _c(FN.PACKAGE, 1);
        _c(FN.GROSS_WEIGHT_KG, '0.1');
        _c(FN.VOLUME_WEIGHT_KG, '0.1');
      } else {
        _c(FN.PACKAGE, 1);
        _c(FN.GROSS_WEIGHT_KG, '');
        _c(FN.VOLUME_WEIGHT_KG, '');
      }
      setPKG(util.dispatch, 1);
    };

    const onChangeDiscRate = (value) => {
      if (value) {
        const originPrice = _v(FN.TOTAL_PRICE);
        const discPrice = util.toFloat(originPrice) * (util.toFloat(value) / 100);
        const newPrice = util.toFloat(originPrice) - util.toFloat(discPrice);
        _c(FN.TOTAL_PRICE, newPrice.toString());
      }
    };

    const _tpa = (name, partner) => {
      return (
        <TradePartnerSearchInput
          idField={_n(`${partner}Id`)} displayField={_n(name)}
          formik={formik} fields={fields} errors={errors} vertical={true} onOK={(data) => onTPartner(partner, data)}
        />
      );
    };

    const onTPartner = (name, data) => {
      setPartnerData({name, data});
      const {id, value, Address, phone, cell, localName, email} = data;
      _c(`${name}`, value);
      _c(`${name}Local`, localName);
      _c(`${name}Id`, id);
      name !== FN.PARTNER && _c(`${name}Email`, email);
      if (name === FN.SHIPPER) {
        _c(`${name}AddrEng`, Address);
        _c(`${name}Phone`, phone);
        _c(`${name}Phone2`, cell);
      }
      if (name === FN.CONSIGNEE) {
        _c(`${name}Addr`, Address);
        _c(`${name}Phone1`, phone);
        _c(`${name}Phone2`, cell);
      }
      if (name === FN.NOTIFY) {
        _c(`${name}Addr`, Address);
        _c(`${name}Phone`, phone);
      }
    };

    const onSearchAddress = (searchType: 'shipper' | 'consignee') => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          const {zonecode, roadAddress, addressEnglish, buildingName, buildingCode} = data;
          if (searchType === 'shipper') {
            shipperPostRef.current = zonecode.toUpperCase();
            _c(FN.SHIPPER_ADDR, roadAddress.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
            _c(FN.SHIPPER_ADDR_ENG, addressEnglish.toUpperCase());
            _c(FN.SHIPPER_POST, zonecode.toUpperCase());
            _c(FN.SHIPPER_BUILDING_CODE, buildingCode);
          } else {
            consigneePostRef.current = zonecode.toUpperCase();
            _c(FN.CONSIGNEE_ADDR_LOCAL, roadAddress.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
            _c(FN.CONSIGNEE_ADDR, addressEnglish.toUpperCase());
            _c(FN.CONSIGNEE_POST, zonecode.toUpperCase());
            _c(FN.CONSIGNEE_BUILDING_CODE, buildingCode);
          }
        }
      }).open();
    };

    React.useEffect(() => {
      if (partnerData?.name === 'shipper' && carrierType === 'O') {
        _c('consignee', partnerData?.data?.value);
        _c('consigneeLocal', partnerData?.data?.localName);
        _c('consigneeId', partnerData?.data?.id);
        _c('consigneeAddr', '');
        _c('consigneePhone1', '');
        _c('consigneePhone2', '');
      }
    }, [_v(FN.SHIPPER_ID)]);

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pt-12'}>
            {carrierType === 'O' &&
              <div className={'flex pl-20 pr-20'}>
                <div className={'flex-1 mr-4'}>
                  <div>WORK ORDER(P)</div>
                  <div>{_r(FN.WORK_ORDER)}</div>
                </div>
                <div className={'flex-1'}>
                  <div>WORK ORDER(D)</div>
                  <div>{_r(FN.WORK_ORDER_D)}</div>
                </div>
              </div>
            }
            <div className={'flex pl-20 pr-20'}>
              <div className={'flex-1 mr-4'}>
                <div>REMARK(P)</div>
                <div>{_r(FN.REMARK)}</div>
              </div>
              <div className={'flex-1'}>
                <div>REMARK(D)</div>
                <div>{_r(FN.REMARK_D)}</div>
              </div>
            </div>
            <div className={'flex pl-8 pr-20 pt-12'}>
              <div className={'flex-3'}>
                <div className={'flex'}>
                  <div className={'flex-1 mr-8'}>{_r(FN.PARTNER_LOCAL)}</div>
                  <div className={'flex-1'}>{_tpa(FN.PARTNER, FN.PARTNER)}</div>
                </div>
                <div>{_tpa(FN.SHIPPER_LOCAL, 'shipper')}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER)}</div>
                <div className={'flex flex-1'}>
                  <div>
                    <div className={'ml-3'}>Address</div>
                    {isSeoul &&
                      <div className={'ml-2'}>
                        <PrimaryButton label={'Search'} onClick={() => onSearchAddress('shipper')} style={{whiteSpace: 'pre-line'}}/>
                      </div>
                    }
                  </div>
                  <div className={'w-full'} style={isSeoul ? {marginLeft: 5} : {marginLeft: 12}}>{isSeoul ? _r(FN.SHIPPER_ADDR) : _r(FN.SHIPPER_ADDR_ENG)}</div>
                </div>
                {isSeoul && <div className={'flex-1'} style={{marginTop: -5}}>{_r(FN.SHIPPER_ADDR_ENG)}</div>}
                {!isSeoul && <div className={'flex-1'} style={{marginTop: 69}}>{_r(FN.SHIPPER_EMAIL)}</div>}
                {isSeoul &&
                  <div className={'flex flex-1'} style={{marginTop: -4}}>
                    <div>{_r(FN.SHIPPER_POST)}</div>
                    {(carrierType === 'A' && isCourier) && <div className={'ml-2'}>{_r(FN.CUSTOMS_NO)}</div>}
                  </div>
                }
                <div className={'flex flex-1'}>
                  <div>{_r(FN.SHIPPER_PHONE1)}</div>
                  <div className={'ml-2'}>{_r(FN.SHIPPER_PHONE2)}</div>
                </div>
                <div className={'mt-8'}>{_r(FN.MEMO)}</div>
              </div>
              <div className={'flex-3'}>
                <div className={'flex'}>
                  <div className={'flex-6'}>{_r(FN.PICKUP_TYPE)}</div>
                  {(carrierType === 'A' && isCourier) &&
                    <>
                    <div className={'flex flex-1 mt-8 ml-4'}>
                      <div className={'mr-2'}>Doc</div>
                      <div>{_r(FN.DOCUMENT)}</div>
                    </div>
                    <div className={'flex flex-1 mt-8 ml-4'}>
                      <div className={'mr-2'}>Li</div>
                      <div>{_r(FN.LITHIUM)}</div>
                    </div>
                    </>
                  }
                </div>
                <div>{_tpa(FN.CONSIGNEE_LOCAL, 'consignee')}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE)}</div>
                <div className={'flex flex-1'}>
                  <div>
                    <div className={'ml-3'}>Address</div>
                    {!isSeoul &&
                      <div className={'ml-2'}>
                        <PrimaryButton label={'Search'} onClick={() => onSearchAddress('consignee')} style={{whiteSpace: 'pre-line'}}/>
                      </div>
                    }
                  </div>
                  <div className={'w-full'} style={!isSeoul ? {marginLeft: 5} : {marginLeft: 12}}>{!isSeoul ? _r(FN.CONSIGNEE_ADDR_LOCAL) : _r(FN.CONSIGNEE_ADDR)}</div>
                </div>
                {!isSeoul && <div className={'flex-1'} style={{marginTop: -5}}>{_r(FN.CONSIGNEE_ADDR)}</div>}
                <div className={'flex-1'}>{''}</div>
                {isSeoul && <div className={'flex-1'} style={{marginTop: 69}}>{_r(FN.CONSIGNEE_EMAIL)}</div>}
                {!isSeoul &&
                  <div className={'flex flex-1'} style={{marginTop: -4}}>
                    <div>{_r(FN.CONSIGNEE_POST)}</div>
                    {(carrierType === 'A' && isCourier) && <div className={'ml-2'}>{_r(FN.CUSTOMS_NO)}</div>}
                  </div>
                }
                <div className={'flex flex-1'}>
                  <div>{_r(FN.CONSIGNEE_PHONE1)}</div>
                  <div className={'ml-2'}>{_r(FN.CONSIGNEE_PHONE2)}</div>
                </div>
                {carrierType !== 'O' &&
                  <div className={'flex'}>
                    <div className={'mr-2'}>
                      <div style={{textAlign: 'center'}}>L</div>
                      <div>{_r(FN.VOL_LENGTH)}</div>
                    </div>
                    <div className={'mr-2'}>
                      <div style={{textAlign: 'center'}}>W</div>
                      <div>{_r(FN.VOL_WEIGHT)}</div>
                    </div>
                    <div>
                      <div style={{textAlign: 'center'}}>H</div>
                      <div>{_r(FN.VOL_HEIGHT)}</div>
                    </div>
                  </div>
                }
                {carrierType === 'O' &&
                  <div className={'flex'}>
                    <div className={'flex-1'}>{_r(FN.ENT_DATE)}</div>
                    <div className={'flex-1'}>{_r(FN.VISA_TYPE)}</div>
                    <div className={'flex-1'}>{_r(FN.TAPE)}</div>
                  </div>
                }
                <div className={'flex'}>
                  <div>{_r(FN.PACKAGE)}</div>
                  <div>{_r(FN.GROSS_WEIGHT_KG)}</div>
                  {carrierType !== 'O' && <div>{_r(FN.VOLUME_WEIGHT_KG)}</div>}
                  {carrierType === 'O' && <div>{_r(FN.CBM)}</div>}
                </div>
              </div>
              <div className={'flex-4'}>
                <div className={'flex'}>
                  <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                  <div className={'flex-1'}>{_r(FN.TOTAL_PRICE)}</div>
                </div>
                <div className={'flex'}>
                  {carrierType !== 'O' && <div className={'flex-1'}>{_r(FN.DISC_RATE)}</div>}
                  <div className={'flex-1'}>{_r(FN.PAID_BY)}</div>
                  {carrierType === 'O' && <div className={'flex-1'}>{''}</div>}
                </div>
                <div>
                  {ItemListForm.renderForm()}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

export default CourierHouseBLCustomerFormManager;
