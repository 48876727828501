/**
 * @flow
 */
import React from 'react';
import FormManager from '../lib/FormManager';
import type {FormField} from '../components/Form';
import type {ForwardingType} from './BLFormManager';

const FD_OEM = {
  // [name, serverName, label, type, options, required]
  FILING_NO: ['filingNo', 'filingNo', 'Filing No.', undefined, undefined, false],
  CARRIER_BKG_NO: ['bkgNo', 'bkgNo', 'Carrier Bkg. No.', undefined, undefined, false],
  MBL_NO: ['mblNo', 'mblNo', 'MB/L No.', undefined, undefined, false],
  HBL_NO: ['hblNo', 'hblNo', 'HB/L No.', undefined, undefined, false],
  MAWB_NO: ['mawbNo', 'mawbNo', 'MAWB No.', undefined, undefined, false],
  MASTER_NO: ['masterNo', 'masterNo', 'Master No.', undefined, undefined, false],
};

class BLTopSearchFormManager extends FormManager {
  forwardingType: ForwardingType;
  onEnter: (name: string, value: string) => void;
  isEditMode: boolean;
  constructor(type: ForwardingType = 'OEM') {
    super({
      prefix: `bl-top-search-form`,
      fields: [],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.forwardingType = type;
    const fields = this.getFields();
    this.fields = fields.map(i => ({...i, name: `${this.prefix}-${i.name}`}));
  }
  onRender = () => {
    return (
      <div className={'flex w-full'} style={{visibility: 'hidden'}}>
        <div className={'flex-1'}>{this.renderField(FD_OEM.FILING_NO[0])}</div>
      </div>
    );
    // if (this.isEditMode) {
    //   return (
    //     <div className={'flex w-full'} style={{visibility: 'hidden'}}>
    //       <div className={'flex-1'}>{this.renderField(FD_OEM.FILING_NO[0])}</div>
    //     </div>
    //   );
    // }
    // switch (this.forwardingType) {
    //   case 'OEM': return this.renderOEM();
    //   case 'OEH': return this.renderOEH();
    //   case 'AEM': return this.renderAEM();
    //   case 'AEH': return this.renderAEH();
    //   case 'OIM': return this.renderOIM();
    //   case 'OIH': return this.renderOIH();
    //   case 'AIM': return this.renderAIM();
    //   case 'AIH': return this.renderAIH();
    //   default:
    //     return null;
    // }
  };
  onValidate = (values) => {};
  getFields() {
    const fields: FormField[] = [];
    for (const key in FD_OEM) {
      if (FD_OEM.hasOwnProperty(key)) {
        const [name, serverName, label, type, options, required] = FD_OEM[key];
        fields.push({name, serverName, label, type, options, required, smallMargin: true});
      }
    }
    return fields;
  }
  renderOEM() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    _f(FD_OEM.MBL_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    _f(FD_OEM.CARRIER_BKG_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.FILING_NO[0])}</div>
        <div className={'flex-1'}>{_r(FD_OEM.MBL_NO[0])}</div>
        <div className={'flex-1'}>{_r(FD_OEM.CARRIER_BKG_NO[0])}</div>
        <div className={'flex-1'} />
      </div>
    );
  }
  renderOEH() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.MASTER_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.MASTER_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
  renderAEM() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    _f(FD_OEM.MAWB_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.FILING_NO[0])}</div>
        <div className={'flex-1'}>{_r(FD_OEM.MAWB_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
  renderAEH() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.MASTER_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.MASTER_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
  renderOIM() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    _f(FD_OEM.MBL_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    _f(FD_OEM.CARRIER_BKG_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.FILING_NO[0])}</div>
        <div className={'flex-1'}>{_r(FD_OEM.MBL_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
  renderOIH() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.MASTER_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.MASTER_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
  renderAIM() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    _f(FD_OEM.MAWB_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.FILING_NO[0])}</div>
        <div className={'flex-1'}>{_r(FD_OEM.MAWB_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
  renderAIH() {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    _f(FD_OEM.MASTER_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FD_OEM.MASTER_NO[0])}</div>
        <div className={'flex-1'} />
        <div className={'flex-1'} />
        <div className={'flex-1'} />
      </div>
    );
  }
}

export default BLTopSearchFormManager;
