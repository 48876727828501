/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from '../media/coship_logo.png';

class ForwardingQuotationPrint extends React.Component {
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    return <QuotationPrint printData={printData} user={user}/>;
  }
}

const QuotationPrint = ({printData, user}) => {
  const {accountData, accountGridData = [], branchListData, bankInfoData, qtNo} = printData;
  if (!printData) {
    return null;
  }
  const [size, setSize] = React.useState(25);
  const [arrARDetailData, setArrARDetailData] = React.useState([]);
  const arrLength = arrARDetailData?.length;
  const ref = React.useRef(null);

  React.useEffect(() => {
    setArrARDetailData(util.chunk(accountGridData, size));
  }, [accountGridData]);

  React.useLayoutEffect(() => {
    let containerHeight = 450;
    let count = 0;
    const commodityHeight = document.getElementById('commodity')?.clientHeight;
    containerHeight = containerHeight - commodityHeight + 21;
    if (arrARDetailData?.length > 0) {
      arrARDetailData[0].forEach((item, index) => {
        const rowHeight = document.getElementById(`accountType${index}`).clientHeight;
        if (containerHeight < rowHeight && count === 0) {
          setSize(index - 1);
          setArrARDetailData(util.chunk(accountGridData, index - 1));
          count += 1;
        } else {
          containerHeight -= rowHeight;
        }
      })
    }
  }, []);

  const renderHeader = (accountData, user) => {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr style={{height: '20px'}}>
                <td className={'no-border-all right invoice_label'}><h1>QUOTATION</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_right'}>
                  <h3 className={'invoice_label'}>QUOTATION No. : {qtNo}</h3>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '15px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all'} style={{width: '10%'}}>TO :</td>
                <td className={'no-border-all'} style={{width: '90%', whiteSpace: 'pre-line'}}>{accountData.partner_name}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>DATE</td>
                <td className={'left'}>{accountData.qt_date}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>TERMS</td>
                <td className={'left'}>{accountData.term ?? ''}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>UNTIL VALID</td>
                <td className={'left'}>{accountData.dueDate ? accountData.dueDate : accountData.valid_until}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };
  const renderHeaderSub = (accountData) => {
    return (
      <table className={'print-table'} style={{width: '100%', height: '80px'}}>
        <tbody>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('VOLUME', accountData.volume)}
          {renderInvoiceInfoLine('P.O.L', accountData.pol)}
        </tr>
        <tr className={'flex'}>
          {renderInvoiceInfoLine('DESTINATION', accountData.destination)}
          {renderInvoiceInfoLine('P.O.D', accountData.pod)}
        </tr>
        </tbody>
      </table>
    );
  }
  const renderInvoiceInfoLine = (label, value) => {
    return (
      <td className={'no-border-all flex-1'}>
        <div className={'flex'}>
          <div className={'flex-1'}>{label}</div>
          <div className={'mx-4'}>:</div>
          <div className={'flex-2'} style={{whiteSpace: 'pre-line'}}>{value}</div>
        </div>
      </td>
    );
  };
  const renderInvoiceItemsTotal = (label, value1, value2) => {
    return (
      <tr>
        <td className={'no-border-all right invoice_label'}>{label}</td>
        <td className={'no-border-all right invoice_label'}>{value1}</td>
        <td className={'no-border-all right invoice_label'}>{value2}</td>
      </tr>
    );
  };
  const renderInvoiceMemoInfo = (label, value) => {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-wrap', minHeight: '25px', fontSize: '0.8em', paddingTop: '3px', paddingBottom: '3px'}}>{value}</div></td>
      </tr>
    );
  };
  const renderInvoiceBankInfo = (label, value) => {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-wrap', minHeight: '230px', fontSize: '0.8em', paddingTop: '3px', paddingBottom: '3px'}}>{value}</div></td>
      </tr>
    );
  };
  const renderFootInfo = (branchLists) => {
    return branchLists?.map((item, idx) => {
      return (
        <td key={idx} className={'no-border-all flex-1 f-15'}>
          {item.pic}<br/>
          {item.address}<br/>
          TEL: {item.phone} {item.fax && `FAX: ${item.fax}`}<br/>
          EMAIL: {item.email}
        </td>
      );
    });
  };

  return (
    <div style={{color: 'black'}}>
      {arrARDetailData?.map((items, index) => {
        const itemsSize = items.length;
        const emptyArr = [];
        for(let i=0; i<size-itemsSize; i++) {
          emptyArr.push({empty: '\u00a0'});
        }
        let totalAmount = 0;
        return (
          <div className={'page-break'} key={index}>
            {renderHeader(accountData, user)}
            <hr />
            {renderHeaderSub(accountData)}
            <table className={'print-table mt-20'} style={{width: '100%', height: '450px'}}>
              <tbody>
                <tr style={{height: 25}}>
                  <td className={'no-border-lr left invoice_label fb-15'} width={'25%'}>DESCRIPTION OF CHARGES</td>
                  <td className={'no-border-lr left invoice_label fb-15'} width={'15%'}>REMARK</td>
                  <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>QTY</td>
                  <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>UNIT</td>
                  <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>RATE</td>
                  <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>CURRENCY</td>
                  <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>CUR.RATE</td>
                  <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>AMOUNT</td>
                </tr>
                <tr><td colSpan={8} className={'no-border-all'} height={8}>{''}</td></tr>
                {items?.map((item, index) => {
                  totalAmount += item['amount'];
                  return (
                    <tr id={`accountType${index}`} ref={ref} key={index}>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1}}>{item['description']}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1}}>{item['remark']}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['volume']}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['unit']}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{util.formatCurrency(item['rate'])}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['currency']}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{item['cur_rate']}</td>
                      <td className={'no-border-all'} height={'20'} style={{paddingTop: '1px', lineHeight: 1, textAlign: 'right'}}>{util.formatCurrency(item['amount'])}</td>
                    </tr>
                  );
                })}
                {size > itemsSize &&
                  emptyArr?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan={8} className={'no-border-all'} width={'100%'} height={'20'} style={{paddingTop: '1px', lineHeight: 1}}>{item.empty}</td>
                      </tr>
                    );
                  })
                }
                {arrLength-1 !== index && (<tr>
                  <td colSpan={8} className={'no-border-all center'}><b>CONTINUED</b></td>
                </tr>)}
              </tbody>
            </table>
            <hr />
            {arrLength-1 !== index && (<table className={'print-table mt-20'} style={{width: '100%', height: '50px'}}>
              <tbody>
              <tr>
                <td className={'no-border-all'}>{'\u00a0'}</td>
              </tr>
              </tbody>
            </table>)}
            {arrLength-1 === index && (<table className={'print-table mt-20'} style={{width: '100%', height: '30px'}}>
              <tbody>
              {renderInvoiceItemsTotal(`TOTAL IN ${accountData.currency}`, '', util.formatCurrency(util.toFloat(totalAmount)))}
              </tbody>
            </table>)}
            <table className={'print-table mt-20'} style={{width: '100%'}}>
              <tbody>
              {renderInvoiceMemoInfo('MEMO', accountData.memo)}
              {renderInvoiceBankInfo('BANK INFO', bankInfoData?.memo)}
              </tbody>
            </table>
            <hr className={'line'} />
            <table className={'print-table mt-20'} style={{width: '100%'}}>
              <tbody>
              <tr className={'flex'}>
                {renderFootInfo(branchListData)}
              </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export function useForwardingQuotationPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountFormRef = React.useRef();
  const accountGridDataRef = React.useRef([]);
  const branchListRef = React.useRef();
  const bankInfoRef = React.useRef();
  const printRef = React.useRef();
  const qtNoRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: qtNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <ForwardingQuotationPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountFormRef.current.getValues(),
        accountGridData: accountGridDataRef.current,
        branchListData: branchListRef.current,
        bankInfoData: bankInfoRef.current,
        qtNo: qtNoRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (form, items, branchLists, bankInfo, no) => {
      accountFormRef.current = form;
      accountGridDataRef.current = items;
      branchListRef.current = branchLists;
      bankInfoRef.current = bankInfo;
      qtNoRef.current = no
      modal.open();
    },
    print,
  };
}

export default ForwardingQuotationPrint;
