/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import {buyingSellingTypeOptions} from '../field-defs/bl';
import {DefaultButton} from '../../components/buttons';
import React from "react";
import {AccountTypeSearchInput} from '../../components/SearchInput';

const unitOptions = [
  { value: 'CNTR', label: 'CNTR'},
  { value: 'B/L', label: 'B/L'},
  { value: 'CBM', label: 'CBM'},
  { value: 'DAYS', label: 'DAYS'},
  { value: 'M/T', label: 'M/T'},
  { value: 'R/T', label: 'R/T'},
  { value: 'TRUCK', label: 'TRUCK'},
  { value: 'HRS', label: 'HRS'},
  { value: 'KG', label: 'KG'},
  { value: 'WAGON', label: 'WAGON'},
  { value: 'CFT', label: 'CFT'},
  { value: 'SHIP', label: 'SHIP'},
];

class AccountBLRateGridFormManager extends FormManager {
  onSave;
  constructor() {
    super({
      prefix: `account-bl-rate-grid-form`,
      fields: [
        {name: 'id', serverName: 'id', label: ''},
        {name: 'atypeId', serverName: 'atypeId', label: ''},
        {name: 'accountType', serverName: 'accountType', label: ''},
        {name: 'pc', serverName: 'pc', label: '', options: buyingSellingTypeOptions, noDefOption: true},
        {name: 'unit', serverName: 'unit', label: '', options: unitOptions, noDefOption: true},
        {name: 'rate', serverName: 'rate', label: ''},
        {name: 'volume', serverName: 'volume', label: '', defaultValue: 1},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atypeId`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;
    const pc = _v('pc');
    React.useEffect(() => {
      if(!pc) {
        _c('pc', 'CC');
      } else {
        _c('pc', pc);
      }
    }, [pc]);
    return (
      <>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Account Type</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>P/C</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Unit</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Rate</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Volume</span></div>
          <div className={'ml-20'} style={{visibility: 'hidden', height: 10}}><DefaultButton label={'Add'} onClick={() => {}} noMargin /></div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-8'}>{_at('accountType')}</div>
          <div className={'flex-1 mr-8'}>{_r('pc')}</div>
          <div className={'flex-1 mr-8'}>{_r('unit')}</div>
          <div className={'flex-1 mr-8'}>{_r('rate')}</div>
          <div className={'flex-1 mr-8'}>{_r('volume')}</div>
          <div className={'ml-20'}><DefaultButton label={'Add'} onClick={this.onSave} noMargin /></div>
        </div>
      </>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

export default AccountBLRateGridFormManager;
