/**
 * @flow
 */
import * as React from 'react';
import {api, util} from "../../../../services/service";
import {TabButton} from "../../CommonStyle";
import {PrimaryButton} from "../../../../components/buttons";
import MovingBLListSearchFormManager from "../../../../forms/newCoship/moving/list/MovingBLListSearchFormManager";
import FilterPane from "../../../../components/FilterPane";
import useMovingHouseBLListGridView from "../../../../grids/newCoship/moving/list/useMovingHouseBLListGridView";
import useMovingMasterBLListGridView from "../../../../grids/newCoship/moving/list/useMovingMasterBLListGridView";
import useMovingInventoryBLListGridView from "../../../../grids/newCoship/moving/list/useMovingInventoryBLListGridView";
import useMovingUpcomingBLListGridView from "../../../../grids/newCoship/moving/list/useMovingUpcomingBLListGridView";
import {useTopbar} from "../../../../redux/reducers/topbarReducer";

const TABS = [
  {index: 1, path: '/admin/moving/bllist/house'},
  {index: 2, path: '/admin/moving/bllist/master'},
  {index: 3, path: '/admin/moving/bllist/inventory'},
  {index: 4, path: '/admin/moving/bllist/upcoming'}
];

const searchForm = new MovingBLListSearchFormManager();

const MovingBLList = () => {
  useTopbar({label: 'Moving'}, {label: 'BL List'});
  const [clickedIndex, setClickedIndex] = React.useState<number>(1);
  const pathname = window.location.pathname;
  const {hblGrid, mblGrid, inventoryGrid, upcomingGrid} = useMovingHouseBLList();

  const onSearch = () => {
    const gridInfo = util.getGridInfo(hblGrid.props.name);
    const values = searchForm.getValues();
    const fromDate = values.fromDate ? util.toStartTS(values.fromDate) : undefined;
    const toDate = values.toDate ? util.toEndTS(values.toDate) : undefined;
    const flag = values?.flag?.length > 0;
    const kind = values.kind ? values.kind : undefined;
    const branchId = values.branchId ? values.branchId : undefined;
    const payload = {...api.getQueryListData(gridInfo), ...values, fromDate, toDate, flag, kind, branchId};
    let search;
    if (pathname === TABS[1].path) {
      search = mblGrid.query(payload);
    } else if (pathname === TABS[2].path) {
      search = inventoryGrid.query(payload);
    } else if (pathname === TABS[3].path) {
      search = upcomingGrid.query(payload);
    } else {
      search = hblGrid.query(payload);
    }
    return search;
  };

  searchForm.onSearch = onSearch;

  const onClear = () => {
    searchForm.clearValues();
  };

  const onClickTab = (tabIndex) => {
    setClickedIndex(tabIndex);
    util.nav(TABS[tabIndex-1].path);
  };

  React.useEffect(() => {
    if (pathname === TABS[1].path) {
      setClickedIndex(TABS[1].index);
    } else if (pathname === TABS[2].path) {
      setClickedIndex(TABS[2].index);
    } else if (pathname === TABS[3].path) {
      setClickedIndex(TABS[3].index);
    } else {
      setClickedIndex(TABS[0].index);
    }
    util.nav(pathname);
    searchForm.clickedIndex = clickedIndex;
  }, [clickedIndex]);

  return (
    <div className={'flex'}>
      <div>
        <FilterPane>
          {searchForm.renderForm()}
          <PrimaryButton label={'APPLY'} onClick={onSearch} noMargin className={'w-full mt-3'} />
          <PrimaryButton label={'CLEAR'} onClick={onClear} noMargin className={'w-full mt-2'} />
        </FilterPane>
      </div>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        <div className={'flex'} style={{marginLeft: -12}}>
          <TabButton style={{backgroundColor: clickedIndex === TABS[0].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[0].index)}>
            <div>HOUSE BL</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[1].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[1].index)}>
            <div>MASTER BL</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[2].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[2].index)}>
            <div>STORAGE</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[3].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[3].index)}>
            <div>UPCOMING</div>
          </TabButton>
        </div>
        <div style={{marginTop: -50}}>
          {clickedIndex === TABS[0].index && hblGrid.render()}
          {clickedIndex === TABS[1].index && mblGrid.render()}
          {clickedIndex === TABS[2].index && inventoryGrid.render()}
          {clickedIndex === TABS[3].index && upcomingGrid.render()}
        </div>
      </div>
    </div>
  );
};

function useMovingHouseBLList() {
  const hblGrid = useMovingHouseBLListGridView((action, data) => {
    if (action === 'edit') {
      util.nav(`/admin/moving/house/customer?id=${data.id}`);
    }
  });
  const mblGrid = useMovingMasterBLListGridView((action, data) => {
    if (action === 'edit') {
      util.nav(`/admin/moving/master/mbl?id=${data.id}`);
    }
  });
  const inventoryGrid = useMovingInventoryBLListGridView((action, data) => {
    if (action === 'edit') {
      util.nav(`/admin/moving/house/customer?id=${data.id}`);
    }
  });
  const upcomingGrid = useMovingUpcomingBLListGridView((action, data) => {
    if (action === 'edit') {
      util.nav(`/admin/moving/master/mbl?id=${data.id}`);
    }
  });
  return {hblGrid, mblGrid, inventoryGrid, upcomingGrid};
}

export default MovingBLList;
