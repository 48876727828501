/**
 * @flow
 */
import React from 'react';
import useCourierAccountListGridView from "../../../../grids/newCoship/courier/house/useCourierAccountListGridView";
import {useSelector} from "react-redux";
import useAccountPaymentModal from "../../../../modals/account/useAccountPaymentModal";

interface CourierHouseBLAccountListProps {
  isShowList: boolean;
  onSearch: any;
}

const CourierHouseBLAccountList = (props: CourierHouseBLAccountListProps) => {
  const {isShowList, onSearch} = props;
  const {grid, accountPaymentModal} = useCourierHouseBLAccountList(isShowList, onSearch);

  return (
    <div>
      {isShowList && grid.render()}
      {accountPaymentModal.render()}
    </div>
  );
};

function useCourierHouseBLAccountList(isShowList, onSearch) {
  const {accountList} = useSelector(state => state['newCoship']);
  const accountPaymentModal = useAccountPaymentModal(onSearch);
  const grid = useCourierAccountListGridView((action, data) => {
    if (action === 'commission') {
      const {account_id, inv_no: invoiceNo} = data;
      accountPaymentModal.open(account_id ?? 0, invoiceNo ?? '');
    }
  });

  React.useEffect(() => {
    isShowList && grid.setRows(accountList);
  }, [isShowList, accountList]);

  return {grid, accountPaymentModal};
}

export default CourierHouseBLAccountList;
