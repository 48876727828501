/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions} from '../forms/field-defs/bl';
import {defaultANTitle} from '../forms/print/v2/OIHANPrintForm';
import coshipLogo from "../media/coship_logo.png";

class AIHArrNoti extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return null;
    }
    const {
      titleLabel, shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, mblNo, mawbNo, subMblNo, amsNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime,
      polLabel, podLabel, fDestEta, delEta, freightLoc, subLocCode, storageDate, available, finalEtaDate, finalEtaTime,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      title, blNo, customerRef, showInvoice, showFreightTerm,
      emptyPickup, forwardAgent, partner,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
      invoice, invoiceBlankLines,
    } = printData;
    const {renderCell: _cell, renderCell4: _c4, renderItemCell: _l} = this;
    const markLines = mark?.split('\n').length ?? 0;
    const descLines = desc?.split('\n').length ?? 0;
    const maxLines = markLines > descLines ? markLines : descLines;
    return (
      <div style={{color: 'black', fontSize: 16, marginTop: '1cm'}}>
        <div className={'flex-column center'} style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
        <div className={'flex'}>
          <div className={'flex-1'}><img src={coshipLogo} /></div>
          <div className={'flex-7 flex-column center'} style={{marginLeft: '-130px'}}>
            <div>{address}</div>
            <div>Prepared by {pic} {util.formatDT(Date.now())}</div>
            <div>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
          </div>
        </div>
        <div className={'flex-column center'} style={{fontSize: 36, fontWeight: 'bold', marginTop: '30px'}}>{titleLabel}</div>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            {_cell('SHIPPER', shipperAddr, {rowSpan: 3, width: '50%'})}
            {_cell('MASTER B/L NO.', mblNo ?? mawbNo, {width: '50%'}, 'HOUSE B/L NO.', blNo, true)}
          </tr>
          <tr>
            {_cell('FILING NO.', filingNo, {width: '50%'}, 'CUSTOMER REF. NO.', customerRef, true)}
          </tr>
          <tr>
            {_cell('AMS B/L NO.', amsNo??'\u00a0', {width: '50%'}, 'SUB B/L NO.', subMblNo??'\u00a0', true)}
          </tr>
          <tr>
            {_cell('CONSIGNEE', consigneeAddr, {rowSpan: 3, width: '50%'})}
            {_cell('FLIGHT NO.', flightNo)}
          </tr>
          <tr>
            {_cell('DEPARTURE', polFull, {width: '50%'}, 'ETD', util.formatD(flightDate) + ' ' + flightTime, false)}
          </tr>
          <tr>
            {_cell('FIRST PORT OF ENTRY', porFull, {width: '50%'}, 'ETA', '\u00a0', false)}
          </tr>
          <tr>
            {_cell('NOTIFY PARTY', notifyAddr, {rowSpan: 3, width: '50%'})}
            {_cell('DESTINATION PORT', podFull??'\u00a0', {width: '50%'}, 'ETA', util.formatD(arrivalDate) + ' ' + arrivalTime, false)}
          </tr>
          <tr>
            {_cell('FINAL DESTINATION', final??'\u00a0', {width: '50%'}, 'ETA', `${finalEtaDate??'\u00a0'} ${finalEtaTime??'\u00a0'}`, false)}
          </tr>
          <tr>
            {_cell('I.T. NO. & PLACE', (itNo || itLoc) ? `${itNo??''} / ${itLoc??''}` : '\u00a0', {width: '50%'}, 'I.T. DATE', itDate??'\u00a0', false)}
          </tr>
          <tr>
            {_cell('CUSTOMS BROKER', cBrokerAddr, {rowSpan: 3, width: '50%'})}
            {_cell('FREIGHT LOCATION', freightLoc)}
          </tr>
          <tr>
            {_cell('SUB LOCATION CODE', subLocCode)}
          </tr>
          <tr>
            {_cell('STORAGE START', storageDate??'', {width: '50%'}, 'AVAILABLE DATE', available??'', true)}
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td align={'center'}><div className={'label f-13'}>MARKS & NOS</div></td>
            <td align={'center'}><div className={'label f-13'}>NO. OF PKGS</div></td>
            <td align={'center'}><div className={'label f-13'}>DESCRIPTION OF PACKAGES AND GOODS</div></td>
            <td align={'center'}><div className={'label f-13'}>GROSS WEIGHT</div></td>
            <td align={'center'}><div className={'label f-13'}>VOLUME WEIGHT</div></td>
          </tr>
          <tr>
            <td className={'no-border-b'}><div className={'value'} style={{whiteSpace: 'pre-line'}}>{mark}</div></td>
            <td className={'no-border-b'}><div className={'value'}>{packageCount} {packageType}</div></td>
            <td className={'no-border-b'}><div className={'value'} style={{whiteSpace: 'pre-line'}}>{desc}</div></td>
            <td className={'no-border-b'} align={'right'}><div className={'value'}>{util.weightForPrint(grossWeightKg??0, 'KG')}</div></td>
            <td className={'no-border-b'} align={'right'}><div className={'value'}>{util.weightForPrint(chargeableWeightKg??0, 'KG')}</div></td>
          </tr>
          {this.renderBlankLine(15 - (maxLines ?? 0))}
          <tr>
            {_l()}
            {_l()}
            {_l()}
            {_l(showFreightTerm && <div style={{width: 100, whiteSpace: 'nowrap'}}>FREIGHT {freightLabel}</div>)}
            {_l()}
          </tr>
          {this.renderBlankLine(1)}
          <tr>
            {_l()}
            {_l()}
            {_l()}
            {_l()}
            {_l()}
          </tr>
          {this.renderBlankLine(1)}
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tr style={{height: '7em'}}>
            <td className={'label f-13'} style={{width: 120}}>REMARK</td>
            <td className={'label f-13'} style={{whiteSpace: 'pre-line'}}>{pubRemark}</td>
          </tr>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td width={'50%'} rowSpan={100}>
              <div className={'label f-13'}>
                TO ALL CUSTOMERS:<br/>
                1. PLEASE MAKE A CHECK PAYABLE TO "{company}".<br/>
                2. WE ACCEPT CUSTOM HOUSE BROKER'S CHECK ONLY. (CASHIER'S CHECK AND MONEY ORDER ARE ACCEPTED)<br/>
                3. PERSONAL CHECKS OR CUSTOMER'S CHECKS ARE NOT ACCEPTABLE.<br/>
                4. SHIPMENT WILL BE RELEASED AGAINST PAYMENT AND PROPERLY ENDORSED ORIGINAL BILL OF LADING.<br/>
                5. CONSIGNEE MUST CLEAR CUSTOMS AND CONFIRM THE FREIGHT RELEASE WITH PIER/CFS PRIOR TO CARGO PICK-UP.<br/>
                6. CARGO RELEASE WILL BE EFFECTED 48 HOURS AFTER RECEIPT OF YOUR CHECK AND/OR O.B/L<br/>
              </div>
            </td>
            {showInvoice && (
              <td width={'50%'}>
                <div className={'flex between'}>
                  <div className={'value'}>Invoice No : {invoice.invNo}</div>
                  <div className={'value'}>Due Date : {util.formatD(invoice.dueDate)}</div>
                </div>
              </td>
            )}
          </tr>
          {showInvoice && (
            <>
              <tr>
                {this.renderLowerItemCell('DESCRIPTION OF CHARGES', 'AMOUNT', true)}
              </tr>
              {invoice?.items?.map(({item, price}, index) => {
                return (
                  <tr key={index}>
                    {this.renderLowerItemCell(item, util.formatCurrency(price), false, true)}
                  </tr>
                );
              })}
              {invoiceBlankLines.map((_, index) => {
                return (
                  <tr key={index}>
                    {this.renderLowerItemCell('\u00a0', '\u00a0', false, true)}
                  </tr>
                );
              })}
              <tr>
                {this.renderLowerItemTotalCell('TOTAL DUE', util.formatCurrency(invoice.totalAmount))}
              </tr>
              <tr>
                {this.renderLowerItemTotalCell(`PLEASE PAY THIS AMOUNT ${invoice.currency??''}`, util.formatCurrency(invoice.totalAmount))}
              </tr>
            </>
          )}
          </tbody>
        </table>
      </div>
    );
  }
  renderCell(label, value, tdProps, extraLabel, extraValue, extraHalf) {
    if (extraLabel) {
      return (
        <td width={'50%'}>
          <div className={'flex between'}>
            <div className={extraHalf ? 'flex-1' : 'flex-2'}>
              <div className={'label f-13'}>{label}</div>
              <div className={'value'} style={{whiteSpace: 'pre-line'}}>{value ? value : '\u00a0'}</div>
            </div>
            <div className={'flex-1'}>
              <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{extraLabel}</div>
              <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{extraValue ? extraValue : '\u00a0'}</div>
            </div>
          </div>
        </td>
      );
    } else {
      return (
        <td {...tdProps}>
          <div className={'label f-13'}>{label}</div>
          <div className={'value'} style={{whiteSpace: 'pre-line'}}>{value ? value : '\u00a0'}</div>
        </td>
      );
    }
  }
  renderCell4(...args) {
    return (
      <td width={'50%'}>
        <div className={'flex between'}>
          <div className={'flex-1'}>
            <div className={'label f-13'}>{args[0]}</div>
            <div className={'value'}>{args[1]}</div>
          </div>
          <div className={'flex-1'}>
            <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{args[2]}</div>
            <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{args[3]}</div>
          </div>
          <div className={'flex-1'}>
            <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{args[4]}</div>
            <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{args[5]}</div>
          </div>
          <div className={'flex-1'}>
            <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{args[6]}</div>
            <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{args[7]}</div>
          </div>
        </div>
      </td>
    );
  }
  renderBlankLine(count) {
    const res = [];
    for (let i = 0; i < count; ++i) {
      res.push(
        <tr key={i}>
          <td className={'no-border-b no-border-t'} style={{height: 20}}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
        </tr>
      );
    }
    return res;
  }
  renderItemCell(value = ' ', align = undefined) {
    return <td className={'no-border-b no-border-t'} align={align}><div className={'value'}>{value}</div></td>;
  }
  renderLowerItemCell(label1, label2, isHeading, noBottomBorder) {
    return (
      <td className={noBottomBorder ? 'no-border-b no-border-t' : ''}>
        <div className={'flex'}>
          <div className={isHeading ? 'flex-2 flex center' : 'flex-2 flex'}>
            <div className={'label f-13'}>{label1}</div>
          </div>
          <div className={'flex-1'} style={{height: '100%'}}>
            <div className={isHeading ? 'label f-13 pl-12 w-full flex center' : `label pl-12 w-full flex right`} style={{borderLeft: '1px solid #000'}}>{label2}</div>
          </div>
        </div>
      </td>
    );
  }
  renderLowerItemTotalCell(label1, label2) {
    return (
      <td>
        <div className={'flex'}>
          <div className={'flex-2 flex center'}>
            <div className={'value'}>{label1}</div>
          </div>
          <div className={'flex-1'}>
            <div className={`value pl-12 w-full flex right`} style={{borderLeft: '1px solid #000'}}>{label2}</div>
          </div>
        </div>
      </td>
    );
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers, serverData, invoice} = blData;
    let {title, titleEtc, blNo, customerRef, showInvoice, showFreightTerm} = printFormData;
    const titleLabel = (title ? title : titleEtc) ?? defaultANTitle;
    const {shipperAddr = '', consigneeAddr = '', forwardingAgentAddr = '', notifyAddr = '', cBrokerAddr = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = '', exportRefNo = '', mblNo, mawbNo, subMblNo, amsNo} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime} = jvessel;
    const {polLabel, podLabel, fDestEta, delEta, freightLoc, subLocCode, storageDate, available} = jroute;
    const {bladdress = {}, cutoff = {}, route = {}} = serverData;
    const {emptyPickup = '', forwardAgent = '', partner = ''} = bladdress;
    const {doc, pickupDate, portOpen, rail, vgm} = cutoff;
    const {delivery, final, podFull, polFull, porFull} = route;
    let {freight, orgBlType, svcTerm1, svcTerm2, package: packageCount, packageType, grossWeightKg, chargeableWeightKg} = jshipment;
    const {description: desc, mark, itNo, date: itDate, itLoc, pubRemark} = jmark ?? {};
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.labelFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.labelFromOptions(svcTerm2, svcTypeOptions);
    const blankLines = [];
    const blankCount = 23 - containers.length;
    for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    const invoiceBlankLines = [];
    const invoiceBlankCount = 5 - (invoice.items?.length ?? 0);
    for (let i = 0; i < invoiceBlankCount; ++i) invoiceBlankLines.push(i);
    return {
      titleLabel, shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, mblNo, mawbNo, subMblNo, amsNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime,
      polLabel, podLabel, fDestEta, delEta, freightLoc, subLocCode, storageDate, available, finalEtaDate, finalEtaTime,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      title, blNo, customerRef, showInvoice, showFreightTerm,
      emptyPickup, forwardAgent, partner,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
      invoice, invoiceBlankLines,
    };
  }
}

export default AIHArrNoti;
