/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {DefaultButton} from '../../components/buttons';
import {apiAccount, apiSettings, util} from "../../services/service";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class MasterCommissionFormManager extends FormManager {
  grid;
  constructor() {
    super({
      prefix: `master-commission-edit-item-form`,
      fields: [
        {name: 'customerId', serverName: 'customer_id', label: ''},
        {name: 'air', serverName: 'air', label: ''},
        {name: 'ocean', serverName: 'ocean', label: ''},
        {name: 'ups', serverName: 'ups', label: ''},
        {name: 'capost', serverName: 'capost', label: ''},
        {name: 'pickup', serverName: 'pickup', label: ''},
        {name: 'remark', serverName: 'remark', label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  getGridData() {
    let grid = this.grid;
    const rows = [];
    if (grid.apiRef && grid.apiRef.current) {
      grid.apiRef.current.forEachNode(node => rows.push(node.data));
    }
    return rows;
  }

  setGridData(gridData) {
    gridData = gridData.map((row) => {
      console.log(" ***** row: ", row.rate, row.volume, row.dcType);

      return {...row};
    });

    this.grid.setRows(gridData);
  }

  onRender = () => {
    const [editMode, setEditMode] = React.useState('edit');
    const [customerId, setCustomerId] = React.useState(0);
    const grid = useCommissionItemGrid((e) => {
      console.log(e);
      setEditMode('edit');
      this.setValues(e.data);
      setCustomerId(e.data.customer_id);
    });

    this.grid = grid;
    const {_r} = this.getRenderProps();

    const onQuery = async () => {
      const {data} = await apiSettings.commissionList();
      grid.setRows(data);
    }

    const onSave = async () => {
      const values = this.getValues(undefined, true);
      if (values) {
        let {customerId, air, ocean, ups, capost, pickup, remark} = values;
        // save data to db
        const params = {
          customerId,
          air: util.toFloat(air),
          ocean: util.toFloat(ocean),
          ups: util.toFloat(ups),
          capost: util.toFloat(capost),
          pickup: util.toFloat(pickup),
          remark
        };
        let res = await apiSettings.setCommission(params);
        if (res) {
          this.clearValues();
          onQuery();
        }
      }
    };

    const title = 'Commission';
    React.useEffect(() => {
      onQuery();
    }, []);
    return (
      <>
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
            </div>
            {grid.render()}
            <div className={'flex w-full'}>
              <div className={'flex-1 mr-8'}>
                <div>Air</div>
                <div>{_r('air')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>Ocean</div>
                <div>{_r('ocean')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>UPS</div>
                <div>{_r('ups')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>Post</div>
                <div>{_r('capost')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>Pick up</div>
                <div>{_r('pickup')}</div>
              </div>
              <div className={'flex-3'}>
                <div>Remark</div>
                <div>{_r('remark')}</div>
              </div>
              <div className={'mt-20 ml-20'}>
                <DefaultButton label={'edit'} onClick={onSave} noMargin />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      </>
    );
  };

  onValidate = (values) => {
    let {air, ocean, ups, capost, pickup} = values;
    if(air == 0) air = air.toString();
    if(ocean == 0) ocean = ocean.toString();
    if(ups == 0) ups = ups.toString();
    if(capost == 0) capost = capost.toString();
    if(pickup == 0) pickup = pickup.toString();
    if (!(air && ocean && ups && capost && pickup)) {
      util.showWarning('Please enter all required fields.');
      return null;
    }
    return values;
  };
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function useCommissionItemGrid(onCellClicked) {
  function getColumns() {
    return [
      {field: 'customer_id', hide: true},
      {field: 'customer_name'},
      {field: 'air', headerName: 'Air', valueFormatter: util.twoDecimalPlaceFormatter, flex: 1},
      {field: 'ocean', headerName: 'Ocean', valueFormatter: util.twoDecimalPlaceFormatter, flex: 1},
      {field: 'ups', headerName: 'UPS', valueFormatter: util.twoDecimalPlaceFormatter, flex: 1},
      {field: 'capost', headerName: 'Post', valueFormatter: util.twoDecimalPlaceFormatter, flex: 1},
      {field: 'pickup', headerName: 'Pick up', valueFormatter: util.twoDecimalPlaceFormatter, flex: 1},
      {field: 'remark', headerName: 'Remark', flex: 1},
      {field: 'udate', headerName: 'Update Date', valueFormatter: util.dateFormatter},
      {field: 'staff_name', headerName: 'Staff Name'},
    ];
  }
  return useSimpleGrid({
    columns: getColumns(),
    height: 200,
    className: 'pb-20',
    actions: [],
    actionWidth: 70,
    agGridProps: {
      onCellClicked(e) {
        // when action cell clicked, ignore it
        onCellClicked(e);
      }
        // onRowClicked(e: RowClickedEvent) {
        //   enterEditMode(e.data);
    }
  });
}

export default MasterCommissionFormManager;