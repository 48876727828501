/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {api} from '../../../../services/service';
import {FD, FN, kindOptions} from '../../../field-defs/newCoship';
import {useUser} from "../../../../redux/reducers/userReducer";
import {KIND} from "../../courier/list/CourierBLListSearchFormManager";

class MovingSettingSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor() {
    super({
      prefix: `moving-setting-search-form`,
      fields: [FD.KIND, FD.BRANCH_ID],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const user = useUser();
    const kind = _v(FN.KIND);
    _f(FN.BRANCH_ID).options = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).label = '';
    _f(FN.KIND).label = '';
    _f(FN.KIND).options = [{value: '', label: 'ALL'}, ...kindOptions];

    // React.useEffect(() => {
    //   if(!_v(FN.BRANCH_ID)) {
    //     _c(FN.BRANCH_ID, user.branch_id);
    //   }
    // }, [user]);

    React.useEffect(() => {
      _c(KIND, 'M');
    }, []);

    React.useEffect(() => {
      if (kind !== undefined) {
        this.onSearch();
      }
    }, [kind]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          {/*<div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>*/}
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default MovingSettingSearchFormManager;
