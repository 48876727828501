/**
 * @flow
 */
import React from 'react';
import {apiAccount, util} from "../../services/service";
import {useSimpleGridModal} from "../../components/SimpleModal";
import {GridApi} from "ag-grid-community";


export default function useVendorDetailAllModal() {
  const [id, setId] = React.useState();

  async function onQuery(id) {
    apiAccount.accountVDDataDetail(id).then((res) => {
      const rows = buildRows(res.data);
      modal.setRows(rows);
    });
  }

  const download = () => {
    if (modal.grid.rows?.length > 0) {
      const api: GridApi = modal.grid.apiRef.current;
      api.exportDataAsCsv({
        fileName: `VD DETAIL ALL.csv`,
      });
    }
  }

  const getColumns = () => {
    return [
      {field: 'post_date', headerName: 'DATE', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'tracking_no', headerName: 'TRACKING NO.', valueFormatter: postalTextFormatter, flex: 1},
      {field: 'customer', headerName: 'CUSTOMER', flex: 1},
      {field: 'weight', headerName: 'WEIGHT', flex: 1},
      {field: 'amount', headerName: 'AMOUNT', valueFormatter: util.currencyFormatter, flex: 1},
    ];
  };

  const modal = useSimpleGridModal({
    title: 'VD DETAIL ALL',
    centered: true,
    width: 920,
    buttons: [{label: 'Export Excel', color: 'primary', onClick: download}],
    gridProps: {
      columns: getColumns(),
      actionWidth: 70,
      rows: [],
      height: 250,
      className: 'mb-20',
    },
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      if (id) {
        onQuery({id}).catch();
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (id) => {
      setId(id);
      modal.open();
    },
  };
}

function postalTextFormatter(p) {
  return p.value.toString().replaceAll('"', '').replace('=', '');
}

function buildRows(data: Object[]) {
  return data.map((row) => ({
    ...row,
    post_date: row['post_date'] ? util.formatD(row['post_date']) : '',
    tracking_no: row['tracking_no'] ? `="${row['tracking_no']}"` : '',
  }));
}
