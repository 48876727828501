/**
 * @flow
 */
import React from 'react';
import {Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {cargoTypeOptions, kindFormatter, kindOptions, packageTypeOptions} from '../field-defs/bl';
import {labelType, standaloneLabel} from '../helper';
import {TradePartnerSearchInput} from '../../components/SearchInput';
import _c from 'classnames';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {api, apiBL, util} from '../../services/service';
import {useUser} from '../../redux/reducers/userReducer';
import {DangerButton, DefaultButton} from '../../components/buttons';
import useAccountPaymentModal from '../../modals/account/useAccountPaymentModal';
import useBLListModal from '../../modals/bl/useBLListModal';
import {loadBoxList} from '../../modals/bl/useBLBoxModal';
import {SK_BRANCH} from "../account/AccountListSearchFormManager";
import {useManifestPreviewModal} from "../../print-layouts/ManifestPrint";
import {useManifestPreviewModal2} from "../../print-layouts/ManifestPrint2";
import {useManifestPreviewModal3} from "../../print-layouts/ManifestPrint3";

export const woTypeOptions = [
  {value: 'PU', label: 'Pickup'}, // O
  {value: 'DL', label: 'Delivery'}, // I
  {value: 'RP', label: 'Request Props'}, // O
  {value: 'RN', label: 'Return'}, // O
  {value: 'EP', label: 'Empty Pickup'}, // O
  {value: 'FP', label: 'Freight Pickup'}, // O
  {value: 'LR', label: 'Loaded Return'}, // O
  {value: 'BD', label: 'Box Delivery'}, // O
  {value: 'DM', label: 'Domestic'}, // O
];

const FN = {
  ID: 'id',
  KIND: 'kind',
  BRANCH_ID: 'branchId',
  FILING_NO: 'filingNo',
  BL_NO: 'blNo',
  WO_TYPE: 'woType',
  BILL_TO: 'billTo',
  BILL_TO_ID: 'billToId',
  BILL_TO_ADDR: 'billToAddr',
  BILL_TO_CONTACT: 'billToContact',
  BILL_TO_PHONE: 'billToPhone',
  TRUCKER: 'trucker',
  TRUCKER_ID: 'truckerId',
  TRUCKER_ADDR: 'truckerAddr',
  TRUCKER_CONTACT: 'truckerContact',
  TRUCKER_PHONE: 'truckerPhone',
  CUSTOMER: 'customer',
  CUSTOMER_ID: 'customerId',
  CUSTOMER_ADDR: 'customerAddr',
  CUSTOMER_CONTACT: 'customerContact',
  CUSTOMER_PHONE: 'customerPhone',

  CARRIER: 'carrier',
  VSL_VOY: 'vslVoy',
  POL: 'pol',
  ETD: 'etd',
  POD: 'pod',
  ETA: 'eta',

  WORKPLACE: 'workplace',
  WORKPLACE_ID: 'workplaceId',
  ADDRESS: 'address',
  PIC: 'pic',
  PHONE: 'phone',
  PHONE2: 'workplacePhone2',
  CITY: 'city',
  WORK_DATE: 'work_date',
  WORK_TIME: 'work_time',
  REF_NO: 'ref_no',

  CARGO_TYPE: 'cargoType',
  COMMODITY: 'commodity',
  PACKAGE: 'package',
  PACKAGE_TYPE: 'packageType',
  GROSS_WEIGHT_KG: 'grossWeightKg',
  MEASUREMENT_CBM: 'cbm',
  TAPE: 'tape',
  CONTAINER_SUMMARY: 'containerSummary',
  CONTAINER_GRID_DATA: 'containerGridData',
  EXPORT_REF_NO: 'exportRefNo',

  REMARK: 'remark',
  TRUCKING: 'trucking',
  CUSTOMS: 'customs',
  WAREHOUSE_LOADING: 'warehouseLoading',
};

const SN = FN;

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class WorkOrderFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  onSave;
  onSearch;
  grid;
  blGrid;
  woId;
  blId;
  portType;
  workType;
  consigneeId;
  consigneeLocal;
  consigneeAddrLocal;
  remark;
  workAssigned = [];
  onSearchBLList;
  constructor() {
    super({
      prefix: 'work-order-form',
      fields: [
        {name: FN.ID, serverName: FN.ID, label: '',},
        {name: FN.KIND, serverName: FN.KIND, label: 'Service', smallLabel: true, smallMargin: true, options: kindOptions, noDefOption: true},
        {name: FN.BRANCH_ID, serverName: FN.BRANCH_ID, label: 'Branch', options:'', smallLabel: true, noDefOption: true},
        {name: FN.FILING_NO, serverName: FN.FILING_NO, label: 'Filing No.',},
        {name: FN.BL_NO, serverName: FN.BL_NO, label: 'B/L No.',},
        {name: FN.WO_TYPE, serverName: FN.WO_TYPE, label: 'W/O Type', options: woTypeOptions, noDefOption: true},

        {name: FN.BILL_TO, serverName: FN.BILL_TO, label: '', smallMargin: true, vertical: true},
        {name: FN.BILL_TO_ID, serverName: FN.BILL_TO_ID, label: '',},
        {name: FN.BILL_TO_ADDR, serverName: FN.BILL_TO_ADDR, label: '', type: 'textarea', smallMargin: true},
        {name: FN.BILL_TO_CONTACT, serverName: FN.BILL_TO_CONTACT, label: '',},
        {name: FN.BILL_TO_PHONE, serverName: FN.BILL_TO_PHONE, label: '',},
        {name: FN.TRUCKER, serverName: FN.TRUCKER, label: '', smallMargin: true, vertical: true},
        {name: FN.TRUCKER_ID, serverName: FN.TRUCKER_ID, label: '',},
        {name: FN.TRUCKER_ADDR, serverName: FN.TRUCKER_ADDR, label: '', type: 'textarea', smallMargin: true},
        {name: FN.TRUCKER_CONTACT, serverName: FN.TRUCKER_CONTACT, label: '',},
        {name: FN.TRUCKER_PHONE, serverName: FN.TRUCKER_PHONE, label: '',},
        {name: FN.CUSTOMER, serverName: FN.CUSTOMER, label: '', smallMargin: true, vertical: true},
        {name: FN.CUSTOMER_ID, serverName: FN.CUSTOMER_ID, label: '',},
        {name: FN.CUSTOMER_ADDR, serverName: FN.CUSTOMER_ADDR, label: '', type: 'textarea', smallMargin: true},
        {name: FN.CUSTOMER_CONTACT, serverName: FN.CUSTOMER_CONTACT, label: '',},
        {name: FN.CUSTOMER_PHONE, serverName: FN.CUSTOMER_PHONE, label: '',},

        {name: FN.CARRIER, serverName: FN.CARRIER, label: 'Carrier', disabled: true},
        {name: FN.VSL_VOY, serverName: FN.VSL_VOY, label: 'VSL/VOY', disabled: true},
        {name: FN.POL, serverName: FN.POL, label: '', disabled: true},
        {name: FN.ETD, serverName: FN.ETD, label: '', disabled: true},
        {name: FN.POD, serverName: FN.POD, label: '', disabled: true},
        {name: FN.ETA, serverName: FN.ETA, label: '', disabled: true},

        {name: FN.WORKPLACE, serverName: FN.WORKPLACE, label: 'Place', smallMargin: true, required: true},
        {name: FN.WORKPLACE_ID, serverName: FN.WORKPLACE_ID, label: ''},
        {name: FN.ADDRESS, serverName: FN.ADDRESS, label: '', type: 'textarea', smallMargin: true},
        {name: FN.PIC, serverName: FN.PIC, label: 'PIC', required: true},
        {name: FN.PHONE, serverName: FN.PHONE, label: 'Phone',},
        {name: FN.PHONE2, serverName: FN.PHONE2, label: '',},
        {name: FN.CITY, serverName: FN.CITY, label: 'City', required: true},
        {name: FN.WORK_DATE, serverName: FN.WORK_DATE, label: 'EST. Time', type: 'date', placeholder: 'Date', required: true},
        {name: FN.WORK_TIME, serverName: FN.WORK_TIME, label: '', placeholder: 'Time'},
        {name: FN.REF_NO, serverName: FN.REF_NO, label: 'Ref. No.',},

        {name: FN.CARGO_TYPE, serverName: FN.CARGO_TYPE, label: 'Cargo Type', options: cargoTypeOptions, noDefOption: true},
        {name: FN.COMMODITY, serverName: FN.COMMODITY, label: 'Commodity',},
        {name: FN.PACKAGE, serverName: FN.PACKAGE, label: '',},
        {name: FN.PACKAGE_TYPE, serverName: FN.PACKAGE_TYPE, label: '', options: packageTypeOptions, noDefOption: true},
        {name: FN.GROSS_WEIGHT_KG, serverName: FN.GROSS_WEIGHT_KG, label: 'Gross Weight',},
        {name: FN.MEASUREMENT_CBM, serverName: FN.MEASUREMENT_CBM, label: 'CBM',},
        {name: FN.TAPE, serverName: FN.TAPE, label: 'Tape',},
        {name: FN.CONTAINER_SUMMARY, serverName: FN.CONTAINER_SUMMARY, label: 'Container Summary',},
        {name: FN.CONTAINER_GRID_DATA, serverName: FN.CONTAINER_GRID_DATA, label: '',},
        {name: FN.EXPORT_REF_NO, serverName: FN.EXPORT_REF_NO, label: 'Export Ref. No.',},

        {name: FN.REMARK, serverName: FN.REMARK, label: '', type: 'textarea'},
        {name: FN.TRUCKING, serverName: FN.TRUCKING, label: '', type: 'textarea'},
        {name: FN.CUSTOMS, serverName: FN.CUSTOMS, label: '', type: 'textarea'},
        {name: FN.WAREHOUSE_LOADING, serverName: FN.WAREHOUSE_LOADING, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const user = useUser();
    const isManagerRef = React.useRef();
    isManagerRef.current = user.isManager;
    const areaOptions = api.useAreaOptions(true);
    const isAir = this.portType === 'A';
    const isCopy = this.workType === 'PU' // || this.workType === 'DL';
    const copyLabel = this.workType === 'PU' ? 'Make Delivery' : 'Make Pickup';
    // const hasId = !!this.woId;
    const [hasId, setHasId] = React.useState(!!this.woId);
    _f(FN.BRANCH_ID).options = [...areaOptions];
    _f(FN.FILING_NO).onEnter = (name, value) => this.onSearch(FN.FILING_NO, value);
    _f(FN.BL_NO).onEnter = (name, value) => this.onSearch(FN.BL_NO, value);
    _f(FN.WO_TYPE).disabled = hasId;
    _f(FN.FILING_NO).disabled = hasId;
    _f(FN.BL_NO).disabled = hasId;
    React.useEffect(() => {_c(FN.BRANCH_ID, user.branch_id)}, []);
    React.useEffect(() => {
      setHasId(!!this.woId);
    }, [this.woId]);
    const _tp = (name) => {
      return (
        <TradePartnerSearchInput
          idField={_n(`${name}Id`)}
          displayField={_n(name)}
          formik={formik} fields={fields} errors={errors}
          onOK={({id, value, Address, localAddress, city, phone, cell}) => {
            _c(`${name}`, value);
            _c(`${name}Id`, id);
            _c(`${name}Addr`, Address);
            _c('address', localAddress);
            city && name === FN.WORKPLACE && _c('city', city);
            _c('phone', phone);
            _c('workplacePhone2', cell);
          }}
        />
      );
    };
    const _tpg = (name, label) => {
      return (
        <>
          <div className={'flex'}>
            {standaloneLabel(label)}
            <div className={'flex-1'}>{_tp(name)}</div>
          </div>
          <div>{_r(`${name}Addr`)}</div>
          <div className={'flex'}>
            {standaloneLabel('PIC / Phone')}
            <div className={'flex-1 mr-8'}>{_r(`${name}Contact`)}</div>
            <div className={'flex-1'}>{_r(`${name}Phone`)}</div>
          </div>
        </>
      );
    };
    const onDelete = () => {
      if(hasId) {
        util.showConfirm('Are you sure to delete?', async () => {
          const res = await apiBL.deleteWorkOrder(parseInt(this.woId));
          if(res) {
            util.showSuccess('Work order has been deleted successfully!');
            util.nav('/admin/schedule/workorder');
          }
        })
      }
    };
    const grid = useContainerGrid();
    const blGrid = useBLListGrid();
    this.grid = grid;
    this.blGrid = blGrid;
    const payHistoryModal = useAccountPaymentModal();
    const blListModal = useBLListModal();
    const onHBL = () => {
      util.nav(`/admin/bl/house/${this.blId}`);
    };
    const manifestPreviewModal = useManifestPreviewModal();
    const manifestPreviewModal2 = useManifestPreviewModal2();
    const manifestPreviewModal3 = useManifestPreviewModal3();
    const {id, branchId} = this.getReqValues();
    const branchName = _f(FN.BRANCH_ID).options.find(i => i.value === parseInt(branchId))?.label;
    const onPrint = async () => {
      const {data} = await apiBL.getBLManifestList({branchId: util.toInt(branchId), branchName, workorderId: id});
      if (data?.length > 0) {
        if(user.label_type === labelType.LABEL3) {
          manifestPreviewModal3.open(data, data?.length > 1 ? true : (data?.length === 1 && data[0].ctns > 1));
        } else if(user.label_type === labelType.LABEL2) {
          manifestPreviewModal2.open(data, data?.length > 1 ? true : (data?.length === 1 && data[0].ctns > 1));
        } else {
          manifestPreviewModal.open(data);
        }
        apiBL.setBLPrint(data?.map(r => r['bl_no'])).catch();
      }
    };
    const onCopy = () => {
      if(hasId) {
        util.showConfirm('Are you sure to copy?', async () => {
          util.nav('/admin/schedule/workorder/set');
          if (this.workType === 'PU') {
            _c(FN.WO_TYPE, 'DL');
          } else {
            _c(FN.WO_TYPE, 'PU');
          }
          _c(FN.ID, 0);
          _c(FN.BRANCH_ID, user.branch_id);
          _c(FN.WORKPLACE_ID, this.consigneeId);
          _c(FN.WORKPLACE, this.consigneeLocal);
          _c(FN.CITY, '');
          _c(FN.ADDRESS, this.consigneeAddrLocal);
          _c(FN.REMARK, this.remark);
          this.woId = '';
          setHasId('');
        });
      }
    };
    React.useEffect(() => {
      if (!blListModal.isOpen) {
        this.onSearchBLList();
      }
    }, [blListModal.isOpen]);
    return (
      <>
        <Card style={cardStyle}>
          <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
            <div className={'flex right w-full'}>
              {/*<DefaultButton label={'New'} onClick={() => {this.clearValuesFast(); grid.setRows([])}} />*/}
              <DefaultButton label={'Save'} onClick={() => this.onSave(this.getReqValues())} />
              {hasId && isAir && <DefaultButton label={'Print'} onClick={() => onPrint(this.getReqValues())} />}
              {hasId && !isAir && isCopy && <DefaultButton label={copyLabel} onClick={onCopy} />}
              {hasId && user.isManager && (<DangerButton label={'Delete'} onClick={onDelete} />)}
              {hasId && <DefaultButton label={'HB/L'} onClick={onHBL} />}
              {hasId && <DefaultButton label={'P.History'} onClick={() => payHistoryModal.open(undefined, undefined, _v(FN.BL_NO), true)} />}
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.WO_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.BL_NO)}</div>
            </div>
            {this.workAssigned.length > 0 && (<div style={{color: 'red', fontWeight: 'bold'}}>ASSIGNED: {util.formatD(this.workAssigned[0])} ({this.workAssigned[1]})</div>)}
          </CardBody>
        </Card>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-24'}>
            <Card style={cardStyle}>
              <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
                <b>Party Information</b>
                {_tpg(FN.BILL_TO, 'Bill To')}
                {_tpg(FN.TRUCKER, 'Trucker')}
                {_tpg(FN.CUSTOMER, 'Customer')}
              </CardBody>
            </Card>
          </div>
          <div className={'flex-1 mr-24'}>
            <Card style={cardStyle}>
              <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
                <div className={'mb-1'} style={{display: "flex", justifyContent: "space-between"}}>
                  <b>B/L List</b>
                  {isManagerRef.current && <DefaultButton label={'View'} noMargin onClick={() => blListModal.open(id)} />}
                </div>
                {blGrid.render(_v(FN.BL_NO))}
                {this.renderTab()}
                <b>Workplace</b>
                {/*{_tpg(FN.WORKPLACE, 'Place')}*/}
                <div className={'flex'}>
                  <div className={'flex-1 mr-8 ml-8'}>{_tp(FN.WORKPLACE)}</div>
                  <div className={'flex-1 ml-8'}>{_r(FN.CITY)}</div>
                </div>
                <div>{_r(FN.ADDRESS)}</div>
                <div className={'flex'}>
                  {/*{standaloneLabel('Est. Time')}*/}
                  <div className={'flex-2 mr-8'}>{_r(FN.WORK_DATE)}</div>
                  <div className={'flex-1'}>{_r(FN.WORK_TIME)}</div>
                </div>
                <div className={'flex'}>
                  <div className={'flex-1'}>{_r(FN.PIC)}</div>
                </div>
                <div className={'flex'}>
                  <div className={'flex-2 mr-8'}>{_r(FN.PHONE)}</div>
                  <div className={'flex-1'}>{_r(FN.PHONE2)}</div>
                </div>
                {_r(FN.REF_NO)}
              </CardBody>
            </Card>
          </div>
          <div className={'flex-1'}>
            <Card style={cardStyle}>
              <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
                <b>Shipment & Item</b>
                {_r(FN.CARGO_TYPE)}
                {_r(FN.COMMODITY)}
                <div className={'flex'}>
                  {standaloneLabel('Package')}
                  <div className={'flex-1 mr-8'}>{_r(FN.PACKAGE)}</div>
                  <div className={'flex-1'}>{_r(FN.PACKAGE_TYPE)}</div>
                </div>
                {_r(FN.GROSS_WEIGHT_KG)}
                <div className={'flex'}>
                  <div className={'flex-1'}>{_r(FN.MEASUREMENT_CBM)}</div>
                  <div className={'flex-1'}>{_r(FN.TAPE)}</div>
                </div>
                {/*{_r(FN.CONTAINER_SUMMARY)}*/}
                {grid.render(_v(FN.BL_NO))}
                {_r(FN.EXPORT_REF_NO)}
                <b>Vessel & Route</b>
                {_r(FN.CARRIER)}
                {_r(FN.VSL_VOY)}
                <div className={'flex'}>
                  <div style={{width: 76}}>
                    {standaloneLabel('POL / ETD')}
                  </div>
                  <div className={'flex mr-8'}>{_r(FN.POL)}</div>
                  <div className={'flex'}>{_r(FN.ETD)}</div>
                </div>
                <div className={'flex'}>
                  {standaloneLabel('POD / ETA')}
                  <div className={'flex-1 mr-8'}>{_r(FN.POD)}</div>
                  <div className={'flex-1'}>{_r(FN.ETA)}</div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        {payHistoryModal.render()}
        {manifestPreviewModal.render()}
        {manifestPreviewModal2.render()}
        {manifestPreviewModal3.render()}
        {blListModal.render()}
      </>
    );
  };
  static tabs = [
    {value: 'R', label: 'Remark', name: FN.REMARK},
    {value: 'T', label: 'Trucking', name: FN.TRUCKING},
    {value: 'C', label: 'Customs', name: FN.CUSTOMS},
    {value: 'W', label: 'Warehouse', name: FN.WAREHOUSE_LOADING},
  ];
  renderTab = () => {
    const {renderField: _r} = this;
    const [activeTab, setActiveTab] = React.useState('R');
    const {tabs} = WorkOrderFormManager;
    return (
      <div className={'tabs tabs--bordered-top-small'}>
        <div className={'tabs__wrap'}>
          <Nav tabs>
            {tabs.map(tab => {
              const active = activeTab === tab.value;
              return (
                <NavItem key={tab.name}>
                  <NavLink className={_c({active})} onClick={() => setActiveTab(tab.value)}>
                    {tab.label}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabs.map(tab => <TabPane tabId={tab.value} key={tab.value}>{_r(tab.name)}</TabPane>)}
          </TabContent>
        </div>
      </div>
    );
  }
  onValidate = (values) => {
    return values;
  };
  getReqValues = () => {
    const values = this.getValues();
    const id = values[FN.ID];
    const blNo = values[FN.BL_NO];
    const branchId = values[FN.BRANCH_ID];
    const kind = values[FN.KIND];
    const workType = values[FN.WO_TYPE] ?? 'PU';
    const workDateStr = values[FN.WORK_DATE];
    const workDate = util.toTS(workDateStr);
    const workTime = values[FN.WORK_TIME];
    const pic = values[FN.PIC];
    const phone = values[FN.PHONE];
    const workplaceId = values[FN.WORKPLACE_ID] ?? 0;
    const workplace = values[FN.WORKPLACE];
    const address = values[FN.ADDRESS];
    const city = values[FN.CITY];
    const jparty = {
      [FN.BILL_TO]: values[FN.BILL_TO],
      [FN.BILL_TO_ID]: values[FN.BILL_TO_ID],
      [FN.BILL_TO_ADDR]: values[FN.BILL_TO_ADDR],
      [FN.BILL_TO_CONTACT]: values[FN.BILL_TO_CONTACT],
      [FN.BILL_TO_PHONE]: values[FN.BILL_TO_PHONE],
      [FN.TRUCKER]: values[FN.TRUCKER],
      [FN.TRUCKER_ID]: values[FN.TRUCKER_ID],
      [FN.TRUCKER_ADDR]: values[FN.TRUCKER_ADDR],
      [FN.TRUCKER_CONTACT]: values[FN.TRUCKER_CONTACT],
      [FN.TRUCKER_PHONE]: values[FN.TRUCKER_PHONE],
      [FN.CUSTOMER]: values[FN.CUSTOMER],
      [FN.CUSTOMER_ID]: values[FN.CUSTOMER_ID],
      [FN.CUSTOMER_ADDR]: values[FN.CUSTOMER_ADDR],
      [FN.CUSTOMER_CONTACT]: values[FN.CUSTOMER_CONTACT],
      [FN.CUSTOMER_PHONE]: values[FN.CUSTOMER_PHONE],
      [FN.PHONE2]: values[FN.PHONE2],
    };
    const jshipitem = {
      [FN.CARGO_TYPE]: values[FN.CARGO_TYPE],
      [FN.COMMODITY]: values[FN.COMMODITY],
      [FN.PACKAGE]: values[FN.PACKAGE],
      [FN.PACKAGE_TYPE]: values[FN.PACKAGE_TYPE],
      [FN.GROSS_WEIGHT_KG]: values[FN.GROSS_WEIGHT_KG],
      [FN.MEASUREMENT_CBM]: values[FN.MEASUREMENT_CBM],
      [FN.TAPE]: values[FN.TAPE],
      [FN.CONTAINER_SUMMARY]: values[FN.CONTAINER_SUMMARY],
      [FN.CONTAINER_GRID_DATA]: values[FN.CONTAINER_GRID_DATA],
      [FN.EXPORT_REF_NO]: values[FN.EXPORT_REF_NO],
    }
    const jremark = {
      [FN.REMARK]: values[FN.REMARK],
      [FN.TRUCKING]: values[FN.TRUCKING],
      [FN.CUSTOMS]: values[FN.CUSTOMS],
      [FN.WAREHOUSE_LOADING]: values[FN.WAREHOUSE_LOADING],
    };
    return {
      id, blNo, branchId, kind, workType, workDate, workDateStr, workTime,
      pic, phone, workplaceId, workplace, address, city,
      jparty, jshipitem, jremark
    };
  }
  setFromBLData = (blData) => {
    const {id, bound, carrier_type: carrierType, filing_no: filingNo, h_m: hm, jcommon, jcustomer, jroute, jshipment, jvessel, kind, containers} = blData;
    const {hblNo, mblNo, mawbNo, hawbNo, branchId, portType, workorderD} = jcommon;
    const {shipper, shipperId, shipperAddr, consignee, consigneeId, consigneeAddr, consigneeLocal, consigneeAddrLocal} = jcustomer;
    const {carrier, etd, eta, flightDate, arrivalDate, vslVoy, flightNo} = jvessel;
    const {polLabel, podLabel, departure, destination} = jroute;
    const {cargoType, commodity, package: pkg, packageType, grossWeightKg, measurementCbm, cbm} = jshipment;
    this.portType = portType;
    this.consigneeId = consigneeId;
    this.consigneeLocal = consigneeLocal;
    this.consigneeAddrLocal = consigneeAddrLocal;
    this.remark = workorderD;
    const isExport = bound === 'E';
    // const isHouse = hm === 'H';
    // const isOcean = carrierType === 'O';
    // const values = {};
    const containerSummary = [];
    const containerGridData = containers.map(([containerNo, tpSize, packages]) => {
      containerSummary.push(tpSize);
      return {containerNo, tpSize, packages};
    });
    const dataToSet = {
      [FN.KIND]: kind,
      [FN.FILING_NO]: filingNo,
      [FN.BRANCH_ID]: branchId,
      [FN.BL_NO]: hblNo ?? hawbNo ?? mblNo ?? mawbNo,
      [FN.BILL_TO]: isExport ? consignee : shipper,
      [FN.BILL_TO_ID]: isExport ? consigneeId : shipperId,
      [FN.BILL_TO_ADDR]: isExport ? consigneeAddr : shipperAddr,
      [FN.CUSTOMER]: isExport ? shipper : consignee,
      [FN.CUSTOMER_ID]: isExport ? shipperId : consigneeId,
      [FN.CUSTOMER_ADDR]: isExport ? shipperAddr : consigneeAddr,
      [FN.CARRIER]: carrier,
      [FN.VSL_VOY]: vslVoy ?? flightNo,
      [FN.POL]: polLabel ?? departure,
      [FN.POD]: podLabel ?? destination,
      [FN.ETD]: util.formatD(etd ?? flightDate),
      [FN.ETA]: util.formatD(eta ?? arrivalDate),
      [FN.CARGO_TYPE]: cargoType,
      [FN.COMMODITY]: commodity,
      [FN.PACKAGE]: pkg,
      [FN.PACKAGE_TYPE]: packageType,
      [FN.GROSS_WEIGHT_KG]: grossWeightKg,
      [FN.MEASUREMENT_CBM]: measurementCbm ?? cbm,
      [FN.CONTAINER_SUMMARY]: containerSummary.join(', '),
      [FN.CONTAINER_GRID_DATA]: containerGridData,
    };
    this.blId = id;
    this.setValuesFast({...this.getValues(), ...dataToSet});
  };
  setFromWOData = (woData) => {
    const {
      id, bl_no: blNo, branch, branch_id: branchId, kind,
      workplace, workplace_id, work_type, work_date, work_time,
      pic, phone, city, address, ref_no, export_ref: exportRefNo,
      jparty, jremark, jshipitem, jcommon, work_assigned,
    } = woData;

    const eta = util.formatD(jcommon['eta']);
    const etd = util.formatD(jcommon['etd']);

    const data = {
      id, blNo, branch, branchId, kind,
      workplace, workplaceId: workplace_id,
      woType: work_type, work_date: util.formatD(work_date), work_time,
      pic, phone, city, address,
      ref_no, exportRefNo, ...jparty, ...jremark, ...jshipitem, ...jcommon, eta, etd,
    };
    this.workType = work_type;
    this.grid.setRows(jshipitem?.['containerGridData'] ?? []);
    this.workAssigned = work_assigned;
    this.setValuesFast({...this.getValues(), ...data});
  }
  loadBoxList = async () => {
    const res = await apiBL.getBLBoxList(this.blId);
    this.grid.setRows(res.data);
  };
}

function useContainerGrid() {
  return useSimpleGrid({
    columns: [
      {field: 'length', headerName: 'L. (cm)', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'width', headerName: 'W. (cm)', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'height', headerName: 'H. (cm)', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'pcs', headerName: 'PCS', width: 70, minWidth: 70},
      {field: 'cal_weight', headerName: 'C.W', valueFormatter: util.formatWeight, minWidth: 70, flex: 1},
      {field: 'box_cbm', headerName: 'CBM', valueFormatter: util.formatCBM, minWidth: 70, flex: 1},
    ],
    height: 150,
    className: 'mb-20',
    agGridProps: {
      headerHeight: 25,
      rowHeight: 25,
    }
  });
}

function useBLListGrid() {
  return useSimpleGrid({
    columns: [
      {field: 'bl_id', hide: true},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true), width: 90, minWidth: 90},
      {field: 'customer', headerName: 'Customer', flex: 1, minWidth: 85},
      {field: 'consignee', headerName: 'Consignee', flex: 1, minWidth: 90},
      {field: 'carrier_type', headerName: 'Carrier', width: 70, minWidth: 70},
      {field: 'package', headerName: 'Packages', width: 80, minWidth: 70},
      {field: 'weight', headerName: 'Weight', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, width: 70, minWidth: 70},
      {field: 'total_price', headerName: 'Total', width: 80, minWidth: 80, valueFormatter: util.currencyFormatter},
      {field: 'paid_by', headerName: 'Paid', width: 80, minWidth: 80},
    ],
    height: 170,
    className: 'mb-20',
    agGridProps: {
      headerHeight: 25,
      rowHeight: 25,
    }
  });
}

export default WorkOrderFormManager;
