/**
 * @flow
 */
import React from "react";
import FormManager from "../../../../lib/FormManager";
import {api, util} from "../../../../services/service";
import {oceanAirOptionsForBLList} from "../../../helper";
import {kindOptions} from "../../../field-defs/bl";
import {useUser} from "../../../../redux/reducers/userReducer";

export const SEARCH = 'qryText';
export const FROM_DATE = 'fromDate';
export const TO_DATE = 'toDate';
export const KIND = 'kind';
export const BRANCH = 'branchId';
export const CARRIER_TYPE = 'carrierType';
export const DEPARTURE = 'departure';
export const DESTINATION = 'destination';
export const STORAGE = 'storage';
export const CUSTOMER_ONLY = 'customer_only';
export const FLAG = 'flag';

export default class CourierBLListSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  clickedIndex;
  constructor() {
    super({
      prefix: 'courier-bl-list-search',
      fields: [
        {name: SEARCH, serverName: SEARCH, label: 'Search', smallMargin: true},
        {name: FROM_DATE, serverName: FROM_DATE, label: 'Date', smallLabel: true, smallMargin: true, type: 'date'},
        {name: TO_DATE, serverName: TO_DATE, label: '', smallLabel: true, smallMargin: true, type: 'date'},
        {name: KIND, serverName: KIND, label: 'Service', options: [{value: '', label: 'ALL'}, ...kindOptions], noDefOption: true, smallMargin: true},
        {name: BRANCH, serverName: BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: CARRIER_TYPE, serverName: CARRIER_TYPE, label: 'Carrier Type', options: [{value: '', label: 'ALL'}, ...oceanAirOptionsForBLList], noDefOption: true, smallMargin: true},
        {name: DEPARTURE, serverName: DEPARTURE, label: 'Departure', smallMargin: true},
        {name: DESTINATION, serverName: DESTINATION, label: 'Destination', smallMargin: true},
        {name: STORAGE, serverName: STORAGE, type: 'checkbox', label: 'Storage', smallMargin: true},
        {name: CUSTOMER_ONLY, serverName: CUSTOMER_ONLY, type: 'checkbox', label: 'Customeer Only', smallMargin: true},
        {name: FLAG, serverName: FLAG, type: 'checkbox', label: 'Flag', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const areaOptions = api.useAreaOptions(true);
    const user = useUser();
    _f(BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SEARCH).onEnter = this.onSearch;

    const kind = _v(KIND);
    const branchId = _v(BRANCH);
    const carrierType = _v(CARRIER_TYPE);

    React.useEffect(() => {
      _c(FROM_DATE, util.toDefaultFirstTS(util.getCurrentDate()));
      _c(TO_DATE, util.toDefaultEndTS(util.getCurrentDate()));
      _c(KIND, 'C');
    }, []);

    React.useEffect(() => {
      if (!branchId) {
        _c(BRANCH, user.branch_id);
      }
    }, [user.branch_id]);

    React.useEffect(() => {
      if (!(kind === undefined && branchId === undefined && carrierType === undefined)) {
        this.onSearch();
      }
    }, [kind, branchId, carrierType, this.clickedIndex]);

    return (
      <>
        {_r(SEARCH)}
        {_r(FROM_DATE)}
        <div>~</div>
        {_r(TO_DATE)}
        {_r(KIND)}
        {_r(BRANCH)}
        {_r(CARRIER_TYPE)}
        {this.clickedIndex !== 1 && _r(DEPARTURE)}
        {this.clickedIndex !== 1 && _r(DESTINATION)}
        <div style={{marginTop: 10}}>
          {this.clickedIndex === 1 && _r(STORAGE)}
          {this.clickedIndex === 1 && _r(CUSTOMER_ONLY)}
          {_r(FLAG)}
        </div>
      </>
    );
  };
}
