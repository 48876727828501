/**
 * @flow
 */
import {useDataGridView} from "../../components/DataGridView";
import {apiAccount, apiBL, util} from '../../services/service';
import {woTypeOptions} from '../../forms/work-order/WorkOrderFormManager';
import {kindFormatter} from '../../forms/field-defs/bl';
import React from "react";

export default function useWorkOrderGridView(onGridReady) {
  return useDataGridView({
    async onAction(action, data) {
      if (action === 'edit') {
        const {id, bl_no} = data;
        util.openTab(`/admin/schedule/workorder/set?id=${id}&blNo=${bl_no}`);
        // util.nav(`/admin/schedule/workorder/set?id=${id}&blNo=${bl_no}`);
      }
    },
    async onQuery(gridInfo) {
      // return apiBL.workOrderList(gridInfo);
      const {data, totalCount} = await apiBL.workOrderList(gridInfo);
      return {
        totalCount,
        data: data.map(r => ({...r, phone: `="${r.phone}"`})),
      };
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
        // TODO
      }
    },
    name: `ListWorkOrder`,
    label: 'Work Order List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Schedule'},
    menuLabel: {label: 'Work Order List'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 70,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    initialSearchOneYear: true,
    onGridReady,
    columns: [
      {field: 'id', hide: true},
      {field: 'work_date', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
      {field: 'kind', headerName: 'Service', valueFormatter: kindFormatter, width: 100},
      {field: 'work_type', headerName: 'Type', valueFormatter: workTypeFormatter, width: 80},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true), width: 120},
      {field: 'workplace', headerName: 'Customer', width: 120},
      {field: 'phone', headerName: 'Phone', valueFormatter: postalTextFormatter, width: 100},
      {field: 'address', headerName: 'Address', flex: 1},
      {field: 'city', headerName: 'City', width: 100},
      {field: 'cbm', headerName: 'CBM/Tape', valueFormatter: CBMTapeFormatter, width: 100},
      {field: 'package', headerName: 'PKGS', width: 80},
      {field: 'remark', headerName: 'Remark', flex: 1},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatterEx, width: 100},
    ],
  });
}

function workTypeFormatter(p) {
  const item = woTypeOptions.find(i => i.value === p.value);
  return item?.label ?? '';
}

function postalTextFormatter(p) {
  const str = p.value.toString().replaceAll('"', '').replace('=', '');
  return str === 'null' ? '' : str;;
}

function CBMTapeFormatter(p) {
  const str = p.value.toString().split('/');
  const cbm = str[0] === 'None' ? '' : str[0];
  const tape = str[1] === 'None' ? '' : str[1];
  const simbol = cbm || tape ? '/' : '';
  return `${cbm} ${simbol} ${tape}`;
}
