/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import type {FormField} from '../../components/Form';
import {api, apiAccount, util} from '../../services/service';
import {useUser} from '../../redux/reducers/userReducer';
import {
  FlightSearchInput,
  PortSearchInput,
  TradePartnerSearchInput,
  VesselSearchInput,
  VslVoySearchInput
} from '../../components/SearchInput';
import {BL, FD, FN, SN} from '../field-defs/account';
import {INVOICE as INVOICE_TYPES} from '../field-defs/account';
import type {INVOICE_TYPE} from '../field-defs/account';

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class AccountInvoiceAPFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  InvoiceType: INVOICE_TYPE;
  onTradeTypeChange: (tradeType: string, portType: string) => void;
  grid;
  oehGrid;
  constructor() {
    super({
      prefix: `account-invoice-form`,
      fields: [],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.InvoiceType = INVOICE_TYPES.TYPE_AP;
    this.fields = this.getFields();
  }
  getGridData() {
    let grid = this.InvoiceType === 'OEH' ? this.oehGrid : this.grid;
    const rows = [];
    if (grid.apiRef && grid.apiRef.current) {
      grid.apiRef.current.forEachNode(node => rows.push(node.data));
    }
    return rows;
  }
  onRender = () => {
    const {getField: _f, getValue: _v, setValue: _c} = this;
    const user = useUser();

    switch (this.InvoiceType) {
      case INVOICE_TYPES.TYPE_AP: return  <INVOICE {...this.getRenderProps()} />;
      default:
          throw new Error(`Invalid forwarding type (${this.InvoiceType})`);
    }
  };
  onValidate = (values) => {
    const {blId, invoiceDate, partner, partnerId} = values;
    if(!blId) {
      util.showError('There is no filing no.');
      return undefined;
    }
    if(!partner && !partnerId) {
      util.showWarning('Please enter a partner data!');
      return undefined;
    }
    if (!invoiceDate || !util.isValidDate(invoiceDate)) {
      util.showWarning('Please enter a valid invoice data!');
      return undefined;
    }
    return values; // TODO: 밸리데이션이 필요한 경우 여기서 구현함
  };
  getFields() {
    const fields: FormField[] = [];
    for (const key in FD) {
      if (FD.hasOwnProperty(key)) {
        fields.push(FD[key]);
      }
    }
    return fields.map(i => ({...i, name: `${this.prefix}-${i.name}`}));
  }
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function useSearchInput(props) {
  const {_n, _c, formik, fields, errors, fm} = props;
  function setValues(data) {
    const values = {};
    const prevValues = {};
    for (const [name, value] of Object.entries(data)) {
      values[_n(name)] = value;
    }
    for (const [name, value] of Object.entries(fm.getValues())) {
      prevValues[_n(name)] = value;
    }
    formik.setValues({...prevValues, ...values});
  }
  const onTPartner = (name, {id, value, Address, /*City, Country*/}) => {
    _c(`${name}`, value);
    _c(`${name}Id`, id);
    _c(`${name}Addr`, Address);
  };
  const onVSL = (name, data) => {
    const {carrier, carrierId, etd, eta, pol: polLabel, pod: podLabel, billingCarrier, billingCarrierId, onboard, id: craftId, value: vslVoy} = data;
    const values = {
      craftId, vslVoy, carrier, carrierId, etd: util.formatD(etd), eta: util.formatD(eta),
      polLabel, podLabel, billingCarrier, billingCarrierId, onboard
    };
    setValues(values);
  };
  const onFLT = (name, data) => {
    const {
      id: craftId,
      carrier, carrierId,
      flightNo,
      departure, destination,
      flightDate, arrivalDate,
      etaOfFpoe, finalEta,
      firstTo,
      trans1To, trans1ToFlight,
      trans2To, trans2ToFlight,
      trans3To, trans3ToFlight,
      billingCarrier, billingCarrierId,
      issuingCarrier, issuingCarrierId,
    } = data;
    const values = {
      craftId,
      carrier, carrierId,
      flightNo,
      departure, destination,
      flightDate: util.formatD(flightDate),
      flightTime: util.formatFlightT(flightDate),
      arrivalDate: util.formatD(arrivalDate),
      arrivalTime: util.formatFlightT(arrivalDate),
      etaOfFpoe, finalEta,
      firstTo,
      trans1To, trans1ToFlight,
      trans2To, trans2ToFlight,
      trans3To, trans3ToFlight,
      billingCarrier, billingCarrierId,
      issuingCarrier, issuingCarrierId,
    };
    setValues(values);
  };
  const _tp = (name, vertical = false) => <TradePartnerSearchInput
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical={vertical}
  />;
  const _tpa = (name) => <TradePartnerSearchInput
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical onOK={(data) => onTPartner(name, data)}
  />;
  const _vsl = (name) => <VslVoySearchInput
      idField={_n('craftId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} onOK={(data) => onVSL(name, data)}
  />
  const _flt = (name) => <FlightSearchInput
      idField={_n('craftId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} onOK={(data) => onFLT(name, data)}
  />
  const _pt = (name) => <PortSearchInput
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
  />;
  return {_tp, _tpa, _vsl, _flt, _pt};
}
const INVOICE = (props) => {
  const {_r, _v, _f, _c} = props;
  const {_tp, _tpa, _vsl, _pt} = useSearchInput(props);
  const invNo = _v(FN.DC_NO);
  const user = useUser();
  const branchId = _v(FN.FINANCIAL_OFFICE);
  _f(FN.DC_NO).disabled = !!invNo; // 인보이스 넘버가 있으면 비활성화 함
  _f(FN.FINANCIAL_OFFICE).options = api.useAreaOptions();
  _f(FN.BANK_INFO).options = apiAccount.useBankList(branchId);

  React.useEffect(() => {
    if (!branchId && user.branch_id) {
      _c(FN.FINANCIAL_OFFICE, user.branch_id);
    }
  }, [branchId, user.branch_id ?? 0]);

  const invDate = _v(FN.INVOICE_DATE);
  const terms = _v(FN.TERMS);
  React.useEffect(() => {
    if (terms && util.isValidDate(invDate)) {
      _c(FN.DUE_DATE, util.addDays(util.toTS(invDate), util.toInt(terms)));
    } else {
      _c(FN.DUE_DATE, '');
    }
  }, [terms, invDate]);

  const currency = _v(FN.CURRENCY);
  const incoterms = _v(FN.INCOTERMS);
  React.useEffect(() => {
    if(!currency) {
      _c(FN.CURRENCY, user.currency);
    }
    if(!incoterms) {
      _c(FN.INCOTERMS, '');
    }
  }, [currency, incoterms]);

  const bankInfo = _v(FN.BANK_INFO);
  React.useEffect(() => {
    _f(FN.BANK_INFO).onChange = (name, value) => {
      if(value.includes('(CAD)')) {
        _c(FN.CURRENCY, 'CAD');
      } else if(value.includes('(USD)')) {
        _c(FN.CURRENCY, 'USD');
      } else if(value.includes('(KRW)')) {
        _c(FN.CURRENCY, 'KRW');
      }
    }
  }, [bankInfo]);

  React.useEffect(() => {
    _f(FN.MEMO).type = 'textarea';
  }, []);

    return (
      <>
        <Card style={cardStyle}>
          <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
            <div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER)}</div>
                <div className={'flex-1'}>{_r(FN.POR)}</div>
                <div className={'flex-1'}>{_r(FN.COMMODITY)}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.MBL_NO)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE)}</div>
                <div className={'flex flex-1'}>
                  <div className={'flex-3'}>{_r(FN.POL)}</div>
                  <div className={'flex-1 ml-8'}>{_r(FN.POL_ETD)}</div>
                </div>
                <div className={'flex flex-1'}>
                  <div className={'flex-3'}>{_r(FN.PACKAGE)}</div>
                  <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
                </div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.HBL_NO)}</div>
                <div className={'flex-1'}>{_r(FN.NOTIFY)}</div>
                <div className={'flex flex-1'}>
                  <div className={'flex-3'}>{_r(FN.POD)}</div>
                  <div className={'flex-1 ml-8'}>{_r(FN.POD_ETA)}</div>
                </div>
                <div className={'flex flex-1'}>
                  <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                  <div className={'ml-8 mt-8'}>KG</div>
                </div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.CARRIER_BKG_NO)}</div>
                <div className={'flex-1'}>{_r(FN.VESSEL_FIGHT_NO)}</div>
                <div className={'flex-1'}>{_r(FN.DEL)}</div>
                <div className={'flex flex-1'}>
                  <div className={'flex-2'}>{_r(FN.CHARGEABLE_WEIGHT_KG)}</div>
                  <div className={'ml-8 mt-8'}>KG</div>
                </div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.POSTING_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FINAL_DESTINATION)}</div>
                <div className={'flex flex-1'}>
                  <div className={'flex-2'}>{_r(FN.VOLUME_WEIGHT_KG)}</div>
                  <div className={'ml-8 mt-8'}>CBM</div>
                </div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.CONTAINER_STR)}</div>
                <div className={'flex-1'}>&nbsp;</div>
                <div className={'flex-1'}>&nbsp;</div>
                <div className={'flex-1'}>&nbsp;</div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card style={cardStyle}>
          <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
            <div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_tp(FN.PARTNER)}</div>
                <div className={'flex-1'}>{_r(FN.INVOICE_NO)}</div>
                <div className={'flex-1'}>{_r(FN.VENDOR_INVOICE_NO)}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.INVOICE_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.INCOTERMS)}</div>
                <div className={'flex-1'}>{_r(FN.FINANCIAL_OFFICE)}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.TERMS)}</div>
                <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                <div className={'flex-1'}>{_r(FN.BANK_INFO)}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.DUE_DATE)}</div>
                <div className={'flex-2'}>{''}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.MEMO)}</div>
              </div>
            </div>
          </CardBody>
        </Card>
      </>
  );
};

export function getAccountFormData(serverData) {
  const {carrier_type, id, inv_no, blNo, bl_id, incoterms, container_str, jcommon, jcustomer, jroute, jshipment, jvessel, jextra, jitems, footer} = serverData;
  const isOcean = carrier_type !== 'A';
  let vfNo = '';
  if (isOcean) {
    vfNo = jvessel['craftId'];
  } else {
    vfNo = jvessel['flightNo'];
  }

  let ret =  {
    formData: {
      [FN.ID]: id,
      [FN.CONTAINER_STR]: container_str,
      [FN.FILING_NO]: jcommon['filingNo'],
      [FN.MBL_NO]:    jcommon['mblNo'] ?? jcommon['mawbNo'],
      [FN.HBL_NO]:    jcommon['hblNo'] ?? jcommon['hawbNo'],
      [FN.CARRIER_BKG_NO]: jcommon['bkgNo'],
      [FN.AGENT_REF_NO]: jcommon['agentRefNo'],
      [FN.POSTING_DATE]: util.formatD(jcommon['postDate']),
      [FN.BL_ID]: bl_id ?? jcommon['id'],
      [FN.SHIPPER]: jcustomer['shipper'],
      [FN.CONSIGNEE]: jcustomer['consignee'],
      [FN.NOTIFY]: jcustomer['notify'],
      [FN.VESSEL_FIGHT_NO]: vfNo,
      [FN.POR]: jroute['porLabel'],
      [FN.POL]: jroute['polLabel'] ? jroute['polLabel'] : jroute['departure'],
      [FN.POL_ETD]: jroute['polEtd'] ? jroute['polEtd'] : jvessel['etdDate'],
      [FN.POD]: jroute['podLabel']  ? jroute['podLabel'] : jroute['destination'],
      [FN.POD_ETA]: jroute['podEta'] ? jroute['podEta'] : jvessel['etaDate'],
      [FN.DEL]: jroute['delLabel'],
      [FN.FINAL_DESTINATION]: jroute['finalDestLabel'],
      [FN.FINAL_DEST_ETA]: jroute['fDestEta'],
      [FN.COMMODITY]: jshipment['commodity'],
      [FN.PACKAGE]: jshipment['package'],
      [FN.PACKAGE_TYPE]: jshipment['packageType'],
      [FN.GROSS_WEIGHT_KG]: jshipment['grossWeightKg'],
      [FN.CHARGEABLE_WEIGHT_KG]: jshipment['chargeableWeightKg'],
      [FN.VOLUME_WEIGHT_KG]: jshipment['volumeWeightKg'],

      // Bill form
      [FN.PARTNER_ID]: jextra['partnerId'],
      [FN.PARTNER]: jextra['partner'],
      [FN.SHIP_TO]: jextra['shipTo'],
      [FN.ATTENTION_TO]: jextra['attentionTo'],
      [FN.CUSTOMER_REF_NO]: jextra['customerRefNo'],
      [FN.INVOICE_NO]: jextra['invoiceNo'],
      [FN.VENDOR_INVOICE_NO]: jextra['vendorInv'],
      [FN.INVOICE_DATE]: util.formatD(jextra['invoiceDate']),
      [FN.TERMS]: jextra['terms'],
      [FN.DUE_DATE]: util.formatD(jextra['dueDate']),
      [FN.CURRENCY]: jextra['currency'],
      [FN.TAX_INVOICE_NO]: jextra['taxInvoiceNo'],
      [FN.FINANCIAL_OFFICE]: jextra['financialOffice'],
      [FN.BANK_INFO]: jextra['bankInfo'],
      [FN.INCOTERMS]: incoterms,
      [FN.MEMO]: jextra['memo'],
      [FN.FOOTER]: footer,
    },
    gridData: jitems
  }
  return ret;
}

export function getAccountEntryData(_f, gridData) {
  const blNo = _f[FN.HBL_NO] ? _f[FN.HBL_NO] : _f[FN.MBL_NO]
  let amount = 0;
  for (const {volume, rate} of gridData) {
    amount += volume * rate;
  }
  const jitems = gridData.map(r => ({...r, rate: parseFloat(r['rate']), volume: parseFloat(r['volume'])}));
  const jextra = {
    [FN.PARTNER_ID]: _f[FN.PARTNER_ID],
    [FN.PARTNER]: _f[FN.PARTNER],
    [FN.SHIP_TO]: _f[FN.SHIP_TO],
    [FN.ATTENTION_TO]: _f[FN.ATTENTION_TO],
    [FN.CUSTOMER_REF_NO]: _f[FN.CUSTOMER_REF_NO],
    [FN.MEMO]: _f[FN.MEMO],
    [FN.INVOICE_NO]: _f[FN.INVOICE_NO],
    [FN.VENDOR_INVOICE_NO]: _f[FN.VENDOR_INVOICE_NO],
    [FN.POSTING_DATE]: util.toTS(_f[FN.POSTING_DATE]),
    [FN.INVOICE_DATE]: util.toTS(_f[FN.INVOICE_DATE]),
    [FN.TERMS]: _f[FN.TERMS],
    [FN.DUE_DATE]: util.toTS(_f[FN.DUE_DATE]),
    [FN.CURRENCY]: _f[FN.CURRENCY],
    [FN.INCOTERMS]: _f[FN.INCOTERMS],
    [FN.TAX_INVOICE_NO]: _f[FN.TAX_INVOICE_NO],
    [FN.FINANCIAL_OFFICE]: _f[FN.FINANCIAL_OFFICE],
    [FN.BANK_INFO]: _f[FN.BANK_INFO],
  };
  return {
    id: _f[FN.ID],
    invNo: _f[FN.INVOICE_NO],
    accType: 'AP',
    blNo,
    blId: _f[FN.BL_ID],
    branchId: util.toInt(_f[FN.FINANCIAL_OFFICE]),
    currency: _f[FN.CURRENCY],
    amount,
    invDate: _f[FN.INVOICE_DATE],
    dueDate: _f[FN.DUE_DATE],
    taxNo: _f[FN.TAX_INVOICE_NO],
    incoterms: _f[FN.INCOTERMS],
    refNo: _f[FN.CUSTOMER_REF_NO],
    jextra,
    jitems,
  };
}

export default AccountInvoiceAPFormManager;
