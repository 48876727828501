/**
 * @flow
 */
import React, {useState} from 'react';
import {Modal} from 'reactstrap';

const AsyncStatusModal = ({open, message}) => {
  const rtl = {direction: 'ltr'};
  const modalClass = 'modal-dialog--header';
  return (
    <Modal
      isOpen={open}
      modalClassName={`${rtl.direction}-support`}
      className={modalClass}
      backdrop={'static'}
      keyboard={false}
      centered
    >
      <div className="modal__body" style={{whiteSpace: 'pre-line'}}>
        {message}
      </div>
    </Modal>
  );
};

export function useAsyncStatusModal(message) {
  const [open, setOpen] = useState(false);
  return {
    open: () => setOpen(true),
    close: () => setOpen(false),
    render: () => <AsyncStatusModal open={open} message={message} />,
  };
}

export default AsyncStatusModal;
