/**
 * @flow
 */
import React from 'react';
import useRequestGridView from "../../grids/useRequestGridView";
import DashboardRequestFormManager from "../../forms/dashboard/DashboardRequestFormManager";
import {useSimpleEditModal} from "../../components/SimpleModal";
import {api, apiDashboard, util} from '../../services/service';
import DashboardRequestSearchFormManager from "../../forms/dashboard/DashboardRequestSearchFormManager";
import SearchPane from "../../components/SearchPane";
import {PrimaryButton} from "../../components/buttons";
import type {QueryListParams} from "../../services/API";

const searchForm = new DashboardRequestSearchFormManager();
const DashboardRequestList = () => {
  const requestModal = useRequestEditModal(onSave);

  const onAction = async (action, data) => {
    if (action === 'edit') {
      requestModal.open('edit', data);
    }
  };

  async function onSave () {
    const {id, service_type: serviceType, response_status: responseStatus, remark} = requestForm.getValues();
    console.log('hello==>', serviceType, responseStatus);
    const res = await apiDashboard.requestSave({id, serviceType, responseStatus, remark});
    if (res) {
      util.showSuccess('Request has been saved successfully!');
      requestModal.close();
      gridView.query();
    }
  }

  const gridView = useRequestGridView(onAction);

  const onSearch = () => {
    const searchValues = searchForm.getValues();
    const gridInfo = util.getGridInfo(gridView.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      ...searchValues
    };
    console.log('listParam=>', listParam);
    gridView.query(listParam);
  };
  searchForm.onSearch = onSearch;

  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {gridView.render()}
      </div>
      {requestModal.render()}
    </div>
  );
};


const requestForm = new DashboardRequestFormManager();
const useRequestEditModal = (onSave) => {
  return useSimpleEditModal({
    title: 'REQUEST FORM',
    centered: true,
    width: 480,
    form: requestForm,
    onSave,
  });
};

export default DashboardRequestList;
