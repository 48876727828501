/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {DefaultButton} from '../../components/buttons';
import {apiAccount, apiSettings, util} from "../../services/service";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class MasterDefaultCommissionFormManager extends FormManager {
  constructor() {
    super({
      prefix: `master-commission-edit-item-form`,
      fields: [
        {name: 'air', serverName: 'air', label: ''},
        {name: 'ocean', serverName: 'ocean', label: ''},
        {name: 'ups', serverName: 'ups', label: ''},
        {name: 'capost', serverName: 'capost', label: ''},
        {name: 'pickup', serverName: 'pickup', label: ''},
        {name: 'remark', serverName: 'remark', label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onRender = () => {
    const [editMode, setEditMode] = React.useState('edit');
    const {_r, _c, errors} = this.getRenderProps();

    const onQuery = async () => {
      const defaultCommission = await apiSettings.defaultCommissionList();
      _c('air', defaultCommission.data[0].air);
      _c('ocean', defaultCommission.data[0].ocean);
      _c('ups', defaultCommission.data[0].ups);
      _c('capost', defaultCommission.data[0].capost);
      _c('pickup', defaultCommission.data[0].pickup);
      _c('remark', defaultCommission.data[0].remark);
    }

    const onSave = async () => {
      const values = this.getValues(undefined, true);
      if (values) {
        const {air, ocean, ups, capost, pickup, remark} = values;
        const params = {air: util.toFloat(air), ocean: util.toFloat(ocean), ups: util.toFloat(ups), capost: util.toFloat(capost), pickup: util.toFloat(pickup), remark};
        //console.log('hello.params', params);
        let res = await apiSettings.setDefaultCommission(params);
        if (res) {
          this.clearValues();
          onQuery();
        }
      }
    };

    const title = 'Default Commission';
    React.useEffect(() => {
      onQuery();
    }, []);
    return (
      <>
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1 mr-8'}>
                <div>Air</div>
                <div>{_r('air')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>Ocean</div>
                <div>{_r('ocean')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>UPS</div>
                <div>{_r('ups')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>Post</div>
                <div>{_r('capost')}</div>
              </div>
              <div className={'flex-1 mr-8'}>
                <div>Pick up</div>
                <div>{_r('pickup')}</div>
              </div>
              <div className={'flex-3 mr-8'}>
                <div>Remark</div>
                <div>{_r('remark')}</div>
              </div>
              <div className={'mt-20 ml-20'}>
                <DefaultButton label={'edit'} onClick={onSave} noMargin />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      </>
    );
  };

  onValidate = (values) => {
    let {air, ocean, ups, capost, pickup} = values;
    if(air == 0) air = air.toString();
    if(ocean == 0) ocean = ocean.toString();
    if(ups == 0) ups = ups.toString();
    if(capost == 0) capost = capost.toString();
    if(pickup == 0) pickup = pickup.toString();
    if (!(air && ocean && ups && capost && pickup)) {
      util.showWarning('Please enter all required fields.');
      return null;
    }
    return values;
  };

  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

export default MasterDefaultCommissionFormManager;
