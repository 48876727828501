/**
 * @flow
 */
import React from 'react';
import useStaffHolidayGridView from '../../grids/staff/useStaffHolidayGridView';
import useHolidayEditModal from "../../modals/staff/useHolidayEditModal";
import useHolidayDetailModal from "../../modals/staff/useHolidayDetailModal";
import useStaffEditModal from "../../modals/staff/useStaffEditModal";
import {PrimaryButton} from "../../components/buttons";
import {api, util} from "../../services/service";
import type {QueryListParams} from "../../services/API";

const StaffHoliday = () => {
  const {grid, onAdd, editModal, detailModal, editStaffModal} = useStaffHoliday();
  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'ADD HOLIDAY'} onClick={onAdd} />
        </div>
        {grid.render()}
      </div>
      {editModal.render()}
      {detailModal.render()}
      {editStaffModal.render()}
    </div>
  );
};

function useStaffHoliday() {
  // staff modal
  const editStaffModal = useStaffEditModal(async (mode, data) => {
    await onSaveStaff(data);
  });
  const onSaveStaff = (data) => {
    const payload = {
      ...data,
      isActive: data.isActive === true || data.isActive?.[0] === 'on',
    };
    api.staffEdit(payload).then((res) => {
      if (res) {
        util.showSuccess('Staff has been saved successfully!');
        editStaffModal.close();
        onSearch();
      }
    });
  };
  const grid = useStaffHolidayGridView((action, data) => {
    if (action === 'edit') {
      editModal.open('copy', data);
    }
    if (action === 'detail') {
      detailModal.open(data);
    }
  }, editStaffModal);
  const onSearch = () => {
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
    };
    grid.query(listParam);
  };
  const onSave = (mode, data) => {
    if (data === undefined || (!data.uid || !data.startDate || !data.endDate || !data.totalDays || !data.totalDays || !(data.usedDays === 0 || data.usedDays === '0' || data.usedDays))) {
      return util.showWarning(`Please fill all the required fields`);
    }
    const totalDays = data.totalDays === '0' ? 0 : parseInt(data.totalDays);
    const payload = {
      ...data,
      userId: data.uid,
      // startDate: data.endDate ? util.toStartTS(data.startDate) : undefined,
      // endDate: data.endDate ? util.toEndTS(data.endDate) : undefined,
      startDate: data.endDate ? util.formatD(data.startDate) : undefined,
      endDate: data.endDate ? util.formatD(data.endDate) : undefined,
      totalDays,
      usedDays: parseFloat(data.usedDays),
      uid: undefined,
      uname2: undefined,
    };
    console.log('payload: ', data, totalDays, payload);
    if (payload.id) {
      api.staffHolidayEdit(payload).then(res => {
        if (res) {
          onRefreshList('Holiday has been saved successfully!');
        }
      });
    } else {
      api.staffHolidayAdd(payload).then(res => {
        if (res) {
          onRefreshList('Holiday has been saved successfully!');
        }
      });
    }
  };
  const onDelete = (data) => {
    if(data) {
      util.showConfirm('Are you sure to delete?', async () => {
        api.staffHolidayDel({id: data.id}).then((res) => {
          if(res) {
            onRefreshList('Holiday has been deleted successfully!');
          }
        });

      })
    }
  };
  const onCopy = (data) => {
    if(data) {
      util.showConfirm('Are you sure to copy?', async () => {
        api.staffHolidayCopy({id: data.id}).then((res) => {
          if(res) {
            onRefreshList('Holiday has been copied successfully!');
          }
        });
      })
    }
  };
  const onRefreshList = (title) => {
    util.showSuccess(title);
    editModal.close();
    onSearch();
  };
  const editModal = useHolidayEditModal(async (mode, data) => {
    if(mode === 'delete') {
      await onDelete(data);
    } else if (mode === 'copy') {
      await onCopy(data);
    } else {
      await onSave(mode, data);
    }
  });
  const detailModal = useHolidayDetailModal();
  const onAdd = () => {
    editModal.open('add', {});
  };
  React.useEffect(() => {
    onSearch();
  }, []);
  return {grid, onAdd, editModal, detailModal, editStaffModal};
}

export default StaffHoliday;
