/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import FormManager from "../../lib/FormManager";
import { Editor } from '@tinymce/tinymce-react';
import {SK_BRANCH} from "../account/AccountListSearchFormManager";
import {api} from "../../services/service";

const FN = {
  ID: 'id',
  SUBJECT: 'subject',
  BRANCH_ID: 'branch_id',
  PIC_NAME: 'pic_name',
  PIC_EMAIL: 'pic_email',
  MESSAGE: 'message',
};

class EmailFormManager extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'email-template',
      fields: [
        {name: FN.ID, serverName: FN.ID, label: '', hidden: true},
        {name: FN.SUBJECT, serverName: FN.SUBJECT, label: 'Subject'},
        {name: FN.BRANCH_ID, serverName: FN.BRANCH_ID, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: FN.PIC_NAME, serverName: FN.PIC_NAME, label: 'Name Title'},
        {name: FN.PIC_EMAIL, serverName: FN.PIC_EMAIL, label: 'E-mail'},
        {name: FN.MESSAGE, serverName: FN.MESSAGE, label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
        useDeleteButton: true,
      },
    });
  }

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const areaOptions = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).options = [...areaOptions];
    const handleEditorChange = (message, editor) => {
      _c(FN.MESSAGE, message);
    }
    return (
      <div className={'w-full'} style={{paddingBottom: '10px'}}>
        <div className={'flex'}>
          <div className={'flex-1 w-500'}>{_r(FN.SUBJECT)}</div>
          <div className={'flex-1 w-500'}>{_r(FN.BRANCH_ID)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 w-500'}>{_r(FN.PIC_NAME)}</div>
          <div className={'flex-1 w-500'}>{_r(FN.PIC_EMAIL)}</div>
        </div>
        <div style={{paddingBottom: '10px'}}>
          <Editor
            initialValue={_v(FN.MESSAGE)}
            init={{
              width: 900,
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | code | help'
            }}
            onEditorChange={handleEditorChange}
          />
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

export default EmailFormManager;
