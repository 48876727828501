/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import {apiBL, util} from '../../services/service';
import MarkFormManager from './MarkFormManager';

const FN = {
  ISSUED_PLACE: 'issuedPlace',
  ONBOARD_DATE: 'onboardDate',
  NUM_ORG_BL: 'numOrgBl',
  ITN_NO: 'itnNo',
  FOR_TR_TO: 'forTrTo',
  ONWARD_ROUTE: 'onwardRoute',
  CARRIER: 'carrier',
  CARRIER_ID: 'carrierId',
  EXPRESS_BL: 'expressBl',
  FREIGHT_TERM: 'freightTerm',
  SAID: 'said',
  SAY: 'say',
  KG: 'kb',
  LB: 'lb',
  CBM: 'cbm',
  CFT: 'cft',
  SHOW_AS: 'showAs',
  MARK: 'mark',
  DESCRIPTION: 'desc',
  DESC_OPT: 'descOption',
  REMARK: 'remark',
  PO_NO: 'poNo',
  ITEM_NO: 'itemNo',
};

const LB = {

};

class MarkOEHFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  blForm;
  constructor() {
    super({
      prefix: 'mark-OEH',
      fields: [
        {name: FN.ISSUED_PLACE, serverName: FN.ISSUED_PLACE, label: ''},
        {name: FN.ONBOARD_DATE, serverName: FN.ONBOARD_DATE, label: '', type: 'date', mask: util.MASK_DATE},
        {name: FN.NUM_ORG_BL, serverName: FN.NUM_ORG_BL, label: ''},
        {name: FN.ITN_NO, serverName: FN.ITN_NO, label: ''},
        {name: FN.FOR_TR_TO, serverName: FN.FOR_TR_TO, label: ''},
        {name: FN.ONWARD_ROUTE, serverName: FN.ONWARD_ROUTE, label: ''},
        {name: FN.CARRIER, serverName: FN.CARRIER, label: ''},
        {name: FN.CARRIER_ID, serverName: FN.CARRIER_ID, label: ''},
        {name: FN.EXPRESS_BL, serverName: FN.EXPRESS_BL, label: ''},
        {name: FN.FREIGHT_TERM, serverName: FN.FREIGHT_TERM, label: ''},
        {name: FN.SAID, serverName: FN.SAID, label: '', type: 'textarea'},
        {name: FN.SAY, serverName: FN.SAY, label: '', type: 'textarea'},
        {name: FN.KG, serverName: FN.KG, label: ''},
        {name: FN.LB, serverName: FN.LB, label: ''},
        {name: FN.CBM, serverName: FN.CBM, label: ''},
        {name: FN.CFT, serverName: FN.CFT, label: ''},
        {name: FN.SHOW_AS, serverName: FN.SHOW_AS, label: '', options: showAsOptions, noDefOption: true},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: '', type: 'textarea'},
        {name: FN.DESC_OPT, serverName: FN.DESC_OPT, label: '', options: descOptions, noDefOption: true, smallMargin: true},
        {name: FN.REMARK, serverName: FN.REMARK, label: '', type: 'textarea'},
        {name: FN.PO_NO, serverName: FN.PO_NO, label: '', type: 'textarea'},
        {name: FN.ITEM_NO, serverName: FN.ITEM_NO, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    // const upperField = (label, name) => {
    //   return (
    //     <div className={'flex-column mr-8'}>
    //       {label ? <div className={'ml-2 mb-1'}>{label}</div> : null}
    //       {name ? _r(name) : null}
    //     </div>
    //   );
    // };
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          {/*<div className={'pt-8'}><b>Gross Weight</b></div>*/}
          {/*<div className={'w-96 mx-8'}>{_r(FN.KG)}</div>*/}
          {/*<div className={'pt-8'}>KG</div>*/}
          {/*<div className={'w-96 mx-8'}>{_r(FN.LB)}</div>*/}
          {/*<div className={'pt-8 mr-16'}>LB</div>*/}
          {/*<div className={'pt-8'}><b>Measurement</b></div>*/}
          {/*<div className={'w-96 mx-8'}>{_r(FN.CBM)}</div>*/}
          {/*<div className={'pt-8'}>CBM</div>*/}
          {/*<div className={'w-96 mx-8'}>{_r(FN.CFT)}</div>*/}
          {/*<div className={'pt-8 mr-16'}>CFT</div>*/}
          <div className={'pt-8'}><b>Show Weight on B/L as</b></div>
          <div className={'w-96 ml-8'}>{_r(FN.SHOW_AS)}</div>
          <div className={'pt-8 ml-8'}>For Transshipment To</div>
          <div className={'w-128 ml-8'}>{_r(FN.FOR_TR_TO)}</div>
          <div className={'pt-8 ml-8'}>Onward Inland Routing</div>
          <div className={'w-128 ml-8'}>{_r(FN.ONWARD_ROUTE)}</div>
        </div>
        {/*<div className={'flex'}>*/}
        {/*  {upperField('Issued Place', FN.ISSUED_PLACE)}*/}
        {/*  {upperField('Onboard Date', FN.ONBOARD_DATE)}*/}
        {/*  {upperField('No. of Org. B/L', FN.NUM_ORG_BL)}*/}
        {/*  {upperField('ITN No./Remark', FN.ITN_NO)}*/}
        {/*  {upperField('For Transshipment To', FN.FOR_TR_TO)}*/}
        {/*  {upperField('Onward Inland Routing', FN.ONWARD_ROUTE)}*/}
        {/*</div>*/}
        <div className={'flex'}>
          <div className={'flex-1 mr-8'}>
            <div className={'ml-2 mb-1'}>Said</div>
            {_r(FN.SAID)}
          </div>
          <div className={'flex-1'}>
            <div className={'ml-2 mb-1'}>Say</div>
            {_r(FN.SAY)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'pt-8'}><b>Carrier</b></div>
          <div className={'mx-8'}>{_r(FN.CARRIER)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            <div>Description <span style={{color: 'red'}}>(Max 13 lines)</span></div>
            {_r(FN.DESC_OPT)}
            {_r(FN.DESCRIPTION)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-2 mr-8'}>
            {_l('Booking Remark')}
            {_r(FN.REMARK)}
          </div>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('P/O No.')}
            {_r(FN.PO_NO)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Item No.')}
            {_r(FN.ITEM_NO)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const showAsOptions = [
  {value: 'KG', label: 'KG'},
  {value: 'LB', label: 'LB'},
];

const descOptions = [
  {value: 'SAID TO CONTAIN', label: 'SAID TO CONTAIN'},
  {value: `SHIPPER'S LOAD AND COUNT`, label: `SHIPPER'S LOAD AND COUNT`},
  {value: ' ', label: 'BLANK'},
];

const form = new MarkOEHFormManager();

export function useMarkOEHModal(onSave, blForm) {
  const [isInited, setIsInited] = React.useState();
  const [uname, setUname] = React.useState('');
  const title = !isInited ? `( EDITED by ${uname??''} )` : '';
  form.blForm = blForm;
  const modal = useSimpleEditModal({
    title: `OEH Mark & Desc. ${title}`,
    width: 1024,
    form,
    onSave,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      setIsInited(blForm.getValue('isInited'));
      setUname(blForm.getValue('uname'));
      setTimeout(() => { // NOTE: 이미 modal.isOpen 훅이 작동을 했으므로 다음 프레임에서 처리를 해주어야 폼에 값이 제대로 설정됨 (무슨 말이지???)
        let info = '';
        let tpSize = '';
        const packages =  blForm.getValue('package') ? `${blForm.getValue('package')} ${blForm.getValue('packageType')}` : '';
        apiBL.getContainers(blForm.getValue('id')).then(({data}) => {
          if (data?.length > 0) {
            for (const {container_no, seal_no, tp_size} of data) {
              if(container_no || seal_no) {
                info += `${container_no} / ${seal_no}\n`;
                tpSize += tp_size ? `${tp_size} x 1\n` : '';
              }
            }
          }
          // const said = packages ? `${packages}\n${tpSize}` : tpSize;
          const said = packages ? packages : '';
          if (!form.getValue(FN.DESCRIPTION)) {
            form.setValue(FN.DESCRIPTION, blForm.getValue('commodity'));
          }
          if (!form.getValue(FN.MARK)) {
            form.setValue(FN.MARK, info);
          }
          if (!form.getValue(FN.SAID)) {
            form.setValue(FN.SAID, said);
          }
          if (!form.getValue(FN.SAY)) {
            form.setValue(FN.SAY, said);
          }
        });
      }, 0);
    }
  }, [modal.isOpen]);
  return modal;
}

export default MarkOEHFormManager;
