/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../components/SimpleModal';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {apiSettings, util} from '../../services/service';
import MasterCourierPriceEditFormManager from "../../forms/settings/MasterCourierPriceEditFormManager";

const courierPriceForm = new MasterCourierPriceEditFormManager();

export default function useCourierPriceModal() {
  const [partnerId, setPartnerId] = React.useState(0);
  const [partner, setPartner] = React.useState();
  const [carrierType, setCarrierType] = React.useState();
  const gridRef = React.useRef();
  const grid = useSimpleGrid({
    columns: [
      {field: 'id', hide: true},
      {field: 'weight', headerName: 'Weight(kg) / CBM', editable: isEditable, flex: 1, valueFormatter: util.formatWeight},
      {field: 'price', headerName: 'Price', editable: isEditable, flex: 1, valueFormatter: util.currencyFormatter},
    ],
    height: 500,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: (action, data) => {
      if (action === 'delete') {
        const currentGrid = gridRef.current;
        const index = currentGrid.rows.findIndex(i => i['id'] === data['id']);
        const newRows = [...currentGrid.rows];
        newRows.splice(index, 1);
        currentGrid.setRows(newRows);
      }
    },
    agGridProps: {
      onCellValueChanged(e) {
        const {colDef: {field}, newValue} = e;
        const row = grid.rows.find(i => i.id === e.data.id);
        if (field === 'weight') {
          row['weight'] = newValue;
        } else if (field === 'price') {
          row['price'] = newValue;
        }
        grid.setRows([...grid.rows]);
      }
    }
  });

  courierPriceForm.grid = grid;

  const modal = useSimpleModal({
    title: 'Vendor Table',
    centered: true,
    width: 500,
    buttons: getButtons(),
    children: (
      <div className={'w-full'}>
        {courierPriceForm.renderForm()}
        {grid.render()}
      </div>
    )
  });

  function getButtons() {
    return [{label: 'SAVE', color: 'primary', onClick: onSave}];
  }

  function onSave() {
    const gridInfo = courierPriceForm.grid.rows;
    const id = courierPriceForm.id;
    const priceList = [];

    for(let i=0; i<gridInfo.length; i++) {
      const price = parseFloat(gridInfo[i].price);
      if(!price) {
        util.showWarning('Please enter Price data!!');
        return;
      }
    }

    if(partnerId === 0 && partner === 'Default') {
    } else {
      if(!id) {
        util.showWarning('Please enter Partner data!');
        return;
      }
    }

    if(!gridInfo.length) {
      util.showWarning('Please enter Weight or Price data!');
      return;
    }

    gridInfo.map((value) => {
      const list = [];
      list.push(util.toFloat(value.weight));
      list.push(util.toFloat(value.price));
      priceList.push(list);
    });

    const data = {partnerId: id, priceList: priceList, carrierType};
    const res = apiSettings.setCourierPrice(data);
    if(res) {
      modal.close();
    }
  }

  function getData(cType) {
    setCarrierType(cType??carrierType);
    apiSettings.getCourierPrice(partnerId, cType??carrierType).then(res => {
      const {data} = res;
      if (data && data.length > 0) {
        const gridInfo = data[0].price_list;
        courierPriceForm.id = partnerId;
        const newRows = [];
        for (let i = 0; i < gridInfo.length; i++) {
          const id = i;
          const weight = gridInfo[i][0];
          const price = gridInfo[i][1];
          const rows = {id, weight, price};
          newRows.push(rows);
        }
        grid.setRows(newRows);
      }
    });
  }

  function getEmptyData() {
    grid.setRows([]);
    courierPriceForm.setValue('partnerId', undefined);
    courierPriceForm.setValue('partner', undefined);
    courierPriceForm.setValue('carrierType', undefined);
    setPartnerId(undefined);
    setPartner(undefined);
    setCarrierType(undefined);
  }

  courierPriceForm.getData = getData;

  gridRef.current = grid;
  React.useEffect(() => {
    if (modal.isOpen) {
      if (partnerId) {
        // getData();
        courierPriceForm.setValue('partnerId', partnerId);
        courierPriceForm.setValue('partner', partner);
        courierPriceForm.setValue('carrierType', carrierType);
      } else {
        // if(partner === 'Default') {
        //   // getData();
        // } else {
        //   getEmptyData();
        // }
      }
    }
  }, [modal.isOpen]);

  const open = (data) => {
    if(data) {
      setPartnerId(data.partner_id);
      setPartner(data.partner_name);
      setCarrierType(data.carrier_type);
    }
    modal.open();
  };

  return {...modal, open};
}

function isEditable(p) {
  let result = true;
  if(p.data.done) {
    result = false;
  }
  return result;
}
