/**
 * @flow
 */
import React from "react";
import FormManager from "../../lib/FormManager";

export default class AccountRequestStatementFormManager extends FormManager {
  partnerData;
  constructor() {
    super({
      prefix: 'account-request-statement',
      fields: [
        {name: 'memo', serverName: 'memo', label: 'Memo', type: 'textarea', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;

    return (
      <>
        {_r('memo')}
      </>
    );
  };
}
