/**
 * @flow
 */
import {apiAccount, util} from "../../services/service";
import {useDataGridView} from "../../components/DataGridView";
import {INVOICE} from "../../forms/field-defs/account";
import React from "react";
import {useUser} from "../../redux/reducers/userReducer";

export default function useAccountGridView(onActionCb, onGridReady, onQueryData, accountAPEditModal, searchForm) {
  const user = useUser();
  const isDirectorRef = React.useRef();
  const isManagerRef = React.useRef();
  const isAccountTeamRef = React.useRef();
  const userBranchIdRef = React.useRef();
  const isAccountRef = React.useRef();
  isDirectorRef.current = user.isDirector;
  isManagerRef.current = user.isManager;
  isAccountTeamRef.current = user.team_main === 'A';
  userBranchIdRef.current = user.branch_id;
  isAccountRef.current = getBranchName(userBranchIdRef.current, searchForm) === 'TORONTO' ? true : (isDirectorRef.current || (isManagerRef.current && isAccountTeamRef.current));
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      const {data = [], totalCount} = await apiAccount.accountList(gridInfo);
      let tAmount = 0, tBalance = 0;
      if (data?.length > 0) {
        data.forEach(value => {
          tAmount += value.amount;
          // if(!gridInfo.invType) {
          //   if(value.acc_type !== INVOICE.TYPE_DC_NOTE) {
          //     tBalance += value.acc_type === INVOICE.TYPE_AP ? value.balance * -1 : value.balance;
          //   }
          // } else {
          //   tBalance += value.balance;
          // }
          // tBalance += value.acc_type === INVOICE.TYPE_AP ? value.amount * -1 : value.amount;
          tBalance += value.balance;
        })
      }
      await onQueryData(tAmount, tBalance, gridInfo);
      return {
        totalCount,
        data: data?.map(r => ({
          ...r,
          bl_no: r.bl_no ? `="${r.bl_no}"` : '',
          inv_date: util.formatD(r.inv_date),
          due_date: util.formatD(r.due_date),
          paid_date: util.formatD(r.paid_date),
        })),
      };
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
        // TODO
      }
    },
    name: `ListAccount`,
    label: 'List All for Account',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'List'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'commission'],
    // isActionDisabled: (action, data) => {
    //   const isBeforeOct = util.toEndDateTS(util.getSpecificDate('2022-09-30')) >= util.toEndDateTS(util.getSpecificDate(data.inv_date));
    //   const isBranch = onChcekBranch(data.jextra.financialOffice, userBranchIdRef.current, getBranchName(userBranchIdRef.current, searchForm), data.branch_name);
    //   const isShowPayment = isBeforeOct ? isAccountRef.current : (isBranch && isAccountRef.current);
    //   const isDisabled = !((data.acc_type === INVOICE.TYPE_AR || data.acc_type === INVOICE.TYPE_CM || data.acc_type === INVOICE.TYPE_VD) && isAccountTeamRef.current && isShowPayment);
    //   return action === 'edit' ? false : isDisabled;
    // },
    isActionDisabled: (action, data) => {
      const isShowPayment = getBranchName(userBranchIdRef.current, searchForm) !== 'TORONTO';
      const isDisabled = !((data.acc_type === INVOICE.TYPE_AR || data.acc_type === INVOICE.TYPE_CM || data.acc_type === INVOICE.TYPE_VD) && isAccountTeamRef.current && isShowPayment);
      return action === 'edit' ? false : isDisabled;
    },
    actionTooltips: {
      commission: 'Payment',
    },
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    onGridReady,
    columns: [
      {field: 'id', hide: true},
      {field: 'branch_name', headerName: 'Branch', width: 140},
      {field: 'acc_type', headerName: 'Type', width: 100},
      {field: 'inv_no', headerName: 'Inv No.', cellRendererFramework: (param) => accountLinkFormatter(param, accountAPEditModal), width: 100},
      {field: 'filing_no', headerName: 'Filing No.', cellRendererFramework: blLinkFormatter, width: 100},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: blLinkFormatter, width: 100},
      {field: 'bill_to', headerName: 'Bill To / Partner', flex: 1, minWidth: 140},
      {field: 'inv_date', headerName: 'Inv. Date', width: 100},
      {field: 'due_date', headerName: 'Due Date', width: 100},
      {field: 'currency', headerName: 'Currency', width: 50},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.twoDecimalPlaceFormatter, width: 100},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.twoDecimalPlaceFormatter, width: 100},
      {field: 'paid_date', headerName: 'Paid', width: 100},
      {field: 'note_no', headerName: 'Note#', cellRendererFramework: accountDCNoteLinkFormatter, width: 100},
    ],
  });
}

export function blLinkFormatter(param) {
  if(param.data['h_m']) {
    let hm = 'master';
    if(param.data['h_m'] === 'H') {
      hm = 'house';
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        // util.nav(`/admin/bl/${hm}/${param.data['bl_id']}`);
        util.openTab(`/admin/bl/${hm}/${param.data['bl_id']}`);
      }}>
        {/*{param.value}*/}
        {param?.value ? param?.value.toString().replaceAll('"', '').replace('=', '') : ''}
      </a>
    );
  } else {
    return param?.value ? param?.value.toString().replaceAll('"', '').replace('=', '') : '';
  }
}

function accountLinkFormatter(param, accountAPEditModal) {
  if(param.data['id']) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        const accType = param.data['acc_type'] === INVOICE.TYPE_DC_NOTE ? 'dcnote' : param.data['acc_type'];
        if (accType === INVOICE.TYPE_AP && accType === param.data['bl_no']) {
          accountAPEditModal.open('print', param.data);
        } else {
          util.openTab(`/admin/account/list/${accType.toLowerCase()}?id=${param.data['id']}`);
        }
      }}>
        {param.value}
      </a>
    );
  } else {
    return param.value;
  }
}

function accountDCNoteLinkFormatter(param) {
  if(param.data['ref_no']) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
          util.openTab(`/admin/account/list/dcnote?id=${param.data['ref_no']}`);
      }}>
        {param.value}
      </a>
    );
  } else {
    return param.value;
  }
}

// Toronto 브랜치는 Vancouver, Seoul 브랜치 데이터 수정 못하고, Vancouver, Seoul 브랜치는 Toronto 브랜치 데이터 수정 못함.
const onChcekBranch = (branchId, userBranchId, userBranchName, branchName) => {
  const isUserVancouverSeoul = userBranchName === 'VANCOUVER' || userBranchName === 'SEOUL';
  const isUserToronto = userBranchName === 'TORONTO';
  const isDataVancouverSeoul = branchName === 'VANCOUVER' || branchName === 'SEOUL';
  const isDataToronto = branchName === 'TORONTO';
  if (branchId && userBranchId && userBranchId !== branchId) {
    if ((isUserVancouverSeoul && isDataToronto) || (isUserToronto && isDataVancouverSeoul)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const getBranchName = (branchId, form) => {
  let branchOptions;
  try {
    branchOptions = form.getField('branchId').options;
  } catch (error) {
    branchOptions = [];
  }
  return branchOptions?.find(i => i.value === parseInt(branchId))?.label;
}
