/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import AccountInvoiceVDFormManager, {getAccountEntryData, getAccountFormData} from '../../forms/account/AccountInvoiceVDFormManager';
import AccountTopSearchFormManager from '../../forms/account/AccountTopSearchFormManager';
import AccountInvoiceAddItemFormManager from '../../forms/account/AccountInvoiceAddItemFormManager';
import {DangerButton, DefaultButton} from '../../components/buttons';
import {apiAccount, util} from '../../services/service';
import {INVOICE} from "../../forms/field-defs/account";
import {useInvoiceVDPreviewModal} from "../../print-layouts/InvoiceVDPrint";
import {useUser} from "../../redux/reducers/userReducer";
import useVendorDetailAllModal from "../../modals/account/useVendorDetailAllModal";
import {useAsyncStatusModal} from "../../components/AsyncStatusModal";
import useAccountPaymentModal from "../../modals/account/useAccountPaymentModal";

const type = INVOICE.TYPE_VD;
const title = 'Vendor Invoice';
// let id = new URL(window.location.href).searchParams.get('id');

const accountForm = new AccountInvoiceVDFormManager();
const accountSearchForm = new AccountTopSearchFormManager(type);
const accountInvoiceAddItem = new AccountInvoiceAddItemFormManager(type);

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const AccountInvoiceVD = () => {
  useTopbar({label: 'Account'}, {label: title});
  const invoiceNoRef = React.useRef();
  const user = useUser();
  const teamRef = React.useRef();
  const isManagerRef = React.useRef();
  teamRef.current = user.team_main;
  isManagerRef.current = user.isManager;
  const qs = util.getQS();
  const {id} = qs;
  const [mode, setMode] = React.useState('add');
  const [isApplied, setIsApplied] = React.useState(false);
  document.title = `${title} ${mode}`;
  const invoiceVDPrintModal = useInvoiceVDPreviewModal();
  const invoiceVDDetailAllModal = useVendorDetailAllModal();
  const accountPaymentModal = useAccountPaymentModal();

  const onSave = async () => {
    // if(!util.isValidPermitForAccount(user, mode)) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    const values = accountForm.getValues(undefined, true);
    // const values = accountForm.getValues();
    if (!values) {
      return;
    }
    const gridData = accountInvoiceAddItem.getGridData();
    const data = getAccountEntryData(values, gridData);
    const invDate = util.toTS(data.inv_date);
    const dataInfo = {...data, invDate};
    const res = await apiAccount.setVendorInvoice(dataInfo);
    if (res) {
      util.showSuccess('Account Entry has been saved successfully.');
      onSearch();
    }
  };

  const onPrint = () => {
    apiAccount.accountVDDataDetail({id: id}).then((res) => {
      const {data} = res;
      invoiceVDPrintModal.open(accountForm, accountSearchForm, accountInvoiceAddItem, data);
    });
  }

  const onDelete = () => {
    if(id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.delAccountVD(parseInt(id));
        if(res) {
          util.showSuccess('Account has been deleted successfully!');
          util.nav('/admin/account/list');
        }
      })
    }
  };

  const onDetailAll = () => {
    invoiceVDDetailAllModal.open(id);
  };

  accountInvoiceAddItem.VDDetailAll = onDetailAll;

  const onSearch = () => {
    setMode('edit');
    apiAccount.vendorInvoiceList({id: id}).then(({data}) => {
      if (Array.isArray(data)) {
        const {formData, gridData} = getAccountFormData(data[0]);
        const [{inv_no}] = data;
        invoiceNoRef.current = inv_no;
        accountSearchForm.mode = mode;
        accountForm.setValuesFast(formData);
        accountInvoiceAddItem.setAccountId(id);
        accountInvoiceAddItem.setPartnerId(formData.partnerId);
        accountInvoiceAddItem.setGridData(gridData);
        accountInvoiceAddItem.setDcRate(formData.dcRate2);
        accountInvoiceAddItem.setPaidAmount(data[0].paid_amount);
        accountInvoiceAddItem.onApply = onApply;
        const isDCRate = data[0].dc_rate ? true : false;
        accountInvoiceAddItem.isApplied = isDCRate;
        setIsApplied(isDCRate);
      } else {
        // api 에서 오류를 처리하므로 여기서는 그냥 아무작업도 하지 않음
      }
    });
  };

  const asyncStatusModal = useAsyncStatusModal('Processing... please wait...');
  const onApply = (accountId, dcRate) => {
    if(!dcRate) {
      return util.showWarning('Please enter DC Rate!');
    }
    asyncStatusModal.open();
    apiAccount.applyVendorDCRate({accountId: util.toInt(accountId), dcRate: util.toFloat(dcRate)}).then(res => {
      if(res) {
        asyncStatusModal.open();
        util.showSuccess('DC Rate has been applied successfully.');
        accountInvoiceAddItem.isApplied = true;
        setIsApplied(true);
        onSearch();
      }
    }).finally(() => asyncStatusModal.close());
  };

  accountInvoiceAddItem.onSearch = onSearch;

  React.useEffect(() => {
    if (id) {
      onSearch();
    }
  }, [id]);

  const paymentHistory = () => {
    accountPaymentModal.open(id ?? 0, invoiceNoRef.current ?? '');
  }

  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              <DefaultButton label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
              <DefaultButton label={'P. History'} onClick={paymentHistory} noMargin disabled={id && teamRef.current === 'A' ? false : true} /><div className={'w-8'} />
              <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
              {isManagerRef.current && (<><DangerButton label={'Delete'} onClick={onDelete} disabled={isApplied} noMargin /><div className={'w-8'} /></>)}
            </div>
          </div>
          {accountSearchForm.renderForm()}
        </CardBody>
      </Card>
      {accountForm.renderForm()}
      {accountInvoiceAddItem.renderForm()}
      {invoiceVDPrintModal.render()}
      {invoiceVDDetailAllModal.render()}
      {asyncStatusModal.render()}
      {accountPaymentModal.render()}
    </>
  );
};

export default AccountInvoiceVD;
