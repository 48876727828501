/**
 * @flow
 */
import React from 'react';

class AEMTC extends React.Component {
  render() {
    const {printData = {}, user = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {additionalSecurityInformation, airWaybill, consignment, consolidation,
      otherScreeningMethod, reasonForIssuing, receivedFrom, regulatedEntity, screeningMethod,
      securityStatus, securityStatusIssuedBy, securityStatusIssuedOn, shipper, shipperAddress, transfer} = printFormData;
    const {departure, destination} = blData.jroute;
    const {blNo} = blData;
    let isChecked = false;
    if(consolidation) {
      if(consolidation[0] === 'on') {
        isChecked = true;
      } else {
        isChecked = false;
      }
    }
    return (
      <div style={{color: 'black', fontSize: '16px'}}>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td colSpan={6} className={'no-border-all'} width={'50px'}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6} className={'center'}>
              <div style={{whiteSpace: 'pre-line'}}><h4>{shipper}</h4></div>
              <div style={{whiteSpace: 'pre-line'}}><h4>{shipperAddress}</h4></div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className={'center'}>
              <div><h2><b>CANADIAN SECURE CARGO DECLARATION</b></h2></div>
              <div><h2>{shipper}</h2></div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={'no-border-b'}>
              <div>1) Program number of the regulated entity:</div>
            </td>
            <td colSpan={3}>
              <div>2) Air waybill or similar control document:</div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={'no-border-t center'}>
              <div><h1><b>{regulatedEntity}</b></h1></div>
            </td>
            <td colSpan={3} className={'center'}>
              <div><h1><b>{blNo}</b></h1></div>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div>3) Contents of consignment:</div>
            </td>
          </tr>
          <tr>
            <td className={'center'}>
              {this.renderConsolidation(isChecked)}
            </td>
            <td rowSpan={2} colSpan={5}>
              <div><h2>{consignment}</h2></div>
            </td>
          </tr>
          <tr>
            <td className={'center'}>
              <div>&nbsp;</div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div>4) Origin:</div>
            </td>
            <td colSpan={2}>
              <div>5) Destination:</div>
            </td>
            <td colSpan={2}>
              <div>6) Transfer/Transit points (if known):</div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className={'center'}>
              <div><h2><b>{departure}</b></h2></div>
            </td>
            <td colSpan={2} className={'center'}>
              <div><h2><b>{destination}</b></h2></div>
            </td>
            <td colSpan={2} className={'center'}>
              <div>{transfer}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>7) Security Status:</div>
            </td>
            <td colSpan={2} className={'no-border-r'}>
              <div>Reasons for issuing security status:</div>
            </td>
            <td colSpan={3} className={'no-border-l'}>
              <div>{reasonForIssuing}</div>
            </td>
          </tr>
          <tr>
            <td rowSpan={2} className={'center'}>
              <div><h1><b>{securityStatus}</b></h1></div>
            </td>
            <td colSpan={2}>
              <div>8) Received from (code):</div>
            </td>
            <td>
              <div>9) Screening method:</div>
            </td>
            <td colSpan={2} className={'no-border-b pointColor'}>
              <div>10) Grounds for exemption (code):</div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className={'center'}>
              <div>{receivedFrom}</div>
            </td>
            <td className={'center'}>
              <div><h1><b>{screeningMethod}</b></h1></div>
            </td>
            <td colSpan={2} className={'no-border-t center pointColor'}>
              <div>&nbsp;</div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className={'no-border-b'}>
              <div>11) Other screening method(s) (if applicable):</div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className={'no-border-t center'}>
              <div>{otherScreeningMethod}</div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div>12) Security status issued by (name of ACR):</div>
            </td>
            <td colSpan={3}>
              <div>13) Security status issued on (YYYY-MM-DD):</div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={'center'}>
              <div><h2><b>{securityStatusIssuedBy}</b></h2></div>
            </td>
            <td colSpan={3} className={'center'}>
              <div><h2><b>{securityStatusIssuedOn}</b></h2></div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className={'pointColor'}>
              <div>14)</div>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div>15) Additional security information:</div>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div>{additionalSecurityInformation}</div>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div>Declaration for Known Consignors:</div>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div>
                On behalf of the regualated entity identified in box 1, the Authorized Cargo Representative identified in box 12 declares on the date in box 13
                that an Authorized Cargo Representative screened the cargo for threat items in accordance with the security measures and the cargo does not contain
                any threat item; and, access to the cargo was restricted and the cargo was not tampered with between the time it was screened for threat items
                and the time it was tendered.
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderConsolidation(isChecked) {
    if(isChecked) {
      return <div><input type={'checkbox'} name={'consolidation'} checked />&nbsp;Consolidation</div>;
    } else {
      return <div><input type={'checkbox'} name={'consolidation'} />&nbsp;Consolidation</div>;
    }
  }
}

export default AEMTC;
