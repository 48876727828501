/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {DefaultButton} from '../../components/buttons';
import {apiSettings, util} from "../../services/service";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const hawbCompanyOptions = [
  {value: 'cj', label: 'cj'},
  {value: 'kpost', label: 'kpost'},
];

class MasterHAWBFormManager extends FormManager {
  grid;
  constructor() {
    super({
      prefix: `master-HAWB-add-item-form`,
      fields: [
        {name: 'id', serverName: 'id', label: ''},
        // {name: 'startDate', serverName: 'start_date', label: '', type: 'date', mask: util.MASK_DATE},
        // {name: 'endDate', serverName: 'end_date', label: '', type: 'date', mask: util.MASK_DATE},
        {name: 'company', serverName: 'Company', label: '', options: hawbCompanyOptions},
        {name: 'prefix', serverName: 'prefix', label: ''},
        {name: 'hawbStart', serverName: 'hawb_start', label: ''},
        {name: 'hawbEnd', serverName: 'hawb_end', label: ''},
        {name: 'remark', serverName: 'remark', label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  getGridData() {
    let grid = this.grid;
    const rows = [];
    if (grid.apiRef && grid.apiRef.current) {
      grid.apiRef.current.forEachNode(node => rows.push(node.data));
    }
    return rows;
  }

  setGridData(gridData) {
    gridData = gridData.map((row) => {
      return {...row};
    });

    this.grid.setRows(gridData);
  }

  onRender = () => {
    const [editMode, setEditMode] = React.useState('add');
    const [id, setId] = React.useState(0);
    const grid = useHAWBItemGrid((e) => {
      setEditMode('edit');
      this.setValues(e.data);
      _c('company', e.data.company);
      setId(e.data.id);
    });

    this.grid = grid;
    const {_r, _c} = this.getRenderProps();

    const onSave = async () => {
      const values = this.getValues(undefined, true);
      if (values) {
        let {company, prefix, hawbStart, hawbEnd, remark} = values;

        // save data to db
        const params = {
          id,
          company: company.toLowerCase(),
          prefix,
          hawbStart: util.toInt(hawbStart),
          hawbEnd: util.toInt(hawbEnd),
          remark
        };
        let res = await apiSettings.setHAWB(params);
        if (res) {
          this.clearValues();
          onCancelEdit();
          onQuery().catch();
        }
      }
    };
    const onCancelEdit = () => {
      setEditMode('add');
      setId(0);
      this.clearValuesFast();
    };

    const onQuery = async () => {
      const {data} = await apiSettings.hawbList();
      grid.setRows(data);
    }

    const title = 'HAWB';
    React.useEffect(() => {
      // (async () => {
      //   const {data} = await apiSettings.hawbList();
      //   grid.setRows(data);
      // })();
      onQuery();
    }, []);

    function useHAWBItemGrid(onCellClicked) {
      function getColumns() {
        return [
          {field: 'id', hide: true},
          // {field: 'start_date', headerName: 'StartDate', type: 'date', valueFormatter: util.dateFormatter, flex: 1},
          // {field: 'end_date', headerName: 'EndDate', type: 'date', valueFormatter: util.dateFormatter, flex: 1},
          {field: 'company', headerName: 'Company', flex: 1},
          {field: 'cdate', headerName: 'Date', type: 'date', valueFormatter: util.dateFormatter, flex: 1},
          {field: 'prefix', headerName: 'Prefix', flex: 1},
          {field: 'hawb_start', headerName: 'From', flex: 1},
          {field: 'hawb_end', headerName: 'To', flex: 1},
          {field: 'remark', headerName: 'Remark', flex: 1},
        ];
      }
      async function onDelete(id) {
        const data = {id};
        if(id) {
          const res = await apiSettings.delHAWBNumber(data);
          if(res) {
            onQuery();
          }
        }
      }
      return useSimpleGrid({
        columns: getColumns(),
        height: 200,
        className: 'pb-20',
        actions: ['delete'],
        actionWidth: 70,
        onAction: (action, data) => {
          if (action === 'delete') {
            util.showConfirm('Are you sure to delete?', () => onDelete(data.id));
          }
        },
        agGridProps: {
          onCellClicked(e) {
            // when action cell clicked, ignore it
            onCellClicked(e);
          }
        }
      });
    }

    return (
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
            </div>
            {grid.render()}
            <div className={'flex w-full'}>
              {/*<div className={'flex-4 mr-8'}>*/}
              {/*  <div>StartDate</div>*/}
              {/*  <div>{_r('startDate')}</div>*/}
              {/*</div>*/}
              {/*<div className={'flex-4 mr-8'}>*/}
              {/*  <div>EndDate</div>*/}
              {/*  <div>{_r('endDate')}</div>*/}
              {/*</div>*/}
              <div className={'flex-3 mr-8'}>
                <div>Company</div>
                <div>{_r('company')}</div>
              </div>
              <div className={'flex-4 mr-8'}>
                <div>Prefix</div>
                <div>{_r('prefix')}</div>
              </div>
              <div className={'flex-3 mr-8'}>
                <div>From</div>
                <div>{_r('hawbStart')}</div>
              </div>
              <div className={'flex-3 mr-8'}>
                <div>To</div>
                <div>{_r('hawbEnd')}</div>
              </div>
              <div className={'flex-5 mr-8'}>
                <div>Remark</div>
                <div>{_r('remark')}</div>
              </div>
              <div className={'ml-20'}>{''}</div>
              {editMode === 'add'
                ? (
                  <div className={'mt-20 ml-20'}><DefaultButton label={'Add'} onClick={onSave} noMargin /></div>
                )
                : (
                  <div className={'mt-20 ml-20'}>
                    <DefaultButton label={'Edit'} onClick={onSave} />
                    <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
                  </div>
                )
              }
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  onValidate = (values) => {
    const {prefix, hawbStart, hawbEnd} = values;
    if (!(prefix && hawbStart && hawbEnd)) {
      util.showWarning('Please enter all required fields.');
      return null;
    }
    return values;
  };
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

export default MasterHAWBFormManager;
