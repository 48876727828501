/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {useUser} from "../../redux/reducers/userReducer";
import {currencyOptions, houseMasterOptions} from '../helper';
import {api} from "../../services/service";

const cargoTypeOptions = [
  {value: '', label: 'ALL'},
  {value: 'M', label: 'Moving'},
  {value: 'C', label: 'Courier'},
  {value: 'E', label: 'Ecommerce'},
  {value: 'F', label: 'Forwarding'},
  {value: 'U', label: 'UPS'},
  {value: 'P', label: 'Canada Post'},
];

class AccountProfitSearchFormManager extends FormManager {
  onSearch;
  constructor() {
    super({
      prefix: 'account-profit-search',
      fields: [
        {name: 'branchId', serverName: 'branchId', label: 'Branch', noDefOption: true, smallMargin: true},
        {name: 'cargoType', serverName: 'cargoType', label: 'Service', options: cargoTypeOptions, noDefOption: true, smallMargin: true},
        {name: 'currency', serverName: 'currency', label: 'Currency', options: currencyOptions, noDefOption: true, smallMargin: true},
        {name: 'HM', serverName: 'HM', label: 'House / Master', smallLabel: true, smallMargin: true, options: addAllOptions(houseMasterOptions), noDefOption: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    // const [formik, , fields, errors] = this.renderParams;
    const user = useUser();
    const areaOptions = api.useAreaOptions(true);
    _f('branchId').options = [{value: '', label: 'ALL'}, ...areaOptions];

    React.useEffect(() => {
      _c('currency', user.currency ?? 'CAD');
    }, [])
    const branchId = _v('branchId');
    const cargoType = _v('cargoType');
    const currency = _v('currency');
    const hm = _v('HM');

    React.useEffect(() => {
      if (!(branchId === undefined) || !(cargoType === undefined) || !(currency === undefined) || !(hm === undefined)) {
        this.onSearch();
      }
    }, [branchId, cargoType, currency, hm]);

    return (
      <>
        {_r('branchId')}
        {_r('cargoType')}
        {_r('currency')}
        {_r('HM')}
      </>
    );
  }
}

function addAllOptions(options: any[]) {
  return [{value: '', label: 'ALL'}, ...options];
}

export default AccountProfitSearchFormManager;
