/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {api} from '../../../../services/service';
import {FD, FN, kindOptions} from '../../../field-defs/newCoship';
import {KIND} from "../../courier/list/CourierBLListSearchFormManager";

class ForwardingSettingSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor() {
    super({
      prefix: `forwarding-setting-search-form`,
      fields: [FD.KIND, FD.BRANCH_ID],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const kind = _v(FN.KIND);
    _f(FN.BRANCH_ID).options = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).label = '';
    _f(FN.KIND).label = '';
    _f(FN.KIND).options = [{value: '', label: 'ALL'}, ...kindOptions];

    React.useEffect(() => {
      _c(KIND, 'F');
    }, []);

    React.useEffect(() => {
      if (kind !== undefined) {
        this.onSearch();
      }
    }, [kind]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default ForwardingSettingSearchFormManager;
