/**
 * @flow
 */
import React from "react";
import FormManager from '../../lib/FormManager';
import {FD, FN} from '../field-defs/account';
import {api, apiAccount, util} from "../../services/service";
import {TradePartnerSearchInput} from "../../components/SearchInput";
import {useUser} from "../../redux/reducers/userReducer";
import type {FormField} from "../../components/Form";

class AccountRequestEditFormManager extends FormManager {
  static FN = FN;
  constructor() {
    super({
      prefix: 'account-request-edit',
      fields: [],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        useDeleteButton: true,
      },
    });
    this.fields = this.getFields();
  }
  onRender = () => {
    return <INVOICE {...this.getRenderProps()} />;
  };
  onValidate = (values) => {
    const {invoiceDate, partner, partnerId} = values;
    if(!partner || !partnerId) {
      util.showWarning('Please enter a partner data!');
      return;
    }
    if (!invoiceDate || !util.isValidDate(invoiceDate)) {
      util.showWarning('Please enter a valid invoice data!');
      return;
    }
    return values;
  };
  getFields() {
    const fields: FormField[] = [];
    for (const key in FD) {
      if (FD.hasOwnProperty(key)) {
        fields.push(FD[key]);
      }
    }
    return fields.map(i => ({...i, name: `${this.prefix}-${i.name}`}));
  }
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function useSearchInput(props) {
  const {_n, formik, fields, errors, fm} = props;
  const _tp = (name, vertical = false) => <TradePartnerSearchInput
    vertical
    idField={_n(`${name}Id`)} displayField={_n(name)}
    formik={formik} fields={fields} errors={errors}
  />;
  return {_tp};
}

const INVOICE = (props) => {
  const {_r, _f, _v, _c} = props;
  const {_tp} = useSearchInput(props);
  const user = useUser();
  const branchField = _f(FN.FINANCIAL_OFFICE);
  const branchId = _v(FN.FINANCIAL_OFFICE);
  const currency = _v(FN.CURRENCY);
  const paidBy = _v(FN.PAID_BY);
  branchField.options = api.useAreaOptions();
  _f(FN.BANK_INFO).options = apiAccount.useBankList(branchId);

  React.useEffect(() => {
    if (!branchId && user.branch_id && branchField.options.length > 0) {
      _c(FN.FINANCIAL_OFFICE, user.branch_id);
    }
  }, [branchId, user.branch_id, branchField.options]);

  React.useEffect(() => {
    if(!currency) {
      _c(FN.CURRENCY, user.currency);
    }
  }, [currency]);

  React.useEffect(() => {
    if(!paidBy) {
      _c(FN.PAID_BY, 'EFT/ACH');
    }
  }, [paidBy]);

  const bankInfo = _v(FN.BANK_INFO);
  React.useEffect(() => {
    _f(FN.BANK_INFO).onChange = (name, value) => {
      if(value.includes('(CAD)')) {
        _c(FN.CURRENCY, 'CAD');
      } else if(value.includes('(USD)')) {
        _c(FN.CURRENCY, 'USD');
      } else if(value.includes('(KRW)')) {
        _c(FN.CURRENCY, 'KRW');
      } else {
        _c(FN.CURRENCY, user.currency);
      }
    }
  }, [bankInfo]);

  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-12'}>{_tp(FN.PARTNER)}</div>
        <div className={'flex-1 mr-12'}>{_r(FN.INVOICE_NO)}</div>
        <div className={'flex-1'}>{_r(FN.VENDOR_INVOICE_NO)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-12'}>{_r(FN.INVOICE_DATE)}</div>
        <div className={'flex-1 mr-12'}>{_r(FN.PAID_DATE)}</div>
        <div className={'flex-1'}>{_r(FN.FINANCIAL_OFFICE)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-12'}>{_r(FN.CURRENCY)}</div>
        <div className={'flex-1 mr-12'}>{_r(FN.PAID_BY)}</div>
        <div className={'flex-1'}>{_r(FN.BANK_INFO)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-3'}>{_r(FN.MEMO)}</div>
      </div>
    </>
  );
};

export function getRequestEntryData(_f) {
  const jextra = {
    [FN.PARTNER_ID]: _f[FN.PARTNER_ID],
    [FN.PARTNER]: _f[FN.PARTNER],
    [FN.MEMO]: _f[FN.MEMO],
    [FN.PAID_DATE]: util.toTS(_f[FN.PAID_DATE]),
    [FN.VENDOR_INVOICE_NO]: _f[FN.VENDOR_INVOICE_NO],
    [FN.CURRENCY]: _f[FN.CURRENCY],
    [FN.PAID_BY]: _f[FN.PAID_BY],
    [FN.BANK_INFO]: _f[FN.BANK_INFO],
    [FN.INVOICE_NO]: _f[FN.INVOICE_NO],
    [FN.INVOICE_DATE]: _f[FN.INVOICE_DATE],
    [FN.FINANCIAL_OFFICE]: _f[FN.FINANCIAL_OFFICE],
  };
  return {
    jextra
  };
}

export default AccountRequestEditFormManager;
