/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {api, util} from '../../../../services/service';
import {setCarrierType} from "../../../../redux/reducers/newCoshipReducer";
import {FD, FN} from '../../../field-defs/newCoship';

class MovingMasterBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `moving-master-form`,
      fields: [
        FD.MBL_NO,
        FD.BOOKING_NO,
        FD.PORT_TYPE,
        FD.BRANCH_ID,
        FD.KIND,
        FD.TRADE_TYPE,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const carrierType = _v(FN.PORT_TYPE);
    _f(FN.BRANCH_ID).options = api.useAreaOptions(true);

    React.useEffect(() => {
      if (carrierType) {
        setCarrierType(util.dispatch, carrierType);
      } else {
        _c(FN.CARRIER_TYPE, 'O');
        setCarrierType(util.dispatch, 'O');
      }
    }, [carrierType]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BOOKING_NO)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{''}</div>
          <div className={'flex-1 mr-2'}>{''}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default MovingMasterBLFormManager;
