/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from "../media/coship_logo.png";

class InvoiceRequestStatementPrint extends React.Component {
  totalAmount = 0;
  currency;
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountData, billToData, memo} = printData;
    return (
      <div style={{color: 'black'}}>
        {this.renderHeader(accountData, billToData, user)}
        <hr />
        <table className={'print-table bold-border'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td rowSpan={2} className={'invoice_label center'} width={'20%'} height={'100px'}>CONFIRM</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>OFFICER</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>MANAGER</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>GENERAL MANAGER</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>PRESIDENT</td>
          </tr>
          <tr>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
          </tr>
          <tr>
            <td rowSpan={2} className={'invoice_label center'} width={'20%'} height={'120px'}>RECIPIENT</td>
            <td colSpan={4} className={'invoice_label center'} width={'80%'} height={'50px'}>I properly accept the ($<span className={'left-space'}>)</span></td>
          </tr>
          <tr>
            <td className={'invoice_label center'} width={'20%'} height={'70px'}>NAME</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td colSpan={2} className={'invoice_label bottom_right'} width={'40%'} height={'70px'}>Signature</td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%', marginTop: '50px'}}>
          <tbody>
          <tr>
            <td className={'no-border-lr invoice_label'} width={'15%'}>Inv. Date</td>
            <td className={'no-border-lr invoice_label'} width={'20%'}>Inv. No.</td>
            <td className={'no-border-lr invoice_label'} width={'20%'}>Vendor Inv.</td>
            <td className={'no-border-lr invoice_label'} width={'15%'}>B/L No</td>
            <td className={'no-border-lr right invoice_label'} width={'10%'}>CURRENCY</td>
            <td className={'no-border-lr right invoice_label'} width={'20%'}>AMOUNT</td>
          </tr>
          {this.renderInvoiceItems(accountData)}
          {this.renderEmptyItems(accountData)}
          </tbody>
        </table>
        <hr />
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
          {this.renderInvoiceItemsTotal('TOTAL AMOUNT', this.currency, util.formatCurrency(this.totalAmount))}
          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
          {this.renderInvoiceExtraInfo('MEMO', memo)}
          </tbody>
        </table>
        <hr className={'line'} />
      </div>
    );
  }
  renderHeader(accountData, billToData, user) {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr style={{height: '20px'}}>
                <td className={'no-border-all right invoice_label'}><h1>REQUEST STATEMENT</h1></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '15px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>BILL TO :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{billToData.billTo}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>TEAM :</td>
                <td className={'no-border-all'}>{getTeam(user.team_main)}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>NAME :</td>
                <td className={'no-border-all'}>{user.uname}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'invoice_label'}>DATE</td>
                <td>{util.getCurrentDate()}</td>
              </tr>
              <tr>
                <td className={'invoice_label'}>TOTAL AMOUNT</td>
                <td className={'invoice_label right'}>{util.formatCurrency(this.totalAmount)}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceItems(items) {
    let tAmount = 0;
    return items.map((item, index) => {
      tAmount += item['amount'];
      this.totalAmount = tAmount;
      this.currency = item['currency'];
      return (
        <tr key={index}>
          <td className={'no-border-all'} width={'15%'}>{util.formatD(item['inv_date'])}</td>
          <td className={'no-border-all'} width={'20%'}>{item['inv_no']}</td>
          <td className={'no-border-all'} width={'20%'}>{item['vendorInv']}</td>
          <td className={'no-border-all'} width={'15%'}>{item['bl_no']}</td>
          <td className={'no-border-all right'} width={'10%'}>{item['currency']}</td>
          <td className={'no-border-all right'} width={'20%'}>{util.formatCurrency(item['amount'])}</td>
        </tr>
      );
    });
  }
  renderEmptyItems(items) {
    const empty = [];
    let length = 37 - items.length;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={6} className={'no-border-all'}>{'\u00a0'}</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceItemsTotal(label, value1, value2) {
    return (
      <tr>
        <td className={'no-border-all right invoice_label'}>{label}</td>
        <td className={'no-border-all right invoice_label'}>{value1}</td>
        <td className={'no-border-all right invoice_label'}>{value2}</td>
      </tr>
    );
  }
  renderInvoiceExtraInfo(label, value) {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-wrap', minHeight: '130px', fontSize: '0.8em', paddingTop: '3px', paddingBottom: '3px'}}>{value}</div></td>
      </tr>
    );
  }
}

export function useInvoiceRequestStatementPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountData = React.useRef();
  const billToDataRef = React.useRef();
  const memoRef = React.useRef();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceRequestStatementPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountData.current,
        billToData: billToDataRef.current,
        memo: memoRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data, billToData, memo) => {
      accountData.current = data;
      billToDataRef.current = billToData;
      memoRef.current = memo;
      modal.open();
    },
    print,
  };
}

const teamMainOptions = [
  {value: 'A', label: 'ALL'},
  {value: 'M', label: 'MOVING'},
  {value: 'C', label: 'COURIER'},
  {value: 'F', label: 'FORWARDING'},
];

const getTeam = (data) => {
  let teamMain = '';
  teamMainOptions.forEach(team => {
    if(team.value === data) teamMain = team.label;
  });
  return teamMain;
}

export default InvoiceRequestStatementPrint;
