/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';

class AEMPrint extends React.Component {
  totalOtherPP;
  totalOtherCC;
  render() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, markData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {awbNo, sci, byFirstCarrier, signatureOfShipper, signatureOfIssuingCarrier, destinationCountry,
    consigneeCharge, consigneeDesc, consigneeFor, deliveryReceiptCharge, deliveryReceiptDesc, deliveryReceiptFor,
    issuingCarrierCharge, issuingCarrierDesc, issuingCarrierFor, shipperCharge, shipperDesc, shipperFor} = printFormData;
    const {filingNo, HM} = blData;
    const {mawbNo, currency: hblCurrency} = blData.jcommon;
    const {shipperAddr = '', consigneeAddr = '', shipperAccNo = ''} = blData.jcustomer;
    const {accountInfo = '', handlingInfo = '', goods = '', showAs = 'KG'} = blData.jmark;
    const {departure, iata, acctNo, destination, trans1, trans1Flight, trans2, trans2Flight, issuingCarrier, issuingCarrierAddr} = blData.jroute;
    const {blCurrency, freight, otherCharge, dvCarriage, dvCustoms, insurance, package: packages, grossWeightKg, chargeableWeightKg, commodity, shippingInfo1, shippingInfo2} = blData.jshipment;
    const {carrier, flightNo, flightDate, flightTime} = blData.jvessel;
    let {air_rate, others, pp_cc, departure: airportOfDeparture, destination: airportOfDestination, bl_currency} = blData.blRate;
    const arranged = 'AS ARRANGED';
    const currency = HM === 'M' ? blCurrency : hblCurrency;
    let arrAwbNo1, arrAwbNo2;
    if(mawbNo) {
      arrAwbNo1 = mawbNo.split('-')[0];
      arrAwbNo2 = mawbNo.split('-')[mawbNo.split('-').length-1];
    }
    const wtVal_ppd = freight === 'PP' ? 'X' : '';
    const wtVal_coll = freight === 'CC' ? 'X' : '';
    const other_ppd = otherCharge === 'PP' ? 'X' : '';
    const other_coll = otherCharge === 'CC' ? 'X' : '';
    const printFor = [
      {name: 'SHIPPER', isChecked: shipperFor && shipperFor[0] === 'on' ? true : false},
      {name: 'ISSUING CARRIER', isChecked: issuingCarrierFor && issuingCarrierFor[0] === 'on' ? true : false},
      {name: 'CONSIGNEE', isChecked: consigneeFor && consigneeFor[0] === 'on' ? true : false},
      {name: 'DELIVERY RECEIPT', isChecked: deliveryReceiptFor && deliveryReceiptFor[0] === 'on' ? true : false}
    ];
    const lb = 2.20462;
    const grossWeight = showAs === 'LB' ? (grossWeightKg * lb).toFixed(2) : grossWeightKg;
    const chargeableWeight = showAs === 'LB' ? (chargeableWeightKg * lb).toFixed(2) : chargeableWeightKg;
    let totalWeight = 0, totalWeightPP = 0, totalWeightCC = 0, totalPP = 0, totalCC = 0;
    if(HM === 'M') {
      if(showAs === 'LB') {
        totalWeight = chargeableWeight && air_rate ? (chargeableWeight * air_rate).toFixed(2) : '';
      } else {
        totalWeight = grossWeight && air_rate ? (grossWeight * air_rate).toFixed(2) : '';
      }
    } else {
      totalWeight = '';
      air_rate = arranged;
    }
    if(pp_cc === 'PP') {
      totalWeightPP = HM === 'M' ? totalWeight : pp_cc === 'PP' ? arranged : '';
    } else {
      totalWeightCC = HM === 'M' ? totalWeight : pp_cc === 'CC' ? arranged : '';
    }
    totalPP = HM === 'M' ? (totalWeightPP && this.totalOtherPP ? (util.formatCurrency(util.toFloat(totalWeightPP) + util.toFloat(this.totalOtherPP))) : '') : '';
    totalCC = HM === 'M' ? (totalWeightCC && this.totalOtherCC ? (util.formatCurrency(util.toFloat(totalWeightCC) + util.toFloat(this.totalOtherCC))) : '') : '';
    totalWeightPP = totalWeightPP === 0 ? '' : totalWeightPP;
    totalWeightCC = totalWeightCC === 0 ? '' : totalWeightCC;

    return (
      <div style={{color: 'black'}}>
        {printFor.map((item, idx) => {
          if(item.isChecked) {
            return (
              <div key={item.name} className={'page-break'} style={{marginTop: '2cm'}}>
                <div className={'flex between'}>
                  <div
                    style={{fontSize: 24, fontWeight: 'bold'}}>{arrAwbNo1 ?? ''} | {departure} | {arrAwbNo2 ?? ''}</div>
                  <div style={{fontSize: 24, fontWeight: 'bold'}}>{awbNo}</div>
                </div>
                <table className={'print-table'} style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td className={'no-border-b'} colSpan={2} width={'25%'}>
                      <div className={'smallLabel'}>Shipper's Name and Address</div>
                    </td>
                    <td className={'centerTop'} width={'25%'}>
                      <div className={'smallLabel'}>Shipper's Account Number</div>
                      <div className={'value'}>{shipperAccNo}</div>
                    </td>
                    <td colSpan={3} width={'50%'}>
                      <div className={'smallLabel'}>AIR WAY BILL</div>
                      <div className={'value'}>{carrier}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-t'} colSpan={3} width={'50%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{shipperAddr}</div>
                    </td>
                    <td colSpan={3} width={'50%'}>
                      <div className={'value'} style={{fontSize: '0.7em', whiteSpace: 'pre-line'}}>Copies 1,2 and 3 of this Air Waybill are originals and have the same
                        validity
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-b'} colSpan={2} width={'25%'}>
                      <div className={'smallLabel'}>Consignee's Name and Address</div>
                    </td>
                    <td className={'pointColor centerTop'} width={'25%'}>
                      <div className={'smallLabel'}>Consignee's Account Number</div>
                    </td>
                    <td colSpan={3} rowSpan={2} width={'50%'}>
                      <div className={'value'} style={{fontSize: '0.7em', whiteSpace: 'pre-line'}}>
                        It is agreed that the goods described herein are accepted in apparent good order and condition
                        (except as noted)
                        for carriage SUBJECT TO THE CONDITIONS OF CONTRACT OF THE REVERSE HEREOF, ALL GOODS
                        MAY BE CARRIED BY ANY OTHER MEANS INCLUDING ROAD OR ANY OTHER CARRIER UNLESS SPECIFIC CONTRARY
                        INSTUCTIONS ARE GIVEN HEREON BY THE SHIPPER, AND SHIPPER AGREES THAT THE SHIPMENT MAY BE CARRIED
                        VIA INTERMEDIATE STOPPING PLACES WHICH THE CARRIER DEEMS APPROPRIATE. THE SHIPPER'S ATTENTION IS
                        DRAWN TO THE NOTICE CONCERNING CARRIER'S LIMITAION OF LIABILITY. Shipper may increase such
                        limitation of liability by declaring a higher value for carriage and paying a supplemental charge
                        if required.
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className={'no-border-t'} width={'50%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{consigneeAddr}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-b'} colSpan={3} width={'50%'}>
                      <div className={'smallLabel'}>Issuing Carrier's Agent Name and City</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{issuingCarrier}</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{issuingCarrierAddr}</div>
                    </td>
                    <td className={'no-border-b'} rowSpan={2} colSpan={3} width={'50%'}>
                      <div className={'smallLabel'}>ACCOUNT INFORMATION</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{accountInfo}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-b'} colSpan={2} width={'25%'}>
                      <div className={'smallLabel'}>Agent's IATA Code</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{iata}</div>
                    </td>
                    <td className={'no-border-b'} width={'25%'}>
                      <div className={'smallLabel'}>Account No.</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{acctNo}</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className={'no-border-b'} width={'50%'}>
                      <div className={'smallLabel'}>Airport of Departure(Addr. Oof First Carrier) and Requested Routing</div>
                    </td>
                    <td className={'no-border-b'} width={'17%'}>
                      <div className={'smallLabel'}>Reference Number</div>
                    </td>
                    <td colSpan={2} className={'no-border-b'} width={'33%'}>
                      <div className={'smallLabel'}>Optional Shipping Information</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className={'no-border-t'} width={'50%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{airportOfDeparture}</div>
                    </td>
                    <td className={'no-border-t'} width={'17%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{filingNo}</div>
                    </td>
                    <td width={'17%'}>
                      <div className={'value'}>{shippingInfo1}</div>
                    </td>
                    <td width={'16%'}>
                      <div className={'value'}>{shippingInfo2}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table className={'print-table'} style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td className={'no-border'} width={'5%'}>
                      <div className={'smallLabel'}>To</div>
                    </td>
                    <td className={'no-border'} width={'7%'}>
                      <div className={'smallLabel'}>By First Carrier</div>
                    </td>
                    <td className={'no-border-t'} width={'10%'}>
                      <div className={'smallLabel'}>Routing and Destination</div>
                    </td>
                    <td className={'no-border'} width={'5%'}>
                      <div className={'smallLabel'}>To</div>
                    </td>
                    <td className={'no-border'} width={'5%'}>
                      <div className={'smallLabel'}>By</div>
                    </td>
                    <td className={'no-border'} width={'5%'}>
                      <div className={'smallLabel'}>To</div>
                    </td>
                    <td className={'no-border'} width={'5%'}>
                      <div className={'smallLabel'}>By</div>
                    </td>
                    <td className={'no-border'} width={'8%'}>
                      <div className={'smallLabel'}>Currency</div>
                    </td>
                    <td className={'no-border pointColor'} width={'5%'}>
                      <div className={'smallLabel'}>CHGS Code</div>
                    </td>
                    <td className={'no-border center'} colSpan={2} width={'10%'}>
                      <div className={'smallLabel'}>WT/VAL</div>
                    </td>
                    <td className={'no-border center'} colSpan={2} width={'10%'}>
                      <div className={'smallLabel'}>Other</div>
                    </td>
                    <td className={'no-border center'} width={'12%'}>
                      <div className={'smallLabel'}>Declared value for Carriage</div>
                    </td>
                    <td className={'no-border center'} width={'13%'}>
                      <div className={'smallLabel'}>Declared value for Customs</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-t'} width={'5%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{destination}</div>
                    </td>
                    <td className={'no-border-t'} colSpan={2} width={'17%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{byFirstCarrier}</div>
                    </td>
                    <td className={'no-border-t'} width={'5%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{trans1}</div>
                    </td>
                    <td className={'no-border-t'} width={'5%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{trans1Flight ? trans1Flight.slice(0, 2) : ''}</div>
                    </td>
                    <td className={'no-border-t'} width={'5%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{trans2}</div>
                    </td>
                    <td className={'no-border-t'} width={'5%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{trans2Flight ? trans2Flight.slice(0, 2) : ''}</div>
                    </td>
                    <td className={'no-border-t'} width={'8%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line', marginLeft: 15}}>{bl_currency}</div>
                    </td>
                    <td className={'no-border-t pointColor'} width={'5%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>&nbsp;</div>
                    </td>
                    <td className={'center'} width={'5%'}>
                      <div className={'smallLabel'}>PPD</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{wtVal_ppd}</div>
                    </td>
                    <td className={'center'} width={'5%'}>
                      <div className={'smallLabel'}>COLL</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{wtVal_coll}</div>
                    </td>
                    <td className={'center'} width={'5%'}>
                      <div className={'smallLabel'}>PPD</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{other_ppd}</div>
                    </td>
                    <td className={'center'} width={'5%'}>
                      <div className={'smallLabel'}>COLL</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{other_coll}</div>
                    </td>
                    <td className={'no-border-t center'} width={'12%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{dvCarriage}</div>
                    </td>
                    <td className={'no-border-t center'} width={'13%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{dvCustoms}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-b bold-top bold-left bold-right center'} colSpan={3} width={'22%'}>
                      <div className={'smallLabel'}>Airport of Destination</div>
                    </td>
                    <td className={'center'} colSpan={5} width={'28%'}>
                      <div>Requested Flight/Date</div>
                    </td>
                    <td className={'no-border-t no-border-b center'} colSpan={4} width={'20%'}>
                      <div className={'smallLabel'}>Amount of Insurance</div>
                    </td>
                    <td className={'no-border-t'} colSpan={3} rowSpan={2} width={'30%'}>
                      <div className={'value'} style={{fontSize: '0.7em', whiteSpace: 'pre-line'}}>
                        INSUTANCE - If Carrier offers insurance, and such insurance is
                        requested in accoradance with the conditions thereof, indicate amount to
                        be insured in figures in box marked 'Amount of Insurance'
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border bold-bottom bold-left bold-right center'} colSpan={3} width={'22%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{airportOfDestination}</div>
                    </td>
                    <td className={'center'} colSpan={3} width={'10%'}>
                      <div className={'no-border-b value'} style={{whiteSpace: 'pre-line'}}>{flightNo}</div>
                    </td>
                    <td className={'center'} colSpan={2} width={'18%'}>
                      <div className={'no-border-b value'}
                           style={{whiteSpace: 'pre-line'}}>{util.formatD(flightDate)}</div>
                    </td>
                    <td className={'no-border-t center'} colSpan={4} width={'20%'}>
                      <div className={'value'}>{insurance}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border'} colSpan={15} width={'100%'}>
                      <div className={'smallLabel'}>Handling Information</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-t'} colSpan={14} width={'87%'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{handlingInfo}</div>
                      <div className={'value'} style={{fontSize: '0.7em', whiteSpace: 'pre-line'}}>(For U.S.A use only)
                        these commodities, technology or software were exported from
                        the United States in accordance with the Export Admini stration Regulations. Diversion contrary to
                        USA law prohib
                      </div>
                    </td>
                    <td className={'center'} width={'13%'}>
                      <div className={'smallLabel'}>SCI</div>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{sci}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table className={'print-table'} style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td className={'no-border-t'} width={'7%'} align={'center'} rowSpan={2}>
                      <div className={'smallLabel'}>No. of Pieces RCP</div>
                    </td>
                    <td className={'no-border'} width={'7%'} align={'center'}>
                      <div className={'smallLabel'}>Gross</div>
                    </td>
                    <td className={'no-border bold-thick-right'} width={'4%'} align={'center'}>
                      <div className={'smallLabel'}>kg</div>
                    </td>
                    <td className={'no-border bold-thick-right'} width={'15%'} colSpan={2}>
                      <div className={'smallLabel'}>Rate Class</div>
                    </td>
                    <td className={'no-border bold-thick-right'} width={'10%'} align={'center'}>
                      <div className={'smallLabel'}>Chargeable</div>
                    </td>
                    <td className={'no-border-t bold-thick-right'} width={'10%'} align={'center'}>
                      <div className={'smallLabel'}>Rate</div>
                    </td>
                    <td className={'no-border-t bold-thick-right'} width={'10%'} align={'center'} rowSpan={2}>
                      <div className={'smallLabel'}>Total</div>
                    </td>
                    <td className={'no-border'} width={'37%'} align={'center'}>
                      <div className={'smallLabel'}>Nature and Quantity of Goods</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border-t'} align={'center'}>
                      <div className={'smallLabel'}>Weight</div>
                    </td>
                    <td className={'no-border-t bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>lb</div>
                    </td>
                    <td className={'no-border'} align={'center'}>
                      <div className={'smallLabel'}>&nbsp;</div>
                    </td>
                    <td className={'bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>Commodity Item No.</div>
                    </td>
                    <td className={'no-border-t bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>Weight</div>
                    </td>
                    <td className={'bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>Charge</div>
                    </td>
                    <td className={'no-border-t'} align={'center'}>
                      <div className={'smallLabel'}>(incl. Dimensions or Volume))</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'no-border'} align={'center'}>
                      <div className={'value'}>{packages}</div>
                    </td>
                    <td className={'no-border'} align={'center'}>
                      <div className={'value'}>{grossWeight}</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'value'}>{showAs}</div>
                    </td>
                    <td className={'no-border'} align={'center'}>
                      <div className={'value'}>&nbsp;</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'value'}>{''}</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'value'}>{chargeableWeight}</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'value'}>{air_rate ? air_rate : ''}</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'value'}>{totalWeight}</div>
                    </td>
                    <td className={'no-border'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{goods}</div>
                    </td>
                  </tr>
                  {this.renderEmptyItems()}
                  <tr>
                    <td className={'no-border-b'} align={'center'}>
                      <div className={'value'}>{packages}</div>
                    </td>
                    <td className={'no-border-b'} align={'center'}>
                      <div className={'value'}>{grossWeight}</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'value'}>{showAs}</div>
                    </td>
                    <td className={'no-border'} align={'center'}>
                      <div className={'smallLabel'}>&nbsp;</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>&nbsp;</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>&nbsp;</div>
                    </td>
                    <td className={'no-border bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>&nbsp;</div>
                    </td>
                    <td className={'no-border-b bold-thick-right'} align={'center'}>
                      <div className={'smallLabel'}>&nbsp;</div>
                    </td>
                    <td className={'no-border'} align={'center'}>
                      <div className={'value'} style={{whiteSpace: 'pre-line'}}>{`"FREIGHT ${freight === 'PP' ? 'PREPAID' : 'COLLECT'}"`}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table className={'print-table'} style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td className={'smallLabel center'} width={'10%'}>Prepaid</td>
                    <td className={'smallLabel center'} colSpan={2} width={'20%'}>Weight Charge</td>
                    <td className={'smallLabel center'} width={'10%'}>Collect</td>
                    <td className={'smallLabel no-border-b no-border-r left'} width={'20%'}>Other Charges</td>
                    <td className={'smallLabel no-border-b no-border-r no-border-l left'} width={'8%'}>PREPAID</td>
                    <td className={'smallLabel no-border-b no-border-l left'} colSpan={2} width={'32%'}>COLLECT</td>
                  </tr>
                  <tr>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>
                      <div className={'value'}>{totalWeightPP}</div>
                    </td>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>
                      <div className={'value'}>{totalWeightCC}</div>
                    </td>
                    <td className={'smallLabel no-border-t'} colSpan={4} rowSpan={5}
                        width={'60%'} height={'30px'}>{this.renterBlRateOthers(HM, others)}</td>
                  </tr>
                  <tr>
                    <td className={'smallLabel center'} colSpan={4} width={'40%'}>Valuation Charge</td>
                  </tr>
                  <tr>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>&nbsp;</td>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={'smallLabel center'} colSpan={4} width={'40%'}>Tax</td>
                  </tr>
                  <tr>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>&nbsp;</td>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={'smallLabel center'} colSpan={4} width={'40%'}>Total Other Charges due Agent</td>
                    <td className={'smallLabel no-border-b'} colSpan={4} rowSpan={2} width={'60%'}>
                      <div className={'smallLabel'} style={{fontSize: '0.8em', whiteSpace: 'pre-line'}}>
                        Shipper certifies that the particulars on the face hereof are correct and that insofar as any part
                        of the consignment
                        contains dangerous goods, such part is properly described by name and is in proper condition for
                        carriage by air
                        according to the applicable Dangerous Goods Regulations
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>&nbsp;</td>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td className={'smallLabel center'} colSpan={4} width={'40%'}>Total Other Charges due Carrier</td>
                    <td className={'smallLabel no-border-t dot-bottom value'} style={{whiteSpace: 'pre-line'}} colSpan={4} rowSpan={2} width={'60%'}>{signatureOfShipper}</td>
                  </tr>
                  <tr>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>
                      <div className={'value'}>{this.totalOtherPP ? util.formatCurrency(util.toFloat(this.totalOtherPP)) : ''}</div>
                    </td>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>
                      <div className={'value'}>{this.totalOtherCC ? util.formatCurrency(util.toFloat(this.totalOtherCC)) : ''}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'value center pointColor'} colSpan={2} width={'20%'}>&nbsp;</td>
                    <td className={'value center pointColor'} colSpan={2} width={'20%'}>&nbsp;</td>
                    <td className={'smallLabel no-border-t center'} colSpan={4} width={'60%'}>Signature of Shipper or his
                      Agent
                    </td>
                  </tr>
                  <tr>
                    <td className={'smallLabel center'} colSpan={2} width={'20%'}>Total Prepaid</td>
                    <td className={'smallLabel center'} colSpan={2} width={'20%'}>Total Collect</td>
                    <td className={'smallLabel no-border-b value'} style={{whiteSpace: 'pre-line'}} colSpan={4} rowSpan={2}
                        width={'68%'}>{signatureOfIssuingCarrier}</td>
                  </tr>
                  <tr>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>
                      <div className={'value'}>{HM === 'M' ? totalPP : pp_cc === 'PP' ? arranged : ''}</div>
                    </td>
                    <td className={'value center'} colSpan={2} width={'20%'} height={'30px'}>
                      <div className={'value'}>{HM === 'M' ? totalCC : pp_cc === 'CC' ? arranged : ''}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'smallLabel center pointColor'} colSpan={2} width={'20%'}>Currency Conversion rates</td>
                    <td className={'smallLabel center pointColor'} colSpan={2} width={'20%'}>CC Charges in Dst. Currency</td>
                    <td className={'value no-border-t no-border-r dot-bottom'}
                        width={'13%'}>{util.formatD(flightDate)}</td>
                    <td className={'value no-border-all dot-bottom'} width={'17%'}>{departure}</td>
                    <td className={'value no-border no-border-l dot-bottom'} colSpan={2} width={'30%'}>{departure}</td>
                  </tr>
                  <tr>
                    <td className={'value center pointColor'} colSpan={2} width={'20%'}>&nbsp;</td>
                    <td className={'value center pointColor'} colSpan={2} width={'20%'}>&nbsp;</td>
                    <td className={'smallLabel no-border-t no-border-r'} width={'13%'}
                        style={{fontSize: '0.8em', whiteSpace: 'pre-line'}}>Executed on (date)
                    </td>
                    <td className={'smallLabel no-border-t no-border-lr'} width={'17%'}
                        style={{fontSize: '0.8em', whiteSpace: 'pre-line'}}>at (place)
                    </td>
                    <td className={'smallLabel no-border-t no-border-l'} colSpan={2} width={'30%'}
                        style={{fontSize: '0.8em', whiteSpace: 'pre-line'}}>Signature of issuing carrier or its Agent
                    </td>
                  </tr>
                  <tr>
                    <td className={'value center pointColor'} colSpan={2} rowSpan={2} width={'20%'}>For Carrier's Use only
                      at Destination
                    </td>
                    <td className={'value center pointColor'} colSpan={2} width={'20%'}>Charges at Destination</td>
                    <td className={'smallLabel no-border-b pointColor'} width={'13%'}>Total Collect Charges</td>
                    <td className={'smallLabel no-border-b no-border-r right'} width={'17%'}>&nbsp;</td>
                    <td className={'smallLabel no-border-l right'} colSpan={2} rowSpan={2} width={'30%'}>
                      <div style={{fontSize: 24, fontWeight: 'bold'}}>{awbNo}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'value center pointColor'} colSpan={2} width={'20%'}>&nbsp;</td>
                    <td className={'smallLabel pointColor'} width={'13%'}>&nbsp;</td>
                    <td className={'smallLabel no-border-t no-border-r'} width={'20 %'}>
                      <div className={'value'}>(FOR {item.name})</div>
                    </td>
                  </tr>
                  {[0].map((_, i) => {
                    return (
                      <tr key={i}>
                        <td className={'no-border-all'} colSpan={8}>&nbsp;</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            );
          }
        })}
      </div>
    );
  }
  renterBlRateOthers(hm, others) {
    this.totalOtherPP = 0;
    this.totalOtherCC = 0;
    if(others) {
      return (
        <table className={'print-table'} style={{width: '100%'}}>
          {others.map((item) => {
            if(item[1] === 'PP') {
              this.totalOtherPP += item[2];
            } else {
              this.totalOtherCC += item[2];
            }
            return (
              <tr>
                <td className={'value no-border-all'} width={'30%'}>
                  {item[0]}
                </td>
                <td className={'value no-border-all left'} width={'30%'}>
                  <div style={{marginLeft: '15px'}}>{item[1] === 'PP' ? util.formatCurrency(util.toFloat(item[2])) : ''}</div>
                </td>
                <td className={'value no-border-all left'} width={'30%'}>
                  <div style={{marginLeft: '75px'}}>{item[1] === 'CC' ? util.formatCurrency(util.toFloat(item[2])) : ''}</div>
                </td>
                <td className={'value no-border-all left'} width={'10%'}>&nbsp;</td>
              </tr>
            );
          })}
        </table>
      );
    } else {
      return;
    }
  }
  renderEmptyItems() {
    const empty = [];
    let length = 10;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td className={'no-border-t no-border-b'}>&nbsp;</td>
          <td className={'no-border-t no-border-b'}>&nbsp;</td>
          <td className={'no-border-t no-border-b bold-thick-right'}>&nbsp;</td>
          <td className={'no-border-t no-border-b'}>&nbsp;</td>
          <td className={'no-border-t no-border-b bold-thick-right'}>&nbsp;</td>
          <td className={'no-border-t no-border-b bold-thick-right'}>&nbsp;</td>
          <td className={'no-border-t no-border-b bold-thick-right'}>&nbsp;</td>
          <td className={'no-border-t no-border-b bold-thick-right'}>&nbsp;</td>
          <td className={'no-border-t no-border-b'}>&nbsp;</td>
        </tr>
      );
    }
    return empty;
  }
}

export default AEMPrint;
