/**
 * @flow
 */
import React from 'react';
import Form, {isChecked, renderField} from '../components/Form';
import type {FormField, FormProps} from '../components/Form';
import {ColDef} from 'ag-grid-community';
import {util} from '../services/service';

export interface FormManagerParams {
  prefix: string;
  label?: string;
  fields: FormField[];
  columns?: ColDef[];
  formProps: FormProps;
}

class FormManager {
  prefix: string;
  label: string;
  fields: FormField[] = [];
  columns: ColDef[];
  formProps: FormProps;
  formik;
  renderParams: any;
  constructor(params: FormManagerParams) {
    const {prefix, label, fields, columns, formProps} = params;
    this.prefix = prefix;
    this.label = label;
    this.fields = fields.map(f => ({...f, name: `${prefix}-${f.name}`}));
    this.columns = columns;
    this.formProps = formProps;
  }
  onRender = () => {
    throw new Error('Not implemented!');
  }
  onValidate = (values: Object) => {
    throw new Error('Not implemented!');
  };
  renderForm = () => {
    return (
      <Form
        onFormik={formik => this.setFormik(formik)}
        fields={this.fields}
        render={(formik, fields, errors) => {
          this.renderParams = [formik, undefined, fields, errors];
          return this.onRender(formik, fields, errors);
        }}
        {...this.formProps}
      />
    );
  }
  setFormik = (formik) => {
    this.formik = formik;
  }
  getValue = (name) => {
    const {formik} = this;
    return formik.values[`${this._n(name)}`];
  }
  setValue = (name, value) => {
    const {formik} = this;
    // formik.handleChange({target: {name: `${this._n(name)}`, value}});
    formik.setFieldValue(this._n(name), value);
  }
  setValueWithName = (name, value) => {
    const {formik} = this;
    formik.handleChange({target: {name, value}});
  }
  getField = (name) => {
    return this.fields.find(i => i.name === this._n(name));
  }
  getValues = (names?: string[], validate?: boolean) => {
    const values = {};
    if (names) {
      for (const name of names) {
        values[name] = this.getValue(name);
      }
    } else {
      for (const field of this.fields) {
        const name = field.name.replace(`${this.prefix}-`, '');
        const value = this.formik.values[field.name];
        const isEmailField = field.name.match(/.*([Ee]mail).*/) !== null;
        if (typeof value === 'string' && !isEmailField) {
          values[name] = field.useSameAsInput === true ? value : value.toUpperCase();
        } else {
          values[name] = value;
        }
      }
    }
    return validate === true ? this.onValidate(values) : values;
  }
  setValues = (serverData) => {
    const values = {};
    for (const field of this.fields) {
      const name = field.serverName ?? field.name;
      const value = serverData?.[name];
      if (value !== undefined) {
        if (field.type === 'date') {
          // this.setValueWithName(field.name, util.formatD(value));
          values[field.name] = util.formatD(value);
        } else {
          // this.setValueWithName(field.name, value);
          values[field.name] = value;
        }
      }
    }
    this.formik.setValues(values);
  }
  setValuesFast = (serverData) => {
    const values = {};
    for (const [name, value] of Object.entries(serverData)) {
      values[this._n(name)] = value;
    }
    this.formik.setValues(values);
  }
  clearValues = () => {
    if (this.formik) {
      this.formik.setValues({});
      // for (const {name} of this.fields) {
      //   this.setValueWithName(name, '');
      // }
    } else {
      // process.env.NODE_ENV !== 'production' && console.log('[FormManager] form NOT ready.', this.prefix);
    }
  };
  clearValuesFast = () => {
    this.formik.setValues({});
  };
  renderField = (name: string) => {
    this.renderParams[1] = this._n(name);
    return renderField(...this.renderParams); // formik, name, fields, errors
  }
  _n = (name: string) => `${this.prefix}-${name}`;
  isChecked = (name) => isChecked(this.formik, this._n(name), false);
  setFocus = (name: string) => {
    const el = document.querySelector(`#${this._n(name)}`);
    el && el.focus();
  };
}

export default FormManager;
