/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../../components/SimpleModal';
import ForwardingHouseBLPackingListFormManager from '../../../forms/newCoship/forwarding/house/ForwardingHouseBLPackingListFormManager';

const editForm = new ForwardingHouseBLPackingListFormManager();

export default function(printModal, houseBLForm, customerForm, workDetailsForm) {
  const modal = useSimpleModal({
    title: 'PACKING LIST',
    centered: true,
    width: 600,
    isNoButton: true,
    children: editForm.renderForm(),
  });
  React.useEffect(() => {
    if (modal.isOpen) {
      editForm.modal = modal;
      editForm.printModal = printModal;
      editForm.houseBLForm = houseBLForm;
      editForm.customerForm = customerForm;
      editForm.workDetailsForm = workDetailsForm;
    }
  }, [modal.isOpen]);
  return {...modal};
};
