/**
 * @flow
 */
import {useDataGridView} from '../components/DataGridView';
import {api, util} from '../services/service';
import {kindFormatter} from '../forms/field-defs/bl';
import React from 'react';

export default function useReportListGridView(onGridReady, onQueryData) {
  return useDataGridView({
    async onAction(action, data) {
      // onAction
    },
    async onQuery(gridInfo) {
      const {data, totalCount} = await api.listBLReport(gridInfo);
      let tAmount = 0;
      data.forEach(value => {
        tAmount += value.tprice;
      })
      await onQueryData(tAmount);
      return {
        totalCount,
        data: data.map((row) => ({
          ...row,
          hbl_no: row.hbl_no ? `="${row.hbl_no}"` : '',
          shipper_phone: row.shipper_phone ? `="${row.shipper_phone}"` : '',
        })),
      };
    },
    name: `ReportListAll`,
    label: 'Report',
    addLabel: '',
    editLabel: '',
    categoryLabel: 'B/L',
    menuLabel: {label: 'List'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actionWidth: 0,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    initialSearchLastMonthToToday: true,
    onGridReady,
    columns: [
      {field: 'mbl_no', headerName: 'Master BL', width: 120},
      {field: 'branch_name', headerName: 'Branch', width: 100},
      {field: 'kind', headerName: 'Service', width: 80, valueFormatter: kindFormatter},
      {field: 'carrier_type', headerName: 'Carrier Type', width: 80, valueFormatter: oceanAirFormatter},
      {field: 'hbl_no', headerName: 'House BL', width: 120, cellRendererFramework: blLinkFormatter},
      {field: 'wo_date', headerName: 'W/O Date', width: 100},
      {field: 'onboard', headerName: 'Onboard', width: 100},
      {field: 'wo_type', headerName: 'W/O Type', width: 120},
      {field: 'partner', headerName: 'Partner', width: 120},
      {field: 'customer', headerName: 'Customer', width: 120},
      {field: 'shipper', headerName: 'Shipper', width: 120},
      {field: 'shipper_phone', headerName: 'S.Phone', width: 100, valueFormatter: textFormatter},
      {field: 'consignee', headerName: 'Consignee', width: 120},
      {field: 'package', headerName: 'CTNS', width: 80},
      {field: 'c_weight', headerName: 'Chargeable Wt', width: 100},
      {field: 'tprice', headerName: 'Total Price', width: 100, valueFormatter: util.currencyFormatter},
    ],
  });
}

function oceanAirFormatter(p) {
  return p.value === 'O' ? 'Ocean' : p.value === 'A' ? 'Air' : 'Ground';
}

function textFormatter(p) {
  const str = p.value.toString().replaceAll('"', '').replace('=', '');
  return str === 'null' ? '' : str;;
}

function blLinkFormatter(param) {
  if(param.data['id']) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        util.openTab(`/admin/bl/house/${param.data['id']}`);
      }}>
        {param.value.toString().replaceAll('"', '').replace('=', '')}
      </a>
    );
  } else {
    return param.value;
  }
}
