/**
 * @flow
 */
import React from 'react';
import {useSimpleGridModal} from '../../components/SimpleModal';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {apiBL, util} from '../../services/service';
import {kindOptions} from '../../forms/field-defs/bl';

export default function useHouseSearchModal() {
  const [queryData, setQueryData] = React.useState();
  const [totalCount, setTotalCount] = React.useState();
  const [qryText, setQueryText] = React.useState();
  const onEnter = async () => {
    if (qryText) {
      await getHouseList();
    }
  };
  async function getHouseList() {
    const res = await apiBL.getHouseListBulk({...queryData, qryText});
    const {data, totalCount} = res;
    modal.setRows(data);
    setTotalCount(totalCount);
  }
  async function onAdd() {
    const api = modal.grid.apiRef.current;
    if (api) {
      const house = [];
      api.forEachNode((node) => {
        console.log(node.data, node.isSelected());
        if (node.isSelected()) {
          house.push(node.data.id);
        }
      });
      if (house.length > 0) {
        const res = await apiBL.assignHouseListToMaster({id: queryData.masterId, house});
        if (res) {
          await getHouseList();
          util.showSuccess('Selected housed are assigned to the master B/L');
        }
      }
    }
  }
  const modal = useSimpleGridModal({
    title: 'HOUSE LIST',
    titleComponent: () => (
      <>
        <div className={'card__title no-margin pt-20 pl-20'}>
          <h5 className="bold-text">SEARCH HOUSE LIST</h5>
        </div>
        <div className={'theme-light ltr-support flex between middle px-20'}>
          <div className={'mt-8'}>{totalCount === undefined ? '' : `${modal.grid?.rows?.length ?? 0} of ${totalCount}`}</div>
          <div className={'inbox__emails-control-search flex middle'}>
            <input
              id={`house-search`}
              placeholder={'Search...'}
              value={qryText ?? ''}
              onChange={({target: {value}}) => setQueryText(value)}
              onKeyDown={({target: {value}, key}) => {
                if (key === 'Enter') {
                  onEnter(value).catch();
                }
              }}
              style={{width: 200}}
            />
            <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
              e.preventDefault();
              onEnter(document.querySelector('#house-search').value).catch();
            }}>
              <MagnifyIcon />
            </a>
          </div>
        </div>
      </>
    ),
    centered: true,
    width: 920,
    height: 700,
    buttons: [
      {label: 'Refresh', onClick: () => getHouseList()},
      {label: 'Add', onClick: onAdd, color: 'primary'},
      // {label: 'Close', onClick: () => {modal.close()}},
    ],
    gridProps: {
      columns: [
        {headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 50, minWidth: 50},
        // {field: 'kind', headerName: 'Service', valueFormatter: kindFormatter, width: 150},
        // {field: 'bl_no', headerName: 'HB/L No.', width: 150},
        // {field: 'post_date', headerName: 'Post Date', valueFormatter: util.dateFormatter, width: 150},
        // {field: 'pol_label', headerName: 'POL', flex: 1},
        // {field: 'pod_label', headerName: 'POD', flex: 1},
        // {field: 'id', hide: true},
        // {field: 'jcustomer', hide: true},
        // {field: 'jshipment', hide: true},
        {field: 'id', hide: true},
        // {field: 'no', headerName: 'No.', width: 50, minWidth: 50},
        {field: 'bl_no', headerName: 'HB/L No.', flex: 1},
        {field: 'kind', headerName: 'Service Type', sortable: true, unSortIcon: true, valueFormatter: (p) => util.labelFromOptions(p.value, kindOptions), flex: 1},
        {field: 'shipper', headerName: 'Shipper', sortable: true, unSortIcon: true, flex: 1},
        {field: 'consignee', headerName: 'Consignee', sortable: true, unSortIcon: true, flex: 1},
        {field: 'packages', headerName: 'Package', width: 100},
        {field: 'gw', headerName: 'G.W.', valueFormatter: util.formatWeight, width: 100},
        {field: 'cw', headerName: 'C.W.', valueFormatter: util.formatWeight, width: 100},
        {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, width: 100},
      ],
      actions: [],
      actionWidth: 0,
      onAction: (action, data) => {},
      rows: [],
      height: 500,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        onRowClicked(e) {}
      }
    },
    children: null,
  });
  React.useEffect(() => {
    if (modal.isOpen) {
      getHouseList().catch(console.error);
      document.querySelector('#house-search').focus();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (newQueryData) => {
      setQueryData(newQueryData);
      modal.open();
    },
  };
}
