/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import MarkFormManager from './MarkFormManager';
import {apiBL} from "../../services/service";

const FN = {
  ITN_NO: 'itnNo',
  UCR_NO: 'ucrNo',
  HANDLING_INFO: 'handlingInfo',
  ACCOUNT_INFO: 'accountInfo',
  MARK: 'mark',
  GOODS: 'goods',
  SHOW_AS: 'showAs',
  REMARK_FOR_MAN: 'remark',
  PO_NO: 'poNo',
  ITEM_NO: 'itemNo',
};

const LB = {

};

class MarkAEHFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  constructor() {
    super({
      prefix: 'mark-AEH',
      fields: [
        {name: FN.ITN_NO, serverName: FN.ITN_NO, label: ''},
        {name: FN.UCR_NO, serverName: FN.UCR_NO, label: ''},
        {name: FN.SHOW_AS, serverName: FN.SHOW_AS, label: '', options: showAsOptions, noDefOption: true},
        {name: FN.HANDLING_INFO, serverName: FN.HANDLING_INFO, label: '', type: 'textarea'},
        {name: FN.ACCOUNT_INFO, serverName: FN.ACCOUNT_INFO, label: '', type: 'textarea'},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.GOODS, serverName: FN.GOODS, label: '', type: 'textarea'},
        {name: FN.PO_NO, serverName: FN.PO_NO, label: '', type: 'textarea'},
        {name: FN.ITEM_NO, serverName: FN.ITEM_NO, label: '', type: 'textarea'},
        {name: FN.REMARK_FOR_MAN, serverName: FN.REMARK_FOR_MAN, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'pt-8'}><b>Show Weight on B/L as</b></div>
          <div className={'w-96 mx-8'}>{_r(FN.SHOW_AS)}</div>
          <div className={'pt-8'}><b>ITN No.</b></div>
          <div className={'w-128 mx-8'}>{_r(FN.ITN_NO)}</div>
          <div className={'pt-8'}><b>UCR No.</b></div>
          <div className={'w-128 mx-8'}>{_r(FN.UCR_NO)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Handling Information')}
            {_r(FN.HANDLING_INFO)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Account Info.')}
            {_r(FN.ACCOUNT_INFO)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Nature and Quantity of Goods')}
            {_r(FN.GOODS)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Remark for Manifest')}
            {_r(FN.REMARK_FOR_MAN)}
          </div>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('P/O No.')}
            {_r(FN.PO_NO)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Item No.')}
            {_r(FN.ITEM_NO)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const showAsOptions = [
  {value: 'KG', label: 'KG'},
  {value: 'LB', label: 'LB'},
];

const form = new MarkAEHFormManager();

export function useMarkAEHModal(onSave, blForm) {
  const [isInited, setIsInited] = React.useState();
  const [uname, setUname] = React.useState('');
  const title = !isInited ? `( EDITED by ${uname??''} )` : '';

  const modal = useSimpleEditModal({
    title: `AEH Mark & Desc. ${title}`,
    width: 1024,
    form,
    onSave,
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      setIsInited(blForm.getValue('isInited'));
      setUname(blForm.getValue('uname'));
    }
  }, [modal.isOpen]);

  return modal;
}

export default MarkAEHFormManager;
