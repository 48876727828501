/**
 * @flow
 */
import React from 'react';
import {Button, Modal} from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import type {SimpleGridProps} from './SimpleGrid';
import {useSimpleGrid} from './SimpleGrid';
import FormManager from '../lib/FormManager';
import {util} from '../services/service';
import {RowClickedEvent} from 'ag-grid-community';
import {useUser} from "../redux/reducers/userReducer";

export interface SimpleModalProps {
  title: string;
  titleComponent: () => React$Node;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  width?: number | string;
  height?: number | string;
  centered?: boolean;
  hideCloseButton?: boolean;
  children?: React$Node;
  buttons?: {
    label: string,
    onClick: () => void,
    color?: string;
  }[];
  isNoButton?: boolean;
}

const SimpleModal = (props: SimpleModalProps) => {
  return (
    <Modal isOpen={props.open ?? false} className={'theme-light modal-dialog--form'} centered={props.centered} style={{width: props.width, overFlow: 'scroll'}}>
      {props.titleComponent && props.titleComponent()}
      {!props.titleComponent && (
        <div className={'card__title no-margin pt-20 px-20 flex between'}>
          <h5 className="bold-text">{props.title}</h5>
          {props.hideCloseButton !== true && (
            <a href={'/#close'} onClick={e => {e.preventDefault(); props.setOpen(false)}}>
              <CloseIcon color={'#606060'} />
            </a>
          )}
        </div>
      )}
      <div className={'w-full p-16 pb-24'} style={{height: props.height, overflow: 'auto'}}>
        {props.children}
      </div>
      {!props.isNoButton &&
        <div className={'flex center w-full'} style={{paddingBottom: 10}}>
          {props.buttons?.map(({label, onClick, color}, index) => {
            return (
              <Button key={`${label}-${index}`} color={color} size={'sm'} type={'button'} className={'no-margin mr-1'} onClick={onClick}>{label}</Button>
            );
          })}
          <Button size={'sm'} type={'button'} className={'no-margin'} onClick={() => props.setOpen(false)}>Close</Button>
        </div>
      }
    </Modal>
  );
};

export interface SimpleGridModalProps extends SimpleModalProps {
  gridProps: SimpleGridProps;
}

interface UseSimpleModal {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  render: (children?: React$Node) => React$Node;
}

export function useSimpleModal(props: SimpleModalProps): UseSimpleModal {
  const [open, setOpen] = React.useState<boolean>();
  return {
    isOpen: open, // undefined | true | false
    open: () => setOpen(true),
    close: () => setOpen(false),
    render: (children) => <SimpleModal {...{...props, open, setOpen}}>{children ?? props.children}</SimpleModal>,
  };
}

type EditMode = 'add' | 'edit';

interface SimpleEditModalProps extends SimpleModalProps {
  form: FormManager;
  onSave: (mode: EditMode, data: any) => void;
  isDirector?: boolean;
}

interface UseSimpleEditModal extends UseSimpleModal {
  open: (mode: EditMode, data: any) => void;
}

export function useSimpleEditModal({form, onSave, ...props}: SimpleEditModalProps): UseSimpleEditModal {
  const [editData, setEditData] = React.useState();
  const user = useUser();
  const getButtons = () => {
    const isManager = user.isManager;
    if (editData?.mode === 'print') {
      if(isManager && form.formProps.useDeleteButton) {
        return [
          {label: 'Print', color: 'primary', onClick: () => onSave('print', form.getValues(undefined, true))},
          {label: 'Edit', color: 'primary', onClick: () => onSave('edit', form.getValues(undefined, true))},
          {label: 'Delete', color: 'danger', onClick: () => onSave('delete', form.getValues(undefined, true))},
        ]
      } else {
        return [
          {label: 'Print', color: 'primary', onClick: () => onSave('print', form.getValues(undefined, true))},
          {label: 'Edit', color: 'primary', onClick: () => onSave('edit', form.getValues(undefined, true))},
        ]
      }
    } else if(editData?.mode === 'send') {
      return [
        {label: 'Send', color: 'primary', onClick: () => onSave('send', form.getValues(undefined, true))},
      ]
    } else if(editData?.mode === 're-send') {
      return [
        {label: 'Re-Send', color: 'primary', onClick: () => onSave('re-send', form.getValues(undefined, true))},
      ]
    } else if (editData?.mode === 'copy') {
      if(props.title === 'HOLIDAY') {
        if (props.isDirector) {
          return [
            {label: 'Copy', color: 'success', onClick: () => onSave('copy', form.getValues(undefined, true))},
            {label: 'Edit', color: 'primary', onClick: () => onSave('edit', form.getValues(undefined, true))},
            {label: 'Delete', color: 'danger', onClick: () => onSave('delete', form.getValues(undefined, true))},
          ]
        }
      } else if(isManager && form.formProps.useDeleteButton && form.formProps.useCopyButton) {
        return [
          {label: 'Copy', color: 'success', onClick: () => onSave('copy', form.getValues(undefined, true))},
          {label: 'Edit', color: 'primary', onClick: () => onSave('edit', form.getValues(undefined, true))},
          {label: 'Delete', color: 'danger', onClick: () => onSave('delete', form.getValues(undefined, true))},
        ]
      } else {
        return [
          {label: 'Edit', color: 'primary', onClick: () => onSave(editData?.mode, form.getValues(undefined, true))},
        ]
      }
    } else {
      if(isManager && editData?.mode === 'edit' && form.formProps.useDeleteButton) {
        return [
          {label: editData?.mode === 'edit' ? 'Edit' : 'Add', color: 'primary', onClick: () => onSave(editData?.mode, form.getValues(undefined, true))},
          {label: 'Delete', color: 'danger', onClick: () => onSave('delete', form.getValues(undefined, true))},
        ];
      } else {
        return [
          // {label: editData?.mode === 'edit' ? 'Edit' : 'Add', color: 'primary', onClick: () => onSave(editData?.mode, form.getValues(undefined, true))},
          {label: editData?.mode === 'edit' ? 'Edit' : 'Add', color: 'primary', onClick: () => onSave(editData?.mode, form.getValues(undefined, true), form)},
        ];
      }
    }
  }
  const extendedProps = {
    ...props,
    title: ((editData?.mode === 'edit' || editData?.mode === 'copy') ? 'EDIT ' : editData?.mode === 'add' ? 'ADD ' : '') + props.title,
    buttons: getButtons(),
    children: (
      <>
        {form.renderForm()}
        {props.children}
      </>
    ),
  };
  const modal = useSimpleModal(extendedProps);
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const {mode, data} = editData ?? {};
      if (mode === 'add') {
        // form.clearValuesFast();
        // form.setValues(data);
      } else if (mode === 'edit' || mode === 'send' || mode === 're-send' || mode === 'copy') {
        form.setValues(data);
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    ...editData,
    open: (mode, data) => {
      setEditData({mode, data});
      modal.open();
    },
  };
}

export function useSimpleEditModalAccountRequest({form, onSave, ...props}: SimpleEditModalProps): UseSimpleEditModal {
  const [editData, setEditData] = React.useState();
  const getButtons = () => {
    if (props.isAccount) {
      return [
        {label: 'Print', color: 'primary', onClick: () => onSave('print', form.getValues(undefined, true))},
        {label: 'Edit', color: 'primary', onClick: () => onSave('edit', form.getValues(undefined, true))},
        {label: 'Delete', color: 'danger', onClick: () => onSave('delete', form.getValues(undefined, true))},
      ]
    } else if (props.isManager) {
      return [
        {label: 'Print', color: 'primary', onClick: () => onSave('print', form.getValues(undefined, true))},
        {label: 'Edit', color: 'primary', onClick: () => onSave('edit', form.getValues(undefined, true))},
      ]
    } else {
      return [
        {label: 'Print', color: 'primary', onClick: () => onSave('print', form.getValues(undefined, true))},
      ]
    }
  }
  const extendedProps = {
    ...props,
    title: (editData?.mode === 'edit' ? 'EDIT ' : editData?.mode === 'add' ? 'ADD ' : '') + props.title,
    buttons: getButtons(),
    children: (
      <>
        {form.renderForm()}
        {props.children}
      </>
    ),
  };
  const modal = useSimpleModal(extendedProps);
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const {mode, data} = editData ?? {};
      if (mode === 'add') {
        form.clearValuesFast();
        form.setValues(data);
      } else if (mode === 'edit' || mode === 'send' || mode === 're-send') {
        form.setValues(data);
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    ...editData,
    open: (mode, data) => {
      setEditData({mode, data});
      modal.open();
    },
  };
}

interface UseSimpleGridModal extends UseSimpleModal {
  setRows: (rows: Array) => void;
  grid: any;
}

export function useSimpleGridModal({gridProps, ...props}: SimpleGridModalProps): UseSimpleGridModal {
  const [open, setOpen] = React.useState<boolean>();
  const grid = useSimpleGrid(gridProps);
  return {
    isOpen: open, // undefined | true | false
    open: () => setOpen(true),
    close: () => setOpen(false),
    setRows: (rows) => grid.setRows(rows),
    grid,
    render: (children) => {
      return (
        <SimpleModal {...props} open={open} setOpen={setOpen}>
          {grid.render()}
          {children ?? props.children}
        </SimpleModal>
      );
    },
  };
}

/**
 * @example
 * const onQuery = async (queryData: Object) => {...};
 * const onCUD = async (mode, data) => {...};
 * const addOnly = false;
 * const form = myFormManager;
 * const modal = useCUDGridModal({
 *   title: 'Modal Title',
 *   width: 800,
 *   centered: true,
 *   gridProps: {
 *     columns: [...],
 *     height: 250,
 *     rows: [],
 *     className: 'additional classes',
 *   }
 * }, form, onQuery, onCUD, addOnly);
 */
export function useCUDGridModal(
  props: SimpleGridModalProps,
  form: FormManager,
  onQuery: (queryData: Object) => Promise<any[]>,
  onCUD: (mode: 'add' | 'edit' | 'delete', data: Object) => void,
  addOnly: boolean = false
) {
  const [queryData, setQueryData] = React.useState();
  const [mode, setMode] = React.useState();
  const modalRef = React.useRef();
  const queryListRef = React.useRef();
  function getButtons() {
    async function onEditClick() {
      const res = await onCUD('edit', form.getValues(undefined, true));
      if (res) {
        exitEditMode();
        await queryList()
      }
    }
    async function onAddClick() {
      const res = await onCUD('add', form.getValues(undefined, true));
      if (res) {
        exitEditMode();
        await queryList()
      }
    }
    if (mode === 'edit') {
      return [
        {label: 'Edit', color: 'primary', onClick: onEditClick},
        {label: 'Clear', onClick: () => exitEditMode()}
      ];
    } else {
      return [
        {label: 'Add', color: 'primary', onClick: onAddClick},
      ];
    }
  }
  function enterEditMode(data) {
    setMode('edit');
    form.setValues(data);
  }
  function exitEditMode() {
    setMode('add');
    form.clearValues();
  }
  async function queryList() {
    const res = await onQuery(queryData);
    if (Array.isArray(res)) {
      modalRef.current.setRows(res);
    } else {
      modalRef.current.setRows(res['data'] ?? []);
    }
  }
  const modal = useSimpleGridModal({
    ...props,
    buttons: getButtons(),
    gridProps: {
      ...props.gridProps,
      actions: addOnly ? ['delete'] : ['edit', 'delete'],
      actionWidth: 70,
      onAction: (action, data) => {
        if (action === 'edit') {
          enterEditMode(data);
        } else if (action === 'delete') {
          util.showConfirm('Are you sure to delete?', async () => {
            const res = onCUD('delete', data);
            if (res) {
              exitEditMode();
              await queryList();
            }
          });
        }
      },
      agGridProps: {
        onRowClicked(e: RowClickedEvent) {
          if (!addOnly) {
            enterEditMode(e.data);
          }
        }
      },
    },
    children: form.renderForm(),
  });
  modalRef.current = modal;
  queryListRef.current = queryList;
  React.useEffect(() => {
    if (modal.isOpen === true) {
      queryList().catch(console.error);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (queryData) => {
      setMode('add');
      setQueryData(queryData);
      modal.open();
    },
    queryData,
    queryList,
  };
}

export default SimpleModal;
