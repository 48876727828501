/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions} from '../forms/field-defs/bl';
import {defaultANTitle} from "../forms/print/v2/OIHANPrintForm";

class AIHDelOrder extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return null;
    }
    const {
      shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, mblNo, mawbNo, subMblNo, amsNo, customerRefNo, hawbNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, arrivalDate,
      polLabel, podLabel, fDestEta, delEta, freightLoc, goDate, storageDate, finalEtaDate, finalEtaTime,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      printFormData, pickupLabel, deliveryLabel, valueToShow,
      emptyPickup, forwardAgent, partner,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
      invoice, invoiceBlankLines,
    } = printData;
    const {renderCell: _cell} = this;
    return (
      <div style={{color: 'black', fontSize: 16, marginTop: '1cm'}}>
        <div className={'flex-column center'}>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
          <div>{address}</div>
          <div>Prepared by {pic} {util.formatDT(Date.now())}</div>
          <div>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>DELIVERY ORDER</div>
          <div className={'flex center'}>
            THE MERCHANT DESCRIBED BELOW WILL BE ENTERED AND/OR FORWARDED AS FOLLOWS:
          </div>
        </div>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            {_cell('PICKUP', pickupLabel, {rowSpan: 4, width: '50%'})}
            {_cell('DATE', util.formatD(Date.now()), {width: '50%'}, 'OUR FILING NO.', filingNo, true)}
          </tr>
          <tr>
            {_cell('TRUCKER', printFormData['truckingCompany'], {width: '50%'}, 'CUSTOMER REF. NO.', customerRefNo, true)}
          </tr>
          <tr>
            {_cell('MB/L NO.', mawbNo, {width: '50%'}, 'HB/L NO.', hawbNo, true)}
          </tr>
          <tr>
            {_cell('ACI B/L No.', valueToShow === 'CN' ? carrier : vslVoy, {width: '50%'})}
          </tr>
          <tr>
            {_cell('DELIVERY (Appointment is required prior delivery)', deliveryLabel, {rowSpan: 3, width: '50%'})}
            {_cell('DATE OF', fDestEta, {width: '50%'}, 'STORAGE START', storageDate, true)}
          </tr>
          <tr>
            {_cell('CARRIER', valueToShow === 'CN' ? carrier : flightNo, {width: '50%'})}
          </tr>
          <tr>
            {_cell('ORIGIN AIRPORT', polFull, {width: '50%'})}
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr style={{backgroundColor: '#c0c0c0'}}>
            <td align={'center'}><div className={'label f-13'}>NO. OF PKGS.</div></td>
            <td align={'center'}><div className={'label f-13'}>DESCRIPTION</div></td>
            <td align={'center'}><div className={'label f-13'}>Gross Weight</div></td>
            <td align={'center'}><div className={'label f-13'}>Chargeable Weight</div></td>
          </tr>
          <tr>
            <td className={'no-border-b'}><div className={'value'}>{packageCount} {packageType}</div></td>
            <td className={'no-border-b'}><div className={'value'} style={{whiteSpace: 'pre-line'}}>{desc}</div></td>
            <td className={'no-border-b'} align={'right'}><div className={'value'}>{util.weightForPrint(grossWeightKg, 'KG')}</div></td>
            <td className={'no-border-b'} align={'right'}><div className={'value'}>{util.weightForPrint(chargeableWeightKg, 'KG')}</div></td>
          </tr>
          {this.renderBlankLine(20)}
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td width={'50%'}>
              <div className={'value'}>ORIGINAL DELIVERY ORDER</div>
              <div className={'value'}>INLAND FREIGHT : {freightLabel}</div>
            </td>
            <td width={'50%'}>
              <div className={'label f-13'}>
                NOTICE: BAD ORDER PACKAGES MUST BE SIGNED FOR AS IN CONDITION RECEIVED. ALL PIER CHARGES FOR ACCOUNT OF RECEIVER UNLESS OTHERWISE SPECIFIED.
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={'label f-13 mb-16'}>CARRIER SIGNATURE / DATE</div>
              <div className={'flex mb-16'}>
                <div className={'flex-1'}>CARRIER :</div>
                <div className={'flex-1'}>DATE :</div>
              </div>
            </td>
            <td>
              <div className={'label f-13 mb-16'}>RECEIVED IN GOOD ORDER / DATE</div>
              <div className={'flex mb-16'}>
                <div className={'flex-1'}>BY :</div>
                <div className={'flex-1'}>DATE :</div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} height={250}>
              <div className={'label f-13'}>REMARK</div>
              <div className={'label f-13'} style={{whiteSpace: 'pre-line'}}>{printFormData.remark}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderCell(label, value, tdProps, extraLabel, extraValue, extraHalf) {
    if (extraLabel) {
      return (
        <td width={'50%'}>
          <div className={'flex between'}>
            <div className={extraHalf ? 'flex-1' : 'flex-2'}>
              <div className={'label f-13'}>{label}</div>
              <div className={'value'}>{value ? value : '\u00a0'}</div>
            </div>
            <div className={'flex-1'}>
              <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{extraLabel}</div>
              <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{extraValue ? extraValue : '\u00a0'}</div>
            </div>
          </div>
        </td>
      );
    } else {
      return (
        <td {...tdProps}>
          <div className={'label f-13'}>{label}</div>
          <div className={'value'} style={{whiteSpace: 'pre-line'}}>{value ? value : '\u00a0'}</div>
        </td>
      );
    }
  }
  renderCell4(...args) {
    return (
      <td width={'50%'}>
        <div className={'flex between'}>
          <div className={'flex-1'}>
            <div className={'label f-13'}>{args[0]}</div>
            <div className={'value'}>{args[1]}</div>
          </div>
          <div className={'flex-1'}>
            <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{args[2]}</div>
            <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{args[3]}</div>
          </div>
          <div className={'flex-1'}>
            <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{args[4]}</div>
            <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{args[5]}</div>
          </div>
          <div className={'flex-1'}>
            <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{args[6]}</div>
            <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{args[7]}</div>
          </div>
        </div>
      </td>
    );
  }
  renderBlankLine(count) {
    const res = [];
    for (let i = 0; i < count; ++i) {
      res.push(
        <tr key={i}>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
        </tr>
      );
    }
    return res;
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers, serverData, invoice} = blData;
    const {pickupAddr, pickupPIC = '', pickupFax = '', deliveryAddr, deliveryPIC = '', deliveryFax = '', valueToShow = 'CN'} = printFormData;
    const {shipperAddr = '', consigneeAddr = '', forwardingAgentAddr = '', notifyAddr = '', cBrokerAddr = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = '', exportRefNo = '', mblNo, mawbNo, subMblNo, amsNo, customerRefNo, hawbNo} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, arrivalDate, finalEtaDate, finalEtaTime} = jvessel;
    const {polLabel, podLabel, fDestEta, delEta, freightLoc, goDate, storageDate} = jroute;
    const {bladdress, cutoff, route} = serverData;
    const {emptyPickup, forwardAgent, partner} = bladdress;
    const {doc, pickupDate, portOpen, rail, vgm} = cutoff;
    const {delivery, final, podFull, polFull, porFull} = route;
    let {freight, orgBlType, svcTerm1, svcTerm2, package: packageCount, packageType, grossWeightKg, chargeableWeightKg} = jshipment;
    const {description: desc, mark, itNo, date: itDate, itLoc, pubRemark} = jmark ?? {};
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.labelFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.labelFromOptions(svcTerm2, svcTypeOptions);
    // const blankLines = [];
    // const blankCount = 10 - containers.length;
    // for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    const invoiceBlankLines = [];
    const invoiceBlankCount = 5 - (invoice?.items?.length ?? 0);
    for (let i = 0; i < invoiceBlankCount; ++i) invoiceBlankLines.push(i);
    const pickupLabel = [pickupAddr].join('\n');
    const deliveryLabel = [deliveryAddr].join('\n');
    return {
      shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, mblNo, mawbNo, subMblNo, amsNo, customerRefNo, hawbNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, arrivalDate,
      polLabel, podLabel, fDestEta, delEta, freightLoc, goDate, storageDate, finalEtaDate, finalEtaTime,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      // blankLines,
      company, address, email, fax, phone, pic,
      printFormData, pickupLabel, deliveryLabel, valueToShow,
      emptyPickup, forwardAgent, partner,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
      invoice, invoiceBlankLines,
    };
  }
}

export default AIHDelOrder;
