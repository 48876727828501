/**
 * @flow
 */
import React from "react";
import FormManager from "../../lib/FormManager";
import {AccountTypeSearchInput, TradePartnerSearchInput} from "../../components/SearchInput";
import {INVOICE} from "../field-defs/account";
import {api} from "../../services/service";

export const SK_BRANCH = 'branchId';
export const SK_TEAM = 'teamMain';
export const SK_INV_TYPE = 'invType';
export const SK_ACCOUNT_TYPE = 'atype';
export const SK_ACCOUNT_TYPE_ID = 'atypeId';
export const SK_BILL_TO = 'billTo';
export const SK_BILL_TO_ID = 'billToId';
export const SK_BILL_TO_BILL_ADDR = 'billToBillAddr';
export const SK_PAID_REQUEST = 'paidRequest';
export const SK_VENDOR_INV = 'vendorInv';
export const SK_REMARK = 'remark';
export const SK_AMOUNT = 'amount';
export const teamMainOptions = [
  {value: '', label: 'ALL'},
  {value: 'M', label: 'MOVING'},
  {value: 'C', label: 'COURIER'},
  {value: 'F', label: 'FORWARDING'},
  {value: 'A', label: 'ACCOUNT'},
  {value: 'S', label: 'SALES'},
];

const invoiceTypeOptions = [
  {value: '', label: 'ALL'},
  { value: INVOICE.TYPE_AP, label: INVOICE.TYPE_AP_LABEL},
  { value: INVOICE.TYPE_DC_NOTE, label: INVOICE.TYPE_DCNOTE_LABEL},
];

const paidRequestOptions = [
  {value: 'A', label: 'ALL'},
  { value: 'N', label: 'Unpaid'},
  { value: 'Y', label: 'Paid'},
];

export default class AccountRequestSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  constructor() {
    super({
      prefix: 'account-list-search',
      fields: [
        {name: SK_BRANCH, serverName: SK_BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: SK_TEAM, serverName: SK_TEAM, label: 'Team', options: teamMainOptions, noDefOption: true, smallMargin: true},
        {name: SK_INV_TYPE, serverName: SK_INV_TYPE, label: 'Type', options: invoiceTypeOptions, noDefOption: true, smallMargin: true},
        {name: SK_ACCOUNT_TYPE, serverName: SK_ACCOUNT_TYPE, label: 'Account Type', smallMargin: true},
        {name: SK_ACCOUNT_TYPE_ID, serverName: SK_ACCOUNT_TYPE_ID, label: '', smallMargin: true},
        {name: SK_BILL_TO, serverName: SK_BILL_TO, label: 'Bill To / Partner', smallMargin: true},
        {name: SK_BILL_TO_ID, serverName: SK_BILL_TO_ID, label: '', smallMargin: true},
        {name: SK_BILL_TO_BILL_ADDR, serverName: SK_BILL_TO_BILL_ADDR, label: '', smallMargin: true},
        {name: SK_PAID_REQUEST, serverName: SK_PAID_REQUEST, label: 'Payment', options: paidRequestOptions, noDefOption: true, smallMargin: true},
        {name: SK_VENDOR_INV, serverName: SK_VENDOR_INV, label: 'Vendor Inv.', smallMargin: true},
        {name: SK_REMARK, serverName: SK_REMARK, label: 'Remark', smallMargin: true},
        {name: SK_AMOUNT, serverName: SK_AMOUNT, label: 'Amount', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    _f(SK_REMARK).onEnter = _ => this.onSearch();
    _f(SK_VENDOR_INV).onEnter = _ => this.onSearch();
    _f(SK_AMOUNT).onEnter = _ => this.onSearch();
    _f(SK_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];

    // account type search form
    const _at = (name) => <AccountTypeSearchInput
      vertical forSmallLabel
      idField={_n('atypeId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
      />;

    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      vertical forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} onOK={(data) => onTPartner(name, data)}
      />;

    const onTPartner = (name, data) => {
      this.partnerData = data;
      _c(SK_BILL_TO_ID, data.id);
      _c(SK_BILL_TO, data.value);
      _c(SK_BILL_TO_BILL_ADDR, data.bill_address);
    };

    const branch = _v(SK_BRANCH);
    const team = _v(SK_TEAM);
    const type = _v(SK_INV_TYPE);
    const atypeId = _v(SK_ACCOUNT_TYPE_ID);
    const billToId = _v(SK_BILL_TO_ID);
    const paidRequest = _v(SK_PAID_REQUEST);

    React.useEffect(() => {
      if(paidRequest === undefined) {
        _c(SK_PAID_REQUEST, 'N');
      }
    }, []);

    React.useEffect(() => {
      // eslint-disable-next-line max-len
      if (!(branch === undefined && team === undefined && type === undefined && atypeId === undefined && billToId === undefined && paidRequest === undefined)) {
        this.onSearch();
      }
    }, [branch, team, type, atypeId, billToId, paidRequest]);
    React.useEffect(() => {
      this.setFocus(SK_REMARK);
    }, []);

    return (
      <>
        {_r(SK_BRANCH)}
        {_r(SK_TEAM)}
        {/*{_r(SK_INV_TYPE)}*/}
        {_at(SK_ACCOUNT_TYPE)}
        {_tp(SK_BILL_TO)}
        {_r(SK_PAID_REQUEST)}
        {_r(SK_VENDOR_INV)}
        {_r(SK_REMARK)}
        {_r(SK_AMOUNT)}
      </>
    );
  };
}
