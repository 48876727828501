/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {ColDef} from 'ag-grid-community';

const LoginLogList = () => {
  return (
    <DataGridView
      name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
      addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
      actions={ACTIONS} actionWidth={ACTION_WIDTH}
      showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
      columns={COLUMNS}
      onAction={async (action, data) => onAction(action, data)}
      onQuery={async (gridInfo) => onQuery(gridInfo)}
    />
  );
};

const NAME = 'loginLogList';
const LABEL = 'Log';
const ADD_LABEL = '';
const EDIT_LABEL = '';
const CATEGORY_LABEL = 'Log';
const MENU_LABEL = 'Login';
const SORT_COL = 'cdate';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 0;
const SHOW_DATE_SEARCH = true;
const ACTIONS = [];
const ACTION_WIDTH = 0;
const COLUMNS: ColDef = [
  {field: 'uid', hide: true},
  {field: 'token', hide: true},
  {field: 'cdate', headerName: 'Created', flex: 0.75, valueFormatter: util.dateTimeFormatter},
  {field: 'login_id', headerName: 'LoginID', flex: 0.5},
  {field: 'uname', headerName: 'Staff Name', flex: 1},
  {field: 'udate', headerName: 'Updated', flex: 0.75, valueFormatter: util.dateTimeFormatter},
  {field: 'useragent', headerName: 'UserAgent', flex: 1},
  {field: 'ipaddress', headerName: 'IP', flex: 0.5},
  {field: 'isexpired', headerName: 'Expired', flex: 0.25, valueFormatter: util.yesNoFormatter},
];

async function onAction(action, data) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.loginLogList(gridInfo);
}

export default LoginLogList;
