/**
 * @flow
 */
import React from 'react';
import {Modal} from 'reactstrap';
import Form, {renderField} from '../../components/Form';
import type {FormField} from '../../components/Form';
import {api, util} from '../../services/service';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {useStaffSearch} from '../../components/SearchModal';
import {useUser} from '../../redux/reducers/userReducer';
import type {CalendarEvent} from '../../components/CalendarView';
import {useState} from '../../hooks/useState';
import {StaffSearchInput} from '../../components/SearchInput';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import {schedulePurposeOptions} from "../../forms/helper";
import MagnifyIcon from "mdi-react/MagnifyIcon";

export interface StaffFullScheduleState {
  events: CalendarEvent[];
  holidayInfo: string;
  formDisabled: boolean;
  myself: boolean;
  showDelete: boolean;
  isHoliday: boolean;
  // modal state
  open: boolean;
  mode?: 'add' | 'edit';
  data?: any;
}

const CATEGORY_LABEL = 'Staff';
const MENU_LABEL = 'Schedule';

let formik; // hacky but very effective!
let fromTS; // hacky but very effective!
let toTS; // hacky but very effective!

const initialState: StaffFullScheduleState = {
  events: [],
  holidayInfo: '',
  formDisabled: false,
  myself: false,
  showDelete: false,
  open: false,
  isHoliday: false,
};

const StaffFullSchedule = () => {
  useTopbar({label: CATEGORY_LABEL}, {label: MENU_LABEL});
  const [state, setState] = useState<StaffFullScheduleState>(initialState);
  const user = useUser();
  const branchRef = React.useRef(user.branch_id);
  const initialDate = moment().format('YYYYMMDD');
  const areaOptions = api.useAreaOptions(true);

  const searchStaff = useStaffSearch(({id, value}) => {
    formik.handleChange({target: {name: 'name', value}});
    formik.handleChange({target: {name: 'userId', value: id}});
    if (id !== user.id) {
      formik.handleChange({target: {name: 'isHoliday', checked: false}});
    }
    setState({myself: id === user.id});
  });
  React.useEffect(() => {
    onDateChange(undefined, 'month').catch();
  }, []);
  React.useEffect(() => {
    if (state.open === true) {
      getHolidayCount();
    }
  }, [state.open]);

  const getHolidayCount = () => {
    const newState: StaffFullScheduleState = {...state};
    if (state.mode === 'add') {
      newState.myself = true;
    } else {
      newState.myself = formik.values['userId'] === user.id;
    }
    newState.formDisabled = formik.values['isHoliday'] === true && state.mode === 'edit';
    setState(newState);
    api.getHolidayCount(formik.values['userId']).then(res => {
      try {
        const {data: {holiday: [days, endDate]}} = res;
        // newState.holidayInfo = `remains ${days} day${days > 1 ? 's' : ''} (${util.formatDate(endDate, 'YYYY-MM-DD')})`;
        newState.holidayInfo = `${days} day${days > 1 ? 's' : ''} left until ${util.formatDate(endDate, 'YYYY-MM-DD')}`;
        setState(newState);
      } catch (error) {
        // DO NOTHING
      }
    });
  };

  const buildEvents = (data) => {
    return data?.map(item => {
      const {id, isholiday: isHoliday, job_desc: jobDesc, location, plan_date: planDate, plan_time: planTime, purpose, uid: userId, uname2: name} = item;
      const [startDate, endDate] = util.getBeginEndDateTS(util.getCurrentDate(), 'day');
      const isTodayPlan = startDate <= planDate && planDate <= endDate;
      const color = isTodayPlan ? '#fffae5' : 'white';
      const detail = location || planTime || purpose ? `(${location}${planTime ? ' ' + planTime : ''}${purpose ? ' ' + purpose : ''})` : '';
      const title = `${name} ${detail}`;
      return {
        title,
        start: planDate,
        end: planDate,
        allDay: true,
        color,
        url: '/admin/staffs/plan',
        resource: {
          id, isHoliday, jobDesc, location, planDate, planTime, purpose, userId, name
        }
      };
    });
  };
  const loadList = async (from, to, additionalState: StaffFullScheduleState, searchText) => {
    const branchId = branchRef.current;
    const qryText = searchText || undefined;
    const {data} = await api.staffScheduleList({fromDate: from, toDate: to, branchId: branchId, qryText});
    setState({events: buildEvents(data), ...additionalState});
  };
  const onAddSchedule = () => {
    setState({showDelete: false, open: true, mode: 'add', data: {userId: user.id, name: user.uname}});
  };
  const onEditSchedule = (data) => {
    data.jsEvent.preventDefault();
    const {resource} = data?.event?._def?.extendedProps;
    let {planDate, isHoliday} = resource;
    // planDate = new Date(planDate);
    const canDelete = user.isDirector ? true : util.canDeleteSchedule(resource.planDate);
    setState({showDelete: canDelete, open: true, mode: 'edit', data: {...resource, planDate}});
  };
  const onDateChange = async (date, view) => {
    const [from, to] = util.getBeginEndDateTS(date, view);
    fromTS = from;
    toTS = to;
    await loadList(from, to);
  };
  const onSave = async (values) => {
    const [startPlanDate, endPlanDate] = util.getBeginEndDateTS(values.planDate, 'day');
    let endPlanDate2;
    if (values.planDate2) {
      [, endPlanDate2] = util.getBeginEndDateTS(values.planDate2, 'day');
    } else {
      endPlanDate2 = endPlanDate;
    }
    const data = {...values, planDate: util.formatD(startPlanDate), planDate2: util.formatD(endPlanDate2)};
    if (!data['userId']) {
      return util.showWarning('Invalid user. Please search user.');
    }
    if (state.mode === 'add' && data.isHoliday === true && !data.dayCount) {
      // dayCount 는 필수 입력임!
      util.showWarning('Please enter Days.');
      return;
    }
    const res = state.mode === 'add' ? await api.staffScheduleAdd(data) : await api.staffScheduleEdit(data);
    if (res) {
      util.showSuccess(`Schedule has been ${state.mode === 'add' ? 'added' : 'edited'} successfully!`);
      await loadList(fromTS, toTS, {open: false});
    }
  };
  const onDelete = (values) => {
    util.showConfirm('Are you sure to delete schedule?', async () => {
      const {id} = values;
      const res = await api.staffScheduleDel({id});
      if (res) {
        util.showSuccess('Schedule has been deleted successfully!');
        await loadList(fromTS, toTS, {open: false});
      } else {
        util.showError('Failed to delete schedule!');
      }
    });
  };
  const onOk = (values) => {
    formik.setFieldValue('userId', values.id);
    formik.setFieldValue('name', values.value);
    getHolidayCount();
  };
  function getFields(): FormField[] {
    const disabled = state.formDisabled;
    const holidayDisabled = state.mode === 'edit' ? true : !state.myself;
    return [
      {name: 'id', required: false},
      {name: 'userId', required: false},
      {name: 'name', label: 'Name', required: true, disabled: state.mode === 'edit' ? true : disabled},
      {name: 'planDate', label: 'Date From', type: 'datePicker', required: true, disabled},
      {name: 'planDate2', label: 'Date To', type: 'datePicker', required: true, disabled},
      {name: 'dayCount', label: 'Days', required: false, disabled},
      {name: 'planTime', label: 'Time', required: false, disabled},
      {name: 'isHoliday', label: 'Holiday', type: 'checkbox', required: false, disabled: holidayDisabled},
      {name: 'location', label: 'Location', required: false, disabled},
      {name: 'purpose', label: 'Purpose', options: schedulePurposeOptions, disabled},
      {name: 'jobDesc', label: 'Desc.', type: 'textarea', required: false, textAreaRows: 6, disabled},
    ];
  }
  function renderFields(formik, fields, errors) {
    const render = (name) => renderField(formik, name, fields, errors);
    const planDate = formik.values['planDate'];
    const planDate2 = formik.values['planDate2'];
    if (planDate && !planDate2) {
      formik.setFieldValue('planDate2', planDate);
    }
    return (
      <div>
        <div className={'flex wrap'}>
          <StaffSearchInput className={'flex-1 mr-2'} displayField={'name'} idField={'userId'} formik={formik} fields={fields} errors={errors} onOK={onOk}/>
          <div className={'flex-1 mr-2'}>{render('purpose')}</div>
        </div>
        <div className={'flex wrap'}>
          <div className={'flex-1 mr-2'}>{render('planDate')}</div>
          <div className={'flex-1 mr-2'}>{render('planDate2')}</div>
        </div>
        <div className={'flex wrap'}>
          <div className={'flex-1 mr-2'}>{render('planTime')}</div>
          <div className={'flex-1 mr-2'}>{render('location')}</div>
        </div>
        {render('jobDesc')}
        <div style={{marginTop: -10, marginLeft: 80}}>{state.holidayInfo}</div>
      </div>
    );
  }
  const fields = getFields();
  const onBranchChange = (e) => {
    const value = e.target.value;
    const branchId = value ? Number(value) : 0;
    branchRef.current = branchId;
    const el = document.querySelector('#staff-schedule-search');
    const {value: searchText = ''} = el;
    setTimeout(() => loadList(fromTS, toTS, {}, searchText), 0);
  };

  const onKeyDown = (e) => {
    const {target: {value}, key} = e;
    if (key === 'Enter') {
      loadList(fromTS, toTS, {}, value).catch();
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    const el = document.querySelector('#staff-schedule-search');
    const {value} = el || undefined;
    loadList(fromTS, toTS, {}, value).catch();
  };

  return (
    <div style={{position: 'relative'}}>
      <div className={'calendar'} style={{position: 'relative', fontWeight: 'bold'}}>
        <FullCalendar
          plugins={[ dayGridPlugin ]}
          headerToolbar={{
            left: 'today prevYear,prev,next,nextYear addScheduleButton',
            center: 'title',
            right: ''
          }}
          customButtons={{addScheduleButton: {text: 'ADD SCHEDULE', click: onAddSchedule}}}
          initialView='dayGridMonth'
          initialDate={initialDate}
          // navLinks={true}
          // navLinkDayClick={onDateChange}
          showNonCurrentDates={false}
          fixedWeekCount={false}
          eventTextColor={'#666'}
          contentHeight={'auto'}
          events={state.events}
          eventClick={onEditSchedule}
          datesSet={(date) => {
            fromTS = util.toTS(date.start);
            toTS = util.toTS(date.end);
            loadList(fromTS, toTS, {}).catch();
          }}
        />
        <div className={'form'} style={{position: 'absolute', top: 10, right: 10, marginBottom: 0, width: 'inherit'}}>
          <div style={{display: 'flex'}}>
            <div className={'form__form-group'}>
              <select value={branchRef.current} onChange={onBranchChange}>
                {/*<option value={0}>ALL</option>*/}
                {areaOptions.map(({value, label}) => {
                  return <option key={value} value={value}>{label}</option>
                })}
              </select>
            </div>
            <div className={'form__form-group flex ml-1'}>
              <input name={'qryText'} id={'staff-schedule-search'} placeholder={'Search'} size={15} onKeyDown={onKeyDown}/>
              <a href={'/#search'} onClick={onSearch} className={'inbox-search-icon'}><MagnifyIcon/></a>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={state.open} className={'modal-dialog--form'} centered style={{width: 720}}>
        <Form
          onFormik={fmk => formik = fmk}
          title={`${state.mode ?? 'add'} Staff Schedule`}
          horizontal
          fields={fields}
          values={state.data}
          onSubmit={state.formDisabled ? undefined : onSave}
          onDelete={state.showDelete ? onDelete : undefined}
          onCancel={() => setState({open: false})}
          onChange={() => {}}
          render={(formik, fields, errors) => renderFields(formik, fields, errors)}
        />
      </Modal>
      {searchStaff.render()}
    </div>
  );
};

export default StaffFullSchedule;
