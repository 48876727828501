/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import AccountInvoiceCMFormManager, {getAccountEntryData, getAccountFormData} from '../../forms/account/AccountInvoiceCMFormManager';
import AccountTopSearchFormManager from '../../forms/account/AccountTopSearchFormManager';
import AccountInvoiceAddItemFormManager from '../../forms/account/AccountInvoiceAddItemFormManager';
import {DangerButton, DefaultButton} from '../../components/buttons';
import {apiAccount, util} from '../../services/service';
import {INVOICE} from "../../forms/field-defs/account";
import {useInvoiceCMPreviewModal} from "../../print-layouts/InvoiceCMPrint";
import {useUser} from "../../redux/reducers/userReducer";
import useAccountPaymentModal from "../../modals/account/useAccountPaymentModal";
import toInt from "validator/es/lib/toInt";
import {useAsyncStatusModal} from "../../components/AsyncStatusModal";

const type = INVOICE.TYPE_CM;


const accountForm = new AccountInvoiceCMFormManager();
const accountSearchForm = new AccountTopSearchFormManager(type);
const accountInvoiceAddItem = new AccountInvoiceAddItemFormManager(type);

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const AccountInvoiceCM = () => {
  const title = 'Commission Settlement';
  useTopbar({label: 'Account'}, {label: title});
  const invoiceNoRef = React.useRef();
  const user = useUser();
  const teamRef = React.useRef();
  const isManagerRef = React.useRef();
  teamRef.current = user.team_main;
  isManagerRef.current = user.isManager;
  const qs = util.getQS();
  const {id} = qs;
  const [mode, setMode] = React.useState('add');
  document.title = `${title} ${mode}`;
  const invoiceCMPrintModal = useInvoiceCMPreviewModal();
  const accountPaymentModal = useAccountPaymentModal();

  const onSave = async () => {
    // if(!util.isValidPermitForAccount(user, mode)) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    const values = accountForm.getValues(undefined, true);
    // const values = accountForm.getValues();
    if (!values) {
      return;
    }
    const gridData = accountInvoiceAddItem.getGridData();
    const data = getAccountEntryData(values, gridData);
    const invDate = util.toTS(data.inv_date);
    const dataInfo = {...data, invDate};
    const res = await apiAccount.setSettlement(dataInfo);
    if (res) {
      util.showSuccess('Account Entry has been saved successfully.');
      onSearch();
    }
  };

  const onPrint = () => {
    apiAccount.accountCMDataDetail({id: id}).then((res) => {
      const {data} = res;
      invoiceCMPrintModal.open(accountForm, accountSearchForm, accountInvoiceAddItem, data);
    });
  }

  const onDelete = () => {
    if(id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.delAccountCM(parseInt(id));
        if(res) {
          util.showSuccess('Account has been deleted successfully!');
          util.nav('/admin/account/list');
        }
      })
    }
  };

  const onSearch = () => {
    setMode('edit');
    apiAccount.settlementList({id: id}).then(({data}) => {
      if (Array.isArray(data)) {
        const {formData, gridData} = getAccountFormData(data[0]);
        const [{inv_no}] = data;
        invoiceNoRef.current = inv_no;
        accountSearchForm.mode = mode;
        accountForm.setValuesFast(formData);
        accountInvoiceAddItem.setAccountId(id);
        accountInvoiceAddItem.setGridData(gridData);
      } else {
        // api 에서 오류를 처리하므로 여기서는 그냥 아무작업도 하지 않음
      }
    });
  };

  const asyncStatusModal = useAsyncStatusModal('Processing... please wait...');
  const onMakePaidAll = () => {
    asyncStatusModal.open();
    apiAccount.setPaidAllCM(toInt(id)).then((data) => {
      if(data) {
        util.showSuccess('Commission has been applied successfully.');
        onSearch();
      }
    }).finally(() => asyncStatusModal.close());;
  };

  React.useEffect(() => {
    if (id) {
      onSearch();
    }
  }, [id]);

  accountInvoiceAddItem.onSearch = onSearch;
  accountInvoiceAddItem.onMakePaidAll = onMakePaidAll;

  const paymentHistory = () => {
    accountPaymentModal.open(id ?? 0, invoiceNoRef.current ?? '');
  }

  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              <DefaultButton label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
              <DefaultButton label={'P. History'} onClick={paymentHistory} noMargin disabled={id && teamRef.current === 'A' ? false : true} /><div className={'w-8'} />
              <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
              {isManagerRef.current && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
            </div>
          </div>
          {accountSearchForm.renderForm()}
        </CardBody>
      </Card>
      {accountForm.renderForm()}
      {accountInvoiceAddItem.renderForm()}
      {invoiceCMPrintModal.render()}
      {accountPaymentModal.render()}
      {asyncStatusModal.render()}
    </>
  );
};

export default AccountInvoiceCM;
