/**
 * @flow
 */
import React from 'react';
import {util} from "../services/service";

class AEMManifest extends React.Component {
  totalPcs;
  totalKgs;
  totalLbs;
  render() {
    const {printData = {}, user = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    const {print} = user;
    const {company, address, email, fax, phone, pic} = print;
    if (blData.id <= 0) return null;
    const {mawbNo, companyName, remark} = printFormData;
    const {filingNo} = blData;
    const {others} = blData.blManifest;
    const {consignee, consigneeAddr} = blData.jcustomer;
    const {flightNo, carrier, flightDate, flightTime, arrivalDate, arrivalTime} = blData.jvessel;
    const {departure, destination} = blData.jroute;

    return (
      <div style={{fontSize: '10px', color: 'black'}}>
        <style type="text/css">
          {"@media print{@page {size: landscape}}"}
        </style>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td className={'no-border-all center'}>
              <div className={'flex-column center mb-4'}>
                <div style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
                <div>{address}</div>
                <div>Prepared by {pic} {util.formatDT(Date.now())}</div>
                <div>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td className={'no-border-all center'}>
              <h3 style={{color: 'black'}}><b>CARGO MANIFEST</b></h3>
            </td>
          </tr>
          </tbody>
        </table>
        <hr/>
        <table className={'print-table'} style={{width: '100%', fontSize: '12px'}}>
          <tbody>
          <tr>
            <td className={'no-border-all'} rowSpan={2}><div className={'label'}>TO:</div></td>
            <td className={'no-border-all'} rowSpan={2} width={'50%'}><div className={'value'}>{consignee}{'\n'}{consigneeAddr}</div></td>
            <td className={'pointColor'}><div className={'label'}>MAWB No.</div></td>
            <td><div className={'value'}>{mawbNo}</div></td>
            <td className={'pointColor'}><div className={'label'}>FILING No.</div></td>
            <td><div className={'value'}>{filingNo}</div></td>
          </tr>
          <tr>
            {/*<td className={'no-border-all'}><div className={'label'}>{consigneeAddr}</div></td>*/}
            <td className={'pointColor'}><div className={'label'}>FLIGHT No.</div></td>
            <td><div className={'value'}>{flightNo}</div></td>
            <td className={'pointColor'}><div className={'label'}>CARRIER</div></td>
            <td><div className={'value'}>{carrier}</div></td>
          </tr>
          <tr>
            <td className={'no-border-all'} rowSpan={2}><div className={'label'}>FROM:</div></td>
            <td className={'no-border-all'} rowSpan={2} width={'50%'}><div className={'value'}>{companyName}{'\n'}{address}</div></td>
            <td className={'pointColor'}><div className={'label'}>DEPARTURE</div></td>
            <td><div className={'value'}>{departure}</div></td>
            <td className={'pointColor'}><div className={'label'}>ETD</div></td>
            <td><div className={'value'}>{util.formatD(flightDate)} {flightTime}</div></td>
          </tr>
          <tr>
            {/*<td className={'no-border-all'}><div className={'value'}>{address}</div></td>*/}
            <td className={'pointColor'}><div className={'label'}>DESTINATION</div></td>
            <td><div className={'value'}>{destination}</div></td>
            <td className={'pointColor'}><div className={'label'}>ETA</div></td>
            <td><div className={'value'}>{util.formatD(arrivalDate)} {arrivalTime}</div></td>
          </tr>
          </tbody>
        </table>
        <div style={{height: '50px'}}>&nbsp;</div>
        <table className={'print-table'} style={{width: '100%', fontSize: '12px'}}>
          <tbody>
          <tr>
            <td className={'no-border-r no-border-l'} style={{paddingTop: '6px'}}>
              <div className={'value'}>HAWB NO</div>
              <div className={'value'}>PCS</div>
            </td>
            <td className={'no-border-r no-border-l'} style={{paddingTop: '6px'}}><div className={'value'}>WEIGHT</div></td>
            <td className={'no-border-r no-border-l'} style={{paddingTop: '6px'}}><div className={'value'}>SHIPPER INFORMATION</div></td>
            <td className={'no-border-r no-border-l'} style={{paddingTop: '6px'}}><div className={'value'}>CONSIGNEE/NOTIFY INFORMATION</div></td>
            <td className={'no-border-r no-border-l'} style={{paddingTop: '6px'}}><div className={'value'}>NATURE OF GOODS</div></td>
            <td className={'no-border-r no-border-l'} style={{paddingTop: '6px'}}><div className={'value'}>TERM</div></td>
          </tr>
          {this.renderItems(others)}
          {/*{this.renderEmptyItems(others)}*/}
          <tr>
            <td className={'no-border-r no-border-l'}><div className={'value'}>TOTAL</div></td>
            <td className={'no-border-r no-border-l'}><div className={'value'}>{this.totalPcs} (PCS)</div></td>
            <td className={'no-border-r no-border-l'}>
              <div className={'value'}>{this.totalKgs.toFixed(2)} KGS</div>
              <div className={'value'}>{(this.totalKgs * 2.20462).toFixed(2)} LBS</div>
            </td>
            <td className={'no-border-r no-border-l'} colSpan={3}><div className={'value'}>{others ? others?.length: 0} BL(S)</div></td>
          </tr>
          <tr>
            <td className={'no-border-r'}><div className={'value'}>REMARK:</div></td>
            <td className={'no-border-l'} colSpan={5}><div className={'label'} style={{whiteSpace: 'pre-line'}}>{remark}</div></td>
          </tr>
          </tbody>
        </table>
        <div style={{height: '100px'}}>&nbsp;</div>
        <hr/>
      </div>
    );
  }
  renderItems(others) {
    this.totalPcs = 0;
    this.totalKgs = 0;
    this.totalLbs = 0;
    const lb = 2.20462;
    return (
      others?.map((item, idx) => {
        this.totalPcs += util.toInt(item.pcs);
        this.totalKgs += isNaN(util.toFloat(item.weight)) ? 0 : util.toFloat(item.weight);
        return (
          <>
          <tr key={idx}>
            <td className={'no-border-all'}>
              <div className={'label'}>{item.hawbNo}</div>
              <div className={'label'}>{item.pcs} {item.pcsType}</div>
            </td>
            <td className={'no-border-all'}>
              <div className={'label'}>{item.weight} K</div>
              <div className={'label'}>{(item.weight * lb).toFixed(2)} L</div>
            </td>
            <td className={'no-border-all'}><div className={'label'} style={{whiteSpace: 'pre-line'}}>{item.shipper}</div></td>
            <td className={'no-border-all'}>
              <div className={'label'} style={{whiteSpace: 'pre-line'}}>{item.consignee ? `(C) ${item.consignee}` : ''}</div>
              <div className={'label'} style={{whiteSpace: 'pre-line'}}>{item.notify ? `(N) ${item.notify}` : ''}</div>
            </td>
            <td className={'no-border-all'}><div className={'label'}>{item.goods}</div></td>
            <td className={'no-border-all'}><div className={'label'}>{item.term}</div></td>
          </tr>
          <tr>
            <td colSpan={6} className={'no-border-all'}>&nbsp;</td>
          </tr>
          </>
        );
      })
    );
  }
  renderEmptyItems(others) {
    const empty = [];
    let length = 10 - others.length;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={5} className={'no-border-all'}>&nbsp;</td>
        </tr>
      );
    }
    return empty;
  }
}

export default AEMManifest;
