/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {api, util} from "../../services/service";
import {partnerTypeOptions, yesNoOptions} from "../helper";
import {StaffSearchInput} from "../../components/SearchInput";

class PartnerListSearchFormManager extends FormManager {
  onSearch;
  constructor() {
    super({
      prefix: 'partner-list-search',
      fields: [
        // eslint-disable-next-line max-len
        {name: 'partnerType', serverName: 'partner_type', label: 'Type', smallMargin: true, options: [{value: '', label: 'ALL'}, ...partnerTypeOptions], noDefOption: true},
        {name: 'branchId', serverName: 'branchId', label: 'Branch', smallMargin: true, noDefOption: true},
        {name: 'staff', serverName: 'staff_name', label: 'PIC', smallLabel: true},
        {name: 'staffId', serverName: 'staff_id', label: 'PIC', smallLabel: true},
        {name: 'isActive', serverName: 'isActive', label: 'Actived', smallMargin: true, options: [...yesNoOptions, {label: 'ALL', value: ''}], noDefOption: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    _f('branchId').options = [{value: '', label: 'ALL'}, ...areaOptions];
    const partnerType = _v('partnerType');
    const branchId = _v('branchId');
    const staffId = _v('staffId');
    const isActive = _v('isActive')??'Y';
    React.useEffect(() => {
      if (!(partnerType === undefined && branchId === undefined && staffId === undefined && isActive === undefined)) {
        _c('isActive', isActive);
        this.onSearch();
      }
    }, [partnerType, branchId, staffId, isActive]);
    // React.useEffect(() => {
    //   _c('isActive', isActive??'Y');
    // }, [isActive]);
    return (
      <>
        {_r('partnerType')}
        {_r('branchId')}
        <StaffSearchInput
          vertical
          className={'flex-1'}
          displayField={_n('staff')}
          idField={_n('staffId')}
          formik={formik} fields={fields} errors={errors}
        />
        {_r('isActive')}
      </>
    );
  }
}

export default PartnerListSearchFormManager;
