/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions} from '../forms/field-defs/bl';

class OEHBookConf extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return null;
    }
    const {
      shipper, shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, hblNo,
      bkgNo, filingNo, b13No,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, cargoPickupAddr, cargoDeliveryAddr,
      said, say, desc, mark, remark, unit, forTrTo, onwardRoute, poNo,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, commodity, packageCount, packageType, grossWeightKg, cbm, containerSummary = '',
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      emptyPickup, forwardAgent, partner,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
      trucker, truckerPhone, truckerFax,
      docDate, docTime,
      warehouseDate, warehouseTime,
      portOpenDate, portOpenTime,
      portDate, portTime,
      railDate, railTime,
      vgmDate, vgmTime,
    } = printData;
    const packages = packageCount ? `${packageCount} ${packageType}` : '';
    const phoneTrucker = truckerPhone ? `Phone: ${truckerPhone}` : '';
    const faxTrucker = truckerFax ? `Fax: ${truckerFax}` : '';
    const {renderItemCell: _l, renderItemLabelCell: _lb, renderLabelValue: _lv} = this;
    return (
      <div style={{fontSize: 16, color: 'black', lineHeight: 1.3}}>
        <div className={'flex-column center'}>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
          <div style={{fontSize: 14}}>{address}</div>
          <div style={{fontSize: 14}}>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
          <div style={{fontSize: 24, fontWeight: 'bold', marginTop: 12}}>BOOKING CONFIRMATION</div>
        </div>
        <div className={'flex'}>
          <div className={'flex middle flex-1'}>
            <div>To: {shipper}</div>
          </div>
          <div className={'flex-1'}>
            <div>Sales PIC : {pic}</div>
            <div>Issued At : {util.formatD(Date.now())} Issued By : VANCOUVER</div>
            <div>TEL: {phone} EMAIL: {email}</div>
          </div>
        </div>
        <div className={'flex center py-1'} style={{borderTop: '1px solid #000'}}>
          We received a booking request by you as follows. Please review following items and advise us of any discrepancy.
        </div>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            {_l('SHIPPER', {width: '50%'})}
            {_l('BOOKING INFORMATION', {width: '50%'})}
          </tr>
          <tr>
            {_lb(shipperAddr)}
            {_lb((
              <>
                {_lv('Carrier BKG No.', bkgNo)}
                {_lv('HB/L No.', hblNo)}
                {_lv('EXPORT REF. No.', exportRefNo)}
                {_lv('P/O No.', poNo)}
                {_lv('Freight Term', freightLabel)}
              </>
            ), {width: '50%'})}
          </tr>
          <tr>
            {_l('CONSIGNEE', {width: '50%'})}
            {_l('AGENT', {width: '50%'})}
          </tr>
          <tr>
            {_lb(consigneeAddr, {rowSpan: 3})}
            {_lb(forwardAgent)}
          </tr>
          <tr>
            {_l('NOTIFY', {width: '50%'})}
          </tr>
          <tr>
            {_lb(notifyAddr, {width: '50%'})}
          </tr>
          <tr>
            {_l('VESSEL & ROUTE INFORMATION', {colSpan: 2})}
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={'flex'}>
                <div className={'flex-1'}>
                  {_lv('VESSEL & VOYAGE', vslVoy)}
                  {_lv('PLACE OF RECEIPT', porFull)}
                  {_lv('PORT OF LOADING', polFull)}
                  {_lv('PORT OF DISCHARGE.', podFull)}
                  {_lv('PLACE OF DELIVERY', delivery)}
                  {_lv('FINAL DESTINATION', final)}
                </div>
                <div className={'flex-1'}>
                  {_lv('CARRIER', carrier)}
                  {_lv('', '')}
                  {_lv('ETD', util.formatD(etd))}
                  {_lv('ETA', util.formatD(eta))}
                  {_lv('', '')}
                  {_lv('MOVE TYPE', `${svcTerm1Label} / ${svcTerm2Label}`)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            {_l('CARGO INFORMATION', {colSpan: 2})}
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={'flex'}>
                <div className={'flex-1'}>
                  {_lv('COMMODITY', commodity)}
                  {/*{_lv('PACKAGES', `${packageCount} ${packageType} / ${containerSummary}`)}*/}
                  {_lv('PACKAGES', util.displayWithMark(packages, containerSummary, '/'))}
                  {/*{_lv('WEIGHT', `${util.weightForPrint(grossWeightKg, 'KG')} / ${util.weightForPrint(grossWeightKg, 'LB')}`)}*/}
                  {_lv('WEIGHT', util.displayWithMark(util.weightForPrint(grossWeightKg, 'KG'), util.weightForPrint(grossWeightKg, 'LB'), '/'))}
                  {_lv('DANGEROUS', 'NO')}
                </div>
                <div className={'flex-1'}>
                  <div className={'value4'}>{' '}</div>
                  <div className={'value4'}>{' '}</div>
                  {/*{_lv('MEASUREMENT', `${util.volumeForPrint(cbm, 'KG')} / ${util.volumeForPrint(cbm, 'LB')}`)}*/}
                  {_lv('MEASUREMENT', util.displayWithMark(util.volumeForPrint(cbm, 'KG'), util.volumeForPrint(cbm, 'LB'), '/'))}
                  {_lv('L/C', 'NO')}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            {_l('CARGO PICK UP')}
            {_l('CUT-OFF DATE')}
          </tr>
          <tr>
            {_lb(cargoPickupAddr)}
            {_lb(
              <>
                {_lv('FIRST RECEIVING', `${portOpenDate} ${portOpenTime}`)}
                {_lv('RAIL', `${railDate} ${railTime}`)}
                {_lv('PORT', `${portDate} ${portTime}`)}
                {_lv('WAREHOUSE', `${warehouseDate} ${warehouseTime}`)}
                {_lv('DOC', `${docDate} ${docTime}`)}
                {_lv('VGM', `${vgmDate} ${vgmTime}`)}
              </>
            )}
          </tr>
          <tr>
            {_l('EMPTY PICK UP')}
            {_l('CARGO DELIVERY LOCATION')}
          </tr>
          <tr>
            {_lb(emptyPickup, {height: 80})}
            {_lb(cargoDeliveryAddr, {height: 80})}
          </tr>
          <tr>
            {_l('CUSTOMS BROKER')}
            {_l('TRUCKER')}
          </tr>
          <tr>
            {_lb(' ', {height: 80})}
            {/*{_lb(`${trucker}\nPhone: ${truckerPhone??''}, Fax: ${truckerFax??''}`, {height: 80})}*/}
            {_lb(`${trucker}\n${util.displayWithMark(phoneTrucker, faxTrucker, ',')}`, {height: 80})}
          </tr>
          <tr>
            {_l('REMARK', {colSpan: 2})}
          </tr>
          <tr>
            {_lb(remark, {colSpan: 2, height: 80})}
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderItemCell(value = ' ', tdProps) {
    return <td {...tdProps}><div className={'value4'}>{value}</div></td>;
  }
  renderItemLabelCell(value = ' ', tdProps) {
    return <td {...tdProps}><div className={'label3'} style={{whiteSpace: 'pre-line'}}>{value}</div></td>;
  }
  renderLabelValue(label, value, labelWidth = 180) {
    return (
      <div className={'flex'}>
        <div className={'label3'} style={{width: labelWidth}}>{label}</div>
        <div className={'label3 mr-12'}>{label?`:`:''}</div>
        <div className={'value4'}>{value}</div>
      </div>
    );
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers} = blData;
    const {
      trucker = '', phone: truckerPhone = '', fax: truckerFax = '',
      docDate = '', docTime = '',
      warehouseDate = '', warehouseTime = '',
      portOpenDate = '', portOpenTime = '',
      portDate = '', portTime = '',
      railDate = '', railTime = '',
      vgmDate = '', vgmTime = '',
      remark = '',
      jextra,
    } = printFormData;
    const {shipper, shipperAddr = '', consigneeAddr = '', forwardingAgentAddr = '', notifyAddr = '', cargoPickupAddr = '', warehouseAddr: cargoDeliveryAddr = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = '', exportRefNo = '', hblNo} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta} = jvessel;
    const {polLabel, podLabel} = jroute;
    const {bladdress, cutoff, route} = jextra;
    const {emptyPickup, forwardAgent, partner} = bladdress;
    const {doc, pickupDate, portOpen, rail, vgm} = cutoff;
    const {delivery, final, podFull, polFull, porFull} = route;
    let {freight, orgBlType, svcTerm1, svcTerm2, commodity, package: packageCount, packageType, grossWeightKg, cbm, containerSummary} = jshipment;
    const {said, say, desc, mark, remark: markRemark, showAs: unit = 'KG', forTrTo, onwardRoute, poNo} = jmark ?? {};
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.labelFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.labelFromOptions(svcTerm2, svcTypeOptions);
    const blankLines = [];
    const blankCount = 10 - containers?.length;
    for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    return {
      shipper, shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, hblNo,
      bkgNo, filingNo, b13No,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, cargoPickupAddr, cargoDeliveryAddr,
      said, say, desc, mark, remark, unit, forTrTo, onwardRoute, poNo,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, commodity, packageCount, packageType, grossWeightKg, cbm, containerSummary,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      emptyPickup, forwardAgent, partner,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
      trucker, truckerPhone, truckerFax,
      docDate, docTime,
      warehouseDate, warehouseTime,
      portOpenDate, portOpenTime,
      portDate, portTime,
      railDate, railTime,
      vgmDate, vgmTime,
    };
  }
}

export default OEHBookConf;
