/**
 * @flow
 */
import React from "react";
import {util} from '../service';
import API from "./API";

class APIMoving extends API {
  async setMovingBL(data) {
    const token = util.getToken();
    const action = 'SetMovingBL';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async getTapeList() {
    const token = util.getToken();
    const action = 'GetTapeList';
    const reqData = {action, token, data: {}};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  useTapeOptions() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getTapeList().then(({data}) => {
        const newOptions = data.map((data) => ({label: data, value: data}));
        setOptions([...newOptions]);
      }).catch();
    }, []);
    return options;
  }
  async getBLData(data) {
    const token = util.getToken();
    const action = 'GetBLData';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async setWorkOrder(data) {
    const token = util.getToken();
    const action = 'SetWorkOrder';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async delWorkOrder(data) {
    const token = util.getToken();
    const action = 'DelWorkOrder';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async setAR(data) {
    const token = util.getToken();
    const action = 'SetAR';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async setAP(data) {
    const token = util.getToken();
    const action = 'SetAP';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async setAccountInfo(data) {
    const token = util.getToken();
    const action = 'SetAccountInfo';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async delAccountItem(data) {
    const token = util.getToken();
    const action = 'DelAccountItem';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async setAccountItem(data) {
    const token = util.getToken();
    const action = 'SetAccountItem';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async getAccountItems(data) {
    const token = util.getToken();
    const action = 'GetAccountItems';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getAccountData(data) {
    const token = util.getToken();
    const action = 'GetAccountData';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getMBLHouses(data) {
    const token = util.getToken();
    const action = 'GetMBLHouses';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getBulkHouse(data) {
    const token = util.getToken();
    const action = 'GetBulkHouse';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async setMBLHouses(data) {
    const token = util.getToken();
    const action = 'SetMBLHouses';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async getARAPItemList(data) {
    const token = util.getToken();
    const action = 'GetARAPItemList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getARAPItem(data) {
    const token = util.getToken();
    const action = 'GetARAPItem';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async setARAPItems(data) {
    const token = util.getToken();
    const action = 'SetARAPItems';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async delARAPItems(data) {
    const token = util.getToken();
    const action = 'DelARAPItems';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async getHBlList(data) {
    const token = util.getToken();
    const action = 'GetHBlList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getMBlList(data) {
    const token = util.getToken();
    const action = 'GetMBlList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getStorageList(data) {
    const token = util.getToken();
    const action = 'GetStorageList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getUpcommingList(data) {
    const token = util.getToken();
    const action = 'GetUpcommingList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async setPackingList(data) {
    const token = util.getToken();
    const action = 'SetPackingList';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async getPackingList(data) {
    const token = util.getToken();
    const action = 'GetPackingList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async setBLFlag(data) {
    const token = util.getToken();
    const action = 'SetBLFlag';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
  async getPresetItems(data) {
    const token = util.getToken();
    const action = 'GetPresetItems';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async getPresetList(data) {
    const token = util.getToken();
    const action = 'GetPresetList';
    const reqData = {action, token, data};
    return await API.send('moving/R', reqData).then((res) => res);
  }
  async setAccount(data) {
    const token = util.getToken();
    const action = 'SetAccount';
    const reqData = {action, token, data};
    return await API.send('moving/CUD', reqData).then((res) => res);
  }
}

export default APIMoving;
