/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import OEMPrint from '../../../print-layouts/OEMPrint';
import {usePrintModal} from './usePrintModal';

const FN = {
  TITLE: 'title',
  WATERMARK: 'watermark',
};
const SN = {
  TITLE: 'title',
  WATERMARK: 'watermark',
};
export const oemPrintTitleOptions = [
  {value: 'MBL', label: 'Master Bill of Lading'},
  {value: 'BKF', label: 'Booking Form'},
  {value: 'SPR', label: 'Shipping Request'},
  {value: 'SPI', label: 'Shipping Instruction'},
];

export class OEMBLPrintForm extends FormManager {
  static FN = FN;
  static SN = SN;
  constructor() {
    super({
      prefix: 'print-bl-oem',
      fields: [
        {name: FN.TITLE, serverName: SN.TITLE, label: 'Select Title', options: oemPrintTitleOptions, noDefOption: true},
        {name: FN.WATERMARK, serverName: SN.WATERMARK, label: '', type: 'checkbox'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    const {renderField: _r} = this;
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.TITLE)}
        <div className={'flex middle'} style={{marginLeft: 120}}>
          <div style={{width: 50}}>{_r(FN.WATERMARK)}</div>
          <span>Seaway Bill Watermark</span>
        </div>
      </div>
    );
  };
}

const blPrintForm = new OEMBLPrintForm();

export function useOEMBLPrintModal(blForm) {
  const onOpen = () => {};
  return usePrintModal(true,'OCEAN EXPORT SR', 1024, blForm, blPrintForm, OEMPrint, onOpen);
}
