/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';

interface FilterPaneProps {
  title?: string;
  children?: React$Node;
}

const FilterPane = (props: FilterPaneProps) => {
  return (
    <Card className={'search-pane'}>
      <CardBody className={'p-16'}>
        <div className={'card__title mb-12 bold-text'}>{props.title ?? 'FILTER'}</div>
        {props.children}
      </CardBody>
    </Card>
  );
};

export default FilterPane;
