import React from 'react';
import {Table} from 'reactstrap';
import Panel from '../../../components/Panel';
import {util} from "../../../services/service";
import {PrimaryButton} from "../../../components/buttons";

const UnlinkedBLList = (props) => {
  const data = props.data;
  const unlinkedList = data ? data.unlinked : [];
  const renderUnlinkedList = () => {
    return (
      unlinkedList.map((item, idx) => {
        return (
          <tr key={idx}>
            <td className="td-black">{util.formatD(item.cdate)}</td>
            <td className="td-black"><a href={`/admin/bl/house/${item.bl_id}`} onClick={(e) => {
              e.preventDefault();
              util.nav(`/admin/bl/house/${item.bl_id}`);
            }}><span style={{color: 'black', textDecorationLine: 'underline'}}>{item.bl_no}</span></a></td>
            <td className="td-black">{item.customer}</td>
          </tr>
        );
      })
    );
  };
  const onBLList = (e) => {
    e.preventDefault();
    util.nav('/admin/bl/list');
  };
  return (
    <>
    <Panel
      md={12}
      lg={6}
      xl={4}
      xs={12}
      title={'B/L LIST (NO A/R)'}
      subhead=""
    >
      <div>
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}><a href={'/admin/bl/list'} onClick={onBLList}>B/L LIST</a></div>
        <Table responsive className="table dashboard__occupancy-table">
          <thead>
          <tr style={{borderTop: 'none'}}>
            <th>Date</th>
            <th>B/L No.</th>
            <th>Customer</th>
          </tr>
          </thead>
          <tbody>
          {renderUnlinkedList()}
          </tbody>
        </Table>
      </div>
    </Panel>
    </>
  );
};

export default UnlinkedBLList;