/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {usePrintModal} from './usePrintModal';
import AIHDelOrder from '../../../print-layouts/AIHDelOrder';
import {apiBL, util} from '../../../services/service';
import {freightTypeOptions} from '../../field-defs/bl';
import {TradePartnerSearchInput} from '../../../components/SearchInput';

const FN = {
  HBL_NO: 'hblNo',
  FILING_NO: 'filingNo',
  FREIGHT_TERM: 'freightTerm',
  VALUE_TO_SHOW: 'valueToShow',
  TRUCKING_COMPANY: 'truckingCompany',
  TRUCKING_COMPANY_ID: 'truckingCompanyId',
  PICKUP: 'pickup',
  PICKUP_ID: 'pickupId',
  PICKUP_ADDR: 'pickupAddr',
  PICKUP_PIC: 'pickupPIC',
  PICKUP_PHONE: 'pickupPhone',
  PICKUP_FAX: 'pickupFax',
  DELIVERY: 'delivery',
  DELIVERY_ID: 'deliveryId',
  DELIVERY_PIC: 'deliveryPIC',
  DELIVERY_ADDR: 'deliveryAddr',
  DELIVERY_PHONE: 'deliveryPhone',
  DELIVERY_FAX: 'deliveryFax',
  DESCRIPTION: 'description',
  REMARK: 'remark',
};

class AIHDOPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'do-aih',
      fields: [
        {name: FN.HBL_NO, serverName: FN.HBL_NO, label: 'HB/L No.', readonly: true},
        {name: FN.FILING_NO, serverName: FN.FILING_NO, label: 'Filing No.', readonly: true},
        {name: FN.FREIGHT_TERM, serverName: FN.FREIGHT_TERM, label: 'Freight Term', readonly: true},
        {name: FN.VALUE_TO_SHOW, serverName: FN.VALUE_TO_SHOW, label: 'Carrier Field', options: carrierFieldOptions, noDefOption: true},
        {name: FN.TRUCKING_COMPANY, serverName: FN.TRUCKING_COMPANY, label: 'Trucking Comp.'},
        {name: FN.TRUCKING_COMPANY_ID, serverName: FN.TRUCKING_COMPANY_ID},
        {name: FN.PICKUP, serverName: FN.PICKUP, label: 'Pickup', smallMargin: true},
        {name: FN.PICKUP_ID, serverName: FN.PICKUP_ID},
        {name: FN.PICKUP_ADDR, serverName: FN.PICKUP_ADDR, label: 'Address', type: 'textarea', smallMargin: true},
        {name: FN.PICKUP_PIC, serverName: FN.PICKUP_PIC, label: 'PIC', smallMargin: true},
        {name: FN.PICKUP_PHONE, serverName: FN.PICKUP_PHONE, label: 'Phone/Fax'},
        {name: FN.PICKUP_FAX, serverName: FN.PICKUP_FAX, label: ''},
        {name: FN.DELIVERY, serverName: FN.DELIVERY, label: 'Delivery', smallMargin: true},
        {name: FN.DELIVERY_ID, serverName: FN.DELIVERY_ID},
        {name: FN.DELIVERY_ADDR, serverName: FN.DELIVERY_ADDR, label: 'Address', type: 'textarea', smallMargin: true},
        {name: FN.DELIVERY_PIC, serverName: FN.DELIVERY_PIC, label: 'PIC', smallMargin: true},
        {name: FN.DELIVERY_PHONE, serverName: FN.DELIVERY_PHONE, label: 'Phone/Fax'},
        {name: FN.DELIVERY_FAX, serverName: FN.DELIVERY_FAX, label: ''},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: 'Description', type: 'textarea'},
        {name: FN.REMARK, serverName: FN.REMARK, label: 'Remark', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    // eslint-disable-next-line max-len
    const _tp = (name, onOK) => <TradePartnerSearchInput displayField={_n(name)} idField={_n(`${name}Id`)} onOK={onOK} formik={formik} fields={fields} errors={errors} />;
    const onOK = (name, {id, value, Address: address, phone, fax, pic}) => {
      _c(`${name}`, value);
      _c(`${name}Id`, id);
      _c(`${name}Addr`, address);
      _c(`${name}Phone`, phone);
      _c(`${name}Fax`, fax);
      _c(`${name}PIC`, pic);
    };
    return (
      <div className={'flex w-full mr-12 mb-24'}>
        <div className={'flex-1'}>
          {_r(FN.HBL_NO)}
          {_r(FN.FILING_NO)}
          {_r(FN.FREIGHT_TERM)}
          {_r(FN.VALUE_TO_SHOW)}
          {_tp(FN.TRUCKING_COMPANY)}
          {/*{_r(FN.DESCRIPTION)}*/}
          {_r(FN.REMARK)}
        </div>
        <div className={'flex-1'}>
          {_tp(FN.PICKUP, (data) => onOK(FN.PICKUP, data))}
          {_r(FN.PICKUP_ADDR)}
          {_r(FN.PICKUP_PIC)}
          <div className={'flex'}>
            <div className={'flex-2'}>{_r(FN.PICKUP_PHONE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.PICKUP_FAX)}</div>
          </div>
          {_tp(FN.DELIVERY, (data) => onOK(FN.DELIVERY, data))}
          {_r(FN.DELIVERY_ADDR)}
          {_r(FN.DELIVERY_PIC)}
          <div className={'flex'}>
            <div className={'flex-2'}>{_r(FN.DELIVERY_PHONE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.DELIVERY_FAX)}</div>
          </div>
        </div>
      </div>
    );
  };
}

const carrierFieldOptions = [
  {value: 'CN', label: 'Carrier Name'},
  {value: 'VN', label: 'Vessel Name'},
];

const printForm = new AIHDOPrintForm();

export function useAIHDOPrintModal(blForm) {
  const onOpen = () => {
    const hawbNo = blForm.getValue('hawbNo');
    const filingNo = blForm.getValue('filingNo');
    const freight = blForm.getValue('freight');
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    printForm.setValue(FN.HBL_NO, hawbNo);
    printForm.setValue(FN.FILING_NO, filingNo);
    printForm.setValue(FN.FREIGHT_TERM, freightLabel);
  };
  const onLoadPrintData = async (blData) => {
    const {data} = await apiBL.getBLPrintData(blData.blNo);
    return {serverData: data?.[0] ?? {}};
  };
  return usePrintModal(false, 'DELIVERY ORDER', 1024, blForm, printForm, AIHDelOrder, onOpen, onLoadPrintData, 900, false);
}
