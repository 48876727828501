import React from 'react';
import {Table} from 'reactstrap';
import Panel from '../../../components/Panel';
import {util} from "../../../services/service";

const GrossSales = (props) => {
  const data = props.data;
  const branchList = data ? data.branch : [];
  const serviceList = data ? data.service : [];
  const renderList = (list, name) => {
    let totalCAD = 0, totalUSD = 0, totalKRW = 0;
    return (
      list.map((item, idx) => {
        const key = Object.keys(item);
        const value = Object.values(item);
        const CAD = value ? value[0].CAD.gross : 0;
        const USD = value ? value[0].USD.gross : 0;
        const KRW = value ? value[0].KRW.gross : 0;
        totalCAD += CAD;
        totalUSD += USD;
        totalKRW += KRW;
        return (
          <>
          <tr>
            <td className="td-head">{keyName(key)}</td>
            <td className="td-black">{util.currencyFormat(CAD)}</td>
            <td className="td-black">{util.currencyFormat(USD)}</td>
            <td className="td-black">{util.currencyFormat(KRW)}</td>
          </tr>
          {idx === list.length-1 && name === 'branch' &&
            <tr>
              <td className="td-head"><b>Total</b></td>
              <td className="td-black"><b>{util.currencyFormat(totalCAD)}</b></td>
              <td className="td-black"><b>{util.currencyFormat(totalUSD)}</b></td>
              <td className="td-black"><b>{util.currencyFormat(totalKRW)}</b></td>
            </tr>}
          </>
        );
      })
    );
  };
  const keyName = (type) => {
    let name = '';
    switch(type[0]) {
      case 'C':
        name = 'COURIER';
        break;
      case 'E':
        name = 'eCOMMERCE';
        break;
      case 'F':
        name = 'FORWARDING';
        break;
      case 'M':
        name = 'MOVING';
        break;
      case 'V':
        name = 'VEHICLE';
        break;
      case 'U':
        name = 'UPS';
        break;
      case 'P':
        name = 'C.POST';
        break;
      default:
        name = type;
        break;
    }
    return name;
  };
  return (
    <>
    <Panel
      md={12}
      lg={6}
      xl={4}
      xs={12}
      title={'Gross Sales'}
      subhead=""
    >
      <div>
        <Table responsive className="table dashboard__occupancy-table">
          <thead>
          <tr style={{borderTop: 'none'}}>
            <th>Branch</th>
            <th>CAD</th>
            <th>USD</th>
            <th>KRW</th>
          </tr>
          </thead>
          <tbody>
          {renderList(branchList, 'branch')}
          </tbody>
        </Table>
      </div>
      <div style={{marginTop: '30px'}}>
        <Table responsive className="table dashboard__occupancy-table">
          <thead>
          <tr style={{borderTop: 'none'}}>
            <th>Service</th>
            <th>CAD</th>
            <th>USD</th>
            <th>KRW</th>
          </tr>
          </thead>
          <tbody>
          {renderList(serviceList, 'service')}
          </tbody>
        </Table>
      </div>
    </Panel>
    </>
  );
};

export default GrossSales;
