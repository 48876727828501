/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import MarkFormManager from './MarkFormManager';

const FN = {
  IT_NO: 'itNo',
  IT_DATE: 'itDate',
  IT_LOC: 'itLoc',
  GOODS_NOW: 'goodsNow',
  GOODS_VALUE: 'goodsValue',
  CCN: 'ccn',
  CCN_DATE: 'ccnDate',
  PREV_CCN: 'prevCcn',
  UCR_NO: 'ucrNo',
  MANIFEST_FROM: 'manFrom',
  TO_A: 'toA',
  MARK: 'mark',
  DESCRIPTION: 'description',
  IN_REMARK: 'inRemark',
  AUTH_REMARK: 'authRemark',
  PUB_REMARK: 'pubRemark',
};

const LB = {

};

class MarkAIHFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  constructor() {
    super({
      prefix: 'mark-AIH',
      fields: [
        {name: FN.IT_NO, serverName: FN.IT_NO, label: 'IT No.'},
        {name: FN.IT_DATE, serverName: FN.IT_DATE, label: 'Date'},
        {name: FN.IT_LOC, serverName: FN.IT_LOC, label: 'IT Issued Loc.'},
        {name: FN.GOODS_NOW, serverName: FN.GOODS_NOW, label: 'Goods Now at'},
        {name: FN.GOODS_VALUE, serverName: FN.GOODS_VALUE, label: 'Value of Goods'},
        {name: FN.CCN, serverName: FN.CCN, label: 'CCN'},
        {name: FN.CCN_DATE, serverName: FN.CCN_DATE, label: 'Date'},
        {name: FN.PREV_CCN, serverName: FN.PREV_CCN, label: 'Prev.CCN'},
        {name: FN.UCR_NO, serverName: FN.UCR_NO, label: 'UCR No.'},
        {name: FN.MANIFEST_FROM, serverName: FN.MANIFEST_FROM, label: 'Manifest From'},
        {name: FN.TO_A, serverName: FN.TO_A, label: 'To / A'},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: '', type: 'textarea'},
        {name: FN.IN_REMARK, serverName: FN.IN_REMARK, label: '', type: 'textarea'},
        {name: FN.AUTH_REMARK, serverName: FN.AUTH_REMARK, label: '', type: 'textarea'},
        {name: FN.PUB_REMARK, serverName: FN.PUB_REMARK, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          {_r(FN.IT_NO)}
          {_r(FN.IT_DATE)}
          {_r(FN.IT_LOC)}
          {_r(FN.GOODS_NOW)}
          {_r(FN.GOODS_VALUE)}
        </div>
        <div className={'flex'}>
          {_r(FN.CCN)}
          {_r(FN.CCN_DATE)}
          {_r(FN.PREV_CCN)}
          {_r(FN.MANIFEST_FROM)}
          {_r(FN.TO_A)}
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Description')}
            {_r(FN.DESCRIPTION)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Internal Remark')}
            {_r(FN.IN_REMARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Authority to Make Entry Remark')}
            {_r(FN.AUTH_REMARK)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('A/N Public Remark')}
            {_r(FN.PUB_REMARK)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const form = new MarkAIHFormManager();

export function useMarkAIHModal(onSave, blForm) {
  const [isInited, setIsInited] = React.useState();
  const [uname, setUname] = React.useState('');
  const title = !isInited ? `( EDITED by ${uname??''} )` : '';

  const modal = useSimpleEditModal({
    title: `AIH Mark & Desc. ${title}`,
    width: 1200,
    form,
    onSave,
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      setIsInited(blForm.getValue('isInited'));
      setUname(blForm.getValue('uname'));
    }
  }, [modal.isOpen]);

  return modal;
}

export default MarkAIHFormManager;
