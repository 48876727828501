/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import AccountBLRateEditFormManager from '../../forms/account/AccountBLRateEditFormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {apiAccount, util} from '../../services/service';
import AccountBLRateGridFormManager from '../../forms/account/AccountBLRateGridFormManager';

const editForm = new AccountBLRateEditFormManager();
const editGridForm = new AccountBLRateGridFormManager();

export default function(onSave) {
  const gridRef = React.useRef();
  const grid = useSimpleGrid({
    columns: [
      {field: 'id', hide: true},
      {field: 'atypeId', hide: true},
      {field: 'accountType', headerName: 'Account Type', flex: 1},
      {field: 'pc', headerName: 'P/C', width: 120},
      {field: 'unit', headerName: 'Unit', width: 120},
      {field: 'rate', headerName: 'Rate', width: 120, valueFormatter: util.currencyFormatter},
      {field: 'volume', headerName: 'Volume', width: 120, valueFormatter: util.currencyFormatter},
      {field: 'amount', headerName: 'Amount', flex: 1, valueFormatter: amountFormatter },
    ],
    height: 200,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: (action, data) => {
      if (action === 'delete') {
        const currentGrid = gridRef.current;
        const index = currentGrid.rows.findIndex(i => i['id'] === data['id']);
        const newRows = [...currentGrid.rows];
        newRows.splice(index, 1);
        currentGrid.setRows(newRows);
      }
    },
  });
  editGridForm.onSave = () => {
    const {id = grid.rows?.length ?? 0, atypeId, accountType, pc = 'CC', unit = 'CNTR', rate = 0, volume = 1} = editGridForm.getValues();
    if(!atypeId || !accountType) {
      util.showWarning('Please enter a valid Account Type.');
      return;
    }
    if(isNaN(rate)) {
      util.showWarning('Please enter a valid Rate.');
      return;
    }
    if(!volume || isNaN(volume)) {
      util.showWarning('Please enter a valid volume.');
      return;
    }
    grid.setRows([...(grid.rows ?? []), {id, atypeId, accountType, pc, unit, rate, volume}]);
    editGridForm.clearValuesFast();
  };
  const modal = useSimpleEditModal({
    title: 'B/L RATE',
    centered: true,
    width: 900,
    form: editForm,
    onSave,
    children: (
      <>
        {grid.render()}
        {editGridForm.renderForm()}
      </>
    ),
  });
  gridRef.current = grid;
  React.useEffect(() => {
    if (modal.isOpen) {
      if (modal.data?.id > 0) {
        apiAccount.blRateData(modal.data.id).then(res => {
          const {data} = res;
          if (data && data.length > 0) {
            grid.setRows(data[0]['jitems']);
          }
        });
      } else {
        grid.setRows([]);
      }
    }
  }, [modal.isOpen]);
  return {grid, ...modal};
}

function amountFormatter(p) {
  let amount = p.data['rate'] * p.data['volume'];
  if (isNaN(amount))
    return (0).toFixed(2);
  else
    return (p.data['rate'] * p.data['volume']).toFixed(2);
}
