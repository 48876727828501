/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {api, apiBL} from '../../../services/service';
import {getBLEntryData, PRINT_TITLE} from '../../field-defs/bl';
import BLFormManager from '../../BLFormManager';
import FormManager from '../../../lib/FormManager';
import {useUser} from '../../../redux/reducers/userReducer';

export function usePrintModal(isMaster, title, width, blForm, printForm, PrintComponent, onOpen, onLoadPrintData, optionModalWidth = undefined, loadContainerData = true, onSave, values = undefined) {
  const onPrint = (preview) => {
    previewModal.open(blForm, printForm, preview, title, values);
  };
  let buttons = [
    {label: 'Print', onClick: () => onPrint(false) },
    {label: 'Preview', onClick: () => onPrint(true) },
  ];
  if (title === PRINT_TITLE.BOOKING_CONFIRM) {
    buttons = [
      {label: 'Save', onClick: () => onSave(printForm) },
      {label: 'Print', onClick: () => onPrint(false) },
      {label: 'Preview', onClick: () => onPrint(true) },
    ];
  }
  const previewModal = usePrintPreviewModal(isMaster, title, width, PrintComponent, onLoadPrintData, loadContainerData);
  const modal = useSimpleModal({
    width: optionModalWidth,
    title,
    children: printForm.renderForm(),
    buttons: buttons,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      onOpen && onOpen();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    render: () => {
      return (
        <>
          {modal.render()}
          {previewModal.render()}
        </>
      );
    },
  };
}

function usePrintPreviewModal(isMaster, title, width, PrintComponent, onLoadPrintData, loadContainerData = true) {
  const [printData, setPrintData] = React.useState();
  const [docTitle, setDocTitle] = React.useState();
  const printRef = React.useRef();
  const blFormRef = React.useRef();
  const printFormRef = React.useRef();
  const previewRef = React.useRef();
  const blValues = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: docTitle,
  });
  const user = useUser();
  const modal = useSimpleModal({
    title,
    width,
    children: <PrintComponent ref={printRef} printData={printData} user={user} />,
  });
  const loadPrintData = async () => {
    const blForm = blFormRef.current;
    const printForm = printFormRef.current;
    const values = blValues.current ? blValues.current : blForm.getValues();
    const blData = getBLEntryData(isMaster, values);
    const printFormData = printForm.getValues();
    const {data: markData} = await api.forwardViewBLMark(blData.id);
    if (loadContainerData === true) {
      const {data: containerData} = await apiBL.getContainers(blData.id);
      blData.containers = containerData ?? [];
    }
    const additionalData = onLoadPrintData ? await onLoadPrintData(blData, printFormData) : {};
    blData.jmark = markData[0]['jmark'] ?? {};
    for (const [key, value] of Object.entries(additionalData)) {
      blData[key] = value;
    }
    setPrintData({blData, printFormData});
  };
  React.useEffect(() => {
    if (modal.isOpen === true) {
      loadPrintData().then(() => {
        if (previewRef.current === false) {
          setTimeout(() => {
            print();
          }, 0);
        }
      });
    } else {
      setPrintData(undefined);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (blForm: BLFormManager, printForm: FormManager, preview: boolean, title, values) => {
      blFormRef.current = blForm;
      printFormRef.current = printForm;
      previewRef.current = preview;
      blValues.current = values;
      let dTitle = '';
      switch(title) {
        case 'ARRIVAL NOTICE':
          dTitle = printForm.getValue('title');
          break;
        case 'B/L PRINT':
          dTitle = blForm.getValue('hblNo') ?? blForm.getValue('hawbNo');
          break;
        case 'BOOKING CONFIRM':
          dTitle = `Booking confirmation - ${blForm.getValue('hblNo')}`;
          break;
      }
      setDocTitle(dTitle);
      modal.open();
    },
    print
  };
}
