/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";
import {util} from "../../services/service";
import {StaffSearchInput} from '../../components/SearchInput';

class StaffHolidayEditFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'holiday',
      fields: [
        {name: 'id', serverName: 'id', required: false, field: 'id', hide: true},
        {name: 'uid', serverName: 'uid', required: true, field: 'uid', hide: true},
        {name: 'uname2', serverName: 'uname2', headerName: 'Name', required: false, field: 'uname2', flex: 1},
        {name: 'startDate', serverName: 'start_date', headerName: 'Start Date', type: 'datePicker', required: true, field: 'start_date', valueFormatter: util.dateFormatterString, flex: 1},
        {name: 'endDate', serverName: 'end_date', headerName: 'End Date', type: 'datePicker', required: true, field: 'end_date', valueFormatter: util.dateFormatterString, flex: 1},
        {name: 'totalDays', serverName: 'total_days', headerName: 'Total Days', required: true, field: 'total_days', flex: 1},
        {name: 'usedDays', serverName: 'used_days', headerName: 'Used Days', required: true, field: 'used_days', flex: 1},
        {name: 'remark', serverName: 'remark', headerName: 'Remark', type: 'textarea', required: false, field: 'remark', flex: 1},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
        useDeleteButton: true,
        useCopyButton: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const onOk = (values) => {
      _c('uid', values.id);
      _c('uname2', values.value);
    };
    const startDate = _v('startDate');
    const endDate = _v('endDate');

    React.useEffect(() => {
      if(startDate && !endDate) {
        _c('endDate', util.addDays(startDate, 365));
      }
    }, [startDate]);
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <StaffSearchInput className={'flex-1 mr-2'} displayField={'holiday-uname2'} idField={'id'} formik={formik} fields={fields} errors={errors} vertical onOK={onOk}/>
          <div className={'flex-1 ml-2'}><span /></div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('startDate')}</div>
          <div className={'flex-1 mr-2'}>{_r('endDate')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('totalDays')}</div>
          <div className={'flex-1 mr-2'}>{_r('usedDays')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('remark')}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    // for (const field of this.fields) {
    //   const name = field.name.replace(`${this.prefix}-`, '');
    //   if (field.required === true && !values[name]) {
    //     return util.showWarning(`Please fill all the required fields`);
    //   }
    // }
    return values;
  };
}

export default StaffHolidayEditFormManager;
