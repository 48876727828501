/**
 * @flow
 */
import React from "react";
import {apiAccount, util} from "../../services/service";
import {useDataGridView} from "../../components/DataGridView";
import {INVOICE} from "../../forms/field-defs/account";

export default function useAccountListARPaidGridView(onActionCb, onGridReady, onQueryData) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      // return apiAccount.accountARPaidList(gridInfo);
      const {data, totalCount} = await apiAccount.accountARPaidList(gridInfo);
      let tAmount = 0
      data.forEach(value => {
        tAmount += value.amount;
      })
      await onQueryData(tAmount);
      return {
        totalCount,
        data: data.map(r => ({
          ...r, inv_date: util.formatD(r.inv_date),
          pay_date: util.formatD(r.pay_date),
          cdate: util.formatD(r.cdate),
          udate: util.formatD(r.udate),
        })),
      };
    },
    onCellValueChange(e) {},
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
      }
    },
    name: `ListAccountARPaid`,
    label: 'AR Paid List All for Account',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'List AR Paid'},
    sortCol: 'pay_date',
    sortDesc: true,
    pageRows: 20,
    actions: [],
    actionWidth: 0,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    // doNotPerformInitialQuery: true,
    initialSearchCurrentYear: true,
    onGridReady,
    columns: [
      {field: 'branch_name', headerName: 'Branch', width: 140},
      {field: 'acc_type', headerName: 'Type', width: 100},
      {field: 'pay_date', headerName: 'Pay Date', width: 100},
      {field: 'inv_no', headerName: 'Inv No.', cellRendererFramework: accountLinkFormatter, width: 140},
      {field: 'bill_to', headerName: 'Bill To', flex: 1, minWidth: 140},
      {field: 'inv_date', headerName: 'Inv. Date', width: 100},
      {field: 'currency', headerName: 'Currency', width: 50},
      {field: 'rate', headerName: 'Rate', width: 50},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.twoDecimalPlaceFormatter, width: 50},
      {field: 'bank_info', headerName: 'Bank Info.', width: 140},
      {field: 'pay_type', headerName: 'P.Type', width: 50},
      {field: 'remark', headerName: 'Remark', flex: 1},
      {field: 'cdate', headerName: 'Created', width: 100},
      {field: 'created_by', headerName: 'Create by', width: 100},
      {field: 'udate', headerName: 'Updated', width: 100},
      {field: 'edited_by', headerName: 'Edited by', width: 100},
    ],
  });
}

function accountLinkFormatter(param) {
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        util.openTab(`/admin/account/list/${param.data.acc_type.toLowerCase()}?id=${param.data['account_id']}`);
      }}>
        {param.value}
      </a>
    </div>
  );
}
