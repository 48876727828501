/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import coshipLogo from "../media/coship_logo.png";
import {useUser} from "../redux/reducers/userReducer";

class InvoiceDCNotePrint extends React.Component {
  totalBalance;
  totalDebit;
  totalCredit;
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountData, accountGridData, bankInfoData} = printData;
    const title = this.totalBalance >= 0 ? 'DEBIT' : 'CREDIT';
    const arrDCNoteDetailData = util.chunk(accountGridData, 20)??[{value: ''}];
    const arrLength = arrDCNoteDetailData.length;
    this.totalBalance = 0;
    this.totalDebit = 0;
    this.totalCredit = 0;
    accountGridData.forEach((account) => {
      if(account.amount > 0) this.totalDebit += account.amount;
      if(account.amount < 0) this.totalCredit += account.amount;
      this.totalBalance += account.amount;
    });
    return (
      <div style={{color: 'black'}}>
        {arrLength === 0 && this.renderPrint([], accountData, user, title)}
        {arrDCNoteDetailData.map((items, index) => {
          return (
            this.renderPrint(items, accountData, user, title, arrLength, index, bankInfoData)
          );
        })}
      </div>
    );
  }

  renderPrint(items, accountData, user, title, arrLength, index, bankInfoData) {
    return (
      <div className={'page-break'}>
        {this.renderHeader(accountData, user, title)}
        <table className={'print-table mt-40'} style={{width: '100%', height: '750px'}}>
          <tbody>
          <tr style={{height: 25}}>
            <td className={'no-border-lr invoice_label left fb-12'} width={'10%'}>DATE</td>
            <td className={'no-border-lr invoice_label left fb-12'} width={'15%'}>B/L NO.</td>
            <td className={'no-border-lr invoice_label left fb-12'} width={'10%'}>REF. NO.</td>
            <td className={'no-border-lr invoice_label left fb-12'} width={'30%'}>CUSTOMER</td>
            <td className={'no-border-lr invoice_label left fb-12'} width={'5%'}>CUR</td>
            <td className={'no-border-lr invoice_label right fb-12'} width={'10%'}>DEBIT</td>
            <td className={'no-border-lr invoice_label right fb-12'} width={'10%'}>CREDIT</td>
            <td className={'no-border-lr invoice_label right fb-12'} width={'10%'}>BALANCE</td>
          </tr>
          {this.renderInvoiceItems(items)}
          {this.renderEmptyItems(items, accountData, arrLength, index)}
          </tbody>
        </table>
        <hr />
        {arrLength-1 !== index &&
          (<table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td colSpan={8} className={'no-border-all center'}><b>CONTINUED</b></td>
            </tr>
            </tbody>
          </table>)
        }
        {arrLength-1 === index &&
          (<><table className={'print-table mt-20'} style={{width: '100%'}}>
              <tbody>
              {this.renderInvoiceItemsTotal('CURRENCY', 'TOTAL DEBIT', 'TOTAL CREDIT', 'TOTAL BALANCE')}
              {this.renderInvoiceItemsTotal(items[0].currency, this.totalDebit, this.totalCredit, this.totalBalance)}
              </tbody>
            </table>
            <table className={'print-table mt-20'} style={{width: '100%'}}>
              <tbody>
              {this.renderInvoiceExtraInfo('MEMO', accountData.memo)}
              {this.renderInvoiceExtraInfo('BANK INFO', bankInfoData?.memo)}
              </tbody>
            </table>
            <hr className={'line'} /></>)
        }
      </div>
    );
  }

  renderHeader(accountData, user, title) {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%', height: '100px'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr>
                <td colSpan={2} className={'no-border-all right invoice_label'}><h1>{title} NOTE</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_left'} width={'30%'}>
                  <div className={'invoice_label'} style={{fontSize: '22px', fontWeight: 'bold'}}>Note No. :</div>
                </td>
                <td className={'no-border-all bottom_right'} width={'70%'}>
                  <div className={'invoice_label'} style={{fontSize: '22px', fontWeight: 'bold'}}>{accountData.invoiceNo}</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '30px', height: '80px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>BILL TO :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{accountData.billTo}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2 align-self-center'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'invoice_label left fb-12'}>PERIOD</td>
                <td>{accountData.period}</td>
              </tr>
              <tr>
                <td className={'invoice_label left fb-12'}>PRINT DATE</td>
                <td>{util.getCurrentDate()}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceItems(items) {
    return items?.map((item, index) => {
      const amount = item['amount'];
      const debit = amount > 0 ? amount : 0;
      const credit = amount < 0 ? amount : 0;
      return (
        <tr key={index} style={{height: 20}}>
          <td className={'no-border-all'} style={{paddingTop: '10px'}} width={'10%'}>{util.formatD(item['cdate'])}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px'}} width={'15%'}>{item['bl_no']}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px'}} width={'10%'}>{item['ref_no']}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px'}} width={'30%'}>{util.textSubstring(item['customer_name'] ?? '', 23)}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px'}} width={'5%'}>{item['currency']}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px', textAlign: 'right'}} width={'10%'}>{util.formatCurrency(debit)}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px', textAlign: 'right'}} width={'10%'}>{util.formatCurrency(credit)}</td>
          <td className={'no-border-all'} style={{paddingTop: '10px', textAlign: 'right'}} width={'10%'}>{util.formatCurrency(amount)}</td>
        </tr>
      );
    });
  }
  renderEmptyItems(items, accountData, arrLength, index) {
    const empty = [];
    const extra = arrLength-1 === index ? 3 : 0;
    let length = 20 - items.length - extra;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i} style={{height: 20}}>
          <td colSpan={8} className={'no-border-all'} style={{paddingTop: '10px'}}>{'\u00a0'}</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceItemsTotal(value1, value2, value3, value4) {
    return (
      <tr>
        <td className={'no-border-all right invoice_label'} width={'55%'}>{value1}</td>
        <td className={'no-border-all right invoice_label'} width={'15%'}>{util.formatCurrency(value2)}</td>
        <td className={'no-border-all right invoice_label'} width={'15%'}>{util.formatCurrency(value3)}</td>
        <td className={'no-border-all right invoice_label'} width={'15%'}>{util.formatCurrency(value4)}</td>
      </tr>
    );
  }
  renderInvoiceExtraInfo(label, value) {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-line', minHeight: '150px'}}>{value}</div></td>
      </tr>
    );
  }
  renderCustomerDetailItems(arrItems) {
    return arrItems.map((items) => {
      return (
        <div className={'page-break'} style={{marginTop: '2cm'}}>
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'}>DATE</td>
              <td className={'no-border-lr invoice_label'}>B/L NO.</td>
              <td className={'no-border-lr invoice_label'}>CURRENCY</td>
              <td className={'no-border-lr right invoice_label'}>DEBIT</td>
              <td className={'no-border-lr right invoice_label'}>CREDIT</td>
            </tr>
            {items?.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={'no-border-lr'}>{util.formatD(item.inv_date)}</td>
                  <td className={'no-border-lr'}>{item.bl_no}</td>
                  <td className={'no-border-lr'}>{item.currency}</td>
                  <td className={'no-border-lr right'}>{util.formatCurrency(item.debit)}</td>
                  <td className={'no-border-lr right'}>{util.formatCurrency(item.credit)}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      );
    });
  }
}

export function useInvoiceDCNotePreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountFormRef = React.useRef();
  const accountSearchFormRef = React.useRef();
  const accountInvoiceAddItemRef = React.useRef();
  const accountDCNoteDataDetailRef = React.useRef();
  const bankInfoRef = React.useRef();
  const printRef = React.useRef();
  const invoiceNoRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: invoiceNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceDCNotePrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountFormRef.current.getValues(),
        accountSearchData: accountSearchFormRef.current.getValues(),
        accountInvoiceAddItemData: accountInvoiceAddItemRef.current.getValues(),
        accountGridData: accountInvoiceAddItemRef.current.getGridData(),
        accountDCNoteDataDetailData: accountDCNoteDataDetailRef.current,
        bankInfoData: bankInfoRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (accountForm, accountSearchForm, accountInvoiceAddItem, accountDCNoteDataDetail, bankInfo) => {
      accountFormRef.current = accountForm;
      accountSearchFormRef.current = accountSearchForm;
      accountInvoiceAddItemRef.current = accountInvoiceAddItem;
      accountDCNoteDataDetailRef.current = accountDCNoteDataDetail;
      invoiceNoRef.current = accountForm.getValue('invoiceNo');
      bankInfoRef.current = bankInfo;
      modal.open();
    },
    print,
  };
}

export default InvoiceDCNotePrint;
