/**
 * @flow
 */
import React from 'react';
import BLFormManager from '../../forms/BLFormManager';
import BLItemFormManager from '../../forms/bl/BLItemFormManager';
import BLBoxFormManager from '../../forms/bl/BLBoxFormManager';
import {useCUDGridModal, useSimpleGridModal, useSimpleModal} from '../../components/SimpleModal';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {apiBL, util} from '../../services/service';
import {FN} from '../../forms/field-defs/bl';
import {DefaultButton} from '../../components/buttons';

const blItemForm = new BLItemFormManager();
const blBoxForm = new BLBoxFormManager();

export default function useBLBoxModal(blForm: BLFormManager) {
  const [currentBoxNo, setCurrentBoxNo] = React.useState();
  const selectModal = useItemsSelectModal(blForm);
  async function onAddBox() {
    const values = blBoxForm.getValues();
    const blId = blForm.getValue(FN.ID);
    const {boxNo} = values;
    if (!boxNo) {
      util.showWarning('Please enter box no.');
      return;
    }
    const res = await apiBL.setBLBox({...values, blId});
    if (res) {
      await loadBoxList(blId, boxGrid);
      blBoxForm.clearValues();
    }
  }
  function onAddBoxItem(data) { // 액션(addChild) 버튼을 눌렀을때 아이템의 전체 목록을 보여주는 모달을 열어줌!
    selectModal.open(data);
    setCurrentBoxNo(data['box_no']);
  }
  async function onDeleteBox(data) {
    const blId = blForm.getValue(FN.ID);
    const {box_no: boxNo} = data;
    const res = await apiBL.delBLBox(blId, boxNo);
    if (res) {
      await loadBoxList(blId, boxGrid);
      itemGrid.setRows([]);
      setCurrentBoxNo(undefined);
    }
  }
  function onSelectBox(data) {
    blBoxForm.setValues(data);
    setCurrentBoxNo(data['box_no']);
  }
  async function onAddItem() {
    if (!currentBoxNo) {
      util.showWarning('Please select box!');
      return;
    }
    const values = blItemForm.getValues();
    const blId = blForm.getValue(FN.ID);
    const errorMessage = validationItems(values);
    if(errorMessage) {
      return util.showWarning(errorMessage);
    }
    const res = await apiBL.setBLBoxItem({...values, blId, boxNo: currentBoxNo});
    if (res) {
      await loadBoxItemList(blId, currentBoxNo, itemGrid);
      blItemForm.clearValues();
    }
  }
  async function onDeleteItem(data) {
    const blId = blForm.getValue(FN.ID);
    const {item_no: itemNo} = data;
    const res = await apiBL.delBLBoxItem(blId, currentBoxNo, itemNo);
    if (res) {
      await loadBoxItemList(blId, currentBoxNo, itemGrid);
    }
  }
  function onSelectItem(data) {
    blItemForm.setValues(data);
  }
  const boxGrid = useBoxGrid(blForm, onAddBoxItem, onDeleteBox, onSelectBox);
  const itemGrid = useItemGrid(blForm, onDeleteItem, onSelectItem);
  function renderChildren() {
    return (
      <div className={'flex'}>
        <div className={'mr-20 flex-column'} style={{flex: 1.3}}>
          <span>CTNS</span>
          {boxGrid.render()}
          {blBoxForm.renderForm()}
          <div className={'w-full flex center'}>
            <DefaultButton label={'Save CTN'} onClick={onAddBox} noMargin />
          </div>
        </div>
        <div className={'flex-1 flex-column'}>
          <span>Item List {currentBoxNo ? `(Box #${currentBoxNo})` : ''}</span>
          {itemGrid.render()}
          {blItemForm.renderForm()}
          <div className={'w-full flex center'}>
            <DefaultButton label={'Save Item'} onClick={onAddItem} noMargin />
          </div>
        </div>
        {selectModal.render()}
      </div>
    );
  }
  const modal = useSimpleModal({
    title: 'MANAGE CTNS',
    width: 1400,
    children: renderChildren(),
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const blId = blForm.getValue(FN.ID);
      if (blId) {
        loadBoxList(blId, boxGrid).catch();
      }
    } else if (modal.isOpen === false) {
      setCurrentBoxNo(undefined);
      boxGrid.setRows([]);
      itemGrid.setRows([]);
      blItemForm.totalQty = 0;
      blItemForm.totalPrice = 0;
      blItemForm2.totalQty = 0;
      blItemForm2.totalPrice = 0;
    }
  }, [modal.isOpen]);
  React.useEffect(() => {
    if (currentBoxNo) {
      const blId = blForm.getValue(FN.ID);
      loadBoxItemList(blId, currentBoxNo, itemGrid).catch();
      blItemForm.clearValues();
    }
  }, [currentBoxNo]);
  React.useEffect(() => {
    if (selectModal.isOpen === false) {
      const blId = blForm.getValue(FN.ID);
      loadBoxItemList(blId, currentBoxNo, itemGrid).catch();
      blItemForm.clearValues();
    }
  }, [selectModal.isOpen]);
  blItemForm.hideItemNo = true;
  // blItemForm.hideHsCode = true;

  const onSearch = async (value) => {
    if (value) {
      const res = await apiBL.getHSCode(value);
      blItemForm.setValue('hsCode', res.data[0].retval);
    }
  };
  blItemForm.onSearch = onSearch;

  return {
    ...modal,
    open: (boxData) => {
      if (boxData) {
        setCurrentBoxNo(boxData['box_no']);
      }
      modal.open();
    },
  };
}

export async function loadBoxList(blId, grid) {
  const {data} = await apiBL.getBLBoxList(blId);
  if (grid.hasOwnProperty('current')) {
    grid.current.setRows(data);
  } else {
    grid.setRows(data);
  }
  return data;
}

export async function loadHouseBoxList(blId, filingNo, grid) {
  const {data} = await apiBL.setMasterBLBox(blId, filingNo);
  if (grid.hasOwnProperty('current')) {
    grid.current.setRows(data);
  } else {
    grid.setRows(data);
  }
  return data;
}

export async function loadBoxItemList(blId, boxNo, grid) {
  const {data} = await apiBL.getBLBoxItemList(blId, boxNo);
  totalPriceQty(data, blItemForm);
  if (grid) {
    grid.setRows(data);
  } else {
    return data;
  }
}

let boxGridCallbacks = {};
function useBoxGrid(blForm: BLFormManager, onAddChild, onDelete, onSelectRow) {
  boxGridCallbacks = {
    onAddChild, onDelete, onSelectRow
  };
  return useSimpleGrid({
    columns: [
      {field: 'box_no', headerName: 'No.', width: 50, minWidth: 50},
      {field: 'length', headerName: 'Length', valueFormatter: util.formatWeight, width: 85, minWidth: 85},
      {field: 'width', headerName: 'Width', valueFormatter: util.formatWeight, width: 85, minWidth: 85},
      {field: 'height', headerName: 'Height', valueFormatter: util.formatWeight, width: 85, minWidth: 85},
      {field: 'pcs', headerName: 'PCS', width: 85, minWidth: 85},
      {field: 'cal_weight', headerName: 'V.Weight', valueFormatter: util.formatWeight, flex: 1, minWidth: 85},
      {field: 'box_cbm', headerName: 'CBM', valueFormatter: util.formatCBM, flex: 1, minWidth: 85},
    ],
    height: 450,
    actions: ['addChild', 'delete'],
    actionWidth: 80,
    onAction: (action, data) => {
      if (action === 'delete') {
        util.showConfirm('Are you sure to delete?', () => {
          boxGridCallbacks.onDelete(data);
        });
      } else if (action === 'addChild') {
        boxGridCallbacks.onAddChild(data);
      }
    },
    agGridProps: {
      rowSelection: 'single',
      onCellClicked(e) {
        if (e.colDef.headerName !== 'Actions') {
          boxGridCallbacks.onSelectRow(e.data);
        }
      }
    }
  });
}

let itemGridCallbacks = {};
function useItemGrid(blForm: BLFormManager, onDelete, onSelectRow) {
  itemGridCallbacks = {
    onDelete, onSelectRow,
  };
  return useSimpleGrid({
    columns: [
      {field: 'item_no', headerName: 'No.', width: 50, minWidth: 50},
      {field: 'item_title', headerName: 'Item', flex: 1},
      {field: 'item_qty', headerName: 'QTY', width: 90, minWidth: 90},
      {field: 'unit_price', headerName: 'Unit Value', valueFormatter: util.currencyFormatter, width: 90, minWidth: 90},
      {field: 'totalValue', headerName: 'Total Value', valueFormatter: totalPriceFormatter, width: 90, minWidth: 90},
      {field: 'hs_code', headerName: 'HS Code', width: 90, minWidth: 90},
    ],
    height: 450,
    actions: ['delete'],
    actionWidth: 70,
    onAction: (action, data) => {
      if (action === 'delete') {
        util.showConfirm('Are you sure to delete?', () => {
          itemGridCallbacks.onDelete(data);
        });
      }
    },
    agGridProps: {
      rowSelection: 'single',
      onCellClicked(e) {
        if (e.colDef.headerName !== 'Actions') {
          itemGridCallbacks.onSelectRow(e.data);
        }
      }
    }
  });
}

function totalPriceFormatter(p) {
  let {item_qty: itemQty, unit_price: unitPrice} = p.data;
  itemQty = parseInt(itemQty);
  unitPrice = parseFloat(unitPrice);
  if (itemQty && unitPrice) {
    return util.formatCurrency(itemQty * unitPrice);
  } else {
    return '';
  }
}

function totalPriceQty(data, itemForm) {
  let totalQty = 0;
  let totalPrice = 0;
  if(data) {
    for(let i=0; i<data.length; i++) {
      totalQty += data[i].item_qty;
      totalPrice += data[i].item_qty * data[i].unit_price;
    }
  }
  itemForm.totalQty = totalQty;
  itemForm.totalPrice = totalPrice;
}

const blItemForm2 = new BLItemFormManager();
export function useBLItemsModal(blForm: BLFormManager) {
  const gridRef = React.useRef();
  const modal = useCUDGridModal({
    title: 'MANAGE ITEMS',
    width: 660,
    centered: true,
    gridProps: {
      columns: [
        {field: 'box_no', headerName: 'Box No.', width: 70, minWidth: 70},
        {field: 'item_no', headerName: 'No.', width: 50, minWidth: 50},
        {field: 'item_title', headerName: 'Item', flex: 1},
        {field: 'item_qty', headerName: 'QTY', width: 70, minWidth: 70},
        {field: 'unit_price', headerName: 'Unit Value', valueFormatter: util.currencyFormatter, width: 80, minWidth: 80},
        {field: 'totalValue', headerName: 'Total Value', valueFormatter: totalPriceFormatter, width: 90, minWidth: 90},
        {field: 'hs_code', headerName: 'HS Code', width: 90, minWidth: 90},
      ],
      height: 450,
    }
  }, blItemForm2,async (queryData) => {
    const blId = blForm.getValue(FN.ID);
    if (blId) {
      const {data} = await apiBL.getBLBoxItemList(blId, 0);
      return data;
    } else {
      return Promise.resolve([]);
    }
  }, async (mode, data) => {
    const blId = blForm.getValue(FN.ID);
    if (mode === 'add' || mode === 'edit') {
      const {
        requestDate, receiveDate, trackingNo, payMethod, totalPrice, currency, cardInfo, remark, ...values
      } = data;
      const jcommerce = {
        requestDate, receiveDate, trackingNo, payMethod, totalPrice, currency, cardInfo, remark,
      };
      const errorMessage = validationItems(data);
      if(errorMessage) {
        return util.showWarning(errorMessage);
      }
      const res = await apiBL.setBLBoxItem({...values, blId, jcommerce});
      if (res) {
        const {data} = await apiBL.getBLBoxItemList(blId, 0);
        gridRef.current.setRows(data);
        blItemForm2.clearValues();
      }
    } else if (mode === 'delete') {
      const res = await apiBL.delBLBoxItem(blId, data['box_no'], data['item_no']);
      if (res) {
        const {data} = await apiBL.getBLBoxItemList(blId, 0);
        gridRef.current.setRows(data);
        blItemForm2.clearValues();
      }
    }
  }, false);
  totalPriceQty(modal.grid.rows, blItemForm2);
  const onSearch = async (value) => {
    if (value) {
      const res = await apiBL.getHSCode(value);
      blItemForm2.setValue('hsCode', res.data[0].retval);
    }
  };
  gridRef.current = modal.grid;
  blItemForm2.kind = blForm.getValue(FN.KIND);
  blItemForm2.onSearch = onSearch;

  return modal;
}

function useItemsSelectModal(blForm: BLFormManager) {
  const [boxData, setBoxData] = React.useState();
  const gridRef = React.useRef();
  async function handleSelect() {
    // 사용자가 선택한 아이템 목록을 가져옴
    const selectedItems = modal.grid.apiRef.current.getSelectedRows();
    if (selectedItems.length <= 0) {
      return;
    }
    const items = selectedItems.map(i => i['item_no']);
    const blId = blForm.getValue(FN.ID);
    const res = await apiBL.setBLBoxItemBulk(blId, boxData['box_no'], items);
    if (res) {
      modal.close();
    }
  }
  const modal = useSimpleGridModal({
    title: 'SELECT ITEMS',
    width: 640,
    centered: true,
    buttons: [
      {label: 'Add', onClick: handleSelect},
    ],
    gridProps: {
      columns: [
        {checkboxSelection: true, headerCheckboxSelection: true, width: 50, minWidth: 50},
        {field: 'item_no', headerName: 'No.', width: 50, minWidth: 50},
        {field: 'item_title', headerName: 'Item', flex: 1},
        {field: 'item_qty', headerName: 'QTY', width: 70, minWidth: 90},
        {field: 'unit_price', headerName: 'Unit Value', valueFormatter: util.currencyFormatter, width: 90, minWidth: 90},
        {field: 'totalValue', headerName: 'Total Value', valueFormatter: totalPriceFormatter, width: 90, minWidth: 90},
      ],
      height: 450,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
      },
    }
  });
  gridRef.current = modal.grid;
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const blId = blForm.getValue(FN.ID);
      loadBoxItemList(blId, 0).then((data) => {
        const rows = data.filter(i => i['box_no'] === 0);
        modal.grid.setRows(rows);
      });
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (boxData) => {
      setBoxData(boxData);
      modal.open();
    },
  };
}

function validationItems(data) {
  if(!data.itemTitle) {
    return 'Please enter Title!';
  }
  if(!data.itemQty) {
    return 'Please enter QTY!';
  }
  if(!data.unitPrice) {
    return 'Please enter Unit Price!';
  }
  return null;
}
