import React from 'react';
import {useSelector} from 'react-redux';
import useInterval from 'react-useinterval';
import {api, util} from '../../services/service';

export const NOTI_UPDATE = 'NOTI_UPDATE';
export const NOTI_RESET = 'NOTI_RESET';

export function updateNoti(dispatch, count) {
  dispatch({type: NOTI_UPDATE, count});
}

export function resetNoti(dispatch) {
  dispatch({type: NOTI_RESET});
}

export interface NotiState {
  count: number;
}

export function useNoti(): NotiState {
  const noti = useSelector(state => state['noti']);
  useInterval(() => {
    api.getNotiCount().then(res => updateNoti(util.dispatch, res?.data?.['noti_count'] ?? 0));
  }, 5 * 60 * 1000);
  React.useEffect(() => {
    api.getNotiCount().then(res => updateNoti(util.dispatch, res?.data?.['noti_count'] ?? 0));
  }, []);
  return noti;
}

export default function notiReducer(state: NotiState = {}, action) {
  const {type, count} = action;
  switch (type) {
    case NOTI_UPDATE:
      return {count};
    case NOTI_RESET:
      return {};
    default:
      return state;
  }
}
