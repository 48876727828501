/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {ModalState} from '../../components/DataGridView';
import type {FormField} from '../../components/Form';
import CommissionFormManager from '../../forms/settings/CommissionFormManager';
import {useSimpleEditModal} from '../../components/SimpleModal';
import {pickupTimeOptions} from '../../forms/helper';
import type {CustomerType} from '../../services/API';

interface CustomerListProps {
  cType: CustomerType;
}

let cType; // NOTE: anti-pattern but very effective
let areaOptions = []; // NOTE: anti-pattern but very effective

const NAME = {B: 'businessCList', U: 'userCList', V: 'vendorCList', D: 'dropzoneCList'};
const LABEL = {B: 'Business', U: 'User', V: 'Vendor', D: 'Dropzone'};
const ADD_LABEL = {B: 'ADD BUSINESS', U: 'ADD USER', V: 'ADD VENDOR', D: 'ADD DROPZONE'};
const EDIT_LABEL = {B: 'EDIT BUSINESS', U: 'EDIT USER', V: 'EDIT VENDOR', D: 'EDIT DROPZONE'};
const MENU_LABEL = {B: 'Business', U: 'User', V: 'Vendor', D: 'Dropzone'};

const commissionForm = new CommissionFormManager();
let formik;

const CustomerList = (props: CustomerListProps) => {
  cType = props.cType;
  areaOptions = api.useAreaOptions(true);
  async function onAction(action, data) {
    switch (action) {
      case 'addOpen':
        formik.setFieldValue('pickupTime', 'AM');
        return;
      case 'editOpen':
        if (!formik.values['pickupTime']) {
          formik.setFieldValue('pickupTime', 'AM');
        }
        updatePickupDays(data);
        return;
      case 'add':
        if (cType === 'D') {
          const pickupDay = getPickupDays(data);
          return api.customerAdd({...data, cType, pickupDay});
        } else {
          return api.customerAdd({...data, cType});
        }
      case 'edit':
        if (cType === 'D') {
          const pickupDay = getPickupDays(data);
          return api.customerEdit({...data, cType, pickupDay});
        } else {
          return api.customerEdit({...data, cType});
        }
      case 'commission':
        const res = await api.customerCommission(data.id);
        const cData = res?.data?.[0];
        if (cData) {
          const {customer_id, remark, footer, jval: {air, ocean, ups, capost, desk}} = cData;
          modal.open('edit', {customer_id, remark, footer, air, ocean, ups, capost, desk});
        }
        return;
    }
  }
  async function onCommissionSave(mode, data) {
    if (mode === 'edit') {
      const {customerId, remark, air, ocean, ups, capost, desk} = data;
      const jval = {
        air: parseFloat(air),
        ocean: parseFloat(ocean),
        ups: parseFloat(ups),
        capost: parseFloat(capost),
        desk: parseFloat(desk),
      };
      await api.customerCommissionSave({customerId, remark, jval});
      modal.close();
    }
  }
  const grid = useDataGridView({
    onAction,
    onQuery: (gridInfo) => api.customerList({...gridInfo, cType}),
    renderForm: (formik, fields, errors) => renderForm(cType, formik, fields, errors),
    name: NAME[cType],
    label: LABEL[cType],
    sortCol: 'cname',
    sortDesc: false,
    pageRows: 20,
    modalWidth: 720,
    addLabel: ADD_LABEL[cType],
    editLabel: EDIT_LABEL[cType],
    categoryLabel: {label: 'Customer'},
    menuLabel: {label: MENU_LABEL[cType]},
    actions: cType === 'D' ? ['edit', 'commission'] : ['edit'],
    actionWidth: 70,
    showDateSearch: false,
    columns: getColumns,
    useExtendedColDef: true,
    onFormik: (fmk => (formik = fmk)),
  });
  const modal = useSimpleEditModal({
    title: 'COMMISSION',
    centered: true,
    width: 480,
    form: commissionForm,
    onSave: onCommissionSave,

  });
  return (
    <>
      {grid.render()}
      {modal.render()}
    </>
  )
};

function renderForm(cType, formik, fields, errors) {
  const _r = name => renderField(formik, name, fields, errors);
  return (
    <>
      <div className={'flex wrap'}>
        <div className={'flex-1 mr-2'}>
          {_r('customerName')}
          {_r('customerName2')}
          {_r('password')}
          {_r('cphone')}
          {_r('hphone')}
          {_r('email')}
        </div>
        <div className={'flex-1 ml-2'}>
          {_r('areaId')}
          {_r('address')}
          {_r('city')}
          {_r('postalCode')}
          {/*{_r('customsCode')}*/}
          {_r('rate')}
          {_r('isLogin')}
        </div>
      </div>
      {cType === 'D' && (
        <div className={'flex middle mb-20'} style={{position: 'relative', marginLeft: 120}}>
          <div style={{position: 'absolute', left: -120, textAlign: 'right', width: 120, paddingRight: 20}}>Pickup Days</div>
          {_r('sunCheck')}
          <span style={{marginRight: 20}}>SUN</span>
          {_r('monCheck')}
          <span style={{marginRight: 20}}>MON</span>
          {_r('tueCheck')}
          <span style={{marginRight: 20}}>THU</span>
          {_r('wedCheck')}
          <span style={{marginRight: 20}}>WED</span>
          {_r('thuCheck')}
          <span style={{marginRight: 20}}>THU</span>
          {_r('friCheck')}
          <span style={{marginRight: 20}}>FRI</span>
          {_r('satCheck')}
          <span style={{marginRight: 20}}>SAT</span>
        </div>
      )}
      {cType === 'D' && (
        <div className={'flex'}>
          <div className={'flex-1'}>{_r('pickupTime')}</div>
          <div className={'flex-1'} />
        </div>
      )}
      {_r('remark')}
    </>
  );
}

function getColumns(modal: ModalState): (ColDef | FormField)[] {
  return [
    { name: 'customerId', label: 'ID', required: false,
      field: 'id', hide: true},
    { name: 'areaId', label: 'Area', options: areaOptions, required: true,
      field: 'area_id', hide: true},
    { name: 'cType', label: 'Type', required: false,
      field: 'ctype', hide: true},
    { name: 'remark', label: 'Remark', required: false,
      field: 'remark', hide: true},
    { name: 'footer', required: false,
      field: 'footer', hide: true},
    { name: 'password', label: 'Password', required: modal.mode === 'add',
      field: 'password', hide: true},
    { name: 'cdate', required: false,
      field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 1},
    { name: 'customerName', required: true,
      field: 'cname', headerName: 'Eng.Name', flex: 1},
    { name: 'customerName2', required: false,
      field: 'cname2', headerName: 'Local Name', flex: 1},
    { name: 'email', required: false,
      field: 'email', headerName: 'Email', flex: 1},
    { name: 'cphone', mask: util.MASK_PHONE, required: true,
      field: 'cphone', headerName: 'Cell #', flex: 1},
    { name: 'hphone', mask: util.MASK_PHONE, required: false,
      field: 'hphone', headerName: 'Home #', flex: 1, hide: true},
    { name: 'area_name', required: false,
      field: 'area_name', headerName: 'Area', flex: 1},
    { name: 'address', required: true,
      field: 'address', headerName: 'Address', flex: 1, hide: true},
    { name: 'city', required: false,
      field: 'city', headerName: 'City', flex: 1},
    { name: 'postalCode', required: false,
      field: 'postal_code', headerName: 'Postal', flex: 1, hide: true},
    { name: 'customsCode', required: false,
      field: 'customs_code', headerName: 'Customs #', flex: 1, hide: true},
    { name: 'rate', required: true,
      field: 'rate', headerName: 'Discount Rate', flex: 0.5},
    { name: 'isLogin', type: 'checkbox', required: false,
      field: 'islogin', headerName: 'Login', valueFormatter: util.yesNoFormatter, flex: 0.5, hide: true},
    { name: 'monCheck', type: 'checkbox',
      field: 'monCheck', headerName: '', hide: true},
    { name: 'tueCheck', type: 'checkbox',
      field: 'tueCheck', headerName: '', hide: true},
    { name: 'wedCheck', type: 'checkbox',
      field: 'wedCheck', headerName: '', hide: true},
    { name: 'thuCheck', type: 'checkbox',
      field: 'thuCheck', headerName: '', hide: true},
    { name: 'friCheck', type: 'checkbox',
      field: 'friCheck', headerName: '', hide: true},
    { name: 'satCheck', type: 'checkbox',
      field: 'satCheck', headerName: '', hide: true},
    { name: 'sunCheck', type: 'checkbox',
      field: 'sunCheck', headerName: '', hide: true},
    { name: 'pickupTime', options: pickupTimeOptions,
      field: 'pickup_time', headerName: 'Pickup Time', hide: true},
    { name: 'pickupDay',
      field: 'pickup_day', headerName: 'Pickup Day', hide: true},
  ];
}

const pickupDayInfo = {
  Mon: 'monCheck',
  Tue: 'tueCheck',
  Wed: 'wedCheck',
  Thu: 'thuCheck',
  Fri: 'friCheck',
  Sat: 'satCheck',
  Sun: 'sunCheck',
};

function updatePickupDays({pickupDay}) {
  if (pickupDay) {
    for (const day of pickupDay) {
      const fieldName = pickupDayInfo[day];
      formik.setFieldValue(fieldName, ['on']);
    }
  }
}

function getPickupDays(data) {
  const days = [];
  for (const [key, fieldName] of Object.entries(pickupDayInfo)) {
    if (data[fieldName] === true) {
      days.push(key);
    }
  }
  return days;
}

export default CustomerList;
