/**
 * @flow
 */
import React from 'react';
import {useSimpleGridModal} from '../../../components/SimpleModal';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {apiCourier} from "../../../services/newCoship/service";

export function useCourierCLPWaitingHousesModal(setReturnData) {
  const [totalCount, setTotalCount] = React.useState();
  const [pickupDate, setPickupDate] = React.useState(undefined);
  const [qryText, setQueryText] = React.useState(undefined);
  const [filingNo, setFilingNo] = React.useState(undefined);

  async function getHouseList() {
    const payload = {pickup_date: pickupDate, search_word: qryText};
    apiCourier.getCLPWaitingHouses(payload).then((res) => {
      if (res) {
        modal.setRows(res.data);
        setTotalCount(res.totalCount);
      }
    });
  }
  async function onAdd() {
    const selectedRows = modal.grid.apiRef.current.getSelectedRows();
    const selectedBls = selectedRows.map((x) => x.bl_id);
    const payload = {filing_no: filingNo, bl_ids: selectedBls};
    if (payload.filing_no && payload.bl_ids.length > 0) {
      apiCourier.setCLPHouses(payload).then((res) => {
        if (res) {
          setReturnData(res.data.houses);
          modal.close();
        }
      });
    }
  }
  const onEnter = async () => {
    if (qryText) {
      await getHouseList();
    }
  };
  const onChangePickupDate = (e) => {
    const value = e.target.value
    setPickupDate(value);
  };

  const modal = useSimpleGridModal({
    title: 'HOUSE LIST',
    titleComponent: () => (
      <>
        <div className={'card__title no-margin pt-20 pl-20'}>
          <h5 className="bold-text">SEARCH CLP WAITING LIST</h5>
        </div>
        <div className={'theme-light ltr-support flex between middle px-20'}>
          <div className={'mt-8'}>{totalCount === undefined ? '' : `${modal.grid?.rows?.length ?? 0} of ${totalCount}`}</div>
          <div className={'flex'}>
            <input type={'date'} onChange={onChangePickupDate}/>
            <div className={'inbox__emails-control-search flex middle ml-20'}>
              <input
                id={`house-search`}
                placeholder={'Search...'}
                value={qryText ?? ''}
                onChange={({target: {value}}) => setQueryText(value)}
                onKeyDown={({target: {value}, key}) => {
                  if (key === 'Enter') {
                    onEnter(value).catch();
                  }
                }}
                style={{width: 200}}
              />
              <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
                e.preventDefault();
                onEnter(document.querySelector('#house-search').value).catch();
              }}>
                <MagnifyIcon/>
              </a>
            </div>
          </div>
        </div>
      </>
    ),
    centered: true,
    width: 1020,
    height: 550,
    buttons: [{label: 'Add', onClick: onAdd, color: 'primary'}],
    gridProps: {
      columns: [
        {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
        {field: 'bl_id', hide: true},
        {field: 'wo_id', hide: true},
        {field: 'bl_no', headerName: 'BL No.', width: 100},
        {field: 'maple_type', headerName: 'Mpale Type', width: 100},
        {field: 'packages', headerName: 'Pkg.', sortable: true, minWidth: 60, width: 60},
        {field: 'pickup', headerName: 'Pick up', sortable: true, width: 100},
        {field: 'edate', headerName: 'E.Date', sortable: true, width: 100},
        {field: 'shipper', headerName: 'Shipper', flex: 1},
        {field: 'consignee', headerName: 'Consignee', flex: 1},
        {field: 'weight_kg', headerName: 'Weight', minWidth: 70, width: 70},
        {field: 'cbm', headerName: 'CBM', minWidth: 70, width: 70},
      ],
      actions: [],
      actionWidth: 0,
      onAction: (action, data) => {},
      rows: [],
      height: 500,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        onRowClicked(e) {}
      }
    },
    children: null,
  });

  React.useEffect(() => {
    if (modal.isOpen) {
      getHouseList().catch(console.error);
      document.querySelector('#house-search').focus();
    } else {
      setPickupDate(undefined);
      setQueryText(undefined);
    }
  }, [modal.isOpen, pickupDate]);

  return {
    ...modal,
    open: (fNo) => {
      setFilingNo(fNo);
      modal.open();
    },
  };
}
