/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';
import type {DataGridActionType} from '../../components/DataGrid';
import {MBLFilingNoSearchInput} from '../../components/SearchInput';
import {packageTypeOptions, tpSizeOptions} from '../../forms/field-defs/bl';
import {PrimaryButton} from '../../components/buttons';
import SearchPane from '../../components/SearchPane';
import CraftContainerSearchFormManager from '../../forms/craft/CraftContainerSearchFormManager';
import {queryGridData} from '../../components/DataGrid';
import useCraftContainerModal from '../../modals/useCraftContainerModal';
import {useContainerDetailPreviewModal} from "../../print-layouts/ContainerDetailPrint";
import {useUser} from "../../redux/reducers/userReducer";

const searchForm = new CraftContainerSearchFormManager();

let cachedGridData;

const ContainerList = () => {
  const user = useUser();
  const containerDetailPrintModal = useContainerDetailPreviewModal();
  const onPrint = (data, mode) => {
    getQuery(data).then((res) => {
      if (res.data) {
        containerDetailPrintModal.open(res.data[0], mode);
      }
    });
  }
  const getQuery = async (data) => {
    return await api.craftContainerItemList(data);
  }
  const modal = useCraftContainerModal(onPrint);
  React.useEffect(() => {
    const qs = util.getQS();
    const {filingNo} = qs;
    if (filingNo) {
      searchForm.setValue('searchName', 'FNO');
      searchForm.setValue('searchVal', filingNo);
      onSearch({searchName: 'FNO', searchVal: filingNo}).catch();
    } else {
      onSearch().catch();
    }
  }, []);
  async function onAction(action, data) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
    switch (action) {
      case 'add':
      case 'edit':
        const {id, containerNo, tpSize, filingNo, sealNo, packCount, packUnit, weightKg, cbm, oFrt, remark, pickNo, commodity} = data;
        return api.craftContainerSet({id, containerNo, tpSize, filingNo, sealNo, packCount, packUnit, weightKg, cbm, remark, pickNo, commodity, jextra: data});
      case 'delete':
        // if(!util.isValidPermitForAccount(user, 'edit')) {
        //   return util.showError('Sorry, you can not edit container Info.');
        // }
        return api.delContainer(data.id);
      case 'addChild':
        modal.open(data);
        return;
    }
  }
  async function onQuery(gridInfo) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
    // const {data: rows, totalCount} = await api.airVesselList(gridInfo);
    // return {data: rows.map(row => ({...row, db_craft_id: row.craft_id})), totalCount};
    // return api.craftContainerList(gridInfo);
    cachedGridData = gridInfo;
    const {data: rows, totalCount} = await api.craftContainerList(gridInfo);
    const rowsToUse = rows?.map(({jextra, ...others}) => {
      return {...jextra, ...others};
    });
    return {data: rowsToUse, totalCount};
  }
  const onSearch = async (additional) => {
    const searchData = searchForm.getValues();
    const gridInfo = {...cachedGridData, ...searchData, ...additional};
    await queryGridData(NAME, gridInfo, onQuery);
  };
  searchForm.onSearch = onSearch;
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'}>
        <DataGridView
          name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
          addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
          actions={ACTIONS} actionWidth={ACTION_WIDTH}
          actionTooltips={{addChild: 'Add House B/L'}}
          showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
          columns={COLUMNS}
          onAction={async (action, data) => onAction(action, data)}
          onQuery={async (gridInfo) => onQuery(gridInfo)}
          renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
          useExtendedColDef
          isActionDisabled={isActionDisabled}
          doNotPerformInitialQuery={true}
          hideSearchInput={true}
        />
      </div>
      {modal.render()}
      {containerDetailPrintModal.render()}
    </div>
  );
};

const yesNoOptions = [
  {value: '', label: ''},
  {value: 'Y', label: 'Y'},
  {value: 'N', label: 'N'},
];

const ventOptions = [
  {value: '', label: ''},
  {value: '0%', label: '0%'},
  {value: '25%', label: '25%'},
  {value: '50%', label: '50%'},
  {value: '75%', label: '75%'},
  {value: '100%', label: '100%'},
];

const airFlowUnitOptions = [
  {value: '', label: ''},
  {value: 'CBM', label: 'CBM'},
  {value: 'CFT', label: 'CFT'},
];

const dgOptions = [
  {value: 'N', label: 'N'},
  {value: 'Y', label: 'Y'},
];

const NAME = 'containerList';
const LABEL = 'Container';
const ADD_LABEL = 'ADD CONTAINER';
const EDIT_LABEL = 'EDIT CONTAINER';
const CATEGORY_LABEL = 'Craft';
const MENU_LABEL = 'Container';
const SORT_COL = 'id';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 800;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit', 'addChild', 'delete'];
const ACTION_WIDTH = 100;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'id', required: false,
    field: 'id', hide: true},
  { name: 'containerNo', required: true,
    field: 'container_no', headerName: 'Container', flex: 1},
  { name: 'filingNo', required: true,
    field: 'filing_no', headerName: 'Filing No.', cellRendererFramework: util.blMasterLinkFormatter, flex: 1},
  { name: 'blNo',
    field: 'bl_no', headerName: 'MB/L No.', cellRendererFramework: util.blMasterLinkFormatter, flex: 1},
  { name: 'tpSize', options: tpSizeOptions, noDefOption: true, required: true,
    field: 'tp_size', headerName: 'TP/SZ', width: 100},
  { name: 'sealNo',
    field: 'seal_no', headerName: 'Seal No.', width: 100},
  { name: 'packCount', label: 'Package',
    field: 'packCount', hide: true},
  { name: 'package',
    field: 'package', hide: true, headerName: 'Package', width: 100},
  { name: 'packUnit',
    field: 'packUnit', hide: true, options: packageTypeOptions, noDefOption: true},
  { name: 'weightKg', label: 'Weight(KG)',
    field: 'weight_kg', hide: true, headerName: 'Weight', width: 100},
  { name: 'weightLb', label: '',
    field: 'weightLb', hide: true},
  { name: 'cbm',
    field: 'cbm', hide: true, headerName: 'CBM', width: 100},
  { name: 'cft',
    field: 'cft', hide: true},
  { name: 'vslvoy',
    field: 'vslvoy', hide: true, headerName: 'VSL/VOY', width: 100},
  { name: 'pol',
    field: 'pol', headerName: 'POL', width: 100},
  { name: 'pod',
    field: 'pod', headerName: 'POD', width: 100},
  { name: 'etd',
    field: 'etd', headerName: 'ETD', valueFormatter: util.dateFormatter, width: 100},
  { name: 'eta',
    field: 'eta', headerName: 'ETA', valueFormatter: util.dateFormatter, width: 100},
  { name: 'remark', label: 'Remark',
    field: 'remark', hide: true},
  { name: 'pickNo', label: 'Pick No.',
    field: 'pickNo', hide: true},
  { name: 'jextra',
    field: 'jextra', hide: true},
  { name: 'aReefer', label: 'A.Reefer/Genset', field: 'aReefer', hide: true, options: yesNoOptions, noDefOption: true},
  { name: 'genset', label: '', field: 'genset', hide: true, options: yesNoOptions, noDefOption: true},
  { name: 'vent', label: 'Vent/D.G.', field: 'vent', hide: true, options: ventOptions, noDefOption: true},
  { name: 'dg', label: '', field: 'dg', hide: true, options: dgOptions, noDefOption: true},
  { name: 'finalEta', label: 'FinalETA/LFD', field: 'finalEta', hide: true},
  { name: 'lfd', label: '', field: 'lfd', hide: true},
  { name: 'freeDestination', label: 'Free Dest.', field: 'freeDestination', hide: true},
  { name: 'temp', label: 'Temp./Humi.', field: 'temp', hide: true},
  { name: 'humi', label: '', field: 'humi', hide: true},
  { name: 'airFlow', label: 'Air Flow/Unit', field: 'airFlow', hide: true},
  { name: 'airFlowUnit', label: '', field: 'airFlowUnit', hide: true, options: airFlowUnitOptions, noDefOption: true},
  { name: 'appt', label: 'Appt./Delivered', field: 'appt', hide: true},
  { name: 'delivered', label: '', field: 'delivered', hide: true},
  { name: 'emptyReturn', label: 'Empty Return', field: 'emptyReturn', hide: true},
  { name: 'oFrt', label: 'O/Frt', field: 'oFrt', hide: true},
  { name: 'containerSeq', label: 'Container Seq.', field: 'containerSeq', hide: true},
  { name: 'commodity', label: 'Commodity', field: 'commodity', type: 'textarea', hide: true},
];

function renderForm(formik, fields, errors) {
  const _r = (name) => renderField(formik, name, fields, errors);
  const _fn = (nameId, nameDisplay) => <MBLFilingNoSearchInput onOK={(data) => {
    const {id, value, jshipment} = data;
    formik.setFieldValue(nameId, id);
    formik.setFieldValue(nameDisplay, value);
    formik.setFieldValue('packCount', jshipment['package']);
    formik.setFieldValue('packUnit', jshipment['packageType']);
    formik.setFieldValue('weightKg', jshipment['grossWeightKg']);
    formik.setFieldValue('cbm', jshipment['cbm']);
  }} displayField={nameDisplay} idField={nameId} formik={formik} fields={fields} errors={errors} />;
  React.useEffect(() => {
    if (!formik.values['packUnit']) {
      formik.setFieldValue('packUnit', 'PACKAGE(S)');
    }
  }, []);
  return (
    <div>
      <div className={'flex'}>
        <div className={'flex-1'}>
          {_r('containerNo')}
          {_fn('filingNoId', 'filingNo')}
          {_r('pickNo')}
          {_r('weightKg')}
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('aReefer')}</div>
            <div className={'mx-8 mt-8'}>/</div>
            <div className={'flex-1'}>{_r('genset')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('vent')}</div>
            <div className={'mx-8 mt-8'}>/</div>
            <div className={'flex-1'}>{_r('dg')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('finalEta')}</div>
            <div className={'mx-8 mt-8'}>/</div>
            <div className={'flex-1'}>{_r('lfd')}</div>
          </div>
          {_r('freeDestination')}
          {_r('oFrt')}
        </div>
        <div className={'flex-1'}>
          {_r('tpSize')}
          {_r('sealNo')}
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('packCount')}</div>
            <div className={'mx-8 mt-8'}>{' '}</div>
            <div className={'flex-1'}>{_r('packUnit')}</div>
          </div>
          {_r('cbm')}
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('temp')}</div>
            <div className={'mx-8 mt-8'}>/</div>
            <div className={'flex-1'}>{_r('humi')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('airFlow')}</div>
            <div className={'mx-8 mt-8'}>/</div>
            <div className={'flex-1'}>{_r('airFlowUnit')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-2'}>{_r('appt')}</div>
            <div className={'mx-8 mt-8'}>/</div>
            <div className={'flex-1'}>{_r('delivered')}</div>
          </div>
          {_r('emptyReturn')}
          {_r('containerSeq')}
        </div>
      </div>
      <div>
        {_r('remark')}
      </div>
      <div>
        {_r('commodity')}
      </div>
    </div>
  );
}

function isActionDisabled(type: DataGridActionType, data: Object) {
  //if (type === 'addChild') return data['isair'] === true;
}

export default ContainerList;
