import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { sidebarReducer, themeReducer, rtlReducer, dataGridReducer } from '../../redux/reducers/index';
import {util, api} from '../../services/service';
import userReducer from '../../redux/reducers/userReducer';
import topbarReducer from '../../redux/reducers/topbarReducer';
import globalDialogReducer from '../../redux/reducers/globalDialogReducer';
import notiReducer from '../../redux/reducers/notiReducer';
import newCoshipReducer from '../../redux/reducers/newCoshipReducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  dataGrid: dataGridReducer,
  user: userReducer,
  topbar: topbarReducer,
  gdlg: globalDialogReducer,
  noti: notiReducer,
  newCoship: newCoshipReducer,
});

const store = createStore(reducer);
util.getState = store.getState;
util.dispatch = store.dispatch;
api.dispatch = store.dispatch;

export default store;
