import React from 'react';
import {api, util} from '../../services/service';
import {useSimpleModal} from '../../components/SimpleModal';
import {useSimpleGrid} from "../../components/SimpleGrid";

export default function useHolidayDetailModal() {
  const [rowData, setRowData] = React.useState(); // 그리드에서 선택한 행의 데이터를 보관함

  const grid = useSimpleGrid({
    columns: [
      {field: 'uname2', headerName: 'Name', flex: 1},
      {field: 'start_date', headerName: 'From', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'end_date', headerName: 'To', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'holiday_type', headerName: 'Type', flex: 1},
      {field: 'used_days', headerName: 'Used Days', flex: 1},
    ],
    height: 400,
    className: 'pb-20',
    actions: [],
    actionWidth: 70,
    onAction: () => {},
  });

  const modal = useSimpleModal({
    title: 'HOLIDAY HISTORY',
    centered: true,
    width: 650,
    children: grid.render()
  });

  const onSearch = async () => {
    api.getStaffHolidayHistory(rowData.id).then(res => {
      const {data} = res;
      if (data && data.length > 0) {
        grid.setRows(data);
      } else {
        grid.setRows([]);
      }
    });
  };

  const open = (data: Object) => {
    setRowData(data);
    modal.open();
  };

  React.useEffect(() => {
    if (modal.isOpen) {
      onSearch().catch();
    }
  }, [modal.isOpen]);
  return {...modal, open};
}
