/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {util} from "../../services/service";

const FN = {
  ID: 'id',
  NAME: 'customer_name',
  SERVICE_TYPE: 'service_type',
  EMAIL: 'email',
  PHONE: 'phone',
  FROM_COUNTRY: 'from_country',
  TO_COUNTRY: 'to_country',
  REQUEST_DATE: 'cdate',
  RESPONSE_DATE: 'udate',
  STATUS: 'response_status',
  STAFF: 'update_staff',
  ADDRESS: 'address',
  BIGO: 'bigo',
  REMARK: 'remark',
};

const SN = {
  ID: 'id',
  NAME: 'customer_name',
  SERVICE_TYPE: 'service_type',
  EMAIL: 'email',
  PHONE: 'phone',
  FROM_COUNTRY: 'from_country',
  TO_COUNTRY: 'to_country',
  REQUEST_DATE: 'cdate',
  RESPONSE_DATE: 'udate',
  STATUS: 'response_status',
  STAFF: 'update_staff',
  ADDRESS: 'address',
  BIGO: 'bigo',
  REMARK: 'remark',
};

export const serviceTypeOptions = [
  {value: '', label: 'Choose one.'},
  {value: '해외이사', label: '해외이사'},
  {value: '차량운송', label: '차량운송'},
  {value: '국제택배', label: '국제택배'},
  {value: '배송대행', label: '배송대행'},
  {value: '상업화물', label: '상업화물'},
  {value: 'E-COMMERCE SOLUTION', label: 'E-COMMERCE SOLUTION'},
  {value: 'Contact Us', label: 'Contact Us'},
];

export const responseStatusOptions = [
  {value: 'New', label: 'New'},
  {value: 'Called', label: 'Called'},
  {value: 'Contracted', label: 'Contracted'},
  {value: 'Thinking', label: 'Thinking'},
  {value: 'NORESPONSE', label: 'No Response'},
];

class DashboardRequestFormManager extends FormManager {
  FN = FN;
  constructor() {
    super({
      prefix: 'quote',
      fields: [
        {name: FN.ID, serverName: SN.ID, label: '', hide: true},
        {name: FN.NAME, serverName: SN.NAME, label: 'Name', disabled: true},
        {name: FN.SERVICE_TYPE, serverName: SN.SERVICE_TYPE, label: 'Service Type', options: serviceTypeOptions, noDefOption: true, useSameAsInput: true},
        {name: FN.EMAIL, serverName: SN.EMAIL, label: 'Email', disabled: true},
        {name: FN.PHONE, serverName: SN.PHONE, label: 'Phone', disabled: true},
        {name: FN.FROM_COUNTRY, serverName: SN.FROM_COUNTRY, label: 'City/Country of Departure', disabled: true},
        {name: FN.TO_COUNTRY, serverName: SN.TO_COUNTRY, label: 'City/Country of Destination', disabled: true},
        {name: FN.REQUEST_DATE, serverName: SN.REQUEST_DATE, label: 'Request Date', disabled: true, type: "date"},
        {name: FN.RESPONSE_DATE, serverName: SN.RESPONSE_DATE, label: 'Response Date', disabled: true, type: "date"},
        {name: FN.STATUS, serverName: SN.STATUS, label: 'Response Status', required: false, type: 'textarea', options: responseStatusOptions, noDefOption: true},
        {name: FN.STAFF, serverName: SN.STAFF, label: 'Updated by', required: false, disabled: true},
        {name: FN.ADDRESS, serverName: SN.ADDRESS, label: 'Address', disabled: true},
        {name: FN.BIGO, serverName: SN.BIGO, label: 'Extra Info.', disabled: true, type: 'textarea'},
        {name: FN.REMARK, serverName: SN.REMARK, label: 'Remark', required: false, type: 'textarea'},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => this.onSave(),
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.NAME)}</div>
          <div className={'flex-1'}>{_r(FN.SERVICE_TYPE)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.EMAIL)}</div>
          <div className={'flex-1'}>{_r(FN.PHONE)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.FROM_COUNTRY)}</div>
          <div className={'flex-1'}>{_r(FN.TO_COUNTRY)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1'}>{_r(FN.ADDRESS)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1'}>{_r(FN.BIGO)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.REQUEST_DATE)}</div>
          <div className={'flex-1'}>{_r(FN.RESPONSE_DATE)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1 mr-20'}>{_r(FN.STATUS)}</div>
          <div className={'flex-1'}>{_r(FN.STAFF)}</div>
        </div>
        <div className={'flex wrap w-full'}>
          <div className={'flex-1'}>{_r(FN.REMARK)}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values;
  }
}

export default DashboardRequestFormManager;
