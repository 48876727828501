/**
 * @flow
 */
import {useSimpleGrid} from "../../../../components/SimpleGrid";
import React from "react";

export default function useForwardingMasterBLContainerListGridView(onActionCb) {
  return useSimpleGrid({
    columns: [
      {field: 'container_no', headerName: 'Container#', flex: 1},
      {field: 'tpSize', headerName: 'TP/SZ', flex: 1},
      {field: 'filing_no', headerName: 'Filing No.', flex: 1},
      {field: 'seal_no', headerName: 'Seal#', flex: 1},
    ],
    height: 530,
    className: 'pb-20',
    actions: ['edit', 'addChild', 'delete'],
    actionWidth: 100,
    onAction: onActionCb,
  });
}
