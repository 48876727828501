/**
* @flow
*/
import {apiAccount, util} from "../../services/service";
import {useDataGridView} from "../../components/DataGridView";
import React from "react";

export default function useAccountProfitGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiAccount.profitList(gridInfo);
    },
    onCellValueChange(e) {
      // TODO
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
        // TODO
      }
    },
    name: `ListProfit`,
    label: 'Profit List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'Profit List'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['detail'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    columns: [
      {field: 'post_date', headerName: 'Post Date', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'filing_no', headerName: 'Filing No', cellRendererFramework: (param) => blAccountLinkFormatter(param, 'FN'), flex: 1},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: (param) => blAccountLinkFormatter(param, 'BL'), flex: 1},
      {field: 'pol', headerName: 'POL', flex: 1},
      {field: 'pod', headerName: 'POD', flex: 1},
      {field: 'amount_ar', headerName: 'A/R', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'amount_ap', headerName: 'A/P', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'amount_cm', headerName: 'C/M', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'amount_dc', headerName: 'D/C', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'amount_balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'amount_revenue', headerName: 'Revenue', valueFormatter: util.currencyFormatter, flex: 1}
    ],
  });
}

function blAccountLinkFormatter(param, mode) {
  const accountURL = mode === 'FN' ? `filingNo=${param.data['filing_no']}` : `blNo=${param.data['bl_no']}`;
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        util.openTab(`/admin/account/list?${accountURL}`);
      }}>
        {param.value}
      </a>
    </div>
  );
}
