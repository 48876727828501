/**
 * @flow
 */
import React from 'react';
import {useParams} from 'react-router-dom';
import FormManager from '../../lib/FormManager';
import {statusOptions} from '../helper';

const searchKeyOptions = [
    {value: 'PH', label: 'Phone'},
    {value: 'EM', label: 'Email'},
    {value: 'CO', label: 'Country'},
];

const statusKeyOptions = [
  {value: '', label: 'ALL'}, ...statusOptions
]

class BLSearchFormManager extends FormManager {
  onSearch;
  cachedValues; // 검색 옵션 값들을 저장
  constructor() {
    super({
      prefix: 'bl-list-search',
      fields: [
        {name: 'onlyWaitList', serverName: 'onlyWaitList', label: 'Shipping Wait', type: "checkbox", smallLabel: true, smallMargin: true},
        {name: 'searchName', label: 'Search by', options: searchKeyOptions, noDefOption: true, smallMargin: true, smallLabel: true},
        {name: 'searchVal', label: '', smallMargin: true, smallLabel: true},
        {name: 'status', label: 'Status', smallLabel: true, smallMargin: true, options: statusKeyOptions, noDefOption: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const params = useParams();
    const onlyWaitList = _v('onlyWaitList');
    const statusOption = _v('status');
    const readyToSearch = React.useRef(false);

    React.useEffect(() => {
      if (readyToSearch.current) {
        this.onSearch();
      } else {
        readyToSearch.current = true
      }
    }, [params.ts, onlyWaitList, statusOption]);

    const searchKey = _v('searchName');
    React.useEffect(() => {
      if(!searchKey) {
        _c('searchName', 'PH');
      } else {
        _c('searchName', searchKey);
      }
    }, [searchKey]);
    return (
      <>
        {_r('status')}
        {_r('searchName')}
        {_r('searchVal')}
        <div className={'mt-40 mb-12'}>{_r('onlyWaitList')}</div>
      </>
    );
  };
}

export default BLSearchFormManager;
