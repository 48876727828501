/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import Barcode from 'react-barcode';
import coshipLogo from '../media/coship_logo.png';
import {util} from '../services/service';

class ManifestPrint3 extends React.Component {
  render() {
    const {data = [], isMultiRows} = this.props;
    if (data.length <= 0) {
      return null;
    }
    return (
      <div>{data.map((item, index) => this.renderItem(item, index, isMultiRows)).flat()}</div>
    );
  }
  renderItem(item, index, isMultiRows) {
    let {
      bl_no, memo, ctns,
      shipper, shipper_address, shipper_phone,
      consignee, consignee_address, consignee_phone1, items,
      weight, cj_addr,
    } = item;
    if (cj_addr === null) {
      return util.showWarning('Please check consignee address!');;
    }
    const {CLSFCD, SUBCLSFCD, CLSFADDR, CLLDLVBRANNM, CLLDLVEMPNICKNM, P2PCD, BOX_COUNT, SHIPPER_NAME, SHIPPER_PHONE, SHIPPER_ADDRESS} = cj_addr;
    const clsfcd1 = CLSFCD?.substr(0, 1);
    const clsfcd2 = CLSFCD?.substr(1, 3);
    const clldlv = CLLDLVEMPNICKNM ? `${CLLDLVBRANNM}-${CLLDLVEMPNICKNM}` : CLLDLVBRANNM;
    const itemsOfItem = [];
    for (let i = 0; i < parseInt(ctns); ++i) itemsOfItem.push(i);
    const itemsCount = items?.length;
    let itemsToDisplay = items?.filter((i, index) => index < 10).map(i => i.item_qty > 1 ? ` ${i.item}(${i.item_qty})` : ` ${i.item}`);
    const MAX_DESC_LEN = 32;
    if (itemsToDisplay?.length > MAX_DESC_LEN) {
      itemsToDisplay = itemsToDisplay.substr(0, MAX_DESC_LEN) + '...';
    }
    const description = (itemsToDisplay + (itemsCount > 9 ? `외 ${itemsCount - 9}건` : '')).split(',').join(' / ') + ` / ${weight}KG`;
    bl_no = bl_no?.replaceAll(`"`, '').replaceAll('=', '');
    consignee = consignee.replace(consignee.substr(consignee.length - 1, 1), '*');
    consignee_phone1 = consignee_phone1?.replaceAll(`"`, '').replaceAll('=', '');
    consignee_phone1 = consignee_phone1.replace(consignee_phone1.substr(consignee_phone1.length - 4, 4), '****');
    shipper_phone = shipper_phone?.replaceAll(`"`, '').replaceAll('=', '');
    const style = {
      // margin: '0.18cm 0.1cm 0.1cm 1.3cm',
      color: 'black',
      fontSize: 30,
      width: 650,
      // height: 500,
      lineHeight: '0.5cm',
    };
    return itemsOfItem.map((ctnsIndex) => {
      return (
        <div className={'flex-column page-break air-manifest-print-container notoSansKorean'} style={{...style, marginTop: '0cm', marginRight: '0.1cm', marginBottom: '0.1cm', marginLeft: '1.3cm'}} key={`${index}-${ctnsIndex}`}>
          <style type="text/css">
            {"@media print{@page {size: landscape}}"}
          </style>
          <div style={{display: 'flex', height: 30, paddingLeft: 35, marginTop: 18}}>
            <div style={{width: '43%', fontSize: '0.8em'}}>{bl_no}</div>
            {/*<div style={{width: '22%', fontSize: '0.6em'}}>{util.getCurrentDate2()}</div>*/}
            <div style={{width: '22%', fontSize: '0.6em'}}>{''}</div>
            <div style={{width: '15%', fontSize: '0.6em'}}>{ctnsIndex + 1}/{ctns}</div>
            <div style={{width: '20%', fontSize: '0.6em'}}>{'재출력:0'}</div>
          </div>
          <div style={{display: 'flex', height: 90}}>
            <Barcode value={CLSFCD} displayValue={false} height={80} width={1.8} margin={0}/>
            <div style={{display: 'flex', paddingLeft: 10, marginTop: 30}}>
              <div style={{marginTop: 9}}>
                <span style={{fontSize: '2em', textDecoration: 'underline'}}>{clsfcd1}</span>
              </div>
              <div style={{display: 'flex'}}>
                <div style={{width: '300px'}}>
                  <span style={{fontSize: '3em'}}>{clsfcd2}</span>
                  <span style={{fontSize: '2em'}}>-</span>
                  <span style={{fontSize: '2em'}}>{SUBCLSFCD}</span>
                </div>
                <div style={{width: '40px', display: 'flex', alignItems: 'center'}}>
                  <span style={{fontSize: '1.3em'}}>{P2PCD}</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{height: 100, marginTop: 3, marginLeft: 1}}>
            <div style={{display: 'flex', fontSize: '0.7em'}}>
              <div style={{width: '30%', marginTop: 5}}>{consignee}</div>
              <div style={{width: '31%', marginTop: 5}}>{consignee_phone1}</div>
              <div style={{width: '39%'}}><Barcode value={bl_no} displayValue={false} height={35} width={2.3} margin={0}/></div>
            </div>
            <div style={{fontSize: '0.7em', lineHeight: '0.6cm', whiteSpace: 'pre-wrap'}}>{consignee_address}</div>
            <div style={{fontSize: '1.1em', lineHeight: '0.7cm', marginTop: 1}}>{CLSFADDR}</div>
          </div>
          <div style={{height: 40, marginTop: 13, marginLeft: 1}}>
            <div style={{display: 'flex', marginTop: 2, fontSize: '0.5em'}}>
              <div style={{width: '32%'}}>{util.textSubstring(SHIPPER_NAME, 16)}</div>
              <div style={{width: '24%'}}>{SHIPPER_PHONE}</div>
              <div style={{width: '31%'}}>{BOX_COUNT}</div>
              <div style={{width: '7%'}}>{'0'}</div>
              <div style={{width: '6%', paddingLeft: 4}}>{'신용'}</div>
            </div>
            <div style={{fontSize: '0.55em', lineHeight: '0.7cm', marginTop: -1}}>{SHIPPER_ADDRESS}</div>
          </div>
          <div style={{height: 120, marginTop: 5}}>
            <div style={{fontSize: '0.6em', lineHeight: '0.7cm', whiteSpace: 'pre-wrap'}}>{util.textSubstring(description, 330)}</div>
          </div>
          <div style={{display: 'flex', height: 50}}>
            <div style={{width: '75%'}}>{''}</div>
            {/*<div style={{width: '30%'}}><img src={coshipLogo} style={{width: '2.5cm'}} alt={'COSHIP'}/></div>*/}
            {/*<div style={{width: '25%'}}>{''}</div>*/}
            <div style={{width: '25%', marginTop: 32, fontSize: '0.7em', fontWeight: 'bold', color: 'red'}}>캐나다쉬핑</div>
          </div>
          <div style={{display: 'flex', height: 70, marginTop: 20}}>
            <div style={{width: '64%', paddingRight: 20}}>
              <div style={{height: 40, fontSize: '0.5em', lineHeight: '0.4cm', whiteSpace: 'pre-wrap'}}>{util.textSubstring(memo, 65)}</div>
              <div style={{height: 30, fontSize: '1.1em'}}>{clldlv}</div>
            </div>
            <div style={{width: '36%'}}>
              <Barcode value={bl_no} displayValue={true} font={'Noto Sans KR'} height={50} width={2.3} margin={0} textMargin={0}/>
            </div>
          </div>
        </div>
      );
    });
  }
}

export function useManifestPreviewModal3() {
  const [data, setData] = React.useState([]);
  const [isMultiRows, setIsMultiRows] = React.useState(false);
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'Air Manifest',
    width: 900,
    height: 650,
    children: <ManifestPrint3 ref={printRef} data={data} isMultiRows={isMultiRows} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      print();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data, isMultiRows) => {
      setData(data);
      setIsMultiRows(isMultiRows);
      modal.open();
    },
    print,
  };
}
