/**
 * @flow
 */
import React from "react";
import FormManager from '../../lib/FormManager';
import {DefaultButton} from '../../components/buttons';
import {AccountTypeSearchInput} from '../../components/SearchInput';

const unitOptions = [
  { value: 'CNTR', label: 'CNTR'},
  { value: 'B/L', label: 'B/L'},
  { value: 'CBM', label: 'CBM'},
  { value: 'DAYS', label: 'DAYS'},
  { value: 'M/T', label: 'M/T'},
  { value: 'R/T', label: 'R/T'},
  { value: 'TRUCK', label: 'TRUCK'},
  { value: 'HRS', label: 'HRS'},
  { value: 'KG', label: 'KG'},
  { value: 'WAGON', label: 'WAGON'},
  { value: 'CFT', label: 'CFT'},
  { value: 'SHIP', label: 'SHIP'},
];

class AccountRequestGridFormManager extends FormManager {
  onSave;
  constructor() {
    super({
      prefix: `account-request-grid-form`,
      fields: [
        {name: 'atypeId', serverName: 'atypeId', label: ''},
        {name: 'accountType', serverName: 'accountType', label: ''},
        {name: 'unit', serverName: 'unit', label: '', options: unitOptions, noDefOption: true},
        {name: 'rate', serverName: 'rate', label: ''},
        {name: 'volume', serverName: 'volume', label: ''},
        {name: 'totalAmount', serverName: 'totalAmount', label: 'Total'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atypeId`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;
    return (
      <>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Account Type</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Unit</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Rate</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Volume</span></div>
          <div className={'ml-20'} style={{visibility: 'hidden', height: 10}}><DefaultButton label={'Add'} onClick={() => {}} noMargin /></div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-8'}>{_at('accountType')}</div>
          <div className={'flex-1 mr-8'}>{_r('unit')}</div>
          <div className={'flex-1 mr-8'}>{_r('rate')}</div>
          <div className={'flex-1 mr-8'}>{_r('volume')}</div>
          <div className={'ml-20'}><DefaultButton label={'Add'} onClick={this.onSave} noMargin /></div>
        </div>
      </>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

export default AccountRequestGridFormManager;
