/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from '../../components/DataGridView';
import {apiAccount, util} from '../../services/service';

export default function useAccountVendorGridView(cargoType, detailModal) {
  const queryInfo = React.useRef();
  function openDetailModal(data) {
    const {partner_id: partnerId} = data;
    const {fromDate, toDate} = queryInfo.current ?? {};
    detailModal.open({...data, partnerId, fromDate, toDate});
  }
  return useDataGridView({
    async onAction(action, data) {
      if (action === 'detail') {
        openDetailModal(data);
      }
    },
    async onQuery(gridInfo) {
      queryInfo.current = gridInfo;
      return apiAccount.vendorList(gridInfo);
    },
    onCellValueChange(e) {
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      // if (headerName !== 'Actions') {
      //   openHistoryModal(data);
      // }
    },
    name: `VendorList_${cargoType}`,
    label: 'Vendor List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: cargoType},
    menuLabel: {label: 'List Vendor'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['detail'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    initialSearchLastMonth: true,
    showBranch: true,
    columns: [
      {field: 'partner_id', hide: true},
      {field: 'partner_name', headerName: 'Eng. Name', flex: 1},
      {field: 'local_name', headerName: 'Local Name', flex: 1},
      {field: 'phones', headerName: 'Phone #', flex: 1},
      {field: 'city', headerName: 'City', width: 150},
      {field: 'address', headerName: 'Address', width: 150},
      {field: 'packages', headerName: 'Packages', width: 140},
      {field: 'currency', headerName: 'Currency', width: 140},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 140},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 140}
    ],
  });
}
