/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import type {DataGridActionType} from '../../components/DataGrid';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';

let areaOptions = [];

const NoticeList = () => {
  areaOptions = api.useAreaOptions(true);
  return (
    <DataGridView
      name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
      addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
      actions={ACTIONS} actionWidth={ACTION_WIDTH}
      showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
      columns={getColumns}
      onAction={async (action, data) => onAction(action, data)}
      onQuery={async (gridInfo) => onQuery(gridInfo)}
      renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
      isActionDisabled={isActionDisabled}
      useExtendedColDef
    />
  );
};

const NAME = 'noticeList';
const LABEL = 'Notice';
const ADD_LABEL = 'ADD NOTICE';
const EDIT_LABEL = 'EDIT NOTICE';
const CATEGORY_LABEL = 'Settings';
const MENU_LABEL = 'Notice';
const SORT_COL = 'cdate';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 640;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit', 'delete'];
const ACTION_WIDTH = 70;

async function onAction(action, data) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
  switch (action) {
    case 'add':
      return api.noticeAdd(data);
    case 'edit':
      return api.noticeEdit(data);
    case 'delete':
      return api.noticeDel(data);
    case 'detail':
      return;
    case 'addOpen':
      return;
    case 'editOpen':
      return;
  }
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.noticeList(gridInfo);
}

function renderForm(formik, fields, errors) {
  const render = (name) => renderField(formik, name, fields, errors);
  return (
    <>
      {render('title')}
      {render('message')}
      {render('areaId')}
      {render('isPublish')}
    </>
  );
}

function isActionDisabled(type: DataGridActionType, data: Object) {
  if (type === 'edit' || type === 'delete') return data.ispublish === true;
}

function getColumns(): (ColDef | FormField)[] {
  const extendedAreaOptions = [{value: 0, label: 'ALL'}, ...areaOptions];
  return [
    { name: 'id', required: false,
      field: 'id', hide: true},
    { name: 'areaId', label: 'Branch', options: extendedAreaOptions, required: true,
      field: 'area_id', hide: true},
    { name: 'message', type: 'textarea', textAreaRows: 10, required: true,
      field: 'message', headerName: 'Message', hide: true},
    { name: 'cdate', required: false,
      field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 1},
    { name: 'title', required: true,
      field: 'title', headerName: 'Title', flex: 1},
    { name: 'loginId', required: false,
      field: 'login_id', headerName: 'LoginID', flex: 1},
    { name: 'uname', required: false,
      field: 'uname', headerName: 'Staff Name', flex: 1},
    { name: 'isPublish', type: 'checkbox', required: false,
      field: 'ispublish', headerName: 'Published', flex: 1, valueFormatter: util.yesNoFormatter},
    { name: 'area_name', required: false,
      field: 'area_name', headerName: 'Branch', flex: 1},
    { name: 'footer', required: false,
      field: 'footer', hide: true},
  ];
}

export default NoticeList;
