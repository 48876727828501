/**
 * @flow
 */
import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {ColDef} from 'ag-grid-community';
import type {DataGridInfo} from '../redux/reducers/dataGridReducer';
import {updateDataGridInfo, useDataGridInfo} from '../redux/reducers/dataGridReducer';
import KeyboardArrowUpIcon from 'mdi-react/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from 'mdi-react/KeyboardArrowDownIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import FirstPageIcon from 'mdi-react/FirstPageIcon';
import LastPageIcon from 'mdi-react/LastPageIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ViewListIcon from 'mdi-react/ViewListIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import {isMobileOnly} from 'react-device-detect';
import {PrimaryButton} from './buttons';
import type {QueryListParams} from '../services/API';
import {util, api} from '../services/service';
import PlusIcon from 'mdi-react/PlusIcon';
import PaymentIcon from 'mdi-react/PaymentIcon';
import PrinterIcon from 'mdi-react/PrinterIcon';
import ReceiptIcon from 'mdi-react/ReceiptIcon';
import LocalAtmIcon from 'mdi-react/LocalAtmIcon';
import PartnerHistoryIcon from 'mdi-react/ReceiptIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import BlMakeIcon from 'mdi-react/ReceiptIcon';

import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";

export type DataGridQuery = (gridInfo: QueryListParams) => Promise<{rows: Object[], totalCount: number}>;

export type DataGridActionType = 'edit' | 'delete' | 'detail' | 'addChild' | 'history' | 'print' | 'invoice' | 'commission' | 'partnerHistory' | 'copy' | 'email' | 'blMake';

export interface DataGridProps {
  name: string;
  columns: ColDef[];
  gridInfo: DataGridInfo;
  leftButton?: {
    label: string;
    onClick: () => void;
  };
  showDateSearch?: boolean;
  onQuery?: DataGridQuery;
  onRowClick?: (data: Object, rowIndex: number, param: Object) => void;
  onRowDoubleClick?: (data: Object, rowIndex: number, param: Object) => void;
  onCellClick?: (event: Object) => void;
  onCellValueChange?: (e: any) => void;
  hideSearchInput?: boolean;
  sortableColumns?: Object;
  initialSearchToday?: boolean;
  initialSearchOneWeek?: boolean;
  initialSearchOneMonth?: boolean;
  initialSearchOneYear?: boolean;
  initialSearchLastMonth?: boolean;
  initialSearchCurrentYear?: boolean;
  initialSearchLastMonthToToday?: boolean;
  initialSearchDateNone?: boolean;
  doNotPerformInitialQuery?: boolean;
  onGridReady?: any;
  rowSelection?: string,
  rowMultiSelectWithClick?: boolean,
  onSelectionChanged?: (e: any) => void;
  qryText?: string;
  showBranch?: boolean;
}

export const defaultColDef: ColDef = {
  resizable: true,
  minWidth: 70,
};

const rowCountOptions = [
  {value: 20, label: '20 rows'},
  {value: 80, label: '80 rows'},
  {value: 120, label: '120 rows'},
  {value: 2000, label: '2000 rows'},
];

const DataGrid = (props: DataGridProps) => {
  let gridInfo = useDataGridInfo(props.name) ?? props.gridInfo;
  gridInfo = gridInfo ?? props.gridInfo;
  util.setGridInfo(props.name, gridInfo);
  const [dateInfo, setDateInfo] = React.useState({});
  React.useEffect(() => {
    let queryInfo;
    if (props.initialSearchToday === true) { // WorkOrder 의 경우 오늘 날짜로 검색하는 것이 기본값이므로 이에 맞게 날짜 검색창을 설정해줌!
      const startToday = moment().startOf('day').toDate();
      const endToday = moment().endOf('day').toDate();
      queryInfo = {...gridInfo, fromDate: startToday.getTime(), toDate: endToday.getTime()};
      setDateInfo({start: startToday, end: endToday});
    } else if (props.initialSearchOneWeek === true) { // 날짜 검색 필드를 과거 7일 전부터 오늘까지로 설정
      const today = moment().endOf('day').toDate();
      const oneWeekAgo = moment().startOf('day').subtract(7, 'days').toDate();
      queryInfo = {...gridInfo, fromDate: oneWeekAgo.getTime(), toDate: today.getTime()};
      setDateInfo({start: oneWeekAgo, end: today});
    } else if(props.initialSearchOneMonth === true) { // 날짜 검색 필드를 한달 단위로 설정 ex) 11/01 ~ 11/30
      const firstDay = moment().startOf('month').toDate();
      const lastDay = moment().endOf('month').toDate();
      queryInfo = {...gridInfo, fromDate: firstDay.getTime(), toDate: lastDay.getTime()};
      setDateInfo({start: firstDay, end: lastDay});
    } else if(props.initialSearchOneYear === true) { // 오늘 날짜로부터 1년 뒤로 설정
      const firstDay =  moment().startOf('day').toDate();
      const lastDay = moment().endOf('day').add(12, 'months').toDate();
      queryInfo = {...gridInfo, fromDate: firstDay.getTime(), toDate: lastDay.getTime()};
      setDateInfo({start: firstDay, end: lastDay});
    } else if(props.initialSearchLastMonth === true) { // 날짜 검색 필드를 전달 단위로 설정 ex) 11/01 ~ 11/30
      const firstDay = moment().subtract(1, 'months').startOf('month').toDate();
      const lastDay = moment().subtract(1, 'months').endOf('month').toDate();
      queryInfo = {...gridInfo, fromDate: firstDay.getTime(), toDate: lastDay.getTime()};
      setDateInfo({start: firstDay, end: lastDay});
    } else if(props.initialSearchCurrentYear === true) { // 현재 년으로 설정 ex) 1/1 ~ 12/31
      const firstDay = moment().startOf('year').toDate();
      const lastDay = moment().endOf('year').toDate();
      queryInfo = {...gridInfo, fromDate: firstDay.getTime(), toDate: lastDay.getTime()};
      setDateInfo({start: firstDay, end: lastDay});
    } else if(props.initialSearchLastMonthToToday === true) { // 날짜 검색 필드를 전달부터 오늘까지로 설정 ex) 11/01 ~ 12/12
      const firstDay = moment().subtract(1, 'months').startOf('month').toDate();
      const endToday = moment().endOf('day').toDate();
      queryInfo = {...gridInfo, fromDate: firstDay.getTime(), toDate: endToday.getTime()};
      setDateInfo({start: firstDay, end: endToday});
    } else if(props.initialSearchDateNone === true) { // 디폴트 날짜 검색 필드값이 없음
      queryInfo = {...gridInfo, fromDate: undefined, toDate: undefined};
      setDateInfo({start: undefined, end: undefined});
    } else { // 그외의 경우 기본 날짜 필드를 일년 단위로 설정 (12개월을 뺀 달의 첫날)
      const firstDay = moment().subtract(12, 'months').startOf('month').toDate();
      const lastDay = moment().add(1, 'year').endOf('year').toDate();
      queryInfo = {...gridInfo, fromDate: firstDay.getTime(), toDate: lastDay.getTime()};
      setDateInfo({start: firstDay, end: lastDay});
      // queryInfo = gridInfo;
    }
    if (props.qryText) {
      queryInfo = {...gridInfo, qryText: props.qryText};
    } else {
      // 서치에 포커스를 줌
      const search = document.querySelector(`#${props.name}-search`);
      if (search) {
        search.focus();
      }
    }
    setTimeout(() => {
      // 마운트 될때 쿼리를 실행
      if (props.doNotPerformInitialQuery !== true) {
        if (props.gridInfo.cType && props.gridInfo.cType !== queryInfo.cType) {
          // NOTE: customer specific
          // cType 이 존재하는 경우 store 와 props 의 값이
          // 일치하지 않는 경우 props 의 값을 사용하여 쿼리함!
          queryGridData(props.name, {...queryInfo, cType: props.gridInfo.cType}, props.onQuery).catch(console.error);
        } else {
          queryGridData(props.name, queryInfo, props.onQuery).catch(console.error);
        }
      } else {
        util.setGridInfo(props.name, queryInfo);
      }
    }, 100);
  }, []);
  return (
    <div style={{marginLeft: -10, marginTop: -20}}>
      {renderGridHeader(props, gridInfo, dateInfo, setDateInfo)}
      <div className={`ag-theme-balham`} style={{width: '100%', height: props.dataGridHeight ? props.dataGridHeight : 'calc(100vh - 110px - 55px)'}}>
        <AgGridReact
          columnDefs={props.columns}
          rowData={gridInfo?.rows ?? []}
          defaultColDef={{
            ...defaultColDef,
            headerComponentParams: {
              dataGridName: props.name,
              dataGridOnQuery: props.onQuery,
              dataSortableColumns: props.sortableColumns ?? {},
            }
          }}
          frameworkComponents={{agColumnHeader: HeaderCell}}
          onRowClicked={param => props.onRowClick?.(param.data, param.rowIndex, param)}
          onRowDoubleClicked={param => props.onRowDoubleClick?.(param.data, param.rowIndex, param)}
          onCellClicked={props.onCellClick}
          onCellValueChanged={props.onCellValueChange}
          enableCellTextSelection={true}
          onGridReady={props.onGridReady}
          rowSelection={props.rowSelection}
          rowMultiSelectWithClick={props.rowMultiSelectWithClick}
          onSelectionChanged={props.onSelectionChanged}
        />
      </div>
      {renderPagination(props, gridInfo)}
    </div>
  );
};

function renderIntervalDate(props, gridInfo, dateInfo, setDateInfo) {
  const onChange = (date, name) => {
    let start, end;
    start = name === 'start' ? date : dateInfo.start;
    end = name === 'end' ? date : dateInfo.end;
    if (start || end) {
      start = start ?? end;
      end = end ?? start;
      queryGridData(props.name, {...gridInfo, fromDate: start.getTime(), toDate: end.getTime(), page: 1}, props.onQuery).catch(console.error);
    } else {
      const newGridInfo = {...gridInfo};
      newGridInfo.fromDate = undefined;
      newGridInfo.toDate = undefined;
      newGridInfo.page = 1;
      queryGridData(props.name, newGridInfo, props.onQuery).catch(console.error);
    }
    setDateInfo({...dateInfo, [name]: date});
  };
  const width = props.hideSearchInput === true ? 140 : 100;
  // return (
  //   <div className="date-picker date-picker--interval">
  //     <div style={{width, backgroundColor: '#fafbfe'}}>
  //       <DatePicker
  //         selected={dateInfo.start}
  //         selectsStart
  //         startDate={dateInfo.start}
  //         endDate={dateInfo.end}
  //         onChange={date => onChange(date, 'start')}
  //         dateFormat={'yyyy-MM-dd'}
  //         placeholderText={'From'}
  //         withPortal={isMobileOnly}
  //       />
  //     </div>
  //     <span className={'mx-2'}>~</span>
  //     <div style={{width, backgroundColor: '#fafbfe'}}>
  //       <DatePicker
  //         selected={dateInfo.end}
  //         selectsEnd
  //         minDate={dateInfo.start}
  //         startDate={dateInfo.start}
  //         endDate={dateInfo.end}
  //         onChange={date => onChange(date, 'end')}
  //         dateFormat={'yyyy-MM-dd'}
  //         placeholderText={'To'}
  //         withPortal={isMobileOnly}
  //       />
  //     </div>
  //   </div>
  // );
  return (
    <div className="date-picker date-picker--interval">
      <div style={{width, backgroundColor: '#fafbfe'}}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div style={{margin: 10, display: "flex", justifyContent: "center"}}>
              <button type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
              <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                {util.getRangeYears().map(option => (<option key={option} value={option}>{option}</option>))}
              </select>
              <select value={util.getMonths()[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(util.getMonths().indexOf(value))}>
                {util.getMonths().map(option => (<option key={option} value={option}>{option}</option>))}
              </select>
              <button type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
            </div>
          )}
          selected={dateInfo.start}
          selectsStart
          startDate={dateInfo.start}
          endDate={dateInfo.end}
          onChange={date => onChange(date, 'start')}
          dateFormat={'yyyy-MM-dd'}
          placeholderText={'From'}
          withPortal={isMobileOnly}
        />
      </div>
      <span className={'mx-2'}>~</span>
      <div style={{width, backgroundColor: '#fafbfe'}}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div style={{margin: 10, display: "flex", justifyContent: "center"}}>
              <button type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
              <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                {util.getRangeYears(2018, 1).map(option => (<option key={option} value={option}>{option}</option>))}
              </select>
              <select value={util.getMonths()[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(util.getMonths().indexOf(value))}>
                {util.getMonths().map(option => (<option key={option} value={option}>{option}</option>))}
              </select>
              <button type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
            </div>
          )}
          selected={dateInfo.end}
          selectsEnd
          minDate={dateInfo.start}
          startDate={dateInfo.start}
          endDate={dateInfo.end}
          onChange={date => onChange(date, 'end')}
          dateFormat={'yyyy-MM-dd'}
          placeholderText={'To'}
          withPortal={isMobileOnly}
        />
      </div>
    </div>
  );
}

function renderBranch(props, gridInfo) {
  const areaOptions = api.useAreaOptions(true);
  const branchOptions = [{value: '', label: 'ALL'}, ...areaOptions]
  const onChange = ({target: {value}}) => {
    queryGridData(props.name, {...gridInfo, branchId: value ? parseInt(value) : undefined}, props.onQuery).catch(console.error);
  };
  return (
    <select style={{backgroundColor: '#fafbfe', width: 130}} className={'ml-2'} onChange={onChange}>
      {branchOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
    </select>
  );
}

function renderGridHeader(props: DataGridProps, gridInfo: DataGridInfo, dateInfo, setDateInfo) {
  const onChange = ({target: {value}}) => {
    updateDataGridInfo(util.dispatch, props.name, {...gridInfo, qryText: value, page: 1});
    // if (value.length >= 2) {
    //   // 더이상 자동으로 쿼리를 보내지 않음
    //   //queryGridData(props.name, {...gridInfo, qryText: value, page: 1}, props.onQuery).catch(console.error);
    //   updateDataGridInfo(util.dispatch, props.name, {...gridInfo, qryText: value, page: 1});
    // } else if (value.length === 0) {
    //   queryGridData(props.name, {...gridInfo, qryText: '', page: 1}, props.onQuery).catch(console.error);
    // } else {
    //   updateDataGridInfo(util.dispatch, props.name, {...gridInfo, qryText: value, page: 1});
    // }
  };
  const onKeyDown = e => {
    const {target: {value}, key} = e;
    if (key === 'Enter') {
      queryGridData(props.name, {...gridInfo, qryText: value, page: 1}, props.onQuery).catch(console.error);
    }
  };
  const onSearchIconClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const el = document.querySelector(`#${props.name}-search`);
    const {value} = el ?? {};
    if (value) {
      queryGridData(props.name, {...gridInfo, qryText: value, page: 1}, props.onQuery).catch(console.error);
    }
  };
  return (
    <div style={{height: 50}} className={'flex between middle'}>
      {props.leftButton && (
        <div>
          <PrimaryButton id={`${props.name}-add-button`} label={props.leftButton.label} onClick={props.leftButton.onClick} noMargin />
        </div>
      )}
      {!props.leftButton && <div style={{width: 10}}/>}
      <form className={'form'} onSubmit={e => e.preventDefault()}>
        <div className={'flex middle'}>
          {props.showBranch &&
            <div className={'mr-3'}>{renderBranch(props, gridInfo)}</div>
          }
          {props.showDateSearch === true && (
            <div className={'mr-2'}>
              {renderIntervalDate(props, gridInfo, dateInfo, setDateInfo)}
            </div>
          )}
          {props.hideSearchInput !== true && (
            <div className="inbox__emails-control-search">
              <input
                id={`${props.name}-search`}
                placeholder={'Search...'}
                value={gridInfo.qryText}
                onChange={onChange}
                onKeyDown={onKeyDown}
              />
              <a href={'/#search'} onClick={onSearchIconClick} className={'inbox__emails-control-search-icon'}><MagnifyIcon /></a>
            </div>
          )}
          {props.showRowSearch === true && (
            <select style={{backgroundColor: '#fafbfe'}} className={'ml-2'} value={gridInfo.rowCount ?? 20} onChange={({target: {value}}) => {
              queryGridData(props.name, {...gridInfo, rowCount: parseInt(value)}, props.onQuery).catch(console.error);
            }}>
              {rowCountOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
            </select>
          )}
        </div>
      </form>
    </div>
  );
}

function renderPagination(props: DataGridProps, gridInfo: DataGridInfo) {
  const paginationInfo = util.getPaginationInfo(gridInfo ?? props.gridInfo);
  if (!paginationInfo) {
    return <div/>;
  }
  const {page, totalPages, totalCount, startIndex, endIndex} = paginationInfo;
  const onClick = (e, pageToJump) => {
    e.preventDefault();
    if (page !== pageToJump) {
      queryGridData(props.name, {...gridInfo, page: pageToJump}, props.onQuery).catch(console.error);
    }
  };
  return (
    <div className={'flex middle right mt-2'}>
      <span className={'mr-24'}>{`${startIndex + 1} to ${endIndex + 1} of ${totalCount}`}</span>
      <a href={'/#first'} onClick={e => onClick(e, 1)}><FirstPageIcon color={page === 1 ? '#bbbbbb' : '#666666'} /></a>
      <a href={'/#pref'} onClick={e => onClick(e, page === 1 ? 1 : page - 1)}><ChevronLeftIcon color={page === 1 ? '#bbbbbb' : '#666666'} /></a>
      <span className={'mx-12'}>{`Page ${page} of ${totalPages}`}</span>
      <a href={'/#next'} onClick={e => onClick(e, page === totalPages ? totalPages : page + 1)}><ChevronRightIcon color={page === totalPages ? '#bbbbbb' : '#666666'} /></a>
      <a href={'/#last'} onClick={e => onClick(e, totalPages)}><LastPageIcon color={page === totalPages ? '#bbbbbb' : '#666666'} /></a>
    </div>
  );
}

const HeaderCell = ({column, displayName, dataGridName, dataGridOnQuery, dataSortableColumns}) => {
  const gridInfo: DataGridInfo = useDataGridInfo(dataGridName) ?? {};
  let colId = column.colId;
  if (colId.endsWith('_1')) {
    colId = colId.substr(0, colId.length - 2);
  }
  const getDesc = () => {
    if (colId === gridInfo.orderBy) {
      if (gridInfo.isDesc === false) {
        return true;
      } else if (gridInfo.isDesc === true) {
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const onClick = async () => {
    if (displayName !== 'Actions') {
      // 헤더를 클릭하여 정렬을 변경한 경우 다시 목록을 쿼리함
      const newInfo = {...gridInfo, orderBy: colId, isDesc: getDesc()};
      queryGridData(dataGridName, newInfo, dataGridOnQuery).catch(console.error);
    }
  };
  const isSortable = dataSortableColumns[colId] !== false;
  if (!isSortable) {
    return displayName;
  }
  return (
    <div onClick={onClick} className={'flex middle'}>
      {displayName}
      {colId === gridInfo.orderBy && gridInfo.isDesc !== undefined && (
        gridInfo.isDesc ? <KeyboardArrowDownIcon size={18} className={'mx-1'} /> : <KeyboardArrowUpIcon size={18} className={'mx-1'} />
      )}
    </div>
  );
};

interface ActionCellProps {
  data: Object;
  onEditClick?: (data: Object) => void;
  onDeleteClick?: (data: Object) => void;
  onDetailClick?: (data: Object) => void;
  onHistoryClick?: (data: Object) => void;
  onPrintClick?: (data: Object) => void;
  onCommissionClick?: (data: Object) => void;
  onPartnerHistoryClick?: (data: Object) => void;
  onCopyClick?: (data: Object) => void;
  onEmailClick?: (data: Object) => void;
  onBlMakeClick?: (data: Object) => void;
  isDisabled?: (type: DataGridActionType, data: Object) => boolean;
  actionTooltips?: {[key: DataGridActionType]: string},
}

const actionIcons = {
  edit: TuneIcon,
  delete: DeleteIcon,
  detail: ViewListIcon,
  addChild: PlusIcon,
  history: PaymentIcon,
  print: PrinterIcon,
  invoice: ReceiptIcon,
  commission: LocalAtmIcon,
  partnerHistory: PartnerHistoryIcon,
  copy: ContentCopyIcon,
  email: EmailOutlineIcon,
  blMake: BlMakeIcon,
};

export const actionHandlers = {
  edit: 'onEditClick',
  delete: 'onDeleteClick',
  detail: 'onDetailClick',
  addChild: 'onAddChildClick',
  history: 'onHistoryClick',
  print: 'onPrintClick',
  invoice: 'onInvoiceClick',
  commission: 'onCommissionClick',
  partnerHistory: 'onPartnerHistoryClick',
  copy: 'onCopyClick',
  email: 'onEmailClick',
  blMake: 'onBlMakeClick',
};

const actionTooltips = {
  edit: 'Edit',
  delete: 'Delete',
  detail: 'Detail',
  addChild: 'Add',
  history: 'History',
  print: 'Print',
  invoice: 'Invoice',
  commission: 'Commission',
  partnerHistory: 'History',
  copy: 'Copy',
  email: 'Email',
  blMake: 'BlMake',
};

function renderActionIcon(props: ActionCellProps, type: DataGridActionType, color) {
  if (props[actionHandlers[type]]) {
    const tooltipLabel = props.actionTooltips?.[type] ?? actionTooltips[type];
    const disabled = props.isDisabled ? props.isDisabled(type, props.data) === true : false;
    const Icon = actionIcons[type];
    if (disabled) {
      return <Icon size={18} color={'#c0c0c0'} className={'mx-1'} />
    } else {
      return (
        <>
          <a data-tip data-for={`${type}-tip`} href={'/#'} onClick={(e) => {e.preventDefault(); props[actionHandlers[type]](props.data)}}>
            <Icon size={18} color={color} className={'mx-1'} />
          </a>
          <ReactTooltip id={`${type}-tip`} className={'tooltip'} place={'right'} type={'info'} effect={'solid'}>{tooltipLabel}</ReactTooltip>
        </>
      );
    }
  }
}

export const ActionCell = (props: ActionCellProps) => {
  return (
    <>
      {renderActionIcon(props, 'edit', '#70bbfd')}
      {renderActionIcon(props, 'history', '#70bbfd')}
      {renderActionIcon(props, 'detail', '#70bbfd')}
      {renderActionIcon(props, 'addChild', '#70bbfd')}
      {renderActionIcon(props, 'print', '#70bbfd')}
      {renderActionIcon(props, 'invoice', '#70bbfd')}
      {renderActionIcon(props, 'commission', '#70bbfd')}
      {renderActionIcon(props, 'delete', '#ff4861')}
      {renderActionIcon(props, 'partnerHistory', '#70bbfd')}
      {renderActionIcon(props, 'copy', '#70bbfd')}
      {renderActionIcon(props, 'email', '#70bbfd')}
      {renderActionIcon(props, 'blMake', '#70bbfd')}
    </>
  );
};

export async function queryGridData(name: string, gridInfo?: DataGridInfo, onQuery?: DataGridQuery) {
  gridInfo = gridInfo ?? util.getGridInfo(name) ?? {};
  // const {qryText, orderBy, isDesc, page = 1, rowCount = 20, fromDate, toDate, cType} = gridInfo;
  // const queryInfo: QueryListParams = {qryText, orderBy, isDesc, page, rowCount, fromDate, toDate, cType};
  const queryInfo = api.getQueryListData(gridInfo);
  if (onQuery) {
    const {data: rows, totalCount} = await onQuery(queryInfo);
    updateDataGridInfo(util.dispatch, name, {...gridInfo, rows, totalCount});
  } else {
    const {data: rows, totalCount} = await api[name](queryInfo);
    updateDataGridInfo(util.dispatch, name, {...gridInfo, rows, totalCount});
  }
}

export default DataGrid;
