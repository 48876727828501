/**
 * @flow
 */
import React from 'react';
import {renderField} from '../../components/Form';
import type {FormField} from '../../components/Form';
import {
  clearFieldValues, currencyOptions,
  getFieldValues,
  setFieldValue, setFieldValues,
} from '../helper';
import type {FormInfo, SearchData} from '../helper';
import {ColDef} from 'ag-grid-community';
import {util} from '../../services/service';

const PREFIX = 'container';
const LABEL = 'Container';

const fields: FormField[] = [
  {name: 'id', label: ''},
  {name: 'pId', label: ''},
  {name: 'fileNo', label: ''},
  {name: 'containerId', label: ''},
  {name: 'craftId', label: ''},
  {name: 'weight', label: ''},
  {name: 'sealNo', label: ''},
  // {name: 'mainFee', label: ''},
  // {name: 'currency', label: '', options: currencyOptions},
  // {name: 'extraFee', label: ''},
  // {name: 'currency2', label: '', options: currencyOptions},
  {name: 'footer', label: ''},
].map(i =>({...i, name: `${PREFIX}-${i.name}`}));

const columns: ColDef[] = [
  {field: 'id', hide: true},
  {field: 'pid', hide: true},
  {field: 'file_no', headerName: 'File No.', flex: 1},
  {field: 'craft_id', hide: true},
  {field: 'container_id', headerName: 'Container', flex: 1},
  {field: 'seal_no', headerName: 'Seal No.', flex: 1},
  {field: 'weight', headerName: 'Weight', flex: 1},
  // {field: 'currency', hide: true},
  // {field: 'main_fee', headerName: 'Fee', valueFormatter: (p) => util.currencyFormatterEx(p, 'currency'), flex: 1},
  // {field: 'currency2', hide: true},
  // {field: 'extra_fee', headerName: 'Extra Expense', valueFormatter: (p) => util.currencyFormatterEx(p, 'currency2'), flex: 1},
];

const nameMap = Object.entries({
  id: 'id',
  pid: 'pId',
  file_no: 'fileNo',
  container_id: 'containerId',
  craft_id: 'craftId',
  weight: 'weight',
  seal_no: 'sealNo',
  // currency: 'currency',
  // main_fee: 'mainFee',
  // currency2: 'currency2',
  // extra_fee: 'extraFee',
});

function render(formik, fields, errors, onSearch) {
  const _r = (name) => renderField(formik, `${PREFIX}-${name}`, fields, errors);
  return (
    <>
      <>
        <div className={'form-row flex wrap w-full mt-12 pl-3'}>
          <div className={'flex-1 f-12 ml-2'}>File No.</div>
          <div className={'flex-1 f-12 ml-2'}>Container</div>
          <div className={'flex-1 f-12 ml-2'}>Weight</div>
          <div className={'flex-1 f-12 ml-2'}>Seal No.</div>
        </div>
        <div className={'form-row flex wrap w-full pl-3'}>
          <div className={'flex-1 mr-2'}>{_r('fileNo')}</div>
          <div className={'flex-1 mr-2'}>{_r('containerId')}</div>
          <div className={'flex-1 mr-2'}>{_r('weight')}</div>
          <div className={'flex-1 mr-2'}>{_r('sealNo')}</div>
        </div>
        {/*<div className={'form-row flex wrap w-full pl-3'} style={{marginTop: -10}}>*/}
        {/*  <div className={'flex-1 f-12 ml-2'}>Currency</div>*/}
        {/*  <div className={'flex-1 f-12 ml-2'}>Fee</div>*/}
        {/*  <div className={'flex-1 f-12 ml-2'}>Extra Currency</div>*/}
        {/*  <div className={'flex-1 f-12 ml-2'}>Extra Expense</div>*/}
        {/*</div>*/}
        {/*<div className={'form-row flex wrap w-full pl-3'}>*/}
        {/*  <div className={'flex-1 mr-2'}>{_r('currency')}</div>*/}
        {/*  <div className={'flex-1 mr-2'}>{_r('mainFee')}</div>*/}
        {/*  <div className={'flex-1 mr-2'}>{_r('currency2')}</div>*/}
        {/*  <div className={'flex-1 mr-2'}>{_r('extraFee')}</div>*/}
        {/*</div>*/}
      </>
    </>
  );
}

export const containerFormInfo: FormInfo = {
  tab: PREFIX,
  label: LABEL,
  fields,
  columns,
  render,
  formik: null,
  nameMap,
  clearFieldValues: () => clearFieldValues(containerFormInfo),
  setFieldValues: (data: any) => setFieldValues(containerFormInfo, data),
  setFieldValue: (name: string, value: any) => setFieldValue(containerFormInfo, name, value),
  getFieldValues: () => getFieldValues(containerFormInfo),
};
