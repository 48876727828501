/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, apiSettings, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';
import type {DataGridActionType} from '../../components/DataGrid';
import {PortSearchInput, TradePartnerSearchInput, VesselSearchInput} from '../../components/SearchInput';
import ViewListIcon from 'mdi-react/ViewListIcon';
import {useUser} from "../../redux/reducers/userReducer";
import useVesselHouseModal from "../../modals/useVesselHouseModal";
import {useSendEmailModal} from "../../forms/email/SendEmailFormManager";

const VesselList = () => {
  const [houses, setHouses] = React.useState();
  const user = useUser();

  const onSendEmail = (houses) => {
    setHouses(houses);
    sendEmailModal.open('send', onSend);
  };
  const onSend = (mode, data) => {
    const {pic_email, toEmail, subject, subjectName, message, selectedFile} = data;
    let blId = data.blId ?? houses;
    if(!subject) {
      return util.showWarning('Please select subject!');
    }
    if(!pic_email) {
      return util.showWarning('Please enter a valid email!');
    }
    if(!message) {
      return util.showWarning('Please enter a valid message!');
    }
    const formData = new FormData();
    formData.append('from', pic_email);
    formData.append('to', toEmail);
    formData.append('subject', subjectName);
    formData.append('message', message);
    formData.append('houses', blId);
    formData.append('file', selectedFile);
    const res = apiSettings.sendEmail(formData);
    if(res) {
      util.showSuccess('Email has been sent successfully.');
      sendEmailModal.close();
    }
  };
  const sendEmailModal = useSendEmailModal(onSend, houses);
  const houseListModal = useVesselHouseModal(onSendEmail);
  async function onAction(action, data) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
    switch (action) {
      case 'add': return await callAPI(data);
      case 'edit': return await callAPI(data);
      case 'copy': return await callAPI(data);
      case 'delete':
        // if(!util.isValidPermitForAccount(user, 'edit')) {
        //   return util.showError('Sorry, you can not edit vessel Info.');
        // }
        return api.delCraft(data.id);
      case 'addChild':
        return;
      case 'email':
        houseListModal.open(data);
        return;
    }
  }
  function callAPI(data) {
    const {id, carrierId, craftNo, vslName, etdDate, etdTime, etaDate, etaTime, departure, destination, ...jextra} = data;
    return api.craftSet({
      id,
      isVessel: true,
      carrierId,
      craftNo,
      vslName,
      etdDate,
      etdTime,
      etaDate,
      etaTime,
      departure,
      destination,
      jextra: {
        etdDate,
        etdTime,
        etaDate,
        etaTime,
        ...jextra,
      },
    });
  }
  React.useEffect(() => {
    const qs = util.getQS();
    if (qs.add === 'true') {
      document.querySelector(`#${NAME}-add-button`).click();
    }
  }, []);
  return (
    <>
      <DataGridView
        name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
        addLabel={ADD_LABEL} editLabel={EDIT_LABEL} copyLabel={COPY_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
        actions={ACTIONS} actionWidth={ACTION_WIDTH}
        showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
        columns={COLUMNS}
        onAction={async (action, data) => onAction(action, data)}
        onQuery={async (gridInfo) => onQuery(gridInfo)}
        renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
        useExtendedColDef
        isActionDisabled={isActionDisabled}
      />
      {houseListModal.render()}
      {sendEmailModal.render()}
    </>
  );
};

const NAME = 'vesselList';
const LABEL = 'Vessel';
const ADD_LABEL = 'ADD VESSEL';
const EDIT_LABEL = 'EDIT VESSEL';
const COPY_LABEL = 'COPY VESSEL';
const CATEGORY_LABEL = 'Craft';
const MENU_LABEL = 'Vessel';
const SORT_COL = 'id';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 800;
const SHOW_DATE_SEARCH = false;
// const ACTIONS = ['edit', 'copy', 'delete'];
// const ACTION_WIDTH = 100;
const ACTIONS = ['edit', 'copy', 'delete', 'email'];
const ACTION_WIDTH = 130;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'id', required: false,
    field: 'id', hide: true},
  { name: 'isVessel', required: false,
    field: 'isvessel', hide: true},
  { name: 'filingNo', required: false,
    field: 'filing_no', headerName: 'Filing No.', cellRendererFramework: util.blMasterLinkFormatter},
  { name: 'carrierId', required: false,
    field: 'carrier_id', hide: true},
  { name: 'carrier', required: true,
    field: 'carrier_name', headerName: 'Carrier', flex: 1},
  { name: 'billingCarrier', label: 'Billing Carrier',
    field: 'billingCarrier', hide: true},
  { name: 'vslName', required: true,
    field: 'vsl_name', headerName: 'VSL/VOY', flex: 1},
  { name: 'craftNo', required: true,
    field: 'craft_no', hide: true},
  { name: 'onboard', label: 'Onboard',
    field: 'onboard', hide: true},
  { name: 'craftNo', required: false,
    field: 'craft_no', headerName: 'VOY', flex: 1},
  { name: 'etdDate', required: false, type: 'date', mask: util.MASK_DATE,
    field: 'etd_date', headerName: 'ETD', valueFormatter: util.dateFormatter, width:100},
  { name: 'etdTime', required: false, mask: util.MASK_TIME,
    field: 'etd_time', hide: true},
  { name: 'etaDate', required: false, type: 'date', mask: util.MASK_DATE,
    field: 'eta_date', headerName: 'ETA', valueFormatter: util.dateFormatter, width:100},
  { name: 'etaTime', required: false, mask: util.MASK_TIME,
    field: 'eta_time', hide: true},
  { name: 'departure', required: true,
    field: 'departure', headerName: 'POL', width:100},
  { name: 'destination', required: true,
    field: 'destination', headerName: 'POD', width:100},
  { name: 'ccount', required: false,
    field: 'ccount', headerName: 'Containers', width:100, cellRendererFramework: containerCountFormatter},
  { name: 'jextra', required: false,
    field: 'jextra', hide: true},
];

function containerCountFormatter(param) {
  if(param.data['filing_no'] && param.data['ccount'] > 0) {
    return (
      <div>
        {param.value}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} onClick={(e) => {
          e.preventDefault();
          util.nav(`/admin/craft/container?filingNo=${param.data['filing_no']}`);
        }}>
          <ViewListIcon size={18} color={'#70bbfd'} className={'mx-1'} />
        </a>
      </div>
    );
  } else {
    return (
      <div>
        {param.value}
      </div>
    );
  }
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  const {data: rows, totalCount} = await api.craftListVessel(gridInfo);
  const rowsToUse = rows.map(({jextra, ...others}) => {
    const {etaTime: eta_time, etdTime: etd_time} = jextra;
    return {...jextra, ...others, eta_time, etd_time};
  });
  return {data: rowsToUse, totalCount};
}

function renderForm(formik, fields, errors) {
  const _r = (name) => renderField(formik, name, fields, errors);
  const _tp = (nameId, nameDisplay) => <TradePartnerSearchInput idField={nameId} displayField={nameDisplay} formik={formik} fields={fields} errors={errors} />;
  const _pt = (nameId, nameDisplay) => <PortSearchInput displayField={nameDisplay} idField={nameId} formik={formik} fields={fields} errors={errors} />
  const _vs = (nameId, nameDisplay) => <VesselSearchInput idField={nameId} displayField={nameDisplay} formik={formik} fields={fields} errors={errors} />
  return (
      <div className={'flex'}>
        <div className={'flex-1'}>
          <div className={'flex-4'}>{_tp('carrierId', 'carrier')}</div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('vslName')}</div>
            <div className={'flex-1 ml-8'}>{_r('craftNo')}</div>
          </div>
          <div className={'flex-4'}>{_pt('departureId', 'departure')}</div>
          <div className={'flex-4'}>{_pt('destinationId', 'destination')}</div>
        </div>
        <div className={'flex-1'}>
          <div className={'flex-4'}>{_tp('billCarrierId', 'billingCarrier')}</div>
          <div className={'flex-4'} style={{paddingTop: '16px', paddingBottom: '15px'}}>{'\u00a0'}</div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('etdDate')}</div>
            <div className={'flex-1 ml-8'}>{_r('etdTime')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('etaDate')}</div>
            <div className={'flex-1 ml-8'}>{_r('etaTime')}</div>
          </div>
        </div>
      </div>
  );
}

function isActionDisabled(type: DataGridActionType, data: Object) {
  if (type === 'addChild') return data['isair'] === true;
  if (type === 'delete' && data.ccount > 0) {
    return true;
  } else {
    return false;
  }
}

export default VesselList;
