/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';

class CourierOceanPrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (printData?.length === 0) {
      return null;
    }
    return (
      <div style={{color: 'black', textAlign: 'center', fontWeight: 'bold'}}>
        {<style type="text/css">
          {"@media print{@page {size: landscape}}"}
        </style>}
        {printData?.map((data) => {
          let packages = [];
          for (let i=0; i<data.totalPackage; i++) packages.push(i+1);
          return (
            packages.map((count) => {
              return (
                <div key={`label-${data}`} className={'page-break'} style={{marginTop: 100}}>
                  <div style={{fontSize: '15em'}}>{data.name}</div>
                  <div style={{fontSize: '15em'}}>{`${data.totalPackage} - ${count}`}</div>
                </div>
              );
            })
          );
        })}
      </div>
    );
  }
}

export function useCourierOceanPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'Ocean Label',
    width: 1024,
    children: <CourierOceanPrint ref={printRef} printData={printData}/>,
  });
  React.useEffect(() => {
    setTimeout(() => {
      print();
    }, 500);
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setPrintData(data);
      modal.open();
    },
    print,
  };
}
