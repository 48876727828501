/**
 * @flow
 */
import React from 'react';
import {TAB_ACCOUNT} from '../../modal-tabs/types';
import useAccountVendorGridView from '../../grids/account/useAccountVendorGridView';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import useVendorDetailModal from "../../modals/account/useVendorDetailModal";

const AccountVendor = () => {
  useTopbar({label: 'Account'}, {label: 'Vendor'});
  const detailModal = useVendorDetailModal();
  const grid = useAccountVendorGridView(TAB_ACCOUNT, detailModal);
  React.useEffect(() => {
    if (detailModal.isOpen === false) {
      grid.query();
    }
  }, [detailModal.isOpen]);
  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        {grid.render()}
      </div>
      {detailModal.render()}
    </div>
  );
};

export default AccountVendor;
