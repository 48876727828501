/**
 * @flow
 */
import {useDataGridView} from '../components/DataGridView';
import {api, util} from '../services/service';
import {partnerTypeOptions} from '../forms/helper';

export function useTradePartnerGridView(actionCb, searchText = '') {
  return useDataGridView({
    async onAction(action, data) {
      return actionCb(action, data);
    },
    async onQuery(gridInfo) {
      return api.forwardTradePartnerList(gridInfo);
    },
    onRowClick(data) {
      //movingModal.open(data.hbl, TAB_WORK_ORDER);
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    name: `tradePartnerList`,
    label: 'Trade Partner',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Partner'},
    menuLabel: {label: 'List'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'detail', 'commission', 'partnerHistory'],
    actionTooltips: {
      detail: 'Contact Info',
      commission: 'Bank Info',
    },
    isActionDisabled: (action, data) => {
      // eslint-disable-next-line max-len
      return (
        action === 'partnerHistory'
        && data.partner_type !== 'CS'
        && data.partner_type !== 'VD'
        && data.partner_type !== 'DZ'
        && data.partner_type !== 'BC'
      );
    },
    actionWidth: 130,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    qryText: searchText,
    //initialSearchToday: true,
    columns: () => {
      return [
        {field: 'branch_name', headerName: 'Branch', width: 100},
        {field: 'partner_type', headerName: 'Type', valueFormatter: partnerTypeFormatter,width: 80},
        {field: 'eng_name', headerName: 'Eng. Name', width: 100},
        {field: 'local_name', headerName: 'Name', width: 80},
        {field: 'email', headerName: 'Email', width: 100},
        {field: 'phone', headerName: 'Phone', width: 100},
        {field: 'city', headerName: 'City', width: 100},
        {field: 'local_address', headerName: 'Address', flex: 1},
        {field: 'cdate', headerName: 'C.Date', valueFormatter: cDateFormatter, width: 100},
        {field: 'staff_name', headerName: 'PIC', width: 80},
        {field: 'isactive', headerName: 'Active', valueFormatter: util.yesNoFormatter, width: 80},
      ];
    },
  });
}

function partnerTypeFormatter(p) {
  const item = partnerTypeOptions.find(i => i.value === p.value);
  return item ? item.label : '';
}

function cDateFormatter(p) {
  return util.formatD(p.value) ? util.formatD(p.value) : '';
}
