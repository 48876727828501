/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';

const FN = {
  BOX_NO: 'boxNo',
  WIDTH: 'width',
  LENGTH: 'length',
  HEIGHT: 'height',
  KG: 'calWeight',
  PCS: 'pcs',
  CBM: 'boxCBM',
};

const SN = {
  BOX_NO: 'box_no',
  WIDTH: 'width',
  LENGTH: 'length',
  HEIGHT: 'height',
  KG: 'cal_weight',
  PCS: 'pcs',
  CBM: 'box_cbm',
};

class BLBoxFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'bl-box-form',
      fields: [
        {name: FN.BOX_NO, serverName: SN.BOX_NO, label: ''},
        {name: FN.WIDTH, serverName: SN.WIDTH, label: ''},
        {name: FN.LENGTH, serverName: SN.LENGTH, label: ''},
        {name: FN.HEIGHT, serverName: SN.HEIGHT, label: ''},
        {name: FN.PCS, serverName: SN.PCS, label: ''},
        {name: FN.KG, serverName: SN.KG, label: ''},
        {name: FN.CBM, serverName: SN.CBM, label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    const w = _v(FN.WIDTH);
    const l = _v(FN.LENGTH);
    const h = _v(FN.HEIGHT);
    const pcs = _v(FN.PCS)
    React.useEffect(() => {
      if (w && l && h && pcs) {
        const weight = w * l * h / 6000 * pcs;
        const cbm = w * l * h * pcs / 1000000;
        if (!isNaN(weight)) {
          _c(FN.KG, weight.toFixed(1));
        }
        if (!isNaN(cbm)) {
          _c(FN.CBM, cbm.toFixed(2));
        }
      }
    }, [w, l, h, pcs]);
    return (
      <div className={'ml-20 mt-8 w-full'}>
        <div className={'form-row flex wrap w-full'}>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>No.</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Width</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Length</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Height</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>PCS</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>V.Weight</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>CBM</span></div>
        </div>
        <div className={'form-row flex wrap w-full'}>
          <div className={'flex-1 mr-12'}>{_r(FN.BOX_NO)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.WIDTH)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.LENGTH)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.HEIGHT)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.PCS)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.KG)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.CBM)}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

export default BLBoxFormManager;
