/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {kindOptions} from '../field-defs/bl';
import {useUserBranch} from '../../redux/reducers/userReducer';
import {woTypeOptions} from '../work-order/WorkOrderFormManager';
import {useParams} from 'react-router-dom';

class BLWOSearchFormManager extends FormManager {
  onSearch;
  onInitialSearch;
  constructor() {
    super({
      prefix: 'lb-list-search',
      fields: [
        // eslint-disable-next-line max-len
        {name: 'kind', serverName: 'kind', label: 'Service', smallLabel: true, smallMargin: true, options: addAllOptions(kindOptions), noDefOption: true},
        // eslint-disable-next-line max-len
        {name: 'workType', serverName: 'workType', label: 'Type', smallLabel: true, smallMargin: true, options: addAllOptions(woTypeOptions), noDefOption: true},
        {name: 'branchId', serverName: 'branchId', label: 'Branch', smallLabel: true, noDefOption: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, getValue: _v} = this;
    const params = useParams();
    const branchId = _v('branchId');
    const {readyToSearch} = useUserBranch(this, 'branchId', this.onInitialSearch, true);
    const kind = _v('kind');
    const workType = _v('workType');
    React.useEffect(() => {readyToSearch && this.onSearch();}, [kind, workType, branchId, params.ts]);
    return (
      <>
        {_r('kind')}
        {_r('workType')}
        {_r('branchId')}
      </>
    );
  };
  onValidate = (values) => {
    // TODO: 밸리데이션이 필요한 경우 여기서...
    return values;
  };
}

function addAllOptions(options: any[]) {
  return [{value: '', label: 'ALL'}, ...options];
}

export default BLWOSearchFormManager;
