/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {DefaultButton} from '../../components/buttons';
import {apiSettings, util} from "../../services/service";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class UpdateDistributingCodeFormManager extends FormManager {
  uploadModal;
  updateDate;
  constructor() {
    super({
      prefix: `update-distributing-code-form`,
      fields: [],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onRender = () => {
    const title = 'Update Distributing Code';
    const [updated, setUpdated] = React.useState();
    const onQuery = async () => {
      const res = await apiSettings.getLastUpdated();
      setUpdated(res.data[0].udate);
    }
    const onUpload = () => {
      this.uploadModal.open();
    }
    React.useEffect(() => {
      // if(this.updateDate) {
      //   setUpdated(this.updateDate);
      // }
      if(!updated) {
        onQuery().catch();
      }
    }, []);
    return (
      <>
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
            </div>
            <div className={'w-full'}>
              <div className={'ml-20 mb-20'}>Last Update: {util.formatDTS(updated)}</div>
              <div className={'ml-20'}>
                <DefaultButton label={'Upload'} onClick={onUpload} noMargin />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      </>
    );
  };

  onValidate = (values) => {
  };

  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

export default UpdateDistributingCodeFormManager;
