/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';
import type {DataGridActionType} from '../../components/DataGrid';
import {PortSearchInput, TradePartnerSearchInput} from '../../components/SearchInput';
import {useUser} from "../../redux/reducers/userReducer";

const FlightList = () => {
  const user = useUser();
  async function onAction(action, data) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
    switch (action) {
      case 'add': return await callAPI(data);
      case 'edit': return await callAPI(data);
      case 'copy': return await callAPI(data);
      case 'delete':
        // if(!util.isValidPermitForAccount(user, 'edit')) {
        //   return util.showError('Sorry, you can not edit flight Info.');
        // }
        return api.delCraft(data.id);
      case 'addChild':
        return;
    }
  }
  function callAPI(data) {
    const {id, carrierId, craftNo, vslName, etdDate, etdTime, etaDate, etaTime, departure, destination, ...jextra} = data;
    //console.log('hello', {id, carrierId, craftNo, vslName, etdDate, etdTime, etaDate, etaTime, departure, destination, jextra});
    return api.craftSet({
      id,
      isVessel: false,
      carrierId,
      craftNo,
      vslName,
      etdDate,
      etdTime,
      etaDate,
      etaTime,
      departure,
      destination,
      jextra: {
        etdDate,
        etdTime,
        etaDate,
        etaTime,
        ...jextra,
      }
    });
  }
  React.useEffect(() => {
    const qs = util.getQS();
    if (qs.add === 'true') {
      document.querySelector(`#${NAME}-add-button`).click();
    }
  }, []);
  return (
    <>
      <DataGridView
        name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
        addLabel={ADD_LABEL} editLabel={EDIT_LABEL} copyLabel={COPY_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
        actions={ACTIONS} actionWidth={ACTION_WIDTH}
        showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
        columns={COLUMNS}
        onAction={async (action, data) => onAction(action, data)}
        onQuery={async (gridInfo) => onQuery(gridInfo)}
        renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
        useExtendedColDef
        isActionDisabled={isActionDisabled}
      />
    </>
  );
};

const NAME = 'flightList';
const LABEL = 'Flight';
const ADD_LABEL = 'ADD FLIGHT';
const EDIT_LABEL = 'EDIT FLIGHT';
const COPY_LABEL = 'COPY FLIGHT';
const CATEGORY_LABEL = 'Craft';
const MENU_LABEL = 'Flight';
const SORT_COL = 'id';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 800;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit', 'copy', 'delete'];
const ACTION_WIDTH = 100;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'id', required: false,
    field: 'id', hide: true},
  { name: 'isVessel', required: false,
    field: 'isvessel', hide: true},
  { name: 'filingNo', required: false,
    field: 'filing_no', headerName: 'Filing No.', cellRendererFramework: util.blMasterLinkFormatter},
  { name: 'carrierId', required: false,
    field: 'carrier_id', hide: true},
  { name: 'carrier', required: true,
    field: 'carrier_name', headerName: 'Carrier'},
  { name: 'craftNo', required: true,
    field: 'craft_no', headerName: 'Flight No.'},
  { name: 'etdDate', required: false, type: 'date', mask: util.MASK_DATE,
    field: 'etd_date', headerName: 'ETD', valueFormatter: util.dateFormatter, flex: 0.5},
  { name: 'etdTime', required: false, mask: util.MASK_TIME,
    field: 'etd_time', hide: true},
  { name: 'etaDate', required: false, type: 'date', mask: util.MASK_DATE,
    field: 'eta_date', headerName: 'ETA', valueFormatter: util.dateFormatter, flex: 0.5},
  { name: 'etaTime', required: false, mask: util.MASK_TIME,
    field: 'eta_time', hide: true},
  { name: 'departure', required: true, label: 'Departure',
    field: 'departure', headerName: 'POL'},
  { name: 'destination', required: true, label: 'Destination',
    field: 'destination', headerName: 'POD'},
  { name: 'firstTo', required: false, label: 'First To',
    field: 'firstTo', hide: true},
  { name: 'trans1To', required: false, label: 'Trans1',
    field: 'trans1To', hide: true},
  { name: 'trans1Flight', required: false, placeholder: 'Flight #',
    field: 'trans1Flight', hide: true},
  { name: 'trans2To', required: false, label: 'Trans2',
    field: 'trans2To', hide: true},
  { name: 'trans2Flight', required: false, placeholder: 'Flight #',
    field: 'trans2Flight', hide: true},
  { name: 'trans3To', required: false, label: 'Trans3',
    field: 'trans3To', hide: true},
  { name: 'trans3Flight', required: false, placeholder: 'Flight #',
    field: 'trans3Flight', hide: true},
  { name: 'issuingCarrier', label: 'Issuing Carrier',
    field: 'issuingCarrier', hide: true},
  { name: 'billingCarrier', label: 'Billing Carrier',
    field: 'billingCarrier', hide: true},
  { name: 'etaOfFpoeDate', label: 'ETA of FPOE', type: 'date',
    field: 'etaOfFpoeDate', hide: true},
  { name: 'etaOfFpoeTime', label: '',
    field: 'etaOfFpoeTime', hide: true},
  { name: 'finalEtaDate', label: 'Final ETA', type: 'date',
    field: 'finalEtaDate', hide: true},
  { name: 'finalEtaTime', label: '',
    field: 'finalEtaTime', hide: true},
  { name: 'jextra', required: false,
    field: 'jextra', hide: true},
];

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  const {data: rows, totalCount} = await api.craftListFlight(gridInfo);
  const rowsToUse = rows.map(({jextra, ...others}) => {
    const {etaTime: eta_time, etdTime: etd_time} = jextra;
    return {...others, eta_time, etd_time, ...jextra};
  });
  return {data: rowsToUse, totalCount};
}

function renderForm(formik, fields, errors) {
  const _r = (name) => renderField(formik, name, fields, errors);
  const _tp = (nameId, nameDisplay) => <TradePartnerSearchInput idField={nameId} displayField={nameDisplay} formik={formik} fields={fields} errors={errors} />;
  const _pt = (nameId, nameDisplay) => <PortSearchInput displayField={nameDisplay} idField={nameId} formik={formik} fields={fields} errors={errors} />
  return (
      <div className={'flex'}>
        <div className={'flex-1'}>
          {_tp('carrierId', 'carrier')}
          {_tp('issuingCarrierId', 'issuingCarrier')}
          {_r('craftNo')}
          {_tp('billingCarrierId', 'billingCarrier')}
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('etdDate')}</div>
            <div className={'flex-1 ml-8'}>{_r('etdTime')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('etaOfFpoeDate')}</div>
            <div className={'flex-1 ml-8'}>{_r('etaOfFpoeTime')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('etaDate')}</div>
            <div className={'flex-1 ml-8'}>{_r('etaTime')}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r('finalEtaDate')}</div>
            <div className={'flex-1 ml-8'}>{_r('finalEtaTime')}</div>
          </div>
          {_pt('pod', 'departure')}
          {_pt('firstToId', 'firstTo')}
          <div className={'flex'}>
            <div className={'flex-3'}>{_pt('trans1ToId', 'trans1To')}</div>
            <div className={'flex-1 ml-8'}>{_r('trans1Flight')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_pt('trans2ToId', 'trans2To')}</div>
            <div className={'flex-1 ml-8'}>{_r('trans2Flight')}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_pt('trans3ToId', 'trans3To')}</div>
            <div className={'flex-1 ml-8'}>{_r('trans3Flight')}</div>
          </div>
          {_pt('destinationId', 'destination')}
        </div>
      </div>
  );
}

function isActionDisabled(type: DataGridActionType, data: Object) {
  if (type === 'addChild') return data['isair'] === true;
}

export default FlightList;
