/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from '../../components/DataGridView';
import {apiAccount, util} from '../../services/service';

export default function useAccountDcnoteGridView(cargoType, detailModal) {
  const queryInfo = React.useRef();
  function openDetailModal(data) {
    const {partner_id: partnerId, currency} = data;
    const {fromDate, toDate} = queryInfo.current ?? {};
    detailModal.open({partnerId, fromDate, toDate, currency});
  }
  return useDataGridView({
    async onAction(action, data) {
      if (action === 'detail') {
        openDetailModal(data);
      }
    },
    async onQuery(gridInfo) {
      queryInfo.current = gridInfo;
      return apiAccount.dcnoteList(gridInfo);
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      // if (headerName !== 'Actions') {
      // }
    },
    name: `DcnoteList_${cargoType}`,
    label: 'Dcnote List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: cargoType},
    menuLabel: {label: 'List Dcnote'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['detail'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    initialSearchOneWeek: true,
    columns: [
      {field: 'partner_id', hide: true},
      {field: 'partner_name', headerName: 'Name', flex: 1},
      {field: 'phones', headerName: 'Phone #', flex: 1},
      {field: 'currency', headerName: 'Currency', width: 140},
      {field: 'debit_amount', headerName: 'Debit', valueFormatter: util.currencyFormatter, width: 140},
      {field: 'credit_amount', headerName: 'Credit', valueFormatter: util.currencyFormatter, width: 140},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 140},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 140}
    ],
  });
}

function scheduleFormatter(p) {
  return p.value ? p.value.replace(/\'/g, '').toUpperCase() : '';
}
