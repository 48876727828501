/**
 * @flow
 */
import React from 'react';
import {api, util} from '../services/service';
import {useSimpleGridModal} from '../components/SimpleModal';

export default function useCraftContainerModal(onPrint) {
  const [queryData, setQueryData] = React.useState();
  function getButtons() {
    return [
      {label: 'SD Print', color: 'primary', onClick: () => onPrint(queryData, 'print')},
      {label: 'SD Excel', color: 'primary', onClick: () => onPrint(queryData, 'excel')},
      {
        label: 'Link HB/L',
        color: 'primary',
        onClick: async () => {
          const rows = modal.grid.apiRef.current.getSelectedRows();
          let houses = [];
          rows.forEach((row) => {
            houses.push(row.bl_id);
          });
          const {container_no: containerNo, filing_no: filingNo} = queryData ?? {};
          const res = await api.craftLinkHBL(filingNo, containerNo  , houses);
          if (res) {
            util.showSuccess('HB/L has been linked successfully!');
          }
        },
      },
    ];
  }
  // 에디트 모드인 경우 HBL 필드를 변경할수 없다!
  const {container_no: containerNo, filing_no: filingNo} = queryData ?? {};
  const modal = useSimpleGridModal({
    title: `HB/Ls IN CONTAINER ( ${containerNo} )`,
    centered: true,
    width: 860,
    buttons: getButtons(),
    gridProps: {
      columns: [
        {field: 'bl_id', hide: true},
        {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
        {field: 'bl_no', headerName: 'HB/L', flex: 1, cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true)},
        {field: 'customer', headerName: 'Customer', flex: 1},
        {field: 'weight_kg', headerName: 'Weight', valueFormatter: util.formatWeight, flex: 1},
        {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, flex: 1},
        {field: 'packages', headerName: 'Packages', flex: 1},
        {field: 'seq_no', headerName: 'SEQ No.', editable: true, width: 100},
      ],
      onAction: async (action, data) => {
        if (action === 'delete') {
          util.showConfirm('Are you sure to delete?', async () => {
            const {bl_id} = data;
            const res = await api.delContainerHBL(bl_id, containerNo);
            if (res) {
              util.showSuccess('HB/L Link has been deleted successfully!');
            }
          });
        }
      },
      actions: [],
      actionWidth: 50,
      rows: [],
      height: 350,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        onCellValueChanged(e) {
          const blId = e.data.bl_id;
          const seqNo = e.data.seq_no
          api.setSeqNo(blId, parseInt(seqNo)).then((res) => {});
        }
      }
    },
  });
  React.useEffect(() => {
    if (modal.isOpen) {
      api.craftContainerHBLList(filingNo, containerNo).then(res => {
        const {data: rows} = res;
        modal.setRows(rows);
        modal.grid.apiRef.current.forEachNode((node, index) => {
          if(node.data.container_no.includes(containerNo)) {
            node.setSelected(true);
          }
        });
      });
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setQueryData(data);
      modal.open();
    },
  };
}
