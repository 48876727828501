/**
 * @flow
 */
import React from 'react';
import useWorkOrderGridView from '../../grids/BL/useWorkOrderGridView';
import {DefaultButton, PrimaryButton} from '../../components/buttons';
import {api, util} from '../../services/service';
import BLWOSearchFormManager from '../../forms/bl/BLWOSearchForm';
import SearchPane from '../../components/SearchPane';
import type {QueryListParams} from '../../services/API';

const searchForm = new BLWOSearchFormManager();

const WorkOrderList = () => {
  const {gridView, onAddClick, onDownload} = useWorkOrderList();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={() => {searchForm.onSearch()}} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'ADD WORK ORDER'} onClick={onAddClick} />
          <DefaultButton label={'EXPORT CSV'} onClick={onDownload} />
        </div>
        {gridView.render()}
      </div>
    </div>
  );
};

function useWorkOrderList() {
  const apiRef = React.useRef();
  const {blNo, branchId, kind} = util.getQS();
  const gridView = useWorkOrderGridView((params) => {apiRef.current = params.api});
  // const gridInfo = util.getGridInfo(gridView.props.name);
  let gridInfo = {page: 1, rowCount: 20, qryText: ''};
  const onAddClick = () => {
    // util.openTab(`/admin/schedule/workorder/set`);
    const blQuery = blNo ? `?blNo=${blNo}` : '';
    util.nav(`/admin/schedule/workorder/set${blQuery}`);
  };
  searchForm.onSearch = () => {
    const {kind, workType, branchId} = searchForm.getValues();
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      kind, workType, branchId
    };
    gridView.query(listParam);
  };
  searchForm.onInitialSearch = () => {
    // const {blNo, branchId, kind} = util.getQS();
    if (blNo) {
      gridView.query({qryText: blNo, page: 1});
    } else {
      searchForm.onSearch();
    }
  };

  const onDownload = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: `${util.formatD(gridInfo.fromDate)}.wo.csv`,
        columnKeys: gridView.props.columns.filter((c, index) => index > 0).map(c => c.field),
        customHeader: util.formatD(gridInfo.fromDate),
      });
    }
  };

  return {gridView, onAddClick, onDownload};
}

export default WorkOrderList;
