/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import DayScheduleAddFormManager from '../../forms/schedule/DayScheduleAddFormManager';
import {util} from '../../services/service';

const editForm = new DayScheduleAddFormManager();

export function useDayScheduleAddModal() {
  const [gridModal, setGridModal] = React.useState();
  const [savedScheduleListIds, setSavedScheduleListIds] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState();
  const [gridRows, setGridRows] = React.useState();

  const onAdd = (_, data) => {
    const newData = {
      address: data.address ?? '',
      city: data.city ?? '',
      pack_count: data.pack_count ?? '',
      phone: data.phone ?? '',
      remark: data.remark ?? '',
      work_time: data.work_time ?? '',
      work_type: data.work_type ?? '',
      workplace: data.workplace ?? '',
    };
    if (!(newData.work_type && newData.workplace && newData.city)) {
      return util.showWarning('Please enter required fields.');
    }
    modal.close();
    const values = [{
      ...newData,
      id: 0,
      bl_id: 0,
      in_out: '',
      kind: 'C',
      bl_no: '',
      branch_id: 0,
      branch: '',
      work_date: selectedDate,
      pic: '',
      workplace_id: 0,
      cbm: '',
      cbm_offer: '',
      h_m: 'H',
      amount: 0,
      balance: 0,
      paid_cbm: '',
      filing_no: '',
      cbm_tape: '',
      carrier_type: 'G',
      dropzone: '',
      tape: '',
    }];
    const rows = [
      ...values,
      ...gridModal.grid.rows,
    ];
    const newRows = rows.map((data, index) => ({...data, index}));
    gridModal.setRows(newRows);
    gridRows.current = newRows;
    if (gridModal.grid.rows?.length > 0) {
      setTimeout(() => {
        gridModal.grid.apiRef.current.forEachNode((node) => node.setSelected(savedScheduleListIds?.includes(node.data.workplace_id)));
      }, 0);
    }
  };
  const modal = useSimpleEditModal({
    title: 'ADD DAY SCHEDULE',
    centered: true,
    width: 700,
    form: editForm,
    onSave: onAdd,
  });
  const open = (gridModal, savedIds, selectedDate, rowsRef) => {
    setGridModal(gridModal);
    setSavedScheduleListIds(savedIds);
    setSelectedDate(selectedDate);
    setGridRows(rowsRef);
    modal.open();
  };
  return {...modal, open};
}
