/**
 * @flow
 */
import React from 'react';
import {Modal, Button, ButtonToolbar} from 'reactstrap';

export interface AlertProps {
  title: string;
  message: string;
  open: boolean;
  color?: string;
  close: () => void;
  onConfirm?: () => void;
}

const Alert = (props: AlertProps) => {
  const {color = 'default', title, message, rtl = {direction: 'ltr'}} = props;
  // const Icon = getIcon(color);
  const modalClass = 'modal-dialog--header';
  return (
    <Modal
      isOpen={props.open}
      modalClassName={`${rtl.direction}-support`}
      className={`modal-dialog--${color} ${modalClass}`}
      backdrop={'static'}
      keyboard={false}
      centered
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => props.close()} />
        {/*{header ? '' : Icon}*/}
        <h4 className="text-modal  modal__title">{title}</h4>
      </div>
      <div className="modal__body">
        {message}
      </div>
      <ButtonToolbar className="modal__footer">
        {props.onConfirm && <Button className="modal_cancel" onClick={() => props.close()}>Cancel</Button>}
        {props.onConfirm && ' '}
        <Button className="modal_ok" color={color} onClick={() => {props.onConfirm && props.onConfirm(); props.close()}}>OK</Button>
      </ButtonToolbar>
    </Modal>
  );
};

export function useAlert() {
  const [state, setState] = React.useState<AlertProps>({
    open: false,
    close: () => setState({...state, open: false}),
  });
  const show = (title, message, color = 'primary') => {
    setState({...state, title, message, open: true, color, onConfirm: null});
  }
  const render = () => <Alert {...state} />;
  return {state, show, render};
}

export function useConfirm() {
  const [state, setState] = React.useState<AlertProps>({
    open: false,
    close: () => setState({...state, open: false}),
  });
  const show = (title, message, onConfirm: () => void, color = 'primary') => {
    setState({...state, title, message, open: true, color, onConfirm});
  }
  const render = () => <Alert {...state} />;
  return {state, show, render};
}

// function getIcon(color) {
//   switch (color) {
//     case 'primary':
//       return <span className="lnr lnr-pushpin modal__title-icon"/>;
//     case 'success':
//       return <span className="lnr lnr-thumbs-up modal__title-icon"/>;
//     case 'warning':
//       return <span className="lnr lnr-flag modal__title-icon"/>;
//     case 'danger':
//       return <span className="lnr lnr-cross-circle modal__title-icon"/>;
//     default:
//       break;
//   }
// }
//
export default Alert;
