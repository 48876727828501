/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../lib/FormManager';
import type {FormField} from '../components/Form';
import {standaloneLabel} from './helper';
import {api, apiBL, util} from '../services/service';
import {useUser} from '../redux/reducers/userReducer';
import {
  FlightSearchInput,
  PortSearchInput,
  TradePartnerSearchInput,
  VslVoySearchInput
} from '../components/SearchInput';
import {FD, FN, SN, incotermsAirCourierOptions, incotermsOptions, freightTypeOptions, packageTypeOptions, pickupTypeOptions,
  shipModeOptions, salesTypeOptions, svcTypeOptions, svcTypeOptions2, orgBlTypeOptions} from './field-defs/bl';
import {useSimpleGrid} from '../components/SimpleGrid';
import {DefaultButton} from '../components/buttons';
import useBLBoxModal, {loadBoxList, loadHouseBoxList, useBLItemsModal} from '../modals/bl/useBLBoxModal';
import useBLMasterBoxModal from '../modals/bl/useBLMasterBoxModal';
import {useParams} from "react-router-dom";

export type ForwardingType = 'OEM' | 'OEH' | 'AEM' | 'AEH' | 'OIM' | 'OIH' | 'AIM' | 'AIH';

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class BLFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  forwardingType: ForwardingType;
  onTradeTypeChange: (tradeType: string, portType: string) => void;
  grid;
  onMawbDelete;
  onSave;
  workAssigned;
  isBranch;
  onSaveComment;
  onInventoryHistoryModal;
  constructor(type: ForwardingType = 'OEM') {
    super({
      prefix: `bl-form`,
      fields: [],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.forwardingType = type;
    this.fields = this.getFields();
  }
  getGridData() {
    let grid = this.grid;
    return grid.rows;
  }
  // setGridData() {
  //   const grid = this.grid;
  //   if (grid) {
  //     grid.setRows(this.getValue(FN.GRID_DATA));
  //   }
  // }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {getField: _f, getValue: _v, setValue: _c} = this;
    const params = useParams() ?? {};
    const currentId = _v(FN.ID);
    const user = useUser();
    const kind = _v(FN.KIND);
    const branchId = _v(FN.BRANCH_ID);
    const tradeType = _v(FN.TRADE_TYPE) ?? 'E'; // 기본값은 Export(E)
    const portType = _v(FN.PORT_TYPE) ?? 'O'; // 기본값은 Ocean(O)
    const isHouse = this.forwardingType.endsWith('H');
    const isHBLEditable = kind === 'U' || kind === 'P' || kind === 'F' || kind === 'C' || kind === 'E' || kind === 'M' || kind === 'V';
    const priceLock = _v(FN.PRICE_LOCK) === 'Y';
    const branchField = _f(FN.BRANCH_ID);
    const pickupType = _v(FN.PICKUP_TYPE);
    const isImport = tradeType === 'I';
    const [masterOptions, setMasterOptions] = React.useState();
    const [readyForComingMaster, setReadyForComingMaster] = React.useState(false);
    const {filingNo: masterFilingNo, masterNo: masterNoParam, id} = util.getQS(); // 마스터 에서 <New House> 버튼 클릭시 마스터의 파일링 넘버 또는 마스터 번호가 url 패러미터로 넘어옴
    const packageType = _v(FN.PACKAGE_TYPE);
    const freightType = _v(FN.FREIGHT);
    const otherChargeType = _v(FN.OTHER_CHARGE);
    const shipMode = _v(FN.SHIP_MODE);
    const salesType = _v(FN.SALES_TYPE);
    const svcTerm1 = _v(FN.SVC_TERM1);
    const svcTerm2 = _v(FN.SVC_TERM2);
    const blCurrency = _v(FN.BL_CURRENCY);
    const orgBLType = _v(FN.ORIGINAL_BL_TYPE);
    const inventoryField = _f(FN.INVENTORY);
    const inventory = _v(FN.INVENTORY);
    branchField.options = api.useAreaOptions(true);
    inventoryField.options = api.useInventoryOptions(branchId, true, currentId);
    React.useEffect(() => {
      inventoryField.onChange = (name, values) => {
        _c(FN.INVENTORY, values);
      }
    }, [inventory]);
    React.useEffect(() => {
      if (!branchId && user.id > 0 && branchField.options.length > 0 && !params.id) {
        _c(FN.BRANCH_ID, user.branch_id);
      }
      // if (kind && user.id > 0) { // 서비스가 설정되어 있지 않으면 로그인한 사용자의 팀에 따라 서비스의 기본값을 변경해줌
      const {id, masterNo} = util.getQS(); // 쿼리 패러미터에 id, masterNo 가 존재하면 복사를 하는 경우이므로 자동 세팅을 하지 않음!
      // if (user.id > 0 && !currentId && !id && !masterNo) { // 편집 모드일 경우 Kind 변경해 주니 kind 가 자등으로 service 값으로 변경됨
      if (user.id > 0 && !id && !masterNo) { // 편집 모드일 경우 Kind 변경해 주니 kind 가 자등으로 service 값으로 변경됨
        const team = user['team_main'];
        // 팀이 'A' 인 경우 Moving 을 기본값으로 설정, 그렇지 않은 경우 팀의 해당 서비스를 설정
        const service = (team === 'N' || team === 'S' || team === 'A') ? 'M' : team;
       if(!params.id) {
         _c(FN.KIND, kind ?? service);
       }
        setTimeout(() => setReadyForComingMaster(true), 100);
      }
      // if (!_v(FN.PORT_TYPE) && user.id > 0) {
      //   if (kind === 'M' || kind === 'V' || kind === 'F') { // Moving/Vehicle/Forwarding 은 Ocean 이 기본값
      //     _c(FN.PORT_TYPE, 'O');
      //   } else {
      //     _c(FN.PORT_TYPE, 'A');
      //   }
      // }
    }, [branchId, user.id, branchField.options]);
    React.useEffect(() => {
      this.onTradeTypeChange && this.onTradeTypeChange(tradeType, portType)
    }, [tradeType, portType]);
    React.useEffect(() => {
      if (kind === 'F' && !_v(FN.NOTIFY_ADDR)) {
        // Forwarding 의 경우 notify 자동 설정
        _c(FN.NOTIFY_ADDR, 'SAME AS CONSIGNEE');
      }
      if (!(currentId || masterFilingNo || masterNoParam || id)) { // 편집 모드인 경우 변경하지 않음, 또는 마스터에서 넘어온 경우 변경해 주면 안됨
        if (kind === 'M' || kind === 'V' || kind === 'F' || kind === 'E') { // Moving/Vehicle/Forwarding/eCommerce 은 Ocean 이 기본값
          _c(FN.PORT_TYPE, 'O');
        } else {
          _c(FN.PORT_TYPE, 'A');
        }
        if(kind === 'E') {
          _c(FN.IS_GROUND, true);
        } else {
          _c(FN.IS_GROUND, false);
        }
      }
    }, [kind]);
    if(!pickupType) {
      _c(FN.PICKUP_TYPE, pickupTypeOptions[1].value);
    }

    React.useEffect(() => {
      _c(FN.PICKUP_TYPE, pickupType);
    }, [pickupType]);
    React.useEffect(() => {
      if(this.forwardingType === 'OEM') {
        _f(FN.CUSTOMER).required = false;
      } else {
        _f(FN.CUSTOMER).required = true;
      }
    }, []);
    React.useEffect(() => {
      if(!packageType) {
        _c(FN.PACKAGE_TYPE, packageTypeOptions[0].value);
      }
    }, [packageType]);
    React.useEffect(() => {
      if(!freightType) {
        _c(FN.FREIGHT, freightTypeOptions[0].value);
      }
    }, [freightType]);
    React.useEffect(() => {
      if(!otherChargeType) {
        _c(FN.OTHER_CHARGE, freightTypeOptions[0].value);
      }
    }, [otherChargeType]);
    React.useEffect(() => {
      if(!shipMode) {
        _c(FN.SHIP_MODE, shipModeOptions[0].value);
      }
    }, [shipMode]);
    React.useEffect(() => {
      if(!salesType) {
        _c(FN.SALES_TYPE, salesTypeOptions[0].value);
      }
    }, [salesType]);
    React.useEffect(() => {
      if(!svcTerm1) {
        _c(FN.SVC_TERM1, svcTypeOptions[0].value);
      }
    }, [svcTerm1]);
    React.useEffect(() => {
      if(!svcTerm2) {
        _c(FN.SVC_TERM2, svcTypeOptions2[0].value);
      }
    }, [svcTerm2]);
    React.useEffect(() => {
      if(!blCurrency) {
        setTimeout(() => {
          _c(FN.BL_CURRENCY, 'USD');
        }, 0);
      } else {
        _c(FN.BL_CURRENCY, blCurrency);
      }
    }, [blCurrency]);
    React.useEffect(() => {
      if(!blCurrency) {
        _c(FN.BL_CURRENCY, 'USD');
      }
      if(this.forwardingType === 'OEM') {
        _c(FN.BL_CURRENCY, 'USD');
      } else {
        _c(FN.BL_CURRENCY, 'CAD');
      }
    }, [this.forwardingType]);
    React.useEffect(() => {
      this.resetRequired(_f);
      if(this.forwardingType === 'AEH' || this.forwardingType === 'OEH') {
        _f(FN.P_DATE).required = true;
        _f(FN.TOTAL_PRICE).required = true;
        _f(FN.CUSTOMER_ADDR).required = true;
        _f(FN.SHIPPER).required = true;
        _f(FN.SHIPPER_LOCAL).required = true;
        _f(FN.SHIPPER_ADDR).required = true;
        _f(FN.SHIPPER_PHONE).required = true;
        _f(FN.CONSIGNEE_LOCAL).required = true;
        _f(FN.CONSIGNEE_ADDR_LOCAL).required = true;
        _f(FN.PACKAGE).required = true;
      }
      if(this.forwardingType === 'AEH') {
        _f(FN.MAWB_NO).required = true;
        _f(FN.CONSIGNEE_POST).required = true;
        _f(FN.CUSTOMS_NO).required = true;
        _f(FN.GROSS_WEIGHT_KG).required = true;
        _f(FN.CHARGEABLE_WEIGHT_KG).required = true;
        _f(FN.VOLUME_WEIGHT_KG).required = true;
      } else if (this.forwardingType === 'OEH') {
        _f(FN.SHIPPER_PHONE2).required = true;
        _f(FN.CONSIGNEE).required = true;
        _f(FN.CONSIGNEE_PHONE1).required = true;
        _f(FN.CONSIGNEE_PHONE2).required = true;
        _f(FN.ENT_DATE).required = true;
        _f(FN.VISA_TYPE).required = true;
      }
    }, [this.forwardingType]);

    React.useEffect(() => {
      if(isHouse && isImport) {
        _f(FN.CONSIGNEE).required = true;
        _f(FN.CONSIGNEE_LOCAL).required = true;
      }
    }, [this.forwardingType]);

    // _f(FN.CONSIGNEE).required = isHouse && isImport;
    // _f(FN.CONSIGNEE_LOCAL).required = isHouse && isImport;

    // if (isHouse) {
    //   _f(FN.TRADE_TYPE).disabled = !!currentId;
    //   _f(FN.PORT_TYPE).disabled = !!currentId;
    // } else {
    //   _f(FN.TRADE_TYPE).disabled = !!currentId;
    //   _f(FN.PORT_TYPE).disabled = !!currentId;
    // }
    React.useEffect(() => {
      if (masterFilingNo || masterNoParam) {
        _f(FN.TRADE_TYPE).disabled = true;
        _f(FN.PORT_TYPE).disabled = true;
      }
    }, []);

    // house bl에서 carrier type 변경시 filingNo, mawbNo, mblNo 리셋
    React.useEffect(() => {
      _f(FN.PORT_TYPE).onChange = () => {
        if (params.id) {
          util.showWarning('Old FLING NO, MAWB NO and MBL NO will be removed when you save!');
        }
        _c(FN.FILING_NO, undefined);
        _c(FN.MAWB_NO, undefined);
        _c(FN.MBL_NO, undefined);
      }
    }, [portType]);

    // 자동 무게 계산
    if (portType === 'O') {
      const lb = 2.20462;
      const gwkg = _v(FN.GROSS_WEIGHT_KG);
      const gwlb = _v(FN.GROSS_WEIGHT_LB);
      const vwkg = _v(FN.VOLUME_WEIGHT_KG);
      _f(FN.GROSS_WEIGHT_KG).onBlur = () => {
        if (gwkg) {
          _c(FN.GROSS_WEIGHT_LB, (gwkg * lb).toFixed(2));
          if(tradeType === 'E') {
            _c(FN.CHARGEABLE_WEIGHT_KG, util.toFloat(gwkg) >= util.toFloat(vwkg) ? gwkg : vwkg);
          }
        }
      }
      _f(FN.GROSS_WEIGHT_LB).onBlur = () => {
        if (!gwkg && gwlb) {
          _c(FN.GROSS_WEIGHT_KG, (gwlb / lb).toFixed(2));
        }
      }
      _f(FN.VOLUME_WEIGHT_KG).onBlur = () => {
        if (vwkg && tradeType === 'E') {
          _c(FN.CHARGEABLE_WEIGHT_KG, util.toFloat(gwkg) >= util.toFloat(vwkg) ? gwkg : vwkg);
        }
      }
    }

    // _f(FN.MBL_NO).disabled = isHouse;
    // _f(FN.MAWB_NO).disabled = isHouse;
    _f(FN.HBL_NO).disabled = (currentId > 0 && !isHBLEditable) || (isHouse && !isHBLEditable); // 하우스 BL 자동생성됨, FORWARDING/UPS/C.POST 인경우는 편집가능
    _f(FN.HAWB_NO).disabled = (currentId > 0 && !isHBLEditable) || (isHouse && !isHBLEditable); // 하우스 BL 자동생성됨, FORWARDING/UPS/C.POST 인경우는 편집가능
    _f(FN.TOTAL_PRICE).disabled = priceLock; // priceLock === 'Y' then disabled
    _f(FN.CURRENCY).disabled = priceLock; // priceLock === 'Y' then disabled
    _f(FN.INCOTERMS).options = kind === 'C' && portType === 'A' ? incotermsAirCourierOptions : incotermsOptions; // 항공 택배의 경우 다른 incoterms 옵션값을 사용
    if(this.forwardingType === 'OEH'|| this.forwardingType === 'AEH' || this.forwardingType === 'OIH' || this.forwardingType === 'AIH') {
      _f(FN.PARTNER).label = '';
      _f(FN.CUSTOMER).label = '';
      _f(FN.SHIPPER).label = '';
      _f(FN.CONSIGNEE).label = '';
    }
    this.useMasterOptions(portType, tradeType, branchId, isHouse, masterOptions, setMasterOptions, readyForComingMaster);
    this.useRate(); // Buying, Selling Rate 자동 설정
    this.useCustomer(); // Moving, Vehicle, Courier 인 경우 Customer, Shipper, Consignee, Notify 자동 설정
    this.useConsignee(); // Consignee 관련 설정
    this.useAirVessel(isHouse);
    const grid = useBoxGrid(this, isHouse);
    this.grid = grid;
    switch (this.forwardingType) {
      case 'OEM': return  <OEM {...this.getRenderProps()} />;
      case 'OEH': return  <OEH {...this.getRenderProps(grid)} />;
      case 'AEM': return  <AEM {...this.getRenderProps(grid)} />;
      case 'AEH': return  <AEH {...this.getRenderProps(grid, masterOptions, setMasterOptions)} />;
      case 'OIM': return  <OIM {...this.getRenderProps()} />;
      case 'OIH': return  <OIH {...this.getRenderProps(grid)} />;
      case 'AIM': return  <AIM {...this.getRenderProps()} />;
      case 'AIH': return  <AIH {...this.getRenderProps(grid)} />;
      default:
        return null;
    }
  };
  onValidate = (values) => {
    return values; // TODO: 밸리데이션이 필요한 경우 여기서 구현함
  };
  getFields() {
    const fields: FormField[] = [];
    for (const key in FD) {
      if (FD.hasOwnProperty(key)) {
        fields.push(FD[key]);
      }
    }
    return fields.map(i => ({...i, name: `${this.prefix}-${i.name}`}));
  }
  setWorkAssigned(workAssigned) {
    this.workAssigned = workAssigned;
  }
  getRenderProps = (grid, masterOptions, setMasterOptions) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const onCopyShipmentFromMaster = async () => {
      // 마스터 BL 에서 쉽먼트 정보를 읽어와서 하우스에 복사해줌
      const masterNo = _v(FN.MBL_NO) ?? _v(FN.MAWB_NO);
      if (masterNo) {
        const {data} = await api.forwardViewBLEntry({blNo: masterNo});
        if (data?.length === 1) {
          const {jshipment} = data[0];
          // 마스터에서 복사할 데이터 키를 여기에 정의
          // NOTE: 마스터키와 하우스키가 다른 경우 [masterKey, houseKey] 형태로 정의
          const keys = [
            FN.GROSS_WEIGHT_KG,
            FN.CHARGEABLE_WEIGHT_KG,
            FN.VOLUME_WEIGHT_KG,
            FN.GROSS_WEIGHT_LB,
            FN.CHARGEABLE_WEIGHT_LB,
            FN.VOLUME_WEIGHT_LB,
            FN.CBM,
            FN.COMMODITY,
            FN.PACKAGE,
            FN.PACKAGE_TYPE,
            FN.SHIP_MODE,
            FN.SVC_TERM1,
            FN.SVC_TERM2,
            FN.SALES_TYPE,
            FN.SHIP_MODE,
          ];
          util.copyFromKeys(keys, jshipment, this); // 마스터 jshipment 데이터에서 정의된 키 값을 하우스 폼에 복사함
        }
      }
    };
    const onCopyShipmentFromHouse = async () => {
      const filingNo = _v(FN.FILING_NO);
      if (filingNo) {
        const {data} = await apiBL.getSumHouseShipData(filingNo);
        if (data?.length === 1 && data[0]['retval']) {
          // const {
          //   cbm, chargeable_weight_kg, commodity, gross_weight_kg, package: packageCount, package_type, volume_weight_kg
          // } = data[0]['retval'];
          const jdata = data[0]['retval'];
          const keys = [
            ['gross_weight_kg', FN.GROSS_WEIGHT_KG],
            ['chargeable_weight_kg', FN.CHARGEABLE_WEIGHT_KG],
            ['volume_weight_kg', FN.VOLUME_WEIGHT_KG],
            FN.CBM,
            FN.COMMODITY,
            FN.PACKAGE,
            ['package_type', FN.PACKAGE_TYPE],
          ];
          util.copyFromKeys(keys, jdata, this);
        }
      }
    };
    const onMawbDelete = async () => {
      await this.onMawbDelete();
      const branchId = _v(FN.BRANCH_ID);
      const bound = _v(FN.TRADE_TYPE) ?? 'E'; // 기본값은 Export(E)
      const carrierType = _v(FN.PORT_TYPE) ?? 'O'; // 기본값은 Ocean(O)
      const kind = _v(FN.KIND);
      const {data: {lists = []}} = await apiBL.getMasterList({carrierType, bound, branchId, kind});
      const options = [];
      for (const [masterNo, filingNo] of lists) {
        if (masterNo) {
          options.push({value: masterNo, label: masterNo, filingNo, masterNo});
        }
      }
      setMasterOptions([{value: '', label: ''}, ...options]);
    };
    const onInventoryHistoryModal = (e) => {
      e.preventDefault();
      this.onInventoryHistoryModal();
    };
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this,
      onCopyShipmentFromMaster, onCopyShipmentFromHouse, onMawbDelete, onSave: this.onSave,
      workAssigned: this.workAssigned, isBranch: this.isBranch, onSaveComment: this.onSaveComment,
      onInventoryHistoryModal};
  };
  useMasterOptions = (carrierType = 'O', bound = 'E', branchId, isHouse, masterOptions, setMasterOptions, readyForComingMaster) => {
    const {getField: _f, getValue: _v, setValue: _c} = this;
    const filingNo = _v(FN.FILING_NO);
    let mblNo = _v(FN.MBL_NO);
    const mawbNo = _v(FN.MAWB_NO);
    const isAir = carrierType === 'A';
    const kind = _v(FN.KIND);
    React.useEffect(() => {
      if (!readyForComingMaster) {
        return;
      }
      if (isHouse) {
        let options = [];
        if (bound && carrierType && branchId) {
          apiBL.getMasterList({carrierType, bound, branchId, kind}).then((res) => {
            const {data: {lists = []}} = res;
            for (const [masterNo, filingNo] of lists) {
              if (masterNo) {
                options.push({value: masterNo, label: masterNo, filingNo, masterNo});
              }
            }
            setMasterOptions([{value: '', label: ''}, ...options]);
          });
        } else {
          setMasterOptions(undefined);
        }
      } else {
        setMasterOptions(undefined);
      }
    }, [carrierType, bound, branchId, isHouse, readyForComingMaster, kind]);
    React.useEffect(() => {
      if (isHouse) {
        const mNo = mblNo ? mblNo : mawbNo;
        if (mNo && masterOptions) {
          const optionIndex = masterOptions.findIndex(i => i.value === mNo);
          let newOptions = [...masterOptions];
          if (optionIndex < 0) {
            newOptions.shift();
            newOptions = [{value: mNo, label: mNo, filingNo, masterNo: mNo}, ...newOptions];
            setMasterOptions(newOptions);
            setTimeout(() => {
              _c(isAir ? FN.MAWB_NO : FN.MBL_NO, mNo);
            }, 0);
          } else {
            if (newOptions[0].label === '' && newOptions[0].value === '') {
              newOptions.shift();
              setMasterOptions(newOptions);
            }
            setTimeout(() => {
              _c(isAir ? FN.MAWB_NO : FN.MBL_NO, mNo);
            }, 0);
          }
        }
      }
    }, [mblNo, mawbNo, masterOptions]);
    _f(FN.MBL_NO).options = masterOptions;
    _f(FN.MAWB_NO).options = masterOptions;
    React.useEffect(() => {
      if (isHouse && masterOptions) {
        // this.resetForm();
        if (mblNo) {
          const option = masterOptions.find(i => i.value === mblNo);
          if (option) {
            const selectedFilingNo = option.filingNo;
            _c(FN.FILING_NO, selectedFilingNo);
            // this.useAirVessel(carrierType, option.masterNo);
          }
        } else {
          //_c(FN.FILING_NO, undefined); // NOTE: filingNo 가 존재하는 경우도 있기 때문에 아무것도 하지 않음!
        }
        if (mawbNo) {
          const option = masterOptions.find(i => i.value === mawbNo);
          if (option) {
            const selectedFilingNo = option.filingNo;
            _c(FN.FILING_NO, selectedFilingNo);
            // this.useAirVessel(carrierType, option.masterNo);
          }
        } else {
          //_c(FN.FILING_NO, undefined); // NOTE: filingNo 가 존재하는 경우도 있기 때문에 아무것도 하지 않음!
        }
      }
    }, [isHouse, mblNo, mawbNo, masterOptions]);
  };
  useAirVessel = (isHouse) => {
    const [ready, setReady] = React.useState(false);
    const {setValue: _c, getValue: _v} = this;
    const blNo = _v(FN.MBL_NO) ?? _v(FN.MAWB_NO);
    const carrierType = _v(FN.PORT_TYPE) ?? 'O'; // 기본값은 Ocean(O)
    React.useEffect(() => {
      if (!isHouse) {
        return;
      }
      if (!ready) {
        setTimeout(() => setReady(true), 1000);
        return;
      }
      if (blNo) {
        apiBL.getCraftInfo(blNo).then(res => {
          const data = res.data[0];
          if(data) {
            _c(FN.CARRIER_ID, data.carrier_id);
            _c(FN.CARRIER, data.jextra.carrier);
            _c(FN.FILING_NO, data.filing_no);
            if(carrierType === 'A') {
              _c(FN.FLIGHT_NO, data.vol_voy);
              _c(FN.FLIGHT_DATE, data.jextra.etdDate);
              _c(FN.FLIGHT_TIME, data.jextra.etdTime);
              _c(FN.ARRIVAL_DATE, data.jextra.etaDate);
              _c(FN.ARRIVAL_TIME, data.jextra.etaTime);
              _c(FN.DEPARTURE, data.pol);
              _c(FN.DESTINATION, data.pod);
              _c(FN.ETD_DATE, data.jextra.etdDate);
              _c(FN.ETD_TIME, data.jextra.etdTime);
              _c(FN.ETA_DATE, data.jextra.etaDate);
              _c(FN.ETA_TIME, data.jextra.etaTime);
              _c(FN.CUSTOMER_ETD, data.jextra.etdDate);
              _c(FN.CUSTOMER_ETA, data.jextra.etaDate);
            } else {
              _c(FN.VSL_VOY, data.vol_voy);
              _c(FN.ETD, data.etd);
              _c(FN.ETA, data.eta);
              _c(FN.POL, data.pol);
              _c(FN.POD, data.pod);
              _c(FN.ETD_POL, data.etd);
              _c(FN.ETA_POD, data.eta);
              _c(FN.POL_ETD, data.etd);
              _c(FN.POD_ETA, data.eta);
              _c(FN.ETD_DATE, data.etd);
              _c(FN.ETA_DATE, data.eta);
              _c(FN.CUSTOMER_ETD, data.etd);
              _c(FN.CUSTOMER_ETA, data.eta);
            }
          }
        });
      }
    }, [blNo, isHouse]);
  };
  resetForm = () => {
    const {setValue: _c} = this;
    _c(FN.FILING_NO, '');
    _c(FN.CARRIER_ID, '');
    _c(FN.CARRIER, '');
    _c(FN.FLIGHT_NO, '');
    _c(FN.FLIGHT_DATE, '');
    _c(FN.FLIGHT_TIME, '');
    _c(FN.ARRIVAL_DATE, '');
    _c(FN.ARRIVAL_TIME, '');
    _c(FN.DEPARTURE, '');
    _c(FN.DESTINATION, '');
    _c(FN.VSL_VOY, '');
    _c(FN.ETD, '');
    _c(FN.ETA, '');
    _c(FN.POL, '');
    _c(FN.POD, '');
  };
  useRate = () => {
    const {getValue: _v} = this;
    const buyingRate = _v(FN.BUYING_RATE);
    const sellingRate = _v(FN.SELLING_RATE);
    const cbm = _v(FN.CBM); // Ocean 인 경우 CBM 사용하여 계산
    const kg = _v(FN.GROSS_WEIGHT_KG); // Air 인 경우 KG 사용하여 계산
    React.useEffect(() => {
      switch (this.forwardingType) {
        case 'OEH':
          this.updateRate(FN.BUYING_RATE_1, buyingRate, cbm);
          break;
        case 'AEH':
          this.updateRate(FN.BUYING_RATE_1, buyingRate, kg);
          break;
        default:
          break;
      }
    }, [buyingRate, cbm, kg]);
    React.useEffect(() => {
      switch (this.forwardingType) {
        case 'OEH':
          this.updateRate(FN.SELLING_RATE_1, sellingRate, cbm);
          break;
        case 'AEH':
          this.updateRate(FN.SELLING_RATE_1, sellingRate, kg);
          break;
        default:
          break;
      }
    }, [sellingRate, cbm, kg]);
  };
  updateRate(fieldName, v1, v2) {
    if (v1 && v2) {
      const rate = v1 * v2;
      if (!isNaN(rate)) {
        this.setValue(fieldName, rate.toFixed(2));
      } else {
        this.setValue(fieldName, '');
      }
    } else {
      this.setValue(fieldName, '');
    }
  };
  useCustomer = () => {
    const blId = this.getValue(FN.ID);
    const customer = this.getValue(FN.CUSTOMER);
    const customerLocal = this.getValue(FN.CUSTOMER_LOCAL);
    const customerId = this.getValue(FN.CUSTOMER_ID);
    const customerAddr = this.getValue(FN.CUSTOMER_ADDR);
    const customerPhone = this.getValue(FN.CUSTOMER_PHONE);
    React.useEffect(() => {
      if (blId || util.getQS().id) {
        // BL id 가 설정된 상태면 자동 업데이트를 하지 않음 (에디트 모드)
        return;
      }
      switch (this.forwardingType) {
        case 'OEH':
        case 'AEH':
          if (customer && customerId && customerAddr) {
            const kind = this.getValue(FN.KIND) ?? 'M';
            if (kind === 'M' || kind === 'V') {
              this.updateCustomer(FN.CONSIGNEE, customerId, customer, customerLocal, customerAddr);
              this.updateCustomer(FN.SHIPPER, customerId, customer, customerLocal, customerAddr, '', customerPhone);
              this.updateCustomer(FN.NOTIFY, customerId, customer, '', customerAddr);
            } else if (kind === 'C') {
              this.updateCustomer(FN.SHIPPER, customerId, customer, customerLocal, customerAddr, '', customerPhone);
            }
          }
          break;
        default:
          break;
      }

    }, [customer, customerId, customerAddr, customerPhone]);
  };
  updateCustomer = (fieldName, id, name, nameLocal, addr, addrLocal, phone) => {
    this.setValue(`${fieldName}Id`, id);
    this.setValue(fieldName, name);
    nameLocal && this.setValue(`${fieldName}Local`, nameLocal);
    this.setValue(`${fieldName}Addr`, addr);
    addrLocal && this.setValue(`${fieldName}AddrLocal`, addr);
    phone && this.setValue(`${fieldName}Phone`, phone);
  };
  useConsignee = () => {
    const kind = this.getValue(FN.KIND) ?? 'M';
    const useLocalAddr = (kind === 'C' || kind === 'M' || kind === 'V' || kind === 'E')
      && (this.forwardingType === 'OEH' || this.forwardingType === 'AEH');
    const addrField = this.getField(FN.CONSIGNEE_ADDR);
    const addrLocalField = this.getField(FN.CONSIGNEE_ADDR_LOCAL);
    addrField.smallMargin = useLocalAddr;
    addrField.placeholder = useLocalAddr ? 'English address' : '';
    addrLocalField.placeholder = useLocalAddr ? 'Local address' : '';
  };
  resetRequired = (_f) => {
    _f(FN.P_DATE).required = false;
    _f(FN.MAWB_NO).required = false;
    _f(FN.TOTAL_PRICE).required = false;
    _f(FN.CUSTOMER_ADDR).required = false;
    _f(FN.SHIPPER).required = false;
    _f(FN.SHIPPER_LOCAL).required = false;
    _f(FN.SHIPPER_ADDR).required = false;
    _f(FN.SHIPPER_PHONE).required = false;
    _f(FN.SHIPPER_PHONE2).required = false;
    _f(FN.CONSIGNEE).required = false;
    _f(FN.CONSIGNEE_LOCAL).required = false;
    _f(FN.CONSIGNEE_ADDR_LOCAL).required = false;
    _f(FN.CONSIGNEE_POST).required = false;
    _f(FN.CONSIGNEE_PHONE1).required = false;
    _f(FN.CONSIGNEE_PHONE2).required = false;
    _f(FN.CUSTOMS_NO).required = false;
    _f(FN.PACKAGE).required = false;
    _f(FN.GROSS_WEIGHT_KG).required = false;
    _f(FN.CHARGEABLE_WEIGHT_KG).required = false;
    _f(FN.VOLUME_WEIGHT_KG).required = false;
    _f(FN.ENT_DATE).required = false;
    _f(FN.VISA_TYPE).required = false;
  };
}

function useBoxGrid(blForm: BLFormManager, isHouse = true) {
  const blId = blForm.getValue(FN.ID);
  const kind = blForm.getValue(FN.KIND);
  const gridRef = React.useRef();
  const kindRef = React.useRef();
  const masterBoxModal = useBLMasterBoxModal(blForm, gridRef)
  const boxModal = useBLBoxModal(blForm, gridRef);
  const itemModal = useBLItemsModal(blForm);
  const grid = useSimpleGrid({
    columns: [
      {field: 'length', headerName: 'L. (cm)', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'width', headerName: 'W. (cm)', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'height', headerName: 'H. (cm)', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
      {field: 'pcs', headerName: 'PCS', width: 70, minWidth: 70},
      {field: 'cal_weight', headerName: 'V.W', valueFormatter: util.formatWeight, minWidth: 70, flex: 1},
      {field: 'box_cbm', headerName: 'CBM', valueFormatter: util.formatCBM, minWidth: 70, flex: 1},
    ],
    actions: ['delete'],
    actionWidth: 50,
    height: 200,
    className: 'mb-20 ml-8',
    onAction: (action, data) => {
      if (action === 'delete') {
        util.showConfirm('Are you sure to delete?', async () => {
          const blId = blForm.getValue(FN.ID);
          const res = await apiBL.delBLBox(blId, data['box_no']);
          if (res) {
            const rows = await loadBoxList(blId, gridRef);
            updateWeightAndCBM(rows, blForm, !isHouse);
          }
        });
      }
    },
    agGridProps: {
      onCellClicked(e) {
        if (e.colDef.headerName !== 'Actions') {
          if (isHouse) {
            boxModal.open(e.data);
          } else {
            masterBoxModal.open(e.data);
          }
        }
      }
    }
  });
  gridRef.current = grid;
  kindRef.current = kind;
  React.useEffect(() => {
    if (blId) {
      loadBoxList(blId, grid).catch();
    }
    return () => {
      grid.setRows([]);
    };
  }, [blId]);
  //shipment & item 박스 모달 닫힐때 자동 계산
  React.useEffect(() => {
    if (boxModal.isOpen === false) {
      loadBoxList(blId, grid).then((rows) => {
        //updateWeightAndCBM(rows, blForm, false);
      });
    }
  }, [boxModal.isOpen]);
  React.useEffect(() => {
    if (masterBoxModal.isOpen === false) {
      loadBoxList(blId, grid).then(rows => {
        //updateWeightAndCBM(rows, blForm, true);
      });
    }
  }, [masterBoxModal.isOpen]);
  React.useEffect(() => {
    if (itemModal.isOpen === false) {
      loadBoxList(blId, grid).then(rows => {
        //updateWeightAndCBM(rows, blForm, false);
      });
    }
  }, [itemModal.isOpen]);
  return {
    ...grid,
    render: () => {
      return (
        <>
          {grid.render()}
          {boxModal.render()}
          {itemModal.render()}
          {masterBoxModal.render()}
        </>
      );
    },
    onAddBox: () => {
      if (isHouse) {
        boxModal.open();
      } else {
        masterBoxModal.open();
      }
    },
    onAddECItems: () => {
      itemModal.open();
    },
    onSumHouses: async () => {
      const rows = await loadHouseBoxList(blForm.getValue(FN.ID), blForm.getValue(FN.FILING_NO), grid);
      updateWeightAndCBM(rows, blForm, true);
    },
  };
}

function updateWeightAndCBM(rows, blForm, isMaster = false) {
  const grossWeight = blForm.getValue('grossWeightKg');
  let weightTotal = 0;
  let cbmTotal = 0;
  for (const row of rows ?? []) {
    const {cal_weight: weight, box_cbm: cbm} = row;
    weightTotal += weight;
    cbmTotal += cbm;
  }
  weightTotal = weightTotal > 0 ? weightTotal.toFixed(1) : '';
  if (isMaster) {
    blForm.setValue('grossWeightKg', weightTotal);
    blForm.setValue('chargeableWeightKg', weightTotal);
    blForm.setValue('blGrossWeightKg', weightTotal);
    blForm.setValue('blChargeableWeightKg', weightTotal);
    blForm.setValue('volumeWeightKg', weightTotal);
  } else {
    blForm.setValue('package', rows.length);
    // blForm.setValue('grossWeightKg', weightTotal);
    blForm.setValue('chargeableWeightKg', util.toFloat(grossWeight) >= util.toFloat(weightTotal) ? grossWeight : weightTotal);
    blForm.setValue('volumeWeightKg', weightTotal);
    cbmTotal = cbmTotal > 0 ? cbmTotal.toFixed(3) : '';
    blForm.setValue('cbm', cbmTotal);
  }
}

function useSearchInput(props) {
  const {_f, _n, _c, formik, fields, errors, fm: blForm} = props;
  function setValues(data) {
    const values = {};
    const prevValues = {};
    for (const [name, value] of Object.entries(data)) {
      values[_n(name)] = value;
    }
    for (const [name, value] of Object.entries(blForm.getValues())) {
      prevValues[_n(name)] = value;
    }
    formik.setValues({...prevValues, ...values});
  }
  const onTPartner = (name, data) => {
    const {id, value, Address, localAddress, importNo, phone, cell, city, partnerType, localName, email, bill_address} = data;
    const kind = blForm.getValue(FN.KIND) ?? 'M';
    const portType = blForm.getValue(FN.PORT_TYPE) ?? 'O';
    if (name === FN.PARTNER) {
      _c(FN.PARTNER, value);
      _c(FN.PARTNER_LOCAL, localName);
      _c(FN.PARTNER_ID, id);
      if (partnerType === 'VD') { // 파트너가 밴더이면 customer, shipper 자동 설정
        setValues({
          [FN.CUSTOMER]: value,
          [FN.CUSTOMER_ID]: id,
          [FN.CUSTOMER_ADDR]: Address,
          [FN.CUSTOMER_PHONE]: phone,
          [FN.CUSTOMER_CITY]: city,
          [FN.CUSTOMER_EMAIL]: email,
          [FN.SHIPPER]: value,
          [FN.SHIPPER_LOCAL]: localName,
          [FN.SHIPPER_ID]: id,
          [FN.SHIPPER_ADDR]: Address,
          [FN.SHIPPER_PHONE]: phone,
          [FN.SHIPPER_PHONE2]: cell,
          [FN.SHIPPER_CITY]: city,
          [FN.SHIPPER_EMAIL]: email,
        });
      }
    } else {
      const isAirCourier = kind === 'C' && portType === 'A';
      _c(`${name}`, value);
      _c(`${name}Local`, localName);
      _c(`${name}Id`, id);
      _c(`${name}Addr`, isAirCourier ? bill_address : Address); // 항공 택배의 경우 localAddress 를 사용함
      if (name === FN.CUSTOMER || name === FN.SHIPPER) {
        // customer, shipper 의 경우 전화번호도 저장
        _c(`${name}Phone`, phone);
        _c(`${name}Phone2`, cell);
        _c(`${name}City`, city);
        _c(`${name}Email`, email);
      }
      if (name === FN.CONSIGNEE) {
        _c(`${name}Phone1`, phone);
        _c(`${name}Phone2`, cell);
        _c(`${name}AddrLocal`, localAddress);
        _c(FN.CUSTOMS_NO, importNo);
      }
    }
  };
  const onVSL = (name, data) => {
    let values = {};
    if(name === 'preVsl') {
      values = {
        preVsl: data.value,
      };
    } else {
      const {
        carrier_name: carrier, carrier_id: carrierId,
        etd_date: etd, eta_date: eta,
        departure: polLabel, destination: podLabel,
        onboard, id: craftId, value: vslVoy,
        jextra: {
          billingCarrier, billingCarrierId,
        },
      } = data;
      values = {
        craftId, vslVoy, carrier, carrierId, etd, eta, polEtd: etd, podEta: eta, etdPol: etd, etaPod: eta,
        polLabel, podLabel, billingCarrier, billingCarrierId, onboard,
        etdDate: etd, etaDate: eta,
      };
    }

    setValues(values);
  };
  const onFLT = (name, data) => {
    const {
      id: craftId,
      carrier_name: carrier, carrier_id: carrierId,
      craft_no: flightNo,
      departure, destination,
      etd_date: flightDate, eta_date: arrivalDate,
      etd_time: flightTime, eta_time: arrivalTime,
      jextra: {
        etaOfFpoe, finalEta,
        firstTo,
        trans1To, trans1ToFlight,
        trans2To, trans2ToFlight,
        trans3To, trans3ToFlight,
        billingCarrier, billingCarrierId,
        issuingCarrier, issuingCarrierId,
      },
    } = data;
    const values = {
      craftId,
      carrier, carrierId,
      flightNo,
      departure, destination,
      flightDate,
      flightTime,
      arrivalDate,
      arrivalTime,
      etaOfFpoe, finalEta,
      firstTo,
      trans1To, trans1ToFlight,
      trans2To, trans2ToFlight,
      trans3To, trans3ToFlight,
      billingCarrier, billingCarrierId,
      issuingCarrier, issuingCarrierId,
      etdDate: flightDate,
      etdTime: flightTime,
      etaDate: arrivalDate,
      etaTime: arrivalTime,
      customerEtd: flightDate,
      customerEta: arrivalDate,
    };
    setValues(values);
  };
  const _tp = (name, vertical = false) => {
    _f(name).smallMargin = false;
    return (
      <TradePartnerSearchInput
        idField={_n(`${name}Id`)} displayField={_n(name)}
        formik={formik} fields={fields} errors={errors} vertical={vertical}
      />
    );
  };
  const _tpa = (name, vertical = true) => {
    _f(name).smallMargin = name !== FN.PARTNER ? true : undefined;
    return (
      <TradePartnerSearchInput
        idField={_n(`${name}Id`)} displayField={_n(name)}
        formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onTPartner(name, data)}
      />
    );
  };
  const _tph = (name) => {
    if(name === FN.PARTNER_LOCAL) {
      name = FN.PARTNER;
    }
    if(name === FN.CUSTOMER_LOCAL) {
      name = FN.CUSTOMER;
    }
    if(name === FN.SHIPPER_LOCAL) {
      name = FN.SHIPPER;
    }
    if(name === FN.CONSIGNEE_LOCAL) {
      name = FN.CONSIGNEE;
    }
    return (
      <TradePartnerSearchInput
        idField={_n(`${name}Id`)} displayField={_n(`${name}Local`)} hideIcon={true}
        formik={formik} fields={fields} errors={errors} vertical onOK={(data) => onTPartner(name, data)}
      />
    );
  };
  const _vsl = (name) => <VslVoySearchInput
    idField={_n('craftId')} displayField={_n(name)}
    formik={formik} fields={fields} errors={errors} onOK={(data) => onVSL(name, data)}
  />
  const _flt = (name) => <FlightSearchInput
    idField={_n('craftId')} displayField={_n(name)}
    formik={formik} fields={fields} errors={errors} onOK={(data) => onFLT(name, data)}
  />
  const _pt = (name) => <PortSearchInput
    idField={_n(`${name}Id`)} displayField={_n(name)}
    formik={formik} fields={fields} errors={errors}
  />;
  // const _ss = () => <StaffSearchInput
  //   idField={_n('staffId')} displayField={_n('staff')}
  //   formik={formik} fields={fields} errors={errors}
  // />;
  return {_tp, _tpa, _tph, _vsl, _flt, _pt};
}

const calculateItems = (props) => {
  const {_v, _c, grid} = props;
  const grossWeight = _v('grossWeightKg') || 0;
  let weightTotal = 0;
  let cbmTotal = 0;
  for (const row of grid.rows ?? []) {
    const {cal_weight: weight = 0, box_cbm: cbm = 0} = row;
    weightTotal += weight;
    cbmTotal += cbm;
  }
  weightTotal = weightTotal > 0 ? weightTotal.toFixed(1) : 0;
  _c('package', grid?.rows?.length??0);
  // _c('grossWeightKg', weightTotal);
  _c('chargeableWeightKg', util.toFloat(grossWeight) >= util.toFloat(weightTotal) ? grossWeight : weightTotal);
  _c('volumeWeightKg', weightTotal);
  cbmTotal = cbmTotal > 0 ? cbmTotal.toFixed(3) : 0;
  _c('cbm', cbmTotal);
};

const OEM = (props) => {
  // const {_r, _n, _c, formik, fields, errors} = props;
  const {_f, _r, onCopyShipmentFromHouse} = props;
  const {_tp, _tpa, _vsl, _pt} = useSearchInput(props);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.CARRIER_BKG_NO)}</div>
              <div className={'flex-1'}>{_r(FN.IS_GROUND)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.MRN)}</div>
              <div className={'flex-1'}>{_r(FN.SERVICE_CONTACT_NO)}</div>
              {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
              <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.SUB_MBL_NO)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.B13_NO)}</div>
              <div className={'flex-1'}>{_r(FN.CUSTOMER_REF_NO)}</div>
              <div className={'flex-1'}>{_r(FN.EXPORT_REF_NO)}</div>
              <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              {_tpa(FN.SHIPPER)}
              {_r(FN.SHIPPER_ADDR)}
              {_tpa(FN.CONSIGNEE)}
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tpa(FN.FORWARDING_AGENT)}
              {_r(FN.FORWARDING_AGENT_ADDR)}
              {_tp(FN.CUSTOMER)}
              {_tp(FN.TRIANGLE_AGENT)}
              {_tp(FN.DESTINATION_AGENT)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex'}>
                <div className={'flex-3'}>{_r(FN.COMMODITY)}</div>
                <div className={'flex-1 ml-8'}>
                  <DefaultButton label={'Get HB/L'} onClick={onCopyShipmentFromHouse} />
                </div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <div className={'ml-8 mt-1'}>KG</div>
                <div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>
                <div className={'ml-8 mt-1'}>LB</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.CBM)}</div>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.MEASUREMENT_CFT)}</div>*/}
                {/*<div className={'ml-8 mt-1'}>CFT</div>*/}
              </div>
              {_r(FN.FREIGHT)}
              <div className={'flex'}>
                {_r(FN.SHIP_MODE)}
                {_r(FN.SALES_TYPE)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.SVC_TERM1)}</div>
                <div className={'color-label mx-8'}>~</div>
                <div className={'flex-1'}>{_r(FN.SVC_TERM2)}</div>
              </div>
              <div className={'flex'}>
                {_r(FN.BL_CURRENCY)}
                {_r(FN.ORIGINAL_BL_TYPE)}
              </div>
              <div className={'flex'}>
                {_r(FN.BROKERAGE_RATE)}
                {_r(FN.PROFIT_SHARE)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PORT_OPEN_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PORT_OPEN_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PORT_CUTOFF_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PORT_CUTOFF_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.RAIL_CUTOFF_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.RAIL_CUTOFF_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.DOC_CUTOFF_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.DOC_CUTOFF_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.VGM_CUTOFF_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.VGM_CUTOFF_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PICKUP_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PICKUP_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Released')}
                  <div className={'ml-20 mt-8'}>{_r(FN.RELEASED)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_DATE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Internal')}
                  <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_BY)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Vessel</b>
              {_vsl(FN.VSL_VOY)}
              {_tp(FN.CARRIER)}
              {_r(FN.ETD)}
              {_r(FN.ETA)}
              {_r(FN.ONBOARD)}
              {_r(FN.ETD_POR)}
              {_tp(FN.BILLING_CARRIER)}
              <b className={'ml-12'}>Route</b>
              {_pt(FN.POR)}
              {_pt(FN.POL)}
              {_pt(FN.POD)}
              {_pt(FN.DEL)}
              {_pt(FN.FINAL_DESTINATION)}
              {_pt(FN.DELIVERY_TO)}
              {_r(FN.CONTAINER_SUMMARY)}
              {_tpa(FN.EMPTY_PICKUP)}
              {_r(FN.EMPTY_PICKUP_ADDR)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const AEM = (props) => {
  // const {_r, _n, _c, _v, formik, fields, errors, grid} = props;
  const {_r, _v, grid, onCopyShipmentFromHouse, onSave} = props;
  const {_tp, _tpa, _flt, _pt} = useSearchInput(props);
  const isForwarding = _v(FN.KIND) === 'F';
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MAWB_NO)}</div>
              <div className={'flex-1'}>{_r(FN.CARRIER_BKG_NO)}</div>
              <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BL_DATE)}</div>
              <div className={'flex-1'}>{_r(FN.MRN)}</div>
              {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
              <div className={'flex-1'}>{_r(FN.SUB_MBL_NO)}</div>
              <div className={'flex-1'} />
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.B13_NO)}</div>
              <div className={'flex-1'}>{_r(FN.CUSTOMER_REF_NO)}</div>
              <div className={'flex-1'}>{_r(FN.EXPORT_REF_NO)}</div>
              <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              {_tpa(FN.SHIPPER)}
              {_r(FN.SHIPPER_ADDR)}
              {_tpa(FN.CONSIGNEE)}
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tpa(FN.FORWARDING_AGENT)}
              {_r(FN.FORWARDING_AGENT_ADDR)}
              {_tp(FN.TRIANGLE_AGENT)}
              {_r(FN.SHIPPER_ACCOUNT_NUMBER)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex'}>
                <div className={'flex-3'}>{_r(FN.COMMODITY)}</div>
                <div className={'flex-1 ml-8'}>
                  <DefaultButton label={'Get HB/L'} onClick={onCopyShipmentFromHouse} />
                </div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.CHARGEABLE_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.CHARGEABLE_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.BL_GROSS_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.BL_GROSS_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.BL_CHARGEABLE_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.BL_CHARGEABLE_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.VOLUME_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.VOLUME_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              {_r(FN.BL_CURRENCY)}
              <div className={'flex'}>
                {_r(FN.AIR_RATE)}
                {_r(FN.INCOTERMS)}
              </div>
              <div className={'flex ml-8 mb-1'}>
                <DefaultButton label={'Manage CTNS'} noMargin onClick={grid.onAddBox} disabled={!_v(FN.ID)} />
                {isForwarding && <div className={'ml-8'} />}
                {isForwarding && <DefaultButton label={'Sum'} noMargin onClick={grid.onSumHouses} disabled={!_v(FN.ID)} />}
                <div className={'ml-8'} />
                <DefaultButton label={'Save'} noMargin onClick={onSave} />
              </div>
              {grid.render()}
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.FREIGHT)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.OTHER_CHARGE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.DV_CARRIAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.DV_CUSTOMS)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.INSURANCE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.CARRIER_SPOT_NO)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex flex-1'}>
                  {standaloneLabel('Internal')}
                  <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
                </div>
                <div className={'flex-1'}>{_r(FN.SALES_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_r(FN.SHIPPING_INFO1)}</div>
                <div className={'flex-2 ml-8'}>{_r(FN.SHIPPING_INFO2)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Flight Info</b>
              {_flt(FN.FLIGHT_NO)}
              {_tp(FN.CARRIER)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
              </div>
              <b className={'ml-12'}>Route</b>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DEPARTURE)}</div>
                <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FIRST_TO)}</div>
                <div className={'flex-1'}>{_r(FN.FIRST_TO_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_1)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_1_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_2)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_2_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_3)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_3_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DESTINATION)}</div>
                <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
              </div>
              {_r(FN.IATA_CODE)}
              {_r(FN.AIR_ACCOUNT_NO)}
              {_r(FN.AIR_CARGO_TYPE)}
              {_tpa(FN.ISSUING_CARRIER)}
              {_r(FN.ISSUING_CARRIER_ADDR)}
              {_tpa(FN.BILLING_CARRIER)}
              {_r(FN.BILLING_CARRIER_ADDR)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const OIM = (props) => {
  // const {_r, _n, _c, formik, fields, errors} = props;
  const {_r, onCopyShipmentFromHouse} = props;
  const {_tp, _tpa, _vsl, _pt} = useSearchInput(props);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.SUB_MBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.IS_GROUND)}</div>
            </div>
            <div className={'flex w-full'}>
              {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
              <div className={'flex-1'}>{_r(FN.MRN)}</div>
              <div className={'flex-1'}>{_r(FN.MSN)}</div>
              <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.SERVICE_CONTACT_NO)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
              <div className={'flex-1'} />
              <div className={'flex-1'} />
              <div className={'flex-1'} />
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              {_tpa(FN.SHIPPER)}
              {_r(FN.SHIPPER_ADDR)}
              {_tpa(FN.CONSIGNEE)}
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tp(FN.FORWARDING_AGENT)}
              {_tp(FN.TRIANGLE_AGENT)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex'}>
                <div className={'flex-3'}>{_r(FN.COMMODITY)}</div>
                <div className={'flex-1 ml-8'}>
                  <DefaultButton label={'Get HB/L'} onClick={onCopyShipmentFromHouse} />
                </div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <div className={'ml-8 mt-1'}>KG</div>
                <div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>
                <div className={'ml-8 mt-1'}>LB</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.CBM)}</div>
                {/*<div className={'ml-8 mt-1'}>CBM</div>*/}
                {/*<div className={'flex-1 ml-8'}>{_r(FN.MEASUREMENT_CFT)}</div>*/}
                {/*<div className={'ml-8 mt-1'}>CFT</div>*/}
              </div>
              {_r(FN.FREIGHT)}
              <div className={'flex'}>
                {_r(FN.SHIP_MODE)}
                {_r(FN.SALES_TYPE)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.SVC_TERM1)}</div>
                <div className={'color-label mx-8'}>~</div>
                <div className={'flex-1'}>{_r(FN.SVC_TERM2)}</div>
              </div>
              <div className={'flex'}>
                {_r(FN.BL_CURRENCY)}
                {_r(FN.ORIGINAL_BL_TYPE)}
              </div>
              <div className={'flex'}>
                {_r(FN.BROKERAGE_RATE)}
                {_r(FN.PROFIT_SHARE)}
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Released')}
                  <div className={'ml-20 mt-8'}>{_r(FN.RELEASED)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_DATE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Internal')}
                  <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_BY)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Vessel</b>
              {_vsl(FN.VSL_VOY)}
              {_tp(FN.CARRIER)}
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-3'}>{_vsl(FN.VSL_VOY)}</div>*/}
              {/*  <div className={'flex-1 ml-8'}>{_r(FN.VOY)}</div>*/}
              {/*</div>*/}
              {_r(FN.ETD)}
              {_r(FN.ETA)}
              {_r(FN.DELIVERY_ETA)}
              {_tp(FN.BILLING_CARRIER)}
              <b className={'ml-12'}>Route</b>
              {_pt(FN.POR)}
              {_pt(FN.POL)}
              {_pt(FN.POD)}
              {_pt(FN.DEL)}
              {_pt(FN.CY_LOCATION)}
              {_pt(FN.CFS_LOCATION)}
              {_pt(FN.RET_LOCATION)}
              {_r(FN.IT_NO)}
              {_r(FN.IT_DATE)}
              {_pt(FN.IT_LOCATION)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const AIM = (props) => {
  // const {_r, _n, _c, formik, fields, errors} = props;
  const {_r, onCopyShipmentFromHouse} = props;
  const {_tp, _tpa, _flt, _pt} = useSearchInput(props);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MAWB_NO)}</div>
              <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.SUB_MBL_NO)}</div>
            </div>
            <div className={'flex w-full'}>
              {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
              <div className={'flex-1'}>{_r(FN.MRN)}</div>
              <div className={'flex-1'}>{_r(FN.MSN)}</div>
              <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
              <div className={'flex-1'} />
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              {_tpa(FN.SHIPPER)}
              {_r(FN.SHIPPER_ADDR)}
              {_tpa(FN.CONSIGNEE)}
              {_r(FN.CONSIGNEE_ADDR)}
              {_r(FN.MANIFEST_TO)}
              {_tpa(FN.FORWARDING_AGENT)}
              {_r(FN.FORWARDING_AGENT_ADDR)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex'}>
                <div className={'flex-3'}>{_r(FN.COMMODITY)}</div>
                <div className={'flex-1 ml-8'}>
                  <DefaultButton label={'Get HB/L'} onClick={onCopyShipmentFromHouse} />
                </div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.CHARGEABLE_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.CHARGEABLE_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.VOLUME_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.VOLUME_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              {_r(FN.FREIGHT)}
              {_r(FN.BL_CURRENCY)}
              <div className={'flex flex-1'}>
                {standaloneLabel('Internal')}
                <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Flight Info</b>
              {_flt(FN.FLIGHT_NO)}
              {_tp(FN.CARRIER)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.ETA_FPOE_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.ETA_FPOE_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FINAL_ETA_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FINAL_ETA_TIME)}</div>
              </div>
              {_tp(FN.BILLING_CARRIER)}
              <b className={'ml-12'}>Route</b>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DEPARTURE)}</div>
                <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_1)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_1_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_2)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_2_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_3)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_3_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FIRST_PORT)}</div>
                <div className={'flex-1'}>{_r(FN.FIRST_PORT_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DESTINATION)}</div>
                <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
              </div>
              {_pt(FN.LAST_PORT)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FREIGHT_LOCATION)}</div>
                <div className={'flex-1'}>{_r(FN.FREIGHT_LOCATION_FLIGHT)}</div>
              </div>
              {_r(FN.STORAGE_START_DATE)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const OEH = (props) => {
  const {_r, _v, _c, _f, grid, onCopyShipmentFromMaster, onSave, workAssigned, isBranch, onSaveComment, onInventoryHistoryModal} = props;
  const {_tp, _tpa, _tph, _vsl, _pt} = useSearchInput(props);
  const kind = _v(FN.KIND) ?? 'M';
  const isMoving = kind === 'M';
  const isMVCE = true;
  const isForwarding = kind === 'F';
  const isCPU = kind === 'C' || kind === 'P' || kind === 'U'; // courier, canada post, UPS
  let showLocalConsigneeAddr = isMVCE;
  let showLocalShipperAddr = false;
  const branchOptions = _f(FN.BRANCH_ID).options;
  const branchId = _v(FN.BRANCH_ID);
  const isSeoul = branchOptions.find(i => i.value === parseInt(branchId))?.label === 'SEOUL';
  const isCJ = branchOptions.find(i => i.value === parseInt(branchId))?.label === 'CJ';
  let showCityProvince = false;
  const warehouseDate = _v(FN.WAREHOUSE_ARRIVAL_DATE);
  const shipperPost = _v(FN.SHIPPER_POST);
  const consigneePost = _v(FN.CONSIGNEE_POST);
  const packages = _v(FN.PACKAGE);
  const mapleType = _v(FN.MAPLE_TYPE);
  const isCopyCustomer = _v(FN.IS_COPY_CUSTOMER);
  const shipperPostRef = React.useRef();
  const consigneePostRef = React.useRef();
  if (isSeoul || isCJ) {
    // 브랜치가 서울, CJ인 경우, Shipper 의 폼이 달라짐
    showLocalShipperAddr = true;
    showLocalConsigneeAddr = false;
    showCityProvince = isMVCE;
  }
  const onSearchAddress = (searchType: 'shipper' | 'consignee') => {
    // eslint-disable-next-line no-undef
    new daum.Postcode({
      oncomplete: function(data) {
        const {zonecode, roadAddress, addressEnglish, buildingName, buildingCode} = data;
        if (searchType === 'shipper') {
          shipperPostRef.current = zonecode.toUpperCase();
          _c(FN.SHIPPER_ADDR, roadAddress.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
          _c(FN.SHIPPER_ADDR_ENG, addressEnglish.toUpperCase());
          _c(FN.SHIPPER_POST, zonecode.toUpperCase());
          _c(FN.SHIPPER_BUILDING_CODE, buildingCode);
        } else {
          consigneePostRef.current = zonecode.toUpperCase();
          _c(FN.CONSIGNEE_ADDR_LOCAL, roadAddress.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
          _c(FN.CONSIGNEE_ADDR, addressEnglish.toUpperCase());
          _c(FN.CONSIGNEE_POST, zonecode.toUpperCase());
          _c(FN.CONSIGNEE_BUILDING_CODE, buildingCode);
        }
      }
    }).open();
  };
  React.useEffect(() => {
    if(kind === 'C' && mapleType === undefined) {
      _c(FN.MAPLE_TYPE, 'MAPLE BOX');
    }
  }, [kind]);
  React.useEffect(() => {
    if(shipperPostRef.current !== shipperPost) {
      _c(FN.SHIPPER_BUILDING_CODE, '');
    }
  }, [shipperPost]);
  React.useEffect(() => {
    if(consigneePostRef.current !== consigneePost) {
      _c(FN.CONSIGNEE_BUILDING_CODE, '');
    }
  }, [consigneePost]);
  React.useEffect(() => {
    if(warehouseDate) {
      _c(FN.CLEARED_BY_DATE, util.addDays(warehouseDate, 40));
    }
  }, [warehouseDate]);
  React.useEffect(() => {
    if(mapleType && packages) {
      let cbm;
      if (mapleType === 'MAPLE BAG') {
        cbm = packages * 0.2;
      } else if (mapleType === 'MAPLE BOX') {
        cbm = packages * 0.1;
      } else if (mapleType === 'MAPLE MIX') {
        cbm = packages * 0.15;
      } else {
        cbm = undefined;
      }
      _c(FN.CBM, cbm ? cbm.toFixed(3) : undefined);
    }
  }, [mapleType, packages]);
  React.useEffect(() => {
    _f(FN.IS_COPY_CUSTOMER).onChange = (name, value, e) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        const customerLocal = _v(FN.CUSTOMER_LOCAL);
        const customer = _v(FN.CUSTOMER);
        const customerId = _v(FN.CUSTOMER_ID);
        const customerAddr = _v(FN.CUSTOMER_ADDR);
        _c(FN.SHIPPER_LOCAL, customerLocal);
        _c(FN.SHIPPER, customer);
        _c(FN.SHIPPER_ID, customerId);
        _c(FN.SHIPPER_ADDR, customerAddr);
      }
    };
  }, [isCopyCustomer]);
  React.useEffect(() => {
    _c(FN.SVC_TERM1, 'CF');
    _c(FN.SVC_TERM2, 'CF');
  }, []);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.HBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.IS_GROUND)}</div>
            </div>
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
                <div className={'flex-1'}>{_r(FN.CARRIER_BKG_NO)}</div>
                <div className={'flex-1'}>{_r(FN.MRN)}</div>
                <div className={'flex-1'} />
                {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.B13_NO)}</div>
                <div className={'flex-1'}>{_r(FN.LC_NO)}</div>
                <div className={'flex-1'}>{_r(FN.INVOICE_NO)}</div>
                <div className={'flex-1'}>{_r(FN.BOOKING_NO)}</div>
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.CUSTOMER_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.SUB_MBL)}</div>
                <div className={'flex-1'}>{_r(FN.DOC_NO)}</div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.PICKUP_TYPE)}</div>
                <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                <div className={'flex-1'}>{_r(FN.TOTAL_PRICE)}</div>
                <div className={'flex flex-1'}>
                  {isMoving && <div className={'flex top flex-1'}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={'#'} onClick={onInventoryHistoryModal}>
                      {standaloneLabel('Inventory', {color: '#0000FF', textDecorationLine: 'underline'})}
                    </a>
                    <div className={'flex-1'}>{_r(FN.INVENTORY)}</div>
                  </div>}
                  {isCPU && <div className={'flex-1'}>{'\u00a0'}</div>}
                  {isCPU && <div className={'flex-4'}>{_r(FN.PAID_BY)}</div>}
                </div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.P_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.P_TIME)}</div>
                {kind === 'V' && (<div className={'flex-1'}>{_r(FN.VIN_NO)}</div>)}
                {kind === 'V' && (<div className={'flex-1'}>{_r(FN.CAR_INFO)}</div>)}
                {kind === 'M' && (<div className={'flex-1'}>{_r(FN.WAREHOUSE_ARRIVAL_DATE)}</div>)}
                {kind === 'M' && (<div className={'flex-1'}>{_r(FN.CLEARED_BY_DATE)}</div>)}
                {kind === 'C' && (<div className={'flex-1'}>{''}</div>)}
                {kind === 'C' && (<div className={'flex-1'}>{_r(FN.MAPLE_TYPE)}</div>)}
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
              </div>
            )}
            {!isForwarding && (workAssigned?.length > 0 && (
              <div className={'flex w-full'} style={{color: 'red', fontWeight: 'bold', marginLeft: 80, marginBottom: 20}}>
                <div>ASSIGNED: {util.formatD(workAssigned[0])} ({workAssigned[1]})</div>
              </div>
            ))}
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.WORKORDER)}</div>
              <div className={'flex-1'}>{_r(FN.WORKORDER_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.REMARK)}</div>
              <div className={'flex-1'}>{_r(FN.REMARK_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div style={{width: '97%'}}>{_r(FN.COMMENT)}</div>
              <div className={'ml-8'} style={{width: '3%'}}><DefaultButton style={{height: 40}} label={'Save'} onClick={() => onSaveComment(_v(FN.COMMENT))} noMargin/></div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              <div className={'flex'} style={{marginBottom: -15}}>
                <div className={'flex-3 mr-8'}>{_r(FN.PARTNER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.PARTNER, false)}</div>
              </div>
              <div className={'flex'} style={{marginTop: -12, marginBottom: -15}}>
                <div className={'flex-3 mr-8'}>{_r(FN.CUSTOMER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.CUSTOMER)}</div>
              </div>
              {_r(FN.CUSTOMER_EMAIL)}
              {_r(FN.CUSTOMER_ADDR)}
              <div className={'flex'} style={{marginTop: -12}}>
                {/*<div className={'flex-3 mr-8'}>{_r(FN.SHIPPER_LOCAL)}</div>*/}
                {/*<div className={'flex-2'}>{_tpa(FN.SHIPPER)}</div>*/}
                <div className={'flex flex-3 mr-8'}>
                  <div className={'flex-1'} style={{marginTop: 8}}>{_r(FN.IS_COPY_CUSTOMER)}</div>
                  <div className={'flex-6'}>{_r(FN.SHIPPER_LOCAL)}</div>
                </div>
                <div className={'flex-2'}>{_tpa(FN.SHIPPER)}</div>
              </div>
              {_r(FN.SHIPPER_ADDR)}
              {showLocalShipperAddr && (
                <div className={'flex between center'} style={{marginTop: -20}}>
                  <div className={'flex-1 mr-8'}>{_r(FN.SHIPPER_POST)}</div>
                  <div className={'flex-1 flex right'}><DefaultButton label={'Search address...'} onClick={() => onSearchAddress('shipper')} /></div>
                </div>
              )}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-1 mr-8'}>{_r(FN.SHIPPER_PHONE)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_PHONE2)}</div>
              </div>
              {showLocalShipperAddr && _r(FN.SHIPPER_ADDR_ENG)}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-3 mr-8'}>{_r(FN.CONSIGNEE_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.CONSIGNEE)}</div>
              </div>
              {showLocalConsigneeAddr && _r(FN.CONSIGNEE_ADDR_LOCAL)}
              {showLocalConsigneeAddr && (
                <div className={'flex between center'} style={{marginTop: -20}}>
                  <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_POST)}</div>
                  <div className={'flex-1 flex right'}><DefaultButton label={'Search address...'} onClick={() => onSearchAddress('consignee')} /></div>
                </div>
              )}
              {showCityProvince && (
                <div className={'flex'}>
                  <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_CITY)}</div>
                  <div className={'flex-1'}>{_r(FN.CONSIGNEE_PROVINCE)}</div>
                </div>
              )}
              {showCityProvince && (
                <div className={'flex'} style={{marginTop: -12}}>
                  <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_COUNTRY)}</div>
                  <div className={'flex-1'}>{_r(FN.CONSIGNEE_POST)}</div>
                </div>
              )}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_PHONE1)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE2)}</div>
              </div>
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tpa(FN.C_BROKER)}
              {_r(FN.C_BROKER_ADDR)}
              {_r(FN.EXPORT_REF_NO)}
              {_tp(FN.FORWARDING_AGENT)}
              {_tp(FN.TRIANGLE_AGENT)}
              {_tp(FN.VENDOR)}
              {_tp(FN.TRUCKER)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex ml-8 mb-1 mt-2'}>
                <DefaultButton label={'Manage CTNS'} noMargin onClick={grid.onAddBox} disabled={!_v(FN.ID)} />
                <div className={'ml-8'} />
                <DefaultButton label={'Manage Items'} noMargin onClick={grid.onAddECItems} disabled={!_v(FN.ID)} />
                <div className={'ml-8'} />
                <DefaultButton label={'Save'} noMargin onClick={onSave} />
              </div>
              {grid.render()}
              <div className={'flex ml-8 mb-1 mt-2 right'}>
                <DefaultButton label={'Cal.'} onClick={() => calculateItems(props)} noMargin />
                <div className={'ml-8'} />
                <DefaultButton label={'Get MB/L'} onClick={() => onCopyShipmentFromMaster()} noMargin />
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COMMODITY)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              {!isForwarding && (
                <>
                <div className={'flex'}>
                  <div className={'flex-1'}>{_r(FN.TAPE)}</div>
                  <div className={'flex-1 ml-8'}>{_r(FN.ENT_DATE)}</div>
                </div>
                <div className={'flex'}>
                  <div className={'flex-1'}>{_r(FN.VISA_TYPE)}</div>
                  <div className={'flex-1 ml-8'}>{_r(FN.INSURANCE)}</div>
                </div>
                </>
              )}
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <div className={'ml-8 mt-1'}>KG</div>
                <div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>
                <div className={'ml-8 mt-1'}>LB</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.CBM)}</div>
                <div className={'flex-1'}>{_r(FN.OFFER_CBM)}</div>
                {/*<div className={'ml-8 mt-1'}>CBM</div>*/}
                {/*<div className={'flex-1 ml-8'}>{_r(FN.MEASUREMENT_CFT)}</div>*/}
                {/*<div className={'ml-8 mt-1'}>CFT</div>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COUNTRY_OF_ORIGIN)}</div>
                {/*<div className={'flex-1'}>{_r(FN.HS_CODE)}</div>*/}
              </div>
              <div className={'flex'}>
                {_r(FN.INCOTERMS)}
                {_r(FN.SHIP_MODE)}
              </div>
              <div className={'flex'}>
                {_r(FN.CONTAINER_SUMMARY)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.BUYING_RATE)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.BUYING_RATE_1)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.BUYING_RATE_CUR)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.BUYING_RATE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.SELLING_RATE)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.SELLING_RATE_1)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.SELLING_RATE_CUR)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.SELLING_RATE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                {_r(FN.PROFIT_SHARE)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.SVC_TERM1)}</div>
                <div className={'color-label mx-8'}>~</div>
                <div className={'flex-1'}>{_r(FN.SVC_TERM2)}</div>
              </div>
              <div className={'flex'}>
                {_r(FN.ORIGINAL_BL_TYPE)}
                {_r(FN.CARGO_TYPE)}
              </div>
              <div className={'flex'}>
                {_r(FN.SALES_TYPE)}
                {_r(FN.SHIP_TYPE)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.WH_CUTOFF_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.WH_CUTOFF_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.HBL_CUTOFF_DATE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.HBL_CUTOFF_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Released')}
                  <div className={'ml-20 mt-8'}>{_r(FN.RELEASED)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_DATE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Internal')}
                  <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_BY)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Surrendered')}
                  <div className={'ml-20 mt-8'}>{_r(FN.SURRENDERED)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.BKG_DATE)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Vessel</b>
              {_r(FN.ONBOARD)}
              {_vsl(FN.VSL_VOY)}
              {_tp(FN.CARRIER)}
              {_r(FN.CUSTOMER_ETD)}
              {_r(FN.CUSTOMER_ETA)}
              {_r(FN.CONTAINER_SEQ)}
              {/*{_r(FN.LANE)}*/}
              <b className={'ml-12'}>Route</b>
              {_pt(FN.POR)}
              {_pt(FN.POL)}
              {_pt(FN.POD)}
              {_pt(FN.DEL)}
              {_pt(FN.FINAL_DESTINATION)}
              {_r(FN.ETD_POR)}
              {_r(FN.ETD_POL)}
              {_r(FN.ETA_POD)}
              <div className={'flex'}>
                <div className={'flex-3'}>{_tp(FN.CY_LOCATION)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.AVAILABLE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_tp(FN.FREIGHT_LOCATION)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.LDP)}</div>
              </div>
              {_tp(FN.SUB_LOC_CODE)}
              {_tpa(FN.CARGO_PICKUP)}
              {_r(FN.CARGO_PICKUP_ADDR)}
              {_tpa(FN.WAREHOUSE)}
              {_r(FN.WAREHOUSE_ADDR)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const AEH = (props) => {
  const {_r, _v, _c, _f, grid, onCopyShipmentFromMaster, onMawbDelete, onSave, workAssigned, isBranch, onSaveComment, onInventoryHistoryModal} = props;
  const {_tp, _tpa, _tph, _flt, _pt} = useSearchInput(props);
  const kind = _v(FN.KIND) ?? 'M';
  const isMoving = kind === 'M';
  const isCourier = kind === 'C';
  const isCPU = kind === 'C' || kind === 'P' || kind === 'U'; // courier, canada post, UPS
  const isMVCE = true;
  const isForwarding = kind === 'F';
  let showLocalConsigneeAddr = isMVCE;
  let showLocalShipperAddr = false;
  const branchOptions = _f(FN.BRANCH_ID).options;
  const branchId = _v(FN.BRANCH_ID);
  const isSeoul = branchOptions.find(i => i.value === parseInt(branchId))?.label === 'SEOUL';
  const isCJ = branchOptions.find(i => i.value === parseInt(branchId))?.label === 'CJ';
  let showCityProvince = false;
  const shipperPost = _v(FN.SHIPPER_POST);
  const consigneePost = _v(FN.CONSIGNEE_POST);
  const isCopyCustomer = _v(FN.IS_COPY_CUSTOMER);
  const shipperPostRef = React.useRef();
  const consigneePostRef = React.useRef();
  if (isSeoul || isCJ) {
    // 브랜치가 서울, CJ인 경우, Shipper 의 폼이 달라짐
    showLocalShipperAddr = true;
    showLocalConsigneeAddr = false;
    showCityProvince = isMVCE;
  }
  const onSearchAddress = (searchType: 'shipper' | 'consignee') => {
    // eslint-disable-next-line no-undef
    new daum.Postcode({
      oncomplete: function(data) {
        const {zonecode, roadAddress, roadAddressEnglish, buildingName, buildingCode} = data;
        const building_name = buildingName ? `(${buildingName.toUpperCase()})` : '';
        if (searchType === 'shipper') {
          shipperPostRef.current = zonecode.toUpperCase();
          _c(FN.SHIPPER_ADDR, `${roadAddress.toUpperCase()} ${building_name}`);
          _c(FN.SHIPPER_ADDR_ENG, roadAddressEnglish.toUpperCase());
          _c(FN.SHIPPER_POST, zonecode.toUpperCase());
          _c(FN.SHIPPER_BUILDING_CODE, buildingCode);
        } else {
          consigneePostRef.current = zonecode.toUpperCase();
          _c(FN.CONSIGNEE_ADDR_LOCAL, `${roadAddress.toUpperCase()} ${building_name}`);
          _c(FN.CONSIGNEE_ADDR, roadAddressEnglish.toUpperCase());
          _c(FN.CONSIGNEE_POST, zonecode.toUpperCase());
          _c(FN.CONSIGNEE_BUILDING_CODE, buildingCode);
        }
      }
    }).open();
  };
  const onDeleteMawbNo = () => {
    const mawbNo = _v('mawbNo');
    // if (mawbNo) {
      util.showConfirm('Are you sure to delete Master No?', () => {
        apiBL.resetMbl(_v(FN.ID)).then(res => {
          if (res) {
            onMawbDelete();
          }
        });
      });
    // }
  };
  React.useEffect(() => {
    if(shipperPostRef.current !== shipperPost) {
      _c(FN.SHIPPER_BUILDING_CODE, '');
    }
  }, [shipperPost]);
  React.useEffect(() => {
    if(consigneePostRef.current !== consigneePost) {
      _c(FN.CONSIGNEE_BUILDING_CODE, '');
    }
  }, [consigneePost]);
  React.useEffect(() => {
    _f(FN.IS_COPY_CUSTOMER).onChange = (name, value, e) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        const customerLocal = _v(FN.CUSTOMER_LOCAL);
        const customer = _v(FN.CUSTOMER);
        const customerId = _v(FN.CUSTOMER_ID);
        const customerAddr = _v(FN.CUSTOMER_ADDR);
        const customerPhone = _v(FN.CUSTOMER_PHONE);
        _c(FN.SHIPPER_LOCAL, customerLocal);
        _c(FN.SHIPPER, customer);
        _c(FN.SHIPPER_ID, customerId);
        _c(FN.SHIPPER_ADDR, customerAddr);
        _c(FN.SHIPPER_PHONE, customerPhone);
      }
    };
  }, [isCopyCustomer]);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.HAWB_NO)}</div>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MAWB_NO)}</div>
              <div className={'flex-1'}>
                {isCourier && <div className={'ml-8'}><DefaultButton label={'Reset'} onClick={onDeleteMawbNo} /></div>}
              </div>
            </div>
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
                <div className={'flex-1'}>{_r(FN.MRN)}</div>
                <div className={'flex-1'}>{_r(FN.INVOICE_NO)}</div>
                <div className={'flex-1'}>{_r(FN.CARRIER_BKG_NO)}</div>
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.WO_NO)}</div>
                <div className={'flex-1'}>{_r(FN.BL_DATE)}</div>
                {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
                <div className={'flex-1'}>{_r(FN.LC_NO)}</div>
                <div className={'flex-1'} />
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.CUSTOMER_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.EXPORT_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
                <div className={'flex-1'} />
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.PICKUP_TYPE)}</div>
                <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                <div className={'flex-1'}>{_r(FN.TOTAL_PRICE)}</div>
                <div className={'flex flex-1'}>
                  {isMoving && <div className={'flex top flex-1'}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={'#'} onClick={onInventoryHistoryModal}>
                      {standaloneLabel('Inventory', {color: '#0000FF', textDecorationLine: 'underline'})}
                    </a>
                    <div className={'flex-1'}>{_r(FN.INVENTORY)}</div>
                  </div>}
                  {isCPU && <div className={'flex-1'}>{'\u00a0'}</div>}
                  {isCPU && <div className={'flex-4'}>{_r(FN.PAID_BY)}</div>}
                </div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.P_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.P_TIME)}</div>
                <div className={'flex-1'}>{kind === 'V' ? _r(FN.VIN_NO) : null}</div>
                <div className={'flex-1'}>{kind === 'V' ? _r(FN.CAR_INFO) : null}</div>
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
              </div>
            )}
            {!isForwarding && (workAssigned?.length > 0 && (
              <div className={'flex w-full'} style={{color: 'red', fontWeight: 'bold', marginLeft: 80, marginBottom: 20}}>
                <div className={'flex-1'}>ASSIGNED: {util.formatD(workAssigned[0])} ({workAssigned[1]})</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
              </div>
            ))}
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.WORKORDER)}</div>
              <div className={'flex-1'}>{_r(FN.WORKORDER_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.REMARK)}</div>
              <div className={'flex-1'}>{_r(FN.REMARK_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div style={{width: '97%'}}>{_r(FN.COMMENT)}</div>
              <div className={'ml-8'} style={{width: '3%'}}><DefaultButton style={{height: 40}} label={'Save'} onClick={() => onSaveComment(_v(FN.COMMENT))} noMargin/></div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              <div className={'flex'} style={{marginBottom: -15}}>
                <div className={'flex-3 mr-8'}>{_r(FN.PARTNER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.PARTNER, false)}</div>
              </div>
              <div className={'flex'} style={{marginTop: -12, marginBottom: -15}}>
                <div className={'flex-3 mr-8'}>{_r(FN.CUSTOMER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.CUSTOMER)}</div>
              </div>
              {_r(FN.CUSTOMER_EMAIL)}
              {_r(FN.CUSTOMER_ADDR)}
              <div className={'flex'} style={{marginTop: -12}}>
                {/*<div className={'flex-3 mr-8'}>{_r(FN.SHIPPER_LOCAL)}</div>*/}
                {/*<div className={'flex-2'}>{_tpa(FN.SHIPPER)}</div>*/}
                <div className={'flex flex-3 mr-8'}>
                  <div className={'flex-1'} style={{marginTop: 8}}>{_r(FN.IS_COPY_CUSTOMER)}</div>
                  <div className={'flex-6'}>{_r(FN.SHIPPER_LOCAL)}</div>
                </div>
                <div className={'flex-2'}>{_tpa(FN.SHIPPER)}</div>
              </div>
              {_r(FN.SHIPPER_ADDR)}
              {showLocalShipperAddr && (
                <div className={'flex between center'} style={{marginTop: -20}}>
                  <div className={'flex-1 mr-8'}>{_r(FN.SHIPPER_POST)}</div>
                  <div className={'flex-1 flex right'}><DefaultButton label={'Search address...'} onClick={() => onSearchAddress('shipper')} /></div>
                </div>
              )}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-1 mr-8'}>{_r(FN.SHIPPER_PHONE)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_PHONE2)}</div>
              </div>
              {showLocalShipperAddr && _r(FN.SHIPPER_ADDR_ENG)}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-3 mr-8'}>{_r(FN.CONSIGNEE_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.CONSIGNEE)}</div>
              </div>
              {showLocalConsigneeAddr && _r(FN.CONSIGNEE_ADDR_LOCAL)}
              {showLocalConsigneeAddr && (
                <div className={'flex between center'} style={{marginTop: -20}}>
                  <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_POST)}</div>
                  <div className={'flex-1 flex right'}><DefaultButton label={'Search address...'} onClick={() => onSearchAddress('consignee')} /></div>
                </div>
              )}
              {showCityProvince && (
                <div className={'flex'}>
                  <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_CITY)}</div>
                  <div className={'flex-1'}>{_r(FN.CONSIGNEE_PROVINCE)}</div>
                </div>
              )}
              {showCityProvince && (
                <div className={'flex'} style={{marginTop: -12}}>
                  <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_COUNTRY)}</div>
                  <div className={'flex-1'}>{_r(FN.CONSIGNEE_POST)}</div>
                </div>
              )}
              {/*{isCourier && (*/}
              {/*  <div className={'flex'} style={{marginTop: -12}}>*/}
              {/*    <div className={'flex-1 mr-8'}>{_r(FN.CUSTOMS_NO)}</div>*/}
              {/*    <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_PHONE1)}</div>*/}
              {/*    <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE2)}</div>*/}
              {/*  </div>*/}
              {/*)}*/}
              <div className={'flex'} style={{marginTop: -12}}>
                {isCourier && (<div className={'flex-1 mr-8'}>{_r(FN.CUSTOMS_NO)}</div>)}
                <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_PHONE1)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE2)}</div>
              </div>
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tp(FN.TRIANGLE_AGENT)}
              {_tp(FN.VENDOR)}
              {_tp(FN.FORWARDING_AGENT)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex ml-8 mb-1 mt-2'}>
                <DefaultButton label={'Manage CTNS'} noMargin onClick={grid.onAddBox} disabled={!_v(FN.ID)} />
                <div className={'ml-8'} />
                <DefaultButton label={'Manage Items'} noMargin onClick={grid.onAddECItems} disabled={!_v(FN.ID)} />
                <div className={'ml-8'} />
                <DefaultButton label={'Save'} noMargin onClick={onSave} />
              </div>
              {grid.render()}
              <div className={'flex ml-8 mb-1 mt-2 right'}>
                <DefaultButton label={'Cal.'} onClick={() => calculateItems(props)} noMargin />
                <div className={'ml-8'} />
                <DefaultButton label={'Get MB/L'} onClick={() => onCopyShipmentFromMaster()} noMargin />
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COMMODITY)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                {_r(FN.INCOTERMS)}
                {_r(FN.RATE)}
              </div>
              <div className={'flex'}>
                {_r(FN.GROSS_WEIGHT_KG)}<span className={'ml-8 mt-2'}>KG</span>
                {_r(FN.VOLUME_WEIGHT_KG)}
                {/*<div className={'flex-1 ml-8'}>{_r(FN.G_WT_SPHR_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                {_r(FN.CHARGEABLE_WEIGHT_KG)}<span className={'ml-8 mt-2'}>KG</span>
                {_r(FN.CBM)}
                {/*<div className={'flex-1 ml-8'}>{_r(FN.G_WT_CNEE_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-2'}>{_r(FN.C_WT_SPHR_KG)}</div>*/}
              {/*  <span className={'ml-8 mt-2'}>KG</span>*/}
              {/*  /!*<div className={'flex-1 ml-8'}>{_r(FN.C_WT_SPHR_LB)}</div>*!/*/}
              {/*  /!*<span className={'ml-8 mt-2'}>LB</span>*!/*/}
              {/*</div>*/}
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-2'}>{_r(FN.C_WT_CNEE_KG)}</div>*/}
              {/*  <span className={'ml-8 mt-2'}>KG</span>*/}
              {/*  /!*<div className={'flex-1 ml-8'}>{_r(FN.C_WT_CNEE_LB)}</div>*!/*/}
              {/*  /!*<span className={'ml-8 mt-2'}>LB</span>*!/*/}
              {/*</div>*/}
              {/*<div className={'flex'}>*/}
              {/*  {_r(FN.VOLUME_WEIGHT_KG)}*/}
              {/*  {_r(FN.CBM)}*/}
              {/*</div>*/}
              {_r(FN.MEMO)}
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COUNTRY_OF_ORIGIN)}</div>
                {/*<div className={'flex-1'}>{_r(FN.HS_CODE)}</div>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.BUYING_RATE)}</div>
                {/*<div className={'flex-1 ml-1'}>{_r(FN.BUYING_RATE_UNIT)}</div>*/}
                <div className={'flex-1 ml-1'}>{_r(FN.BUYING_RATE_1)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.BUYING_RATE_CUR)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.SELLING_RATE)}</div>
                {/*<div className={'flex-1 ml-1'}>{_r(FN.SELLING_RATE_UNIT)}</div>*/}
                <div className={'flex-1 ml-1'}>{_r(FN.SELLING_RATE_1)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.SELLING_RATE_CUR)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.FREIGHT)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.OTHER_CHARGE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.PROFIT_SHARE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.SALES_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.DV_CARRIAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.DV_CUSTOMS)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.SHIP_TYPE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.INSURANCE)}</div>
              </div>
              <div className={'flex mb-24'}>
                <div className={'flex flex-1'}>
                  {standaloneLabel('Internal')}
                  <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Flight Info</b>
              {_tp(FN.CARRIER)}
              {_flt(FN.FLIGHT_NO)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FINAL_ETA_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FINAL_ETA_TIME)}</div>
              </div>
              {_r(FN.CUSTOMER_ETD)}
              {_r(FN.CUSTOMER_ETA)}
              {/*{_r(FN.LANE)}*/}
              <b className={'ml-12'}>Route</b>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DEPARTURE)}</div>
                <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FIRST_TO)}</div>
                <div className={'flex-1'}>{_r(FN.FIRST_TO_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_1)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_1_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_2)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_2_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_3)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_3_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DESTINATION)}</div>
                <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
              </div>
              {_tp(FN.ISSUED_BY)}
              {_r(FN.BOOKING_DATE)}
              {_r(FN.DATE_ISSUED)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FIRST_PORT)}</div>
                <div className={'flex-1'}>{_r(FN.FIRST_PORT_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FINAL_DESTINATION)}</div>
                <div className={'flex-1'}>{_r(FN.FINAL_DESTINATION_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_tp(FN.FREIGHT_LOCATION)}</div>
                {/*<div className={'flex-1'}>{_r(FN.FREIGHT_LOCATION_FLIGHT)}</div>*/}
              </div>
              {_r(FN.STORAGE_START_DATE)}
              {_r(FN.AVAILABLE)}
              {_tp(FN.SUB_LOC_CODE)}
              {_tpa(FN.ISSUING_CARRIER)}
              {_r(FN.ISSUING_CARRIER_ADDR)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const OIH = (props) => {
  const {_r, _c, _v, _f, grid, onCopyShipmentFromMaster, onSave, workAssigned, isBranch, onSaveComment, onInventoryHistoryModal} = props;
  const {_tp, _tpa, _tph, _vsl, _pt} = useSearchInput(props);
  const kind = _v(FN.KIND) ?? 'M';
  const isMoving = kind === 'M';
  const isForwarding = kind === 'F';
  React.useEffect(() => {
    _c(FN.SVC_TERM1, 'CF');
    _c(FN.SVC_TERM2, 'CF');
  }, []);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.HBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MBL_NO)}</div>
              <div className={'flex-1'}>{_r(FN.IS_GROUND)}</div>
            </div>
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
                {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
                <div className={'flex-1'}>{_r(FN.SUB_MBL_NO)}</div>
                <div className={'flex-1'}>{_r(FN.AMS_NO)}</div>
                <div className={'flex-1'}>{_r(FN.HSL)}</div>
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.LC_NO)}</div>
                <div className={'flex-1'}>{_r(FN.INVOICE_NO)}</div>
                <div className={'flex-1'}>{_r(FN.CUSTOMER_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.SUB_MBL)}</div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.PICKUP_TYPE)}</div>
                <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                <div className={'flex-1'}>{_r(FN.TOTAL_PRICE)}</div>
                <div className={'flex flex-1'}>
                  {isMoving && <div className={'flex top flex-1'}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={'#'} onClick={onInventoryHistoryModal}>
                      {standaloneLabel('Inventory', {color: '#0000FF', textDecorationLine: 'underline'})}
                    </a>
                    <div className={'flex-1'}>{_r(FN.INVENTORY)}</div>
                  </div>}
                </div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.P_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.P_TIME)}</div>
                <div className={'flex-1'}>{kind === 'V' ? _r(FN.VIN_NO) : null}</div>
                <div className={'flex-1'}>{kind === 'V' ? _r(FN.CAR_INFO) : null}</div>
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
              </div>
            )}
            {!isForwarding && (workAssigned?.length > 0 && (
              <div className={'flex w-full'} style={{color: 'red', fontWeight: 'bold', marginLeft: 80, marginBottom: 20}}>
                <div>ASSIGNED: {util.formatD(workAssigned[0])} ({workAssigned[1]})</div>
              </div>
            ))}
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.WORKORDER)}</div>
              <div className={'flex-1'}>{_r(FN.WORKORDER_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.REMARK)}</div>
              <div className={'flex-1'}>{_r(FN.REMARK_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div style={{width: '97%'}}>{_r(FN.COMMENT)}</div>
              <div className={'ml-8'} style={{width: '3%'}}><DefaultButton style={{height: 40}} label={'Save'} onClick={() => onSaveComment(_v(FN.COMMENT))} noMargin/></div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              <div className={'flex'} style={{marginBottom: -15}}>
                <div className={'flex-3 mr-8'}>{_r(FN.PARTNER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.PARTNER, false)}</div>
              </div>
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-3 mr-8'}>{_r(FN.SHIPPER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.SHIPPER)}</div>
              </div>
              {_r(FN.SHIPPER_EMAIL)}
              {_r(FN.SHIPPER_ADDR)}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-1 mr-8'}>{_r(FN.SHIPPER_PHONE)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_PHONE2)}</div>
              </div>
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-3 mr-8'}>{_r(FN.CONSIGNEE_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.CONSIGNEE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_PHONE1)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE2)}</div>
              </div>
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tpa(FN.C_BROKER)}
              {_r(FN.C_BROKER_ADDR)}
              {_tp(FN.FORWARDING_AGENT)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex ml-8 mb-1 mt-2'}>
                <DefaultButton label={'Manage CTNS'} noMargin onClick={grid.onAddBox} disabled={!_v(FN.ID)} />
                <div className={'ml-8'} />
                <DefaultButton label={'Save'} noMargin onClick={onSave} />
              </div>
              {grid.render()}
              <div className={'flex ml-8 mb-1 mt-2 right'}>
                <DefaultButton label={'Cal.'} onClick={() => calculateItems(props)} noMargin />
                <div className={'ml-8'} />
                <DefaultButton label={'Get MB/L'} onClick={() => onCopyShipmentFromMaster()} noMargin />
              </div>
              <div className={'flex'}>
                {_r(FN.SHIP_MODE)}
                {_r(FN.INCOTERMS)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COMMODITY)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <div className={'ml-8 mt-1'}>KG</div>
                <div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>
                <div className={'ml-8 mt-1'}>LB</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1'}>{_r(FN.CBM)}</div>
                {/*<div className={'ml-8 mt-1'}>CBM</div>*/}
                {/*<div className={'flex-1 ml-8'}>{_r(FN.MEASUREMENT_CFT)}</div>*/}
                {/*<div className={'ml-8 mt-1'}>CFT</div>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COUNTRY_OF_ORIGIN)}</div>
                {/*<div className={'flex-1'}>{_r(FN.HS_CODE)}</div>*/}
              </div>
              <div className={'flex'}>
                {_r(FN.BL_CURRENCY)}
                {_r(FN.FREIGHT)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.SVC_TERM1)}</div>
                <div className={'color-label mx-8'}>~</div>
                <div className={'flex-1'}>{_r(FN.SVC_TERM2)}</div>
              </div>
              <div className={'flex'}>
                {_r(FN.SHIP_TYPE)}
                {_r(FN.SALES_TYPE)}
              </div>
              <div className={'flex'}>
                {_r(FN.PROFIT_SHARE)}
                {_r(FN.ORIGINAL_BL_TYPE)}
              </div>
              <div className={'flex'}>
                {_r(FN.CARGO_TYPE)}
                {_r(FN.SERVICE_SCOPE)}
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Org. B/L Rcvd.')}
                  <div className={'ml-20 mt-8'}>{_r(FN.ORG_BL_RECEIVED)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.ORG_BL_RECEIVED_DATE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Released')}
                  <div className={'ml-20 mt-8'}>{_r(FN.RELEASED)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_DATE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('Internal')}
                  <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
                </div>
                <div className={'flex-2 ml-8'}>{_r(FN.RELEASED_BY)}</div>
              </div>
              <div className={'flex mb-24'}>
                <div className={'flex top flex-1'}>
                  {standaloneLabel('ROR')}
                  <div className={'ml-20 mt-8'}>{_r(FN.ROR)}</div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Vessel</b>
              {_r(FN.ONBOARD)}
              {_vsl(FN.VSL_VOY)}
              <div className={'flex'}>
                <div className={'flex-3'}>{_vsl(FN.PRE_VSL)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PRE_VOY)}</div>
              </div>
              {_r(FN.CUSTOMER_ETD)}
              {_r(FN.CUSTOMER_ETA)}
              {_r(FN.CONTAINER_SEQ)}
              {/*{_r(FN.LANE)}*/}
              <b className={'ml-12'}>Route</b>
              <div className={'flex'}>
                <div className={'flex-3'}>{_pt(FN.POR)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.POR_ETD)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_pt(FN.POL)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.POL_ETD)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_pt(FN.POD)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.POD_ETA)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_pt(FN.DEL)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.DEL_ETA)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_pt(FN.FINAL_DESTINATION)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.FINAL_DEST_ETA)}</div>
              </div>
              {_pt(FN.DELIVERY_TO)}
              <div className={'flex'}>
                <div className={'flex-3'}>{_tp(FN.CY_LOCATION)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.AVAILABLE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3'}>{_tp(FN.FREIGHT_LOCATION)}</div>
                <div className={'flex-1 ml-1'}>{_r(FN.LDP)}</div>
              </div>
              {/*{_r(FN.GO_DATE)}*/}
              {_tp(FN.SUB_LOC_CODE)}
              {_tp(FN.TRUCKER)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const AIH = (props) => {
  const {_r, _c, _v, _f, grid, onCopyShipmentFromMaster, onSave, workAssigned, isBranch, onSaveComment, onInventoryHistoryModal} = props;
  const {_tp, _tpa, _tph, _flt, _pt} = useSearchInput(props);
  const kind = _v(FN.KIND) ?? 'M';
  const isMoving = kind === 'M';
  const isForwarding = kind === 'F';
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.BRANCH_ID)}</div>
              <div className={'flex-1'}>{_r(FN.KIND)}</div>
              <div className={'flex-1'}>{_r(FN.TRADE_TYPE)}</div>
              <div className={'flex-1'}>{_r(FN.PORT_TYPE)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.HAWB_NO)}</div>
              <div className={'flex-1'}>{_r(FN.FILING_NO)}</div>
              <div className={'flex-1'}>{_r(FN.MAWB_NO)}</div>
              {!isForwarding && (
                <div className={'flex-1'} />
              )}
              {isForwarding && (
                <div className={'flex-1'}>{_r(FN.AMS_NO)}</div>
              )}
            </div>
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_TYPE)}</div>
                {/*<div className={'flex-1'}>{_r(FN.POST_DATE)}</div>*/}
                <div className={'flex-1'}>{_r(FN.CUSTOMER_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.AGENT_REF_NO)}</div>
                <div className={'flex-1'}>{_r(FN.HSL)}</div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.PICKUP_TYPE)}</div>
                <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
                <div className={'flex-1'}>{_r(FN.TOTAL_PRICE)}</div>
                <div className={'flex flex-1'}>
                  {isMoving && <div className={'flex top flex-1'}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href={'#'} onClick={onInventoryHistoryModal}>
                      {standaloneLabel('Inventory', {color: '#0000FF', textDecorationLine: 'underline'})}
                    </a>
                    <div className={'flex-1'}>{_r(FN.INVENTORY)}</div>
                  </div>}
                </div>
              </div>
            )}
            {!isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.P_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.P_TIME)}</div>
                <div className={'flex-1'}>{kind === 'V' ? _r(FN.VIN_NO) : null}</div>
                <div className={'flex-1'}>{kind === 'V' ? _r(FN.CAR_INFO) : null}</div>
              </div>
            )}
            {isForwarding && (
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
              </div>
            )}
            {!isForwarding && (workAssigned?.length > 0 && (
              <div className={'flex w-full'} style={{color: 'red', fontWeight: 'bold', marginLeft: 80, marginBottom: 20}}>
                <div>ASSIGNED: {util.formatD(workAssigned[0])} ({workAssigned[1]})</div>
              </div>
            ))}
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.WORKORDER)}</div>
              <div className={'flex-1'}>{_r(FN.WORKORDER_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FN.REMARK)}</div>
              <div className={'flex-1'}>{_r(FN.REMARK_D)}</div>
            </div>
            <div className={'flex w-full'}>
              <div style={{width: '97%'}}>{_r(FN.COMMENT)}</div>
              <div className={'ml-8'} style={{width: '3%'}}><DefaultButton style={{height: 40}} label={'Save'} onClick={() => onSaveComment(_v(FN.COMMENT))} noMargin/></div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className={'flex w-full'}>
        <div className={'mr-24'} style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-20 pr-20 pt-12 pb-1'}>
              <b>Customer</b>
              <div className={'flex'} style={{marginBottom: -15}}>
                <div className={'flex-3 mr-8'}>{_r(FN.PARTNER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.PARTNER, false)}</div>
              </div>
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-3 mr-8'}>{_r(FN.SHIPPER_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.SHIPPER)}</div>
              </div>
              {_r(FN.SHIPPER_EMAIL)}
              {_r(FN.SHIPPER_ADDR)}
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-1 mr-8'}>{_r(FN.SHIPPER_PHONE)}</div>
                <div className={'flex-1'}>{_r(FN.SHIPPER_PHONE2)}</div>
              </div>
              <div className={'flex'} style={{marginTop: -12}}>
                <div className={'flex-3 mr-8'}>{_r(FN.CONSIGNEE_LOCAL)}</div>
                <div className={'flex-2'}>{_tpa(FN.CONSIGNEE)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-1 mr-8'}>{_r(FN.CONSIGNEE_PHONE1)}</div>
                <div className={'flex-1'}>{_r(FN.CONSIGNEE_PHONE2)}</div>
              </div>
              {_r(FN.CONSIGNEE_ADDR)}
              {_tpa(FN.NOTIFY)}
              {_r(FN.NOTIFY_ADDR)}
              {_tpa(FN.C_BROKER)}
              {_r(FN.C_BROKER_ADDR)}
              {_tp(FN.TRIANGLE_AGENT)}
              {_tp(FN.FORWARDING_AGENT)}
            </CardBody>
          </Card>
        </div>
        <div className={'flex-1 mr-24'}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Shipment & Item</b>
              <div className={'flex ml-8 mb-1 mt-2'}>
                <DefaultButton label={'Manage CTNS'} noMargin onClick={grid.onAddBox} disabled={!_v(FN.ID)} />
                <div className={'ml-8'} />
                <DefaultButton label={'Save'} noMargin onClick={onSave} />
              </div>
              {grid.render()}
              <div className={'flex ml-8 mb-1 mt-2 right'}>
                <DefaultButton label={'Cal.'} onClick={() => calculateItems(props)} noMargin />
                <div className={'ml-8'} />
                <DefaultButton label={'Get MB/L'} onClick={() => onCopyShipmentFromMaster()} noMargin />
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COMMODITY)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.PACKAGE)}</div>
                <div className={'flex-1 ml-8'}>{_r(FN.PACKAGE_TYPE)}</div>
              </div>
              {_r(FN.INCOTERMS)}
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.GROSS_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.GROSS_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.CHARGEABLE_WEIGHT_KG)}</div>
                <span className={'ml-8 mt-2'}>KG</span>
                {/*<div className={'flex-1 ml-8'}>{_r(FN.CHARGEABLE_WEIGHT_LB)}</div>*/}
                {/*<span className={'ml-8 mt-2'}>LB</span>*/}
              </div>
              <div className={'flex'}>
                {_r(FN.FREIGHT)}
                {_r(FN.BL_CURRENCY)}
              </div>
              <div className={'flex'}>
                <div className={'flex-2'}>{_r(FN.COUNTRY_OF_ORIGIN)}</div>
                {/*<div className={'flex-1'}>{_r(FN.HS_CODE)}</div>*/}
              </div>
              <div className={'flex'}>
                {_r(FN.SALES_TYPE)}
                {_r(FN.SHIP_TYPE)}
              </div>
              {_r(FN.LC_NO)}
              {_r(FN.INVOICE_NO)}
              {_r(FN.PO_NO)}
              {_tp(FN.TRUCKER)}
              <div className={'flex flex-1'}>
                {standaloneLabel('Internal')}
                <div className={'ml-20 mt-8'}>{_r(FN.INTERNAL)}</div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div style={{flex: 0.75}}>
          <Card style={cardStyle}>
            <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
              <b className={'ml-12'}>Flight Info</b>
              {_tp(FN.CARRIER)}
              {_flt(FN.FLIGHT_NO)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.ETA_FPOE_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.ETA_FPOE_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.FINAL_ETA_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.FINAL_ETA_TIME)}</div>
              </div>
              {_r(FN.CUSTOMER_ETD)}
              {_r(FN.CUSTOMER_ETA)}
              {/*{_r(FN.LANE)}*/}
              <b className={'ml-12'}>Route</b>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DEPARTURE)}</div>
                <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_1)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_1_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_2)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_2_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.TRANS_3)}</div>
                <div className={'flex-1'}>{_r(FN.TRANS_3_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FIRST_PORT)}</div>
                <div className={'flex-1'}>{_r(FN.FIRST_PORT_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DESTINATION)}</div>
                <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.FINAL_DESTINATION)}</div>
                <div className={'flex-1'}>{_r(FN.FINAL_DESTINATION_FLIGHT)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DELIVERY_LOC)}</div>
                <div className={'flex-1'}>{_r(FN.DELIVERY_LOC_ID)}</div>
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_tp(FN.FREIGHT_LOCATION)}</div>
                {/*<div className={'flex-1'}>{_r(FN.FREIGHT_LOCATION_FLIGHT)}</div>*/}
              </div>
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.WAREHOUSE_ARR_ON_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.WAREHOUSE_ARR_ON_TIME)}</div>
              </div>
              {_r(FN.STORAGE_START_DATE)}
              {_r(FN.AVAILABLE)}
              {/*{_r(FN.GO_DATE)}*/}
              {_tp(FN.SUB_LOC_CODE)}
              {_pt(FN.FOREIGN_DESTINATION)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.DOC_PICKUP_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.DOC_PICKUP_TIME)}</div>
              </div>
              {_tp(FN.DOC_PICKUP_BY)}
              <div className={'flex'}>
                <div className={'flex-3 mr-1'}>{_r(FN.CARGO_RELEASED_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.CARGO_RELEASED_TIME)}</div>
              </div>
              {_tp(FN.CARGO_RELEASED_TO)}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default BLFormManager;
