/**
 * @flow
 */
import API from './API';
import APIAccount from "./APIAccount";
import Util from './Util';
import APIBL from './APIBL';
import APIDashboard from "./APIDashboard";
import APISettings from "./APISettings";

export const api = new API();
export const apiAccount = new APIAccount();
export const apiDashboard = new APIDashboard();
export const apiBL = new APIBL();
export const apiSettings = new APISettings();
export const util = new Util();
