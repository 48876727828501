/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {DefaultButton} from '../../components/buttons';
import useInventoryModal from "../../modals/settings/useInventoryModal";
import {apiSettings, util} from "../../services/service";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class MasterInventoryFormManager extends FormManager {
  grid;
  constructor() {
    super({
      prefix: `master-courier-price-add-item-form`,
      fields: [],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onRender = () => {
    const title = 'Inventory';
    const inventoryModal = useInventoryModal();
    const grid = useInventoryItemGrid();
    this.grid = grid;
    const onQuery = async () => {
      const {data} = await apiSettings.listInventory();
      if (data) {
        grid.setRows(data);
      }
    }
    const onAdd = () => {
      inventoryModal.open();
    }
    React.useEffect(() => {
      if(!inventoryModal.isOpen) {
        onQuery().catch();
      }
    }, [inventoryModal.isOpen]);
    function useInventoryItemGrid() {
      function getColumns() {
        return [
          {field: 'id', hide: true},
          {field: 'bl_id', hide: true},
          {field: 'udate', headerName: 'Updated', sortable: true, unSortIcon: true, type: 'date', valueFormatter: util.dateFormatter, flex: 1},
          {field: 'space_name', headerName: 'Name', sortable: true, unSortIcon: true, flex: 1},
          {field: 'branch_name', headerName: 'Branch', sortable: true, unSortIcon: true, flex: 1},
          {field: 'bl_no', headerName: 'HB/L', sortable: true, unSortIcon: true, flex: 1, cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true)},
          {field: 'isactive', headerName: 'Activeated', sortable: true, unSortIcon: true, flex: 1},
        ];
      }
      return useSimpleGrid({
        columns: getColumns(),
        height: 350,
        className: 'pb-20',
        actions: ['edit'],
        actionWidth: 70,
        onAction: (action, data) => {
          if (action === 'edit') {
            inventoryModal.open(data);
          }
        },
      });
    }
    return (
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <div>
                <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
              </div>
              <DefaultButton label={'Add'} onClick={onAdd} />
            </div>
            {grid.render()}
            {inventoryModal.render()}
          </CardBody>
        </Card>
      </div>
    );
  };

  onValidate = (values) => {
  };
}

export default MasterInventoryFormManager;
