/**
 * @flow
 */
import React from 'react';
import FormManager from '../lib/FormManager';
import {workTimeOptions} from './helper';
import {api, util} from '../services/service';
import {useVehicleSearch} from '../components/SearchModal';
import {VehicleSearchInput} from '../components/SearchInput';
// import {StaffSearchInput} from '../components/SearchInput';

const FN = {
  ID: 'id',
  // STAFF_ID: 'staffId',
  // STAFF: 'staff',
  WORKERS: 'workers',
  ETC: 'remark',
  WORK_DATE: 'workDate',
  WORK_TIME: 'workTime',
  VEHICLE_ID: 'vehicleId',
  VEHICLE: 'vehicle',
};

const SN = {
  ID: 'id',
  // STAFF_ID: 'staff_id',
  // STAFF: 'staff_name',
  WORKERS: 'workers',
  ETC: 'remark',
  WORK_DATE: 'work_date',
  WORK_TIME: 'work_time',
  VEHICLE_ID: 'vehicle_id',
  VEHICLE: 'vehicle',
};

class WorkOrderAssignFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  cargoType;
  onVehicleChange;
  onWorkTimeChange;
  hasEditData;
  constructor(cargoType) {
    super({
      prefix: 'work-assign-form',
      fields: [
        {name: FN.ID, serverName: SN.ID, label: ''},
        {name: FN.WORKERS, serverName: SN.WORKERS, label: ''},
        {name: FN.ETC, serverName: SN.ETC, label: ''},
        {name: FN.WORK_DATE, serverName: SN.WORK_DATE, label: '', type: 'date', mask: util.MASK_DATE},
        {name: FN.WORK_TIME, serverName: SN.WORK_TIME, label: '', options: workTimeOptions},
        {name: FN.VEHICLE_ID, serverName: SN.VEHICLE_ID, label: ''},
        {name: FN.VEHICLE, serverName: SN.VEHICLE, label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.cargoType = cargoType;
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const {renderField: _r, getValue: _v, setValue: _c, _n} = this;
    // const select = useVehicleSelect(_v(FN.VEHICLE_ID), this.hasEditData);
    // React.useEffect(() => {
    //   _c(FN.VEHICLE_ID, select.vehicleId);
    //   this.onVehicleChange && this.onVehicleChange(select.vehicleId);
    // }, [select.vehicleId]);
    React.useEffect(() => {
      this.onWorkTimeChange && this.onWorkTimeChange(_v(FN.WORK_TIME));
    }, [_v(FN.WORK_TIME)]);
    React.useEffect(() => {
      _c(FN.WORK_DATE, util.formatD(Date.now()));
      _c(FN.WORK_TIME, 'ALL DAY')
    }, []);
    // if (this.hasEditData) {
    //   _f(FN.VEHICLE_ID).disabled = true;
    //   _f(FN.WORK_DATE).readonly = true;
    //   _f(FN.WORK_TIME).disabled = true;
    //   _f(FN.STAFF).readonly = true;
    //   _f(FN.ETC).readonly = true
    // } else {
    //   _f(FN.VEHICLE_ID).disabled = false;
    //   _f(FN.WORK_DATE).readonly = false;
    //   _f(FN.WORK_TIME).disabled = false;
    //   _f(FN.STAFF).readonly = false;
    //   _f(FN.ETC).readonly = false
    // }
    return (
      <>
        <div className={'flex middle w-full ml-20'}>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Vehicle</span></div>
          {/*{this.hasEditData && <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Date</span></div>}*/}
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Time</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Workers</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Remark</span></div>
        </div>
        <div className={'flex middle w-full ml-20'}>
          {/*<div className={'flex-1 mr-12'}>{select.render()}</div>*/}
          <div className={'flex-1 mr-12'}>
            <VehicleSearchInput
              idField={_n(FN.VEHICLE_ID)} displayField={_n(FN.VEHICLE)}
              formik={formik} fields={fields} errors={errors}
            />
          </div>
          {/*{this.hasEditData && <div className={'flex-1 mr-12'}>{_r(FN.WORK_DATE)}</div>}*/}
          <div className={'flex-1 mr-12'}>{_r(FN.WORK_TIME)}</div>
          <div className={'flex-1 mr-12'}>{_r(FN.WORKERS)}</div>
          {/*<StaffSearchInput className={'flex-1 mr-12'} displayField={_n(FN.STAFF)} idField={_n(FN.STAFF_ID)} formik={formik} fields={fields} errors={errors} />*/}
          <div className={'flex-1 mr-12'}>{_r(FN.ETC)}</div>
        </div>
      </>
    );
  };
  onValidate = (values) => {
    if (!values[FN.VEHICLE_ID]) {
      util.showWarning('Please select vehicle.');
      return;
    }
    // if (!values[FN.STAFF_ID]) {
    //   util.showWarning('Please enter staff.');
    //   return;
    // }
    return values;
  }
  ;
}


interface VehicleSelectProps {
  disabled: boolean;
  vehicleId: number;
  onChange: (vehicle: Object) => void;
}

const VehicleSelect = (props: VehicleSelectProps) => {
  const [vehicles, setVehicles] = React.useState([]);
  const [vehicleId, setVehicleId] = React.useState();
  const vehicleSearch = useVehicleSearch((params) => {
    const index = vehicles.findIndex(i => i.value === params.id);
    if (index < 0) {
      loadVehicles().then(() => setVehicleId(params.id));
    } else {
      setVehicleId(params.id);
    }
  });
  const onChange = ({target: {value}}) => {
    if (value === '__add') {
      vehicleSearch.show();
      setVehicleId('');
    } else if (value) {
      props.onChange(value);
      setVehicleId(value);
    }
  };
  async function loadVehicles() {
    const res = await api.search('Vehicle', '', 100);
    const {data: {finds}} = res;
    if (finds) {
      setVehicles(finds.map(([value,,label]) => ({value, label})));
    }
  }
  React.useEffect(() => {
    loadVehicles().catch();
  }, []);
  React.useEffect(() => {setVehicleId(props.vehicleId)}, [props.vehicleId]);
  return (
    <div className={'form w-full'}>
      <div className={'form__form-group w-full'}>
        <div className={'form__form-group-field'} style={{marginLeft: 0, paddingLeft: 0, width: '100%'}}>
          <select onChange={onChange} value={vehicleId} disabled={props.disabled}>
            <option value={''}>Select vehicle...</option>
            <option value={'__add'}>Add New...</option>
            {vehicles.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
          </select>
        </div>
      </div>
      {vehicleSearch.render()}
    </div>
  );
};

function useVehicleSelect(initialId, disabled: boolean) {
  const [vehicleId, setVehicleId] = React.useState();
  return {
    vehicleId,
    render: () => <VehicleSelect vehicleId={initialId} disabled={disabled} onChange={(id) => setVehicleId(id)} />,
  };
}

export default WorkOrderAssignFormManager;
