/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import MarkFormManager from './MarkFormManager';

const FN = {
  CCN: 'ccn',
  MANIFEST_FROM: 'manFrom',
  TO_A: 'toA',
  HANDLING_INFO: 'handlingInfo',
  MARK: 'mark',
  DESCRIPTION: 'description',
};

const LB = {

};

class MarkAIMFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  constructor() {
    super({
      prefix: 'mark-AIM',
      fields: [
        {name: FN.CCN, serverName: FN.CCN, label: ''},
        {name: FN.MANIFEST_FROM, serverName: FN.MANIFEST_FROM, label: ''},
        {name: FN.TO_A, serverName: FN.TO_A, label: ''},
        {name: FN.HANDLING_INFO, serverName: FN.HANDLING_INFO, label: '', type: 'textarea'},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'pt-8'}><b>CCN</b></div>
          <div className={'w-128 mx-8'}>{_r(FN.CCN)}</div>
          <div className={'pt-8'}><b>Manifest From</b></div>
          <div className={'w-128 mx-8'}>{_r(FN.MANIFEST_FROM)}</div>
          <div className={'pt-8'}><b>To / A</b></div>
          <div className={'w-128 mx-8'}>{_r(FN.TO_A)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Handling Information')}
            {_r(FN.HANDLING_INFO)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Description')}
            {_r(FN.DESCRIPTION)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const form = new MarkAIMFormManager();

export function useMarkAIMModal(onSave) {
  return useSimpleEditModal({
    title: 'AIM Mark & Desc.',
    width: 1024,
    form,
    onSave,
  });
}

export default MarkAIMFormManager;
