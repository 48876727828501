/**
 * @flow
 */
import React from 'react';
import {DefaultButton, PrimaryButton} from '../../components/buttons';
import SearchPane from '../../components/SearchPane';
import useAccountListARPaidGridView from "../../grids/account/useAccountListARPaidGridView";
import AccountListARPaidSearchFormManager from "../../forms/account/AccountListARPaidSearchFormManager";
import {api, util} from "../../services/service";
import type {QueryListParams} from "../../services/API";
import {useTopbar} from "../../redux/reducers/topbarReducer";
import useAccountGridView from "../../grids/account/useAccountGridView";

const searchForm = new AccountListARPaidSearchFormManager();
const AccountListARPaid = () => {
  useTopbar({label: 'Account'}, {label: 'AR Payment History'});
  const {
    onSearch,
    gridView,
    onDownload,
    totalAmount,
  } = useAccountARPaidList();

  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {gridView.render()}
        <div style={{position: 'absolute', top: -12}}>
          <DefaultButton label={'Export Excel'} onClick={onDownload} />
        </div>
        <div className={'flex'} style={{position: 'absolute', bottom: 0, width: 350}}>
          <div className={'flex-1'}>Total Amount: {util.formatCurrency(totalAmount)}</div>
        </div>
      </div>
    </div>
  );
};

function useAccountARPaidList() {
  const [totalAmount, setTotalAmount] = React.useState(0);

  const onAction = async (action, data) => {};

  const onQueryData = (tAmount) => {
    setTotalAmount(tAmount);
  };

  const apiRef = React.useRef();
  const gridView = useAccountListARPaidGridView(onAction, (params) => {apiRef.current = params.api}, onQueryData);

  const onDownload = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: `account-list-ar-paid-${util.formatD(Date.now())}.csv`,
        columnKeys: gridView.props.columns.map(c => c.field),
      });
    }
  };

  const onSearch = () => {
    let values = searchForm.getValues();
    const gridInfo = util.getGridInfo(gridView.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
      ...values,
    };
    gridView.query(listParam);
  };
  searchForm.onSearch = onSearch;

  return {
    onSearch,
    gridView,
    onDownload,
    totalAmount,
  };
}

export default AccountListARPaid;
