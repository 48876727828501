/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {api, apiBL, util} from '../../../services/service';
import {TradePartnerSearchInput} from '../../../components/SearchInput';
import {usePrintModal} from './usePrintModal';
import OEHBookConf from '../../../print-layouts/OEHBookConf';
import {PRINT_TITLE} from "../../field-defs/bl";

const FN = {
  BL_NO: 'blNo',
  TRUCKER: 'trucker',
  TRUCKER_ID: 'truckerId',
  PHONE: 'phone',
  FAX: 'fax',
  PORT_OPEN_DATE: 'portOpenDate',
  PORT_OPEN_TIME: 'portOpenTime',
  PORT_DATE: 'portDate',
  PORT_TIME: 'portTime',
  RAIL_DATE: 'railDate',
  RAIL_TIME: 'railTime',
  WAREHOUSE_DATE: 'warehouseDate',
  WAREHOUSE_TIME: 'warehouseTime',
  DOC_DATE: 'docDate',
  DOC_TIME: 'docTime',
  VGM_DATE: 'vgmDate',
  VGM_TIME: 'vgmTime',
  REMARK: 'remark',
  JEXTRA: 'jextra',
};

class OEHBCPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'bc-oeh',
      fields: [
        {name: FN.BL_NO, serverName: FN.BL_NO, label: 'B/L No.', readonly: true},
        {name: FN.TRUCKER, serverName: FN.TRUCKER, label: 'Trucker', smallMargin: true},
        {name: FN.TRUCKER_ID, serverName: FN.TRUCKER_ID, label: 'Trucker'},
        {name: FN.PHONE, serverName: FN.PHONE, label: '', placeholder: 'Phone'},
        {name: FN.FAX, serverName: FN.FAX, label: '', placeholder: 'FAX'},
        {name: FN.PORT_OPEN_DATE, serverName: FN.PORT_OPEN_DATE, label: 'Port Open', type: 'date', mask: util.MASK_DATE},
        {name: FN.PORT_OPEN_TIME, serverName: FN.PORT_OPEN_TIME, label: ''},
        {name: FN.PORT_DATE, serverName: FN.PORT_DATE, label: 'Port', type: 'date', mask: util.MASK_DATE},
        {name: FN.PORT_TIME, serverName: FN.PORT_TIME, label: ''},
        {name: FN.RAIL_DATE, serverName: FN.RAIL_DATE, label: 'Rail', type: 'date', mask: util.MASK_DATE},
        {name: FN.RAIL_TIME, serverName: FN.RAIL_TIME, label: ''},
        {name: FN.WAREHOUSE_DATE, serverName: FN.WAREHOUSE_DATE, label: 'Warehouse', type: 'date', mask: util.MASK_DATE},
        {name: FN.WAREHOUSE_TIME, serverName: FN.WAREHOUSE_TIME, label: ''},
        {name: FN.DOC_DATE, serverName: FN.DOC_DATE, label: 'DOC', type: 'date', mask: util.MASK_DATE},
        {name: FN.DOC_TIME, serverName: FN.DOC_TIME, label: ''},
        {name: FN.VGM_DATE, serverName: FN.VGM_DATE, label: 'VGM', type: 'date', mask: util.MASK_DATE},
        {name: FN.VGM_TIME, serverName: FN.VGM_TIME, label: ''},
        {name: FN.REMARK, serverName: FN.REMARK, label: 'Remark', type: 'textarea'},
        {name: FN.JEXTRA, serverName: FN.JEXTRA},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const _tp = (name) => <TradePartnerSearchInput idField={`${_n(name)}Id`} displayField={_n(name)} formik={formik} fields={fields} errors={errors} onOK={({id, value, phone, fax}) => {
      _c(FN.TRUCKER_ID, id);
      _c(FN.TRUCKER, value);
      _c(FN.PHONE, phone);
      _c(FN.FAX, fax);
    }}/>
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.BL_NO)}
        {_tp(FN.TRUCKER)}
        <div className={'flex'}>
          <div style={{width: 120}} />
          <div className={'flex-1 mr-8'}>{_r(FN.PHONE)}</div>
          <div className={'flex-1'}>{_r(FN.FAX)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.PORT_OPEN_DATE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.PORT_OPEN_TIME)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.PORT_DATE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.PORT_TIME)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.RAIL_DATE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.RAIL_TIME)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.WAREHOUSE_DATE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.WAREHOUSE_TIME)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.DOC_DATE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.DOC_TIME)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.VGM_DATE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.VGM_TIME)}</div>
        </div>
        {_r(FN.REMARK)}
      </div>
    );
  };
}

const blPrintForm = new OEHBCPrintForm();

export function useOEHBCPrintModal(onSave, blForm) {
  const onOpen = async () => {
    const currentId = blForm.getValue('id');
    if (!currentId) return;
    const res = await api.forwardViewBLMark(currentId);
    if (res?.data?.length !== 1) return;
    const {id, jmark = {}} = res['data'][0];
    const {jbookConfirm} = jmark;
    const bookConfData = {id, ...jbookConfirm};
    const blNo = blForm.getValue('hblNo');
    const {data} = await apiBL.getBLPrintData(blNo);
    const jextra = data?.[0] ?? {};
    // blNo = blForm.getValue('hblNo');
    // const {data} = await apiBL.getBLPrintData(blNo);
    // const jextra = data?.[0] ?? {};
    // const {cutoff} = jextra;
    // const {doc = '', pickupDate: warehouse = '', portOpen = '', portCutoff: port = '', rail = '', vgm = ''} = cutoff;
    // const [docDate = '', docTime = ''] = doc.split(' ');
    // const [warehouseDate = '', warehouseTime = ''] = warehouse.split(' ');
    // const [portOpenDate = '', portOpenTime = ''] = portOpen.split(' ');
    // const [portDate = '', portTime = ''] = port.split(' ');
    // const [railDate = '', railTime = ''] = rail.split(' ');
    // const [vgmDate = '', vgmTime = ''] = vgm.split(' ');
    // blPrintForm.setValuesFast({
    //   blNo,
    //   docDate, docTime,
    //   warehouseDate, warehouseTime,
    //   portOpenDate, portOpenTime,
    //   portDate, portTime,
    //   railDate, railTime,
    //   vgmDate, vgmTime,
    //   jextra,
    // });
    blPrintForm.setValuesFast({...bookConfData, blNo, jextra});
  };
  return usePrintModal(false, PRINT_TITLE.BOOKING_CONFIRM, 1024, blForm, blPrintForm, OEHBookConf, onOpen, undefined, 600, '', onSave);
}
