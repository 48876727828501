/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useParams} from 'react-router-dom';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {DangerButton, DefaultButton} from '../../components/buttons';
import type {ForwardingType} from '../../forms/BLFormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {api, apiBL, util} from '../../services/service';
import {getBLEntryData, kindOptions} from '../../forms/field-defs/bl';
import {useAEMBLPrintModal} from '../../forms/print/v2/AEMBLPrintForm';
import {useMarkOEMModal} from '../../forms/mark/MarkOEMFormManager';
import {useMarkOIMModal} from '../../forms/mark/MarkOIMFormManager';
import {useMarkAEMModal} from '../../forms/mark/MarkAEMFormManager';
import {useMarkAIMModal} from '../../forms/mark/MarkAIMFormManager';
import useHouseSearchModal from '../../modals/bl/useHouseSearchModal';
// import useHouseListModal from '../../modals/bl/useHouseListModal';
import BLFormManager from '../../forms/BLFormManager';
import BLTopSearchFormManager from '../../forms/BLTopSearchFormManager';
import {useOEMBLPrintModal} from '../../forms/print/v2/OEMBLPrintForm';
import {useOEMLabelPrintModal} from '../../forms/print/v2/OEMLabelPrintForm';
import {useOEMShipInstPreviewModal} from '../../print-layouts/OEMShipInst';
import {useAEMManifestPrintModal} from '../../forms/print/v2/AEMManifestPrintForm';
import {useAEMTCPrintModal} from '../../forms/print/v2/AEMTCPrintForm';
import {useUser} from "../../redux/reducers/userReducer";
import {useAEMLabelPrintModal} from '../../forms/print/v2/AEMLabelPrintForm';
import ReactLoading from "react-loading";
import {LoadingContainer} from "./BLHouse";

const blForm = new BLFormManager();
const blSearchForm = new BLTopSearchFormManager();

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const BLMaster = () => {
  const user = useUser();
  const params = useParams() ?? {};
  const [type, setType] = React.useState<ForwardingType>('');
  const [menuLabel, setMenuLabel] = React.useState(params?.id ? 'Edit Master' : 'New Master');
  const [currentId, setCurrentId] = React.useState();
  const [houseData, setHouseData] = React.useState();
  const [filingNo, setFilingNo] = React.useState();
  const [editTime, setEditTime] = React.useState();
  useTopbar({label: 'B/L'}, {label: menuLabel});
  const houseSearchModal = useHouseSearchModal();
  // const houseListModal = useHouseListModal();
  const {onMark, onPrint, onShipInst, onLabel, onManifest, onTC, renderAll: renderAllModals} = useMasterModals(currentId, type, houseData);
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  function updateHouseList(data) {
    const {houses} = data;
    houseGrid.setRows(houses.map((house, index) => ({...house, no: index + 1})));
    // const {portType, tradeType} = jcommon;
    // const newType = (portType ?? 'O').charAt(0) + (tradeType ?? 'E') + 'M';
    // switch (newType) {
    //   case 'OEM':
    //     houseGrid.setRows(houses.map((house, index) => {
    //       const {id, jcommon, jcustomer, jshipment} = house;
    //       const {hblNo} = jcommon;
    //       const {shipper, consignee, partner, notify} = jcustomer;
    //       const {package: pkg, grossWeightKg: kg, grossWeightLb: lb, measurementCbm: cbm, measurementCft: cft} = jshipment;
    //       return {id, no: index + 1, hblNo, shipper, consignee, partner, notify, package: pkg, kg, lb, cbm, cft};
    //     }));
    //     break;
    //   case 'AEM':
    //     houseGrid.setRows(houses.map((house, index) => {
    //       const {id, jcommon, jcustomer, jshipment, jroute} = house;
    //       const {hawbNo: hblNo} = jcommon;
    //       const {shipper, consignee, partner, notify} = jcustomer;
    //       const {departure: pol, destination: pod} = jroute;
    //       const {package: pkg, gWtSPHRKg: gWeightKg, gWtSPHRLb: gWeightLb, cWtSPHRKg: cWeightKg, cWtSPHRLb: cWeightLb} = jshipment;
    //       return {id, no: index + 1, hblNo, shipper, consignee, partner, notify, pol, pod, package: pkg, gWeightKg, gWeightLb, cWeightKg, cWeightLb};
    //     }));
    //     break;
    //   case 'OIM':
    //     houseGrid.setRows(houses.map((house, index) => {
    //       const {id, jcommon, jcustomer, jvessel, jroute} = house;
    //       const {hblNo} = jcommon;
    //       const {shipper, consignee} = jcustomer;
    //       const {carrier}  = jvessel;
    //       const {porLabel: por, polLabel: pol, podLabel: pod, delLabel: del} = jroute;
    //       return {id, no: index + 1, hblNo, shipper, consignee, carrier, por, pol, pod, del};
    //     }));
    //     break;
    //   case 'AIM':
    //     houseGrid.setRows(houses.map((house, index) => {
    //       const {id, jcommon, jcustomer, jroute, jvessel} = house;
    //       const {hawbNo: hblNo} = jcommon;
    //       const {shipper, consignee, partner, notify} = jcustomer;
    //       const {departure, destination} = jroute;
    //       const {carrier} = jvessel;
    //       return {id, no: index + 1, hblNo, shipper, consignee, partner, notify, departure, destination, carrier};
    //     }));
    //     break;
    //   default:
    //     throw new Error(`Invalid type ${type}`);
    // }
  }
  function updateForm(data) {
    if (data?.length === 1) {
      const {jcommon, jcustomer, jroute, jvessel, jshipment, id, filing_no: filingNo, footer} = data[0];
      let {portType = 'O'} = jcommon;
      const isGround = jcommon['isGround'] === 'Y'; // NOTE: Y/N -> true/false
      portType = portType.charAt(0); // NOTE: 이전 데이터 중에 AIR / OCEAN 으로 들어가 있는 경우를 대비해서 넣어 주었음
      const values = {...jcommon, ...jcustomer, ...jroute, ...jvessel, ...jshipment, id, filingNo, portType, isGround, footer};
      updateTS(values);
      blForm.setValuesFast(values);
      setCurrentId(id);
      updateHouseList(data[0]);
      setHouseData(data[0].houses);
      setFilingNo(filingNo);
      setEditTime(data[0].footer.edit[0]);
    } else {
      util.showWarning('No entry found!');
    }
  }
  React.useEffect(() => {
    if (params.id) {
      (async () => {
        const {data} = await api.forwardViewBLEntry({id: params.id});
        updateForm(data);
      })();
    } else {
      onNew();
    }
  }, [params.id]);
  React.useEffect(() => {
    if (houseSearchModal.isOpen === false) {
      // 하우스 추가 모달이 닫히면 마스터를 갱신하여 변경된 내용이 보여지도록함
      (async () => {
        const {data} = await api.forwardViewBLEntry({id: params.id});
        updateForm(data);
      })();
    }
  }, [houseSearchModal.isOpen]);
  // React.useEffect(() => {
  //   if(houseListModal.isOpen === false) {
  //     (async () => {
  //       const {data} = await api.forwardViewBLEntry({id: params.id});
  //       updateForm(data);
  //     })();
  //   }
  // }, [houseListModal.isOpen]);
  blForm.onTradeTypeChange = (tradeType, portType) => {
    const newType = (portType ?? 'O').charAt(0) + (tradeType ?? 'E') + 'M';
    blForm.forwardingType = newType;
    blSearchForm.forwardingType = newType;
    setType(newType);
  };
  blSearchForm.onEnter = async (name, value) => {
    if (value) {
      const values = blSearchForm.getValues();
      const {filingNo, bkgNo: carrierBkgNo, mblNo: blNo} = values;
      const {data} = await api.forwardViewBLEntry({filingNo, carrierBkgNo, blNo});
      updateForm(data);
    }
  };
  // region Event Handlers
  const onNew = () => {
    blForm.clearValuesFast();
    blSearchForm.clearValuesFast();
    util.nav('/admin/bl/master');
    setMenuLabel('New Master');
    setCurrentId(0);
  };
  const onNewHouse = () => {
    const filingNo = blForm.getValue('filingNo');
    const blNo = blForm.getValue('mblNo') ?? blForm.getValue('mawbNo');
    if (blNo) {
      util.openTab(`/admin/bl/house?masterNo=${blNo}`);
    } else {
      util.openTab(`/admin/bl/house?filingNo=${filingNo}`);
    }
  };
  const onMainMaster = async () => {
    const blNo = blForm.getValue('mblNo') ?? blForm.getValue('mawbNo');
    const res = await api.getMainMasterId(blNo);
    if(res && res.data) {
      const blId = res.data[0].retval;
      if (blId) {
        util.openTab(`/admin/bl/master/${blId}`);
      }
    }
  };
  const onSave = async () => {
    setShowLoading(true);
    const isEditMode = !!currentId;
    const values = blForm.getValues();
    const gridData = blForm.getGridData();
    const data = getBLEntryData(true, {...values, gridData});
    const res = await api.forwardAddEditBLEntry(data);
    if (res) {
      util.showSuccess('BL Entry has been saved successfully.');
      blForm.setValue('id', res.id);
      blForm.setValue('filingNo', res.filing_no);
      setCurrentId(res.id);
      setMenuLabel('Edit Master');
      util.nav(`/admin/bl/master/${res.id}`);
      if (isEditMode) {
        const {data} = await api.forwardViewBLEntry({id: res.id});
        updateForm(data);
      }
      setShowLoading(false);
    }
  };
  const onDelete = () => {
    if (currentId) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = api.forwardDelBLEntry(currentId);
        if (res) {
          util.showSuccess('B/L data has been deleted successfully!');
          // onNew();
          util.nav('/admin/bl/list');
        }
      });
    }
  };
  const onContainer = () => {
    util.openTab(`/admin/craft/container?filingNo=${filingNo}`);
  };
  const onBLRate = () => {
    util.nav(`/admin/account/blrate?blNo=${blForm.getValue('mawbNo')}`);
  };
  const onOpenBulk = () => {
    const carrierType = (blForm.getValue('portType') ?? 'O').charAt(0);
    const bound = blForm.getValue('tradeType') ?? 'E';
    const branchId = blForm.getValue('branchId');
    const kind = blForm.getValue('kind');
    houseSearchModal.open({masterId: currentId, carrierType, bound, branchId, kind});
  };
  // const onOpenHouseList = () => {
  //   const filingNo = blForm.getValue('filingNo');
  //   houseListModal.open({filingNo, blId: currentId});
  // };
  // endregion
  const houseGrid = useHouseGrid(type, async (data) => {
    const res = await apiBL.resetMbl(data.id);
    if (res) {
      const {data} = await api.forwardViewBLEntry({id: params.id});
      updateForm(data);
    }
  });
  const isOcean = type.charAt(0) !== 'A';
  const title = type ? `${type} ${isOcean ? 'B/L' : 'AWB'} Entry` : '';
  document.title = title;
  blSearchForm.isEditMode = !!currentId;
  blForm.onSave = onSave;
  function renderButtons() {
    if (currentId) {
      const blNo = blForm.getValue('mblNo') ?? blForm.getValue('mawbNo');
      const isSubMaster = blNo && blNo.substr(0,4) === 'SUB-';
      switch (type) {
        case 'OEM':
          return (
            <>
              {isSubMaster && (<><DefaultButton label={'Main Master'} onClick={onMainMaster} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'New House'} onClick={onNewHouse} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={showLoading}/><div className={'w-8'} />
              {user.isManager && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'Mark & Desc'} onClick={onMark} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Container'} onClick={onContainer} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
              <DefaultButton label={'S/I'} onClick={onShipInst} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Label'} onClick={onLabel} noMargin /><div className={'w-8'} />
            </>
          );
        case 'OIM':
          return (
            <>
              {isSubMaster && (<><DefaultButton label={'Main Master'} onClick={onMainMaster} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'New House'} onClick={onNewHouse} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={showLoading}/><div className={'w-8'} />
              {user.isManager && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'Mark & Desc'} onClick={onMark} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Container'} onClick={onContainer} noMargin /><div className={'w-8'} />
              {/*<DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />*/}
              {/*<DefaultButton label={'S/I'} onClick={onShipInst} noMargin /><div className={'w-8'} />*/}
              {/*<DefaultButton label={'Label'} onClick={onLabel} noMargin /><div className={'w-8'} />*/}
            </>
          );
        case 'AEM':
          return (
            <>
              {isSubMaster && (<><DefaultButton label={'Main Master'} onClick={onMainMaster} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'New House'} onClick={onNewHouse} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={showLoading}/><div className={'w-8'} />
              {user.isManager && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'B/L Rate'} onClick={onBLRate} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Mark & Desc'} onClick={onMark} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Manifest'} onClick={onManifest} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Label'} onClick={onLabel} noMargin /><div className={'w-8'} />
              <DefaultButton label={'TC'} onClick={onTC} noMargin /><div className={'w-8'} />
            </>
          );
        case 'AIM':
          return (
            <>
              {isSubMaster && (<><DefaultButton label={'Main Master'} onClick={onMainMaster} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'New House'} onClick={onNewHouse} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={showLoading}/><div className={'w-8'} />
              {user.isManager && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
              <DefaultButton label={'Mark & Desc'} onClick={onMark} noMargin /><div className={'w-8'} />
              {/*<DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />*/}
              {/*<DefaultButton label={'S/I'} onClick={onShipInst} noMargin /><div className={'w-8'} />*/}
            </>
          );
        default:
          break;
      }
    } else {
      return (
        <>
          <DefaultButton label={'Save'} onClick={onSave} noMargin disabled={showLoading}/><div className={'w-8'} />
        </>
      );
    }
  }
  return (
    <>
      <Card style={cardStyle}>
        {showLoading && <LoadingContainer><ReactLoading color={'grey'} type={'spin'} height={100} width={100}/></LoadingContainer>}
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'} style={{position: 'relative'}}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              {renderButtons()}
            </div>
          </div>
          {blSearchForm.renderForm()}
          {!!currentId && blForm.formik && util.renderFooter(blForm.getValue('footer'), 'house-footer')}
        </CardBody>
      </Card>
      {blForm.renderForm()}
      {!!currentId && (
        <div className={'flex between middle mb-12'} style={{marginTop: -20}}>
          <div>House B/L List</div>
          <div className={'flex between middle'}>
            <div style={{paddingRight: 10}}><DefaultButton label={'Bulk'} onClick={onOpenBulk} noMargin /></div>
            {/*<div><DefaultButton label={'House List'} onClick={onOpenHouseList} noMargin /></div>*/}
          </div>
        </div>
      )}
      {!!currentId && (
        <div style={{padding: 1}}>
          {houseGrid.render()}
        </div>
      )}
      {renderAllModals()}
      {houseSearchModal.render()}
      {/*{houseListModal.render()}*/}
    </>
  );
};

function blLinkRenderer(param) {
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        util.openTab(`/admin/bl/${param.data.h_m === 'M' ? 'master' : 'house'}/${param.data['id']}`);
      }}>
        {param.value}
      </a>
    </div>
  );
}

const houseListColumns = [
  {field: 'id', hide: true},
  {field: 'no', headerName: 'No.', width: 50, minWidth: 50},
  {field: 'bl_no', headerName: 'HB/L No.', flex: 1, cellRendererFramework: blLinkRenderer},
  {field: 'kind', headerName: 'Service Type', sortable: true, unSortIcon: true, valueFormatter: (p) => util.labelFromOptions(p.value, kindOptions), flex: 1},
  {field: 'shipper', headerName: 'Shipper', sortable: true, unSortIcon: true, flex: 1},
  {field: 'consignee', headerName: 'Consignee', sortable: true, unSortIcon: true, flex: 1},
  {field: 'packages', headerName: 'Package', width: 100},
  {field: 'gw', headerName: 'G.W.', valueFormatter: util.formatWeight, width: 100},
  {field: 'cw', headerName: 'C.W.', valueFormatter: util.formatWeight, width: 100},
  {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, width: 100},
];

function useHouseGrid(type: ForwardingType, onResetMbl) {
  return useSimpleGrid({
    columns: houseListColumns,
    height: 200,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    agGridProps: {
      // onCellClicked(e) {
      //   const {id} = e.data;
      //   util.openTab(`/admin/bl/house/${id}`);
      // }
    },
    onAction: (action, data) => {
      if (action === 'delete') {
        util.showConfirm('Are you sure to delete?', () => onResetMbl(data));
      }
    },
  });
}

export function updateTS(values) {
  // values['etd'] = util.formatD(values['etd']); // 타임스탬프로 저장하므로 날짜형식으로 디스플레이!
  // values['eta'] = util.formatD(values['eta']); // 타임스탬프로 저장하므로 날짜형식으로 디스플레이!
  // const flightTs = values['flightDate']; // 타임스탬프로 저장하므로 날짜형식으로 디스플레이!
  // const arrivalTs = values['arrivalDate']; // 타임스탬프로 저장하므로 날짜형식으로 디스플레이!
  // values['flightDate'] = util.formatD(flightTs);
  // values['flightTime'] = util.formatFlightT(flightTs);
  // values['arrivalDate'] = util.formatD(arrivalTs);
  // values['arrivalTime'] = util.formatFlightT(arrivalTs);
  values['pDate'] = util.formatD(values['pDate']);
}

function useMasterModals(currentId, type, houseData) {
  const oemBLPrintModal = useOEMBLPrintModal(blForm);
  const aemBLPrintModal = useAEMBLPrintModal(blForm, true);
  const oemLabelPrintModal = useOEMLabelPrintModal(blForm);
  const oemSIPrintModal = useOEMShipInstPreviewModal();
  const oemMarkModal = useMarkOEMModal((mode, data) => onSaveMark(mode, data), blForm);
  const oimMarkModal = useMarkOIMModal((mode, data) => onSaveMark(mode, data));
  const aemMarkModal = useMarkAEMModal((mode, data) => onSaveMark(mode, data));
  const aimMarkModal = useMarkAIMModal((mode, data) => onSaveMark(mode, data));
  const aemManifestPrintModal = useAEMManifestPrintModal(blForm, houseData);
  const aemTCPrintModal = useAEMTCPrintModal(blForm);
  const aemLabelPrintModal = useAEMLabelPrintModal(blForm);

  const onSaveMark = async (mode, data) => {
    if (!currentId) return;
    const res = await api.forwardSetBLMark({id: currentId, jmark: data});
    if (res) {
      util.showSuccess('BL Mark & Desc. has been saved successfully.');
      switch (type) {
        case 'OEM': oemMarkModal.close(); break;
        case 'OIM': oimMarkModal.close(); break;
        case 'AEM': aemMarkModal.close(); break;
        case 'AIM': aimMarkModal.close(); break;
        default: throw new Error(`Invalid type (${type})`);
      }
    }
  };
  const onMark = async () => {
    if (!currentId) return;
    const res = await api.forwardViewBLMark(currentId);
    if (res?.data?.length !== 1) return;
    const {id, bl_no, filing_no, h_m, jmark = {}} = res['data'][0];
    const data = {id, bl_no, filing_no, h_m, ...jmark};
    switch (type) {
      case 'OEM':
        oemMarkModal.open('edit', data);
        break;
      case 'OIM':
        oimMarkModal.open('edit', data);
        break;
      case 'AEM':
        aemMarkModal.open('edit', data);
        break;
      case 'AIM':
        aimMarkModal.open('edit', data);
        break;
      default:
        return;
    }
  };
  const onPrint = () => {
    switch (type) {
      case 'OEM':
        oemBLPrintModal.open();
        break;
      case 'AEM':
        aemBLPrintModal.open();
        break;
      default:
        break;
    }
  };
  const onShipInst = async () => {
    switch (type) {
      case 'OEM':
        const values = blForm.getValues();
        const blData = getBLEntryData(true, values);
        const {data: markData} = await api.forwardViewBLMark(blData.id);
        const {data: containerData} = await apiBL.getContainers(blData.id);
        oemSIPrintModal.open(blForm, markData, containerData);
        break;
      default:
        break;
    }
  };
  const onLabel = () => {
    switch (type) {
      case 'OEM':
        oemLabelPrintModal.open();
        break;
      case 'AEM':
        aemLabelPrintModal.open();
        break;
      default:
        break;
    }
  };
  const onManifest = () => {
    aemManifestPrintModal.open();
  };
  const onTC = () => {
    aemTCPrintModal.open();
  };
  return {
    onMark, onPrint, onShipInst, onLabel, onManifest, onTC,
    renderAll: () => {
      return (
        <>
          {oemBLPrintModal.render()}
          {aemBLPrintModal.render()}
          {oemLabelPrintModal.render()}
          {oemSIPrintModal.render()}
          {aemManifestPrintModal.render()}
          {aemTCPrintModal.render()}
          {aemLabelPrintModal.render()}
          {oemMarkModal.render()}
          {oimMarkModal.render()}
          {aemMarkModal.render()}
          {aimMarkModal.render()}
        </>
      );
    },
  };
}

export default BLMaster;
