/**
 * @flow
 */
import React from 'react';
import {TradePartnerSearchInput} from '../../../components/SearchInput';
import {apiBL, util} from '../../../services/service';
import {usePrintModal} from './usePrintModal';
import FormManager from '../../../lib/FormManager';
import OIHRelOrder from '../../../print-layouts/OIHRelOrder';
import {useUser} from '../../../redux/reducers/userReducer';

const FN = {
  HBL_NO: 'hblNo',
  BY: 'by',
  DATE: 'date',
  SEND_TO: 'sendTo',
  SEND_TO_ID: 'sendToId',
  RELEASE_TO: 'releaseTo',
  RELEASE_TO_ID: 'releaseToId',
  ADDRESS: 'releaseToAddr',
  PHONE: 'releaseToPhone',
  FAX: 'releaseToFax',
  INSTRUCTIONS: 'instructions',
  TITLE: 'title',
};

class OIHROPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'ro-oih',
      fields: [
        {name: FN.HBL_NO, serverName: FN.HBL_NO, label: 'HB/L No.', readonly: true},
        {name: FN.BY, serverName: FN.BY, label: 'PIC', readonly: true},
        {name: FN.DATE, serverName: FN.DATE, label: 'Date', readonly: true},
        {name: FN.SEND_TO, serverName: FN.SEND_TO, label: 'Send To'},
        {name: FN.SEND_TO_ID, serverName: FN.SEND_TO_ID},
        {name: FN.RELEASE_TO, serverName: FN.RELEASE_TO, label: 'Release To', smallMargin: true},
        {name: FN.RELEASE_TO_ID, serverName: FN.RELEASE_TO_ID},
        {name: FN.ADDRESS, serverName: FN.ADDRESS, label: 'Address', type: 'textarea', smallMargin: true},
        {name: FN.PHONE, serverName: FN.PHONE, label: 'Phone/Fax'},
        {name: FN.FAX, serverName: FN.FAX, label: ''},
        {name: FN.INSTRUCTIONS, serverName: FN.INSTRUCTIONS, label: 'Special Instructions', type: 'textarea'},
        {name: FN.TITLE, serverName: FN.TITLE, label: 'Title', options: titleOptions, noDefOption: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    // eslint-disable-next-line max-len
    const _tp = (name, onOK) => <TradePartnerSearchInput displayField={_n(name)} idField={_n(`${name}Id`)} onOK={onOK} formik={formik} fields={fields} errors={errors} />;
    const onOK = (name, {id, value, Address: address, phone, fax, pic}) => {
      _c(`${name}`, value);
      _c(`${name}Id`, id);
      _c(`${name}Addr`, address);
      _c(`${name}Phone`, phone);
      _c(`${name}Fax`, fax);
    };
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.HBL_NO)}
        {_r(FN.BY)}
        {_r(FN.DATE)}
        {_tp(FN.SEND_TO)}
        {_tp(FN.RELEASE_TO, (data) => onOK(FN.RELEASE_TO, data))}
        {_r(FN.ADDRESS)}
        <div className={'flex'}>
          <div className={'flex-2'}>{_r(FN.PHONE)}</div>
          <div className={'flex-1 ml-8'}>{_r(FN.FAX)}</div>
        </div>
        {_r(FN.INSTRUCTIONS)}
        {_r(FN.TITLE)}
      </div>
    );
  };
}

export const defaultROTitle  = 'RELEASE ORDER/TURNOVER';
const titleOptions = [
  {value: 'RELEASE ORDER/TURNOVER', label: 'RELEASE ORDER/TURNOVER'},
  {value: 'BANK RELEASE REQUIRED', label: 'BANK RELEASE REQUIRED'},
  {value: 'FAX LIEFERSCHEIN', label: 'FAX LIEFERSCHEIN'},
  {value: 'CARGO ON HOLD NOTICE', label: 'CARGO ON HOLD NOTICE'},
  {value: 'CARGO HOLD REMOVED NOTICE', label: 'CARGO HOLD REMOVED NOTICE'},
];

const printForm = new OIHROPrintForm();

export function useOIHROPrintModal(blForm) {
  const user = useUser();
  const {print} = user;
  const onOpen = () => {
    const blNo = blForm.getValue('hblNo');
    printForm.setValue(FN.HBL_NO, blNo);
    printForm.setValue(FN.BY, print.pic);
    printForm.setValue(FN.DATE, util.formatD(Date.now()));
  };
  const onLoadPrintData = async (blData) => {
    const {data} = await apiBL.getBLPrintData(blData.blNo);
    return {serverData: data?.[0] ?? {}};
  };
  return usePrintModal(false, 'DELIVERY ORDER', 1024, blForm, printForm, OIHRelOrder, onOpen, onLoadPrintData, 640);
}
