/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {util} from '../../services/service';
import {TradePartnerSearchInput} from '../../components/SearchInput';

class PortFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'vessel-form',
      fields: [
        {name: 'id', serverName: 'id', label: 'ID'},
        {name: 'vesselName', serverName: 'vesselName', label: 'Name', required: true},
        {name: 'carrierId', serverName: 'carrierId', label: 'Carrier'},
        {name: 'carrier', serverName: 'carrier', label: 'Carrier', required: true},
        {name: 'country', serverName: 'country', label: 'Country'}
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    const {renderField: _r} = this;
    return (
      <div className={'w-full mr-24'}>
        {_r('vesselName')}
        {this.renderTP('carrier')}
        {_r('country')}
      </div>
    );
  };
  renderTP = (name, vertical) => {
    const {_n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return (
      <TradePartnerSearchInput
        idField={_n(`${name}Id`)}
        displayField={_n(name)}
        formik={formik} fields={fields} errors={errors}
        vertical={vertical}
      />
    );
  };
  onValidate = (values) => {
    const {vesselName} = values;
    if (!vesselName) {
      util.showWarning('Please enter the vessel name');
      return;
    }
    return values;
  };
}

export default PortFormManager;
