/**
 * @flow
 */
import React from 'react';
import {api, util} from "../../../../services/service";
import {PrimaryButton} from "../../../../components/buttons";
import useForwardingMasterBLContainerlListGridView from "../../../../grids/newCoship/forwarding/master/useForwardingMasterBLContainerlListGridView";
import useForwardingMasterBLContainerModal from "../../../../modals/newCoship/forwarding/useForwardingMasterBLContainerModal";
import {apiForwarding} from "../../../../services/newCoship/service";
import {useSelector} from "react-redux";
import useCraftContainerModal from "../../../../modals/useCraftContainerModal";
import {useContainerDetailPreviewModal} from "../../../../print-layouts/ContainerDetailPrint";

interface ForwardingMasterBLContainerProps {
  isShowList: boolean;
  containerList: Array;
}

const ForwardingMasterBLContainer = (props: ForwardingMasterBLContainerProps) => {
  const {isShowList, containerList} = props;
  const {grid, containerModal, addChildModal, containerDetailPrintModal, onAdd} = useForwardingMasterBLContainer();

  React.useEffect(() => {
    grid.setRows(containerList);
  }, [containerList]);

  return (
    <div className={'mt-4 ml-4 mr-4'}>
      {isShowList &&
        <>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <PrimaryButton label={'ADD'} onClick={onAdd}/>
          </div>
          <div style={{marginTop: -15}}>
            {grid.render()}
          </div>
        </>
      }
      {containerModal.render()}
      {addChildModal.render()}
      {containerDetailPrintModal.render()}
    </div>
  );
};

function useForwardingMasterBLContainer() {
  const {filingNo} = useSelector(state => state['newCoship']);
  const onActionContainer = (action, data) => {
    if (action === 'edit') {
      containerModal.open('edit', data);
    } else if (action === 'addChild') {
      addChildModal.open(data);
    } else if (action === 'delete') {
      util.showConfirm('Are you sure to delete?', async () => {
        apiForwarding.delContainer({container_id: data.container_id}).then((res) => {
          if (res) {
            grid.setRows(res.data);
            util.showSuccess('Container has been deleted successfully!');
          }
        });
      });
    }
  };
  const onAddContainer = (mode, data, form) => {
    const values = form.getValues();
    const payload = {
      ...values,
      filing_no: filingNo,
      pack_count: values.pack_count ? util.toInt(values.pack_count) : undefined,
      weight_kg: (values.weight_kg || values.weight_kg === 0) ? values.weight_kg.toString() : undefined,
    };
    apiForwarding.setContainer(payload).then((res) => {
      if (res) {
        util.showSuccess('Container has been saved successfully!');
        grid.setRows(res.data);
        containerModal.close();
      }
    });
  };
  const onAdd = () => {
    containerModal.open('add', {});
  };
  const onPrint = (data, mode) => {
    api.craftContainerItemList({id: data.container_id}).then((res) => {
      if (res.data) {
        containerDetailPrintModal.open(res.data[0], mode);
      }
    });
  }

  const grid = useForwardingMasterBLContainerlListGridView(onActionContainer);
  const containerModal = useForwardingMasterBLContainerModal(onAddContainer);
  const addChildModal = useCraftContainerModal(onPrint);
  const containerDetailPrintModal = useContainerDetailPreviewModal();

  return {grid, containerModal, addChildModal, containerDetailPrintModal, onAdd};
}

export default ForwardingMasterBLContainer;
