/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../../components/SimpleModal';
import {useSimpleGrid} from '../../../components/SimpleGrid';
import CourierHouseBLAccountAddItemFormManager from "../../../forms/newCoship/courier/house/CourierHouseBLAccountAddItemFormManager";

const editForm = new CourierHouseBLAccountAddItemFormManager();

export default function(accType) {
  const gridRef = React.useRef();
  const grid = useSimpleGrid({
    columns: [
      {field: 'account_type', headerName: 'Account Type', width: 150},
      {field: 'unit', headerName: 'Unit', width: 100},
      {field: 'rate', headerName: 'Rate', width: 100},
      {field: 'volume', headerName: 'Volume', width: 100},
      {field: 'dc_rate', headerName: 'CUR. Rate', width: 120},
      {field: 'amount', headerName: 'Amount', width: 100},
      {field: 'remark', headerName: 'Remark', flex: 1},
    ],
    height: 300,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: (action, data) => {
      if (action === 'delete') {
        const currentGrid = gridRef.current;
        const index = currentGrid.rows.findIndex(i => i['id'] === data['id']);
        const newRows = [...currentGrid.rows];
        newRows.splice(index, 1);
        currentGrid.setRows(newRows);
      }
    },
  });
  const modal = useSimpleModal({
    title: `Courier ${accType} Items`,
    centered: true,
    width: 900,
    children: editForm.renderForm(),
  });
  gridRef.current = grid;
  const open = (id) => {
    if(id) {
      editForm.setData(id, accType);
    }
    modal.open();
  };
  return {grid, ...modal, open};
};
