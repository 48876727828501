/**
 * @flow
 */
import React from "react";
import API from "./API";
import {util} from "./service";
import type {QueryListParams} from "./API";

class APIAccount extends API {
  // cargoAccountAdd(data) {
  //   const reqData = this.getCargoAccountData(data);
  //   return this.sendCUD('account/CUD', {action: 'AddCargoAccount', data: reqData});
  // }

  async accountTradeBLData(data) {
    const {id, filingNo, carrierBgkNo, blNo, invNo, house = false} = data;
    const reqData = {id, filingNo, carrierBgkNo, blNo, invNo, house};
    return this.sendList('account/ViewData', {action: 'AccountData', data: reqData})
  }

  async accountSet(data) {
    const {id, invNo, accType, blNo, blId, branchId, isRequest = false, currency, amount, invDate, dueDate, taxNo, incoterms, refNo, jextra, jitems} = data;
    const reqData = {
      id, invNo, accType, blNo, blId, branchId, isRequest, currency,
      amount: util.toFloat(amount),
      invDate: util.toTS(invDate),
      dueDate: util.toTS(dueDate),
      taxNo, incoterms, refNo, jextra, jitems
    };
    const res = await this.sendCUD('account/CUD', {action: 'SetAccount', data: reqData});
    return res;
  }
  // async accountSet(data) {
  //   const {id, invNo, accType, blNo, branchId, currency, amount, invDate, dueDate, taxNo, incoterms, refNo, jextra, jitems} = data;
  //   const reqData = {
  //     id, invNo, accType, blNo, branchId, currency,
  //     amount: util.toFloat(amount),
  //     invDate: util.toTS(invDate),
  //     dueDate: util.toTS(dueDate),
  //     taxNo, incoterms, refNo, jextra, jitems
  //   };
  //   const res = await this.sendCUD('account/CUD', {action: 'SetAccount', data: reqData});
  //   return res;
  // }

  async accountList(data: QueryListParams) {
    const {qryText, ...reqData} = data;
    return this.sendList('account/ListData', {action: 'ListAccount', data: reqData});
  }

  async accountARPaidList(data: QueryListParams) {
    const {qryText, ...reqData} = data;
    return this.sendList('account/ListData', {action: 'ListARPHistory', data: reqData});
  }

  async bankList(branchId) {
    return this.sendList('api/GetData', {action: 'BranchBank', data: {id: branchId}});
  }

  async branchBankName(branchId) {
    return this.sendList('api/GetData', {action: 'BranchBankName', data: {id: branchId}});
  }

  async payHistoryGet(data) {
    const {accountId, blNo} = data;
    const reqData = {
      accountId: util.toInt(accountId),
      blNo,
    };
    return this.sendList('account/ViewData', {action: 'PayHistoryData', data: reqData});
  }

  async payHistorySave(data) {
    return this.sendList('account/CUD', {action: 'SetPayHistory', data});
  }

  async payHistoryDel(invoiceId, jobId) {
    return this.sendList('account/CUD', {action: 'DelPayHistory', data: {accountId: invoiceId, jobId}});
  }

  async blRateList(data: QueryListParams) {
    const {qryText, ...reqData} = data;
    return this.sendList('account/ListData', {action: 'ListAccountBL', data: reqData});
  }

  async setBLRate(data) {
    const {id, blNo, currency, jitems} = data;
    const reqData = {
      id, blNo, currency, jitems
    };
    return this.sendCUD('account/CUD', {action: 'SetAccountBL', data: reqData});
  }

  async blRateData(id: number) {
    return this.sendList('account/ViewData', {action: 'AccountBLData', data: {id}});
  }

  async profitList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListAccountProfit', data});
  }

  async profitData(filingNo) {
    return this.sendList('account/ListData', {action: 'ListAccount', data: {filingNo}});
  }

  async commissionList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListCommission', data})
  }

  async commissionHistoryDetail(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListCommissionHistory', data})
  }

  async commissionHistorySave(isPaid: boolean, accountIds: number[]) {
    return this.sendCUD('account/CUD', {action: 'BatchDropzoneHistory', data: {isPaid, accountIds}});
  }

  async changeCommission(accountId: number, amount: number) {
    return this.sendCUD('account/CUD', {action: 'ChangeCommission', data: {accountId, amount}});
  }

  async delCommissionHistory(data) {
    return this.sendCUD('account/CUD', {action: 'DelCommissionHistory', data});
  }

  async vendorList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListVendor', data})
  }

  async vendorHistoryDetail(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListVendorHistory', data})
  }

  async vendorHistorySave(isPaid: boolean, accountIds: number[]) {
    return this.sendCUD('account/CUD', {action: 'BatchDropzoneHistory', data: {isPaid, accountIds}});
  }

  async dcnoteList(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListDCNote', data})
  }

  async dcNoteHistoryDetail(data: QueryListParams) {
    return this.sendList('account/ListData', {action: 'ListDCNoteHistory', data})
  }

  async accountDCCurrency(data: QueryListParams) {
    return this.sendList('account/ViewData', {action: 'AccountDCCurrency', data})
  }

  async settlementList(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'AccountCMData', data})
  }

  async setSettlement(data) {
    return this.sendCUD('account/CUD', {action: 'SetAccountCM', data});
  }

  async vendorInvoiceList(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'AccountVDData', data})
  }

  async setVendorInvoice(data) {
    return this.sendCUD('account/CUD', {action: 'SetAccountVD', data});
  }

  async dcNoteDetailList(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'AccountDCData', data})
  }

  async setDCNote(data) {
    return this.sendCUD('account/CUD', {action: 'SetAccountDC', data});
  }

  async accountAPData(data) {
    return this.sendList('account/ViewData', {action: 'AccountAPData', data: data})
  }

  useBankList(branchId) {
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        this.bankList(branchId??0).then(({data}) => {
          const newOptions = data.lists.map((str) => ({value: str, label: str, extra: str}));
          setOptions(newOptions);
        }).catch();
    }, [branchId]);
    return [{value: '', label: ''}, ...options];
  }

  useBranchBankName(branchId) {
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        this.branchBankName(branchId??0).then(({data}) => {
          const newOptions = data.lists.map((str) => ({value: str, label: str, extra: str}));
          setOptions(newOptions);
        }).catch();
    }, [branchId]);
    return [{value: '', label: ''}, ...options];
  }

  async accountVDDataDetail(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'AccountVDDataDetail', data})
  }

  async accountDCDataDetail(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'AccountDCDataDetail', data})
  }

  async accountCMDataDetail(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'AccountCMDataDetail', data})
  }

  async branchList() {
    return this.sendList('api/GetData', {action: 'BranchList', data: {}});
  }

  async deleteAccountData(id) {
    return this.sendCUD('account/CUD', {action: 'DelAccount', data: {id}});
  }

  async deleteAccountBLRate(id) {
    return this.sendCUD('account/CUD', {action: 'DelAccountBL', data: {id}});
  }

  async unpaidStatementList(data) {
    return this.sendList('account/PrintData', {action: 'UnpaidStatement', data})
  }

  async bankInfo(branchId, bankInfo) {
    return this.sendList('account/PrintData', {action: 'BankInfo', data: {branchId, bankInfo}});
  }

  async delAccountVD(id) {
    return this.sendCUD('account/CUD', {action: 'DelAccountVD', data: {id}});
  }

  async delAccountCM(id) {
    return this.sendCUD('account/CUD', {action: 'DelAccountCM', data: {id}});
  }

  async changeVendorPrice(data) {
    return this.sendCUD('account/CUD', {action: 'ChangeVendorPrice', data});
  }

  async applyVendorDCRate(data) {
    return this.sendCUD('account/CUD', {action: 'ApplyVendorDCRate', data});
  }

  async setPaidAllCM(accountId) {
    return this.sendCUD('account/CUD', {action: 'SetPaidAllCM', data: {accountId}});
  }

  async setAPStatement(accountIds: number[]) {
    return this.sendCUD('account/CUD', {action: 'SetAPStatement', data: {accountIds}});
  }

  async delAccountDCNote(id) {
    return this.sendCUD('account/CUD', {action: 'DelAccountDCNote', data: {id}});
  }

  async getARData(data: {id: number}) {
    return this.sendList('account/ViewData', {action: 'GetARData', data})
  }
}

export default APIAccount;
