/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {util} from '../../../../services/service';
import {DefaultButton, PrimaryButton} from "../../../../components/buttons";
import {FD, FN} from "../../../field-defs/newCoship";
import {apiCourier, apiMoving} from "../../../../services/newCoship/service";
import {useSimpleGrid} from "../../../../components/SimpleGrid";
import {AccountTypeSearchInput, TradePartnerSearchInput} from "../../../../components/SearchInput";
import {currencyOptions} from "../../../helper";
import useCourierAccountModal from "../../../../modals/newCoship/courier/useCourierAccountModal";
import useCourierAccountItemsModal from "../../../../modals/newCoship/courier/useCourierAccountItemsModal";
import {
  setHouseAccountList,
  setHouseAPTotal,
  setHouseARTotal,
  setHouseBalanceTotal
} from "../../../../redux/reducers/newCoshipReducer";

class CourierHouseBLAccountItemFormManager extends FormManager {
  isShowForm;
  blId;
  grid;
  arAPList;
  constructor(accType) {
    super({
      prefix: `courier-house-work_orders-form`,
      fields: [FD.INV_ID, FD.JOB_ID, FD.ATYPE_ID, FD.DESCRIPTION, FD.PARTNER_ID, FD.PARTNER_NAME, FD.CURRENCY, FD.PRICE],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.accType = accType;
  };
  setData = (data) => {
    const arList = util.addRowIdToList(data?.ar_list);
    const apList = util.addRowIdToList(data?.ap_list);
    const list = this.accType === 'AR' ? arList : apList;
    this.grid.setRows(list);
  };
  onRender = () => {
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const {arModal, arItemModal, apItemModal} = useCourierHouseBLAccount(this.accType);
    const qs = util.getQS();
    const {id} = qs;
    _f(FN.CURRENCY).options = currencyOptions;
    _f(FN.PARTNER_NAME).label = '';
    _f(FN.PARTNER_NAME).placeholder = 'Partner';

    const _tp = (name, vertical = false) => {
      _f(`${name}_name`).smallMargin = false;
      return (
        <TradePartnerSearchInput
          idField={_n(`${name}_id`)} displayField={_n(`${name}_name`)}
          formik={formik} fields={fields} errors={errors} vertical={vertical}
        />
      );
    };

    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atype_id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical
    />;

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data).catch();
      }
    };

    const grid = useARAPGrid(onAction, this.accType);
    this.grid = grid;

    const onSave = async () => {
      const rows = this.grid.rows ?? [];
      const items = rows?.map((data) => {
        return (
          {
            inv_id: data.inv_id ?? 0,
            job_id: data.job_id ?? 0,
            atype_id: data.atype_id ?? undefined,
            partner_id: data.partner_id ?? undefined,
            partner_name: data.partner_name ?? undefined,
            currency: data.currency ?? undefined,
            price: data.price ? util.toFloat(data.price) : 0,
          }
        );
      });
      const payload = {
        bl_id: util.toInt(this.blId),
        items,
      };
      if (this.accType === 'AR') {
        await apiCourier.setAR(payload).then((res) => {
          if (res) {
            onOpenEditModal(res.data);
          }
        });
        onClear();
      } else {
        await apiCourier.setAP(payload).then((res) => {
          if (res) {
            onOpenEditModal(res.data);
          }
        });
        onClear();
      }
    };

    const onAdd = () => {
      const values = this.getValues();
      if (!values.atype_id) {
        return util.showWarning('Please selet account type!');
      }
      const rows = this.grid.rows ?? [];
      const newRows = util.addRowIdToList([...rows, {...values}]);
      grid.setRows(newRows);
      onClear();
    };

    const onDelete = async (data) => {
      if (data.job_id && data.inv_id) {
        const payload = {
          inv_id: data.inv_id,
          job_id: data.job_id,
          acc_type: this.accType,
        };
        util.showConfirm('Are you sure to delete?', async () => {
          apiCourier.delAccountItem(payload).then((res) => {
            if (res) {
              getNewGrid(data);
            }
          });
        });
      } else {
        util.showConfirm('Are you sure to delete?', async () => {
          getNewGrid(data);
        });
      }
      onClear();
    };

    const getNewGrid = (data) => {
      const items = this.grid.rows?.filter(x => x.rowId !== data.rowId);
      this.grid.setRows(items);
    };

    const onClear = () => {
      this.clearValues();
      _c(FN.CURRENCY, currencyOptions[0].value);
    };

    const onSearch = () => {
      if (this.blId) {
        apiCourier.getAccountData({bl_id: util.toInt(this.blId), kind: 'M'}).then((res) => {
          if (res) {
            setValues(res.data);
            setHouseARTotal(util.dispatch, res.data.ar_amount);
            setHouseAPTotal(util.dispatch, res.data.ap_amount);
            setHouseBalanceTotal(util.dispatch, res.data.balance_amount);
            setHouseAccountList(util.dispatch, res.data.ar_ap_list);
          }
        });
      }
    };

    const setValues = (data) => {
      const arList = util.addRowIdToList(data?.ar_list);
      const apList = util.addRowIdToList(data?.ap_list);
      const list = this.accType === 'AR' ? arList : apList;
      this.grid.setRows(list);
      this.arAPList = data?.ar_ap_list;
      setHouseARTotal(util.dispatch, data?.ar_amount);
      setHouseAPTotal(util.dispatch, data?.ap_amount);
      setHouseBalanceTotal(util.dispatch, data?.balance_amount);
    };

    const onOpenEditModal = (data) => {
      arModal.open(data);
    };

    React.useEffect(() => {
      _c(FN.CURRENCY, currencyOptions[0].value);
    }, []);

    React.useEffect(() => {
      if (!arModal.isOpen) {
        onSearch();
      }
    }, [arModal.isOpen]);

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-8 pr-20 pt-20 pb-1'}>
            <div className={'flex w-full'}>
              <div className={'flex-1 ml-2 mr-4'}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                  <label>{this.accType === 'AR' ? 'Account Receivable' : 'Account Payable'}</label>
                  <div><PrimaryButton label={'APPLY'} onClick={onSave} disabled={!id} noMargin/></div>
                </div>
                <div>
                  {grid.render()}
                  <div className={'flex mt-1'}>
                    <div className={'mr-2 flex-1'}>{_at(FN.DESCRIPTION)}</div>
                    {this.accType === 'AP' && <div className={'mr-2 flex-1'}>{_tp('partner')}</div>}
                    <div className={'mr-2'} style={{width: 100}}>{_r(FN.CURRENCY)}</div>
                    <div className={'mr-2'} style={{width: 100}}>{_r(FN.PRICE)}</div>
                    <DefaultButton label={'Add'} onClick={onAdd} style={{height: 32}} disabled={!id} noMargin/>
                  </div>
                </div>
              </div>
            </div>
            {arModal.render()}
            {arItemModal.render()}
            {apItemModal.render()}
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

function useARAPGrid(onAction, accType) {
  const qs = util.getQS();
  const {id} = qs;
  const getColumns = () => {
    if (accType === 'AR') {
      return [
        {field: 'inv_id', hide: true},
        {field: 'job_id', hide: true},
        {field: 'atype_id', hide: true},
        {field: 'description', headerName: 'Account Type', flex: 1},
        {field: 'currency', headerName: 'Currency', width: 70},
        {field: 'price', headerName: 'Price', width: 70, editable: true},
      ];
    } else {
      return [
        {field: 'inv_id', hide: true},
        {field: 'job_id', hide: true},
        {field: 'atype_id', hide: true},
        {field: 'partner_id', hide: true},
        {field: 'description', headerName: 'Account Type', flex: 1},
        {field: 'partner_name', headerName: 'Partner', flex: 1},
        {field: 'currency', headerName: 'Currency', width: 70},
        {field: 'price', headerName: 'Price', width: 70, editable: true},
      ];
    }
  };

  const actions = id ? ['delete'] : [];

  return useSimpleGrid({
    columns: getColumns(),
    height: 350,
    className: 'pb-20',
    actions,
    actionWidth: 70,
    onAction: onAction,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
    }
  });
}

function useCourierHouseBLAccount(accType) {
  const {...arItemModal} = useCourierAccountItemsModal('AR');
  const {...apItemModal} = useCourierAccountItemsModal('AP');
  const {...arModal} = useCourierAccountModal(accType === 'AR' ? arItemModal: apItemModal, accType);
  return {arModal, arItemModal, apItemModal};
}

export default CourierHouseBLAccountItemFormManager;
