/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useParams, useHistory} from 'react-router-dom';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {DangerButton, DefaultButton} from '../../components/buttons';
import type {ForwardingType} from '../../forms/BLFormManager';
import {api, apiAccount, apiBL, apiSettings, util} from '../../services/service';
import {getBLEntryData} from '../../forms/field-defs/bl';
import {useMarkOEHModal} from '../../forms/mark/MarkOEHFormManager';
import {useMarkOIHModal} from '../../forms/mark/MarkOIHFormManager';
import {useMarkAEHModal} from '../../forms/mark/MarkAEHFormManager';
import {useMarkAIHModal} from '../../forms/mark/MarkAIHFormManager';
import {updateTS} from './BLMaster';
import BLFormManager from '../../forms/BLFormManager';
import BLTopSearchFormManager from '../../forms/BLTopSearchFormManager';
import useAccountPaymentModal from '../../modals/account/useAccountPaymentModal';
import {useUser} from "../../redux/reducers/userReducer";
import {useOEHLabelPrintModal} from '../../forms/print/v2/OEHLabelPrintForm';
import {useAEHLabelPrintModal} from '../../forms/print/v2/AEHLabelPrintForm';
import {useOEHBLPrintModal} from '../../forms/print/v2/OEHBLPrintForm';
import {useOEHBCPrintModal} from '../../forms/print/v2/OEHBCPrintForm';
import {useAEMBLPrintModal} from '../../forms/print/v2/AEMBLPrintForm';
import {useOIHANPrintModal} from '../../forms/print/v2/OIHANPrintForm';
import {useOIHDOPrintModal} from '../../forms/print/v2/OIHDOPrintForm';
import {useAIHDOPrintModal} from '../../forms/print/v2/AIHDOPrintForm';
import {useOIHROPrintModal} from '../../forms/print/v2/OIHROPrintForm';
import {useOIHPCPrintModal} from '../../forms/print/v2/OIHPCPrintForm';
import {useAIHANPrintModal} from '../../forms/print/v2/AIHANPrintForm';
import {useAIHROPrintModal} from '../../forms/print/v2/AIHROPrintForm';
import {useAIHPCPrintModal} from '../../forms/print/v2/AIHPCPrintForm';
import {useManifestPreviewModal} from '../../print-layouts/ManifestPrint';
import {useManifestPreviewModal2} from '../../print-layouts/ManifestPrint2';
import {useManifestPreviewModal3} from '../../print-layouts/ManifestPrint3';
import useBLTrackingModal from "../../modals/bl/useBLTrackingModal";
import {useSendEmailModal} from "../../forms/email/SendEmailFormManager";
import useEmailModal from "../../modals/email/useEmailModal";
import toInt from "validator/es/lib/toInt";
import {labelType} from "../../forms/helper";
import toFloat from "validator/es/lib/toFloat";
import useBLInventoryHistoryModal from "../../modals/bl/useBLInventoryHistoryModal";
import styled from "styled-components";
import ReactLoading from "react-loading";

const blForm = new BLFormManager();
const blSearchForm = new BLTopSearchFormManager();

const cardStyle = {
  marginLeft: -8,
  marginTop: -12,
  width: 'calc(100% + 8px)',
};

const BLHouse = () => {
  const params = useParams() ?? {};
  const history = useHistory();
  const [type, setType] = React.useState<ForwardingType>('');
  const [menuLabel, setMenuLabel] = React.useState(params?.id ? 'Edit House' : 'New House');
  const [currentId, setCurrentId] = React.useState();
  const [jmarkFromMaster, setJmarkFromMaster] = React.useState();
  const [flagColor, setFlagColor] = React.useState('default');
  const [filingNo, setFilingNo] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [masterId, setMasterId] = React.useState(); // 현재 마스터번호를 사용하지는 않지만 추후 사용가능성이 있으므로 저장함
  const [editTime, setEditTime] = React.useState();
  useTopbar({label: 'B/L'}, {label: menuLabel});
  const user = useUser();
  const modals = useHouseModals({type, currentId, user});
  const [isBranch, setIsBranch] = React.useState(true);
  const [branchId, setBranchId] = React.useState();
  const [customerEmail, setCustomerEmail] = React.useState();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  function updateBLForm(data) {
    if (data?.length === 1) {
      setFilingNo(data[0].filing_no);
      if (data[0]['h_m'] === 'M') {
        // 마스터로 검색한 경우
        const {
          jcommon,
          filing_no, bound: tradeType, carrier_type: portType, id, kind,
          jcustomer, jvessel, jroute, jshipment,
        } = data[0];
        const newType = (portType ?? 'O').charAt(0) + (tradeType ?? 'E') + 'H';
        blForm.forwardingType = newType;
        blSearchForm.forwardingType = newType;
        setType(newType);
        setMasterId(id);
        setCurrentId(undefined);
        // 마스터 데이터를 하우스 데이터에 복사해줌
        // OEH 의 경우 etd 가 etdPol 로 정의
        // OEH 의 경우 eta 가 etdPod 로 정의
        // OIH 의 경우 etd 가 polEtd 로 정의
        // OIH 의 경우 eta 가 podEta 로 정의
        const etd = jroute['etd'] ?? jvessel['etd'];
        const eta = jroute['eta'] ?? jvessel['eta'];
        const isGround = jcommon['isGround'] === 'Y';
        const forwardingAgent = jcustomer['shipper'];
        const forwardingAgentId = jcustomer['shipperId'];
        const partner = jcustomer['consignee'];
        const partnerLocal = jcustomer['consigneeLocal'];
        const partnerId = jcustomer['consigneeId'];
        const values = {
          ...jcommon,
          filingNo: filing_no, tradeType, portType, kind,
          // ...jcustomer, ...jvessel, ...jroute, // 커스토머 정보는 복사하지 않음
          ...jvessel, ...jroute,
          etdPol: etd, etaPod: eta,
          polEtd: etd, podEta: eta,
          isGround,
          id: undefined, // 하우스를 생성하는 경우이므로!!!
          forwardingAgent,
          forwardingAgentId,
          partner,
          partnerLocal,
          partnerId,
          ...jshipment,
          customerEta: undefined, customerEtd: undefined,
        };
        updateTS(values);
        blForm.setValuesFast(values);
      } else {
        // 하우스로 검색한 경우
        const {jcommon, jcustomer, jroute, jvessel, jshipment, filing_no: filingNo, id, kind, footer, flag, account, work_assigned, jmark} = data[0];
        const etd = jroute['etd'] ?? jvessel['etd'];
        const eta = jroute['eta'] ?? jvessel['eta'];
        const isGround = jcommon['isGround'] === 'Y';
        const {mblNo, mawbNo} = jcommon;
        const pDate = jcommon['pDate'];
        const pDateStr = jcommon['pDateStr'];
        const packages = jshipment['package'];
        const values = {
          ...jcommon,
          ...jcustomer,
          ...jroute,
          ...jvessel,
          ...jshipment,
          etdPol: etd,
          etaPod: eta,
          id, filingNo, kind, footer, isGround, account, work_assigned,
          ...jmark,
          pDate: pDate ? pDate : util.getCurrentDateTS(),
          pDateStr: pDateStr ? pDateStr : util.formatD(util.getCurrentDate()),
          package: packages ? packages : 1,
        };
        blForm.setWorkAssigned(work_assigned);
        updateTS(values);
        blForm.setValuesFast(values);
        setMasterId(mblNo ?? mawbNo);
        setCurrentId(id);
        setFlagColor(flag === true ? 'danger' : 'default');
        setCustomerEmail(jcustomer['customerEmail']);
        setBranchId(jcommon.branchId);
      }
      setEditTime(data[0].footer.edit[0]);
    } else {
      util.showWarning('No entry found!');
    }
  }
  function updateBLCopyForm(data) {
    if (data?.length === 1) {
      setFilingNo(data[0].filing_no);
      const {jcommon, jcustomer, kind, bound, carrier_type} = data[0];
      const {branchId, currency} = jcommon;
      const isGround = jcommon['isGround'] === 'Y';
      // const values = {...jcommon, ...jcustomer, kind, bound, carrier_type, isGround, package: 1,
      //   hblNo: null, hawbNo: null, mblNo: null, mawbNo: null, totalPrice: null, filing_no: null, id: null, pickupType: null, pDate: util.getCurrentDate(), pTime: null};
      const values = {...jcustomer, branchId, currency, kind, tradeType: bound, portType: carrier_type, isGround, package: 1, pDate: util.getCurrentDate(),
        consigneeAddr: null, consigneeAddrLocal: null, consigneeCity: null, consigneeProvince: null, consigneeCountry: null, consigneePostalCode: null, buildingCode: null};
      blForm.setValuesFast(values);
    }
  }
  async function loadBLEntry(blId?: number) {
    if (blId) {
      const {data} = await api.forwardViewBLEntry({id: blId});
      setTimeout(() => {
        updateBLForm(data);
      }, 0);
    } else {
      const {filingNo, masterNo: blNo, kind, id} = util.getQS();
      if (id) {
        const {data} = await api.forwardViewBLCopyEntry({id});
        setTimeout(() => {
          updateBLCopyForm(data);
        }, 0);
        // util.nav('/admin/bl/house');
      } else if (blNo || filingNo) {
        const {data} = await api.forwardViewBLEntry({blNo, filingNo});
        setTimeout(() => {
          updateBLForm(data);
        }, 0);
        // masterNo가 넘어온 경우 저장시 마스터의 jmark 를 보내 주기 위해 마스터의 jmark 를 저장함
        setJmarkFromMaster(data?.[0]?.jmark);
        // util.nav('/admin/bl/house');
      } else {
        onNew();
      }
      if (kind) {
        blForm.setValue('kind', kind);
      }
    }
  }
  React.useEffect(() => {
    loadBLEntry(params.id).catch();
    return () => {
      blForm.clearValuesFast();
    };
  }, [params.id]);
  React.useEffect(() => {
    const onHistoryChange = () => {
      const {id: idToCopy} = util.getQS();
      if (idToCopy > 0) {
        loadBLEntry().catch();
      }
    };
    const unsub = history.listen(onHistoryChange);
    return () => {
      unsub();
    };
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      if (branchId && user.branch_id !== branchId) {
        // const isUserVancouverSeoul = getBranchName(user.branch_id) === 'VANCOUVER' || getBranchName(user.branch_id) === 'SEOUL';
        const isUserToronto = getBranchName(user.branch_id) === 'TORONTO';
        const isOtherBranchData = getBranchName(branchId) !== 'TORONTO';
        // const isDataToronto = getBranchName(branchId) === 'TORONTO';
        if (isUserToronto && isOtherBranchData) {
          setIsBranch(false);
          blForm.isBranch = false;
        }
      } else {
        setIsBranch(true);
        blForm.isBranch = true;
      }
    }, 0);
  }, [branchId, user.branch_id]);
  blForm.onTradeTypeChange = (tradeType, portType) => {
    const newType = (portType ?? 'O').charAt(0) + (tradeType ?? 'E') + 'H';
    blForm.forwardingType = newType;
    blSearchForm.forwardingType = newType;
    setType(newType);
  };
  blForm.onMawbDelete = async () => loadBLEntry(params.id);
  blSearchForm.onEnter = async (name, value) => {
    if (value) {
      // 마스터 아이디로 검색하는 경우
      const values = blSearchForm.getValues();
      const {masterNo: blNo} = values;
      const {data} = await api.forwardViewBLEntry({blNo});
      updateBLForm(data);
    }
  };
  const getBranchName = (branchId) => {
    let branchOptions;
    try {
      branchOptions = blForm.getField('branchId').options;
    } catch (error) {
      branchOptions = [];
    }
    return branchOptions.find(i => i.value === parseInt(branchId))?.label;
  }
  const onNew = () => {
    blForm.clearValuesFast();
    blSearchForm.clearValuesFast();
    util.nav('/admin/bl/house');
    setMenuLabel('New House');
    setCurrentId(0);
  };
  const onSave = async () => {
    setShowLoading(true);
    const values = blForm.getValues();
    const gridData = blForm.getGridData();
    const data = getBLEntryData(false, {...values, gridData});
    if (type === 'OEH' || type === 'AEH') { // export 인 경우 customerId 가 반드시 입력되어야함!
      const customerId = blForm.getValue('customerId');
      if (!customerId) {
        util.showWarning('Please enter a valid customer!');
        document.querySelector(`#bl-form-customer`).focus();
        setShowLoading(false);
        return;
      }
    }
    if (type === 'OIH' || type === 'AIH') {
      const consigneeId = blForm.getValue('consigneeId');
      if (!consigneeId) {
        util.showWarning('Please enter a valid consignee!');
        document.querySelector(`#bl-form-consignee`).focus();
        setShowLoading(false);
        return;
      }
    }
    if (data.jmark === undefined) {
      data.jmark = jmarkFromMaster;
    }
    const res = await api.forwardAddEditBLEntry(data);
    if (res) {
      const isAir = values['portType']  === 'A' || values['portType'] === 'AIR';
      util.showSuccess('BL Entry has been save successfully.');
      blForm.setValue('id', res.id);
      blForm.setValue('filingNo', res.filing_no);
      blForm.setValue(isAir ? 'hawbNo' : 'hblNo', res.bl_no);
      setCurrentId(res.id);
      setMenuLabel('Edit House');
      util.nav(`/admin/bl/house/${res.id}`);
      loadBLEntry(res.id).catch();
      setShowLoading(false);
    }
    setFilingNo(blForm.getValue('filingNo'));
  };
  const onSaveComment = async (comment) => {
    const res = await api.setComment(currentId, comment);
    if (res) {
      util.showSuccess('BL comment has been save successfully.');
    }
  };
  const onWorkOrder = () => {
    const blNo = blForm.getValue('hblNo') ?? blForm.getValue('hawbNo');
    const branchId = blForm.getValue('branchId');
    const kind = blForm.getValue('kind');
    // util.nav(`/admin/schedule/workorder?blNo=${blNo}&branchId=${branchId}&kind=${kind}`);
    util.nav(`/admin/schedule/workorder?blNo=${blNo}`);
  };
  const onMaster = async () => {
    const res = await apiBL.vViewMBLId(filingNo);
    if(res) {
      util.nav(`/admin/bl/master/${res?.data[0]?.blId}`);
    }
  };
  const onDelete = () => {
    if (currentId) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = api.forwardDelBLEntry(currentId);
        if (res) {
          util.showSuccess('B/L data has been deleted successfully!');
          // onNew();
          util.nav('/admin/bl/list');
        }
      });
    }
  };
  const onCopy = () => {
    util.openTab(`/admin/bl/house?id=${currentId}`);
  }
  const onAccount = () => {
    const values = blForm.getValues();
    const blNo = values.portType === 'A' ? values.hawbNo : values.hblNo
    util.openTab('/admin/account/list?blNo=' + blNo);
  }
  const onFlag = async () => {
    const isFlagged = flagColor === 'danger';
    const res = await apiBL.setFlag(currentId, !isFlagged);
    if (res) {
      setFlagColor(isFlagged ? 'default' : 'danger');
    }
  };
  const {
    onArrivalNotice, onBookingConfirm, onDeliveryOrder, onLabel, onMark,
    onPaymentHistory, onPreliminaryClaim, onPrint, onReleaseOrder, onEmail, onInventoryHistoryModal,
  } = modals;
  const isOcean = type.charAt(0) !== 'A';
  const title = type ? `${type} ${isOcean ? 'B/L' : 'AWB'} Entry` : '';
  document.title = title;
  blSearchForm.isEditMode = !!currentId;
  blForm.onSave = onSave;
  blForm.onSaveComment = onSaveComment;
  blForm.onInventoryHistoryModal = onInventoryHistoryModal;
  function renderButtons() {
    if (!currentId || !type) return null;
    switch (type) {
      case 'OEH':
        return (
          <>
            <DefaultButton label={'Arrival Notice'} onClick={onArrivalNotice} noMargin /><div className={'w-8'} />
            <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
            <DefaultButton label={'Booking Confirm'} onClick={onBookingConfirm} noMargin /><div className={'w-8'} />
            <DefaultButton label={'Label'} onClick={onLabel} noMargin /><div className={'w-8'} />
            <DefaultButton label={'P. History'} onClick={onPaymentHistory} noMargin /><div className={'w-8'} />
          </>
        );
      case 'AEH':
        return (
          <>
            <DefaultButton label={'Arrival Notice'} onClick={onArrivalNotice} noMargin /><div className={'w-8'} />
            <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
            {/*<DefaultButton label={'S/I'} onClick={() => {}} noMargin /><div className={'w-8'} />*/}
            <DefaultButton label={'Label'} onClick={onLabel} noMargin /><div className={'w-8'} />
            <DefaultButton label={'P. History'} onClick={onPaymentHistory} noMargin /><div className={'w-8'} />
          </>
        );
      case 'OIH':
      case 'AIH':
        return (
          <>
            <DefaultButton label={'Arrival Notice'} onClick={onArrivalNotice} noMargin /><div className={'w-8'} />
            <DefaultButton label={'D/O'} onClick={onDeliveryOrder} noMargin /><div className={'w-8'} />
            <DefaultButton label={'Release Order'} onClick={onReleaseOrder} noMargin /><div className={'w-8'} />
            <DefaultButton label={'P. Claim'} onClick={onPreliminaryClaim} noMargin /><div className={'w-8'} />
            <DefaultButton label={'P. History'} onClick={onPaymentHistory} noMargin /><div className={'w-8'} />
          </>
        );
      default:
        throw new Error(`Invalid type (${type})`);
    }
  }
  function renderAccountInfo() {
    const [accountInfo, setAccountInfo] = React.useState();
    const [renderCount, setRenderCount] = React.useState(0);
    if (!currentId || !blForm.formik) {
      return null;
    }
    const account = blForm.getValue('account');
    // const account = [
    //   {'currency': 'CAD', 'amount': 2800.39, 'balance': 0.00, 'invoice': 2},
    //   {'currency': 'KRW', 'amount': 280000, 'balance': 20000, 'invoice': 1},
    //   {'currency': 'USD', 'amount': 200.00, 'balance': 0.00, 'invoice': 1}
    // ];
    if (!(account?.length > 0)) {
      return null;
    } else {
      if (renderCount < 1) {
        setAccountInfo(account.map(i => `${i.currency} ${util.formatCurrency(i.amount, i.currency)}/${util.formatCurrency(i.balance, i.currency)} (${i.invoice})`).join(', '));
        setRenderCount(renderCount+1);
      }
    }
    const balanceTotal = account.reduce((accum, i) => accum + i.balance, 0);
    const onAccount = async () => {
      await apiAccount.getARData({id: currentId}).then(res => {
        if(res) {
          setAccountInfo(res.data.map(i => `${i.currency} ${util.formatCurrency(i.amount, i.currency)}/${util.formatCurrency(i.balance, i.currency)} (${i.invoice})`).join(', '));
        }
      });
    };
    return (
      <div style={{position: 'absolute', left: 20, bottom: 10, fontWeight: 'bold', color: balanceTotal > 0 ? 'red' : 'black', cursor: 'pointer'}} onClick={onAccount}>
        {accountInfo}
      </div>
    );
  }
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'} style={{position: 'relative'}}>
          {showLoading && <LoadingContainer><ReactLoading color={'grey'} type={'spin'} height={100} width={100}/></LoadingContainer>}
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              {/*<DefaultButton label={'New'} onClick={onNew} noMargin /><div className={'w-8'} />*/}
              <DefaultButton disabled={!isBranch} label={'Save'} onClick={onSave} noMargin disabled={showLoading}/><div className={'w-8'} />
              {!!currentId && (<><DefaultButton label={'Work Order'} onClick={onWorkOrder} noMargin /><div className={'w-8'} /></>)}
              {!!currentId && filingNo && (<><DefaultButton label={'MB/L'} onClick={onMaster} noMargin /><div className={'w-8'} /></>)}
              {!!currentId && user.isManager && (<><DangerButton disabled={!isBranch} label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
              {!!currentId && (
                <><DefaultButton label={'Copy'} onClick={onCopy} noMargin /><div className={'w-8'} /></>
              )}
              {!!currentId && (
                <><DefaultButton label={'Account'} onClick={onAccount} noMargin /><div className={'w-8'} /></>
              )}
              {!!currentId && (
                <><DefaultButton label={'Mark & Desc'} onClick={onMark} noMargin /><div className={'w-8'} /></>
              )}
              {renderButtons()}
              {!!currentId && (
                <>
                  {
                    flagColor === 'default'
                    ? <DefaultButton label={'Flag'} onClick={onFlag} noMargin />
                    : <DangerButton label={'Flag'} onClick={onFlag} noMargin />
                  }
                  <div className={'w-8'} />
                </>
              )}
              {/*{!!currentId && (*/}
              {/*  <><DefaultButton label={'Tracking'} onClick={onTracking} noMargin /><div className={'w-8'} /></>*/}
              {/*)}*/}
              {!!currentId && customerEmail && (
                <><DefaultButton label={'E-mail'} onClick={onEmail} noMargin /><div className={'w-8'} /></>
              )}
            </div>
          </div>
          {blSearchForm.renderForm()}
          {renderAccountInfo()}
          <div className={'pt-1'}>{'\u00a0'}</div>
          {!!currentId && blForm.formik && util.renderFooter(blForm.getValue('footer'), 'house-footer')}
        </CardBody>
      </Card>
      {blForm.renderForm()}
      {modals.renderAll()}
    </>
  );
};

function useHouseModals({type, currentId, user}) {
  const onSaveBookingConfirm = async (data) => {
    if (!currentId) return;
    const res = await api.setBookConfirm({id: currentId, jbookConfirm: data.getValues()});
    if (res) {
      util.showSuccess('BL Booking Confirm has been saved successfully.');
      switch (type) {
        case 'OEH': oehBCPrintModal.close(); break;
        default: throw new Error(`Invalid type (${type})`);
      }
    }
  };

  const onSaveMark = async (mode, data) => {
    if (!currentId) return;
    const res = await api.forwardSetBLMark({id: currentId, jmark: data});
    if (res) {
      util.showSuccess('BL Mark & Desc. has been saved successfully.');
      switch (type) {
        case 'OEH': oehMarkModal.close(); break;
        case 'OIH': oihMarkModal.close(); break;
        case 'AEH': aehMarkModal.close(); break;
        case 'AIH': aihMarkModal.close(); break;
        default: throw new Error(`Invalid type (${type})`);
      }
    }
  };

  const onMark = async () => {
    if (!currentId) return;
    const res = await api.forwardViewBLMark(currentId);
    if (res?.data?.length !== 1) return;
    const {id, bl_no, filing_no, h_m, jmark = {}} = res['data'][0];
    const data = {id, bl_no, filing_no, h_m, ...jmark};
    switch (type) {
      case 'OEH': oehMarkModal.open('edit', data); break;
      case 'OIH': oihMarkModal.open('edit', data); break;
      case 'AEH': aehMarkModal.open('edit', data); break;
      case 'AIH': aihMarkModal.open('edit', data); break;
      default: throw new Error(`Invalid type (${type})`);
    }
  };
  const onPrint = () => {
    switch (type) {
      case 'OEH':
        oehBLPrintModal.open();
        break;
      case 'AEH':
        aehBLPrintModal.open();
        break;
      default:
        throw new Error(`Invalid type (${type})`);
    }
  };
  const onBookingConfirm = async () => {
    switch (type) {
      case 'OEH': oehBCPrintModal.open(); break;
      default: throw new Error(`Invalid type (${type})`);
    }
  };
  const onLabel = () => {
    switch (type) {
      case 'OEH':
        const masterNo = blForm.getValue('mblNo');
        if (!masterNo) {
          util.showWarning('MB/L No. is not set!');
          return;
        }
        oehLabelPrintModal.open();
        break;
      case 'AEH':
        const kind = blForm.getValue('kind');
        if (kind === 'C') {
          const masterNo = blForm.getValue('mawbNo');
          const chargeableWeightKg = blForm.getValue('chargeableWeightKg');
          const shipper = blForm.getValue('shipper');
          const consignee = blForm.getValue('consignee');
          const consigneePhone = blForm.getValue('consigneePhone1');
          const consigneeAddrLocal = blForm.getValue('consigneeAddrLocal');
          const packages = typeof blForm.getValue('package') === 'string' ? toInt(blForm.getValue('package')) : blForm.getValue('package');
          const gridData = blForm.getGridData();
          if (!masterNo) {
            util.showWarning('MAWB No. is not set!');
            return;
          }
          if (!toFloat(chargeableWeightKg)) {
            util.showWarning('Please enter chargeable weight!');
            return;
          }
          if (gridData.length <= 0) {
            util.showWarning('Please enter shipment item!');
            return;
          }
          if (!shipper) {
            util.showWarning('Please enter shipper!');
            return;
          }
          if (!consignee) {
            util.showWarning('Please enter consignee!');
            return;
          }
          if (!consigneePhone) {
            util.showWarning('Please enter consignee phone number!');
            return;
          }
          if (!consigneeAddrLocal) {
            util.showWarning('Please enter consignee address!');
            return;
          }
          if (!packages) {
            util.showWarning('Please enter package!');
            return;
          }
          const blNo = blForm.getValue('hawbNo');
          const branchId = blForm.getValue('branchId');
          const branchName = blForm.getField('branchId').options.find(i => i.value === (typeof branchId === 'number' ? branchId : Number(branchId))).label;
          apiBL.getBLManifestList({blNo, branchId, branchName}).then(({data}) => {
            if (data?.length > 0) {
              if(user.label_type === labelType.LABEL3) {  // CJ 대한통운
                manifestPreviewModal3.open(data, data[0].ctns > 1);
              } else if(user.label_type === labelType.LABEL2) { // 우체국
                manifestPreviewModal2.open(data, data[0].ctns > 1);
              } else {  // LETTER
                manifestPreviewModal.open(data);
              }
              apiBL.setBLPrint(data.map(r => r['bl_no'])).catch();
            }
          });
        } else {
          aehLabelPrintModal.open();
        }
        break;
      default:
        break;
    }
  };
  const onArrivalNotice = () => {
    const portType = blForm.getValue('portType');
    switch (portType) {
      case 'O':
        oihANPrintModal.open();
        break;
      case 'A':
        aihANPrintModal.open();
        break;
      default:
        throw new Error(`Invalid type (${type})`);
    }
  };
  const onDeliveryOrder = () => {
    switch (type) {
      case 'OIH':
        oihDOPrintModal.open();
        break;
      case 'AIH':
        aihDOPrintModal.open();
        break;
      default:
        throw new Error(`Invalid type (${type})`);
    }
  };
  const onReleaseOrder = () => {
    switch (type) {
      case 'OIH':
        oihROPrintModal.open();
        break;
      case 'AIH':
        aihROPrintModal.open();
        break;
      default:
        throw new Error(`Invalid type (${type})`);
    }
  };
  const onPreliminaryClaim = () => {
    switch (type) {
      case 'OIH':
        oihPCPrintModal.open();
        break;
      case 'AIH':
        aihPCPrintModal.open();
        break;
      default:
        throw new Error(`Invalid type (${type})`);
    }
  };
  const onPaymentHistory = () => {
    const blNo = blForm.getValue('hblNo') ?? blForm.getValue('hawbNo');
    payHistoryModal.open(undefined, undefined, blNo, true);
  };
  const onTracking = () => {
    const blId = blForm.getValue('id') ?? 0;
    trackingModal.open(blId);
  };

  const onSendEmail = (mode, data) => {
    const {pic_email, pic_name, toEmail, template, title, message, selectedFile, blId, partnerId} = data;
    if(!template && mode === 'send') {
      return util.showWarning('Please select template!');
    }
    if(!pic_email) {
      return util.showWarning('Please enter a valid email!');
    }
    if(!message) {
      return util.showWarning('Please enter a valid message!');
    }
    const formData = new FormData();
    formData.append('blId', blId);
    formData.append('partnerId', partnerId);
    formData.append('from', pic_email);
    formData.append('fromName', pic_name);
    formData.append('to', toEmail);
    formData.append('subject', title);
    formData.append('message', message);
    formData.append('houses', blId);
    formData.append('file', selectedFile);
    const res = apiSettings.sendEmail(formData);
    if(res) {
      util.showSuccess('Email has been sent successfully.');
      sendEmailModal.close();
      emailModal.onSearch().catch();
    }
  };

  const onEmail = () => {
    const blId = blForm.getValue('id') ?? 0;
    const partnerId = blForm.getValue('customerId') ?? 0;
    const customerEmail = blForm.getValue('customerEmail') ?? 0;
    emailModal.open(blId, partnerId, customerEmail, sendEmailModal);
  };

  const onInventoryHistoryModal = () => {
    const blId = blForm.getValue('id') ?? 0;
    inventoryHistoryModal.open(blId);
  };

  const oehBLPrintModal = useOEHBLPrintModal(false, blForm);
  const aehBLPrintModal = useAEMBLPrintModal(blForm, false);
  const oehLabelPrintModal = useOEHLabelPrintModal(blForm);
  const oehBCPrintModal = useOEHBCPrintModal(onSaveBookingConfirm, blForm);
  const aehLabelPrintModal = useAEHLabelPrintModal(blForm);
  const oihANPrintModal = useOIHANPrintModal(blForm);
  const oihDOPrintModal = useOIHDOPrintModal(blForm);
  const aihDOPrintModal = useAIHDOPrintModal(blForm);
  const oihROPrintModal = useOIHROPrintModal(blForm);
  const oihPCPrintModal = useOIHPCPrintModal(blForm);
  const aihANPrintModal = useAIHANPrintModal(blForm);
  const aihROPrintModal = useAIHROPrintModal(blForm);
  const aihPCPrintModal = useAIHPCPrintModal(blForm);

  const oehMarkModal = useMarkOEHModal(onSaveMark, blForm);
  const oihMarkModal = useMarkOIHModal(onSaveMark, blForm);
  const aehMarkModal = useMarkAEHModal(onSaveMark, blForm);
  const aihMarkModal = useMarkAIHModal(onSaveMark, blForm);
  const payHistoryModal = useAccountPaymentModal();
  const manifestPreviewModal = useManifestPreviewModal();
  const manifestPreviewModal2 = useManifestPreviewModal2();
  const manifestPreviewModal3 = useManifestPreviewModal3();
  const trackingModal = useBLTrackingModal();
  const sendEmailModal = useSendEmailModal(onSendEmail, '', blForm);
  const emailModal = useEmailModal();
  const inventoryHistoryModal = useBLInventoryHistoryModal();
  return {
    onMark,
    onPrint,
    onBookingConfirm,
    onLabel,
    onArrivalNotice,
    onDeliveryOrder,
    onReleaseOrder,
    onPreliminaryClaim,
    onPaymentHistory,
    onTracking,
    onEmail,
    onInventoryHistoryModal,
    renderAll: () => {
      return (
        <>
          {oehLabelPrintModal.render()}
          {oehBLPrintModal.render()}
          {aehBLPrintModal.render()}
          {oehBCPrintModal.render()}
          {aehLabelPrintModal.render()}
          {oihANPrintModal.render()}
          {oihDOPrintModal.render()}
          {aihDOPrintModal.render()}
          {oihROPrintModal.render()}
          {oihPCPrintModal.render()}
          {aihANPrintModal.render()}
          {aihROPrintModal.render()}
          {aihPCPrintModal.render()}
          {oehMarkModal.render()}
          {oihMarkModal.render()}
          {aehMarkModal.render()}
          {aihMarkModal.render()}
          {payHistoryModal.render()}
          {manifestPreviewModal.render()}
          {manifestPreviewModal2.render()}
          {manifestPreviewModal3.render()}
          {trackingModal.render()}
          {sendEmailModal.render()}
          {emailModal.render()}
          {inventoryHistoryModal.render()}
        </>
      );
    },
  };
}

export default BLHouse;

export const LoadingContainer = styled.div`
  position:absolute;
  z-index:100;
  width:100%;
  margin: auto;
  margin-top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
