/**
 * @flow
 */
import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import Layout from '../containers/Layout';
import {util, api} from '../services/service';
import {updateUser, useUser} from '../redux/reducers/userReducer';
import {useDispatch} from 'react-redux';

export interface LayoutContainerProps {
  title?: string;
  subtitle?: string;
  children?: React$Node;
}

const LayoutContainer = ({title, subtitle, children}: LayoutContainerProps) => {
  const user = useUser();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const token = localStorage.getItem('@token');
    if (!token) {
      util.nav('/admin/login');
    }
    if (!user.login_id) {
      api.queryUser().then(data => updateUser(dispatch, data)).catch();
    }
  }, []);
  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Container>
          {(title || subtitle) && (
            <Row>
              <Col md={12}>
                {title && <h3 className="page-title">{title}</h3>}
                {subtitle && <h3 className="page-subhead subhead">{subtitle}</h3>}
              </Col>
            </Row>
          )}
          {children}
        </Container>
      </div>
    </>
  );
}

export default LayoutContainer;
