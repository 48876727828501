import styled from 'styled-components';

export const TabButton = styled.div`
 display:flex;
 justify-content:space-evenly;
 width:150px;
 height:30px;
 padding:4px;
 background-color:white;
 border-radius: 5px 5px 0px 0px;
 box-sizing: border-box;
 font-size:15px;
 color:gray;
 margin:0 2px;
 cursor: pointer;
`

export const LoadingContainer = styled.div`
  position:absolute;
  z-index:100;
  width:100%;
  margin: auto;
  margin-top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
