/**
 * @flow
 */
import React from 'react';

class OEMLabel extends React.Component {
  render() {
    const {printData: {printFormData} = {}} = this.props;
    if (!printFormData) {
      return null;
    }
    const {mblNo, destination, number1, number2, labels} = printFormData;
    let from = parseInt(number1) - 1;
    let to = parseInt(number2) - 1;
    return (
      <div>
        {labels.filter((_, index) => index >= from && index <= to).map(label => this.renderItem(mblNo, destination, label))}
      </div>
    );
  }
  renderItem(mblNo, destination, {consignee, houseblno, pieces}) {
    const {renderRow: _row} = this;
    return (
      <div key={pieces} className={'page-break'} style={{color: 'black'}}>
        <div style={{fontSize: 70, fontWeight: 'bold'}}>LMS, INC.</div>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          {_row('TO', this.renderTo(consignee))}
          {_row('MB/L NO.', mblNo)}
          {_row('HB/L NO.', houseblno)}
          {_row('DESTINATION', destination)}
          {_row('PIECES', pieces)}
          </tbody>
        </table>
      </div>
    );
  }
  renderRow(label, value) {
    return (
      <tr>
        <td>
          <div style={{fontSize: 40}}>{label}</div>
          <div className={'px-16 py-8'} style={{fontSize: 50, fontWeight: 'bold'}}>{value}</div>
        </td>
      </tr>
    );
  }
  renderTo(text) {
    return (
      <div style={{fontSize: 50, fontWeight: 'bold', whiteSpace: 'pre-line'}}>{text}</div>
    );
  }
}

export default OEMLabel;
