/**
 * @flow
 */
import React from 'react';
import {TAB_ACCOUNT} from '../../modal-tabs/types';
import useAccountCommissionGridView from '../../grids/account/useAccountCommissionGridView';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import useCommissionDetailModal from "../../modals/account/useCommissionDetailModal";

const AccountCommission = () => {
  useTopbar({label: 'Account'}, {label: 'Commission'});
  const detailModal = useCommissionDetailModal();
  const grid = useAccountCommissionGridView(TAB_ACCOUNT, detailModal);
  React.useEffect(() => {
    if (detailModal.isOpen === false) {
      grid.query();
    }
  }, [detailModal.isOpen]);
  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        {grid.render()}
      </div>
      {detailModal.render()}
    </div>
  );
};

export default AccountCommission;
