/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import AccountInvoiceDCNoteFormManager, {getAccountEntryData, getAccountFormData} from '../../forms/account/AccountInvoiceDCNoteFormManager';
import AccountTopSearchFormManager from '../../forms/account/AccountTopSearchFormManager';
import AccountInvoiceAddItemFormManager from '../../forms/account/AccountInvoiceAddItemFormManager';
import {DangerButton, DefaultButton} from '../../components/buttons';
import {apiAccount, util} from '../../services/service';
import {INVOICE} from "../../forms/field-defs/account";
import {useInvoiceDCNotePreviewModal} from "../../print-layouts/InvoiceDCNotePrint";
import {useUser} from "../../redux/reducers/userReducer";

const type = INVOICE.TYPE_DC_NOTE;
const title = 'D/C Note';
// let id = new URL(window.location.href).searchParams.get('id');

const accountForm = new AccountInvoiceDCNoteFormManager();
const accountSearchForm = new AccountTopSearchFormManager(type);
const accountInvoiceAddItem = new AccountInvoiceAddItemFormManager(type);

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const AccountInvoiceDCNote = () => {
  useTopbar({label: 'Account'}, {label: title});
  const invoiceDCNotePrintModal = useInvoiceDCNotePreviewModal();
  const user = useUser();
  const isManagerRef = React.useRef();
  isManagerRef.current = user.isManager;
  const qs = util.getQS();
  let mode = qs.id ? 'edit' : 'add';
  document.title = `${title} ${mode}`;

  const onSave = async () => {
    // if(!util.isValidPermitForAccount(user, mode)) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    const values = accountForm.getValues(undefined, true);
    if (!values) {
      return;
    }
    const gridData = accountInvoiceAddItem.getGridData();
    const jexchange = {CAD: util.toFloat(values.CAD), USD: util.toFloat(values.USD), KRW: util.toFloat(values.KRW)};
    const data = getAccountEntryData(values, jexchange, gridData);
    const invDate = util.toTS(data.inv_date);
    const dataInfo = {...data, invDate};
    const res = await apiAccount.setDCNote(dataInfo);
    if (res) {
      util.showSuccess('Account Entry has been saved successfully.');
      onSearch();
    }
  };
  const onPrint = () => {
    // ** 2021-06-09 dc note print detail data **
    // /account/ViewData action: AccountDCDataDetail
    // _act: ViewAccountDetail, _ret: AccountDCDataDetail
    const branchId = accountForm.getValue('financialOffice');
    const bankInfo = accountForm.getValue('bankInfo');
    apiAccount.bankInfo(branchId, bankInfo).then(response => {
      apiAccount.accountDCDataDetail({id: qs.id}).then((res) => {
        const {data} = res;
        invoiceDCNotePrintModal.open(accountForm, accountSearchForm, accountInvoiceAddItem, data, response.data[0]);
      });
    });
  };

  const onDelete = () => {
    if(qs.id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.delAccountDCNote(parseInt(qs.id));
        if(res) {
          util.showSuccess('Account has been deleted successfully!');
          util.nav('/admin/account/list');
        }
      })
    }
  };

  const onSearch = () => {
    apiAccount.dcNoteDetailList({id: qs.id}).then(({data}) => {
      if (Array.isArray(data)) {
        const {formData, gridData} = getAccountFormData(data[0]);
        accountSearchForm.mode = mode;
        accountForm.setValuesFast(formData);
        accountInvoiceAddItem.setAccountId(qs.id);
        accountInvoiceAddItem.setGridData(gridData);
        accountInvoiceAddItem.setDefaultCurrency(formData.currency);
      } else {
        // api 에서 오류를 처리하므로 여기서는 그냥 아무작업도 하지 않음
      }
    });
  };

  React.useEffect(() => {
    if (qs.id) {
      onSearch();
    }
  }, [qs.id]);

  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              <DefaultButton label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
              <DefaultButton label={'Print'} onClick={onPrint} noMargin /><div className={'w-8'} />
              {isManagerRef.current && (<><DangerButton label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} /></>)}
            </div>
          </div>
          {accountSearchForm.renderForm()}
        </CardBody>
      </Card>
      {accountForm.renderForm()}
      {accountInvoiceAddItem.renderForm()}
      {invoiceDCNotePrintModal.render()}
    </>
  );
};

export default AccountInvoiceDCNote;
