/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {useSelector} from "react-redux";
import {util} from "../services/service";

class PackingListPrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (printData?.length === 0) {
      return null;
    }
    return (
      <div className={'page-break'}>
        {printData?.map((data, index) => <div key={`prints-${index}`} className={'page-break'} style={{color: 'black'}}><PackingList blData={data}/></div>)}
      </div>
    );
  }
}

const PackingList = ({blData}) => {
  const {kind, packDate, packFreight} = useSelector(state => state['newCoship']);
  const totalValueRef = React.useRef(0);
  const height1 = 25;
  const height2 = 75;
  const size = kind === 'M' ? 70 : 35;
  const arrData = util.chunk(blData?.pack_list, size);
  const isMultiPage = arrData.length > 0;
  const pathName = document.location.pathname.split('/')[2];

  const renderHeader = (items, index) => {
    let salingOnAbout = blData.etd;
    if (kind === 'E') {
      if (blData.etd) {
        if (blData.eta) {
          salingOnAbout = `${blData.etd} ~ ${blData.eta}`;
        } else {
          salingOnAbout = `${blData.etd} ~ }`;
        }
      } else {
        if (blData.eta) {
          salingOnAbout = ` ~ ${blData.eta}`;
        } else {
          salingOnAbout = '';
        }
      }
    }

    return (
      <>
      <div style={{fontSize: 30, fontWeight: 'bold', textAlign: 'center'}}>INVOICE & PACKING LIST</div>
      <table className={'print-table'} style={{width: '100%'}}>
        <tbody>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex-1 fb-14'}>{'1) SHIPPER / EXPORTER'}</td>
          <td className={'flex-1 fb-14 no-border-l'}>{'8) NO.&DATE OF INVOICE'}</td>
        </tr>
        <tr className={'flex'} style={{height: height2}}>
          <td className={'flex-1 no-border-t'}>
            <div style={{height: height1}}>{blData.shipper}</div>
            <div style={{height: height1}}>{blData.shipperAddrEng}</div>
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>
            <div style={{height: height1}}>{blData.hblNo ?? ' '}</div>
            {(kind === 'M' || kind === 'F') && <div style={{height: height1}}>{packDate}</div>}
            {(kind === 'E') && <div style={{height: height1}}>{''}</div>}
            <div style={{textAlign: 'right'}}>{`PAGE ${index+1} OF ${arrData?.length > 0 ? arrData?.length : 1}`}</div>
          </td>
        </tr>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex-1 no-border-t fb-14'}>{'2) FOR ACCOUNT & RISK OF MESSRS'}</td>
          <td className={'flex-1 no-border-t fb-14 no-border-l'}>{'9) QUANTITY & WEIGHT'}</td>
        </tr>
        <tr className={'flex'} style={{height: height2}}>
          <td className={'flex-1 no-border-t'}>
            <div style={{height: height1}}>{blData.consignee}</div>
            <div style={{height: height1}}>{blData.consigneeAddr}</div>
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>
            <div style={{height: height1}}>Quantity: {items?.length ?? ''}</div>
            <div style={{height: height1}}>Weight: {blData.grossWeightKg ?? ''}</div>
          </td>
        </tr>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex-1 no-border-t fb-14'}>{'3) NOTIFY PARTY'}</td>
          <td className={'flex-1 no-border-t no-border-l'}>{''}</td>
        </tr>
        <tr className={'flex'} style={{height: height2}}>
          <td className={'flex-1 no-border-t'}>
            {blData.same_as_consignee &&
              <>
              <div style={{height: height1}}>{'SAME AS ABOVE'}</div>
              <div style={{height: height1}}>{''}</div>
              </>
            }
            {!blData.same_as_consignee &&
              <>
              <div style={{height: height1}}>{blData.notify}</div>
              <div style={{height: height1}}>{blData.notifyAddr}</div>
              </>
            }
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>
            {/*{kind === 'M' &&*/}
            {/*  <>*/}
            {/*  <div>{`GROSS WT: ${blData.grossWeightKg ?? ''}`}</div>*/}
            {/*  <div>{`TOTAL [${items?.length ?? ''}] P'KGS ONLY`}</div>*/}
            {/*  </>*/}
            {/*}*/}
            {/*{kind === 'E' &&*/}
            {/*  <>*/}
            {/*  <div>{`WEIGHT: ${blData.grossWeightKg ?? ''}`}</div>*/}
            {/*  <div>{`VALUE: ${totalValueRef.current ?? ''}`}</div>*/}
            {/*  </>*/}
            {/*}*/}
            {kind === 'F' &&
              <>
              <div>{`FREIGHT ${packFreight}`}</div>
              <div>{`GROSS WT: ${blData.grossWeightKg ?? ''}`}</div>
              <div>{`TOTAL [${items?.length ?? ''}] P'KGS ONLY`}</div>
              <div>{`TOTAL VALUE [${items?.length ?? ''}]`}</div>
              </>
            }
          </td>
        </tr>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex flex-1 no-border-t'}>
            <div className={'flex-1 fb-14'}>{'4) PORT OF LOADING'}</div>
            <div className={'flex-1 fb-14'} style={{borderLeft: '1px solid black', paddingLeft: 8}}>{'5) PORT OF DISCHARGE'}</div>
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>{''}</td>
        </tr>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex flex-1 no-border-t'}>
            <div className={'flex-1'}>{blData.polLabel}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black', paddingLeft: 8}}>{blData.podLabel}</div>
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>{''}</td>
        </tr>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex flex-1 no-border-t'}>
            <div className={'flex-1 fb-14'}>{'6) CARRIER'}</div>
            <div className={'flex-1 fb-14'} style={{borderLeft: '1px solid black', paddingLeft: 8}}>{'7) SALING ON ABOUT'}</div>
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>{''}</td>
        </tr>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'flex flex-1 no-border-t'}>
            <div className={'flex-1'}>{blData.vslVoy}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black', paddingLeft: 8}}>{salingOnAbout}</div>
          </td>
          <td className={'flex-1 no-border-t no-border-l'}>{''}</td>
        </tr>
        </tbody>
      </table>
      </>
    );
  };

  const renderItems = (items) => {
    if (pathName === 'moving') {
      if (kind === 'E') {
        return renderItemsE(items);
      } else {
        return renderItemsM(items);
      }
    } else if (pathName === 'forwarding') {
      return renderItemsF(items);
    } else if (pathName === 'courier') {
      return renderItemsM(items);
    }
  };

  const renderItemsM = (items) => {
    const packingList1 = items?.filter((_, index) => index < 35);
    const packingList2 = items?.filter((_, index) => index >= 35 && index < 70);
    return (
      <>
      <div className={'flex'}>
        <table className={'print-table flex-1'} style={{width: '100%'}}>
          <tbody>
          <tr className={'flex'} style={{height: height1}}>
            <td className={'no-border-t fb-14 center'} style={{width: 50}}>{'C/NO.'}</td>
            <td className={'no-border-t no-border-l fb-14 flex-1 center'}>{'DESCRIPTION OF GOODS'}</td>
            <td className={'no-border-t no-border-l fb-14 center'} style={{width: 100}}>{'VALUE'}</td>
          </tr>
          {packingList1?.map((data, index) => {
            const price = pathName === 'moving' ? data.value : data.unit_price;
            return (
              <tr key={`packingList1-${index}`} className={'flex'} style={{height: height1}}>
                <td className={'no-border-t'} style={{width: 50}}>{data.c_no}</td>
                <td className={'no-border-t no-border-l flex-1'}>{data.item}</td>
                <td className={'no-border-t no-border-l right'} style={{width: 100}}>{price ? util.formatCurrency(price) : ''}</td>
              </tr>
            );
          })}
          {renderEmptyLineM(packingList1?.length, 'L')}
          </tbody>
        </table>
        <table className={'print-table flex-1'} style={{width: '100%'}}>
          <tbody>
          <tr className={'flex'} style={{height: height1}}>
            <td className={'no-border-t no-border-l fb-14 center'} style={{width: 50}}>{'C/NO.'}</td>
            <td className={'no-border-t no-border-l fb-14 flex-1 center'}>{'DESCRIPTION OF GOODS'}</td>
            <td className={'no-border-t no-border-l fb-14 center'} style={{width: 100}}>{'VALUE'}</td>
          </tr>
          {packingList2?.map((data, index) => {
            const price = pathName === 'moving' ? data.value : data.unit_price;
            return (
              <tr key={`packingList2-${index}`} className={'flex'} style={{height: height1}}>
                <td className={'no-border-t no-border-l center'} style={{width: 50}}>{data.c_no}</td>
                <td className={'no-border-t no-border-l flex-1'} style={{paddingLeft: 5}}>{data.item}</td>
                <td className={'no-border-t no-border-l center'} style={{width: 100}}>{price ? util.formatCurrency(price) : ''}</td>
              </tr>
            );
          })}
          {renderEmptyLineM(packingList2?.length, 'R')}
          </tbody>
        </table>
      </div>
      <div style={{marginTop: 10, textAlign: 'right', fontWeight: 'bold'}}>{`TOTAL [${items?.length}] P'KGS ONLY`}</div>
      <div className={'flex'} style={{marginTop: 30}}>
        <div className={'flex-1'} style={{textAlign: 'center'}}>
          <div><input style={{width: '50%', border: 'none', borderBottom: '2px solid black'}}/></div>
          <div>CLIENT'S SIGNATURE</div>
        </div>
        <div className={'flex-1'} style={{textAlign: 'center'}}>
          <div><input style={{width: '50%', border: 'none', borderBottom: '2px solid black'}}/></div>
          <div>DELIVERY STAFF'S SIGNATURE</div>
        </div>
      </div>
      </>
    );
  };

  const renderItemsE = (items) => {
    let totalValue = 0;
    return (
      <table className={'print-table'} style={{width: '100%'}}>
        <tbody>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'no-border-t fb-14 flex flex-1 center'}>
            <div style={{width: 80}}>{'C/NO.'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'ITEM'}</div>
          </td>
          <td className={'no-border-t no-border-l fb-14 flex flex-1 center'}>
            <div className={'flex-1'}>{'QTY'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'UNIT'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'UNIT VALUE'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'VALUE'}</div>
          </td>
        </tr>
        {items?.map((data, index) => {
          totalValue += data.value;
          totalValueRef.current += data.value;
          return (
            <tr key={`iteemsE-${index}`} className={'flex'} style={{height: height1}}>
              <td className={'no-border-t flex flex-1'}>
                <div style={{width: 80, textAlign: 'center'}}>{data.c_no}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black', paddingLeft: 5}}>{data.item}</div>
              </td>
              <td className={'no-border-t no-border-l flex flex-1 center'}>
                <div className={'flex-1'}>{data.qty}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.unit}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.unit_price ? util.formatCurrency(data.unit_price) : ''}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.value ? util.formatCurrency(data.value) : ''}</div>
              </td>
            </tr>
          );
        })}
        {renderEmptyLineE(items?.length)}
        <tr className={'flex'} style={{height: height1}}>
          <td className={'no-border-t fb-14 flex-1 right'}>{`TOTAL ${blData.package} P'KGS`}</td>
          <td className={'no-border-t fb-14 no-border-l flex-1 right'}>{`TOTAL: ${totalValue}`}</td>
        </tr>
        </tbody>
      </table>
    );
  };

  const renderItemsF = (items) => {
    let totalValue = 0;
    return (
      <>
      <table className={'print-table'} style={{width: '100%'}}>
        <tbody>
        <tr className={'flex'} style={{height: height1}}>
          <td className={'no-border-t fb-14 flex flex-1 center'}>
            <div style={{width: 80}}>{'ITEM NO.'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'DESCRIPTION OF GOODS'}</div>
            <div style={{borderLeft: '1px solid black', width: 120}}>{'MATERIAL'}</div>
          </td>
          <td className={'no-border-t no-border-l fb-14 flex flex-1 center'}>
            <div className={'flex-1'}>{'QTY'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'UNIT'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'UNIT PRICE'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'AMOUNT'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'ORIGIN'}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{'HS CODE'}</div>
          </td>
        </tr>
        {items?.map((data, index) => {
          totalValue += data.value;
          totalValueRef.current += data.value;
          return (
            <tr key={`itemsF-${index}`} className={'flex'} style={{height: height1}}>
              <td className={'no-border-t flex flex-1'}>
                <div style={{width: 80, textAlign: 'center'}}>{data.c_no}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black', paddingLeft: 5}}>{data.item}</div>
                <div style={{borderLeft: '1px solid black', width: 120, paddingLeft: 5}}>{data.material}</div>
              </td>
              <td className={'no-border-t no-border-l flex flex-1 center'}>
                <div className={'flex-1'}>{data.qty}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.unit}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.unit_price ? util.formatCurrency(data.unit_price) : ''}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.value ? util.formatCurrency(data.value) : ''}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.origin}</div>
                <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{data.hsCode}</div>
              </td>
            </tr>
          );
        })}
        {renderEmptyLineF(items?.length)}
        <tr className={'flex'} style={{fontWeight: 'bold'}}>
          <td className={'no-border-all flex flex-1 mt-2 right'}>
            <div>{'TOTAL'}</div>
          </td>
          <td className={'no-border-all flex flex-1 mt-2 center'}>
            <div className={'flex-1'}>{items?.length}</div>
            <div className={'flex-1'}>{''}</div>
            <div className={'flex-1'}>{''}</div>
            <div className={'flex-1'}>{totalValue ? util.formatCurrency(totalValue) : ''}</div>
            <div className={'flex-1'}>{''}</div>
            <div className={'flex-1'}>{''}</div>
          </td>
        </tr>
        </tbody>
      </table>
      <div className={'flex'}>
        <div className={'flex-4'}>{''}</div>
        <div className={'flex-2'} style={{marginTop: 50, textAlign: 'center'}}>
            <div><input style={{width: '100%', border: 'none', borderBottom: '2px solid black'}}/></div>
            <div>SIGNED BY</div>
        </div>
        <div className={'flex-1'}>{''}</div>
      </div>
      </>
    );
  };

  const renderEmptyLineM = (itemCount, position) => {
    const totalLine = 35;
    const emptyLine = totalLine - itemCount;
    const empty = [];
    for(let i=0; i<emptyLine; i++) {
      empty.push(
        <tr key={`emptyM_${i}`} className={'flex'} style={{height: height1}}>
          <td className={position === 'L' ? 'no-border-t' : 'no-border-t no-border-l'} style={{width: 50}}>{''}</td>
          <td className={'no-border-t no-border-l flex-1'}>{''}</td>
          <td className={'no-border-t no-border-l'} style={{width: 100}}>{''}</td>
        </tr>
      );
    }
    return empty;
  };

  const renderEmptyLineE = (itemCount) => {
    const totalLine = 35;
    const emptyLine = totalLine - itemCount;
    const empty = [];
    for(let i=0; i<emptyLine; i++) {
      empty.push(
        <tr key={`emptyE_${i}`} className={'flex'} style={{height: height1}}>
          <td className={'no-border-t fb-14 flex flex-1'}>
            <div style={{width: 80}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
          </td>
          <td className={'no-border-t no-border-l fb-14 flex flex-1'}>
            <div className={'flex-1'}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
          </td>
        </tr>
      );
    }
    return empty;
  };

  const renderEmptyLineF = (itemCount) => {
    const totalLine = 35;
    const emptyLine = totalLine - itemCount;
    const empty = [];
    for(let i=0; i<emptyLine; i++) {
      empty.push(
        <tr key={`emptyF_${i}`} className={'flex'} style={{height: height1}}>
          <td className={'no-border-t flex flex-1'}>
            <div style={{width: 80}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div style={{borderLeft: '1px solid black', width: 120}}>{''}</div>
          </td>
          <td className={'no-border-t no-border-l flex flex-1 center'}>
            <div className={'flex-1'}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
            <div className={'flex-1'} style={{borderLeft: '1px solid black'}}>{''}</div>
          </td>
        </tr>
      );
    }
    return empty;
  };

  return (
    <>
      {isMultiPage ?
        arrData.map((items, index) => {
          return (
            <div key={`item-${index}`}>
              {renderHeader(blData, index)}
              {renderItems(items)}
            </div>
          )
        })
        :
        <div>
          {renderHeader(blData, 0)}
          {renderItems([])}
        </div>
      }
    </>
  );
}

export function usePackingListPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: 'Packing List',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <PackingListPrint ref={printRef} printData={printData}/>,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setPrintData(data);
      modal.open();
    },
    print,
  };
}

export default PackingListPrint;
