/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {util} from '../../services/service';
import {
  AccountTypeSearchInput,
} from '../../components/SearchInput';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {DefaultButton} from '../../components/buttons';
import {INVOICE} from "../field-defs/account";
import useCommissionDetailModal from "../../modals/account/useCommissionDetailModal";
import useVendorDetailModal from "../../modals/account/useVendorDetailModal";
import useDCNoteDetailModal from "../../modals/account/useDCNoteDetailModal";
import {SK_CRAFT_NAME} from "../../containers/Account/forms/AirVesselSearchFormManager";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const dcTypeOptions = [
  { value: 'DEBIT', label: 'DEBIT'},
  { value: 'CREDIT', label: 'CREDIT'},
];

const DEBIT_TYPE = dcTypeOptions[0].value;
const CREDIT_TYPE = dcTypeOptions[1].value;

const PROFIT_SHARE_ID = 17;

const unitOptions = [
  { value: 'CNTR', label: 'CNTR'},
  { value: 'B/L', label: 'B/L'},
  { value: 'CBM', label: 'CBM'},
  { value: 'DAYS', label: 'DAYS'},
  { value: 'M/T', label: 'M/T'},
  { value: 'R/T', label: 'R/T'},
  { value: 'TRUCK', label: 'TRUCK'},
  { value: 'HRS', label: 'HRS'},
  { value: 'KG', label: 'KG'},
  { value: 'WAGON', label: 'WAGON'},
  { value: 'CFT', label: 'CFT'},
  { value: 'SHIP', label: 'SHIP'},
  { value: '-', label: '-'},
];

class AccountInvoiceAddItemFormManager extends FormManager {
  grid;
  onAddPaymentHistory;
  accountId;
  partnerId;
  disablePayment;
  accountForm;
  isDisabled;
  onApply: () => void;
  isApplied;
  onSearch;
  VDDetailAll;
  onMakePaidAll;
  currency;
  constructor(type) {
    super({
      prefix: `account-invoice-add-item-form`,
      fields: [
        {name: 'id', serverName: 'id', label: ''},
        {name: 'atypeId', serverName: 'atypeId', label: ''},
        {name: 'accountType', serverName: 'accountType', label: 'Account Type'},
        {name: 'dcType', serverName: 'dcType', label: 'D/C', options: dcTypeOptions, noDefOption: true},
        {name: 'unit', serverName: 'unit', label: 'Unit', options: unitOptions, noDefOption: true},
        {name: 'rate', serverName: 'rate', label: 'Rate'},
        {name: 'volume', serverName: 'volume', label: 'Volume', defaultValue: 1, mask: util.MASK_NUMBER_DECIMAL_SIX},
        {name: 'debitAmount', serverName: 'debitAmount', label: 'Debit Amount'},
        {name: 'creditAmount', serverName: 'creditAmount', label: 'Credit Amount'},
        {name: 'totalAmount', serverName: 'totalAmount', label: 'Total'},
        {name: 'revenueCost', serverName: 'revenueCost', label: 'Revenue'},
        {name: 'debit', serverName: 'debit', label: 'Debit'},
        {name: 'credit', serverName: 'credit', label: 'Credit'},
        {name: 'dcRate', serverName: 'dcRate', label: 'CUR. Rate', defaultValue: 1, mask: util.MASK_NUMBER_DECIMAL_SIX},
        {name: 'remark', serverName: 'remark', label: 'Remark', type: 'textarea'},
        ////////////////////////////////   CM, VD   //////////////////////////////////////////
        {name: 'Type', serverName: 'Type', label: 'Type'},
        {name: 'Date', serverName: 'Date', label: 'Date'},
        {name: 'Packages', serverName: 'Packages', label: 'Packages'},
        {name: 'Amount', serverName: 'Amount', label: 'Amount'},
        {name: 'Rate', serverName: 'Rate', label: 'Rate', mask: util.MASK_RATE},
        {name: 'totPackages', serverName: 'totPackages', label: 'Total Packages'},
        {name: 'totAmount', serverName: 'totAmount', label: 'Total Amount'},
        {name: 'totCommission', serverName: 'totCommission', label: 'Total Amount'},
        {name: 'paidAmount', serverName: 'paidAmount', label: 'Total Paid'},
        {name: 'dcRate2', serverName: 'dc_Rate', label: 'DC Rate', mask: util.MASK_RATE},
        /////////////////////////////////////////////////////////////////////////////////////
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });

    this.invoiceType = type;
    this.disablePayment = true;
  }

  getGridData() {
    let grid = this.grid;
    const rows = [];
    if (grid.apiRef && grid.apiRef.current) {
      grid.apiRef.current.forEachNode(node => rows.push(node.data));
    }
    return rows;
  }

  setGridData(gridData) {
    gridData = gridData.map((row) => {
      // let debit = 0.0, credit = 0.0, Rate = row.Rate ? util.toFloat(row.Rate).toFixed(2) : 0;
      let debit = 0.0, credit = 0.0, Rate = row.Rate ? util.toFloat(row.Rate) : 0;
      debit = row.dcType === DEBIT_TYPE ? util.round(util.toFloat(row.rate * row.volume), 2) : 0;
      credit = row.dcType === CREDIT_TYPE ? util.round(util.toFloat(row.rate * row.volume), 2) : 0;
      return {...row, debit, credit, Rate};
    });

    this.grid.setRows(gridData);
    this.calculateAmount(gridData, this.grid._c);
  }

  setDefaultCurrency(currency) {
    this.currency = currency;
  }

  setAccountId(id) {
    this.accountId = id;
    this.disablePayment = this.accountId ? false : true;
    this.disablePayment = this.accountId ? false : true;
  }

  setPartnerId(id) {
    this.partnerId = id;
  }

  setDcRate(dcRate) {
    const {_c} = this.getRenderProps();
    _c('dcRate2', dcRate);
  }

  setPaidAmount(paidAmount) {
    const {_c} = this.getRenderProps();
    _c('paidAmount', paidAmount);
  }

  calculateAmount = (rows) => {
    const {_c} = this.getRenderProps();
    // calculate the amount of each D/C from grid rows
    let dAmount = 0, cAmount = 0, total = 0, _amount = 0, totPackages = 0, totAmount = 0, totCommission = 0;

    // add previous data shown in grid view
    for (let row of rows) {
      _amount = (util.toFloat(row.rate) * util.toFloat(row.volume) * util.toFloat(row.dcRate)).toFixed(3);
      _amount = util.round(_amount, 2);
      dAmount += row.dcType === DEBIT_TYPE ? _amount : 0;
      cAmount += row.dcType === CREDIT_TYPE ? _amount : 0;

      if(this.invoiceType === INVOICE.TYPE_AR) {
        total += row.price;
      } else {
        total += _amount;
      }
      if(this.invoiceType === INVOICE.TYPE_CM || this.invoiceType === INVOICE.TYPE_VD) {
        totPackages += this.invoiceType === INVOICE.TYPE_VD ? row.Customers : row.Packages;
        totAmount += this.invoiceType === INVOICE.TYPE_VD ? row.Amount : row.Price;
        totCommission += row.Commission;
      }
      if(this.invoiceType === INVOICE.TYPE_DC_NOTE) {
        totAmount += row.amount;
      }
    }
    if(this.invoiceType === INVOICE.TYPE_DC) {
      total = dAmount - cAmount;
    }

    // set the values to the view
    dAmount = dAmount ? util.toFloat(dAmount).toFixed(2) : 0.00;
    cAmount = cAmount ? util.toFloat(cAmount).toFixed(2) : 0.00;
    total = util.toFloat(total).toFixed(2);
    _c('debitAmount', dAmount);
    _c('creditAmount', cAmount);
    _c('totalAmount', total);
    _c('totPackages', totPackages);
    _c('totAmount', totAmount);
    _c('totCommission', totCommission);
  };

  onRender = () => {
    const [rowId, setRowId] = React.useState();
    const [gridMode, setGridMode] = React.useState('add');
    const [isScrollToBottom, setIsScrollToBottom] = React.useState(false);
    const [currency, setCurrency] = React.useState('CAD');
    const commissionModal = useCommissionDetailModal();
    const vendorModal = useVendorDetailModal();
    const dcNoteModal = useDCNoteDetailModal();
    const grid = useInvoiceItemGrid(this.invoiceType, (action, data) => {
      if (action === 'delete') {
        deleteItem(data);
      } else if (action === 'detail') {
        if(this.invoiceType === INVOICE.TYPE_CM) {
          const dataInfo = {commissionId: util.toInt(this.accountId), pickupType: data.Type};
          commissionModal.open(dataInfo, this.invoiceType);
        } else if(this.invoiceType === INVOICE.TYPE_VD) {
          const dataInfo = {accountId: util.toInt(this.accountId), partnerId: util.toInt(this.partnerId), filingNo: data.FilingNo};
          vendorModal.open(dataInfo, this.invoiceType);
        } else if(this.invoiceType === INVOICE.TYPE_DC_NOTE) {
          const dataInfo = {id: util.toInt(this.accountId), currency: data.Type};
          dcNoteModal.open(dataInfo, this.invoiceType);
        }
      }
    }, (e) => {
      if(this.invoiceType === INVOICE.TYPE_AR || this.invoiceType === INVOICE.TYPE_AP || this.invoiceType === INVOICE.TYPE_DC) {
        // this.setValues(e.data);
        _c('id', e.data.id);
        _c('atypeId', e.data.atypeId);
        _c('accountType', e.data.accountType);
        _c('credit', e.data.credit);
        _c('debit', e.data.debit);
        _c('dcType', e.data.dcType);
        _c('dcRate', e.data.dcRate);
        _c('rate', e.data.rate);
        _c('unit', e.data.unit);
        _c('volume', e.data.volume);
        _c('remark', e.data.remark);
        setRowId(e.data.id);
        setGridMode('edit');
      }
    }, this.isDisabled, isScrollToBottom);

    this.grid = grid;
    const _at = (name) => <AccountTypeSearchInput
      idField={_n(`atypeId`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;
    const _at2 = (name) => <AccountTypeSearchInput
      idField={_n(`atypeId`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
      onOK={(data) => {
        const {id, value} = data;
        this.setValue('atypeId', id);
        this.setValue('accountType', value);
        // account data
        if(id === PROFIT_SHARE_ID) {
          const {profitShare, buyingRate, sellingRate} = this.accountForm.getValues();
          if(profitShare && buyingRate && sellingRate) {
            const rate = (sellingRate - buyingRate) * (profitShare / 100);
            _c('rate', rate);
            onAdd();
          } else {
            this.setValue('atpyeId', null);
            this.setValue('accountType', null);
            util.showWarning('Please enter a valid profit share data in house B/L.');
          }
        }
      }}
    />;
    const {_r, _f, _v, _n, _c, formik, fields, errors} = this.getRenderProps()

    const onAdd = () => {
      // if(!util.isValidPermitForAccount(user, this.accountId ? 'edit' : 'add')) {
      //   return util.showError('Sorry, you can not edit Account Info.');
      // }
      const rows = this.grid.rows ?? [];
      let {atypeId, accountType, dcType = undefined, unit, rate = 0, volume = 1, revenueCost = 0, debit = 0, credit = 0, dcRate = 1, remark} = this.getValues();
      if(atypeId < 0 || !accountType) {
        util.showWarning('Please enter a valid Account Type data!');
        return;
      }

      if(isNaN(rate)) {
        util.showWarning('Please enter a valid Rate data!');
        return;
      }

      if(isNaN(volume)) {
        util.showWarning('Please enter a valid Volume data!');
        return;
      }

      if(dcRate <= 0) {
        util.showWarning('Please enter a valid CUR Rate data!');
        return;
      }

      const id = rowId ?? rows?.length ?? 0;
      if(this.invoiceType === INVOICE.TYPE_DC) {
        dcType = dcType || DEBIT_TYPE;
      } else {
        dcType = undefined;
      }
      unit = unit || 'CNTR';
      if(atypeId === PROFIT_SHARE_ID) {
        dcType = CREDIT_TYPE;
        unit = undefined;
      }

      if(!rate) rate = 0;
      if(!volume) volume = 1;
      if(!dcRate) dcRate = 1;

      let _amount = 0.0;
      _amount = (util.toFloat(rate) * util.toFloat(volume)).toFixed(3);
      _amount = util.round(_amount, 2);
      debit = dcType === DEBIT_TYPE ? _amount : 0;
      credit = dcType === CREDIT_TYPE ? _amount : 0;

      let price = 0;
      if (this.currency !== 'KRW' && dcRate > 100){
         price = (util.toFloat(rate) * util.toFloat(volume) / util.toFloat(dcRate)).toFixed(3);
       }else{
         price = (util.toFloat(rate) * util.toFloat(volume) * util.toFloat(dcRate)).toFixed(3);
      }
      price = util.round(price, 2);

      debit = debit ? util.toFloat(debit) : 0.00;
      volume = volume ? util.toFloat(volume) : 1.000;
      credit = credit ? util.toFloat(credit) : 0.00;
      rate = rate? util.toFloat(rate) : 0.00;
      dcRate = dcRate? util.toFloat(dcRate) : 1.00;

      const newRow = {atypeId, accountType, dcType, unit, rate: util.toFloat(rate), volume: util.toFloat(volume), revenueCost, debit: util.toFloat(debit), credit: util.toFloat(credit), dcRate: util.toFloat(dcRate), id, remark, price};
      let newRows;
      if(gridMode === 'add') {
        newRows = [...rows, newRow];
      } else if(gridMode === 'edit') {
        const rows = grid.rows;
        const rowIndex = rows.findIndex(i => i.id === id);
        newRows = [...rows];
        newRows[rowIndex] = newRow;
      } else {
        newRows = [...rows];
      }
      grid.setRows(newRows);
      this.clearValues();
      this.calculateAmount(newRows);
      setIsScrollToBottom(gridMode === 'add');
      setGridMode('add');
      setRowId(newRows?.length);
      this.setFocus('accountType');
    }

    const deleteItem = (data) => {
      // if(!util.isValidPermitForAccount(user, this.accountId ? 'edit' : 'add')) {
      //   return util.showError('Sorry, you can not edit Account Info.');
      // }
      let rowsData = this.getGridData();
      rowsData = rowsData.filter((row) => row.id != data.id).map((i, index) => ({...i, id: index}));
      grid.setRows(rowsData);
      this.calculateAmount(rowsData);
    }

    const onCancelEdit = () => {
      setGridMode('add');
      setRowId(this.grid.rows?.length ?? 0);
      this.clearValues();
    };

    const onApply = () => {
      this.onApply(this.accountId, _v('dcRate2'));
    };

    let title = 'Invoice Items';
    if(this.invoiceType === INVOICE.TYPE_CM || this.invoiceType === INVOICE.TYPE_VD || this.invoiceType === INVOICE.TYPE_DC_NOTE) {
      title = 'Detail';
    }

    React.useEffect(() => {
      if(vendorModal.isOpen === false) {
        this.onSearch();
      }
    }, [vendorModal.isOpen]);
    React.useEffect(() => {
      if(commissionModal.isOpen === false) {
        this.onSearch();
      }
    }, [commissionModal.isOpen]);
    React.useEffect(() => {
      if(this.currency) {
        setCurrency(this.currency);
      }
    });
    switch (this.invoiceType) {
      case INVOICE.TYPE_AR: {
        return (
          <div className={'w-full'}>
            <Card style={cardStyle}>
              <CardBody className={'p-20'}>
                <div className={'flex between w-full'}>
                  <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
                  <div className={'flex-2 flex right pr-20'}>
                    <span className={'mr-12'}>Total:</span>
                    <span className={'mr-12'}>{util.formatCurrency(util.toFloat(_v('totalAmount'))) ?? '$0.00'}</span>
                  </div>
                </div>
                {grid.render()}
                <div className={'flex w-full'}>
                  <div className={'flex-3'}>{_at('accountType')}</div>
                  <div className={'flex-2'}>{_r('unit')}</div>
                  <div className={'flex-2'}>{_r('rate')}</div>
                  <div className={'flex-2'}>{_r('volume')}</div>
                  <div className={'flex-2'}>{_r('dcRate')}</div>
                  <div className={'ml-20'}>{''}</div>
                  {gridMode === 'add'
                    ? (
                      <div className={'ml-20'}><DefaultButton label={'Add'} disabled={this.isDisabled} onClick={onAdd} noMargin /></div>
                    )
                    : (
                      <div className={'ml-20'}>
                        <DefaultButton label={'Edit'} disabled={this.isDisabled} onClick={onAdd} />
                        <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
                      </div>
                    )
                  }
                </div>
                <div>{_r('remark')}</div>
              </CardBody>
            </Card>
          </div>
        );
      }
      case INVOICE.TYPE_AP: {
        return (
          <div className={'w-full'}>
            <Card style={cardStyle}>
              <CardBody className={'p-20 pb-1'}>
                <div className={'flex between w-full'}>
                  <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
                  <div className={'flex-2 flex right pr-20'}>
                    <span className={'mr-12'}>Total:</span>
                    <span className={'mr-12'}>{_v('totalAmount') ?? '$0.00'}</span>
                  </div>
                </div>
                {grid.render()}
                <div className={'flex w-full'}>
                  <div className={'flex-3'}>{_at('accountType')}</div>
                  <div className={'flex-2'}>{_r('unit')}</div>
                  <div className={'flex-2'}>{_r('rate')}</div>
                  <div className={'flex-2'}>{_r('volume')}</div>
                  <div className={'ml-20'}>{''}</div>
                  {gridMode === 'add'
                    ? (
                      <div className={'ml-20'}><DefaultButton label={'Add'} onClick={onAdd} noMargin /></div>
                    )
                    : (
                      <div className={'ml-20'}>
                        <DefaultButton label={'Edit'} onClick={onAdd} />
                        <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
                      </div>
                    )
                  }
                </div>
                <div>{_r('remark')}</div>
              </CardBody>
            </Card>
          </div>
        );
      }
      case INVOICE.TYPE_DC: {
        return (
          <div className={'w-full'}>
            <Card style={cardStyle}>
              <CardBody className={'p-20'}>
                <div className={'flex between w-full'}>
                  <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
                  <div className={'flex-2 flex right pr-20'}>
                    <span className={'mr-12'}>Debit Amount:</span>
                    <span className={'mr-12'}>{_v('debitAmount') ?? '$0.00'}</span>
                    <span className={'mr-12'}>Credit Amount:</span>
                    <span className={'mr-12'}>{_v('creditAmount') ?? '$0.00'}</span>
                    <span className={'mr-12'}>Total Amount:</span>
                    <span className={'mr-12'}>{_v('totalAmount') ?? '$0.00'}</span>
                  </div>
                </div>
                {grid.render()}
                <div className={'flex w-full'}>
                  <div className={'flex-3'}>{_at2('accountType')}</div>
                  <div className={'flex-2'}>{_r('dcType')}</div>
                  <div className={'flex-2'}>{_r('unit')}</div>
                  <div className={'flex-2'}>{_r('rate')}</div>
                  <div className={'flex-2'}>{_r('volume')}</div>
                  <div className={'ml-20'}>{''}</div>
                  {gridMode === 'add'
                    ? (
                      <div className={'ml-20'}><DefaultButton label={'Add'} onClick={onAdd} noMargin /></div>
                    )
                    : (
                      <div className={'ml-20'}>
                        <DefaultButton label={'Edit'} onClick={onAdd} />
                        <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
                      </div>
                    )
                  }
                </div>
                <div>{_r('remark')}</div>
              </CardBody>
            </Card>
          </div>
        );
      }
      case INVOICE.TYPE_CM: {
        return (
          <div className={'w-full'}>
            <Card style={cardStyle}>
              <CardBody>
                <div className={'flex between w-full'}>
                  <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
                  <div className={'flex-2 flex right pr-20'}>
                    <span className={'mr-12'}>Total Packages:</span>
                    <span className={'mr-12'}>{_v('totPackages') ?? '0'}</span>
                    <span className={'mr-12'}>Total Amount:</span>
                    <span className={'mr-12'}>{util.formatCurrency(_v('totAmount') ?? '0')}</span>
                    <span className={'mr-12'}>Total Commission:</span>
                    <span className={'mr-12'}>{util.formatCurrency(_v('totCommission') ?? '0')}</span>
                  </div>
                </div>
                {grid.render()}
                {commissionModal.render()}
                <div style={{float: 'right'}}>
                  <DefaultButton label={'Make Paid All'} onClick={this.onMakePaidAll} noMargin />
                </div>
              </CardBody>
            </Card>
          </div>
        );
      }
      case INVOICE.TYPE_VD: {
        return (
          <div className={'w-full'}>
            <Card style={cardStyle}>
              <CardBody>
                <div className={'flex between w-full'}>
                  <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
                  <div className={'flex-2 flex right pr-20'}>
                    <span className={'mr-12'}>Total Customers:</span>
                    <span className={'mr-12'}>{_v('totPackages') ?? '0'}</span>
                    <span className={'mr-12'}>Total Amount:</span>
                    <span className={'mr-12'}>{util.formatCurrency(_v('totAmount') ?? '0.00')}</span>
                    <span className={'mr-12'}>Total Paid:</span>
                    <span className={'mr-12'}>{util.formatCurrency(_v('paidAmount') ?? '0.00')}</span>
                    <DefaultButton className={'mb-2'} label={'See All'} onClick={this.VDDetailAll} noMargin />
                  </div>
                </div>
                {grid.render()}
                {vendorModal.render()}
                <div className={'flex w-full'}>
                  <div className={'flex-1'}>{_r('dcRate2')}</div>
                  {/*<DefaultButton className={'ml-20'} label={'Apply'} onClick={onApply} disabled={this.isApplied} />*/}
                  <DefaultButton className={'ml-20'} label={'Apply'} onClick={onApply} />
                  <div className={'flex-3'}>{'\u00a0'}</div>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      }
      case INVOICE.TYPE_DC_NOTE: {
        return (
          <div className={'w-full'}>
            <Card style={cardStyle}>
              <CardBody>
                <div className={'flex between w-full'}>
                  <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
                  <div className={'flex-2 flex right pr-20'}>
                    <span className={'mr-12'}>Total Amount:</span>
                    <span className={'mr-12'}>{`(${currency})`} {util.formatCurrency(_v('totAmount') ?? '0')}</span>
                  </div>
                </div>
                {grid.render()}
                {dcNoteModal.render()}
              </CardBody>
            </Card>
          </div>
        );
      }
    }
  };

  onValidate = (values) => {
    return values;
  };
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function amountFormatter(p) {
  // let amount = p.data['rate'] * p.data['volume'] * p.data['dcRate'];
  let amount = p.data['price'];
  amount = util.round(amount, 2);

  if (isNaN(amount))
    return (0).toFixed(2);
  else
    return util.formatCurrency(amount);
}

function useInvoiceItemGrid(type, onAction, onCellClicked, isDisabled, isScrollToBottom) {
  function getColumns() {
    switch (type) {
      case INVOICE.TYPE_AR:
        return [
          {field: 'id', hide: true},
          {field: 'atypeId', hide: true},
          {field: 'accountType', headerName: 'Account Type', rowDrag: true, width: 200},
          {field: 'unit', headerName: 'Unit', width: 120},
          {field: 'rate', headerName: 'Rate', width: 120, valueFormatter: util.currencyFormatterEx2},
          {field: 'volume', headerName: 'Volume', width: 120, valueFormatter: util.currencyFormatterEx2},
          {field: 'dcRate', headerName: 'CUR. Rate', width: 120, valueFormatter: (param) => util.currencyFormatterEx2(param, 6)},
          {field: 'price', headerName: 'Amount', width: 120, valueFormatter: amountFormatter },
          {field: 'remark', headerName: 'Remark', flex: 1},
      ];
      case INVOICE.TYPE_AP:
        return [
          {field: 'id', hide: true},
          {field: 'atypeId', hide: true},
          {field: 'accountType', headerName: 'Account Type', rowDrag: true, width: 200},
          {field: 'unit', headerName: 'Unit', width: 120},
          {field: 'rate', headerName: 'Rate', width: 120, valueFormatter: util.currencyFormatterEx2},
          {field: 'volume', headerName: 'Volume', width: 120, valueFormatter: util.currencyFormatterEx2},
          {field: 'price', headerName: 'Amount', width: 120, valueFormatter: amountFormatter },
          {field: 'remark', headerName: 'Remark', flex: 1},
        ];
      case INVOICE.TYPE_DC:
        return [
          {field: 'id', hide: true},
          {field: 'atypeId', hide: true},
          {field: 'accountType', headerName: 'Account Type', rowDrag: true, width: 200},
          {field: 'dcType', headerName: 'D/C', width: 120},
          {field: 'unit', headerName: 'Unit', width: 120},
          {field: 'rate', headerName: 'Rate', width: 120, valueFormatter: util.currencyFormatterEx2},
          {field: 'volume', headerName: 'Volume', width: 120, valueFormatter: util.currencyFormatterEx2},
          {field: 'price', headerName: 'Revenue/Cost', width: 120, valueFormatter: amountFormatter},
          {field: 'debit', headerName: 'Debit', width: 120, valueFormatter: (param) => util.currencyFormatterEx2(param, 2)},
          {field: 'credit', headerName: 'Credit', width: 120, valueFormatter: (param) => util.currencyFormatterEx2(param, 2)},
          {field: 'remark', headerName: 'Remark', flex: 1},
      ];
      case INVOICE.TYPE_CM:
        return [
          {field: 'Type', headerName: 'Type', flex: 1},
          {field: 'Packages', headerName: 'Packages', flex: 1},
          {field: 'Price', headerName: 'Amount', flex: 1, valueFormatter: util.currencyFormatter},
          {field: 'Balance', headerName: 'Balance', flex: 1, valueFormatter: util.currencyFormatter},
          {field: 'Rate', headerName: 'Rate', flex: 1, valueFormatter: util.currencyFormatter},
          {field: 'Commission', headerName: 'Commission', flex: 1, valueFormatter: util.currencyFormatter},
      ];
      case INVOICE.TYPE_VD:
        return [
          {field: 'PostDate', headerName: 'W/O Date', flex: 1, valueFormatter: util.dateFormatter},
          {field: 'FilingNo', headerName: 'Filing No.', flex: 1},
          {field: 'Customers', headerName: 'Customers', flex: 1},
          {field: 'Amount', headerName: 'Amount', flex: 1, valueFormatter: util.currencyFormatter},
      ];
      case INVOICE.TYPE_DC_NOTE:
        return [
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, flex: 1},
          {field: 'inv_no', headerName: 'Inv No.', flex: 1},
          {field: 'bl_no', headerName: 'B/L No.', flex: 1},
          {field: 'ref_no', headerName: 'Ref. No.', flex: 1},
          {field: 'customer_name', headerName: 'Customer', flex: 1},
          {field: 'currency', headerName: 'Currency', flex: 1},
          {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, flex: 1},
      ];
    }
  }
  let height = 350;
  let action = ['delete'];
  if(type === INVOICE.TYPE_AR && isDisabled) {
    action = [];
  }
  if(type === INVOICE.TYPE_CM || type === INVOICE.TYPE_VD) {
    height = 300;
    action = ['detail'];
  }
  if(type === INVOICE.TYPE_DC_NOTE) {
    height = 300;
    action = [];
  }

  return useSimpleGrid({
    columns: getColumns(),
    height: height,
    className: 'pb-20',
    actions: action,
    actionWidth: 70,
    onAction: onAction,
    isScrollToBottom,
    // isActionDisabled: (action, data) => {return data.Type === 'Total' ? true : false;},
    isActionDisabled: (action, data) => {return type === INVOICE.TYPE_VD ? (data.FilingNo ? false : true) : false},
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      }
    }
  });
}

export default AccountInvoiceAddItemFormManager;
