/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {currencyOptions} from '../helper';
import {
  SK_ACCOUNT_TYPE, SK_ACCOUNT_TYPE_ID,
  SK_BILL_TO,
  SK_BL_NO,
  SK_BRANCH,
  SK_CARGO,
  SK_INV_NO,
  SK_INV_TYPE, SK_OVERDUE, SK_UNPAID
} from './AccountListSearchFormManager';
import {AccountTypeSearchInput} from "../../components/SearchInput";
import {SK_HBL} from "../ListAllSearchFormManager";

class AccountBLRateSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor() {
    super({
      prefix: 'account-bl-rate-search',
      fields: [
        {name: 'blNo', serverName: 'bl_no', label: 'B/L No.', smallMargin: true},
        {name: 'atype', serverName: 'atype', label: 'Account Type ID', smallMargin: true, smallLabel: true},
        {name: 'atypeId', serverName: 'atypeId', label: 'Account Type ID', smallMargin: true, smallLabel: true},
        {name: 'currency', serverName: 'currency', label: 'Currency', options: [{value: '', label: 'ALL'}, ...currencyOptions], noDefOption: true}
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    // account type search form
    const _at = (name) => <AccountTypeSearchInput vertical forSmallLabel
      idField={_n('atypeId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
      />;
    _f('blNo').onEnter = _ => this.onSearch(); // 일반 텍스트 필드는 엔터를 누르면 바로 검색을 수행
    React.useEffect(() => {this.setFocus('blNo')}, []); // 시작 시 HBL 에 포커스를 줌
    const atypeId = _v('atypeId');
    const currency = _v('currency');
    React.useEffect(() => {
      if (!(atypeId === undefined && currency === undefined)) {
        this.onSearch();
      }
    }, [atypeId, currency]);
    return (
      <>
        {_r('blNo')}
        {_at('atype')}
        {_r('currency')}
      </>
    );
  };
}

export default AccountBLRateSearchFormManager;
