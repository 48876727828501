/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {apiBL} from '../../../services/service';
import {usePrintModal} from './usePrintModal';
import {useUser} from '../../../redux/reducers/userReducer';
import AIHPClaim from '../../../print-layouts/AIHPClaim';
import {TradePartnerSearchInput} from '../../../components/SearchInput';

const FN = {
  FILING_NO: 'filingNo',
  BL_NO: 'blNo',
  OPERATOR: 'operator',
  TO: 'to',
  NOTICE_TO: 'noticeTo',
  NOTICE_TO_ID: 'noticeToId',
  NOTICE_TO_ADDR: 'noticeToAddr',
  SHORTAGE: 'shortage',
};

class AIHPCPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'pc-oih',
      fields: [
        {name: FN.FILING_NO, serverName: FN.FILING_NO, label: 'Filing No.', readonly: true},
        {name: FN.BL_NO, serverName: FN.BL_NO, label: 'HAWB No.', readonly: true},
        {name: FN.OPERATOR, serverName: FN.OPERATOR, label: 'PIC'},
        {name: FN.TO, serverName: FN.TO, label: 'To', options: toOptions, noDefOption: true},
        {name: FN.NOTICE_TO, serverName: FN.NOTICE_TO, label: 'Notice To', smallMargin: true},
        {name: FN.NOTICE_TO_ID, serverName: FN.NOTICE_TO_ID},
        {name: FN.NOTICE_TO_ADDR, serverName: FN.NOTICE_TO_ADDR, label: ' ', type: 'textarea'},
        {name: FN.SHORTAGE, serverName: FN.SHORTAGE, label: '', type: 'textarea', textAreaRows: 8},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const onOK = (name, {id, value, Address: address, phone, fax, pic}) => {
      _c(`${name}`, value);
      _c(`${name}Addr`, address);
    };
    // eslint-disable-next-line max-len
    const _tp = (name, onOK) => <TradePartnerSearchInput displayField={_n(name)} idField={_n(`${name}Id`)} onOK={onOK} formik={formik} fields={fields} errors={errors} />;
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.FILING_NO)}
        {_r(FN.BL_NO)}
        {_r(FN.OPERATOR)}
        {_r(FN.TO)}
        {_tp(FN.NOTICE_TO, (data) => onOK(FN.NOTICE_TO, data))}
        {_r(FN.NOTICE_TO_ADDR)}
        <div className={'ml-8'}>
          <div>Shortage/Damage</div>
          {_r(FN.SHORTAGE)}
        </div>
      </div>
    );
  };
}

const toOptions = [
  {value: 'C', label: 'Customer'},
  {value: 'V', label: 'Vendor'},
];

const printForm = new AIHPCPrintForm();

export function useAIHPCPrintModal(blForm) {
  const user = useUser();
  const {print} = user;
  const onOpen = () => {
    const blNo = blForm.getValue('hawbNo');
    const filingNo = blForm.getValue('filingNo');
    printForm.setValue(FN.OPERATOR, print.pic);
    printForm.setValue(FN.BL_NO, blNo);
    printForm.setValue(FN.FILING_NO, filingNo);
  };
  const onLoadPrintData = async (blData) => {
    const {data} = await apiBL.getBLPrintData(blData.blNo);
    return {serverData: data?.[0] ?? {}};
  };
  return usePrintModal(false, 'PRELIMINARY CLAIM', 1024, blForm, printForm, AIHPClaim, onOpen, onLoadPrintData, 640, false);
}
