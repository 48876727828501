/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../components/SimpleModal';
import {apiSettings, util} from '../../services/service';
import MasterInventoryModalFormManager from "../../forms/settings/MasterInventoryModalFormManager";

const inventoryForm = new MasterInventoryModalFormManager();

export default function useInventoryModal() {
  const [spaceId, setSpaceId] = React.useState(0);
  const [data, setData] = React.useState(undefined);
  const modal = useSimpleModal({
    title: 'Inventory ADD / EDIT',
    centered: true,
    width: 500,
    height: 150,
    buttons: getButtons(),
    children: (
      <div className={'w-full'}>
        {inventoryForm.renderForm()}
      </div>
    )
  });

  function getButtons() {
    return [{label: 'SAVE', color: 'primary', onClick: onSave}];
  }

  function onSave() {
    const values = inventoryForm.getValues();
    const {id, branchId, spaceName, isActive, isReset} = values;
    if (!spaceName) {
      util.showWarning('Please enter inventory name.');
      return;
    }
    const data = {id, branchId: parseInt(branchId), spaceName, isActive, isReset}
    apiSettings.setInventory(data).then(res => {
      if(res) {
        modal.close();
      }
    });
  }
  React.useEffect(() => {
    if (modal.isOpen) {
      if (spaceId) {
        inventoryForm.blNo = data.bl_no;
        inventoryForm.setValue('id', spaceId);
        inventoryForm.setValue('branchId', data.branch_name);
        inventoryForm.setValue('spaceName', data.space_name);
        inventoryForm.setValue('isActive', data.isactive);
      }
    } else {
      setSpaceId(0);
      setData(undefined);
      inventoryForm.blNo = 0;
    }
  }, [modal.isOpen]);

  const open = (data) => {
    if(data) {
      setSpaceId(data.id);
      setData(data);
    }
    modal.open();
  };

  return {...modal, open};
}
