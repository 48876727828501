/**
 * @flow
 */
import React from 'react';
import {Button, Modal} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {api, util} from '../services/service';
import {PrimaryButton} from './buttons';
import {useDataGridView} from "./DataGridView";
import {partnerTypeOptions} from "../forms/helper";
import usePartnerHistoryModal from "../modals/usePartnerHistoryModal";
import TradePartnerFormManager from '../forms/settings/TradePartnerFormManager';
import {useSimpleEditModal} from './SimpleModal';

interface SearchModalProps {
  title: string;
  queryText: string;
  openModal: boolean;
  action: string;
  setOpenModal: (openModal: boolean) => void;
  onOK: (value: any) => void;
}

const TracePartnerSearchModal = (props: SearchModalProps) => {
  const {
    openModal, setOpenModal, partnerHistoryModal,
    queryText, setQueryText, totalCount, canAdd, onEnter, grid,
    onAdd,
    partnerType, onChangePartnerType,
    partnerEditModal,
  } = useSearchModalInternal(props);
  return (
    <Modal isOpen={openModal} className={'modal-dialog--form'} centered style={{width: 950}}>
      <div className={'card__title no-margin pt-20 pl-20'}>
        <h5 className="bold-text">SEARCH {props.title}</h5>
      </div>
      <div className={'theme-light ltr-support flex between middle px-20 mb-12'}>
        <div className={'mt-8'}>{totalCount === undefined ? '' : `${grid.rows.length} of ${totalCount}`}</div>
        <div className={'inbox__emails-control-search flex middle'}>
          <div className={'form form__form-group mr-20'} style={{marginBottom: 0}}>
            <div className={'form__form-group-field'}>
              <select id={'common-tp-type'} value={partnerType} onChange={onChangePartnerType}>
                <option value={''}>All</option>
                {partnerTypeOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
              </select>
            </div>
          </div>
          {canAdd && <div className={'mr-8 w-96'}><PrimaryButton label={'NEW'} onClick={onAdd} noMargin /></div>}
          <input
            id={`common-tp-search`}
            placeholder={'Search...'}
            value={queryText ?? ''}
            onChange={({target: {value}}) => setQueryText(value)}
            onKeyDown={({target: {value}, key}) => {
              if (key === 'Enter') {
                onEnter(value);
              }
            }}
            style={{width: 150}}
          />
          <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
            e.preventDefault();
            onEnter(document.querySelector('#common-tp-search').value);
          }}>
            <MagnifyIcon />
          </a>
        </div>
      </div>
        {grid.render()}
      {/*<div style={{marginTop: -70}}>*/}
      {/*  {grid.render()}*/}
      {/*</div>*/}
      <div className={'flex center w-full mb-24'}>
        <Button size={'sm'} onClick={() => setOpenModal(false)} className={'no-margin ml-1'}>Cancel</Button>
      </div>
      {partnerHistoryModal.render()}
      {partnerEditModal.render()}
    </Modal>
  );
};

function useSearchModalInternal(props: SearchModalProps) {
  const {openModal, setOpenModal} = props;
  const [queryText, setQueryText] = React.useState(props.queryText);
  const [totalCount, setTotalCount] = React.useState();
  const partnerHistoryModal = usePartnerHistoryModal();
  const [partnerType, setPartnerType] = React.useState('');
  const partnerEditModal = useTradePartnerEditModal(async (mode, data) => {
    if (data) {
      const isEdit = mode === 'edit';
      const isChecked = (value) => value === true || value?.[0] === 'on';
      let {isActive, sunCheck, monCheck, tueCheck, wedCheck, thuCheck, friCheck, satCheck, ...others} = data;
      isActive = isChecked(isActive);
      sunCheck = isChecked(sunCheck);
      monCheck = isChecked(monCheck);
      tueCheck = isChecked(tueCheck);
      wedCheck = isChecked(wedCheck);
      thuCheck = isChecked(thuCheck);
      friCheck = isChecked(friCheck);
      satCheck = isChecked(satCheck);
      const pickupDay = [];
      sunCheck && pickupDay.push('Sun');
      monCheck && pickupDay.push('Mon');
      tueCheck && pickupDay.push('Tue');
      wedCheck && pickupDay.push('Wed');
      thuCheck && pickupDay.push('Thu');
      friCheck && pickupDay.push('Fri');
      satCheck && pickupDay.push('Sat');
      let res;
      if(mode === 'edit') {
        res = await (api.forwardTradePartnerEdit({...others, isActive, pickupDay}));
      } else if(mode === 'delete') {
        //console.log('hello partner delete', data);
      } else {
        res = await (api.forwardTradePartnerAdd({...others, isActive, pickupDay}));
      }
      // const res = await (
      //   isEdit
      //     ? api.forwardTradePartnerEdit({...others, isActive, pickupDay})
      //     : api.forwardTradePartnerAdd({...others, isActive, pickupDay})
      // );
      if (res) {
        util.showSuccess('Trade partner has been saved successfully.');
        partnerEditModal.close();
      }
    }
  });
  const search = (action, qryText) => {
    setTotalCount(undefined);
    grid.query({qryText, partnerType, rowCount: 20, orderBy: 'cdate', isDesc: true});
  };
  React.useEffect(() => {
    if (openModal === true) {
      setTotalCount(undefined);
      setQueryText(props.queryText);
      const el = document.querySelector('#common-tp-search');
      el && el.focus();
      search(props.action, props.queryText);
    }
  }, [props.openModal]);
  const onEnter = (value) => {
    search(props.action, value);
  };
  const onOK = (data) => {
    // eslint-disable-next-line max-len
    const {id, eng_name: value, bl_name: Address, local_address: localAddress, import_no: importNo, phone, fax, cell, city, partner_type: partnerType, local_name: localName, staff_name: pic, bill_address, email, shipping_addr} = data;
    const dataInfo = {id, value, Address, localAddress, importNo, phone, fax, cell, city, partnerType, localName, pic, bill_address, email, shipping_addr};
    props.setOpenModal(false);
    props.onOK(dataInfo);
  };
  const onAdd = () => {
    if (props.action === 'TradePartner') {
      //util.openTab('/admin/partner/list?add=true');
      partnerEditModal.open('add', {partner_type: partnerType});
    }
  }
  const onEdit = (data) => {
    if (props.action === 'TradePartner') {
      // const {id, eng_name: value} = data;
      // util.openTab(`/admin/partner/list?edit=${id}&sk=${value}`);
      partnerEditModal.openWithId(data.id);
    }
  };

  const canAdd = true;
  const grid = useTradePartnerModalGrid(props.action, onEdit, onOK, partnerHistoryModal);
  const onChangePartnerType = (e) => {
    const {target: {value}} = e;
    setPartnerType(value);
    setTotalCount(undefined);
    grid.query({qryText: queryText, partnerType: value, rowCount: 20, orderBy: 'cdate', isDesc: true});
  };
  React.useEffect(() => {
    if (partnerEditModal.isOpen === false) {
      search(props.action, queryText);
    }
  }, [partnerEditModal.isOpen]);
  return {
    openModal, setOpenModal, partnerHistoryModal,
    queryText, setQueryText, totalCount, canAdd, onOK, onEnter, grid,
    onAdd,
    partnerType, onChangePartnerType,
    partnerEditModal,
  };
}

const tradePartnerForm = new TradePartnerFormManager();

function useTradePartnerEditModal(onSave) {
  const modal = useSimpleEditModal({
    title: 'TRADE PARTNER',
    width: 1200,
    form: tradePartnerForm,
    onSave,
  });
  return {
    ...modal,
    openWithId: (id) => {
      api.forwardTradePartnerView(id).then(({data}) => {
        modal.open('edit', data[0]);
      });
    },
  };
}

function useTradePartnerModalGrid(searchAction, onEdit, onOK, partnerHistoryModal) {
  async function onQuery(gridInfo) {
    console.log('hello gridInfo', gridInfo);
    return api.forwardTradePartnerList(gridInfo);
  }
  const grid = useDataGridView({
    async onAction(action, data) {
      if (action === 'edit') {
        onEdit(data);
      } else if(action === 'partnerHistory') {
        partnerHistoryModal.open(data);
      }
    },
    onQuery,
    name: `Trade Partner Search`,
    label: '',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: ''},
    menuLabel: {label: ''},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 50,
    actions: ['edit', 'partnerHistory'],
    isActionDisabled: (action, data) => {
      // eslint-disable-next-line max-len
      return action === 'partnerHistory' && data.partner_type !== 'CS' && data.partner_type !== 'VD' && data.partner_type !== 'DZ' && data.partner_type !== 'BC' ? true : false;
    },
    actionWidth: 80,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    useModalDataGrid: true,
    modalWidth: 950,
    modalDataGridHeight: 500,
    padding: 20,
    showRowSearch: false,
    columns: getColumn(),
    onRowDoubleClick: (data) => {onOK(data);}
  });

  function getColumn() {
    return [
      {field: 'id', hide: true},
      {field: 'branch_name', headerName: 'Branch', width: 100},
      {field: 'partner_type', headerName: 'Type', valueFormatter: partnerTypeFormatter,width: 100},
      {field: 'eng_name', headerName: 'Eng. Name', width: 100},
      {field: 'local_name', headerName: 'Name', width: 100},
      {field: 'phone', headerName: 'Phone', flex:1},
      {field: 'city', headerName: 'City', width: 100},
      {field: 'staff_name', headerName: 'PIC', width: 100},
      {field: 'isactive', headerName: 'Active', valueFormatter: util.yesNoFormatter, width: 50},
    ];
  }

  return grid;
}

function partnerTypeFormatter(p) {
  const item = partnerTypeOptions.find(i => i.value === p.value);
  return item ? item.label : '';
}

interface SearchOnOKParams {
  id: any;
  value: any;
}

type SearchOnOKCallback = (params: SearchOnOKParams) => void;

interface SearchState {
  open: boolean;
  value?: string;
  onOK?: SearchOnOKCallback;
}

export function useTradePartnerSearchModal(onOK: SearchOnOKCallback, queryText: string) {
  const [search, setSearch] = React.useState<SearchState>({open: false, onOK});
  return {
    show: (value = '') => {
      setSearch({...search, open: true, value});
    },
    hide: () => {
      setSearch({...search, open: false});
    },
    render: () => {
        return (
          <TracePartnerSearchModal
            openModal={search.open}
            setOpenModal={(open: boolean) => setSearch({...search, open})}
            title={'TRADE PARTNER'}
            action={'TradePartner'}
            queryText={queryText ?? search.value}
            onOK={search.onOK}
          />
        );
    },
  };
}

export function useTradePartnerSearch(onOK: SearchOnOKCallback) {
  return useTradePartnerSearchModal(onOK);
}

export default TracePartnerSearchModal;
