/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModalAccountRequest} from '../../components/SimpleModal';
import AccountRequestEditFormManager from '../../forms/account/AccountRequestEditFormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {apiAccount, util} from '../../services/service';
import AccountRequestGridFormManager from '../../forms/account/AccountRequestGridFormManager';
import {useUser} from "../../redux/reducers/userReducer";
import {getBranchName, onChcekBranch} from "../../containers/Account/AccountInvoiceAR";

const editForm = new AccountRequestEditFormManager();
const editGridForm = new AccountRequestGridFormManager();

export default function(onSave) {
  const [isScrollToBottom, setIsScrollToBottom] = React.useState(false);
  const [isBranch, setIsBranch] = React.useState(false);
  const [branchId, setBranchId] = React.useState();
  const [id, setId] = React.useState();
  const invoiceDateRef = React.useRef();
  const user = useUser();
  const userBranchId = user.branch_id;
  const userBranchIdRef = React.useRef();
  const gridRef = React.useRef();
  const isBranchRef = React.useRef(false);
  const isAccountManager = user.isManager && user.team_main === 'A';
  const isManagerRef = React.useRef();
  const isAccountRef = React.useRef();
  const isBeforeOctRef = React.useRef();
  const isShowSaveRef = React.useRef(false);
  const isShowDeleteRef = React.useRef(false);
  const isAccountDirector = user.isDirector || user.team_main === 'A';
  userBranchIdRef.current = user.branch_id;
  isBranchRef.current = onChcekBranch(branchId, userBranchIdRef.current, editForm);
  isBeforeOctRef.current = util.toEndDateTS(util.getSpecificDate('2022-09-30')) >= invoiceDateRef.current;
  isManagerRef.current = user.isManager;
  isAccountRef.current = isAccountDirector && id;
  // isAccountRef.current = getBranchName(userBranchId, editForm) === 'TORONTO' ? true : id ? (user.isDirector || isAccountManager) : true;
  // isSaveDisabledRef.current = getBranchName(userBranchId, editForm) === 'TORONTO' ? isBeforeOctRef.current ? isManagerRef.current : (isBranch && isManagerRef.current) : isManagerRef.current;
  // isDeleteDisabledRef.current = getBranchName(userBranchId, editForm) === 'TORONTO' ? isBeforeOctRef.current ? isAccountRef.current : (isBranch && isAccountRef.current) : isAccountRef.current;
  isShowSaveRef.current = getBranchName(userBranchIdRef.current, editForm) === 'TORONTO' ? isBranchRef.current : id;
  isShowDeleteRef.current = getBranchName(userBranchIdRef.current, editForm) === 'TORONTO' ? false : isAccountRef.current;

  const grid = useSimpleGrid({
    columns: [
      {field: 'id', hide: true},
      {field: 'atypeId', hide: true},
      {field: 'accountType', headerName: 'Account Type', flex: 1},
      {field: 'unit', headerName: 'Unit', width: 120},
      {field: 'rate', headerName: 'Rate', width: 120, valueFormatter: util.currencyFormatter},
      {field: 'volume', headerName: 'Volume', width: 120, valueFormatter: util.currencyFormatter},
      {field: 'amount', headerName: 'Amount', flex: 1, valueFormatter: amountFormatter },
    ],
    height: 200,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    isScrollToBottom: isScrollToBottom,
    onAction: (action, data) => {
      if (action === 'delete') {
        const currentGrid = gridRef.current;
        const index = currentGrid.rows.findIndex(i => i['id'] === data['id']);
        const newRows = [...currentGrid.rows];
        newRows.splice(index, 1);
        currentGrid.setRows(newRows);
      }
    },
  });
  editGridForm.onSave = () => {
    const {id = grid.rows?.length ?? 0, atypeId, accountType, unit = 'CNTR', rate = 0, volume = 1} = editGridForm.getValues();
    if(!atypeId || !accountType) {
      util.showWarning('Please enter a valid Account Type.');
      return;
    }
    if(rate <= 0 || isNaN(rate)) {
      util.showWarning('Please enter a valid Rate.');
      return;
    }
    if(!volume || isNaN(volume)) {
      util.showWarning('Please enter a valid volume.');
      return;
    }
    grid.setRows([...(grid.rows ?? []), {id, atypeId, accountType, unit, rate, volume}]);
    editGridForm.clearValuesFast();
    setIsScrollToBottom(true);
  };
  const modal = useSimpleEditModalAccountRequest({
    title: 'ACCOUNT REQUEST',
    centered: true,
    width: 900,
    form: editForm,
    onSave,
    // isManager: isBeforeOctRef.current ? isManagerRef.current : (isBranch && isManagerRef.current),
    // isAccount: isBeforeOctRef.current ? isAccountRef.current : (isBranch && isAccountRef.current),
    isManager: isShowSaveRef.current,
    isAccount: isShowDeleteRef.current,
    children: (
      <>
        {grid.render()}
        {editGridForm.renderForm()}
      </>
    ),
  });
  gridRef.current = grid;
  React.useEffect(() => {
    if (modal.isOpen) {
      if (modal.data?.id > 0) {
        apiAccount.accountAPData({id: modal.data.id}).then(({data}) => {
          if (Array.isArray(data) && data.length === 1) {
            editForm.setValuesFast({
              ...data[0],
              ...data[0].jextra,
              invoiceNo: data[0].inv_no,
              invoiceDate: data[0].inv_date,
              financialOffice: data[0].branch_id,
              blId: data[0].bl_id,
              mblNo: data[0].jbl.mblNo,
              hblNo: data[0].jbl.hblNo,
              polLabel: data[0].jbl.pol,
              podLabel: data[0].jbl.pod,
              polEtd: data[0].jbl.etd,
              podEta: data[0].jbl.eta,
            });
            grid.setRows(data[0]['jitems']);
            setBranchId(data[0].branch_id);
            setId(modal.data?.id);
            invoiceDateRef.current = data[0].inv_date;
          } else {
            // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
          }
        });
      } else {
        grid.setRows([]);
      }
    } else {
      // setIsBranch(true);
      setBranchId(undefined);
      setId(undefined);
      isAccountRef.current = undefined;
    }
  }, [modal.isOpen, id]);

  // React.useEffect(() => {
  //   setIsBranch(onChcekBranch(branchId, userBranchId, editForm));
  // }, [branchId, userBranchId]);

  return {requestEditForm: editForm, grid, ...modal};
}

function amountFormatter(p) {
  let amount = p.data['rate'] * p.data['volume'];
  amount = util.round(amount, 2);

  if (isNaN(amount))
    return (0).toFixed(2);
  else
    return util.formatCurrency(amount);
}
