/**
 * @flow
 */
import React from "react";
import FormManager from "../../lib/FormManager";
import {apiAccount, util} from "../../services/service";
import {currencyOptions, paymentOptions} from "../helper";
import {useUser} from "../../redux/reducers/userReducer";
import {FN} from "../field-defs/account";

// {value: 'CHEQUE', label: 'CHEQUE'},
// {value: 'CASH', label: 'CASH'},
// {value: 'C.CARD', label: 'C.CARD'},

const remarkLabel = {
  'CHEQUE': 'Cheque No.',
  'CASH': 'Remark',
  'C.CARD': 'Last 4 digits',
};
class AccountPaymentFormManager extends FormManager {
  totalAmount = 0;
  constructor() {
    super({
      prefix: 'payment-form',
      fields: [
        {name: 'invoiceId', serverName: 'invoice_id', label: ''},
        {name: 'jobId', serverName: 'job_id', label: ''},
        {name: 'payAmount', serverName: 'pay_amount', label: ''},
        {name: 'payDate', serverName: 'pay_date', label: '', type: 'date', mask: util.MASK_DATE},
        {name: 'payType', serverName: 'pay_type', label: '', options: paymentOptions, noDefOption: true},
        {name: 'rate', serverName: 'rate', label: ''},
        {name: 'currency', serverName: 'currency', label: '', options: currencyOptions, noDefOption: true},
        {name: 'remark', serverName: 'remark', label: ''},
        {name: 'bankInfo', serverName: 'bank_info', label: '', options: [], noDefOption: true},
        {name: 'fullPay', serverName: 'fullPay', label: '', type:'checkbox', smallMargin:true},
        {name: 'autoEmail', serverName: 'autoEmail', label: '', type:'checkbox', smallMargin:true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  setData(data) {
    this.totalAmount = data?.reduce((acc, cur) => acc + cur.pay_amount, 0);
  };

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const payDate = _v('payDate');
    const currency = _v('currency');
    const payType = _v('payType');
    const autoEmail = _v('autoEmail');
    const branchId = _v(FN.FINANCIAL_OFFICE);
    // _f(FN.BANK_INFO).options = apiAccount.useBankList(branchId);
    _f(FN.BANK_INFO).options = apiAccount.useBranchBankName(branchId);

    React.useEffect(() => {
      if (!payDate) {
        _c('payDate', util.getCurrentDate());
      }
      if(!currency) {
        _c('currency', 'CAD');
      }
      if(!payType) {
        _c('payType', 'CHEQUE');
      }
    }, [payDate, currency, payType]);

    React.useEffect(() => {
      if(!autoEmail) {
        _c('autoEmail', true);
      }
    }, []);

    return (
      <>
        <div className={'form-row flex justify-content-end wrap w-full'} style={{paddingBottom: 10}}>
          <div style={{color: 'red'}}>Total Paid: {util.formatCurrency(this.totalAmount)}</div>
        </div>
        <div className={'form-row flex wrap w-full'}>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Full Payment</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Currency</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Rate</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Amount</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Date</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Type</span></div>
          <div className={'flex-2 mr-12'}><span className={'f-11 pl-8'}>{remarkLabel[_v('payType')]}</span></div>
          <div className={'flex-2 mr-12'}><span className={'f-11 pl-8'}>Bank Info.</span></div>
          <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Auto Email</span></div>
        </div>
        <div className={'form-row flex wrap w-full'}>
          <div className={'flex-1 ml-20'}>{_r(`fullPay`)}</div>
          <div className={'flex-1 mr-12'}>{_r(`currency`)}</div>
          <div className={'flex-1 mr-12'}>{_r(`rate`)}</div>
          <div className={'flex-1 mr-12'}>{_r(`payAmount`)}</div>
          <div className={'flex-1 mr-12'}>{_r(`payDate`)}</div>
          <div className={'flex-1 mr-12'}>{_r(`payType`)}</div>
          <div className={'flex-2 mr-12'}>{_r(`remark`)}</div>
          <div className={'flex-2 mr-12'}>{_r('bankInfo')}</div>
          <div className={'flex-1 ml-20'}>{_r(`autoEmail`)}</div>
        </div>
      </>
    );
  };
}

export default AccountPaymentFormManager;
