/**
 * @flow
 */
import React from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import LinearIcon from './LinearIcon';
import {PrimaryButton} from './buttons';
import {api, util} from '../services/service';
import {useUser} from '../redux/reducers/userReducer';
import {kindOptions} from '../forms/field-defs/bl';

export interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: any;
}

interface CalendarViewProps {
  buttonLabel?: string;
  onButtonClick?: () => void;
  defaultView?: 'month' | 'week';
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
  onDateClick: (date: Date) => void;
  onViewChange?: (view: 'month' | 'week') => void;
  onShowMore: (events: CalendarEvent[], date: Date) => void;
  showBranchOptions?: boolean;
  onBranchChange?: (branchId: number) => void;
  showKindOptions?: boolean;
  onKindChange?: (kind: string) => void;
  setViewRef?: any;
  useBoxDelivery?: boolean;
  onMonthChange?: (branchId: number) => void;
  isTodayColored?: boolean;
}

const scheduleKindOptions = [...kindOptions];
scheduleKindOptions.splice(4, 1);
scheduleKindOptions.unshift({value: '', label: 'ALL'});
const scheduleKindOptionsEx = [...scheduleKindOptions, {value: 'B', label: 'Box Delivery'}];

const CalendarView = (props: CalendarViewProps) => {
  const {
    date, view, setView, events, onEventDrop, onNavigate, onSelectEvent, onShowMore, onView, areaOptions,
    branch, onBranchChange, kind, onKindChange, month, onMonthChange, onColored, calendarStyle
  } = useCalendarView(props);
  if (props.setViewRef) {
    props.setViewRef.current = setView;
  }
  return (
    <div className={'calendar'} style={{position: 'relative', fontWeight: 'bold'}}>
      <DNDCalendar
        localizer={localizer}
        events={events}
        views={['month', 'week']}
        view={view}
        formats={formats}
        onEventDrop={onEventDrop}
        onNavigate={onNavigate}
        onSelectEvent={onSelectEvent}
        onView={onView}
        date={date}
        eventPropGetter={onColored}
        dayPropGetter={calendarStyle}
        messages={{
          previous: <LinearIcon name={'chevron-left'} />,
          next: <LinearIcon name={'chevron-right'} />,
          today: 'Today', //<span className="lnr lnr-calendar-full" />,
        }}
        popup={false}
        onShowMore={onShowMore}
      />
      <div className={'flex middle form'} style={{position: 'absolute', top: 7, left: 140}}>
        {props.showKindOptions && (
          <div className={'form__form-group'} style={{width: 120, margin: 0, marginLeft: 20, marginRight: 20}}>
            <select value={kind} onChange={onKindChange}>
              {(props.useBoxDelivery === true ? scheduleKindOptionsEx : scheduleKindOptions).map(({value, label}) => {
                return <option key={value} value={value}>{label}</option>
              })}
            </select>
          </div>
        )}
        {props.buttonLabel && <PrimaryButton label={props.buttonLabel} onClick={props.onButtonClick} noMargin />}
      </div>
      {props.showBranchOptions === true && (
        <div className={'form'} style={{position: 'absolute', top: 8, right: 180, marginBottom: 0, width: 'inherit'}}>
          <div className={'form__form-group'}>
            <select value={branch} onChange={onBranchChange}>
              <option value={0}>ALL</option>
              {areaOptions.map(({value, label}) => {
                return <option key={value} value={value}>{label}</option>
              })}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

let showMoreClicked: boolean = false; // hacky way but very effective!

function useCalendarView(props: CalendarViewProps) {
  const user = useUser();
  const [date, setDate] = React.useState(new Date());
  const [view, setView] = React.useState(props.defaultView);
  const [events, setEvents] = React.useState<CalendarEvent[]>(props.events);
  const [branch, setBranch] = React.useState(props.branch_id);
  const [kind, setKind] = React.useState(props.kind);
  const [month, setMonth] = React.useState();
  React.useEffect(() => {
    setEvents(props.events);
    setBranch(props.branch);
    setKind(props.kind);
  }, [props.events]);
  // React.useEffect(() => {
  //   setBranch(user.branch_id);
  //   props.onBranchChange && props.onBranchChange(user.branch_id);
  //   const team = user.team_main;
  //   const moving = team === 'S' || team === 'A' || team === 'N' || team === 'F';
  //   setKind(moving ? '' : team);
  // }, [user]);
  const onEventDrop = ({start, end, event}) => {
    const newEvent = {...event, start, end};
    const eventIndex = events.findIndex(e => e.resource.id === event.resource.id);
    events.splice(eventIndex, 1, newEvent);
    setEvents([...events]);
  };
  const onNavigate = (date, view, action, ...rest) => {
    setTimeout(() => { // onShowMore 이벤트가 나중에 발생하므로 다음 프로세싱에서 처리하도록 타임아웃을 걸어주어 showMore 를 클릭한 것인지 날짜를 클릭한 것인지 판단함!
      if (!showMoreClicked) {
        setDate(date);
        let newView;
        if (action === 'DATE') {
          newView = view === 'month' ? 'week' : 'month';
          setView(newView);
        } else {
          newView = view;
        }
        props.onDateClick(date, newView, action);
      }
      showMoreClicked = false;
    }, 0);
  };
  const onSelectEvent = (event) => {
    props.onEventClick(event);
  };
  const onShowMore = (events, date) => {
    showMoreClicked = true;
    setView('week');
    setDate(date);
    props.onShowMore(events, date);
  };
  const onView = view => {
    setView(view);
    props.onViewChange && props.onViewChange(view, date);
  }
  const areaOptions = api.useAreaOptions(true);
  const onBranchChange = ({target: {value}}) => {
    setBranch(value);
    props.onBranchChange && props.onBranchChange(value ? parseInt(value) : undefined);
  };
  const onKindChange = ({target: {value}}) => {
    setKind(value);
    props.onKindChange && props.onKindChange(value);
  };
  const onMonthChange = ({target: {value}}) => {
    setMonth(value);
    setDate(date);
    props.onMonthChange && props.onMonthChange(value ?? undefined);
  };
  const onColored = (event) => {
    let colorStyle = {};
    if(props.isTodayColored) {
      colorStyle = event.resource.planDate === util.toTS(util.getCurrentDate()) ? {style: {color: 'blue'}} : {};
    } else {
      const {resource: {balance, filing_no}} = event;
      if(balance > 0 && !filing_no) {
        colorStyle = {style: {color: 'red'}};
      } else if(balance === 0 && !filing_no) {
        colorStyle = {style: {color: 'green'}};
      } else if(balance > 0 && filing_no) {
        colorStyle = {style: {color: 'orange'}};
      } else {
        colorStyle = {style: {color: 'black'}};
      }
    }
    return colorStyle;
  };
  const calendarStyle = (calendar) => {
    return util.formatD(calendar) === util.getCurrentDate() ? {style: {backgroundColor: '#fffadf'}} : {};
  };
  return {date, view, setView, events, onEventDrop, onNavigate, onSelectEvent, onShowMore, onView, areaOptions, branch, onBranchChange, kind, onKindChange, month, onMonthChange, onColored, calendarStyle};
}

const localizer = momentLocalizer(moment);
const DNDCalendar = Calendar; //withDragAndDrop(Calendar); 일단 D&D 기능은 사용 하지 않음
const formats = {
  dayFormat: (date, culture) => localizer.format(date, 'ddd / MMM DD', culture),
};

export default CalendarView;
