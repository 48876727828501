import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {util} from '../../services/service';
import {Button, ButtonToolbar, Input, Modal} from 'reactstrap';

const GDLG_OPEN = 'GDLG_OPEN';
const GDLG_CLOSE = 'GDLG_CLOSE';

export function showDialog(message, title, color, onConfirm?: () => void, onCancel?: () => void, okText, cancelText) {
  util.dispatch({type: GDLG_OPEN, message, title, color, onConfirm, onCancel, okText, cancelText});
}

function hideDialog() {
  util.dispatch({type: GDLG_CLOSE});
}

export interface GlobalDialogState {
  open: boolean;
  title?: string;
  message?: string;
  color?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
}

const globalDialogReducer = (state: GlobalDialogState = {open: false}, action) => {
  const {type, message, title, color, onConfirm, onCancel, okText, cancelText} = action;
  switch (type) {
    case GDLG_OPEN:
      return {open: true, title, message, color, onConfirm, onCancel, okText, cancelText};
    case GDLG_CLOSE:
      return {open: false};
    default:
      return state;
  }
};

export function GlobalDialog() {
  const dialog = useSelector(s => s['gdlg']);
  const {open, title, message, color, onConfirm, onCancel, okText, cancelText} = dialog;
  const rtl = {direction: 'ltr'};
  const modalClass = 'modal-dialog--header';
  const onKeyPress = (e) => {
    if(e.key === 'Enter' && !onConfirm) {
      hideDialog();
    }
  };
  return (
    <div onKeyPress={onKeyPress}>
      <Modal
        isOpen={open}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        backdrop={'static'}
        keyboard={false}
        centered
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => hideDialog()} />
          {/*{header ? '' : Icon}*/}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body" style={{whiteSpace: 'pre-line'}}>
          {message}
        </div>
        <ButtonToolbar className="modal__footer">
          {onConfirm && <Button className="modal_cancel" onClick={() => {onCancel && onCancel(); hideDialog();}}>{cancelText ? cancelText : 'Cancel'}</Button>}
          {onConfirm && ' '}
          <Button className="modal_ok" color={color} onClick={() => {onConfirm && onConfirm(); hideDialog();}}>{okText ? okText : 'OK'}</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
}

export default globalDialogReducer;
