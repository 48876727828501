/**
 * @flow
 */
import React from 'react';

export function useState<T>(initialState: T): [T, (state: T) => void] {
  const [state, setState] = React.useState<T>(initialState);
  const update = (partialState: T) => setState({...state, ...partialState});
  return [state, update];
}

export function useStateEx<T>(initialState: T) {
  const [state, dispatch] = React.useReducer<T>(reducer, initialState);
  const update = (state) => dispatch({type: 'UPDATE_STATE', state});
  return [state, update];
}

function reducer<T>(state: T = {}, action: any) {
  if (action.type === 'UPDATE_STATE') {
    return {...state, ...action.state};
  } else {
    return state;
  }
}