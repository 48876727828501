/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";
import {TradePartnerSearchInput} from "../../components/SearchInput";
import {DefaultButton} from "../../components/buttons";

const carrierTypeOptions = [
  {value: 'A', label: 'Air'},
  {value: 'O', label: 'Ocean'}
];

class MasterCourierPriceEditFormManager extends FormManager {
  grid;
  id;
  getData: () => void;
  constructor() {
    super({
      prefix: 'account-courier-price-edit',
      fields: [
        {name: 'partnerId', serverName: 'partner_id', label: '', smallMargin: true},
        {name: 'partner', serverName: 'partner', label: '', smallMargin: true},
        {name: 'carrierType', serverName: 'carrierType', label: '', options: carrierTypeOptions, noDefOption: true, smallMargin: true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onAdd = () => {
    const rows = this.grid.rows ?? [];
    let id = rows?.length ?? 0;
    const weight = 0;
    const price = 0;
    const newRows = [...rows, {id, weight, price}];
    this.grid.setRows(newRows);
  };



  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;

    const partnerId = _v('partnerId');
    React.useEffect(() => {
      this.id = partnerId
    }, [partnerId]);
    const carrierType = _v('carrierType');
    React.useEffect(() => {
      //console.log('hello.carrierType', carrierType);
      if(!carrierType) {
        _c('carrierType', 'A');
      }
      this.getData(carrierType);
    }, [carrierType]);
    return (
      <div className={'flex w-full'}>
        <div className={'flex flex-2'}>
          <label className={'mt-8'}>Partner</label>
          <div className={'mr-8 ml-8'}>{_tp('partner')}</div>
        </div>
        <div className={'flex-1 mr-8 ml-8'}>{_r('carrierType')}</div>
        <div className={'flex-1 ml-8'}><DefaultButton label={'ADD'} onClick={this.onAdd}></DefaultButton></div>
      </div>
    );
  };
  onValidate = (values) => values;
}

export default MasterCourierPriceEditFormManager;
