import React from 'react';
import {apiAccount, util} from '../../services/service';
import {useSimpleGridModal, useSimpleModal} from '../../components/SimpleModal';
import {useDataGridView} from "../../components/DataGridView";
import {queryGridData} from "../../components/DataGrid";
import {FN, INVOICE} from "../../forms/field-defs/account";
import AccountDCNoteDetailFormManager from "../../forms/account/AccountDCNoteDetailFormManager";

const detailForm = new AccountDCNoteDetailFormManager();

export default function useDCNoteDetailModal() {
  const [mode, setMode] = React.useState();
  const [data, setData] = React.useState(); // 그리드에서 선택한 행의 데이터를 보관함
  const [selectedGridData, setSelectedGridData] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  async function onQuery(gridInfo) {
    if (modal.isOpen) {
      const {partnerId, id} = data;
      const dataInfo = {...gridInfo, ...data, isAll: true};
      if (partnerId || id) {
        // return apiAccount.dcNoteHistoryDetail(dataInfo);
        const {data, totalCount} = await apiAccount.dcNoteHistoryDetail(dataInfo);
        // if(data) {
        //   const {data} = await apiAccount.accountDCCurrency(dataInfo);
        //   detailForm.setCurrency(data);
        // }
        modal.setRows(data);
        return {
          totalCount,
          data,
        };
      }
    }
  }

  // const grid = useDataGridView({
  //   async onAction(action, data) {
  //   },
  //   onQuery,
  //   onCellValueChange(e) {
  //   },
  //   onCellClick({data, column: {colDef: {headerName}}}) {
  //   },
  //   name: `D/C Note Detail`,
  //   label: '',
  //   addLabel: '',
  //   editLabel: '',
  //   categoryLabel: {label: 'Account'},
  //   menuLabel: {label: 'D/C Note'},
  //   sortCol: 'cdate',
  //   sortDesc: true,
  //   pageRows: 20,
  //   actions: [],
  //   actionWidth: 0,
  //   showDateSearch: false,
  //   showDeleteButton: false,
  //   hideSearchInput: true,
  //   sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
  //   useExternalForm: true,
  //   useModalDataGrid: true,
  //   modalDataGridHeight: 500,
  //   showRowSearch: false,
  //   columns: getColumn(),
  // });

  // const modal = useSimpleModal({
  //   title: getTitle(),
  //   centered: true,
  //   width: 1024,
  //   form: detailForm,
  //   buttons: getButtons(),
  //   children: (
  //     <>
  //       {grid.render()}
  //       {/*{mode ? '' : detailForm.renderForm()}*/}
  //     </>
  //   )
  // });

  const onSelectionChanged = (e) => {
    const rows = e.api.getSelectedRows();
    let tAmount = 0;
    rows.forEach(row => {
      tAmount += row.amount;
    });
    setSelectedGridData(rows);
    setTotalAmount(tAmount);
  };

  const modal = useSimpleGridModal({
    title: getTitle(),
    centered: true,
    width: 1024,
    buttons: getButtons(),
    gridProps: {
      columns: getColumn(),
      rows: [],
      height: 500,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onSelectionChanged: onSelectionChanged,
        suppressMenuHide: true,
        isRowSelectable: function (rowNode) {
          return !(rowNode.data && rowNode.data.done === 'Yes');
        }
      }
    },
    children: (
      <div className={'flex'} style={{position: 'absolute', bottom: 50, width: '95%', textAlign: 'right'}}>
        <div className={'flex-1'}>Total Amount: {util.formatCurrency(totalAmount)}</div>
      </div>
    )
  });

  function getColumn() {
    switch (mode) {
      case INVOICE.TYPE_DC_NOTE:
        return [
          {field: 'account_id', hide: true},
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
          {field: 'inv_no', headerName: 'Inv No.', width: 120, cellRendererFramework: (param) => util.accountLinkFormatter(param, 'dc', true)},
          {field: 'bl_no', headerName: 'B/L No.', width: 120, cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true)},
          {field: 'ref_no', headerName: 'Ref. No.', flex: 1},
          {field: 'customer_name', headerName: 'Customer', flex: 1},
          {field: 'currency', headerName: 'Currency', width: 100},
          {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 100},
        ];
      default:
        return [
          {field: 'account_id', hide: true},
          {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
          {field: 'inv_no', headerName: 'Inv No.', width: 120, cellRendererFramework: (param) => util.accountLinkFormatter(param, 'dc', true)},
          {field: 'bl_no', headerName: 'B/L No.', width: 120, cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true)},
          {field: 'ref_no', headerName: 'Ref. No.', flex: 1},
          {field: 'customer_name', headerName: 'Customer', flex: 1},
          {field: 'currency', headerName: 'Currency', width: 100},
          {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 100},
          {field: 'done', headerName: 'Done', width: 100},
        ];
    }
  }

  function getTitle() {
    let title = `D/C DETAIL (${util.formatD(data?.fromDate)} ~ ${util.formatD(data?.toDate)})`;
    if(mode === INVOICE.TYPE_DC_NOTE) {
      title = `D/C DETAIL (${data.currency})`;
    }
    return title;
  }

  function getButtons() {
    let button = [{label: 'Make Note', color: 'primary', onClick: onSave}];
    if(mode === INVOICE.TYPE_DC_NOTE) {
      button = [];
    }
    return button;
  }

  async function onSave() {
    // const values = detailForm.getValues(undefined, true);
    // if (!values) {
    //   return;
    // }
    // const jexchange = {CAD: util.toFloat(values.CAD), USD: util.toFloat(values.USD), KRW: util.toFloat(values.KRW)};
    // const dataInfo = {...data, jexchange};

    const accountIds = selectedGridData.map(account => {return account.account_id;});
    const dataInfo = {fromDate: data.fromDate, toDate: data.toDate, accountIds};
    const res = await apiAccount.setDCNote(dataInfo);
    if(res) {
      modal.close();
      util.openTab('/admin/account/list/dcnote?id=' + res.id);
    }
  }

  React.useEffect(() => {
    if (modal.isOpen === true) {
      const {partnerId, id} = data;
      if (partnerId || id) {
        // queryGridData(grid.props.name, {page: 1}, onQuery).then(() => console.log('hello, grid query called!'));
        onQuery().catch();
      }
    }
  }, [modal.isOpen]);

  const open = (data: Object, mode: string) => {
    setData(data);
    setMode(mode);
    modal.open();
  };
  return {...modal, open};
}
