/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {usePrintModal} from './usePrintModal';
import OEMLabel from '../../../print-layouts/OEMLabel';
import {getBLEntryData} from '../../field-defs/bl';
import {apiBL} from '../../../services/service';

const FN = {
  PIECES: 'pieces',
  NUMBER1: 'number1',
  NUMBER2: 'number2',
  MBL_NO: 'mblNo',
  DESTINATION: 'destination',
  LABELS: 'labels',
};

export class OEMLabelPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'label-oem',
      fields: [
        {name: FN.PIECES, serverName: FN.PIECES, label: 'Total Pieces', readonly: true},
        {name: FN.NUMBER1, serverName: FN.NUMBER1, label: 'Number'},
        {name: FN.NUMBER2, serverName: FN.NUMBER2, label: ''},
        {name: FN.MBL_NO, serverName: FN.MBL_NO, label: 'MB/L No.', readonly: true},
        {name: FN.DESTINATION, serverName: FN.DESTINATION, label: 'Destination', readonly: true},
        {name: FN.LABELS, serverName: FN.LABELS},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.PIECES)}
        <div className={'flex middle'}>
          <div className={'flex-2'}>{_r(FN.NUMBER1)}</div>
          <div className={'mx-1 mb-20'}>~</div>
          <div className={'flex-1'}>{_r(FN.NUMBER2)}</div>
        </div>
        {_r(FN.MBL_NO)}
        {_r(FN.DESTINATION)}
      </div>
    );
  };
}

const labelPrintForm = new OEMLabelPrintForm();

export function useOEMLabelPrintModal(blForm) {
  const onOpen = async () => {
    const values = blForm.getValues();
    const blData = getBLEntryData(true, values);
    const {blNo, filingNo} = blData;
    const {data} = await apiBL.getLabelPrintData({blNo, filingNo, isAir: false});
    const {mblNo, totalmaster: pieces, destination, labels} = data[0];
    labelPrintForm.setValuesFast({
      pieces, number1: 1, number2: pieces, mblNo, destination, labels,
    });
  };
  return usePrintModal(true, 'PACKAGE LABEL', 1024, blForm, labelPrintForm, OEMLabel, onOpen);
}
