/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import {apiBL, util} from '../../services/service';
import MarkFormManager from './MarkFormManager';

const FN = {
  IT_NO: 'itNo',
  DATE: 'date',
  IT_LOC: 'itLoc',
  GOODS_NOW: 'goodsNow',
  GOODS_VALUE: 'goodsValue',
  CCN: 'ccn',
  CCN_DATE: 'ccnDate',
  PREV_CCN: 'prevCcn',
  MANIFEST_FORM: 'manifestForm',
  TO_A: 'toA',
  SAID: 'said',
  SAY: 'say',
  MARK: 'mark',
  DESCRIPTION: 'desc',
  IN_REMARK: 'remark',
  PO_NO: 'poNo',
  PUB_REMARK: 'pubRemark',
  CFS_REMARK: 'cfsRemark',
};

const LB = {

};

class MarkOIHFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  blForm;
  constructor() {
    super({
      prefix: 'mark-OIH',
      fields: [
        {name: FN.IT_NO, serverName: FN.IT_NO, label: 'IT No.'},
        {name: FN.DATE, serverName: FN.DATE, label: 'Date', type: 'date', mask: util.MASK_DATE},
        {name: FN.IT_LOC, serverName: FN.IT_LOC, label: 'IT Issued Loc.'},
        {name: FN.GOODS_NOW, serverName: FN.GOODS_NOW, label: 'Goods Now At'},
        {name: FN.GOODS_VALUE, serverName: FN.GOODS_VALUE, label: 'Value of Goods'},
        {name: FN.CCN, serverName: FN.CCN, label: 'CCN'},
        {name: FN.CCN_DATE, serverName: FN.CCN_DATE, label: 'Date', type: 'date', mask: util.MASK_DATE},
        {name: FN.PREV_CCN, serverName: FN.PREV_CCN, label: 'Prev.CCN'},
        {name: FN.MANIFEST_FORM, serverName: FN.MANIFEST_FORM, label: 'Manifest Form'},
        {name: FN.TO_A, serverName: FN.TO_A, label: 'To / A'},
        {name: FN.SAID, serverName: FN.SAID, label: '', type: 'textarea'},
        {name: FN.SAY, serverName: FN.SAY, label: '', type: 'textarea'},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: '', type: 'textarea'},
        {name: FN.IN_REMARK, serverName: FN.IN_REMARK, label: '', type: 'textarea'},
        {name: FN.PO_NO, serverName: FN.PO_NO, label: '', type: 'textarea'},
        {name: FN.PUB_REMARK, serverName: FN.PUB_REMARK, label: '', type: 'textarea'},
        {name: FN.CFS_REMARK, serverName: FN.CFS_REMARK, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          {_r(FN.IT_NO)}
          {_r(FN.DATE)}
          {_r(FN.IT_LOC)}
          {_r(FN.GOODS_NOW)}
          {_r(FN.GOODS_VALUE)}
        </div>
        <div className={'flex'}>
          {_r(FN.CCN)}
          {_r(FN.CCN_DATE)}
          {_r(FN.PREV_CCN)}
          {_r(FN.MANIFEST_FORM)}
          {_r(FN.TO_A)}
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Said')}
            {_r(FN.SAID)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Say')}
            {_r(FN.SAY)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Description')}
            {_r(FN.DESCRIPTION)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Internal Remark')}
            {_r(FN.IN_REMARK)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('P/O No.')}
            {_r(FN.PO_NO)}
          </div>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('A/N Public Remark')}
            {_r(FN.PUB_REMARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('CFS Remark')}
            {_r(FN.CFS_REMARK)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const form = new MarkOIHFormManager();

export function useMarkOIHModal(onSave, blForm) {
  const [isInited, setIsInited] = React.useState();
  const [uname, setUname] = React.useState('');
  const title = !isInited ? `( EDITED by ${uname??''} )` : '';
  form.blForm = blForm;
  const modal = useSimpleEditModal({
    title: `OIH Mark & Desc. ${title}`,
    width: 1024,
    form,
    onSave,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      // setTimeout(() => { // NOTE: 이미 modal.isOpen 훅이 작동을 했으므로 다음 프레임에서 처리를 해주어야 폼에 값이 제대로 설정됨 (무슨 말이지???)
      //   const packages =  blForm.getValue('package') ? `${blForm.getValue('package')} ${blForm.getValue('packageType')}` : '';
      //   if (!form.getValue(FN.DESCRIPTION)) {
      //     form.setValue(FN.DESCRIPTION, blForm.getValue('commodity'));
      //   }
      //   if (!form.getValue(FN.SAID)) {
      //     form.setValue(FN.SAID, packages);
      //   }
      //   if (!form.getValue(FN.MARK)) {
      //     // mark 가 설정되어 있지 않은 경우 컨테이너 정보를 쿼리하여 설정해줌
      //     apiBL.getContainers(blForm.getValue('id')).then(({data}) => {
      //       if (data?.length > 0) {
      //         const info = [];
      //         const tpSize = [];
      //         for (const {container_no, seal_no, tp_size} of data) {
      //           if(container_no || seal_no) {
      //             info.push(`${container_no}/${seal_no}`);
      //             tpSize.push(`${tp_size} x 1`);
      //           }
      //         }
      //         form.setValue(FN.MARK, info.join('\n'));
      //         form.setValue(FN.SAID, packages + '\n' + tpSize.join('\n'));
      //       }
      //     });
      //   }
      // }, 0);
      setIsInited(blForm.getValue('isInited'));
      setUname(blForm.getValue('uname'));
      setTimeout(() => { // NOTE: 이미 modal.isOpen 훅이 작동을 했으므로 다음 프레임에서 처리를 해주어야 폼에 값이 제대로 설정됨 (무슨 말이지???)
        let info = '';
        let tpSize = '';
        const packages =  blForm.getValue('package') ? `${blForm.getValue('package')} ${blForm.getValue('packageType')}` : '';
        apiBL.getContainers(blForm.getValue('id')).then(({data}) => {
          if (data?.length > 0) {
            for (const {container_no, seal_no, tp_size} of data) {
              if(container_no || seal_no) {
                info += `${container_no} / ${seal_no}\n`;
                tpSize += tp_size ? `${tp_size} x 1\n` : '';
              }
            }
          }
          // const said = packages ? `${packages}\n${tpSize}` : tpSize;
          const said = packages ? packages : '';
          if (!form.getValue(FN.DESCRIPTION)) {
            form.setValue(FN.DESCRIPTION, blForm.getValue('commodity'));
          }
          if (!form.getValue(FN.MARK)) {
            form.setValue(FN.MARK, info);
          }
          if (!form.getValue(FN.SAID)) {
            form.setValue(FN.SAID, said);
          }
          if (!form.getValue(FN.SAY)) {
            form.setValue(FN.SAY, said);
          }
        });
      }, 0);
    }
  }, [modal.isOpen]);
  return modal;
}

export default MarkOIHFormManager;
