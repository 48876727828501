/**
 * @flow
 */
import React from 'react';
import {PrimaryButton} from "../../../../components/buttons";
import useMovingMasterBLHblListGridView from "../../../../grids/newCoship/moving/master/useMovingMasterBLHblListGridView";
import {useMasterBLHouseListModal} from "../../../../modals/newCoship/useMasterBLHouseListModal";
import {useSelector} from "react-redux";
import {apiMoving} from "../../../../services/newCoship/service";
import {util} from "../../../../services/service";

interface MovingMasterBLHblProps {
  isShowList: boolean;
  hblList: Array;
}

const MovingMasterBLHbl = (props: MovingMasterBLHblProps) => {
  const {isShowList, hblList} = props;
  const qs = util.getQS();
  const {id} = qs;
  const {grid} = useMovingMasterBLHbl();
  const {filingNo} = useSelector(state => state['newCoship']);

  const getSelectedData = (selectedData) => {
    const gridData = [...grid.rows, ...selectedData].map((data, index) => ({...data, seq_no: index+1}))
    grid.setRows(gridData);
  };

  const houseListModal = useMasterBLHouseListModal(getSelectedData, grid.rows, 'M');

  const onAdd = () => {
    houseListModal.open({filing_no: filingNo});
  };

  const onSave = () => {
    const rows = [];
    if (grid.apiRef && grid.apiRef.current) {
      grid.apiRef.current.forEachNode(node => rows.push(node.data));
    }
    const payload = {
      filing_no: filingNo,
      bl_ids: rows?.map((data) => data.bl_id) ?? [],
    };
    apiMoving.setMBLHouses(payload).then((res) => {
      if (res) {
        grid.setRows(res.data);
        util.showSuccess('House BL has been saved successfully!');
      }
    });
  };

  React.useEffect(() => {
    grid.setRows(hblList);
  }, [hblList]);

  return (
    <div className={'mt-4 ml-4 mr-4'}>
      {isShowList &&
        <>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <PrimaryButton label={'ADD'} disabled={!id} onClick={onAdd}/>
            <PrimaryButton label={'SAVE'} disabled={!id} onClick={onSave}/>
          </div>
          <div style={{marginTop: -15}}>
            {grid.render()}
          </div>
        </>
      }
      {houseListModal.render()}
    </div>
  );
};

function useMovingMasterBLHbl() {
  const onAction = (action, data) => {
    if (action === 'delete') {
      util.showConfirm('Are you sure to delete?', async () => {
        const rows = [];
        if (grid.apiRef && grid.apiRef.current) {
          grid.apiRef.current.forEachNode(node => rows.push(node.data));
        }
        const newRows = rows.filter(x => x.seq_no !== data.seq_no);
        grid.apiRef.current.setRowData(newRows);
      });
    }
  };
  const grid = useMovingMasterBLHblListGridView(onAction);
  return {grid};
}

export default MovingMasterBLHbl;
