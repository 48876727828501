/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from "../../components/SimpleModal";
import AccountRequestStatementFormManager from "../../forms/account/AccountRequestStatementFormManager";
import {apiAccount} from "../../services/service";

const requestStatement = new AccountRequestStatementFormManager();

export default function useRequestStatementModal(onSearch) {
  const [dataInfo, setDataInfo] = React.useState();
  const [billToData, setBillToData] = React.useState();
  const [statementPrint, setStatementPrint] = React.useState();

  const onPrint = async () => {
    const accountIds = [];
    dataInfo.forEach(data => {
      accountIds.push(data.id);
    });
    await apiAccount.setAPStatement(accountIds);
    const memo = requestStatement.getValue('memo');
    statementPrint.open(dataInfo, billToData, memo);
  };
  const modal = useSimpleModal({
    title: 'Request Statememt',
    children: requestStatement.renderForm(),
    buttons: [
      {label: 'Print', onClick: onPrint},
    ],
  });
  React.useEffect(() => {
    if(modal.isOpen === false) {
      onSearch();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (dataInfo, billToData, invoiceRequestStatementPrintModal) => {
      setDataInfo(dataInfo);
      setBillToData(billToData);
      setStatementPrint(invoiceRequestStatementPrintModal);
      modal.open();
    },
  };
}
