/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import type {FormField} from '../../components/Form';
import type {INVOICE_TYPE} from '../field-defs/account';
import {INVOICE} from "../field-defs/account";

const FD = {
    // [name, serverName, label, type, options, required]
    BILL_NO: ['blNo', 'bl_no', 'B/L No.', undefined, undefined, false],
    FILING_NO: ['filingNo', 'filingNo', 'Filing No.', undefined, undefined, false],
    INVOICE_NO: ['invNo', 'invNo', 'Invoice No.', undefined, undefined, false],
    OTHER_NO: ['otherNo', 'otherNo', 'Other No.', undefined, undefined, false],
};

class AccountTopSearchFormManager extends FormManager {
    invoiceType: INVOICE_TYPE;
    mode;
    onEnter: (name: string, value: string) => void;
    constructor(type: INVOICE_TYPE = INVOICE.TYPE_AR) {
        super({
            prefix: `account-top-search-form`,
            fields: [],
            formProps: {
                horizontal: true,
                doNotUseButtons: true,
                doNotUseCard: true,
            },
        });
        this.invoiceType = type;
        const fields = this.getFields();
        this.fields = fields.map(i => ({...i, name: `${this.prefix}-${i.name}`}));
    }
    onRender = () => {
        const {getField: _f} = this;
        _f(FD.BILL_NO[0]).disabled = this.mode === 'edit';
        _f(FD.FILING_NO[0]).disabled = this.mode === 'edit';
        switch (this.invoiceType) {
            case INVOICE.TYPE_AR: return this.renderSearchARForm();
            case INVOICE.TYPE_AP: return this.renderSearchAPForm();
            case INVOICE.TYPE_DC: return this.renderSearchDCForm();
            case INVOICE.TYPE_CM: return this.renderSearchDefaultForm();
            case INVOICE.TYPE_VD: return this.renderSearchDefaultForm();
            case INVOICE.TYPE_DC_NOTE: return this.renderSearchDefaultForm();
            default:
                throw new Error(`Invalid type (${this.invoiceType})`);
        }
    };
    onValidate = (values) => {};
    getFields() {
        const fields: FormField[] = [];
        for (const key in FD) {
            if (FD.hasOwnProperty(key)) {
                const [name, serverName, label, type, options, required] = FD[key];
                fields.push({name, serverName, label, type, options, required, smallMargin: true, disabled: false});
            }
        }
        return fields;
    }
    renderSearchARForm() {
        const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
        //const [formik, _, fields, errors] = this.renderParams;
        _f(FD.BILL_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        _f(FD.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        _f(FD.INVOICE_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        return (
            <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FD.BILL_NO[0])}</div>
                <div className={'flex-1'}>{_r(FD.FILING_NO[0])}</div>
                <div className={'flex-2'} />
            </div>
        );
    }
    renderSearchAPForm() {
        const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
        //const [formik, _, fields, errors] = this.renderParams;
        _f(FD.BILL_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        _f(FD.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        _f(FD.OTHER_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        return (
          <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FD.BILL_NO[0])}</div>
              <div className={'flex-1'}>{_r(FD.FILING_NO[0])}</div>
              <div className={'flex-2'} />
          </div>
        );
    }
    renderSearchDCForm() {
        const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
        //const [formik, _, fields, errors] = this.renderParams;
        _f(FD.BILL_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        _f(FD.FILING_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        _f(FD.INVOICE_NO[0]).onEnter = (name, value) => this.onEnter(name.replace(`${this.prefix}-`, ''), value);
        return (
          <div className={'flex w-full'}>
              <div className={'flex-1'}>{_r(FD.BILL_NO[0])}</div>
              <div className={'flex-1'}>{_r(FD.FILING_NO[0])}</div>
              <div className={'flex-2'} />
          </div>
        );
    }
    renderSearchDefaultForm() {
        return (
          <div className={'flex w-full'}>
              <div className={'flex-4'} />
          </div>
        );
    }
}

export default AccountTopSearchFormManager;
