/**
 * @flow
 */
import React from "react";
import FormManager from "../../lib/FormManager";
import {api, apiAccount, util} from "../../services/service";
import {currencyOptions, oceanAirOptions} from "../helper";
import {AccountTypeSearchInput, TradePartnerSearchInput} from "../../components/SearchInput";
import {INVOICE} from "../field-defs/account";

/*
 BRANCH -> select box "branch..."
 CARGO -> select box "MOVING, COURIER, ECOMMERCE, FORWARDING"
 TYPE -> invoice type: select box "AR, AP, DC"
 INVOICE NO.
 B/L NO.
 ACCOUNT -> search account type
 BILL TO -> search trade partner
 UNPAID -> check box "UNPAID"
 OVERDUE -> check box "OVERDUE"
 */
export const SK_BRANCH = 'branchId';
export const SK_CARRIER_TYPE = 'carrierType';
export const SK_CARGO = 'cargoType';
export const SK_INV_TYPE = 'invType';
export const SK_CURRENCY = 'currency';
export const SK_BANK_INFO = 'bankInfo';
export const SK_INV_NO = 'invNo';
export const SK_FILING_NO = 'filingNo';
export const SK_BL_NO = 'blNo';
export const SK_AMOUNT = 'amount';
export const SK_ACCOUNT_TYPE = 'atype';
export const SK_ACCOUNT_TYPE_ID = 'atypeId';
export const SK_BILL_TO = 'billTo';
export const SK_BILL_TO_ID = 'billToId';
export const SK_BILL_TO_BILL_ADDR = 'billToBillAddr';
export const SK_UNPAID = 'unpaid';
export const SK_OVERDUE = 'isOverdue';

const cargoTypeOptions = [
  {value: '', label: 'ALL'},
  {value: 'M', label: 'Moving'},
  {value: 'V', label: 'Vehicle'},
  {value: 'C', label: 'Courier'},
  {value: 'E', label: 'eCommerce'},
  {value: 'F', label: 'Forwarding'},
  {value: 'U', label: 'UPS'},
  {value: 'P', label: 'Canada Post'},
];

const invoiceTypeOptions = [
  {value: '', label: 'ALL'},
  { value: INVOICE.TYPE_AR, label: INVOICE.TYPE_AR_LABEL},
  { value: INVOICE.TYPE_AP, label: INVOICE.TYPE_AP_LABEL},
  { value: INVOICE.TYPE_DC, label: INVOICE.TYPE_DC_LABEL},
  { value: INVOICE.TYPE_CM, label: INVOICE.TYPE_CM_LABEL},
  { value: INVOICE.TYPE_VD, label: INVOICE.TYPE_VD_LABEL},
  { value: INVOICE.TYPE_DC_NOTE, label: INVOICE.TYPE_DC_NOTE},
];

const unpaidOptions = [
  {value: '', label: 'No'},
  {value: 'All', label: 'All'},
  {value: 'Vendor', label: 'Vendor'},
  {value: 'Dropzone', label: 'Dropzone'},
  {value: 'Customer', label: 'Customer'},
  {value: 'Overpaid', label: 'Overpaid'},
];

export default class AccountListSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  constructor() {
    super({
      prefix: 'account-list-search',
      fields: [
        {name: SK_BRANCH, serverName: SK_BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: SK_CARRIER_TYPE, serverName: SK_CARRIER_TYPE, label: 'Carrier Type', options: [{value: '', label: 'ALL'}, ...oceanAirOptions], noDefOption: true, smallMargin: true},
        {name: SK_CARGO, serverName: SK_CARGO, label: 'Service', options: cargoTypeOptions, noDefOption: true, smallMargin: true},
        {name: SK_INV_TYPE, serverName: SK_INV_TYPE, label: 'Type', options: invoiceTypeOptions, noDefOption: true, smallMargin: true},
        {name: SK_CURRENCY, serverName: SK_CURRENCY, label: 'Currency', noDefOption: true, options: [{value: '', label: 'ALL'}, ...currencyOptions], smallMargin: true},
        {name: SK_BANK_INFO, serverName: SK_BANK_INFO, label: 'Bank Info', noDefOption: true, smallMargin: true},
        {name: SK_INV_NO, serverName: SK_INV_NO, label: 'Invoice No.', smallMargin: true},
        {name: SK_FILING_NO, serverName: SK_FILING_NO, label: 'Filing No.', smallMargin: true},
        {name: SK_BL_NO, serverName: SK_BL_NO, label: 'B/L No.', smallMargin: true},
        {name: SK_AMOUNT, serverName: SK_AMOUNT, label: 'Amount', smallMargin: true},
        {name: SK_ACCOUNT_TYPE, serverName: SK_ACCOUNT_TYPE, label: 'Invoice Item', smallMargin: true},
        {name: SK_ACCOUNT_TYPE_ID, serverName: SK_ACCOUNT_TYPE_ID, label: '', smallMargin: true},
        {name: SK_BILL_TO, serverName: SK_BILL_TO, label: 'Bill To / Partner', smallMargin: true},
        {name: SK_BILL_TO_ID, serverName: SK_BILL_TO_ID, label: '', smallMargin: true},
        {name: SK_BILL_TO_BILL_ADDR, serverName: SK_BILL_TO_BILL_ADDR, label: '', smallMargin: true},
        {name: SK_UNPAID, serverName: SK_UNPAID, label: 'Unpaid', options: unpaidOptions, noDefOption: true, useSameAsInput: true, smallMargin: true},
        {name: SK_OVERDUE, serverName: SK_OVERDUE, label: 'Overdue', type: "checkbox", smallMargin: true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    const [ready, setReady] = React.useState(false);
    _f(SK_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];

    // React.useEffect(() => { if (branchId !== undefined) this.onSearch(); }, [branchId]);

    _f(SK_INV_NO).onEnter = _ => {
      this.onSearch();
      util.nav(`/admin/account/list`);
    };
    _f(SK_FILING_NO).onEnter = _ => {
      this.onSearch();
      util.nav(`/admin/account/list`);
    };
    _f(SK_BL_NO).onEnter = _ => {
      this.onSearch();
      util.nav(`/admin/account/list`);
    };
    _f(SK_AMOUNT).onEnter = _ => {
      this.onSearch();
      util.nav(`/admin/account/list`);
    };

    // account type search form
    const _at = (name) => <AccountTypeSearchInput
      vertical forSmallLabel
      idField={_n('atypeId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;

    // bill to search from
    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      vertical forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} onOK={(data) => onTPartner(name, data)}
    />;

    const onTPartner = (name, data) => {
      this.partnerData = data;
      _c(SK_BILL_TO_ID, data.id);
      _c(SK_BILL_TO, data.value);
      _c(SK_BILL_TO_BILL_ADDR, data.bill_address);
    };

    const branchId = _v(SK_BRANCH);
    const carrierType = _v(SK_CARRIER_TYPE);
    const kind = _v(SK_CARGO);
    const type = _v(SK_INV_TYPE);
    const currency = _v(SK_CURRENCY);
    const bank = _v(SK_BANK_INFO);
    const atypeId = _v(SK_ACCOUNT_TYPE_ID);
    const billToId = _v(SK_BILL_TO_ID);
    const unpaid = _v(SK_UNPAID);
    const overdue = _v(SK_OVERDUE);

    const bankInfo = apiAccount.useBankList(branchId);
    bankInfo.shift();
    _f(SK_BANK_INFO).options = [{value: '', label: 'ALL'}, ...bankInfo];

    React.useEffect(() => {
      setTimeout(() => this.onSearch(), 0);
      setTimeout(() => setReady(true), 1000);
    }, [branchId]);
    React.useEffect(() => {
      // eslint-disable-next-line max-len
      if (ready && !(carrierType === undefined && kind === undefined && type === undefined && currency === undefined && bank === undefined && atypeId === undefined && billToId === undefined && unpaid === undefined && overdue === undefined)) {
        this.onSearch();
      }
    }, [carrierType, kind, type, currency, bank, atypeId, billToId, unpaid, overdue]);
    React.useEffect(() => {
      this.setFocus(SK_INV_NO);
    }, []);

    return (
      <div style={{height: 'calc(100vh - 110px - 55px)', overflow: 'auto'}}>
        {_r(SK_BRANCH)}
        {_r(SK_CARRIER_TYPE)}
        {_r(SK_CARGO)}
        {_r(SK_INV_TYPE)}
        {_r(SK_CURRENCY)}
        {_r(SK_BANK_INFO)}
        {_r(SK_INV_NO)}
        {_r(SK_FILING_NO)}
        {_r(SK_BL_NO)}
        {_r(SK_AMOUNT)}
        {_at(SK_ACCOUNT_TYPE)}
        {_tp(SK_BILL_TO)}
        {_r(SK_UNPAID)}
        {_r(SK_OVERDUE)}
      </div>
    );
  };
}
