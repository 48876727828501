/**
 * @flow
 */
import type {FormField} from '../../components/Form';
import {util} from '../../services/service';
import {accTypeOptions, currencyOptions, tradeTypeOptions} from "../helper";

export const kindOptions = [
  {value: 'M', label: 'Moving'},
  {value: 'V', label: 'Vehicle'},
  {value: 'C', label: 'Courier'},
  {value: 'E', label: 'eCommerce'},
  {value: 'F', label: 'Forwarding'},
  {value: 'U', label: 'UPS'},
  {value: 'P', label: 'Canada Post'},
];

export const oceanAirOptionsForBLList = [
  {value: 'O', label: 'OCEAN'},
  {value: 'A', label: 'AIR'},
  {value: 'G', label: 'GROUND'},
];

export const workTypeOptions = [
  {label: 'PICK UP', value: 'PU'},
  {label: 'DELIVERY', value: 'DL'},
  {label: 'BOX DELIVERY', value: 'BD'},
];

export const pickupTypeOptions = [
  {label: 'DROP OFF', value: 'DROP OFF'},
  {label: 'REQUEST PICKUP', value: 'REQUEST PICKUP'},
];

export const dcTypeOptions = [
  { value: 'DEBIT', label: 'DEBIT'},
  { value: 'CREDIT', label: 'CREDIT'},
];

export const unitOptions = [
  { value: 'CNTR', label: 'CNTR'},
  { value: 'B/L', label: 'B/L'},
  { value: 'CBM', label: 'CBM'},
  { value: 'DAYS', label: 'DAYS'},
  { value: 'M/T', label: 'M/T'},
  { value: 'R/T', label: 'R/T'},
  { value: 'TRUCK', label: 'TRUCK'},
  { value: 'HRS', label: 'HRS'},
  { value: 'KG', label: 'KG'},
  { value: 'WAGON', label: 'WAGON'},
  { value: 'CFT', label: 'CFT'},
  { value: 'SHIP', label: 'SHIP'},
  { value: 'EA', label: 'EA'},
  { value: '-', label: '-'},
];

export const shipmodeOptions = [
  {label: 'FCL', value: 'FCL'},
  {label: 'LCL', value: 'LCL'},
];

export const priorityOptions = [
  {label: 'High', value: 'HIGH'},
  {label: 'Normal', value: 'NORMAL'},
];

export const blStatusOptions = [
  {label: 'NG', value: 'NG'},
  {label: 'In warehouse', value: 'IN WAREHOUSE'},
  {label: 'Storage', value: 'STORAGE'},
  {label: 'Containerized', value: 'CONTAINERIZED'},
  {label: 'Departed', value: 'DEPARTED'},
  {label: 'In transit', value: 'IN TRANSIT'},
  {label: 'Arrived', value: 'ARRIVED'},
  {label: 'Cleared', value: 'CLEARED'},
  {label: 'Delivered', value: 'DELIVERED'},
  {label: 'Completed', value: 'COMPLETED'},
  {label: 'Hold', value: 'HOLD'},
];

export const paidByOptions = [
  {value: 'CARD', label: 'CARD'},
  {value: 'COLLECT', label: 'COLLECT'},
  {value: 'E-TRANSFER', label: 'E-TRANSFER'},
];

export const mapleTypeOptions = [
  {value: 'MAPLE BOX', label: 'Maple Box'},
  {value: 'MAPLE BAG', label: 'Maple Bag'},
  {value: 'MAPLE BUNDLE', label: 'Maple Bundle'},
  {value: 'MAPLE MIX', label: 'Maple Mix'},
];

export const FD: {[key: string]: FormField} = {
  // common
  ID: {name: 'id', serverName: 'id', label: 'ID', smallMargin: true},
  QID: {name: 'qid', serverName: 'qid', label: 'ID', smallMargin: true},
  KIND: {name: 'kind', serverName: 'kind', label: 'Service', options: kindOptions, noDefOption: true, smallMargin: true},
  TRADE_TYPE: {name: 'tradeType', serverName: 'tradeType', label: 'Bound', options: tradeTypeOptions, noDefOption: true, smallMargin: true},
  PORT_TYPE: {name: 'portType', serverName: 'portType', label: 'Carrier Type', options: oceanAirOptionsForBLList, noDefOption: true, smallMargin: true},
  BRANCH_ID: {name: 'branchId', serverName: 'branchId', label: 'Branch', noDefOption: true, smallMargin: true},
  FILING_NO: {name: 'filing_no', serverName: 'filing_no', label: 'Filing No.', disabled: true, smallMargin: true},
  MBL_NO: {name: 'mblNo', serverName: 'mblNo', label: 'MB/L', noDefOption: true, smallMargin: true},
  HBL_NO: {name: 'hblNo', serverName: 'hblNo', label: 'HB/L', smallMargin: true},
  MAWB_NO: {name: 'mawbNo', serverName: 'mawbNo', label: 'MAWB NO.', noDefOption: true, smallMargin: true},
  HAWB_NO: {name: 'hawbNo', serverName: 'hawbNo', label: 'HAWB NO.', smallMargin: true},
  BOOKING_NO: {name: 'bookingNo', serverName: 'bookingNo', label: 'Booking No.', smallMargin: true},
  STAFF_ID: {name: 'staffId', serverName: 'staffId', smallMargin: true},
  STAFF: {name: 'staff', serverName: 'staff', label: 'Staff', smallMargin: true},
  WAREHOUSE_ARRIVAL_DATE: {name: 'warehouseArrivalDate', serverName: 'warehouseArrivalDate', label: 'Warehouse Arrival Date', type: 'date', mask: util.MASK_DATE, smallMargin: true},
  CLEARED_BY_DATE: {name: 'clearedByDate', serverName: 'clearedByDate', label: 'Cleared By', type: 'date', mask: util.MASK_DATE, smallMargin: true},
  DEPARTURE_ORIGIN: {name: 'departure_origin', serverName: 'departure_origin', label: 'Departure', doNotClearOnBlur: true, smallMargin: true},
  DESTINATION_FINAL: {name: 'destination_final', serverName: 'destination_final', label: 'Destination', doNotClearOnBlur: true, smallMargin: true},
  REMARK: {name: 'remark', serverName: 'remark', label: 'Remark', type: 'textarea', smallMargin: true},
  REMARK_P: {name: 'remarkP', serverName: 'remarkP', label: '', type: 'textarea', smallMargin: true},
  REMARK_D: {name: 'remarkD', serverName: 'remarkD', label: '', type: 'textarea', smallMargin: true},
  VIN_NO: {name: 'vinNo', serverName: 'vinNo', label: 'VIN No.', smallMargin: true},
  CAR_INFO: {name: 'carInfo', serverName: 'carInfo', label: 'Car Info.', smallMargin: true},
  BL_STATUS: {name: 'bl_status', serverName: 'bl_status', label: 'B/L Status', options: blStatusOptions, noDefOption: true, defaultValue: 'NG', smallMargin: true},
  WORK_ORDER: {name: 'workorder', serverName: 'workorder', label: '', type: 'textarea', smallMargin: true},
  WORK_ORDER_D: {name: 'workorderD', serverName: 'workorderD', label: '', type: 'textarea', smallMargin: true},
  PICKUP_TYPE: {name: 'pickupType', serverName: 'pickupType', label: 'W/O Type', options: pickupTypeOptions, noDefOption: true, defaultValue: 'DROP OFF', smallMargin: true},
  P_DATE: {name: 'pDate', serverName: 'pDate', label: 'W/O Date', type: 'date', mask: util.MASK_DATE},
  P_TIME: {name: 'pTime', serverName: 'pTime', label: 'W/O Time'},
  PAID_BY: {name: 'paidBy', serverName: 'paidBy', label: 'Paid By', options: paidByOptions, noDefOption: true},
  MAPLE_TYPE: {name: 'mapleType', serverName: 'mapleType', label: 'Maple Type', options: mapleTypeOptions, noDefOption: true},
  TOTAL_PRICE: {name: 'totalPrice', serverName: 'totalPrice', label: 'Total Price', smallMargin: true},
  DISC_RATE: {name: 'discRate', serverName: 'discRate', label: 'Disc. Rate', smallMargin: true},

  // customer
  SHIPPER_ID: {name: 'shipperId', serverName: 'shipperId', label: '', smallMargin: true},
  SHIPPER_LOCAL: {name: 'shipperLocal', serverName: 'shipperLocal', label: 'Shipper', placeholder: 'Korean name', smallMargin: true},
  SHIPPER: {name: 'shipper', serverName: 'shipper', label: ' ', placeholder: 'English name', smallMargin: true},
  SHIPPER_ADDR: {name: 'shipperAddr', serverName: 'shipperAddr', label: '', type: 'textarea', textAreaRows: 4, placeholder: 'Korean address', smallMargin: true},
  SHIPPER_ADDR_ENG: {name: 'shipperAddrEng', serverName: 'shipperAddrEng', label: '', type: 'textarea', textAreaRows: 4, placeholder: 'English address', smallMargin: true},
  SHIPPER_POST: {name: 'shipperPostalCode', serverName: 'shipperPostalCode', label: '', placeholder: 'Postal Code', smallMargin: true},
  SHIPPER_BUILDING_CODE: {name: 'buildingCode', serverName: 'buildingCode', label: '', smallMargin: true},
  SHIPPER_EMAIL: {name: 'shipperEmail', serverName: 'shipperEmail', label: ' ', placeholder: 'Email', smallMargin: true},
  SHIPPER_PHONE1: {name: 'shipperPhone', serverName: 'shipperPhone', label: ' ', placeholder: 'Phone1', smallMargin: true},
  SHIPPER_PHONE2: {name: 'shipperPhone2', serverName: 'shipperPhone2', label: ' ', placeholder: 'Phone2', smallMargin: true},
  CONSIGNEE_ID: {name: 'consigneeId', serverName: 'consigneeId', label: '', smallMargin: true},
  CONSIGNEE_LOCAL: {name: 'consigneeLocal', serverName: 'consigneeLocal', label: 'Consignee', placeholder: 'Korean name', doNotClearOnBlur: true, smallMargin: true},
  CONSIGNEE: {name: 'consignee', serverName: 'consignee', label: ' ', placeholder: 'English name', doNotClearOnBlur: true, smallMargin: true},
  CONSIGNEE_ADDR_LOCAL: {name: 'consigneeAddrLocal', serverName: 'consigneeAddrLocal', label: '', type: 'textarea', textAreaRows: 4, placeholder: 'Korean address', smallMargin: true},
  CONSIGNEE_ADDR: {name: 'consigneeAddr', serverName: 'consigneeAddr', label: '', type: 'textarea', textAreaRows: 4, placeholder: 'English address', smallMargin: true},
  SHIPPING_ADDR: {name: 'shipping_addr', serverName: 'shipping_addr', label: ' ', type: 'textarea', textAreaRows: 4, placeholder: 'Shipping address', smallMargin: true},
  CONSIGNEE_POST: {name: 'consigneePostalCode', serverName: 'consigneePostalCode', label: ' ', placeholder: 'Postal Code', smallMargin: true},
  CONSIGNEE_BUILDING_CODE: {name: 'buildingCode', serverName: 'buildingCode', label: '', smallMargin: true},
  CONSIGNEE_EMAIL: {name: 'consigneeEmail', serverName: 'consigneeEmail', label: ' ', placeholder: 'Email', smallMargin: true},
  CONSIGNEE_PHONE1: {name: 'consigneePhone1', serverName: 'consigneePhone1', label: ' ', placeholder: 'Phone1', smallMargin: true},
  CONSIGNEE_PHONE2: {name: 'consigneePhone2', serverName: 'consigneePhone2', label: ' ', placeholder: 'Phone2', smallMargin: true, },
  NOTIFY_ID: {name: 'notifyId', serverName: 'notifyId', label: '', smallMargin: true},
  NOTIFY_LOCAL: {name: 'notifyLocal', serverName: 'notifyLocal', label: 'Notify Party', placeholder: 'Korean name', smallMargin: true},
  NOTIFY: {name: 'notify', serverName: 'notify', label: ' ', placeholder: 'English name', smallMargin: true},
  NOTIFY_ADDR: {name: 'notifyAddr', serverName: 'notifyAddr', label: 'Address', type: 'textarea', textAreaRows: 4, placeholder: 'English address', smallMargin: true},
  NOTIFY_EMAIL: {name: 'notifyEmail', serverName: 'notifyEmail', label: 'Contact', placeholder: 'Email', smallMargin: true},
  NOTIFY_PHONE: {name: 'notifyPhone', serverName: 'notifyPhone', label: ' ', placeholder: 'Phone', smallMargin: true},
  SAME_AS_CONSIGNEE: {name: 'same_as_consignee', serverName: 'same_as_consignee', label: 'Same as Consignee', type: 'checkbox', smallMargin: true},
  VISA_TYPE: {name: 'visaType', serverName: 'visaType', label: 'Visa Type', smallMargin: true},
  ENT_DATE: {name: 'entDate', serverName: 'entDate', label: 'E. Date', smallMargin: true},
  SHIPPER_STAFF: {name: 'shipper_staff', serverName: 'shipper_staff', label: ' ', placeholder: 'Shipper Staff', smallMargin: true},
  CONSIGNEE_STAFF: {name: 'consignee_staff', serverName: 'consignee_staff', label: ' ', placeholder: 'Consignee Staff', smallMargin: true},
  PARTNER: {name: 'partner', serverName: 'partner', label: '', placeholder: 'English', smallMargin: true},
  PARTNER_LOCAL: {name: 'partnerLocal', serverName: 'partnerLocal', label: 'Partner / D.Zone', placeholder: 'Korean', smallMargin: true},
  PARTNER_LOCAL_ID: {name: 'partnerId', serverName: 'partnerId', smallMargin: true},
  CUSTOMS_NO: {name: 'importNo', serverName: 'importNo', label: '', placeholder: 'Import No.', smallMargin: true},

  // vessel
  CRAFT_ID: {name: 'craftId', serverName: 'craftId', label: '', smallMargin: true},
  VSL_VOY: {name: 'vslVoy', serverName: 'vslVoy', label: 'VSL/VOY', smallMargin: true},
  ETD: {name: 'etd', serverName: 'etd', label: 'ETD', type: 'date', mask: util.MASK_DATE, smallMargin: true},
  ETA: {name: 'eta', serverName: 'eta', label: 'ETA', type: 'date', mask: util.MASK_DATE, smallMargin: true},
  CONTAINER_SEQ: {name: 'containerSeq', serverName: 'containerSeq', label: 'SEQ.', smallMargin: true},
  CONTAINER_NO: {name: 'container_no', serverName: 'container_no', label: 'CNTR NO.', smallMargin: true},
  CONTAINER_TYPE: {name: 'tp_size', serverName: 'tp_size', label: 'CNTR Type', smallMargin: true},
  CARRIER_ID: {name: 'carrierId', serverName: 'carrierId', smallMargin: true},
  CARRIER: {name: 'carrier', serverName: 'carrier', label: 'Carrier', smallMargin: true},
  FLIGHT_NO: {name: 'flightNo', serverName: 'flightNo', label: 'Flight #', smallMargin: true},
  SEAL_NO: {name: 'seal_no', serverName: 'seal_no', label: 'Seal #', smallMargin: true},
  FLIGHT_DATE: {name: 'flightDate', serverName: 'flightDate', label: 'Flight Date', type: 'date', mask: util.MASK_DATE, disabled: true, smallMargin: true},
  FLIGHT_TIME: {name: 'flightTime', serverName: 'flightTime', label: '', mask: util.MASK_TIME, disabled: true, smallMargin: true},
  ARRIVAL_DATE: {name: 'arrivalDate', serverName: 'arrivalDate', label: 'Arrival Date', type: 'date', mask: util.MASK_DATE, disabled: true, smallMargin: true},
  ARRIVAL_TIME: {name: 'arrivalTime', serverName: 'arrivalTime', label: '', mask: util.MASK_TIME, disabled: true, smallMargin: true},

  // route
  POL_ID: {name: 'pol', serverName: 'pol', label: 'POL', smallMargin: true},
  POL: {name: 'polLabel', serverName: 'polLabel', label: 'POL', doNotClearOnBlur: true, smallMargin: true},
  POD_ID: {name: 'pod', serverName: 'pod', label: 'POD', smallMargin: true},
  POD: {name: 'podLabel', serverName: 'podLabel', label: 'POD', doNotClearOnBlur: true, smallMargin: true},
  DEPARTURE: {name: 'departure', serverName: 'departure', label: 'Departure', doNotClearOnBlur: true, smallMargin: true},
  DESTINATION: {name: 'destination', serverName: 'destination', label: 'Destination', doNotClearOnBlur: true, smallMargin: true},
  DEPARTURE_FLIGHT: {name: 'departureFlight', serverName: 'departureFlight', label: '', smallMargin: true},
  DESTINATION_FLIGHT: {name: 'destinationFlight', serverName: 'destinationFlight', label: '', smallMargin: true},

  // shipment
  CBM: {name: 'cbm', serverName: 'cbm', label: 'CBM', mask: util.MASK_NUMBER_DECIMAL_THREE, smallMargin: true},
  OFFER_CBM: {name: 'offerCbm', serverName: 'offerCbm', label: 'Offer CBM', mask: util.MASK_NUMBER_DECIMAL_THREE, smallMargin: true},
  TAPE: {name: 'tape', serverName: 'tape', label: 'Tape', smallMargin: true},
  INSURANCE: {name: 'insurance', serverName: 'insurance', label: 'Insurance', smallMargin: true},
  WEIGHT: {name: 'weight', serverName: 'weight', label: 'Weight', mask: util.MASK_NUMBER, smallMargin: true},
  WEIGHT_KG: {name: 'weight_kg', serverName: 'weight_kg', label: 'Weight', mask: util.MASK_NUMBER, smallMargin: true},
  GROSS_WEIGHT_LB: {name: 'grossWeightLb', serverName: 'grossWeightLb', label: '', mask: util.MASK_NUMBER, smallMargin: true},
  GROSS_WEIGHT_KG: {name: 'grossWeightKg', serverName: 'grossWeightKg', label: 'Gross Weight', mask: util.MASK_NUMBER, smallMargin: true},
  CHARGEABLE_WEIGHT: {name: 'chargeableWeight', serverName: 'chargeableWeight', label: 'Chargeable Weight', mask: util.MASK_NUMBER, smallMargin: true},
  CHARGEABLE_WEIGHT_KG: {name: 'chargeableWeightKg', serverName: 'chargeableWeightKg', label: 'Chargeable Weight', mask: util.MASK_NUMBER, smallMargin: true},
  VOLUME: {name: 'volume', serverName: 'volume', label: 'Volume', mask: util.MASK_NUMBER, smallMargin: true},
  VOLUME_WEIGHT_KG: {name: 'volumeWeightKg', serverName: 'volumeWeightKg', label: 'Vol W.', mask: util.MASK_NUMBER, smallMargin: true},
  PACKAGE: {name: 'package', serverName: 'package', label: 'PKG', mask: util.MASK_NUMBER, smallMargin: true},
  PACK_COUNT: {name: 'pack_count', serverName: 'pack_count', label: '', mask: util.MASK_NUMBER, smallMargin: true},
  COMMODITY: {name: 'commodity', serverName: 'commodity', label: 'Commodity', type: 'textarea', smallMargin: true},
  SHIPMODE: {name: 'shipmode', serverName: 'shipmode', label: 'Shipmode', options: shipmodeOptions, smallMargin: true},
  VOL_LENGTH: {name: 'volLength', serverName: 'volLength', label: '', smallMargin: true},
  VOL_WEIGHT: {name: 'volWeight', serverName: 'volWeight', label: '', smallMargin: true},
  VOL_HEIGHT: {name: 'volHeight', serverName: 'volHeight', label: '', smallMargin: true},
  DOCUMENT: {name: 'document', serverName: 'document', label: '', type: 'checkbox', smallMargin: true},
  LITHIUM: {name: 'lithium', serverName: 'lithium', label: '', type: 'checkbox', smallMargin: true},

  // work order
  WO_ID: {name: 'wo_id', serverName: 'wo_id', label: '', smallMargin: true},
  WORK_TYPE: {name: 'work_type', serverName: 'work_type', label: '', noDefOption: true, smallMargin: true},
  WORK_DATE: {name: 'work_date', serverName: 'work_date', label: '', type: 'date', mask: util.MASK_DATE, smallMargin: true},
  WORK_TIME: {name: 'work_time', serverName: 'work_time', label: '', placeholder: 'TIME', smallMargin: true},
  WORK_CBM: {name: 'cbm', serverName: 'cbm', label: '', smallMargin: true},
  WORK_PKG: {name: 'package', serverName: 'package', label: '', smallMargin: true},
  WORK_LARGE: {name: 'box_large', serverName: 'box_large', label: '', smallMargin: true},
  WORK_MEDIUM: {name: 'box_medium', serverName: 'box_medium', label: '', smallMargin: true},
  WORK_SMALL: {name: 'box_small', serverName: 'box_small', label: '', smallMargin: true},
  WORK_AIR_CAP: {name: 'air_cap', serverName: 'air_cap', label: '', smallMargin: true},
  WORK_TAPE_COLOR: {name: 'tape_color', serverName: 'tape_color', label: '', smallMargin: true},
  WORK_TAPE_QTY: {name: 'tape_qty', serverName: 'tape_qty', label: '', placeholder: 'QTY', smallMargin: true},
  WORK_REMARK: {name: 'remark', serverName: 'remark', label: '', placeholder: 'REMARK', type: 'textarea', textAreaRows: 1, style: {height: 32}, smallMargin: true},

  // AR, AP, DC
  INV_ID: {name: 'inv_id', serverName: 'inv_id', label: '', smallMargin: true},
  JOB_ID: {name: 'job_id', serverName: 'job_id', label: '', smallMargin: true},
  ATYPE_ID: {name: 'atype_id', serverName: 'atype_id', label: '', smallMargin: true},
  DESCRIPTION: {name: 'description', serverName: 'description', label: '', placeholder: 'Account Type', smallMargin: true},
  CURRENCY: {name: 'currency', serverName: 'currency', label: '', options: currencyOptions, noDefOption: true, smallMargin: true},
  PRICE: {name: 'price', serverName: 'price', label: '', placeholder: 'Price', smallMargin: true},
  PARTNER_ID: {name: 'partner_id', serverName: 'partner_id', label: '', smallMargin: true},
  PARTNER_NAME: {name: 'partner_name', serverName: 'partner_name', label: 'Bill To', doNotClearOnBlur: true, smallMargin: true},
  INV_DATE: {name: 'inv_date', serverName: 'inv_date', label: 'Inv. Date', type: 'date', mask: util.MASK_DATE, smallMargin: true},
  BANK_INFO: {name: 'bank_info', serverName: 'bank_info', label: 'Bank Info', smallMargin: true},
  MEMO: {name: 'memo', serverName: 'memo', label: 'Memo', type: 'textarea', smallMargin: true},
  DC_TYPE: {name: 'dc_type', serverName: 'dc_type', label: 'D/C', options: dcTypeOptions, noDefOption: true, smallMargin: true},
  UNIT: {name: 'unit', serverName: 'unit', label: 'Unit', options: unitOptions, smallMargin: true},
  RATE: {name: 'rate', serverName: 'rate', label: 'Rate', smallMargin: true},
  INV_NO: {name: 'inv_no', serverName: 'inv_no', label: 'Invoice #', smallMargin: true},
  PRESET: {name: 'preset', serverName: 'preset', label: 'Preset', smallMargin: true},
  RATE_CURRENCY1: {name: 'rate_currency1', serverName: 'rate_currency1', label: 'Rate', options: currencyOptions, smallMargin: true},
  RATE_CURRENCY2: {name: 'rate_currency2', serverName: 'rate_currency2', label: 'Rate', options: currencyOptions, smallMargin: true},
  RATE_VALUE1: {name: 'rate_value1', serverName: 'rate_value1', smallMargin: true},
  RATE_VALUE2: {name: 'rate_value2', serverName: 'rate_value2', smallMargin: true},
  CAD_RATE: {name: 'cad_rate', serverName: 'cad_rate', label: 'CAD Rate', smallMargin: true},
  VENDOR_INV: {name: 'vendor_inv', serverName: 'vendor_inv', label: 'Vendor Inv.#', smallMargin: true},

  // setting
  SUBJECT: {name: 'subject', serverName: 'subject', label: 'Subject', smallMargin: true},
  ACC_TYPE: {name: 'acc_type', serverName: 'acc_type', label: 'Type', options: accTypeOptions, noDefOption: true, smallMargin: true},
};

const common = {
  ID: '', QID: '', KIND: '', TRADE_TYPE: '', PORT_TYPE: '', BRANCH_ID: '', FILING_NO: '', MBL_NO: '', HBL_NO: '', MAWB_NO: '', HAWB_NO: '', BOOKING_NO: '',
  STAFF: '', STAFF_ID: '', WAREHOUSE_ARRIVAL_DATE: '', CLEARED_BY_DATE: '', DEPARTURE_ORIGIN: '', DESTINATION_FINAL: '', REMARK: '', REMARK_P: '', REMARK_D: '',
  VIN_NO: '', CAR_INFO: '', BL_STATUS: '', WORK_ORDER: '', WORK_ORDER_D: '', PICKUP_TYPE: '', P_DATE: '', P_TIME: '', PAID_BY: '', MAPLE_TYPE: '', TOTAL_PRICE: '', DISC_RATE: '',
};

const customer = {
  SHIPPER_ID: '', SHIPPER_LOCAL: '', SHIPPER: '', SHIPPER_ADDR: '', SHIPPER_ADDR_ENG: '', SHIPPER_POST: '', SHIPPER_BUILDING_CODE: '', SHIPPER_EMAIL: '', SHIPPER_PHONE1: '', SHIPPER_PHONE2: '',
  CONSIGNEE_ID: '', CONSIGNEE_LOCAL: '', CONSIGNEE: '', CONSIGNEE_ADDR_LOCAL: '', CONSIGNEE_ADDR: '', CONSIGNEE_POST: '', CONSIGNEE_BUILDING_CODE: '', CONSIGNEE_EMAIL: '', CONSIGNEE_PHONE1: '', CONSIGNEE_PHONE2: '',
  NOTIFY_ID: '', NOTIFY_LOCAL: '', NOTIFY: '', NOTIFY_ADDR: '', NOTIFY_EMAIL: '', NOTIFY_PHONE: '', SAME_AS_CONSIGNEE: '', VISA_TYPE: '', SHIPPER_STAFF: '', CONSIGNEE_STAFF: '', SHIPPING_ADDR: '',
  PARTNER: '', PARTNER_LOCAL: '', PARTNER_LOCAL_ID: '', CUSTOMS_NO: '',
};

const vessel = {
  CRAFT_ID: '', ETD: '', ETA: '', VSL_VOY: '', CONTAINER_SEQ: '', CONTAINER_NO: '', CONTAINER_TYPE: '', CARRIER_ID: '', CARRIER: '', FLIGHT_NO: '', SEAL_NO: '', FLIGHT_DATE: '', FLIGHT_TIME: '', ARRIVAL_DATE: '', ARRIVAL_TIME: '',
};

const route = {
  POL_ID: '', POL: '', POD_ID: '', POD: '', DEPARTURE: '', DESTINATION: '', DEPARTURE_FLIGHT: '', DESTINATION_FLIGHT: '',
};

const shipment = {
  CBM: '', OFFER_CBM: '', TAPE: '', INSURANCE: '', WEIGHT: '', WEIGHT_KG: '', GROSS_WEIGHT_KG: '', CHARGEABLE_WEIGHT: '', GROSS_WEIGHT_LB: '', CHARGEABLE_WEIGHT_KG: '', VOLUME: '', VOLUME_WEIGHT_KG: '',
  PACKAGE: '', PACK_COUNT: '', COMMODITY: '', SHIPMODE: '', VOL_LENGTH: '', VOL_WEIGHT: '', VOL_HEIGHT: '', DOCUMENT: '', LITHIUM: '', ENT_DATE: '',
};

const workOrder = {
  WO_ID: '', WORK_TYPE: '', WORK_DATE: '', WORK_TIME: '', WORK_CBM: '', WORK_PKG: '', WORK_LARGE: '', WORK_MEDIUM: '', WORK_SMALL: '', WORK_AIR_CAP: '', WORK_TAPE_COLOR: '', WORK_TAPE_QTY: '', WORK_REMARK: '',
};

const account = {
  INV_ID: '', JOB_ID: '', ATYPE_ID: '', DESCRIPTION: '', CURRENCY: '', PRICE: '', PARTNER_ID: '', PARTNER_NAME: '', INV_DATE: '', BANK_INFO: '', MEMO: '',
  DC_TYPE: '', UNIT: '', RATE: '', INV_NO: '', PRESET: '', RATE_CURRENCY1: '', RATE_CURRENCY2: '', RATE_VALUE1: '', RATE_VALUE2: '', CAD_RATE: '', VENDOR_INV: '',
};

const setting = {
  SUBJECT: '', ACC_TYPE: '',
};

// eslint-disable-next-line max-len
export const FN = {...common, ...customer, ...vessel, ...route, ...shipment, ...workOrder, ...account, ...setting};

export const SN = {...FN};

for (const key in FD) {
  if (FD.hasOwnProperty(key)) {
    FN[key] = FD[key].name;
    SN[key] = SN[key].serverName;
  }
}

export function kindFormatter(p) {
  const kind = kindOptions.find(i => i.value === p.value);
  return kind?.label ?? '';
}

export function getBLData(isMaster: boolean, data: Object) {
  const kind = data[FN.KIND];
  const portType = data[FN.PORT_TYPE] ?? 'O';
  const tradeType = data[FN.TRADE_TYPE] ?? 'E';
  const isOcean = !portType.startsWith('A'); // AIR / OCEAN
  const blNo = isMaster ? (isOcean ? data[FN.MBL_NO] : data[FN.MAWB_NO]) : (isOcean ? data[FN.HBL_NO] : data[FN.HAWB_NO]);
  const mblNo = data[FN.MBL_NO];
  const hblNo = data[FN.HBL_NO];
  const mawbNo = data[FN.MAWB_NO];
  const hawbNo = data[FN.HAWB_NO];
  const branchId = data[FN.BRANCH_ID] ? util.toInt(data[FN.BRANCH_ID]) : undefined;
  const packCount = data[FN.PACKAGE] ? util.toInt(data[FN.PACKAGE]) : undefined;
  const weightKg = data[FN.WEIGHT_KG] ? util.toInt(data[FN.WEIGHT_KG]) : undefined;
  const totalPrice = data[FN.TOTAL_PRICE] ? util.toFloat(data[FN.TOTAL_PRICE]) : undefined;
  const discRate = data[FN.DISC_RATE] ? util.toFloat(data[FN.DISC_RATE]) : undefined;
  const document = data[FN.DOCUMENT]?.[0] === 'on' || data[FN.DOCUMENT];
  const lithium = data[FN.LITHIUM]?.[0] === 'on' || data[FN.LITHIUM];
  const consigneeId = (kind === 'C' && portType === 'A' && (data[FN.CONSIGNEE] || data[FN.CONSIGNEE_LOCAL]) && !data[FN.CONSIGNEE_ID]) ? 0 : data[FN.CONSIGNEE_ID];
  const container = {
    [FN.CONTAINER_NO]: data[FN.CONTAINER_NO],
    [FN.CONTAINER_TYPE]: data[FN.CONTAINER_TYPE],
    [FN.SEAL_NO]: data[FN.SEAL_NO],
    [FN.VOLUME]: data[FN.VOLUME],
    [FN.WEIGHT_KG]: weightKg,
    [FN.PACK_COUNT]: packCount,
    [FN.CBM]: data[FN.CBM],
  };

  return {
    id: data[FN.ID],
    qid: data[FN.QID],
    blNo: blNo ? blNo.trim() : blNo,
    filingNo: data[FN.FILING_NO],
    kind,
    HM: isMaster ? 'M' : 'H',
    carrierType: portType,
    bound: tradeType,
    blType: 'NR',
    branchId,
    bl_status: data[FN.BL_STATUS],
    jcommon: {
      [FN.BRANCH_ID]: branchId,
      [FN.KIND]: kind,
      [FN.PORT_TYPE]: portType,
      filingNo: data[FN.FILING_NO],
      [FN.MBL_NO]: mblNo ? mblNo.trim() : mblNo,
      [FN.HBL_NO]: hblNo ? hblNo.trim() : hblNo,
      [FN.MAWB_NO]: mawbNo ? mawbNo.trim() : mawbNo,
      [FN.HAWB_NO]: hawbNo ? hawbNo.trim() : hawbNo,
      [FN.BOOKING_NO]: data[FN.BOOKING_NO],
      [FN.WAREHOUSE_ARRIVAL_DATE]: data[FN.WAREHOUSE_ARRIVAL_DATE],
      [FN.CLEARED_BY_DATE]: data[FN.CLEARED_BY_DATE],
      [FN.DEPARTURE_ORIGIN]: data[FN.DEPARTURE_ORIGIN],
      [FN.DESTINATION_FINAL]: data[FN.DESTINATION_FINAL],
      [FN.REMARK]: data[FN.REMARK],
      [FN.REMARK_P]: data[FN.REMARK_P],
      [FN.REMARK_D]: data[FN.REMARK_D],
      [FN.STAFF]: data[FN.STAFF],
      [FN.VIN_NO]: data[FN.VIN_NO],
      [FN.CAR_INFO]: data[FN.CAR_INFO],
      [FN.TRADE_TYPE]: tradeType,
      [FN.PICKUP_TYPE]: data[FN.PICKUP_TYPE],
      [FN.P_DATE]: data[FN.P_DATE],
      [FN.P_TIME]: data[FN.P_TIME],
      [FN.PAID_BY]: data[FN.PAID_BY],
      [FN.MAPLE_TYPE]: data[FN.MAPLE_TYPE],
      [FN.WORK_ORDER]: data[FN.WORK_ORDER],
      [FN.WORK_ORDER_D]: data[FN.WORK_ORDER_D],
      totalPrice,
      discRate,
    },
    jcustomer: {
      [FN.SHIPPER_ID]: data[FN.SHIPPER_ID],
      [FN.SHIPPER_LOCAL]: data[FN.SHIPPER_LOCAL],
      [FN.SHIPPER]: data[FN.SHIPPER],
      [FN.SHIPPER_ADDR]: data[FN.SHIPPER_ADDR],
      [FN.SHIPPER_ADDR_ENG]: data[FN.SHIPPER_ADDR_ENG],
      [FN.SHIPPER_POST]: data[FN.SHIPPER_POST],
      [FN.SHIPPER_EMAIL]: data[FN.SHIPPER_EMAIL],
      [FN.SHIPPER_PHONE1]: data[FN.SHIPPER_PHONE1],
      [FN.SHIPPER_PHONE2]: data[FN.SHIPPER_PHONE2],
      [FN.CONSIGNEE_ID]: consigneeId,
      [FN.CONSIGNEE_LOCAL]: data[FN.CONSIGNEE_LOCAL],
      [FN.CONSIGNEE]: data[FN.CONSIGNEE],
      [FN.CONSIGNEE_ADDR_LOCAL]: data[FN.CONSIGNEE_ADDR_LOCAL],
      [FN.CONSIGNEE_ADDR]: data[FN.CONSIGNEE_ADDR],
      [FN.SHIPPING_ADDR]: data[FN.SHIPPING_ADDR],
      [FN.CONSIGNEE_POST]: data[FN.CONSIGNEE_POST],
      [FN.CONSIGNEE_EMAIL]: data[FN.CONSIGNEE_EMAIL],
      [FN.CONSIGNEE_PHONE1]: data[FN.CONSIGNEE_PHONE1],
      [FN.CONSIGNEE_PHONE2]: data[FN.CONSIGNEE_PHONE2],
      [FN.NOTIFY_ID]: data[FN.NOTIFY_ID],
      [FN.NOTIFY_LOCAL]: data[FN.NOTIFY_LOCAL],
      [FN.NOTIFY]: data[FN.NOTIFY],
      [FN.NOTIFY_ADDR]: data[FN.NOTIFY_ADDR],
      [FN.NOTIFY_EMAIL]: data[FN.NOTIFY_EMAIL],
      [FN.NOTIFY_PHONE]: data[FN.NOTIFY_PHONE],
      [FN.VISA_TYPE]: data[FN.VISA_TYPE],
      [FN.MEMO]: data[FN.MEMO],
      [FN.PARTNER]: data[FN.PARTNER],
      [FN.PARTNER_LOCAL]: data[FN.PARTNER_LOCAL],
      [FN.PARTNER_LOCAL_ID]: data[FN.PARTNER_LOCAL_ID] ?? 0,
      [FN.CUSTOMS_NO]: data[FN.CUSTOMS_NO],
    },
    jvessel: {
      [FN.CRAFT_ID]: data[FN.CRAFT_ID],
      [FN.ETD]: data[FN.ETD],
      [FN.ETA]: data[FN.ETA],
      [FN.VSL_VOY]: data[FN.VSL_VOY],
      [FN.CONTAINER_SEQ]: data[FN.CONTAINER_SEQ],
      [FN.FLIGHT_NO]: data[FN.FLIGHT_NO],
      [FN.CARRIER_ID]: data[FN.CARRIER_ID],
      [FN.CARRIER]: data[FN.CARRIER],
      [FN.FLIGHT_DATE]: data[FN.FLIGHT_DATE],
      [FN.FLIGHT_TIME]: data[FN.FLIGHT_TIME],
      [FN.ARRIVAL_DATE]: data[FN.ARRIVAL_DATE],
      [FN.ARRIVAL_TIME]: data[FN.ARRIVAL_TIME],
      container,
    },
    jroute: {
      [FN.POL_ID]: data[FN.POL_ID],
      [FN.POL]: data[FN.POL],
      [FN.POD_ID]: data[FN.POD_ID],
      [FN.POD]: data[FN.POD],
      [FN.DEPARTURE]: data[FN.DEPARTURE],
      [FN.DESTINATION]: data[FN.DESTINATION],
      [FN.DEPARTURE_FLIGHT]: data[FN.DEPARTURE_FLIGHT],
      [FN.DESTINATION_FLIGHT]: data[FN.DESTINATION_FLIGHT],
    },
    jshipment: {
      [FN.CBM]: data[FN.CBM],
      [FN.OFFER_CBM]: data[FN.OFFER_CBM],
      [FN.TAPE]: data[FN.TAPE],
      [FN.INSURANCE]: data[FN.INSURANCE],
      [FN.WEIGHT]: data[FN.WEIGHT],
      [FN.GROSS_WEIGHT_LB]: data[FN.GROSS_WEIGHT_LB],
      [FN.GROSS_WEIGHT_KG]: data[FN.GROSS_WEIGHT_KG],
      [FN.CHARGEABLE_WEIGHT]: data[FN.CHARGEABLE_WEIGHT],
      [FN.CHARGEABLE_WEIGHT_KG]: data[FN.CHARGEABLE_WEIGHT_KG],
      [FN.VOLUME]: data[FN.VOLUME],
      [FN.VOLUME_WEIGHT_KG]: data[FN.VOLUME_WEIGHT_KG],
      [FN.PACKAGE]: packCount,
      [FN.COMMODITY]: data[FN.COMMODITY],
      [FN.SHIPMODE]: data[FN.SHIPMODE],
      [FN.VOL_LENGTH]: data[FN.VOL_LENGTH],
      [FN.VOL_WEIGHT]: data[FN.VOL_WEIGHT],
      [FN.VOL_HEIGHT]: data[FN.VOL_HEIGHT],
      [FN.ENT_DATE]: data[FN.ENT_DATE],
      document,
      lithium,
    }
  };
}
