/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from "../media/coship_logo.png";

class InvoiceAPPrint extends React.Component {
  totalAmount = 0;
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountData} = printData;
    return (
      <div style={{color: 'black'}}>
        {this.renderHeader(accountData, user)}
        <hr />
        <table className={'print-table bold-border'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td rowSpan={2} className={'invoice_label center'} width={'20%'} height={'100px'}>CONFIRM</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>OFFICER</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>MANAGER</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>GENERAL MANAGER</td>
            <td className={'invoice_label center'} width={'20%'} height={'30px'}>PRESIDENT</td>
          </tr>
          <tr>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
          </tr>
          <tr>
            <td rowSpan={2} className={'invoice_label center'} width={'20%'} height={'120px'}>RECIPIENT</td>
            <td colSpan={4} className={'invoice_label center'} width={'80%'} height={'50px'}>I properly accept the ($<span className={'left-space'}>)</span></td>
          </tr>
          <tr>
            <td className={'invoice_label center'} width={'20%'} height={'70px'}>NAME</td>
            <td className={'invoice_label'} width={'20%'} height={'70px'}>{'\u00a0'}</td>
            <td colSpan={2} className={'invoice_label bottom_right'} width={'40%'} height={'70px'}>Signature</td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%', marginTop: '50px'}}>
          <tbody>
          <tr style={{height: 25}}>
            <td className={'no-border-lr left invoice_label fb-15'} width={'50%'}>ACCOUNT TYPE</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'15%'}>UNIT</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>RATE</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>VOLUME</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'15%'}>AMOUNT</td>
          </tr>
          <tr><td colSpan={5} className={'no-border-all'} height={8}>{''}</td></tr>
          {this.renderInvoiceItems(accountData.jitems)}
          {this.renderEmptyItems(accountData.jitems)}
          </tbody>
        </table>
        <hr />
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
          {this.renderInvoiceItemsTotal('TOTAL AMOUNT', accountData.currency, util.formatCurrency(this.totalAmount))}
          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
          {this.renderInvoiceExtraInfo('MEMO', accountData.jextra.memo)}
          </tbody>
        </table>
        <hr className={'line'} />
      </div>
    );
  }
  renderHeader(accountData, user) {
    const pol = (accountData.polLabel || accountData.polEtd) ? `${accountData.polLabel} / ${accountData.polEtd}` : '';
    const pod = (accountData.podLabel || accountData.podEta) ? `${accountData.podLabel} / ${accountData.podEta}` : '';
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all right invoice_label'}><h1>ACCOUNT REQUEST</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_right'}>
                  <h3 className={'invoice_label'}>INVOICE No. : {accountData.invoiceNo}</h3>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '15px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>BILL TO :</td>
                <td className={'no-border-all'}>{accountData.jextra.partner}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>TEAM :</td>
                <td className={'no-border-all'}>{getTeam(user.team_main)}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>NAME :</td>
                <td className={'no-border-all'}>{user.uname}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>MASTER B/L NO. :</td>
                <td className={'no-border-all'}>{accountData.mblNo}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>HOUSE B/L NO. :</td>
                <td className={'no-border-all'}>{accountData.hblNo}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>POL / ETD :</td>
                <td className={'no-border-all'}>{pol}</td>
              </tr>
              <tr>
                <td className={'no-border-all w-152 invoice_label'}>POD / ETA :</td>
                <td className={'no-border-all'}>{pod}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>DATE</td>
                <td className={'left'}>{util.getCurrentDate()}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>INV. DATE</td>
                <td className={'left'}>{accountData.invoiceDate}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>VENDOR INV. NO.</td>
                <td className={'left'}>{accountData.jextra.vendorInv}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>PAID BY</td>
                <td className={'left'}>{accountData.jextra.paidBy}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceItems(items) {
    this.totalAmount = 0;
    let price = 0;
    return items?.map((item, index) => {
      price += item['price'];
      this.totalAmount = price;
      return (
        <tr key={index}>
          <td className={'no-border-all left'} width={'50%'}>{item['accountType']}</td>
          <td className={'no-border-all right'} width={'15%'}>{item['unit']}</td>
          <td className={'no-border-all right'} width={'10%'}>{util.formatCurrency(item['rate'])}</td>
          <td className={'no-border-all right'} width={'10%'}>{item['volume']}</td>
          <td className={'no-border-all right'} width={'15%'}>{util.formatCurrency(item['price'])}</td>
        </tr>
      );
    });
  }
  renderEmptyItems(items) {
    const empty = [];
    let length = 30 - items?.length;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={5} className={'no-border-all'}>{'\u00a0'}</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceItemsTotal(label, value1, value2) {
    return (
      <tr>
        <td className={'no-border-all right invoice_label'} width={'70%'}>{label}</td>
        <td className={'no-border-all right invoice_label'} width={'15%'}>{value1}</td>
        <td className={'no-border-all right invoice_label'} width={'15%'}>{value2}</td>
      </tr>
    );
  }
  renderInvoiceExtraInfo(label, value) {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-line', minHeight: '150px'}}>{value}</div></td>
      </tr>
    );
  }
}

export function useInvoiceAPPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountData = React.useRef();
  const printRef = React.useRef();
  const invoiceNoRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: invoiceNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceAPPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountData.current,
        // totalAmount: accountData.current.jitems.reduce((acc, cur) => acc + cur.price, 0),
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      accountData.current = data;
      invoiceNoRef.current = data.invoiceNo;
      modal.open();
    },
    print,
  };
}

const teamMainOptions = [
  {value: 'A', label: 'ALL'},
  {value: 'M', label: 'MOVING'},
  {value: 'C', label: 'COURIER'},
  {value: 'F', label: 'FORWARDING'},
];

const getTeam = (data) => {
  let teamMain = '';
  teamMainOptions.forEach(team => {
    if(team.value === data) teamMain = team.label;
  });
  return teamMain;
}

export default InvoiceAPPrint;
