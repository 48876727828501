/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {tpSizeOptions} from '../field-defs/bl';
import {util} from '../../services/service';

export const SK_TP_SIZE = 'tpSize';
// export const SK_CONTAINER_NO = 'containerNo';
// export const SK_FILING_NO = 'filingNo';
// export const SK_BL_NO = 'blNo';
// export const SK_SEAL_NO = 'sealNo';
export const SK_SEARCH_KEY = 'searchName';
export const SK_SEARCH_VAL = 'searchVal';
export const SK_VSL_VOY = 'vslVoy';
export const SK_POL = 'pol';
export const SK_POD = 'pod';
export const SK_ETD = 'etd';
export const SK_ETA = 'eta';

const searchKeyOptions = [
  {value: 'CNO', label: 'Container No.'},
  {value: 'FNO', label: 'Filing No.'},
  {value: 'BNO', label: 'B/L No.'},
  {value: 'SNO', label: 'Seal No.'},
];

class CraftContainerSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor() {
    super({
      prefix: 'container-list-search',
      fields: [
        {name: SK_TP_SIZE, label: 'TP/SZ', options: tpSizeOptions, noDefOption: true, smallMargin: true, smallLabel: true},
        {name: SK_SEARCH_KEY, label: 'Search by', options: searchKeyOptions, noDefOption: true, smallMargin: true, smallLabel: true},
        {name: SK_SEARCH_VAL, label: '', smallMargin: true, smallLabel: true},
        {name: SK_VSL_VOY, label: 'VSL/VOY', smallMargin: true, smallLabel: true},
        {name: SK_POL, label: 'POL', smallMargin: true, smallLabel: true},
        {name: SK_POD, label: 'POD', smallMargin: true, smallLabel: true},
        {name: SK_ETD, label: 'ETD', smallMargin: true, smallLabel: true, type: 'date', mask: util.MASK_DATE},
        {name: SK_ETA, label: 'ETA', smallLabel: true, type: 'date', mask: util.MASK_DATE},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const tpSize = _v(SK_TP_SIZE);
    const searchKey = _v(SK_SEARCH_KEY);
    React.useEffect(() => {
      if(!searchKey) {
        _c(SK_SEARCH_KEY, 'CNO');
      } else {
        _c(SK_SEARCH_KEY, searchKey);
      }
    }, [searchKey]);
    React.useEffect(() => { tpSize !== undefined && this.onSearch(); }, [tpSize]);
    _f(SK_SEARCH_VAL).onEnter = () => { this.onSearch(); }
    _f(SK_VSL_VOY).onEnter = () => { this.onSearch(); }
    _f(SK_POL).onEnter = () => { this.onSearch(); }
    _f(SK_POD).onEnter = () => { this.onSearch(); }
    return (
      <>
        {_r(SK_TP_SIZE)}
        {_r(SK_SEARCH_KEY)}
        {_r(SK_SEARCH_VAL)}
        {_r(SK_VSL_VOY)}
        {_r(SK_POL)}
        {_r(SK_POD)}
        {_r(SK_ETD)}
        {_r(SK_ETA)}
      </>
    );
  };
}

export default CraftContainerSearchFormManager;
