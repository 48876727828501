/**
 * @flow
 */
import {apiDashboard, util} from "../services/service";
import {useDataGridView} from "../components/DataGridView";

export default function useRequestGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiDashboard.requestList(gridInfo);
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
        // TODO
      }
    },
    name: `ListQuote`,
    label: 'List All Quotes',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Dashboard'},
    menuLabel: {label: 'Request'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 80,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    columns:
      [
        {field: 'id', hide: true},
        {field: 'customer_name', headerName: 'Name', width: 100},
        {field: 'service_type', headerName: 'Type', width: 100},
        {field: 'email', headerName: 'Email', width: 100},
        {field: 'phone', headerName: 'Phone', width: 100},
        {field: 'from_country', headerName: 'From', flex: 1},
        {field: 'to_country', headerName: 'To', flex: 1},
        {field: 'cdate', headerName: 'Request', flex: 1, valueFormatter: util.dateTimeFormatter},
        {field: 'udate', headerName: 'Response', flex: 1, valueFormatter: util.dateTimeFormatter},
        {field: 'response_status', headerName: 'Status', width: 50},
        {field: 'update_staff', hide: true},
        {field: 'address', hide: true},
        {field: 'bigo', hide: true},
        {field: 'remark', hide: true},
      ],
  });
}
