/**
 * @flow
 */
import React from 'react';
import FormManager from '../lib/FormManager';
import {api} from '../services/service';
import {CustomerSearchInput, PartnerSearchInput} from '../components/SearchInput';
import {courierOptions, eCommerceOptions} from './helper';
import {CARGO_COURIER, CARGO_ECOMMERCE} from '../modal-tabs/types';

export const SK_HBL = 'hblCode';
export const SK_COURIER_TYPE = 'subType';
export const SK_ECOMMERCE_TYPE = 'subType';
export const SK_CUSTOMER = 'customer';
export const SK_CUSTOMER_ID = 'customerId';
export const SK_DEPARTURE = 'departureId';
export const SK_PARTNER = 'partner';
export const SK_PARTNER_ID = 'partnerId';

class ListAllSearchFormManager extends FormManager {
  onSearch: () => void;
  cargoType;
  constructor(cargoType) {
    let subTypeLabel = '';
    let subTypeOptions = [];
    if (cargoType === CARGO_COURIER) {
      subTypeLabel = 'Courier Type';
      subTypeOptions = courierOptions;
    } else if (cargoType === CARGO_ECOMMERCE) {
      subTypeLabel = 'eCommerce Type';
      subTypeOptions = eCommerceOptions;
    }
    let partnerLabel = cargoType === CARGO_COURIER ? 'Dropzone' : 'Partner';
    super({
      prefix: 'list-all-search',
      fields: [
        {name: SK_HBL, label: 'HBL', smallMargin: true, smallLabel: true},
        {name: SK_COURIER_TYPE, label: subTypeLabel, options: [{value: '', label: 'ALL'}, ...subTypeOptions], noDefOption: true, smallMargin: true, smallLabel: true},
        {name: SK_CUSTOMER, label: 'Customer', smallMargin: true, smallLabel: true},
        {name: SK_CUSTOMER_ID, label: 'Customer', smallMargin: true, smallLabel: true},
        {name: SK_DEPARTURE, label: 'Departure', options: [], smallLabel: true, noDefOption: true, smallMargin: true},
        {name: SK_PARTNER, label: partnerLabel, smallLabel: true},
        {name: SK_PARTNER_ID, label: partnerLabel, smallLabel: true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
    this.cargoType = cargoType;
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    const dropzoneOptions = api.useDropzoneOptions();
    _f(SK_DEPARTURE).options = [{value: '', label: 'ALL'}, ...areaOptions];
    if (this.cargoType === CARGO_COURIER) {
      _f(SK_PARTNER_ID).options = [{value: '', label: 'ALL'}, ...dropzoneOptions];
      _f(SK_PARTNER_ID).noDefOption = true;
    } else {
      _f(SK_PARTNER_ID).options = undefined;
      _f(SK_PARTNER_ID).noDefOption = undefined;
    }
    _f(SK_HBL).onEnter = _ => this.onSearch(); // 일반 텍스트 필드는 엔터를 누르면 바로 검색을 수행
    _f(SK_CUSTOMER).onChange = () => _c(SK_CUSTOMER_ID, ''); // 아이디를 검색하는 경우 디스플레이 필드가 변경되면 아이디를 삭제함
    _f(SK_PARTNER).onChange = () => _c(SK_PARTNER_ID, ''); // 아이디를 검색하는 경우 디스플레이 필드가 변경되면 아이디를 삭제함
    const valuesToWatch = this.getValues([ // 다음 필드는 변경이 발생하면 검색을 수행 (이펙트 훅을 이용해야 업데이트된 값을 얻어 올수 있음)
      SK_CUSTOMER_ID, SK_DEPARTURE, SK_PARTNER_ID, SK_COURIER_TYPE,
    ]);
    React.useEffect(() => {valuesToWatch[SK_CUSTOMER_ID] !== undefined && this.onSearch()}, [valuesToWatch[SK_CUSTOMER_ID]]);
    React.useEffect(() => {valuesToWatch[SK_DEPARTURE] !== undefined && this.onSearch()}, [valuesToWatch[SK_DEPARTURE]]);
    React.useEffect(() => {valuesToWatch[SK_PARTNER_ID] !== undefined && this.onSearch()}, [valuesToWatch[SK_PARTNER_ID]]);
    React.useEffect(() => {valuesToWatch[SK_COURIER_TYPE] !== undefined && this.onSearch()}, [valuesToWatch[SK_COURIER_TYPE]]);
    // React.useEffect(() => {
    //   this.setFocus(SK_HBL);
    // }, []);
    const hasSubType = this.cargoType === CARGO_COURIER || this.cargoType === CARGO_ECOMMERCE;
    return (
      <>
        {_r(SK_HBL)}
        {hasSubType && _r(SK_COURIER_TYPE)}
        <CustomerSearchInput
          displayField={_n(SK_CUSTOMER)}
          idField={_n(SK_CUSTOMER_ID)}
          vertical
          forSmallLabel // 작은 크기의 마진을 사용하는 세로 폼의 경우 검색 아이콘의 위치를 적당하게 맞추어줌 (forSmallMargin 이라는 이름이 맞음!)
          {...{formik, fields, errors}}
        />
        {_r(SK_DEPARTURE)}
        {this.cargoType === CARGO_COURIER && _r(SK_PARTNER_ID)}
        {this.cargoType !== CARGO_COURIER && (
          <PartnerSearchInput
            displayField={_n(SK_PARTNER)}
            idField={_n(SK_PARTNER_ID)}
            vertical
            //forSmallLabel // 작은 크기의 마진을 사용하는 세로 폼의 경우 검색 아이콘의 위치를 적당하게 맞추어줌 (forSmallMargin 이라는 이름이 맞음!)
            {...{formik, fields, errors}}
          />
        )}
      </>
    );
  };
}

export default ListAllSearchFormManager;
