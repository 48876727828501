/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {TradePartnerSearchInput} from '../../components/SearchInput';

class MarkFormManager extends FormManager {
  renderTP = (name, vertical) => {
    const {_n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return (
      <TradePartnerSearchInput
        idField={_n(`${name}Id`)}
        displayField={_n(name)}
        formik={formik} fields={fields} errors={errors}
        vertical={vertical}
      />
    );
  };
  renderTPA = (name) => {
    const {setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const onTPartner = (name, {id, value, Address}) => {
      _c(`${name}`, value);
      _c(`${name}Id`, id);
      _c(`${name}Addr`, Address);
    };
    return (
      <TradePartnerSearchInput
        idField={_n(`${name}Id`)}
        displayField={_n(name)}
        formik={formik} fields={fields} errors={errors}
        vertical onOK={(data) => onTPartner(name, data)}
      />
    );
  };
  _l = (label) => <div className={'ml-2 mb-1'}>{label}</div>;
}

export default MarkFormManager;
