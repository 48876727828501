/**
 * @flow
 */
import React from 'react';
import {useParams} from 'react-router-dom';
import FormManager from '../../lib/FormManager';
import {kindOptions} from '../field-defs/bl';
import {houseMasterOptions, oceanAirOptionsForBLList, tradeTypeOptions} from '../helper';
import {useUserBranch} from '../../redux/reducers/userReducer';
import {TradePartnerSearchInput} from "../../components/SearchInput";
import {api, util} from '../../services/service';

const searchKeyOptions = [
  {value: 'ENG', label: 'Eng. Name'},
  {value: 'FINO', label: 'Filing No.'},
  {value: 'BLNO', label: 'B/L No.'},
  {value: 'CNO', label: 'Container No.'},
  {value: 'CBN', label: 'Carrier Bkg. No.'},
  {value: 'CCN', label: 'CCN No.'},
  {value: 'BKN', label: 'Booking No.'},
  {value: 'EMAIL', label: 'Email'},
  {value: 'PHONE', label: 'Phone'},
  {value: 'WTYPE', label: 'W/O Type'},
  {value: 'PRICE', label: 'Total Price'},
];

class BLSearchFormManager extends FormManager {
  onSearch;
  cachedValues; // 검색 옵션 값들을 저장
  constructor() {
    super({
      prefix: 'bl-list-search',
      fields: [
        // eslint-disable-next-line max-len
        {name: 'kind', serverName: 'kind', label: 'Service', smallLabel: true, smallMargin: true, options: addAllOptions(kindOptions), noDefOption: true},
        {name: 'spaceId', serverName: 'spaceId', label: 'Inventory', smallLabel: true, smallMargin: true, noDefOption: true},
        // eslint-disable-next-line max-len
        {name: 'carrierType', serverName: 'carrierType', label: 'Type', smallLabel: true, smallMargin: true, options: addAllOptions(oceanAirOptionsForBLList), noDefOption: true},
        // eslint-disable-next-line max-len
        {name: 'bound', serverName: 'bound', label: 'Bound', smallLabel: true, smallMargin: true, options: addAllOptions(tradeTypeOptions), noDefOption: true},
        // eslint-disable-next-line max-len
        {name: 'hm', serverName: 'hm', label: 'House / Master', smallLabel: true, smallMargin: true, options: addAllOptions(houseMasterOptions), noDefOption: true},
        {name: 'branchId', serverName: 'branchId', label: 'Branch', smallLabel: true, smallMargin: true, noDefOption: true},
        {name: 'shipper', serverName: 'shipper', label: 'Shipper / Consignee', smallLabel: true},
        {name: 'trackingNo', serverName: 'trackingNo', label: 'Tracking No.', smallLabel: true, smallMargin: true},
        {name: 'onlyWaitList', serverName: 'onlyWaitList', label: 'Shipping Wait', type: "checkbox", smallLabel: true, smallMargin: true},
        {name: 'notLinkedAccount', serverName: 'notLinkedAccount', label: 'Not issued A/R', type: "checkbox", smallLabel: true, smallMargin: true},
        {name: 'flag', serverName: 'flag', label: 'Flag', type: "checkbox", smallLabel: true, smallMargin: true},
        {name: 'searchName', label: 'Search by', options: searchKeyOptions, noDefOption: true, smallMargin: true, smallLabel: true},
        {name: 'searchVal', label: '', smallMargin: true, smallLabel: true},
        {name: 'partner', serverName: 'partner', label: 'Partner'},
        {name: 'partnerId', serverName: 'partnerId', label: ''},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const params = useParams();
    const shipperField = _f('shipper');
    const branchId = _v('branchId');
    const trackingField = _f('trackingNo');
    const {readyToSearch} = useUserBranch(this, 'branchId', () => {
      const {type, bound, hm, flag, partner_id, partner_name} = util.getQS();
      if (type !== undefined) _c('carrierType', type);
      if (bound !== undefined) _c('bound', bound);
      if (hm !== undefined) _c('hm', hm);
      if (flag === 'yes') _c('flag', true);
      if (partner_id !== undefined) _c('partnerId', partner_id);
      if (partner_name !== undefined) _c('partner', partner_name);
      setTimeout(() => this.onSearch(), 0);
    }, true);
    const kind = _v('kind');
    const carrierType = _v('carrierType');
    const bound = _v('bound');
    const hm = _v('hm');
    const partnerId = _v('partnerId');
    const onlyWaitList = _v('onlyWaitList');
    const notLinkedAccount = _v('notLinkedAccount');
    const flag = _v('flag');
    const inventory = _v('spaceId');
    const inventoryField = _f('spaceId');
    const inventoryOptions = api.useInventoryOptions(branchId, false);
    inventoryField.options = addAllOptions(inventoryOptions);
    React.useEffect(() => {
      if (readyToSearch) {
        this.onSearch();
      }
    }, [kind, carrierType, bound, hm, branchId, params.ts, partnerId, onlyWaitList, notLinkedAccount, flag, inventory]);
    shipperField.onEnter = () => { readyToSearch && this.onSearch(); };
    trackingField.onEnter = () => { readyToSearch && this.onSearch(); };
    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      vertical forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;
    const searchKey = _v('searchName');
    React.useEffect(() => {
      if(!searchKey) {
        _c('searchName', 'ENG');
      } else {
        _c('searchName', searchKey);
      }
    }, [searchKey]);
    return (
      <>
        {_r('kind')}
        {kind === 'M' && _r('spaceId')}
        {kind === 'E' && _r('trackingNo')}
        {_r('carrierType')}
        {_r('bound')}
        {_r('hm')}
        {_r('branchId')}
        {_r('searchName')}
        {_r('searchVal')}
        {_tp('partner')}
        {_r('onlyWaitList')}
        {_r('notLinkedAccount')}
        {_r('flag')}
      </>
    );
  };
  onValidate = (values) => {
    // TODO: 밸리데이션이 필요한 경우 여기서...
    return values;
  };
}

function addAllOptions(options: any[]) {
  return [{value: '', label: 'ALL'}, ...options];
}

export default BLSearchFormManager;
