import React from 'react';
import {apiAccount, util} from '../../services/service';
import {useSimpleModal} from '../../components/SimpleModal';
import {useDataGridView} from "../../components/DataGridView";
import {queryGridData} from "../../components/DataGrid";
import {INVOICE} from "../../forms/field-defs/account";

export default function useCommissionDetailModal() {
  const [mode, setMode] = React.useState();
  const [data, setData] = React.useState(); // 그리드에서 선택한 행의 데이터를 보관함
  async function onQuery(gridInfo) {
    if (modal.isOpen) {
      const {partnerId, commissionId} = data;
      const dataInfo = {...gridInfo, ...data, isAll: true};
      if (partnerId || commissionId) {
        return await apiAccount.commissionHistoryDetail(dataInfo);
      }
    }
  }

  const grid = useDataGridView({
    async onAction(action, data) {
      if(action === 'delete') {
        await apiAccount.delCommissionHistory({accountId: data.account_id, pickupType: data.pickup_type, blId: data.bl_id}).then((res) => {
          if(res) {
            queryGridData('Commission Detail', {page: 1}, onQuery).then(() => console.log('hello, grid query called!'));
          }
        });
      }
    },
    onQuery,
    onCellValueChange(e) {
      const accountId = e.data.account_id;
      const commission = e.data.commission
      apiAccount.changeCommission(accountId, util.toFloat(commission)).then((res) => {
        if(res) {
          util.showSuccess('Commission has been saved successfully.');
        }
      });
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
    },
    name: `Commission Detail`,
    label: '',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'Commission'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['delete'],
    actionWidth: 70,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    useModalDataGrid: true,
    modalDataGridHeight: 500,
    showRowSearch: false,
    columns: getColumn(),
  });

  const modal = useSimpleModal({
    title: getTitle(),
    centered: true,
    width: 1280,
    buttons: getButtons(),
    children: grid.render()
  });

  function getColumn() {
    switch (mode) {
      case INVOICE.TYPE_VD:
        return [
          {field: 'account_id', hide: true},
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
          {field: 'inv_no', headerName: 'Inv No.', width: 120},
          {field: 'bl_no', headerName: 'B/L No.', width: 120},
          {field: 'customer_name', headerName: 'Customer', width: 120},
          {field: 'consignee', headerName: 'Consignee', width: 120},
          {field: 'pickup_type', headerName: 'Type', width: 100},
          {field: 'currency', headerName: 'Currency', width: 100},
          {field: 'price', headerName: 'Price', valueFormatter: util.currencyFormatter, width: 100},
          {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 50},
          {
            field: 'commission',
            headerName: 'Commission',
            editable: isEditable,
            valueFormatter: util.currencyFormatter,
            width: 100
          },
        ];
      default:
        return [
          {field: 'account_id', hide: true},
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
          {field: 'inv_no', headerName: 'Inv No.', width: 120},
          {field: 'bl_no', headerName: 'B/L No.', width: 120, cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true)},
          {field: 'customer_name', headerName: 'Customer', flex: 1},
          {field: 'consignee', headerName: 'Consignee', flex: 1},
          {field: 'pickup_type', headerName: 'Type', width: 120},
          {field: 'currency', headerName: 'Currency', width: 100},
          {field: 'price', headerName: 'Price', valueFormatter: util.currencyFormatter, width: 100},
          {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 100},
          {
            field: 'commission',
            headerName: 'Commission',
            editable: isEditable,
            valueFormatter: util.currencyFormatter,
            width: 100
          },
          {field: 'done', headerName: 'Done', valueFormatter: zeroOrNullYesNoFormatter, width: 100},
        ];
    }
  }

  function getTitle() {
    let title = `COMMISSION DETAIL (${util.formatD(data?.fromDate)} ~ ${util.formatD(data?.toDate)})`;
    if(mode === INVOICE.TYPE_CM) {
      title = `COMMISSION DETAIL (${data.pickupType})`;
    }
    return title;
  }

  function getButtons() {
    let button = [{label: 'Settlement', color: 'primary', onClick: onSave}];
    if(mode === INVOICE.TYPE_CM) {
      button = [];
    }
    return button;
  }

  async function onSave() {
    const res = await apiAccount.setSettlement(data);
    if(res) {
      modal.close();
      util.openTab('/admin/account/list/cm?id=' + res.id);
    }
  }

  React.useEffect(() => {
    if (modal.isOpen === true) {
      const {partnerId, commissionId} = data;
      if (partnerId || commissionId) {
        queryGridData('Commission Detail', {page: 1}, onQuery).then(() => console.log('hello, grid query called!'));
      }
    }
  }, [modal.isOpen]);

  const open = (data: Object, mode: string) => {
    setData(data);
    setMode(mode);
    modal.open();
  };
  return {...modal, open};
}

function isEditable(p) {
  let result = true;
  if(p.data.done) {
    result = false;
  }
  return result;
}

function zeroOrNullYesNoFormatter(p) {
  let result = 'No';
  if(p.data.done) {
    result = 'Yes';
  }
  return result;
}
