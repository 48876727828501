/**
 * @flow
 */
import React from 'react';
import {api, apiBL, util} from '../../services/service';
import useBLUpsGridView from '../../grids/BL/useBLUpsGridView';
import SearchPane from '../../components/SearchPane';
import {PrimaryButton} from '../../components/buttons';
import BLUpsSearchFormManager from '../../forms/bl/BLUpsSearchFormManager';
import type {QueryListParams} from '../../services/API';
import {useSimpleModal} from '../../components/SimpleModal';
import {useDropzone} from 'react-dropzone';
import UploadIcon from 'mdi-react/UploadIcon';
import {useAsyncStatusModal} from '../../components/AsyncStatusModal';
import {statusOptions} from '../../forms/helper';

const searchForm = new BLUpsSearchFormManager();

const BLUps = () => {
  const {grid, search, clear, onDownload, uploadModal, uploadVendorModal} = useBLUps();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={() => search()} className={'w-full mb-5'} />
        {/*<PrimaryButton label={'Clear'} onClick={() => clear()} className={'w-full'} />*/}
        <PrimaryButton label={'Export CSV'} onClick={onDownload} className={'w-full mt-2'} />
        <PrimaryButton label={'Upload'} onClick={() => uploadModal.open()} className={'w-full'} />
        {/*<PrimaryButton label={'Upload Vendor'} onClick={() => {}} className={'w-full'} />*/}
        {/* <PrimaryButton label={'Upload Vendor'} onClick={() => uploadVendorModal.open()} /> */}
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {grid.render()}
        <div style={{position: 'absolute', left: 0, top: -12}}>
          <PrimaryButton label={'ADD NEW'} onClick={()=>{util.openTab("https://coship.ca/application/form?s=MA&d=CANADA&n=C")}} />
        </div>
      </div>
      {uploadModal.render()}
      {uploadVendorModal.render()}
    </div>
  );
};

function useBLUps() {
  const apiRef = React.useRef();

  const grid = useBLUpsGridView((params) => {apiRef.current = params.api});

  const onDownload = () => {
    function itemNameRenderer(param, index) {
      return (param.data.items.length > index) ? param.data.items[index]?.item : "";
    }

    function itemQtyRenderer(param, index) {
      return (param.data.items.length > index) ? param.data.items[index]?.qty : "";
    }

    function itemUnitPriceRenderer(param, index) {
      return (param.data.items.length > index) ? util.currencyFormatter({value: param.data.items[index]?.unitPrice}) : "";
    }

    function statusRenderer(value) {
      const statusOption = statusOptions.find((statusOption) => statusOption.value === value);
      if (statusOption !== undefined) {
        return statusOption?.label;
      } else {
        return '';
      }
    }

    function dateRenderer(value) {
      return util.formatD(value) ? util.formatD(value) : '';
    }

    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: `bl-ups-list-${util.formatD(Date.now())}.csv`,
        columnKeys: grid?.props?.columns?.filter((c, index) => c.field !== "bl_no").map(c => c.field),
        processCellCallback: (cellParams) => {
          if (cellParams.column.colId === 'status_no') {
            return statusRenderer(cellParams.value);
          } else if (cellParams.column.colId.includes('cdate')) {
            return dateRenderer(cellParams.value);
          } else if (cellParams.column.colId.includes('item_name_')) {
            return itemNameRenderer(cellParams.node, cellParams.column.userProvidedColDef.index);
          } else if (cellParams.column.colId.includes('item_qty_')) {
            return itemQtyRenderer(cellParams.node, cellParams.column.userProvidedColDef.index);
          } else if (cellParams.column.colId.includes('item_unit_price_')) {
            return itemUnitPriceRenderer(cellParams.node, cellParams.column.userProvidedColDef.index);
          } else if (cellParams.column.colId.includes('price')) {
            return util.currencyFormatter({value: cellParams.value});
          } else {
            return cellParams.value ?? "";
          }
        }
      });
    }
  };

  const uploadModal = useCSVUploadModal(false, (res) => {
    const message = res?.data?.[0]?.msg;
    if (message) {
      util.showSuccess(message);
      setTimeout(() => {
        search(); // 파일 업로드가 완료 되었으므로 목록을 재검색함!
      }, 0);
      uploadModal.close();
    }
  });

  const uploadVendorModal = useCSVUploadModal(true, (res) => {
    const message = res?.data?.[0]?.msg;
    if (message) {
      util.showSuccess(message);
      setTimeout(() => {
        search(); // 파일 업로드가 완료 되었으므로 목록을 재검색함!
      }, 0);
      uploadVendorModal.close();
    }
  });

  const search = (qryText) => {
    const {searchName, searchVal, onlyWaitList, status} = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      searchName, searchVal, onlyWaitList, statusNo: (status !== '') ? Number(status) : undefined
    };
    if (qryText) {
      grid.query({...listParam, qryText});
    } else {
      grid.query(listParam);
    }
  };

  const clear = () => {
    const gridInfo = util.getGridInfo(grid.props.name);
    gridInfo.qryText = '';
    searchForm.setValue('searchVal', '');
    document.getElementById('ListAllUPS-search').value = '';
  };

  searchForm.onSearch = () => search();

  return {grid, search, clear, onDownload, uploadModal, uploadVendorModal};
}

const UploadCSV = ({onUpload, isVendor}) => {
  const asyncStatusModal = useAsyncStatusModal('Uploading... please wait...');
  const onDrop = React.useCallback((acceptedFiles: FileList) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        asyncStatusModal.open();
        isVendor ?
          apiBL.uploadBLUpsVendorCSV(reader.result, file.name).then((res) => onUpload(res)).finally(() => asyncStatusModal.close()) :
          apiBL.uploadBLUpsCSV(reader.result, file.name).then((res) => onUpload(res)).finally(() => asyncStatusModal.close());
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: '.csv, .xlsx',
    maxFiles: 1,
    onDropRejected: () => {
      util.showWarning('Please select valid CSV/Excel file!');
    },
  });

  return (
    <div className={'flex center middle mb-20'} {...getRootProps()} style={{height: 350, border: '1px solid #d0d0d0'}}>
      <input {...getInputProps()} />
      <div style={{textAlign: 'center'}}>
        <UploadIcon size={56} color={'#c0c0c0'} />
        {isDragActive ? <p>Drop the CSV/Excel file here ...</p> : <p>Drag & drop CSV/Excel file here, or click to select file</p>}
      </div>
      {asyncStatusModal.render()}
    </div>
  );
};

function useCSVUploadModal(isVendor, onUpload) {
  const modal = useSimpleModal({
    title: isVendor ? "Upload Vendor" : "Upload",
    width: 720,
    centered: true,
    children: <UploadCSV onUpload={onUpload} isVendor={isVendor}/>,
  });
  return modal;
}

export default BLUps;
