import React from 'react';
import {Table} from 'reactstrap';
import Panel from '../../../components/Panel';
import {util} from "../../../services/service";
import {PrimaryButton} from "../../../components/buttons";

const UnpaidAccountList = (props) => {
  const data = props.data;
  const CAD = data ? data.unpaid[0].amount[0].CAD : 0;
  const USD = data ? data.unpaid[0].amount[1].USD : 0;
  const KRW = data ? data.unpaid[0].amount[2].KRW : 0;
  const balance_list = data ? data.unpaid[0].balance_list : [];
  const renderList = () => {
    return (
      balance_list.map((item, idx) => {
        return (
          <tr key={idx}>
            <td className="td-head">{item.bill_to}</td>
            <td className="td-black">{item.currency}</td>
            <td className="td-black">{util.currencyFormat(item.amount)}</td>
            <td className="td-black">{util.currencyFormat(item.balance)}</td>
          </tr>
        );
      })
    );
  };
  const onAccountList = (e) => {
    e.preventDefault();
    // util.nav('/admin/account/list?isUnpaid=true');
    util.nav('/admin/account/list');
  };
  return (
    <Panel
      md={12}
      lg={6}
      xl={4}
      xs={12}
      title={'Unpaid Account List'}
      subhead=""
    >
      <div className="dashboard__booking-reservations">
        <p className="dashboard__booking-reservations-title">Total unpaid amount</p>
        <div className={'flex'}>
          <div className={'flex-1'}><h4>CAD {util.currencyFormat(CAD)}</h4></div>
          <div className={'flex-1'}><h4>USD {util.currencyFormat(USD)}</h4></div>
          <div className={'flex-1'}><h4>KRW {util.currencyFormat(KRW)}</h4></div>
        </div>
        <div className={'table-responsive'}>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}><a href={'/admin/account/list'} onClick={onAccountList}>ACCOUNT LIST</a></div>
          <Table className="table dashboard__occupancy-table">
            <thead>
            <tr style={{borderTop: 'none'}}>
              <th>Bill To</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Balance</th>
            </tr>
            </thead>
            <tbody>
            {renderList()}
            </tbody>
          </Table>
        </div>
      </div>
    </Panel>
  );
};

export default UnpaidAccountList;
