/**
 * @flow
 */
import React from "react";
import FormManager from "../../../../lib/FormManager";
import {api, util} from "../../../../services/service";

export const SEARCH = 'qryText';
export const FORWARDING_FROM_DATE = 'fromDate';
export const FORWARDING_TO_DATE = 'toDate';
export const FORWARDING_BRANCH = 'branchId';

export default class ForwardingQuoteSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  constructor() {
    super({
      prefix: 'forwarding-quote-search',
      fields: [
        {name: SEARCH, serverName: SEARCH, label: 'Search', smallMargin: true},
        {name: FORWARDING_FROM_DATE, serverName: FORWARDING_FROM_DATE, label: 'Date', smallLabel: true, smallMargin: true, type: 'date'},
        {name: FORWARDING_TO_DATE, serverName: FORWARDING_TO_DATE, label: '', smallLabel: true, smallMargin: true, type: 'date'},
        {name: FORWARDING_BRANCH, serverName: FORWARDING_BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const areaOptions = api.useAreaOptions(true);
    _f(FORWARDING_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SEARCH).onEnter = this.onSearch;
    const branchId = _v(FORWARDING_BRANCH);

    React.useEffect(() => {
      _c(FORWARDING_FROM_DATE, util.toDefaultFirstTS(util.getCurrentDate()));
      _c(FORWARDING_TO_DATE, util.toDefaultEndTS(util.getCurrentDate()));
    }, []);

    React.useEffect(() => {
      if (!(branchId === undefined)) {
        this.onSearch();
      }
    }, [branchId]);

    return (
      <>
        {_r(SEARCH)}
        {_r(FORWARDING_FROM_DATE)}
        <div>~</div>
        {_r(FORWARDING_TO_DATE)}
        {_r(FORWARDING_BRANCH)}
      </>
    );
  };
}
