/**
 * @flow
 */
import {useSimpleEditModal} from '../../components/SimpleModal';
import StaffListEditFormManager from '../../forms/staff/StaffListEditFormManager';
import React from "react";

const editForm = new StaffListEditFormManager();

export default function useStaffEditModal(onSave) {
  const modal = useSimpleEditModal({
    title: 'STAFF',
    centered: true,
    width: 600,
    form: editForm,
    onSave,
  });
  React.useEffect(() => {
    if(modal.isOpen) {
      if (modal.mode === 'add') {
        editForm.getField('password').required = true;
      } else {
        editForm.getField('password').required = false;
      }
    }
  }, [modal.isOpen]);
  return {...modal};
}
