/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {DefaultButton} from '../../components/buttons';
import useCourierPriceModal from "../../modals/settings/useCourierPriceModal";
import {apiSettings, util} from "../../services/service";

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class MasterCourierPriceFormManager extends FormManager {
  grid;
  constructor() {
    super({
      prefix: `master-courier-price-add-item-form`,
      fields: [],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onRender = () => {
    const title = 'Vendor Table';
    const courierPriceModal = useCourierPriceModal();
    const grid = useVendorTableItemGrid();

    this.grid = grid;
    const onQuery = async () => {
      const {data} = await apiSettings.courierPriceList();
      grid.setRows(data);
    }

    const onAdd = () => {
      courierPriceModal.open({partner_id: 0, partner_name: '', carrier_type: 'A'});
    }

    React.useEffect(() => {
      if(!courierPriceModal.isOpen) {
        onQuery();
      }
    }, [courierPriceModal.isOpen]);

    function useVendorTableItemGrid() {
      function getColumns() {
        return [
          {field: 'partner_id', hide: true},
          {field: 'carrier_type', headerName: 'Type', flex: 1},
          {field: 'partner_name', headerName: 'Vendor', type: 'date', flex: 1},
          {field: 'phone', headerName: 'Phone', type: 'date', flex: 1},
          {field: 'city', headerName: 'City', flex: 1},
          {field: 'address', headerName: 'Address', flex: 1},
        ];
      }
      async function onDelete(id, carrierType) {
        const data = {partnerId: id, priceList: [], carrierType: carrierType};
        if(id) {
          const res = await apiSettings.setCourierPrice(data);
          if(res) {
            onQuery();
          }
        }
      }
      return useSimpleGrid({
        columns: getColumns(),
        height: 400,
        className: 'pb-20',
        actions: ['edit', 'delete'],
        actionWidth: 70,
        onAction: (action, data) => {
          if (action === 'edit') {
            courierPriceModal.open(data);
          }
          if (action === 'delete') {
            util.showConfirm('Are you sure to delete?', () => onDelete(data.partner_id, data.carrier_type));
          }
        },
        isActionDisabled: (action, data) => {
          return action === 'edit' ? false : (data.partner_id === 0 ? true : false);
        },
      });
    }

    return (
      <div className={'w-full'}>
        <Card style={cardStyle}>
          <CardBody className={'p-20'}>
            <div className={'flex between w-full'}>
              <label className={'flex-1'}><b className={'ml-12'}>{title}</b></label>
              <DefaultButton label={'Add'} onClick={onAdd} />
            </div>
            {grid.render()}
            {courierPriceModal.render()}
          </CardBody>
        </Card>
      </div>
    );
  };

  onValidate = (values) => {
  };
}

export default MasterCourierPriceFormManager;