/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";

// const MAPLE_BAG = {
//   maple_type: 'MAPLE BAG',
//   houses: [
//     {shipper: "Kang1", packages: 1, weight_kg: "1", pickup: "2024-08-01", edate: "2024-10-20", clp_remark: "CLP bigo1"},
//     {shipper: "Kang2", packages: 2, weight_kg: "2", pickup: "2024-08-01", edate: "2024-10-20", clp_remark: "CLP bigo2"},
//     {shipper: "Kang3", packages: 3, weight_kg: "3", pickup: "2024-08-01", edate: "2024-10-20", clp_remark: "CLP bigo3"},
//     {shipper: "Kang4", packages: 4, weight_kg: "2", pickup: "2024-08-01", edate: "2024-10-20", clp_remark: "CLP bigo4"},
//     {shipper: "Kang5", packages: 5, weight_kg: "7", pickup: "2024-08-01", edate: "2024-10-20", clp_remark: "CLP bigo5"},
//   ]
// };
//
// const MAPLE_BOX = {
//   maple_type: 'MAPLE BOX',
//   houses: [
//     {shipper: "Kang11", packages: 6, weight_kg: "11", pickup: "2024-08-02", edate: "2024-10-20", clp_remark: "CLP bigo11"},
//     {shipper: "Kang22", packages: 7, weight_kg: "20", pickup: "2024-08-02", edate: "2024-10-20", clp_remark: "CLP bigo22"},
//     {shipper: "Kang33", packages: 8, weight_kg: "22", pickup: "2024-08-02", edate: "2024-10-20", clp_remark: "CLP bigo33"},
//   ]
// };
//
// const MAPLE_BUNDLE = {
//   maple_type: 'MAPLE BUNDLE',
//   houses: [
//     {shipper: "Kang111", packages: 9, weight_kg: "4", pickup: "2024-08-03", edate: "2024-10-20", clp_remark: "CLP bigo111"},
//     {shipper: "Kang1111", packages: 10, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 11, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 12, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 13, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 14, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 15, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 16, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 17, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 18, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 19, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 20, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 21, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 22, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 23, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 24, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 25, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 26, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 27, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 28, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 29, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//   ]
// };
//
// const MAPLE_MIX = {
//   maple_type: 'MAPLE MIX',
//   houses: [
//     {shipper: "Kang1111", packages: 30, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 31, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 32, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 33, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 34, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 35, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 36, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 37, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 38, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 39, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 40, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 41, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 42, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 43, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 44, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 45, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 46, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 47, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 48, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 49, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 50, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 51, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 52, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 53, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 54, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 55, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 56, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 57, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 58, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 59, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 60, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 61, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 62, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 63, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 64, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 65, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 66, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 67, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 68, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 69, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 70, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 71, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 72, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 73, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 74, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 75, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 76, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 77, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 78, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 79, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 80, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 81, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 82, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang3333", packages: 83, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang1111", packages: 84, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     {shipper: "Kang2222", packages: 85, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang3333", packages: 86, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang1111", packages: 87, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang2222", packages: 88, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang3333", packages: 89, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang1111", packages: 90, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang2222", packages: 91, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang3333", packages: 92, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang1111", packages: 93, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang2222", packages: 94, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang3333", packages: 95, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang1111", packages: 96, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang2222", packages: 97, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang3333", packages: 98, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang1111", packages: 99, weight_kg: "10", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang2222", packages: 100, weight_kg: "21", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//     // {shipper: "Kang3333", packages: 101, weight_kg: "24", pickup: "2024-08-04", edate: "2024-10-20", clp_remark: "CLP bigo"},
//   ]
// };

class CLPPrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (printData?.length === 0) {
      return null;
    }
    return <PackingList printData={printData}/>;
  }
}

const PackingList = ({printData}) => {
  const user = useUser();
  const {seal_no, container_no, vsl_name, total_customer, total_bag, total_kg, total_cbm, maples = []} = printData;
  const totalLine = 50;
  const summaryLine = 7;
  const height = 25;
  const pageTotalLine = totalLine * 2;
  // const mapleBag = MAPLE_BAG;
  // const mapleBox = MAPLE_BOX;
  // const mapleBundle = MAPLE_BUNDLE;
  // const mapleMix = MAPLE_MIX;
  const mapleBag = maples[0];
  const mapleBox = maples[1];
  const mapleBundle = maples[2];
  const mapleMix = maples[3];
  const totalBagPackages = util.arraySum(mapleBag.houses?.map((data) => data.packages));
  const totalBagWeight = util.arraySum(mapleBag.houses?.map((data) => util.toFloat(data.weight_kg)));
  const totalBoxPackages = util.arraySum(mapleBox.houses?.map((data) => data.packages));
  const totalBoxWeight = util.arraySum(mapleBox.houses?.map((data) => util.toFloat(data.weight_kg)));
  const totalBundlePackages = util.arraySum(mapleBundle.houses?.map((data) => data.packages));
  const totalBundleWeight = util.arraySum(mapleBundle.houses?.map((data) => util.toFloat(data.weight_kg)));
  const totalMixPackages = util.arraySum(mapleMix.houses?.map((data) => data.packages));
  const totalMixWeight = util.arraySum(mapleMix.houses?.map((data) => util.toFloat(data.weight_kg)));
  const mapleBagHouses = mapleBag.houses?.length > 0 ? [{maple_type: '메이플 백 35'}, ...mapleBag.houses, {totalPackages: totalBagPackages, totalWeight: totalBagWeight}] : [];
  const mapleBoxHouses = mapleBox.houses?.length > 0 ? [{maple_type: '메이플 박스 20'}, ...mapleBox.houses, {totalPackages: totalBoxPackages, totalWeight: totalBoxWeight}] : [];
  const mapleBundleHouses = mapleBundle.houses?.length > 0 ? [{maple_type: '번들'}, ...mapleBundle.houses, {totalPackages: totalBundlePackages, totalWeight: totalBundleWeight}] : [];
  const mapleMixHouses = mapleMix.houses?.length > 0 ? [{maple_type: '혼용'}, ...mapleMix.houses, {totalPackages: totalMixPackages, totalWeight: totalMixWeight}] : [];
  const totalHouses = [...mapleBagHouses, ...mapleBoxHouses, ...mapleBundleHouses, ...mapleMixHouses];
  const summaryInfos = totalHouses?.length > 0 ? [{sName: 'TOTAL BAG'}, {sName: 'TOTAL CBM'}, {sName: 'TOTAL WEIGHT'}, {sName: 'EMPTY LINE'}, {sName: 'SEAL#'}, {sName: 'CONTAINER NO'}, {sName: 'VESSEL NAME'}] : [];
  const totalData = [...mapleBagHouses, ...mapleBoxHouses, ...mapleBundleHouses, ...mapleMixHouses, ...summaryInfos];
  const pageInfos = util.chunk(totalData, pageTotalLine);

  const getIsHouseData = (itemList) => {
    let dataCount = 0;
    for (let i=0; i<itemList.length; i++) {
      if (itemList[i]['shipper'] !== undefined) {
        dataCount++;
      }
    }
    return dataCount > 0;
  };

  const renderItemList = (itemList, pageIndex) => {
    const itemList1 = itemList?.filter((_, index) => index < totalLine);
    const itemList2 = itemList?.filter((_, index) => index >= totalLine && index < pageTotalLine);
    const hasHouseDataFirstLine = getIsHouseData(itemList1);
    const hasHouseDataSecondLine = getIsHouseData(itemList2);
    const firstList = hasHouseDataFirstLine ? [{title: 'first line'}, ...itemList1] : itemList1;
    const secondList = itemList2?.length === 0 ? [] : (hasHouseDataSecondLine ? [{title: 'second line'}, ...itemList2] : itemList2);

    const renderTable = (items, isSummary) => {
      return (
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          {items?.map((data, index) => {
            return (
              <>
                {data.maple_type &&
                  <tr style={{height}}>
                    <td colSpan={6} className={'fb-14 center'} style={{backgroundColor: '#313030', color: 'white', }}>{data.maple_type}</td>
                  </tr>
                }
                {data.title &&
                  <tr style={{height}}>
                    <td className={'center'} style={{backgroundColor: '#dbdcde', width: 50}}>개수</td>
                    <td className={'center'} style={{backgroundColor: '#dbdcde', width: 100}}>픽업</td>
                    <td className={'center'} style={{backgroundColor: '#dbdcde', width: 100}}>귀국</td>
                    <td className={'center'} style={{backgroundColor: '#dbdcde'}}>이름</td>
                    <td className={'center'} style={{backgroundColor: '#dbdcde'}}>특이사항</td>
                    <td className={'center'} style={{backgroundColor: '#dbdcde', width: 50}}>무게</td>
                  </tr>
                }
                {!data.maple_type && !data.title && !data.totalPackages && !data.sName &&
                  <tr key={index} style={{height}}>
                    <td className={'center'} style={{width: 50}}>{data.packages}</td>
                    <td className={'center'} style={{width: 100}}>{data.pickup}</td>
                    <td className={'center'} style={{width: 100}}>{data.edate}</td>
                    <td className={'center'}>{util.textSubstring(data.shipper ?? '', 6)}</td>
                    <td className={'center'}>{util.textSubstring(data.clp_remark ?? '', 10)}</td>
                    <td className={'center'} style={{width: 50}}>{data.weight_kg}</td>
                  </tr>
                }
                {data.totalPackages &&
                  <tr style={{height}}>
                    <td className={'center no-border-r'} style={{backgroundColor: '#e5b7b6'}}>{data.totalPackages}</td>
                    <td colSpan={3} className={'center no-border-lr'} style={{backgroundColor: '#e5b7b6'}}>{''}</td>
                    <td className={'center no-border-lr'} style={{backgroundColor: '#e5b7b6'}}>TOTAL</td>
                    <td className={'center no-border-l'} style={{backgroundColor: '#e5b7b6'}}>{data.totalWeight}</td>
                  </tr>
                }
              </>
            );
          })}
          {summary(isSummary, pageIndex)}
          {emptyList(items.length)}
          </tbody>
        </table>
      );
    };

    return (
      <div className={'flex mt-8'}>
        <div className={'flex-1 mr-3'}>{renderTable(firstList, itemList2?.length === 0)}</div>
        <div className={'flex-1'}>{renderTable(secondList, itemList2?.length !== 0)}</div>
      </div>
    );
  };

  const summary = (isSummary, pageIndex) => {
    return (
      <>
        {isSummary && pageInfos?.length === pageIndex + 1 && <>
          <tr style={{height}}>
            <td colSpan={2} className={'no-border-all'}>{''}</td>
            <td colSpan={2} className={'right'}>{'TOTAL BAG'}</td>
            <td colSpan={2} className={'right'}>{total_bag}</td>
          </tr>
          <tr style={{height}}>
            <td colSpan={2} className={'no-border-all'}>{''}</td>
            <td colSpan={2} className={'right'}>{'TOTAL CBM'}</td>
            <td colSpan={2} className={'right'}>{total_cbm}</td>
          </tr>
          <tr style={{height}}>
            <td colSpan={2} className={'no-border-all'}>{''}</td>
            <td colSpan={2} className={'right'}>{'TOTAL WEIGHT'}</td>
            <td colSpan={2} className={'right'}>{total_kg}</td>
          </tr>
          <tr style={{height}}><td className={'no-border-all'}>{''}</td></tr>
          <tr style={{height}}>
            <td colSpan={3} className={'center'}>{'SEAL#'}</td>
            <td colSpan={3} className={'center'}>{seal_no}</td>
          </tr>
          <tr style={{height}}>
            <td colSpan={3} className={'center'}>{'CONTAINER NO'}</td>
            <td colSpan={3} className={'center'}>{container_no}</td>
          </tr>
          <tr style={{height}}>
            <td colSpan={3} className={'center'}>{'VESSEL NAME'}</td>
            <td colSpan={3} className={'center'}>{vsl_name}</td>
          </tr>
        </>}
      </>
    );
  };

  const emptyList = (itemCount) => {
    const emptyLine = totalLine - itemCount - summaryLine;
    const empty = [];
    if (emptyLine > 0) {
      for (let i = 0; i < emptyLine; i++) {
        empty.push(
          <tr key={`empty_${i}`} style={{height}}>
            <td colSpan={6} className={'no-border-all'}>{''}</td>
          </tr>
        );
      }
    }
    return empty;
  };

  return (
    <div style={{color: 'black'}}>
      {pageInfos?.map((items, index) => {
        return (
          <div key={`item-${index}`} className={'page-break'}>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <div className={'flex-2'} style={{fontSize: 50, fontWeight: 'bold', textAlign: 'center'}}>메이플 CLP</div>
              <table className={'print-table flex-1'} style={{width: '100%'}}>
                <tbody>
                <tr>
                  <td className={'center'}>작성일</td>
                  <td className={'center'}>{util.getCurrentDate()}</td>
                </tr>
                <tr>
                  <td className={'center'}>작성자</td>
                  <td className={'center'}>{user.uname}</td>
                </tr>
                <tr>
                  <td className={'center'}>작업자</td>
                  <td className={'center'}>{''}</td>
                </tr>
                </tbody>
              </table>
            </div>
            {renderItemList(items, index)}
          </div>
        )
      })}
    </div>
  );
}

export function useCLPPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: 'Maple CLP',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <CLPPrint ref={printRef} printData={printData}/>,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setPrintData(data);
      modal.open();
    },
    print,
  };
}

export default CLPPrint;
