/**
 * @flow
 */
import React from 'react';
import type {QueryListParams} from './API';
import API from './API';

class APIDashboard extends API {
  async requestList(data: QueryListParams) {
    return this.sendList('dashboard/ListData', {action: 'ListOnlineRequest', data});
  }

  async requestSave(data) {
    return this.sendList('dashboard/CUD', {action: 'SetOnlineRequest', data});
  }

  async getAccountSummary(data) {
    return this.sendList('dashboard/GetData', {action: 'GetAccountSummary', data});
  }
  async getBLSummary() {
    return this.sendList('dashboard/GetData', {action: 'GetBLSummary', data: {}});
  }
  async getRequestSummary() {
    return this.sendList('dashboard/ListData', {action: 'ListRequestNewTop10', data: {}});
  }
}

export default APIDashboard;
