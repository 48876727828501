/**
 * @flow
 */
import {useSimpleGrid} from "../../../../components/SimpleGrid";
import {util} from "../../../../services/service";

export default function useForwardingSettingAccountTypeListGridView(onActionCb) {
  return useSimpleGrid({
    columns: [
      {field: 'kind', headerName: 'Service', flex: 1},
      {field: 'bound', headerName: 'Bound', flex: 1},
      {field: 'created', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 2},
      {field: 'acc_type', headerName: 'Type', flex: 1},
      {field: 'currency', headerName: 'Currency', flex: 1},
      {field: 'branch_name', headerName: 'Branch', flex: 1},
      {field: 'subject', headerName: 'Subject', flex: 2},
      {field: 'partner_name', headerName: 'Partner', flex: 2},
    ],
    height: 530,
    className: 'pb-20',
    actions: ['edit'],
    actionWidth: 70,
    onAction: onActionCb,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      // onCellClicked(e) {
      //   if(e.colDef.headerName !== 'Actions') {
      //     onCellClicked(e);
      //   }
      // }
    },
  });
}
