/**
 * @flow
 */
import {useDataGridView} from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {teamMainOptions} from "../../forms/helper";

export default function useStaffListGridView(onActionCb) {
  const authOptions = api.usePermissionOptions();
  const areaOptions = api.useAreaOptions(true);
  const jobTitleOptions = api.useJobTitleOptions();

  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return api.staffList(gridInfo);
    },
    name: 'staffList',
    label: 'staff',
    addLabel: '',
    editLabel: 'EDIT STAFF',
    categoryLabel: {label: 'Staff'},
    menuLabel: {label: 'List'},
    sortCol: 'uname',
    sortDesc: false,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 70,
    showDateSearch: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    columns: [
      { name: 'userId', required: false, field: 'id', hide: true},
      { name: 'areaId', label: 'Branch', options: areaOptions, required: true, field: 'area_id', hide: true},
      { name: 'auth', label: 'Auth', options: authOptions, required: true, field: 'auth', hide: true},
      { name: 'jobTitle', label: 'Title', required: true, options: jobTitleOptions, field: 'title_id', hide: true},
      { name: 'password', label: 'Password', required: true, field: 'password', hide: true},
      { name: 'cdate', required: false, field: 'cdate', headerName: 'Created', valueFormatter: util.dateFormatter, flex: 1},
      { name: 'loginId', required: true, field: 'login_id', headerName: 'LoginID', flex: 1},
      { name: 'userName', required: true, field: 'uname', headerName: 'Eng.Name', flex: 1},
      { name: 'userName2', required: true, field: 'uname2', headerName: 'Local Name', flex: 1},
      { name: 'jtitle', required: false, field: 'jtitle', headerName: 'Title', flex: 1},
      { name: 'teamMain', label: 'Main Team', options: teamMainOptions, defaultValue: 'N', field: 'team_main', headerName: 'Main Team', noDefOption: true, flex: 1},
      { name: 'email', required: true, field: 'email', headerName: 'Email', flex: 1, hide: true},
      { name: 'cphone', required: true, field: 'cphone', headerName: 'Cell #', flex: 1},
      { name: 'hphone', required: false, field: 'hphone', headerName: 'Home #', flex: 1, hide: true},
      { name: 'area_name', required: false, field: 'area_name', headerName: 'Branch', flex: 1},
      { name: 'isActive', type: 'checkbox', required: false, field: 'isactive', headerName: 'Activated', valueFormatter: util.yesNoFormatter, flex: 1},
      { name: 'empDate', type: 'date', mask: util.MASK_DATE, required: false, field: 'empdate', headerName: 'Work Start', valueFormatter: util.dateFormatter, flex: 1},
      { name: 'workEnd', type: 'date', mask: util.MASK_DATE, required: false, field: 'work_end', headerName: 'Work End', valueFormatter: util.dateFormatter, flex: 1, hide: true},
      { name: 'address', required: false, field: 'address', headerName: 'Address', flex: 1, hide: true},
    ],
  });
}
