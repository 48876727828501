/**
 * @flow
 */
import React from 'react';
import {DefaultButton, PrimaryButton} from '../../components/buttons';
import SearchPane from '../../components/SearchPane';
import useAccountGridView from "../../grids/account/useAccountGridView";
import AccountListSearchFormManager from "../../forms/account/AccountListSearchFormManager";
import AccountListInvoiceTypeFormManager from "../../forms/account/AccountListInvoiceTypeFormManager";
import {api, apiAccount, apiBL, util} from "../../services/service";
import type {QueryListParams} from "../../services/API";
import {INVOICE} from "../../forms/field-defs/account";
import useAccountPaymentModal from "../../modals/account/useAccountPaymentModal";
import useRequestEditModal from "../../modals/account/useRequestEditModal";
import {useInvoiceAPPreviewModal} from "../../print-layouts/InvoiceAPPrint";
import {getRequestEntryData} from "../../forms/account/AccountRequestEditFormManager";
import {useInvoiceStatementPreviewModal} from "../../print-layouts/InvoiceStatementPrint";
import {useAsyncStatusModal} from "../../components/AsyncStatusModal";
import {useDropzone} from "react-dropzone";
import UploadIcon from "mdi-react/UploadIcon";
import {useSimpleModal} from "../../components/SimpleModal";
import {useUser} from "../../redux/reducers/userReducer";

const searchForm = new AccountListSearchFormManager();
const invoicetypeForm = new AccountListInvoiceTypeFormManager();
const AccountList = () => {
  const [invoiceType, setInvoiceType] = React.useState('AR');
  const invoiceStatementPrintModal = useInvoiceStatementPreviewModal();
  const qs = util.getQS();
  // const {blNo, isUnpaid} = qs;
  const {blNo} = qs;
  const {filingNo} = qs;
  const user = useUser();
  const {
    onSearch,
    uploadModal,
    onDownload,
    gridView,
    accountPaymentModal,
    accountAPEditModal,
    invoiceAPPrintModal,
    totalAmount,
    totalBalance,
    girdViewInfo,
  } = useAccountList(searchForm.blNo, searchForm.filingNo);
  const onAdd = () => {
    const { invoiceType } = invoicetypeForm.getValues();
    let blQuery = '';
    if(blNo) {
      blQuery = `?blNo=${blNo}`;
    }
    if (invoiceType === undefined || invoiceType === 'AR') {
      // util.nav(`/admin/account/list/ar${blQuery}`);
      util.openTab(`/admin/account/list/ar${blQuery}`);
    } else if (invoiceType === 'AP') {
      // util.nav(`/admin/account/list/ap${blQuery}`);
      util.openTab(`/admin/account/list/ap${blQuery}`);
    } else if (invoiceType === 'DC') {
      // util.nav(`/admin/account/list/dc${blQuery}`);
      util.openTab(`/admin/account/list/dc${blQuery}`);
    }
  };

  const onStatement = () => {
    const partnerId = searchForm.getValue('billToId');
    const currency = searchForm.getValue('currency');
    const invNo = searchForm.getValue('invNo');
    const invType = searchForm.getValue('invType');
    const blNo = searchForm.getValue('blNo');
    const branchId = searchForm.getValue('branchId');
    const billTo = searchForm.getValue('billTo');
    const billToBillAddr = searchForm.getValue('billToBillAddr');
    const cargoType = searchForm.getValue('cargoType');
    const unpaid = searchForm.getValue('unpaid');
    const billToData = {billTo, billToBillAddr}
    const fromDate = girdViewInfo.fromDate;
    const toDate = girdViewInfo.toDate;
    if(partnerId && billTo && currency && unpaid) {
      apiAccount.unpaidStatementList({partnerId, currency, invNo, invType, blNo, branchId, cargoType, unpaid, fromDate, toDate}).then((res) => {
        const {data} = res;
        apiAccount.branchList().then(res => {
          invoiceStatementPrintModal.open(data, billToData, res.data.lists, currency);
        });

      });
    } else {
      util.showWarning('Please select Bill To / Partner, Currency and Unpaid in search options');
    }
  };

  const onClickSearch = () => {
    onSearch();
    util.nav(`/admin/account/list`);
  };

  React.useEffect(() => {
    if(accountPaymentModal.isOpen === false) {
      onSearch();
    }
  }, [accountPaymentModal.isOpen]);

  React.useEffect(() => {
    if(blNo) {
      searchForm.setValue('blNo', blNo);
    }
    if(filingNo) {
      searchForm.setValue('filingNo', filingNo);
    }
  }, [blNo, filingNo]);

  invoicetypeForm.getField('invoiceType').onChange = (_, value) => {
    setInvoiceType(value);
  };
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onClickSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {gridView.render()}
        <div style={{position: 'absolute', top: -12, left: -50}}>
          {invoicetypeForm.renderForm()}
        </div>
        <div style={{position: 'absolute', top: -12, left: 110}}>
          {invoiceType === 'AR' && user.team_main === 'A' && (
            <PrimaryButton label={'CSV/EXCEL UPLOAD'} onClick={() => uploadModal.open()} />
          )}
          <PrimaryButton label={'ADD'} onClick={onAdd} />
          <PrimaryButton label={'STATEMENT'} onClick={onStatement} />
          <DefaultButton label={'Export Excel'} onClick={onDownload} />
        </div>
        <div className={'flex'} style={{position: 'absolute', bottom: 0, width: 350}}>
          <div className={'flex-1'}>Total Amount: {util.formatCurrency(totalAmount)}</div>
          <div className={'flex-1'}>Total Balance: {util.formatCurrency(totalBalance)}</div>
        </div>
        {uploadModal.render()}
        {accountPaymentModal.render()}
        {accountAPEditModal.render()}
        {invoiceAPPrintModal.render()}
        {invoiceStatementPrintModal.render()}
      </div>
    </div>
  );
};

function useAccountList(blNo, filingNo) {
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalBalance, setTotalBalance] = React.useState(0);
  const [girdViewInfo, setGirdViewInfo] = React.useState();
  const onAction = async (action, data) => {
    if (action === 'edit') {
      const {acc_type: invoiceType = '', bl_no, id, invoiceNo} = data;
      if (invoiceType === INVOICE.TYPE_AR) {
        // util.nav('/admin/account/list/ar?id=' + id);
        util.openTab('/admin/account/list/ar?id=' + id);
      }
      else if (invoiceType === INVOICE.TYPE_AP) {
        if(invoiceType === bl_no) {
          accountAPEditModal.open('print', data);
        } else {
          // util.nav('/admin/account/list/ap?id=' + id);
          util.openTab('/admin/account/list/ap?id=' + id);
        }
      }
      else if (invoiceType === INVOICE.TYPE_DC) {
        // util.nav('/admin/account/list/dc?id=' + id);
        util.openTab('/admin/account/list/dc?id=' + id);
      }
      else if (invoiceType === INVOICE.TYPE_CM) {
        // util.nav('/admin/account/list/cm?id=' + id);
        util.openTab('/admin/account/list/cm?id=' + id);
      }
      else if (invoiceType === INVOICE.TYPE_VD) {
        // util.nav('/admin/account/list/vd?id=' + id);
        util.openTab('/admin/account/list/vd?id=' + id);
      } else if (invoiceType === INVOICE.TYPE_DC_NOTE) {
        // util.nav('/admin/account/list/dcnote?id=' + id);
        util.openTab('/admin/account/list/dcnote?id=' + id);
      }
    } else if (action === 'commission') {
      const {acc_type: invoiceType, id, inv_no: invoiceNo} = data;
      accountPaymentModal.open(id ?? 0, invoiceNo ?? '');
      //accountPaymentModal.open(id ?? 0, invoiceNo ?? '', undefined, true); // readonly mode
      // if (invoiceType === INVOICE.TYPE_AR)
      //   accountPaymentModal.open(id ?? 0, invoiceNo ?? '');
    }
  };

  /////////////// account-request-modal ///////////////
  const invoiceAPPrintModal = useInvoiceAPPreviewModal();
  const onSave = async (dataToSend) => {
    const res = await apiAccount.accountSet(dataToSend);
    if (res) {
      util.showSuccess('Account Entry has been saved successfully!');
      accountAPEditModal.close();
      onSearch();
    }
  }
  const onPrint = (data) => {
    const dataInfo = {...data, invoiceDate: util.formatD(data.invoiceDate)};
    invoiceAPPrintModal.open(dataInfo);
  }
  const onDelete = (data) => {
    if(data && data.id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.deleteAccountData(parseInt(data.id));
        if(res) {
          util.showSuccess('Account has been deleted successfully!');
          accountAPEditModal.close();
          onSearch();
        }
      })
    }
  }
  const {requestEditForm, grid: editGrid, ...accountAPEditModal} = useRequestEditModal(async (mode, data) => {
    const values = requestEditForm.getValues(undefined, true);
    if (!values) {
      return;
    }
    const {jextra} = getRequestEntryData(values);
    const newJItems = editGrid?.rows?.map(i => {
      return {
        ...i,
        rate: util.toFloat(i['rate']),
        volume: util.toFloat(i['volume']),
      };
    });
    // eslint-disable-next-line max-len
    const dataToSend = {...data, invNo: data.invoiceNo, invDate: util.toTS(data.invoiceDate), accType: 'AP', blId: 0, blNo: '', branchId: data.financialOffice, isRequest: true, jextra: jextra, jitems: newJItems};
    if(mode === 'print') {
      onPrint(dataToSend);
    } else if(mode === 'delete') {
      onDelete(dataToSend);
    } else {
      onSave(dataToSend).catch();
    }
  });
  /////////////// account-request-modal ///////////////

  const onQueryData = (tAmount, tBalance, gridInfo) => {
    setTotalAmount(tAmount);
    setTotalBalance(tBalance);
    setGirdViewInfo(gridInfo);
  };

  const apiRef = React.useRef();
  const gridView = useAccountGridView(onAction, (params) => {apiRef.current = params.api}, onQueryData, accountAPEditModal, searchForm);

  const uploadModal = useCSVUploadModal((res) => {
    const message = res?.data?.[0]?.msg;
    if (message) {
      util.showSuccess(message);
      setTimeout(() => {
        onSearch(); // 파일 업로드가 완료 되었으므로 목록을 재검색함!
      }, 0);
      uploadModal.close();
    }
  });

  const onDownload = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: `account-list-${util.formatD(Date.now())}.csv`,
        columnKeys: gridView.props.columns.filter((c, index) => index > 0).map(c => c.field),
      });
    }
  };

  const onSearch = () => {
    const gridInfo = util.getGridInfo(gridView.props.name);
    let values = searchForm.getValues();
    // if (typeof blNo === 'string') {
    //   values = {...values, blNo};
    // }
    if (blNo) {
      values = {...values, blNo};
      blNo = '';
    }
    if (filingNo) {
      values = {...values, filingNo};
      filingNo = '';
    }

    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
      ...values,
    };
    gridView.query(listParam);
  };
  searchForm.onSearch = onSearch;

  const accountPaymentModal = useAccountPaymentModal();

  return {
    onSearch,
    uploadModal,
    onDownload,
    gridView,
    accountPaymentModal,
    accountAPEditModal,
    invoiceAPPrintModal,
    totalAmount,
    totalBalance,
    girdViewInfo,
  };
}

const VendorUpload = ({onUpload}) => {
  const asyncStatusModal = useAsyncStatusModal('Uploading... please wait...');
  const onDrop = React.useCallback((acceptedFiles: FileList) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        // console.log('hello', file);
        asyncStatusModal.open();
        apiBL.updateCSVPayAmt(reader.result, file.name).then((res) => onUpload(res)).finally(() => asyncStatusModal.close());
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: '.csv, .xlsx',
    maxFiles: 1,
    onDropRejected: () => {
      util.showWarning('Please select valid CSV/Excel file!');
    },
  });
  return (
    <div className={'flex center middle mb-20'} {...getRootProps()} style={{height: 350, border: '1px solid #d0d0d0'}}>
      <input {...getInputProps()} />
      <div style={{textAlign: 'center'}}>
        <UploadIcon size={56} color={'#c0c0c0'} />
        {isDragActive ? <p>Drop the CSV/Excel file here ...</p> : <p>Drag & drop CSV/Excel file here, or click to select file</p>}
      </div>
      {asyncStatusModal.render()}
    </div>
  );
};

function useCSVUploadModal(onUpload) {
  const modal = useSimpleModal({
    title: 'CSV/Excel Upload',
    width: 720,
    centered: true,
    buttons: [
      // {label: 'Close', onClick: () => modal.close()}
    ],
    children: <VendorUpload onUpload={onUpload} />,
  });
  return modal;
};

export default AccountList;
