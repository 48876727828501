/**
 * @flow
 */
import React from 'react';
import {Modal} from 'reactstrap';
import CalendarView from '../../components/CalendarView';
import Form, {isChecked, renderField} from '../../components/Form';
import type {FormField} from '../../components/Form';
import {api, util} from '../../services/service';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {useStaffSearch} from '../../components/SearchModal';
import {useUser} from '../../redux/reducers/userReducer';
import type {CalendarEvent} from '../../components/CalendarView';
import {useState} from '../../hooks/useState';
import {StaffSearchInput} from '../../components/SearchInput';

export interface StaffScheduleState {
  events: CalendarEvent[];
  holidayInfo: string;
  formDisabled: boolean;
  myself: boolean;
  showDelete: boolean;
  // modal state
  open: boolean;
  mode?: 'add' | 'edit';
  data?: any;
}

const CATEGORY_LABEL = 'Staff';
const MENU_LABEL = 'Schedule';

let formik; // hacky but very effective!
let fromTS; // hacky but very effective!
let toTS; // hacky but very effective!

const initialState: StaffScheduleState = {
  events: [],
  holidayInfo: '',
  formDisabled: false,
  myself: false,
  showDelete: false,
  open: false,
};

const StaffSchedule = () => {
  const [state, setState] = useState<StaffScheduleState>(initialState);
  const branchRef = React.useRef();
  useTopbar({label: CATEGORY_LABEL}, {label: MENU_LABEL});
  const user = useUser();
  const searchStaff = useStaffSearch(({id, value}) => {
    formik.handleChange({target: {name: 'name', value}});
    formik.handleChange({target: {name: 'userId', value: id}});
    if (id !== user.id) {
      formik.handleChange({target: {name: 'isHoliday', checked: false}});
    }
    setState({myself: id === user.id});
  });
  React.useEffect(() => {
    onDateChange(undefined, 'week').catch();
  }, []);
  React.useEffect(() => {
    if (state.open === true) {
      const newState: StaffScheduleState = {...state};
      if (state.mode === 'add') {
        newState.myself = true;
      } else {
        newState.myself = formik.values['userId'] === user.id;
      }
      newState.formDisabled = formik.values['isHoliday'] === true && state.mode === 'edit';
      setState(newState);
      api.getHolidayCount().then(res => {
        try {
          const {data: {holiday: [days, endDate]}} = res;
          newState.holidayInfo = `remains ${days} day${days > 1 ? 's' : ''} (${util.formatDate(endDate, 'YYYY-MM-DD')})`;
          setState(newState);
        } catch (error) {
          // DO NOTHING
        }
      });
    }
  }, [state.open]);
  const buildEvents = (data) => {
    return data.map(item => {
      const {id, isholiday: isHoliday, job_desc: jobDesc, location, plan_date: planDate, plan_time: planTime, purpose, uid: userId, uname2: name} = item;
      const title = isHoliday
        ? `${name} (Holiday)`
        : `${name} (${location}${planTime ? ' ' + planTime : ''}${purpose ? ' ' + purpose : ''})`
      return {
        title,
        start: new Date(planDate),
        end: new Date(planDate),
        allDay: true,
        resource: {
          id, isHoliday, jobDesc, location, planDate, planTime, purpose, userId, name
        }
      };
    });
  };
  const loadList = async (from, to, additionalState: StaffScheduleState, paramBranchId) => {
    const branchId = branchRef.current ?? user.branch_id;
    const {data} = await api.staffScheduleList({fromDate: from, toDate: to, branchId: branchId});
    setState({events: buildEvents(data), ...additionalState});
  };
  const onAddSchedule = () => {
    // setShowDelete(false);
    setState({showDelete: false, open: true, mode: 'add', data: {userId: user.id, name: user.uname}});
  };
  const onEditSchedule = (event) => {
    const {resource} = event;
    let {planDate, isHoliday} = resource;
    planDate = new Date(planDate);
    const canDelete = util.canDeleteSchedule(resource.planDate);
    // setState({showDelete: !isHoliday && canDelete, open: true, mode: 'edit', data: {...resource, planDate}});
    setState({showDelete: canDelete, open: true, mode: 'edit', data: {...resource, planDate}});
  };
  const onDateChange = async (date, view) => {
    const [from, to] = util.getBeginEndDateTS(date, view);
    fromTS = from;
    toTS = to;
    await loadList(from, to);
  };
  const onSave = async (values) => {
    const data = {...values, planDate: util.toTS(values.planDate), planDate2: util.toTS(values.planDate2)}
    if (!data['userId']) {
      util.showWarning('Invalid user. Please search user.');
      return;
    }
    if (state.mode === 'add' && data.isHoliday === true && !data.dayCount) {
      // dayCount 는 필수 입력임!
      util.showWarning('Please enter Days.');
      return;
    }
    const res = state.mode === 'add' ? await api.staffScheduleAdd(data) : await api.staffScheduleEdit(data);
    if (res) {
      util.showSuccess(`Schedule has been ${state.mode === 'add' ? 'added' : 'edited'} successfully!`);
      await loadList(fromTS, toTS, {open: false});
    }
  };
  const onDelete = (values) => {
    util.showConfirm('Are you sure to delete schedule?', async () => {
      //console.log(values);
      const {id} = values;
      const res = await api.staffScheduleDel({id});
      if (res) {
        util.showSuccess('Schedule has been deleted successfully!');
        await loadList(fromTS, toTS, {open: false});
      } else {
        util.showError('Failed to delete schedule!');
      }
    });
  };
  function getFields(): FormField[] {
    const disabled = state.formDisabled;
    const holidayDisabled = state.mode === 'edit' ? true : !state.myself;
    return [
      {name: 'id', required: false},
      {name: 'userId', required: false},
      {name: 'name', label: 'Name', required: true, disabled: state.mode === 'edit' ? true : disabled},
      {name: 'planDate', label: 'Date', type: 'datePicker', required: true, disabled},
      {name: 'planDate2', label: 'Date To', type: 'datePicker', required: false, disabled},
      {name: 'dayCount', label: 'Days', required: false, disabled},
      {name: 'planTime', label: 'Time', required: false, disabled},
      {name: 'isHoliday', label: 'Holiday', type: 'checkbox', required: false, disabled: holidayDisabled},
      {name: 'location', label: 'Location', required: false, disabled},
      {name: 'purpose', label: 'Purpose', required: false, disabled},
      {name: 'jobDesc', label: 'Desc.', type: 'textarea', required: false, textAreaRows: 6, disabled},
    ];
  }
  function renderFields(formik, fields, errors) {
    const render = (name) => renderField(formik, name, fields, errors);
    const isHoliday = isChecked(formik, 'isHoliday');
    const isMyself = user.id === formik.values['userId'];
    return (
      <div>
        <div className={'flex wrap'}>
          <StaffSearchInput className={'flex-1 mr-2'} displayField={'name'} idField={'userId'} formik={formik} fields={fields} errors={errors} />
          <div className={'flex-1 ml-2'}><span /></div>
        </div>
        <div className={'flex wrap'}>
          <div className={'flex-1 mr-2'}>{render('planDate')}</div>
          <div className={'flex-1 ml-2'}>{render('planTime')}</div>
        </div>
        <div className={'flex top'}>
          <div className={'mr-2'}>{render('isHoliday')}</div>
          {isMyself && isHoliday && (
            <div className={'mr-2'} style={{marginTop: 1, color: '#a0a0a0'}}>{state.holidayInfo}</div>
          )}
        </div>
        {isMyself && isHoliday && state.mode === 'add' && (
          <div className={'flex middle'}>
            <div className={'flex-1 mr-2'}>{render('planDate2')}</div>
            <div className={'flex-1 ml-2'}>{render('dayCount')}</div>
          </div>
        )}
        <div className={'flex wrap'}>
          <div className={'flex-1 mr-2'}>{render('location')}</div>
          <div className={'flex-1 ml-2'}>{render('purpose')}</div>
        </div>
        {render('jobDesc')}
      </div>
    );
  }
  const fields = getFields();
  return (
    <div style={{position: 'relative'}}>
      <CalendarView
        buttonLabel={'ADD SCHEDULE'}
        onButtonClick={onAddSchedule}
        defaultView={'week'}
        events={state.events}
        onEventClick={onEditSchedule}
        onDateClick={onDateChange}
        onViewChange={view => onDateChange(undefined, view)}
        onShowMore={(events, date) => console.log('[Calendar] showMore click', events, date)}
        showBranchOptions={true}
        onBranchChange={(branchId) => {
          branchRef.current = branchId;
          setTimeout(() => loadList(fromTS, toTS, {}, branchId), 0);
        }}
        isTodayColored={true}
      />
      <Modal isOpen={state.open} className={'modal-dialog--form'} centered style={{width: 720}}>
        <Form
          onFormik={fmk => formik = fmk}
          title={`${state.mode ?? 'add'} Staff Schedule`}
          horizontal
          fields={fields}
          values={state.data}
          onSubmit={state.formDisabled ? undefined : onSave}
          // onDelete={state.formDisabled || state.showDelete ? onDelete : undefined}
          onDelete={state.showDelete ? onDelete : undefined}
          onCancel={() => setState({open: false})}
          onChange={() => {}}
          render={(formik, fields, errors) => renderFields(formik, fields, errors)}
        />
      </Modal>
      {searchStaff.render()}
    </div>
  );
};

export default StaffSchedule;
