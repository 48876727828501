/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {currencyOptions, paymentOptions} from '../helper';
import {SK_INV_NO} from "../account/AccountListSearchFormManager";
import {util} from "../../services/service";
import Icon from "mdi-react/SearchIcon";
import {SK_CRAFT_NAME} from "../../containers/Account/forms/AirVesselSearchFormManager";

/*
jecommerce
{
	'requestDate': 15669000,
	'receiveDate': 164930303,
	'trackingNo': 'trackingnumber',
	'payMethod': 'C.CARD',
	'totalPrice': 300.90,
	'currency': 'USD',
	'cardInfo': '4532',
	'remark': 'Remark'
}
 */
const FN = {
  BOX_NO: 'boxNo',
  ITEM_NO: 'itemNo',
  ITEM_NAME: 'itemTitle',
  QTY: 'itemQty',
  UNIT_VALUE: 'unitPrice',
  HS_CODE: 'hsCode',
  REQ_DATE: 'requestDate',
  RCV_DATE: 'receiveDate',
  TRACKING: 'trackingNo',
  PAY_METHOD: 'payMethod',
  TOTAL_PRICE: 'totalPrice',
  CURRENCY: 'currency',
  CARD_INFO: 'cardInfo',
  REMARK: 'remark',
};

const SN = {
  BOX_NO: 'box_no',
  ITEM_NO: 'item_no',
  ITEM_NAME: 'item_title',
  QTY: 'item_qty',
  UNIT_VALUE: 'unit_price',
  HS_CODE: 'hs_code',
  REQ_DATE: 'requestDate',
  RCV_DATE: 'receiveDate',
  TRACKING: 'trackingNo',
  PAY_METHOD: 'payMethod',
  TOTAL_PRICE: 'totalPrice',
  CURRENCY: 'currency',
  CARD_INFO: 'cardInfo',
  REMARK: 'remark',
};

class BLItemFormManager extends FormManager {
  doNotRenderForm;
  kind;
  hideItemNo;
  totalQty;
  totalPrice;
  onSearch: (value) => void;
  constructor() {
    super({
      prefix: 'bl-item-form',
      fields: [
        {name: FN.BOX_NO, serverName: SN.BOX_NO, label: ''},
        {name: FN.ITEM_NO, serverName: SN.ITEM_NO, label: ''},
        {name: FN.ITEM_NAME, serverName: SN.ITEM_NAME, label: ''},
        {name: FN.QTY, serverName: SN.QTY, label: ''},
        {name: FN.UNIT_VALUE, serverName: SN.UNIT_VALUE, label: ''},
        {name: FN.HS_CODE, serverName: SN.HS_CODE, label: ''},
        {name: FN.REQ_DATE, serverName: SN.REQ_DATE, label: '', type: 'date'},
        {name: FN.RCV_DATE, serverName: SN.RCV_DATE, label: '', type: 'date'},
        {name: FN.TRACKING, serverName: SN.TRACKING, label: ''},
        {name: FN.PAY_METHOD, serverName: SN.PAY_METHOD, label: '', options: paymentOptions, noDefOption: true},
        {name: FN.TOTAL_PRICE, serverName: SN.TOTAL_PRICE, label: ''},
        {name: FN.CURRENCY, serverName: SN.CURRENCY, label: '', options: currencyOptions, noDefOption: true},
        {name: FN.CARD_INFO, serverName: SN.CARD_INFO, label: ''},
        {name: FN.REMARK, serverName: SN.REMARK, label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    if (this.doNotRenderForm === true) {
      return <div className={'mt-20'}/>;
    }
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    _f(FN.ITEM_NAME).onEnter = () => {
      this.onSearch(_v(FN.ITEM_NAME))
      this.setFocus(FN.QTY);
    };
    return (
      <>
        <div className={'ml-20 mt-8 w-full'}>
          <div className={'flex mt-8 mb-8 mr-20 right'}>
            <div className={'mr-20'}>QTY: {this.totalQty??0}</div>
            <div>Total: {util.formatCurrency(this.totalPrice)??0}</div>
          </div>
          <div className={'form-row flex wrap w-full'}>
            {/*{!this.hideItemNo && <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>No.</span></div>}*/}
            <div className={'flex-10 mr-12'}><span className={'f-11 pl-8'}>Title</span></div>
            <div className={'flex-5 mr-12'}><span className={'f-11 pl-8'}>QTY</span></div>
            <div className={'flex-5 mr-12'}><span className={'f-11 pl-8'}>Unit Price</span></div>
            {/*{!this.hideHsCode && <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>HS Code</span></div>}*/}
            <div className={'flex-5 mr-12'}><span className={'f-11 pl-8'}>HS Code</span></div>
            <div className={'flex-1 mr-12'}>{}</div>
          </div>
          <div className={'form-row flex wrap w-full'}>
            {/*{!this.hideItemNo && <div className={'flex-1 mr-12'}>{_r(FN.ITEM_NO)}</div>}*/}
            <div className={'flex-10 mr-12'}>{_r(FN.ITEM_NAME)}</div>
            <div className={'flex-5 mr-12'}>{_r(FN.QTY)}</div>
            <div className={'flex-5 mr-12'}>{_r(FN.UNIT_VALUE)}</div>
            {/*{!this.hideHsCode && <div className={'flex-1 mr-12'}>{_r(FN.HS_CODE)}</div>}*/}
            <div className={'flex-5 mr-12'}>{_r(FN.HS_CODE)}</div>
            <div className={'flex-1 mr-12'}>
              <a href={'/'} onClick={(e) => {
                e.preventDefault();
                this.onSearch(_v(FN.ITEM_NAME));
              }}>
                <Icon size={20} />
              </a>
            </div>
          </div>
        </div>
        {this.kind === 'E' && (
          <div className={'ml-20 w-full'}>
            <div className={'form-row flex wrap w-full'}>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Request Date</span></div>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Received Date</span></div>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Tracking No.</span></div>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Payment</span></div>
            </div>
            <div className={'form-row flex wrap w-full'}>
              <div className={'flex-1 mr-12'}>{_r(FN.REQ_DATE)}</div>
              <div className={'flex-1 mr-12'}>{_r(FN.RCV_DATE)}</div>
              <div className={'flex-1 mr-12'}>{_r(FN.TRACKING)}</div>
              <div className={'flex-1 mr-12'}>{_r(FN.PAY_METHOD)}</div>
            </div>
          </div>
        )}
        {this.kind === 'E' && (
          <div className={'ml-20 w-full'}>
            <div className={'form-row flex wrap w-full'}>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Total Price</span></div>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Currency</span></div>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Card Info.</span></div>
              <div className={'flex-1 mr-12'}><span className={'f-11 pl-8'}>Remark</span></div>
            </div>
            <div className={'form-row flex wrap w-full'}>
              <div className={'flex-1 mr-12'}>{_r(FN.TOTAL_PRICE)}</div>
              <div className={'flex-1 mr-12'}>{_r(FN.CURRENCY)}</div>
              <div className={'flex-1 mr-12'}>{_r(FN.CARD_INFO)}</div>
              <div className={'flex-1 mr-12'}>{_r(FN.REMARK)}</div>
            </div>
          </div>
        )}
      </>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

export default BLItemFormManager;
