/**
 * @flow
 */
import React from 'react';
import {api, util} from '../services/service';
import {useSimpleGridModal} from '../components/SimpleModal';

export default function useVesselHouseModal(onSendEmail) {
  const [queryData, setQueryData] = React.useState();
  function getButtons() {
    return [
      // {label: 'Send', color: 'primary', onClick: () => onSendEmail(queryData)},
      {
        label: 'Send',
        color: 'primary',
        onClick: async () => {
          const rows = modal.grid.apiRef.current.getSelectedRows();
          const length = rows.length - 1;
          let str = ',';
          let houses = '';
          rows.forEach((row, index) => {
            if(length === index) {
              str = '';
            }
            houses += row.bl_id + str;
          })
          onSendEmail(houses);
        },
      },
    ];
  }
  const {id} = queryData ?? {};
  const modal = useSimpleGridModal({
    title: `SEND E-MAIL`,
    centered: true,
    width: 860,
    buttons: getButtons(),
    gridProps: {
      columns: [
        {field: 'bl_id', hide: true},
        {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
        {field: 'bl_no', headerName: 'B/L No.', flex: 1, cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true)},
        {field: 'customer', headerName: 'Customer', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'shipper', headerName: 'Shipper', flex: 1},
        {field: 'consignee', headerName: 'Consignee', flex: 1},
        {field: 'weight_kg', headerName: 'Weight(KG)', flex: 1},
        {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, flex: 1},
      ],
      onAction: async (action, data) => {
      },
      actions: [],
      actionWidth: 50,
      rows: [],
      height: 350,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
      }
    },
  });
  React.useEffect(() => {
    if (queryData && modal.isOpen) {
      api.listCraftHBL(util.toInt(id)).then(res => {
        const {data: rows} = res;
        modal.setRows(rows);
      });
    }
  }, [queryData, modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setQueryData(data);
      modal.open();
    },
  };
}
