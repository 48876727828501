/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from "../media/coship_logo.png";

class InvoiceStatementPrint extends React.Component {
  totalAmount = 0;
  totalBalance = 0;
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountStatementListData, billToData, branchListData, currency} = printData;
    return (
      <div style={{color: 'black'}}>
        <div className={'page-break'}>
          {this.renderHeader(accountStatementListData, billToData, user, currency)}
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'}>Inv. Date</td>
              <td className={'no-border-lr invoice_label'}>Inv. No.</td>
              <td className={'no-border-lr right invoice_label'}>Amount</td>
              <td className={'no-border-lr right invoice_label'}>Balance</td>
            </tr>
            {this.renderInvoiceItems(accountStatementListData)}
            {this.renderEmptyItems(accountStatementListData)}
            </tbody>
          </table>
          <hr />
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            {this.renderInvoiceItemsTotal('TOTAL', util.formatCurrency(this.totalAmount), util.formatCurrency(this.totalBalance))}
            </tbody>
          </table>
          <hr className={'line'} />
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              {this.renderFootInfo(branchListData)}
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  renderHeader(accountData, billToData, user, currency) {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-20 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr style={{height: '20px'}}>
                <td className={'no-border-all right invoice_label'}><h1>STATEMENT</h1></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '15px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100'}>BILL TO :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{billToData.billTo}<br/>{billToData.billToBillAddr}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'invoice_label'}>Total Amount</td>
                <td className={'invoice_label right no-border-lr'}>{currency}</td>
                <td className={'invoice_label right no-border-l'}>{util.formatCurrency(this.totalAmount)}</td>
              </tr>
              <tr>
                <td className={'invoice_label'}>Total Balance</td>
                <td className={'invoice_label right no-border-lr'}>{currency}</td>
                <td className={'invoice_label right no-border-l'}>{util.formatCurrency(this.totalBalance)}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceItems(items) {
    this.totalAmount = 0;
    this.totalBalance = 0;
    return items.map((item, index) => {
      this.totalAmount += item.amount;
      this.totalBalance += item.balance;
      return (
        <tr key={index}>
          <td className={'no-border-all'}>{util.formatD(item['inv_date'])}</td>
          <td className={'no-border-all'}>{item['inv_no']}</td>
          <td className={'no-border-all right'}>{util.formatCurrency(item['amount'])}</td>
          <td className={'no-border-all right'}>{util.formatCurrency(item['balance'])}</td>
        </tr>
      );
    });
  }
  renderEmptyItems(items) {
    const empty = [];
    let length = 54 - items.length;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={4} className={'no-border-all'}>{'\u00a0'}</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceItemsTotal(label, value1, value2) {
    return (
      <tr>
        <td className={'no-border-all'} width={'16%'}>{'\u00a0'}</td>
        <td className={'no-border-all right invoice_label'} width={'40%'}>{label}</td>
        <td className={'no-border-all right invoice_label'} width={'22%'}>{value1}</td>
        <td className={'no-border-all right invoice_label'} width={'22%'}>{value2}</td>
      </tr>
    );
  }
  renderFootInfo(branchLists) {
    return branchLists.map((item, idx) => {
      return (
        <td className={'no-border-all center'}>
          {item.company}<br/>
          {item.address}<br/>
          TEL: {item.phone} {item.fax && `FAX: ${item.fax}`}<br/>
          EMAIL: {item.email}
        </td>
      );
    });
  }
}

export function useInvoiceStatementPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountStatementDataListRef = React.useRef();
  const billToDataRef = React.useRef();
  const branchListRef = React.useRef();
  const currencyRef = React.useRef();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceStatementPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountStatementListData: accountStatementDataListRef.current,
        billToData: billToDataRef.current,
        branchListData: branchListRef.current,
        currency: currencyRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data, billToData, branchLists, currency) => {
      accountStatementDataListRef.current = data;
      billToDataRef.current = billToData;
      branchListRef.current = branchLists;
      currencyRef.current = currency;
      modal.open();
    },
    print,
  };
}

export default InvoiceStatementPrint;
