/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import FormManager from '../../lib/FormManager';
import type {FormField} from '../../components/Form';
import {api, apiAccount, util} from '../../services/service';
import {useUser} from '../../redux/reducers/userReducer';
import {
    FlightSearchInput,
    PortSearchInput,
    TradePartnerSearchInput,
    VesselSearchInput,
    VslVoySearchInput
} from '../../components/SearchInput';
import {BL, FD, FN, SN} from '../field-defs/account';
import {INVOICE as INVOICE_TYPES} from '../field-defs/account';
import type {INVOICE_TYPE} from '../field-defs/account';

const cardStyle = {
    marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

class AccountInvoiceCMFormManager extends FormManager {
    static FN = FN;
    static SN = SN;
    InvoiceType: INVOICE_TYPE;
    onTradeTypeChange: (tradeType: string, portType: string) => void;
    grid;
    oehGrid;
    constructor() {
        super({
            prefix: `account-invoice-form`,
            fields: [],
            formProps: {
                horizontal: true,
                doNotUseButtons: true,
                doNotUseCard: true,
            },
        });
        this.InvoiceType = INVOICE_TYPES.TYPE_CM;
        this.fields = this.getFields();
    }
    getGridData() {
        let grid = this.InvoiceType === 'OEH' ? this.oehGrid : this.grid;
        const rows = [];
        if (grid.apiRef && grid.apiRef.current) {
            grid.apiRef.current.forEachNode(node => rows.push(node.data));
        }
        return rows;
    }
    onRender = () => {
        const {getField: _f, getValue: _v, setValue: _c} = this;
        const user = useUser();

        switch (this.InvoiceType) {
            case INVOICE_TYPES.TYPE_CM: return  <INVOICE {...this.getRenderProps()} />;
            default:
                throw new Error(`Invalid forwarding type (${this.InvoiceType})`);
        }
    };
    onValidate = (values) => {
      const {settlementDate} = values;

      if (!settlementDate || !util.isValidDate(settlementDate)) {
        util.showWarning('Please enter a valid Set. Date');
        return undefined;
      }
      return values; // TODO: 밸리데이션이 필요한 경우 여기서 구현함
    };
    getFields() {
        const fields: FormField[] = [];
        for (const key in FD) {
            if (FD.hasOwnProperty(key)) {
                fields.push(FD[key]);
            }
        }
        return fields.map(i => ({...i, name: `${this.prefix}-${i.name}`}));
    }
    getRenderProps = (grid) => {
        const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
        const [formik, , fields, errors] = this.renderParams;
        return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
    };
}

const INVOICE = (props) => {
  const {_r, _v, _f, _c} = props;
  const user = useUser();
  const branchId = _v(FN.FINANCIAL_OFFICE);
  _f(FN.FINANCIAL_OFFICE).options = api.useAreaOptions();
  _f(FN.BANK_INFO).options = apiAccount.useBankList(branchId);

  React.useEffect(() => {
    if (!branchId && user.branch_id) {
      _c(FN.FINANCIAL_OFFICE, user.branch_id);
    }
  }, [branchId, user.branch_id ?? 0]);

  React.useEffect(() => {
    _f(FN.PARTNER).disabled = true;
    _f(FN.PARTNER).required = false;
    _f(FN.FINANCIAL_OFFICE).disabled = true;
    _f(FN.FINANCIAL_OFFICE).required = false;
  }, []);

  React.useEffect(() => {
    _f(FN.MEMO).type = 'textarea';
  }, []);

    return (
      <>
        <Card style={cardStyle}>
          <CardBody className={'pl-8 pr-20 pt-12 pb-1'}>
            <div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.PARTNER)}</div>
                <div className={'flex-1'}>{_r(FN.SETTLEMENT_DATE)}</div>
                <div className={'flex-1'}>{_r(FN.SETTLEMENT_INVOICE_NO)}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-1'}>{_r(FN.FINANCIAL_OFFICE)}</div>
                <div className={'flex-1'}>{_r(FN.BANK_INFO)}</div>
                <div className={'flex-1'}>{'\u00a0'}</div>
              </div>
              <div className={'flex w-full'}>
                <div className={'flex-3'}>{_r(FN.MEMO)}</div>
              </div>
            </div>
          </CardBody>
        </Card>
      </>
  );
};

export function getAccountFormData(serverData) {
  const {id, partner_name, inv_date, inv_no, branch_id, bank_info, bill_address, memo, period, jamount} = serverData;
  let ret =  {
    formData: {
      [FN.ID]: id,
      [FN.PARTNER]: partner_name,
      [FN.SETTLEMENT_DATE]: util.formatD(inv_date),
      [FN.SETTLEMENT_INVOICE_NO]: inv_no,
      [FN.FINANCIAL_OFFICE]: branch_id,
      [FN.BANK_INFO]: bank_info,
      [FN.BILL_TO]: bill_address,
      [FN.MEMO]: memo,
      [FN.PERIOD]: period,
      },
    gridData: jamount,
  }
  return ret;
}

export function getAccountEntryData(_f, gridData) {
  return {
    id: _f[FN.ID],
    partner_name: _f[FN.PARTNER],
    inv_date: _f[FN.SETTLEMENT_DATE],
    inv_no: _f[FN.SETTLEMENT_INVOICE_NO],
    branchId: util.toInt(_f[FN.FINANCIAL_OFFICE]),
    bankInfo: _f[FN.BANK_INFO],
    memo: _f[FN.MEMO],
    period: _f[FN.PERIOD],
    jamount : gridData
  };
}

export default AccountInvoiceCMFormManager;
