/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions} from '../forms/field-defs/bl';
import {defaultROTitle} from '../forms/print/v2/OIHROPrintForm';

class AIHRelOrder extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return printData;
    }
    const {
      shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, hblNo, hawbNo, mblNo, mawbNo, subMblNo, amsNo, customerRefNo,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, fDestEta, delEta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      title, sendTo, releaseTo, releaseToAddr, instructions,
      // emptyPickup, forwardAgent, partner,
      // doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
    } = printData;
    const {renderCell: _cell, renderNameValue: _nv} = this;
    const markLines = mark.split('\n').length;
    const descLines = desc.split('\n').length;
    const maxLines = markLines > descLines ? markLines : descLines;
    return (
      <div style={{color: 'black', fontSize: 16, marginTop: '1cm'}}>
        <div className={'flex-column center'}>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
          <div>{address}</div>
          <div>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
          <div style={{fontSize: 24, fontWeight: 'bold'}}>{title}</div>
          <div className={'flex-column right w-full'}>
            <div className={'flex'}><div style={{width: 150}}>RELEASE DATE</div> : <div className={'ml-16'} style={{width: 300}}>{util.formatDT(Date.now())}</div></div>
            <div className={'flex'}><div style={{width: 150}}>PRINT BY</div> : <div className={'ml-16'} style={{width: 300}}>{pic} ({util.formatDT(Date.now())})</div></div>
          </div>
          <div className={'flex center mt-8 w-full py-16'} style={{borderTop: '1px solid #000'}}>
            Please retain this copy until cargo is released. If any problems occur or if you have any questions, please contact us.
          </div>
        </div>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            {_cell('To', sendTo, {width: '50%', height: 200})}
            {_cell('Release To', releaseToAddr, {width: '50%', height: 200})}
          </tr>
          <tr>
            <td colSpan={2}><div className={'label f-13'}>B/L INFORMATION</div></td>
          </tr>
          <tr>
            <td colSpan={2} className={'no-border-b'}>
              <div className={'flex'}>
                <div className={'flex-1'}>
                  {_nv('FILING NO.', filingNo)}
                  {_nv('MASTER B/L NO.', mawbNo)}
                  {_nv('HOUSE B/L NO.', hawbNo)}
                  {_nv('SUB B/L NO.', subMblNo)}
                  {_nv('ACI B/L NO.', '')}
                  {_nv('ETA', util.formatD(arrivalDate) + ' ' + arrivalTime)}
                </div>
                <div className={'flex-1'}>
                  {_nv('FLIGHT NO.', flightNo)}
                  {/*{_nv('CONTAINER NO.', containers?.[0]?.['container_no']??'')}*/}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td align={'center'}><div className={'label f-13'}>MARKS & NOS.</div></td>
            <td align={'center'}><div className={'label f-13'}>NO. OF PKGS.</div></td>
            <td align={'center'}><div className={'label f-13'}>DESCRIPTION OF PACKAGES AND GOODS</div></td>
            <td align={'center'}><div className={'label f-13'}>WEIGHT & CBM</div></td>
            <td align={'center'}><div className={'label f-13'}>PORT OF LOADING & ETD</div></td>
          </tr>
          <tr>
            <td className={'no-border-b'}>
              <div className={'value'} style={{whiteSpace: 'pre-line'}}>{mark}</div>
            </td>
            <td className={'no-border-b'}><div className={'value'}>{packageCount} {packageType}</div></td>
            <td className={'no-border-b'}><div className={'value'} style={{whiteSpace: 'pre-line'}}>{desc}</div></td>
            <td className={'no-border-b'} align={'right'}>
              <div className={'value'}>
                G.W: {util.weightForPrint(grossWeightKg, 'KG')}<br/>
                C.W: {util.weightForPrint(chargeableWeightKg, 'KG')}<br/>
              </div>
            </td>
            <td className={'no-border-b'}><div className={'value'}>{polFull}<br/>{util.formatD(flightDate)} {flightTime}</div></td>
          </tr>
          {this.renderBlankLine(20 - maxLines)}
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tr>
            <td><div className={'label f-13'}>SPECIAL INSTRUCTIONS</div></td>
          </tr>
          <tr>
            <td height={300} style={{whiteSpace: 'pre-line'}}>{instructions}</td>
          </tr>
        </table>
      </div>
    );
  }
  renderCell(label, value, tdProps, extraLabel, extraValue, extraHalf) {
    if (extraLabel) {
      return (
        <td width={'50%'}>
          <div className={'flex between'}>
            <div className={extraHalf ? 'flex-1' : 'flex-2'}>
              <div className={'label f-13'}>{label}</div>
              <div className={'value'}>{value ? value : '\u00a0'}</div>
            </div>
            <div className={'flex-1'}>
              <div className={'label f-13 pl-12'} style={{borderLeft: '1px solid #000'}}>{extraLabel}</div>
              <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{extraValue ? extraValue : '\u00a0'}</div>
            </div>
          </div>
        </td>
      );
    } else {
      return (
        <td {...tdProps}>
          <div className={'label f-13'}>{label}</div>
          <div className={'value'} style={{whiteSpace: 'pre-line'}}>{value ? value : '\u00a0'}</div>
        </td>
      );
    }
  }
  renderBlankLine(count) {
    const res = [];
    for (let i = 0; i < count; ++i) {
      res.push(
        <tr key={i}>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
        </tr>
      );
    }
    return res;
  }
  renderNameValue(name, value) {
    return (
      <div className={'flex py-1'}>
        <div className={'w-128'}>{name}</div>
        <div className={'mr-16'}>:</div>
        <div className={'value'}>{value}</div>
      </div>
    );
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers, serverData} = blData;
    const {shipperAddr = '', consigneeAddr = '', forwardingAgentAddr = '', notifyAddr = '', cBrokerAddr = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = '', exportRefNo = '', hblNo, hawbNo, mblNo, mawbNo, subMblNo, amsNo, customerRefNo = ''} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime} = jvessel;
    const {polLabel, podLabel, fDestEta, delEta} = jroute;
    const {route} = serverData;
    // const {emptyPickup, forwardAgent, partner} = bladdress;
    // const {doc, pickupDate, portOpen, rail, vgm} = cutoff;
    const {delivery, final, podFull, polFull, porFull} = route;
    let {freight, orgBlType, svcTerm1, svcTerm2, package: packageCount, packageType, grossWeightKg, chargeableWeightKg} = jshipment;
    const {description: desc, mark, itNo, date: itDate, itLoc, pubRemark} = jmark ?? {};
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.labelFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.labelFromOptions(svcTerm2, svcTypeOptions);
    const blankLines = [];
    const blankCount = 10 - containers?.length??0;
    for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    const {title = defaultROTitle, sendTo, releaseTo, releaseToAddr, instructions} = printFormData;
    return {
      shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr, exportRefNo, cBrokerAddr,
      bkgNo, filingNo, b13No, hblNo, hawbNo, mblNo, mawbNo, subMblNo, amsNo, customerRefNo,
      carrier, vslVoy, onboard, etd, eta, flightNo, flightDate, flightTime, arrivalDate, arrivalTime, finalEtaDate, finalEtaTime,
      polLabel, podLabel, fDestEta, delEta,
      desc, mark, itNo, itDate, itLoc, pubRemark,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packageCount, packageType, grossWeightKg, chargeableWeightKg,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      title, sendTo, releaseTo, releaseToAddr, instructions,
      // emptyPickup, forwardAgent, partner,
      // doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
    };
  }
}

export default AIHRelOrder;
