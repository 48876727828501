/**
 * @flow
 */
import React from 'react';
import useMovingAccountListGridView from "../../../../grids/newCoship/moving/house/useMovingAccountListGridView";
import {useSelector} from "react-redux";
import useAccountPaymentModal from "../../../../modals/account/useAccountPaymentModal";

interface MovingHouseBLAccountListProps {
  isShowList: boolean;
  onSearch: any;
}

const MovingHouseBLAccountList = (props: MovingHouseBLAccountListProps) => {
  const {isShowList, onSearch} = props;
  const {grid, accountPaymentModal} = useMovingHouseBLAccountList(isShowList, onSearch);

  return (
    <div>
      {isShowList && grid.render()}
      {accountPaymentModal.render()}
    </div>
  );
};

function useMovingHouseBLAccountList(isShowList, onSearch) {
  const {accountList} = useSelector(state => state['newCoship']);
  const accountPaymentModal = useAccountPaymentModal(onSearch);
  const grid = useMovingAccountListGridView((action, data) => {
    if (action === 'commission') {
      const {account_id, inv_no: invoiceNo} = data;
      accountPaymentModal.open(account_id ?? 0, invoiceNo ?? '');
    }
  });

  React.useEffect(() => {
    isShowList && grid.setRows(accountList);
  }, [isShowList, accountList]);

  return {grid, accountPaymentModal};
}

export default MovingHouseBLAccountList;
