/**
 * @flow
 */
import type {CargoModalTabInfo} from '../components/CargoFormModal';

export type CargoType = 'Moving' | 'Courier' | 'Forwarding' | 'eCommerce';

export type CourierType = 'M' | 'A' | 'U' | 'C';
export type ECommerceType = 'B' | 'D';

export interface UseTabInfo extends CargoModalTabInfo {
  serverData?: Object;
}

export const TAB_CUSTOMER = 'customer';
export const TAB_ACCOUNT = 'account';
export const TAB_WORK_ORDER = 'work';
export const TAB_CONSIGNEE = 'consignee';
export const TAB_SHIPPING = 'shipping';
export const TAB_MESSAGES = 'messages';
export const TAB_ITEMS = 'items';
export const TAB_TRACKING = 'tracking';

export const CARGO_MOVING = 'Moving';
export const CARGO_COURIER = 'Courier';
export const CARGO_ECOMMERCE = 'eCommerce';
export const CARGO_FORWARDING = 'Forwarding';

export const COURIER_MAPLEBOX = 'M';
export const COURIER_AIR = 'A';
export const COURIER_UPS = 'U';
export const COURIER_CPOST = 'C';

export const courierTypes = {
  [COURIER_MAPLEBOX]: 'MAPLEBOX',
  [COURIER_AIR]: 'AIR',
  [COURIER_UPS]: 'UPS',
  [COURIER_CPOST]: 'CANADA POST',
};

export const ECOMMERCE_BUY_DELIVERY = 'B';
export const ECOMMERCE_DELIVERY_ONLY = 'D';

export const eCommerceTypes = {
  [ECOMMERCE_BUY_DELIVERY]: 'BUY & DELIVERY',
  [ECOMMERCE_DELIVERY_ONLY]: 'DELIVERY ONLY',
};
