/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";
import {FN} from "../field-defs/account";
import {util} from "../../services/service";

class AccountDCNoteDetailFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'account-dc-note-detail',
      fields: [
        {name: FN.CAD, serverName: FN.CAD, label: FN.CAD},
        {name: FN.USD, serverName: FN.USD, label: FN.USD},
        {name: FN.KRW, serverName: FN.KRW, label: FN.KRW},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  setCurrency(data = ['CAD', 'USD', 'KRW']) {
    const {getField: _f} = this;
    _f(FN.CAD).disabled = !data?.includes(FN.CAD) ?? false;
    _f(FN.USD).disabled = !data?.includes(FN.USD) ?? false;
    _f(FN.KRW).disabled = !data?.includes(FN.KRW) ?? false;
  }

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;

    React.useEffect(() => {
      if(!_v(FN.CAD)) {
        _c(FN.CAD, 1);
      }
      if(!_v(FN.USD)) {
        _c(FN.USD, 1);
      }
      if(!_v(FN.KRW)) {
        _c(FN.KRW, 1);
      }
    }, []);

    return (
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-12'}>{_r(FN.CAD)}</div>
        <div className={'flex-1 mr-12'}>{_r(FN.USD)}</div>
        <div className={'flex-1 mr-12'}>{_r(FN.KRW)}</div>
      </div>
    );

  };
  onValidate = (values) => {
    const {CAD, USD, KRW} = values;
    if(isNaN(CAD) || CAD <= 0 || isNaN(USD) || USD <= 0 || isNaN(KRW) || KRW <= 0) {
      util.showWarning('Please enter a valid currency data!');
      return undefined;
    }
    return values;
  };
}

export default AccountDCNoteDetailFormManager;
