/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import AEMManifest from '../../../print-layouts/AEMManifest';
import {usePrintModal} from './usePrintModal';
import {getBLEntryData} from "../../field-defs/bl";
import {apiBL} from "../../../services/service";
import {CheckBoxField} from "../../../shared/components/form/CheckBox";
import {DefaultButton} from "../../../components/buttons";

const FN = {
  MAWB_NO: 'mawbNo',
  COMPANY_NAME: 'companyName',
  MASTER_AGENT: 'masterAgent',
  REPORT_OPTION: 'reportOption',
  MASTER_AGENT_SHIPPER: 'masterAgentShipper',
  HOUSE_AGENT: 'houseAgent',
  WEIGHT_OPTION: 'weightOption',
  CHARGEABLE: 'chargeable',
  GROSS: 'gross',
  REMARK: 'remark',
};
const SN = {
  MAWB_NO: 'mawbNo',
  COMPANY_NAME: 'companyName',
  MASTER_AGENT: 'masterAgent',
  REPORT_OPTION: 'reportOption',
  MASTER_AGENT_SHIPPER: 'masterAgentShipper',
  HOUSE_AGENT: 'houseAgent',
  WEIGHT_OPTION: 'weightOption',
  CHARGEABLE: 'chargeable',
  GROSS: 'gross',
  REMARK: 'remark',
};
const reportTypeOptions = [
  {value: 'masterAgentShipper', label: 'Master Agent (Shipper)'},
  {value: 'houseAgent', label: 'House Agent'},
];
const weightTypeOptions = [
  {value: 'chargeable', label: 'Chargeable'},
  {value: 'gross', label: 'Gross'},
];

export class AEMManifestPrintForm extends FormManager {
  static FN = FN;
  static SN = SN;
  houseData;
  newHouseData;
  constructor() {
    super({
      prefix: 'print-aem',
      fields: [
        {name: FN.MAWB_NO, serverName: SN.MAWB_NO, label: 'MAWB No.', disabled: true},
        {name: FN.COMPANY_NAME, serverName: SN.COMPANY_NAME, label: ''},
        {name: FN.MASTER_AGENT, serverName: SN.MASTER_AGENT, label: '', type: 'textarea', disabled: true},
        {name: FN.REPORT_OPTION, serverName: SN.REPORT_OPTION, label: '', options: reportTypeOptions, noDefOption: true, smallMargin: true, onChange: changeReport},
        {name: FN.WEIGHT_OPTION, serverName: SN.WEIGHT_OPTION, label: '', options: weightTypeOptions, noDefOption: true, smallMargin: true, onChange: changeWeight},
        // {name: FN.CHARGEABLE, serverName: SN.WEIGHT_OPTION, label: 'Chargeable', radioName: FN.WEIGHT_OPTION, onChange: changeWeight, type: 'radio'},
        // {name: FN.GROSS, serverName: SN.WEIGHT_OPTION, label: 'Gross', radioName: FN.WEIGHT_OPTION, type: 'radio'},
        {name: FN.REMARK, serverName: SN.REMARK, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const [mode, setMode] = React.useState('new');
    const [checkList, setCheckList] = React.useState({});
    const {renderForm: _f, renderField: _r, getValue: _v, setValue: _c, _n} = this;
    const reportOption = _v(FN.REPORT_OPTION);
    const weightOption = _v(FN.WEIGHT_OPTION);
    const houseList = [];
    const partnerList = [];
    const partnerAllList = [];
    let partners = {
      partner: '',
      partner_id: 0
    };
    if(this.houseData) {
      this.houseData.map((house) => {
        partners.partner = house.partner;
        partners.partner_id = house.partner_id;
        houseList.push(partners);
      });
    }
    const newHouseData = [...new Set(houseList)];

    React.useEffect(() => {
      if(!reportOption) {
        _c(FN.REPORT_OPTION, FN.MASTER_AGENT_SHIPPER);
      }
    }, [reportOption]);
    React.useEffect(() => {
      if(!weightOption) {
        _c(FN.WEIGHT_OPTION, FN.GROSS);
      }
    }, [weightOption]);
    React.useEffect(() => {
      if(mode === 'clear') {
        partnerAllList.length = 0;
        this.newHouseData = partnerAllList;
      } else if(mode === 'all') {
        this.newHouseData = partnerAllList;
      }
    }, [partnerAllList]);
    const onClickedAll = () => {
      this.newHouseData = partnerAllList;
      setMode('all');
    };
    const onClear = () => {
      partnerAllList.length = 0;
      this.newHouseData = partnerAllList;
      setMode('clear');
    };

    return (
      <div className={'w-full'}>
        <div style={{marginLeft: -36}}>{_r(FN.MAWB_NO)}</div>
        <div>Company Name on Report</div>
        <div>{_r(FN.COMPANY_NAME)}</div>
        <div>Master Agent</div>
        <div>{_r(FN.MASTER_AGENT)}</div>
        <div>Report Type</div>
        <div>{_r(FN.REPORT_OPTION)}</div>
        {(reportOption === FN.MASTER_AGENT_SHIPPER) && (
        <div>
          <div>Weight Option (If, Master Agent)</div>
          <div>{_r(FN.WEIGHT_OPTION)}</div>
        </div>)}
        <div className="d-flex">
          <div className="mr-auto p-2">Select House Agents</div>
          <div className="p-2"><DefaultButton label={'All'} size={'sm'} onClick={onClickedAll} /></div>
          <div className="p-2"><DefaultButton label={'Clear'} size={'sm'} onClick={onClear} /></div>
        </div>
        {newHouseData?.filter((data) => data.partner_id > 0).map((data, idx) => {
          const key = data.partner_id;
          partnerAllList.push(key);
          if(mode === 'new') {
            this.newHouseData = partnerAllList;
          }
          // partnerList.push(key);
          // this.newHouseData = partnerAllList;
          if(mode === 'clear') {
           checkList[key] = false;
          } else if(mode === 'all') {
           checkList[key] = true;
          }
            return (
              <CheckBoxField
                key={key}
                onChange={e => {
                  setMode('change');
                  const {target: {checked}} = e;
                  checkList[key] = checked;
                  setCheckList({...checkList});
                  if(checked) {
                    partnerList.push(key);
                  } else {
                    const index = partnerList.indexOf(key);
                    if (index > -1) {
                      partnerList.splice(index, 1);
                    }
                  }
                  this.newHouseData = partnerList;
                }}
                value={checkList[key] ?? true}
                name={`partner-${data.partner_id}`}
                label={data.partner}
              />
            );
        })}
        <div>Remark</div>
        <div>{_r(FN.REMARK)}</div>
      </div>
    );
  };
}

const blPrintForm = new AEMManifestPrintForm();

const changeReport = (name, value)=> {
  blPrintForm.setValue(FN.REPORT_OPTION, value);
};

const changeWeight = (name, value) => {
  blPrintForm.setValue(FN.WEIGHT_OPTION, value);
};

export function useAEMManifestPrintModal(blForm, houseData) {
  const onOpen = () => {
    const values = blForm.getValues();
    const blData = getBLEntryData(true, values);
    const consignee = `${blData.jcustomer.consignee}\n${blData.jcustomer.consigneeAddr}`;
    blPrintForm.houseData = houseData;
    blPrintForm.setValue(FN.MAWB_NO, blData.blNo);
    blPrintForm.setValue(FN.MASTER_AGENT, consignee);
  };
  const onLoadPrintData = async (blData, blFormData) => {
    const isGross = blPrintForm.getValue(FN.WEIGHT_OPTION) === FN.CHARGEABLE ? false : true;
    const dataInfo = {blNo: blData.blNo, printGrossKG: isGross, partners: blPrintForm.newHouseData??[]}
    const {data} = await apiBL.getManifestPrintData(dataInfo);
    return {blManifest: data?.[0] ?? {}};
  };
  return usePrintModal(true, 'Cargo Manifest Print', 1024, blForm, blPrintForm, AEMManifest, onOpen, onLoadPrintData, 700, false);
}