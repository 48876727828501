/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import MarkFormManager from './MarkFormManager';

const FN = {
  HANDLING_INFO: 'handlingInfo',
  ACCOUNT_INFO: 'accountInfo',
  MARK: 'mark',
  GOODS: 'goods',
  SHOW_AS: 'showAs',
  PO_NO: 'poNo',
  ITEM_NO: 'itemNo',
};

const LB = {

};

class MarkAEMFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  constructor() {
    super({
      prefix: 'mark-AEM',
      fields: [
        {name: FN.SHOW_AS, serverName: FN.SHOW_AS, label: '', options: showAsOptions, noDefOption: true},
        {name: FN.HANDLING_INFO, serverName: FN.HANDLING_INFO, label: '', type: 'textarea'},
        {name: FN.ACCOUNT_INFO, serverName: FN.ACCOUNT_INFO, label: '', type: 'textarea'},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.GOODS, serverName: FN.GOODS, label: '', type: 'textarea'},
        {name: FN.PO_NO, serverName: FN.PO_NO, label: '', type: 'textarea'},
        {name: FN.ITEM_NO, serverName: FN.ITEM_NO, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l, getValue: _v, setValue: _c} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'pt-8'}><b>Show Weight on B/L as</b></div>
          <div className={'w-96 ml-8'}>{_r(FN.SHOW_AS)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Handling Information')}
            {_r(FN.HANDLING_INFO)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Account Info.')}
            {_r(FN.ACCOUNT_INFO)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Nature and Quantity of Goods')}
            {_r(FN.GOODS)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('P/O No.')}
            {_r(FN.PO_NO)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Item No.')}
            {_r(FN.ITEM_NO)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const showAsOptions = [
  {value: 'KG', label: 'KG'},
  {value: 'LB', label: 'LB'},
];

const form = new MarkAEMFormManager();

export function useMarkAEMModal(onSave) {
  return useSimpleEditModal({
    title: 'AEM Mark & Desc.',
    width: 1024,
    form,
    onSave,
  });
}

export default MarkAEMFormManager;
