/**
 * @flow
 */
import React from 'react';
import {useSelector} from 'react-redux';
import {api} from '../../services/service';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_RESET = 'USER_RESET';

export function updateUser(dispatch, user) {
  dispatch({type: USER_UPDATE, user});
}

export function resetUser(dispatch) {
  dispatch({type: USER_RESET});
}

export interface UserState {
  id: number;
  login_id: string;
  uname: string;
  auth: number;
  branch_id: number;
  token: string;
  menu: [string, [string, string][]][];
  currency: string;
  first_url: string;
  isManager: boolean;
  team_main: string;
  print: Object;
}

export function useUser(): UserState {
  return useSelector(state => state['user']);
}

/**
 * <ol>
 *   <li>브랜치 목록을 서버에서 로드</li>
 *   <li>브랜치가 정의되어 정의되어 있지 않은 경우 현재 로그인한 사용자의 브랜치를 기본값으로 설정</li>
 *   <li>searchForm#onSearch() 함수를 호출하여 쿼리를 수행할수 있도록함</li>
 *   <li>약간의 딜레이를 준후(1000 msec) readyToSearch 값을 true 로 설정하여 반환</li>
 *   <li>언마운트 시 검색 폼의 값을 저장하여 다음 마운트시 검색 값들을 복구해줌</li>
 * </ol>
 */
export function useUserBranch(searchForm, nameOfBranchId = 'branchId', callback, addAllAndSetDefault = false) {
  const [ready, setReady] = React.useState(false);
  const user = useUser();
  const branchId = searchForm.getValue(nameOfBranchId);
  const branchField = searchForm.getField(nameOfBranchId);
  const options = api.useAreaOptions(true);
  if (addAllAndSetDefault === true) {
    if (options?.length > 0 && options[0].label !== 'ALL') {
      options.unshift({value: '', label: 'ALL'});
    }
  }
  branchField.options = options;
  React.useEffect(() => {
    if (user.id > 0 && branchField.options.length > 0) {
      if (!branchId) {
        // const team = user['team_main'];
        // const service = (team === 'A' || team === 'N' || team === 'S') ? 'M' : team;
        const {branchId: cachedBranchId} = searchForm.cachedValues ?? {};
        const values = {
          //kind: service,
          ...searchForm.cachedValues, // 저장되어 있는 검색 옵션 값들을 복구
          // branchId 설정순서: 캐시에 저장된 branchId -> 폼에 저장된 branchId -> 사용자의 branchId
          branchId: addAllAndSetDefault === true ? undefined : (cachedBranchId ?? branchId ?? user.branch_id),
        };
        searchForm.setValuesFast(values); // 폼 필드 값들을 업데이트함
        // 서치가 업데이트 된 값으로 샐행될수 있도록하기 위해서 타임아웃을 활용함!
        setTimeout(() => callback ? callback() : searchForm.onSearch(), 0);
        // 초기에 쿼리가 여러번 서버로 전송되는 것을 방지하기 위해 딜레이를 줌!
        setTimeout(() => setReady(true), 1000);
      }
    }
  }, [user.id, branchId, branchField.options]);
  React.useEffect(() => {
    return () => {
      searchForm.cachedValues = searchForm.getValues();
    };
  }, []);
  return {readyToSearch: ready, user};
}

export default function userReducer(state: UserState = {}, action) {
  const {type, user} = action;
  switch (type) {
    case USER_UPDATE:
      return {...user};
    case USER_RESET:
      return {};
    default:
      return state;
  }
}
