/**
 * @flow
 */
import React from 'react';
import useStaffListGridView from "../../grids/staff/useStaffListGridView";
import useStaffEditModal from "../../modals/staff/useStaffEditModal";
import {PrimaryButton} from "../../components/buttons";
import {api, util} from "../../services/service";
import type {QueryListParams} from "../../services/API";

const StaffList = () => {
  const {grid, onAdd, editModal} = useStaffList();
  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'ADD STAFF'} onClick={onAdd} />
        </div>
        {grid.render()}
      </div>
      {editModal.render()}
    </div>
  );
};

function useStaffList() {
  const grid = useStaffListGridView((action, data) => {
    if (action === 'edit') {
      editModal.open('edit', data);
    }
  });
  const onSearch = () => {
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
    };
    grid.query(listParam);
  };
  const onSave = (mode, data) => {
    const validPassword = mode === 'add' ? !data.password : false;
    if (data === undefined || (!data.areaId || !data.auth || !data.jobTitle || validPassword || !data.loginId || !data.userName || !data.userName2 || !data.email || !data.cphone)) {
      return util.showWarning(`Please fill all the required fields`);
    }
    const payload = {
      ...data,
      isActive: data.isActive === true || data.isActive?.[0] === 'on',
    };
    let res;
    if (mode === 'edit') {
      res = api.staffEdit(payload);
    } else {
      res = api.staffAdd(payload);
    }
    if (res) {
      util.showSuccess('Staff has been saved successfully!');
      editModal.close();
    }
  };
  const editModal = useStaffEditModal(async (mode, data) => {
    await onSave(mode, data);
  });
  const onAdd = () => {
    editModal.open('add', {});
  };
  React.useEffect(() => {
    onSearch();
  }, [editModal.isOpen]);
  return {grid, onAdd, editModal};
}

export default StaffList;
