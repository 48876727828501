/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import MarkFormManager from './MarkFormManager';

const FN = {
  CCN: 'ccn',
  MANIFEST_FORM: 'manifestForm',
  TO_A: 'toA',
  MARK: 'mark',
  DESCRIPTION: 'description',
  REMARK: 'remark',
  CFS_REMARK: 'cfsRemark',
};

const LB = {

};

class MarkOIMFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  constructor() {
    super({
      prefix: 'mark-OIM',
      fields: [
        {name: FN.CCN, serverName: FN.CCN, label: 'CCN'},
        {name: FN.MANIFEST_FORM, serverName: FN.MANIFEST_FORM, label: 'Manifest Form'},
        {name: FN.TO_A, serverName: FN.TO_A, label: 'To / A'},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: '', type: 'textarea'},
        {name: FN.REMARK, serverName: FN.REMARK, label: '', type: 'textarea'},
        {name: FN.CFS_REMARK, serverName: FN.CFS_REMARK, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          {_r(FN.CCN)}
          {_r(FN.MANIFEST_FORM)}
          {_r(FN.TO_A)}
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Description')}
            {_r(FN.DESCRIPTION)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1'}>
            {_l('Remark')}
            {_r(FN.REMARK)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1'}>
            {_l('CFS Remark')}
            {_r(FN.CFS_REMARK)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const form = new MarkOIMFormManager();

export function useMarkOIMModal(onSave) {
  return useSimpleEditModal({
    title: 'OIM Mark & Desc.',
    width: 1024,
    form,
    onSave,
  });
}

export default MarkOIMFormManager;
