/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {usePrintModal} from './usePrintModal';
import {getBLEntryData} from '../../field-defs/bl';
import {apiBL} from '../../../services/service';
import {useUser} from '../../../redux/reducers/userReducer';
import AEMLabel from '../../../print-layouts/AEMLabel';

const FN = {
  MAWB_NO: 'mawbNo',
  HAWB_NO: 'hawbNo',
  PIECES_MASTER: 'piecesMaster',
  PIECES_HOUSE: 'piecesHouse',
  HEADER_TYPE: 'headerType',
  SHOW_CARRIER: 'showCarrier',
  SHOW_NUMBER: 'showNumber',
  ShOW_HAWB_NO: 'showHawbNo',
  FORWARDER_NAME: 'forwarderName',
  LABELS: 'labels',
};

export class AEMLabelPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'label-aem',
      fields: [
        {name: FN.MAWB_NO, serverName: FN.MAWB_NO, label: 'MAWB No.', readonly: true},
        {name: FN.HAWB_NO, serverName: FN.HAWB_NO, label: 'HAWB No.', readonly: true},
        {name: FN.PIECES_MASTER, serverName: FN.PIECES_MASTER, label: 'Master Pieces'},
        {name: FN.PIECES_HOUSE, serverName: FN.PIECES_HOUSE, label: 'House Pieces'},
        {name: FN.SHOW_CARRIER, serverName: FN.SHOW_CARRIER, label: 'Show Carrier Name', type: 'checkbox', defaultValue: true},
        {name: FN.SHOW_NUMBER, serverName: FN.SHOW_NUMBER, label: 'Show Total House Pieces', type: 'checkbox', defaultValue: true},
        {name: FN.ShOW_HAWB_NO, serverName: FN.ShOW_HAWB_NO, label: 'Show HAWB No.', type: 'checkbox', defaultValue: true},
        {name: FN.FORWARDER_NAME, serverName: FN.FORWARDER_NAME, label: 'Forwarder Name'},
        {name: FN.LABELS, serverName: FN.LABELS},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.MAWB_NO)}
        {/*{_r(FN.HAWB_NO)}*/}
        {_r(FN.PIECES_MASTER)}
        {/*{_r(FN.PIECES_HOUSE)}*/}
        {_r(FN.SHOW_CARRIER)}
        {_r(FN.SHOW_NUMBER)}
        {_r(FN.ShOW_HAWB_NO)}
        {_r(FN.FORWARDER_NAME)}
      </div>
    );
  };
}

const labelPrintForm = new AEMLabelPrintForm();

export function useAEMLabelPrintModal(blForm) {
  const user = useUser();
  const onOpen = async () => {
    const values = blForm.getValues();
    const blData = getBLEntryData(false, values);
    const {filingNo, jcommon} = blData;
    const {mawbNo} = jcommon;
    const {data} = await apiBL.getLabelPrintData({blNo: mawbNo, filingNo, isAir: true});
    if (data?.length === 1) {
      const {mblNo, totalmaster: pieces, destination, origin, labels} = data[0];
      labelPrintForm.setValuesFast({
        piecesMaster: pieces, piecesHouse: labels.length, mawbNo: mblNo, destination, origin, labels, forwarderName: user.print.company,
      });
    }
  };
  return usePrintModal(false, 'PACKAGE LABEL', 1024, blForm, labelPrintForm, AEMLabel, onOpen);
}
