/**
 * @flow
 */
import React from 'react';

const Home = () => {
  return <p>Page Not Found</p>;
};

export default Home;
