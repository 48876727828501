/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {useSelector} from "react-redux";
import {FD, FN} from '../../../field-defs/newCoship';
import {
  FlightSearchInput,
  PortSearchInput,
  TradePartnerSearchInput,
  VslVoySearchInput
} from "../../../../components/SearchInput";

class MovingMasterBLMblFormManager extends FormManager {
  isShowForm;
  constructor() {
    super({
      prefix: `moving-master-mbl-form`,
      fields: [
        FD.SHIPPER_ID, FD.SHIPPER, FD.SHIPPER_ADDR, FD.CONSIGNEE_ID, FD.CONSIGNEE, FD.CONSIGNEE_ADDR, FD.NOTIFY_ID, FD.NOTIFY, FD.NOTIFY_ADDR,
        FD.SAME_AS_CONSIGNEE, FD.CONTAINER_NO, FD.CONTAINER_TYPE, FD.SEAL_NO, FD.CBM, FD.WEIGHT, FD.GROSS_WEIGHT_KG, FD.PACKAGE, FD.CONTAINER_SEQ, FD.POD, FD.ETA,
        FD.CRAFT_ID, FD.VSL_VOY, FD.POL, FD.ETD, FD.DEPARTURE, FD.DESTINATION, FD.DEPARTURE_FLIGHT, FD.DESTINATION_FLIGHT, FD.FLIGHT_DATE, FD.FLIGHT_TIME,
        FD.ARRIVAL_DATE, FD.ARRIVAL_TIME, FD.COMMODITY, FD.CARRIER, FD.FLIGHT_NO, FD.CHARGEABLE_WEIGHT,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, setValue: _c, getValue: _v, getField: _f, _n} = this;
    const {carrierType} = useSelector(state => state['newCoship']);
    const [formik, , fields, errors] = this.renderParams;
    const sameAsConsignee = _v(FN.SAME_AS_CONSIGNEE);
    _f(FN.SHIPPER).label = 'Shipper';
    _f(FN.SHIPPER_ADDR).label = 'Address';
    _f(FN.SHIPPER_ADDR).type = 'textarea';
    _f(FN.CONSIGNEE).label = 'Consignee';
    _f(FN.CONSIGNEE_ADDR).label = 'Address';
    _f(FN.CONSIGNEE_ADDR).type = 'textarea';
    _f(FN.NOTIFY).label = 'Notify Party';
    _f(FN.NOTIFY_ADDR).type = 'textarea';

    const _pt = (name, vertical = true) => <PortSearchInput
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical={vertical}
    />;

    const _tp = (name, vertical = true) => <TradePartnerSearchInput
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical={vertical}
    />;

    const _vsl = (name, vertical = true) => <VslVoySearchInput
      idField={_n('craftId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onVSL(name, data)}
    />

    const _flt = (name, vertical = true) => <FlightSearchInput
      idField={_n('craftId')} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onFLT(name, data)}
    />

    const _tpa = (name, partner, vertical = true) => {
      _f(name).smallMargin = name !== FN.PARTNER ? true : undefined;
      return (
        <TradePartnerSearchInput
          idField={_n(`${partner}Id`)} displayField={_n(name)}
          formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onTPartner(partner, data)}
        />
      );
    };

    const onTPartner = (name, data) => {
      const {id, value, Address} = data;
      _c(`${name}`, value);
      _c(`${name}Id`, id);
      _c(`${name}Addr`, Address);
    };

    const onVSL = (name, data) => {
      const {id: craftId, etd_date: etd, eta_date: eta, departure: polLabel, destination: podLabel, value: vslVoy} = data;
      const values = {craftId, vslVoy, etd, eta, polLabel, podLabel};
      setValues(values);
    };

    const onFLT = (name, data) => {
      const {
        id: craftId,
        carrier_name: carrier, carrier_id: carrierId,
        craft_no: flightNo,
        departure, destination,
        etd_date: flightDate, eta_date: arrivalDate,
        etd_time: flightTime, eta_time: arrivalTime,
        jextra: {
          etaOfFpoe, finalEta,
          firstTo,
          trans1To, trans1ToFlight,
          trans2To, trans2ToFlight,
          trans3To, trans3ToFlight,
          billingCarrier, billingCarrierId,
          issuingCarrier, issuingCarrierId,
        },
      } = data;
      const values = {
        craftId,
        carrier, carrierId,
        flightNo,
        departure, destination,
        flightDate,
        flightTime,
        arrivalDate,
        arrivalTime,
        etaOfFpoe, finalEta,
        firstTo,
        trans1To, trans1ToFlight,
        trans2To, trans2ToFlight,
        trans3To, trans3ToFlight,
        billingCarrier, billingCarrierId,
        issuingCarrier, issuingCarrierId,
        etdDate: flightDate,
        etdTime: flightTime,
        etaDate: arrivalDate,
        etaTime: arrivalTime,
        customerEtd: flightDate,
        customerEta: arrivalDate,
      };
      setValues(values);
    };

    const setValues = (data) => {
      const values = {};
      const prevValues = {};
      for (const [name, value] of Object.entries(data)) {
        values[_n(name)] = value;
      }
      for (const [name, value] of Object.entries(this.getValues())) {
        prevValues[_n(name)] = value;
      }
      formik.setValues({...prevValues, ...values});
    };

    React.useEffect(() => {
      _f(FN.SAME_AS_CONSIGNEE).onChange = (name, value, e) => {
        const isChecked = e.target.checked;
        const consigneeLocal = isChecked ? _v(FN.CONSIGNEE_LOCAL) : undefined;
        const consignee = isChecked ? _v(FN.CONSIGNEE) : undefined;
        const consigneeId = isChecked ? _v(FN.CONSIGNEE_ID) : undefined;
        const consigneeAddr = isChecked ? _v(FN.CONSIGNEE_ADDR) : undefined;
        const consigneeEmail = isChecked ? _v(FN.CONSIGNEE_EMAIL) : undefined;
        const consigneePhone1 = isChecked ? _v(FN.CONSIGNEE_PHONE1) : undefined;
        _c(FN.NOTIFY_LOCAL, consigneeLocal);
        _c(FN.NOTIFY, consignee);
        _c(FN.NOTIFY_ID, consigneeId);
        _c(FN.NOTIFY_ADDR, consigneeAddr);
        _c(FN.NOTIFY_EMAIL, consigneeEmail);
        _c(FN.NOTIFY_PHONE, consigneePhone1);
      };
    }, [sameAsConsignee]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-8 pr-20 pt-12 pb-1'}>
            {carrierType === 'O' && <OCEAN _r={this.renderField} _pt={_pt} _vsl={_vsl} _tpa={_tpa}/>}
            {carrierType === 'A' && <AIR _r={this.renderField} _pt={_pt} _tp={_tp} _flt={_flt} _tpa={_tpa}/>}
            {carrierType === 'G' && <GROUND _r={this.renderField} _pt={_pt} _tpa={_tpa}/>}
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

const OCEAN = (props) => {
  const {_r, _pt, _vsl, _tpa} = props;
  return (
    <>
      <div className={'flex'}>
        <div className={'flex flex-4'}>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.SHIPPER, 'shipper')}</div>
            <div>{_r(FN.SHIPPER_ADDR)}</div>
          </div>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.CONSIGNEE, 'consignee')}</div>
            <div>{_r(FN.CONSIGNEE_ADDR)}</div>
          </div>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.NOTIFY, 'notify')}</div>
            <div>{_r(FN.NOTIFY_ADDR)}</div>
            <div>{_r(FN.SAME_AS_CONSIGNEE)}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'w-full'}>
            <div>{_r(FN.CONTAINER_NO)}</div>
            <div>{_r(FN.CONTAINER_TYPE)}</div>
            <div>{_r(FN.SEAL_NO)}</div>
            <div>{_r(FN.CBM)}</div>
            <div>{_r(FN.GROSS_WEIGHT_KG)}</div>
            <div>{_r(FN.PACKAGE)}</div>
          </div>
        </div>
      </div>
      <div className={'flex mt-32'}>
        <div className={'flex-2'}>
          <div className={'flex w-full'}>
            <div className={'flex-1'}>{_vsl(FN.VSL_VOY)}</div>
            <div className={'flex-1'}>{_pt(FN.POL)}</div>
            <div className={'flex-1'}>{_r(FN.ETD)}</div>
          </div>
          <div className={'flex w-full'}>
            <div className={'flex-1'}>{_r(FN.CONTAINER_SEQ)}</div>
            <div className={'flex-1'}>{_pt(FN.POD)}</div>
            <div className={'flex-1'}>{_r(FN.ETA)}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'w-full'}>
            <div>{_r(FN.COMMODITY)}</div>
            <div>{''}</div>
            <div>{''}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const AIR = (props) => {
  const {_r, _pt, _tp, _flt, _tpa} = props;
  return (
    <>
      <div className={'flex'}>
        <div className={'flex flex-4'}>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.SHIPPER, 'shipper')}</div>
            <div>{_r(FN.SHIPPER_ADDR)}</div>
          </div>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.CONSIGNEE, 'consignee')}</div>
            <div>{_r(FN.CONSIGNEE_ADDR)}</div>
          </div>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.NOTIFY, 'notify')}</div>
            <div>{_r(FN.NOTIFY_ADDR)}</div>
            <div>{_r(FN.SAME_AS_CONSIGNEE)}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'w-full'}>
            <div>{_tp(FN.CARRIER)}</div>
            <div>{_flt(FN.FLIGHT_NO)}</div>
            <div>{_r(FN.GROSS_WEIGHT_KG)}</div>
            <div>{_r(FN.CHARGEABLE_WEIGHT)}</div>
            <div>{_r(FN.PACKAGE)}</div>
          </div>
        </div>
      </div>
      <div className={'flex mt-32'}>
        <div className={'flex-1'}>
          <div className={'flex w-full'}>
            <div className={'flex flex-3'}>
                <div className={'flex-3 mr-1'}>{_pt(FN.DEPARTURE)}</div>
                <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
              </div>
            <div className={'flex flex-2'}>
              <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
              <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
            </div>
          </div>
          <div className={'flex w-full'}>
            <div className={'flex flex-3'}>
              <div className={'flex-3 mr-1'}>{_pt(FN.DESTINATION)}</div>
              <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
            </div>
            <div className={'flex flex-2'}>
              <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
              <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
            </div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'w-full'}>
            <div>{_r(FN.COMMODITY)}</div>
            <div>{''}</div>
            <div>{''}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const GROUND = (props) => {
  const {_r, _pt, _tpa} = props;
  return (
    <>
      <div className={'flex'}>
        <div className={'flex flex-4'}>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.SHIPPER, 'shipper')}</div>
            <div>{_r(FN.SHIPPER_ADDR)}</div>
          </div>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.CONSIGNEE, 'consignee')}</div>
            <div>{_r(FN.CONSIGNEE_ADDR)}</div>
          </div>
          <div className={'flex-1 w-full'}>
            <div>{_tpa(FN.NOTIFY, 'notify')}</div>
            <div>{_r(FN.NOTIFY_ADDR)}</div>
            <div>{_r(FN.SAME_AS_CONSIGNEE)}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'w-full'}>
            <div>{_r(FN.CBM)}</div>
            <div>{_r(FN.GROSS_WEIGHT_KG)}</div>
            <div>{_r(FN.PACKAGE)}</div>
          </div>
        </div>
      </div>
      <div className={'flex mt-32'}>
        <div className={'flex-1'}>
          <div className={'flex w-full'}>
            <div className={'flex-1'}>{_pt(FN.POL)}</div>
            <div className={'flex-1'}>{_r(FN.ETD)}</div>
          </div>
          <div className={'flex w-full'}>
            <div className={'flex-1'}>{_pt(FN.POD)}</div>
            <div className={'flex-1'}>{_r(FN.ETA)}</div>
          </div>
        </div>
        <div className={'flex-1'}>
          <div className={'w-full'}>
            <div>{_r(FN.COMMODITY)}</div>
            <div>{''}</div>
            <div>{''}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MovingMasterBLMblFormManager;
