/**
 * @flow
 */
import React from 'react';
import useAccountRequestGridView from '../../grids/account/useAccountRequestGridView';
import {PrimaryButton} from '../../components/buttons';
import {api, apiAccount, util} from '../../services/service';
import type {QueryListParams} from '../../services/API';
import useRequestEditModal from '../../modals/account/useRequestEditModal';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import {useInvoiceAPPreviewModal} from "../../print-layouts/InvoiceAPPrint";
import {getRequestEntryData} from "../../forms/account/AccountRequestEditFormManager";
import AccountRequestSearchFormManager from "../../forms/account/AccountRequestSearchFormManager";
import SearchPane from "../../components/SearchPane";
import {useInvoiceRequestStatementPreviewModal} from "../../print-layouts/InvoiceRequestStatementPrint";
import useRequestStatementModal from "../../modals/account/useRequestStatementModal";

const searchForm = new AccountRequestSearchFormManager();
const AccountRequest = () => {
  useTopbar({label: 'Account'}, {label: 'Request'});
  const {grid, onSearch, onAdd, onStatement, editModal, invoiceAPPrintModal, invoiceRequestStatementPrintModal, totalAmount = 0, statementModal} = useAccountRequest();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'ADD'} onClick={onAdd} />
          <PrimaryButton label={'STATEMENT'} onClick={onStatement} />
        </div>
        {grid.render()}
        <div className={'flex'} style={{position: 'absolute', bottom: 0, width: 350}}>
          <div className={'flex-1'}>Total Amount: {util.formatCurrency(totalAmount)}</div>
        </div>
      </div>
      {editModal.render()}
      {invoiceAPPrintModal.render()}
      {invoiceRequestStatementPrintModal.render()}
      {statementModal.render()}
    </div>
  );
};

function useAccountRequest() {
  const invoiceAPPrintModal = useInvoiceAPPreviewModal();
  const invoiceRequestStatementPrintModal = useInvoiceRequestStatementPreviewModal();
  const [data, setData] = React.useState();
  const [totalAmount, setTotalAmount] = React.useState(0);
  const onQueryData = (data) => {
    setData(data);
  };
  const onSelectionChanged = (e) => {
    const rows = e.api.getSelectedRows();
    let tAmount = 0;
    rows.forEach(row => {
      tAmount += row.amount;
    });
    setTotalAmount(tAmount);
  };
  const apiRef = React.useRef();
  const grid = useAccountRequestGridView((action, data) => {
    if (action === 'edit') {
      editModal.open('print', data);
    }
  }, (params) => {apiRef.current = params.api}, onQueryData, onSelectionChanged);
  const onSave = async (dataToSend) => {
    const res = await apiAccount.accountSet(dataToSend);
    if (res) {
      util.showSuccess('Account Entry has been saved successfully!');
      editModal.close();
      onSearch();
    }
  }
  const onPrint = (data) => {
    const dataInfo = {...data, invoiceDate: util.formatD(data.invoiceDate)};
    invoiceAPPrintModal.open(dataInfo);
  }
  const onDelete = (data) => {
    if(data && data.id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.deleteAccountData(parseInt(data.id));
        if(res) {
          util.showSuccess('Account has been deleted successfully!');
          editModal.close();
          onSearch();
        }
      })
    }
  }
  const {requestEditForm, grid: editGrid, ...editModal} = useRequestEditModal(async (mode, data) => {
    if(!data) {
      return;
    }
    const values = requestEditForm.getValues(undefined, true);
    if (!values) {
      return;
    }
    const {jextra} = getRequestEntryData(values);
    const newJItems = editGrid.rows.map(i => {
      return {
        ...i,
        rate: util.toFloat(i['rate']),
        volume: util.toFloat(i['volume']),
      };
    });
    const dataToSend = {...data, invNo: data.invoiceNo, invDate: util.toTS(data.invoiceDate), accType: 'AP', branchId: data.financialOffice, isRequest: true, jextra: jextra, jitems: newJItems};
    if(mode === 'print') {
      onPrint(dataToSend);
    } else if(mode === 'delete') {
      onDelete(dataToSend);
    } else {
      onSave(dataToSend);
    }
  });

  const onSearch = () => {
    const searchValues = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
      ...searchValues
    };
    grid.query(listParam);
  };
  const onAdd = () => {
    editModal.open('add', {});
  };

  const statementModal = useRequestStatementModal(onSearch);
  const onStatement = () => {
    const dataInfo = apiRef.current.getSelectedRows();
    const billToId = searchForm.getValue('billToId');
    const billTo = searchForm.getValue('billTo');
    const billToBillAddr = searchForm.getValue('billToBillAddr');
    const billToData = {billTo, billToBillAddr}
    if(billToId && billTo) {
      // invoiceRequestStatementPrintModal.open(dataInfo, billToData);
      statementModal.open(dataInfo, billToData, invoiceRequestStatementPrintModal);
    } else {
      util.showWarning('Please select Bill To / Partner in search options');
    }
  };

  searchForm.onSearch = onSearch;

  return {
    grid,
    onSearch,
    onAdd,
    onStatement,
    editModal,
    invoiceAPPrintModal,
    invoiceRequestStatementPrintModal,
    totalAmount,
    statementModal,
  };
}

export default AccountRequest;
