/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../../components/SimpleModal';
import MovingSettingAccountTypeFormManager from "../../../forms/newCoship/moving/setting/MovingSettingAccountTypeFormManager";
import {util} from "../../../services/service";
import {apiMoving} from "../../../services/newCoship/service";
import {useUser} from "../../../redux/reducers/userReducer";

const editForm = new MovingSettingAccountTypeFormManager();

export function useMovingSettingAccountTypeListModal(onSearch) {
  const [data, setData] = React.useState(undefined);
  const user = useUser();

  const onSave = () => {
    const values = editForm.getValues();
    if (!values.subject) {
      util.showWarning('Please enter subject!');
    }
    const items = editForm.getItems();
    const newItems = items?.map((data) => ({atype_id: data.atype_id, description: data.description, price: data.price}));
    const payload = {
      id: data?.id ? data?.id : undefined,
      acc_type: values.acc_type ?? 'AR',
      branch_id: util.toInt(values.branch_id) ?? user.branch_id,
      currency: values.currency ?? 'CAD',
      subject: values.subject,
      partner_id: values.partner_id,
      kind: values.kind ?? 'M',
      bound: values.bound ?? 'E',
      items: newItems ?? [],
    };
    apiMoving.setARAPItems(payload).then((res) => {
      if (res) {
        util.showSuccess('Account type has been saved successfully!');
        onClose();
      }
    });
  };

  const onDelete = () => {
    util.showConfirm('Are you sure to delete?', async () => {
      apiMoving.delARAPItems({id: data.id}).then((res) => {
        if (res) {
          util.showSuccess('Account type has been deleted successfully!');
          onClose();
        }
      });
    });
  };

  const onClose = () => {
    onSearch();
    modal.close();
  };

  const getButtons = () => {
    if (data?.id) {
      return [
        {label: 'Edit', color: 'primary', onClick: onSave},
        {label: 'Delete', color: 'danger', onClick: onDelete},
      ];
    } else {
      return [
        {label: 'Save', color: 'primary', onClick: onSave},
      ];
    }
  };

  const modal = useSimpleModal({
    title: `Setting Account Type`,
    centered: true,
    width: 900,
    buttons: getButtons(),
    children: editForm.renderForm(),
  });

  const open = (data) => {
    setData(data);
    editForm.setData(data);
    modal.open();
  };

  return {...modal, open};
};
