/**
 * @flow
 */
import React from 'react';
import {ColDef, GridApi} from 'ag-grid-community';
import {apiSettings, util} from '../../../../services/service';
import SearchPane from '../../../../components/SearchPane';
import {DefaultButton, PrimaryButton} from '../../../../components/buttons';
import CourierCLPSearchFormManager from '../../../../forms/newCoship/courier/manifest/CourierCLPSearchFormManager';
import {useSimpleGrid} from '../../../../components/SimpleGrid';
import {useTopbar} from '../../../../redux/reducers/topbarReducer';
import {useAsyncStatusModal} from '../../../../components/AsyncStatusModal';
import {useUser} from "../../../../redux/reducers/userReducer";
import {apiCourier} from "../../../../services/newCoship/service";
import {useCourierCLPWaitingHousesModal} from "../../../../modals/newCoship/courier/useCourierCLPWaitingHousesModal";
import {usePackingListPreviewModal} from "../../../../print-layouts/PackingListPrint";
import useEmailModal from "../../../../modals/email/useEmailModal";
import {useSendEmailModal} from "../../../../forms/email/SendEmailFormManager";
import {useCourierOceanPreviewModal} from "../../../../print-layouts/CourierOceanPrint";
import {useCLPPreviewModal} from "../../../../print-layouts/CLPPrint";

const searchForm = new CourierCLPSearchFormManager();

const CLP = () => {
  const {grid, onSearch, onDownload, onLabel, onAdd, onCLP, onPL, onRemove, courierOceanModal, houseListModal, packingListPrintModal, clpPrintModal, emailModal, sendEmailModal, asyncStatusModal} = useCLP();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 flex-column ml-24'}>
        <div className={'flex mb-8'}>
          <DefaultButton label={'Export Excel'} onClick={() => onDownload()} noMargin />
          <div className={'mr-8'}/>
          <DefaultButton label={'Add'} onClick={() => onAdd()} noMargin />
          <div className={'mr-8'}/>
          <DefaultButton label={'CLP'} onClick={() => onCLP()} noMargin />
          <div className={'mr-8'}/>
          <DefaultButton label={'Label'} onClick={() => onLabel()} noMargin />
          <div className={'mr-8'}/>
          <DefaultButton label={'P/L'} onClick={() => onPL()} noMargin />
          <div className={'mr-8'}/>
          <DefaultButton label={'Remove'} onClick={() => onRemove()} noMargin />
        </div>
        {grid.render()}
        {courierOceanModal.render()}
        {houseListModal.render()}
        {asyncStatusModal.render()}
        {packingListPrintModal.render()}
        {clpPrintModal.render()}
        {emailModal.render()}
        {sendEmailModal.render()}
      </div>
    </div>
  );
};

function useCLP() {
  useTopbar({label: 'Courier'}, {label: 'CLP'});
  const [blId, setBlId] = React.useState();
  const [isRowClickable, setIsRowClickable] = React.useState(true);
  const filingNoRef = React.useRef('');
  const user = useUser();

  const grid = useSimpleGrid({
    columns: getColumns(),
    height: '100%',
    className: '',
    actions: ['delete', 'email'],
    actionWidth: 70,
    agGridProps: {
      defaultColDef: {
        width: 150,
        resizable: true,
      },
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      onCellClicked(e: CellClickedEvent) {
        if (e.colDef.headerName !== 'Actions') {
          e.node.setSelected(!e.node.selected);
        }
      }
    },
    onAction: (action, data) => {
      if (action === 'delete') {
        const blIds = [data.bl_id];
        onRemove(blIds);
      } else if (action === 'email') {
        onEmail(data);
      }
    },
  });

  const setReturnData = (returnData) => {
    const gridData = returnData?.map((data, index) => ({...data, rowId: index+1}))
    grid.setRows(gridData);
  };

  const onSendEmail = (mode, data) => {
    const {pic_email, pic_name, toEmail, template, title, message, selectedFile, blId, partnerId} = data;
    if(!template && mode === 'send') {
      return util.showWarning('Please select template!');
    }
    if(!pic_email) {
      return util.showWarning('Please enter a valid email!');
    }
    if(!message) {
      return util.showWarning('Please enter a valid message!');
    }
    const formData = new FormData();
    formData.append('blId', blId);
    formData.append('partnerId', partnerId);
    formData.append('from', pic_email);
    formData.append('fromName', pic_name);
    formData.append('to', toEmail);
    formData.append('subject', title);
    formData.append('message', message);
    formData.append('houses', blId);
    formData.append('file', selectedFile);
    const res = apiSettings.sendEmail(formData);
    if(res) {
      util.showSuccess('Email has been sent successfully.');
      sendEmailModal.close();
      emailModal.onSearch().catch();
    }
  };

  const courierOceanModal = useCourierOceanPreviewModal();
  const houseListModal = useCourierCLPWaitingHousesModal(setReturnData);
  const packingListPrintModal = usePackingListPreviewModal();
  const clpPrintModal = useCLPPreviewModal();
  const emailModal = useEmailModal();
  const sendEmailModal = useSendEmailModal(onSendEmail, blId);
  const asyncStatusModal = useAsyncStatusModal('Searching... please wait...');

  const onSearch = async () => {
    const {filingNo, blNo, containerNo} = searchForm.getValues();
    if (filingNo || blNo || containerNo) {
      asyncStatusModal.open();
      apiCourier.getCLPHouses({filingNo, blNo, containerNo}).then((res) => {
        if (res) {
          const data = res.data;
          grid.setRows(data.houses);
          filingNoRef.current = data.filing_no;
        }
      });
      asyncStatusModal.close();
    } else {
      filingNoRef.current = '';
    }
  };
  const onDownload = () => {
    if (grid.rows?.length > 0) {
      const api: GridApi = grid.apiRef.current;
      const {filingNo, mblNo, containerNo} = searchForm.getValues();
      let title = 'download';
      if (filingNo) {
        title = filingNo;
      } else if (mblNo) {
        title = mblNo;
      } else if (containerNo) {
        title = containerNo;
      }
      const columns = getColumns();
      api.exportDataAsCsv({
        fileName: `manifest-${title}.csv`,
        columnKeys: columns.filter((c, index) => index > 0).map(c => c.field),
      });
    }
  };
  const onAdd = () => {
    if (filingNoRef.current) {
      houseListModal.open(filingNoRef.current);
    }
  };
  const onCLP = () => {
    const payload = {filing_no: filingNoRef.current, bl_ids: getSelectedBlIds()};
    if (!payload.filing_no || payload.bl_ids?.length === 0) {
      return util.showWarning('There is no CLP data!');
    }
    apiCourier.getCLPPrintData(payload).then((res) => {
      if (res) {
        clpPrintModal.open(res.data);
      }
    });
  };
  const onLabel = () => {
    const selectedRows = grid.apiRef.current.getSelectedRows();
    const data = selectedRows.map((data) => ({name: data.consignee, totalPackage: data.packages}));
    if (data?.length > 0) {
      courierOceanModal.open(data);
    } else {
      return util.showWarning('There is no Label data!');
    }
  };
  const onPL = () => {
    const payload = {filing_no: filingNoRef.current, bl_ids: getSelectedBlIds()};
    if (!payload.filing_no || payload.bl_ids?.length === 0) {
      return util.showWarning('There is no P/L data!');
    }
    apiCourier.getPLBulkPrint(payload).then((res) => {
      if (res) {
        if (res.data?.length > 0) {
          packingListPrintModal.open(res.data);
        } else {
          return util.showWarning('There is no P/L data!');
        }
      }
    });
  };
  const onRemove = (blIds) => {
    const payload = {filing_no: filingNoRef.current, bl_ids: blIds?.length > 0 ? blIds : getSelectedBlIds()};
    if (payload.filing_no && payload.bl_ids?.length > 0) {
      util.showConfirm('Are you sure to delete?', async () => {
        apiCourier.delCLPHouses(payload).then((res) => {
          if (res) {
            setReturnData(res.data.houses);
          }
        });
      });
    }
  };
  const onEmail = (data) => {
    const blId = data.bl_id;
    const partnerId = data.partner_id;
    const customerEmail = data.email;
    setBlId(blId);
    emailModal.open(blId, partnerId, customerEmail, sendEmailModal);
  };
  const getSelectedBlIds = () => {
    const selectedRows = grid.apiRef.current.getSelectedRows();
    return selectedRows.map((x) => x.bl_id);
  };
  searchForm.onSearch = () => onSearch().catch();

  return {grid, onSearch, onDownload, onLabel, onAdd, onCLP, onPL, onRemove, courierOceanModal, houseListModal, packingListPrintModal, clpPrintModal, emailModal, sendEmailModal, asyncStatusModal};
}

function getColumns(): ColDef[] {
  if (!searchForm.formik) {
    return [];
  }
  return [
    {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
    {field: 'bl_id', hide: true},
    {field: 'wo_id', hide: true},
    {field: 'bl_no', headerName: 'BL No.', cellRendererFramework: RenderHBL, width: 105},
    {field: 'packages', headerName: 'Pkg.', width: 55},
    {field: 'pickup', headerName: 'Pick up', width: 95},
    {field: 'edate', headerName: 'E.Date', width: 90},
    {field: 'shipper', headerName: 'Shipper', width: 100},
    {field: 'consignee', headerName: 'Consignee', width: 100},
    {field: 'maple_type', headerName: 'Mpale Type', width: 110},
    {field: 'clp_remark', headerName: 'CLP Remark', editable: true, minWidth: 100, flex: 1},
    {field: 'weight_kg', headerName: 'Weight', width: 70},
    {field: 'cbm', headerName: 'CBM', width: 60},
    {field: 'delivery', headerName: 'Delivery', editable: true, width: 75},
    {field: 'wo_remark', headerName: 'W.O Remark', editable: true, minWidth: 100, flex: 1},
    {field: 'balance', headerName: 'Balance', width: 75},
  ];
}

function RenderHBL(params) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
      e.preventDefault();
      util.openTab(`/admin/courier/house/customer?id=${params.data.bl_id}`);
    }}>
      {params.value?.replaceAll('"', '').replaceAll('=', '')}
    </a>
  );
}

export default CLP;
