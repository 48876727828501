/**
 * @flow
 */
import React from 'react';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import MasterDefaultCommissionFormManager from "../../forms/settings/MasterDefaultCommissionFormManager";
import MasterCommissionFormManager from "../../forms/settings/MasterCommissionFormManager";
import MasterHAWBFormManager from "../../forms/settings/MasterHAWBFormManager";
import MasterCourierPriceFormManager from "../../forms/settings/MasterCourierPriceFormManager";
import MasterHSCodeFormManager from "../../forms/settings/MasterHSCodeFormManager";
import MasterInventoryFormManager from "../../forms/settings/MasterInventoryFormManager";
import UpdateDistributingCode from "../../forms/settings/UpdateDistributingCodeFormManager";
import {apiSettings, util} from "../../services/service";
import {useDropzone} from "react-dropzone";
import UploadIcon from "mdi-react/UploadIcon";
import {useSimpleModal} from "../../components/SimpleModal";
import {useAsyncStatusModal} from '../../components/AsyncStatusModal';

const masterDefaultCommissionFormManager = new MasterDefaultCommissionFormManager();
const masterCommission = new MasterCommissionFormManager();
const masterHAWB = new MasterHAWBFormManager();
const masterVendorTable = new MasterCourierPriceFormManager();
const masterHSCode = new MasterHSCodeFormManager();
const masterInventory = new MasterInventoryFormManager();
const updateDistributingCode = new UpdateDistributingCode();

const SettingMaster = () => {
  useTopbar({label: 'Settings'}, {label: 'Master'})
  const {uploadModal} = useSettingMaster();
  updateDistributingCode.uploadModal = uploadModal;
  const code = process.env.REACT_APP_VERSION_CODE
  const release_date = process.env.REACT_APP_RELEASE_DATE
  return (
    <>
      {masterDefaultCommissionFormManager.renderForm()}
      {masterCommission.renderForm()}
      {masterVendorTable.renderForm()}
      {masterHAWB.renderForm()}
      {masterHSCode.renderForm()}
      {masterInventory.renderForm()}
      {updateDistributingCode.renderForm()}
      {uploadModal.render()}
      {`Build version: ${code}`}
    </>
  );
};

function useSettingMaster() {
  const [updated, setUpdated] = React.useState();
  const uploadModal = useTXTUploadModal((res) => {
    const message = res?.data?.[0]?.msg;
    if (message) {
      util.showSuccess(message);
      // apiSettings.getLastUpdated().then(res => {
      //   updateDistributingCode.updateDate = res.data[0].udate;
      //   setUpdated(res.data[0].udate);
      // });
      uploadModal.close();
    }
  });
  return {uploadModal};
}

const CodeUpload = ({onUpload}) => {
  const [fileSize, setFileSize] = React.useState('0 BYTE');
  const asyncStatusModal = useAsyncStatusModal('Uploading... please wait...');
  const onDrop = React.useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0].size;
    if (acceptedFile >= 0 && acceptedFile < 1000) {
      setFileSize(`${acceptedFile} BYTE`);
    } else if (acceptedFile >= 1000 && acceptedFile < 1000000) {
      setFileSize(`${acceptedFile / 1000} KB`);
    } else if (acceptedFile >= 1000000 && acceptedFile < 1000000000) {
      setFileSize(`${acceptedFile / 1000000} MB`);
    } else {
      setFileSize(acceptedFile);
    }
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        asyncStatusModal.open();
        apiSettings.updateTXT(reader.result).then((res) => onUpload(res)).finally(() => asyncStatusModal.close());
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: '.txt',
    maxFiles: 1,
    maxSize: 600 * 1024 * 1024,
    onDropRejected: () => {
      util.showWarning('Please select valid txt file!');
    },
  });
  return (
    <>
      <div className={'flex center middle mb-20'} {...getRootProps()} style={{height: 350, border: '1px solid #d0d0d0'}}>
        <input {...getInputProps()} />
        <div style={{textAlign: 'center'}}>
          <UploadIcon size={56} color={'#c0c0c0'} />
          {isDragActive ? <p>Drop the TXT file here ...</p> : <p>Drag & drop TXT file here, or click to select file</p>}
        </div>
        {asyncStatusModal.render()}
      </div>
      <div>{`File size: ${fileSize}`}</div>
    </>
  );
};

function useTXTUploadModal(onUpload) {
  const modal = useSimpleModal({
    title: 'TXT Upload',
    width: 720,
    centered: true,
    buttons: [
      // {label: 'Close', onClick: () => modal.close()}
    ],
    children: <CodeUpload onUpload={onUpload} />,
  });
  return modal;
}

export default SettingMaster;
