/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {currencyOptions, portTypeOptions} from '../helper';
import {util} from '../../services/service';

class AccountTypeFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'account-type-form',
      fields: [
        {name: 'id', serverName: 'id', required: false},
        {name: 'accountType', serverName: 'accountType', label: 'Type Name', required: true},
        {name: 'currency', serverName: 'currency', label: 'Currency', required: true, options: currencyOptions},
        {name: 'price', serverName: 'price', label: 'Price', required: true},
        {name: 'isActive', serverName: 'isActive', label: 'Active', type: 'checkbox', required: false},
        {name: 'rewardRate', serverName: 'reward_rate', label: 'Reward Rate', required: false, defaultValue: 0},
        {name: 'remark', serverName: 'remark', label: 'Remark', required: false},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    return (
      <div className={'w-full mr-24'}>
        {_r('accountType')}
        {_r('currency')}
        {_r('price')}
        {_r('rewardRate')}
      </div>
    );
  };
  onValidate = (values) => {
    const {accountType, currency, price, id, rewardRate = 0} = values;
    if (!accountType) {
      util.showWarning('Please enter the account type');
      return;
    }
    if (!currency) {
      util.showWarning('Please select the currency.');
      return;
    }
    if (!price) {
      util.showWarning('Please enter the price.');
      return;
    }
    if(isNaN(rewardRate)) {
      util.showWarning('Please enter a valid reward rate data!');
      return;
    } else if(rewardRate >= 1 || rewardRate < 0) {
      util.showWarning('Please enter a valid data between 0 and 1');
      return;
    }
    values.isExpense = this.isChecked('isExpense');
    values.id = id ? util.toInt(id) : 0;
    values.isActive = true;
    return values;
  };
}

export default AccountTypeFormManager;
