/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../components/SimpleModal';
import {apiAccount, util} from '../../services/service';
import {useDataGridView} from "../../components/DataGridView";
import {queryGridData} from "../../components/DataGrid";

export default function useProfitDetailModal() {
  const [filingNo, setFilingNo] = React.useState();
  const [data, setData] = React.useState(); // 그리드에서 선택한 행의 데이터를 보관함
  async function onQuery(gridInfo) {
    if (modal.isOpen) {
      return await apiAccount.profitData(filingNo);
    }
  }

  const grid = useDataGridView({
    async onAction(action, data) {
      // TODO
    },
    onQuery,
    onCellValueChange(e) {
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
    },
    name: `Profit Detail`,
    label: '',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'Profit'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: [],
    actionWidth: 0,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    useModalDataGrid: true,
    modalDataGridHeight: 500,
    showRowSearch: false,
    columns: [
      {field: 'id', hide: true},
      {field: 'acc_type', headerName: 'Type', width: 100},
      {field: 'bill_to', headerName: 'Bill To', flex: 1, minWidth: 140},
      {field: 'bl_no', headerName: 'B/L No.', width: 100},
      {field: 'due_date', headerName: 'Due Date', valueFormatter: util.dateTimeFormatter, width: 140},
      {field: 'currency', headerName: 'Currency', width: 50},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.twoDecimalPlaceFormatter, width: 100},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.twoDecimalPlaceFormatter, width: 100},
    ],
  });
  const getTitle = () => {
    if(filingNo) {
      return `PROFIT DETAIL [ ${filingNo} ]`;
    } else {
      return 'PROFIT DETAIL';
    }
  }
  const modal = useSimpleModal({
    title: getTitle(),
    centered: true,
    width: 920,
    buttons: [],
    children: grid.render()
  });

  React.useEffect(() => {
    if (modal.isOpen === true && !!filingNo) {
      queryGridData('Profit Detail', undefined, onQuery).then(() => console.log('hello, grid query called!'));
    }
  }, [modal.isOpen]);

  React.useEffect(() => {
    if (modal.isOpen === true && !!filingNo) {
      apiAccount.profitData(filingNo).then((res) => {
        const {data} = res;
        modal.setRows(data);
      }).catch(console.error);
    }
  }, [modal.isOpen, filingNo]);

  const open = (filingNo: string) => {
    setFilingNo(filingNo);
    modal.open();
  };
  return {...modal, open};
}
