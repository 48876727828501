/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {CheckBoxField} from '../../shared/components/form/CheckBox';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';

const StaffPermission = () => {
  const [checkList, setCheckList] = React.useState({});
  const [permissionData, setPermissionData] = React.useState([]);
  const onCategoryClick = (checked, cid, menus) => {
    const newList = {...checkList};
    for (const [sid] of menus) {
      newList[`${cid}-${sid}`] = checked;
    }
    setCheckList(newList);
  };
  return (
    <DataGridView
      name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
      addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
      actions={ACTIONS} actionWidth={ACTION_WIDTH}
      showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
      columns={COLUMNS}
      onAction={async (action, data) => onAction(action, data, permissionData, setPermissionData, checkList, setCheckList)}
      onQuery={async (gridInfo) => onQuery(gridInfo)}
      renderForm={(formik, fields, errors) => renderForm(formik, fields, errors, permissionData, checkList, setCheckList, onCategoryClick)}
      useExtendedColDef
    />
  );
};

const NAME = 'permissionList';
const LABEL = 'Permission';
const ADD_LABEL = 'ADD PERMISSION';
const EDIT_LABEL = 'EDIT PERMISSION';
const CATEGORY_LABEL = 'Staff';
const MENU_LABEL = 'Permission';
const SORT_COL = 'cdate';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 720;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit', 'delete'];
const ACTION_WIDTH = 70;
const COLUMNS: (ColDef | FormField)[] = [
  {
    name: 'id', required: false,
    field: 'id', hide: true
  },
  {
    name: 'cdate', required: false,
    field: 'cdate', headerName: 'Created', valueFormatter: p => util.formatDate(p.value), flex: 1
  },
  {
    name: 'pname', required: true,
    field: 'pname', headerName: 'Name', flex: 1
  },
  {
    name: 'login_id', required: false,
    field: 'login_id', headerName: 'Created by', flex: 1
  },
  {
    name: 'remark', required: false,
    field: 'remark', headerName: 'Remark', flex: 1
  },
];

async function onAction(action, data, permissionData, setPermissionData, checkList, setCheckList) {
  console.log(`[DataGridView] ${LABEL} action`, action, data);
  switch (action) {
    case 'add': {
      const newData = buildPermissionData(checkList, permissionData);
      return api.permissionAdd({...data, menu: newData});
    }
    case 'edit': {
      const newData = buildPermissionData(checkList, permissionData);
      return api.permissionEdit({...data, menu: newData});
    }
    case 'addOpen':
    case 'editOpen':
    {
      const res = await api.getMenuPermissions(data?.id ?? 0);
      setPermissionData(res);
      setCheckList(buildCheckList(res));
      return;
    }
    case 'delete':
      return api.permissionDel({id: data.id});
    case 'detail':
      return;
  }
}

async function onQuery(gridInfo) {
  console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.permissionList(gridInfo);
}

function renderForm(formik, fields, errors, permissionData, checkList, setCheckList, onCategoryClick) {
  const render = (name) => renderField(formik, name, fields, errors);
  return (
    <>
      {render('pname')}
      {render('remark')}
      <div className={'flex'}>
        <div className={'mb-2 mt-4 flex-1'}>
          {renderPermission(permissionData.filter((i, index) => index % 2 === 0), checkList, setCheckList, onCategoryClick)}
        </div>
        <div className={'mb-2 mt-4 flex-1'}>
          {renderPermission(permissionData.filter((i, index) => index % 2 === 1), checkList, setCheckList, onCategoryClick)}
        </div>
      </div>
    </>
  );
}

function renderPermission(permissionData, checkList, setCheckList, onCategoryClick) {
  return permissionData.map(([cid, title, menus]) => {
    return (
      <div key={title}>
        <div className={'my-2'}>
          <CheckBoxField
            onChange={({target: {checked}}) => onCategoryClick(checked, cid, menus)}
            value={isCategoryChecked(cid, menus, checkList)}
            label={title}
            useBigFont
          />
        </div>
        <div className={'flex wrap pl-3 mb-16'}>
          {menus.map(([sid, label, checked]) => {
            return (
              <div className={'m-2'} key={`${cid}-${sid}`}>
                <CheckBoxField
                  onChange={({target: {checked}}) => setCheckList({...checkList, [`${cid}-${sid}`]: checked})}
                  value={checkList[`${cid}-${sid}`] ?? checked ?? false}
                  label={label}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  })
}

function buildPermissionData(checkList, permissionData) {
  const newData = [];
  for (const [cid, ctitle, menus] of permissionData) {
    const newMenus = [];
    for (const [sid, stitle, checked] of menus) {
      newMenus.push([sid, stitle, checkList[`${cid}-${sid}`]]);
    }
    newData.push([cid, ctitle, newMenus]);
  }
  return newData;
}

function buildCheckList(permissionData) {
  const checkList = {};
  for (const [cid, , menus] of permissionData) {
    for (const [sid, , checked] of menus) {
      checkList[`${cid}-${sid}`] = checked;
    }
  }
  return checkList;
}

function isCategoryChecked(cid, menus, checkList) {
  let count = 0;
  for (const [sid] of menus) {
    if (checkList[`${cid}-${sid}`] === true) {
      ++count;
    }
  }
  return count === menus.length;
}

export default StaffPermission;
