/**
 * @flow
 */
import {useDataGridView} from '../../components/DataGridView';
import {apiBL, util} from '../../services/service';
import React from 'react';
import {statusOptions} from '../../forms/helper';
import {useUser} from '../../redux/reducers/userReducer';

export default function useBLUpsGridView(onGridReady) {
  const user = useUser();
  return useDataGridView({
    async onAction(action, data) {
      if (action === 'edit') {
        util.openTab(`https://coship.ca/application/form?s=MA&d=CANADA&n=C&upsid=${data?.id}&sid=${user?.id}&key=${data?.token}`)
      } else if (action === 'delete') {
        return apiBL.delBLUpsData(data.id);
      }
    },

    async onQuery(gridInfo) {
      const {data, totalCount} = await apiBL.getBLUpsList(gridInfo);
      return {
        totalCount,
        data: data.map(r => ({
          ...r,
          shipper_phone: `="${r.shipper_phone}"`,
          shipper_postal_code: `="${r.shipper_postal_code}"`,
          consignee_postal_code: `="${r.consignee_postal_code}"`,
          consignee_phone1: `="${r.consignee_phone1}"`,
        })),
      };
    },
    name: `ListAllUPS`,
    label: 'UPS',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'B/L'},
    menuLabel: {label: 'UPS'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'delete'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    onGridReady,
    columns: [
      {field: 'id', headerName: 'ID', flex: 1},
      {field: 'status_no', headerName: 'Status', cellRendererFramework: StatusDropbox, width: 100},
      {field: 'cdate', headerName: 'Date', valueFormatter: cDateFormatter, width: 100},
      {field: 'send_type', headerName: 'Type', width: 100},
      {field: 'bl_no', headerName: 'HBL NO.', cellRendererFramework: hblLinkFormatter, width: 100},
      {field: 'mbl_no', headerName: 'MBL NO.', width: 100},
      {field: 'shipper_phone', headerName: 'Shipper Phone', valueFormatter: postalTextFormatter, width: 120},
      {field: 'shipper_local', headerName: 'Shipper', flex: 1},
      {field: 'shipper_addr', headerName: 'Shipper Addr'},
      {field: 'shipper_postal_code', headerName: 'Shipper Postal', valueFormatter: postalTextFormatter, width: 120},
      {field: 'email', headerName: 'Email'},
      {field: 'consignee', headerName: 'Consignee'},
      {field: 'consignee_postal_code', headerName: 'Postal Code', valueFormatter: postalTextFormatter, width: 120},
      {field: 'consignee_phone1', headerName: 'Phone', valueFormatter: postalTextFormatter, width: 120},
      {field: 'consignee_addr', headerName: 'Address'},
      {field: 'consignee_city', headerName: 'City', width: 120},
      {field: 'consignee_province', headerName: 'Province', width: 40},
      {field: 'consignee_country', headerName: 'Country Code', width: 100},
      {field: 'tracking_no', headerName: 'Tracking No.'},
      {field: 'price', headerName: 'Price', valueFormatter: util.currencyFormatter, width: 100},
      {field: 'package', headerName: 'Packages', width: 100},
      {field: 'weight', headerName: 'Gross Weight', width: 100},
      {field: 'vol_weight', headerName: 'Volume Weight', width: 100},
      {field: 'volume', headerName: 'Volume'},
      {field: 'remark', headerName: 'Remark'},
      ...getItemsInfoColumns()
    ],
    isActionDisabled: isActionDisabled,
  });
}

function postalTextFormatter(p) {
  return p?.value ? p?.value.toString().replaceAll('"', '').replace('=', '') : '';
}

function getItemsInfoColumns() {
  function itemNameRenderer(param, index) {
    return (param.data.items.length > index) ? param.data.items[index]?.item : '';
  }

  function itemQtyRenderer(param, index) {
    return (param.data.items.length > index) ? param.data.items[index]?.qty : '';
  }

  function itemUnitPriceRenderer(param, index) {
    return (param.data.items.length > index) ? util.currencyFormatter({value: param.data.items[index]?.unitPrice}) : '';
  }

  const itemsInfoColumns = []
  for (const index of Array.from(Array(20).keys())) {
    itemsInfoColumns.push({
      index: index,
      field: `item_name_${index + 1}`,
      cellRendererFramework: (param) => itemNameRenderer(param, index),
      headerName: `Item Name ${index + 1}`
    });
    itemsInfoColumns.push({
      index: index,
      field: `item_qty_${index + 1}`,
      cellRendererFramework: (param) => itemQtyRenderer(param, index),
      headerName: 'Qty',
      width: 40
    });
    itemsInfoColumns.push({
      index: index,
      field: `item_unit_price_${index + 1}`,
      cellRendererFramework: (param) => itemUnitPriceRenderer(param, index),
      headerName: 'Unit Price',
      width: 100
    });
  }
  return itemsInfoColumns;
}

function isActionDisabled(type: DataGridActionType, data: Object) {
  return (type === 'edit' && data.bl_no !== null);
}

function cDateFormatter(p) {
  return util.formatD(p.value) ? util.formatD(p.value) : '';
}

function StatusDropbox(params) {
  const statusNoRef = React.useRef();
  const onSubmit = (alimTalk) => {
    apiBL.setKorUPSStatus(params?.data?.id, statusNoRef.current, alimTalk).catch();
  };
  const onChange = (e) => {
    statusNoRef.current = Number(e?.target?.value);
    if (statusNoRef.current === 2 || statusNoRef.current === 6) {
      util.showConfirm(
        '알림톡을 보내시겠습니까?',
        () => onSubmit(true),
        () => onSubmit(false),
        'Yes',
        'No'
      );
    } else {
      onSubmit(false);
    }
  };
  return (
    <select key={params.data.id} defaultValue={params.value ?? ''} onChange={onChange}>
      <option key='empty' value={''} hidden>{}</option>
      {statusOptions.map(({value, label}) => {
        return <option key={label} value={value}>{label}</option>
      })}
    </select>
  )
}

function hblLinkFormatter(param) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        util.openTab(`/admin/bl/house/${param.data['bl_id']}`);
      }}>
        {param.value}
      </a>
    );
}
