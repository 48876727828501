/**
 * @flow
 */
import React from 'react';
import useAccountBLRateGridView from '../../grids/account/useAccountBLRateGridView';
import SearchPane from '../../components/SearchPane';
import {PrimaryButton} from '../../components/buttons';
import AccountBLRateSearchFormManager from '../../forms/account/AccountBLRateSearchFormManager';
import {api, apiAccount, util} from '../../services/service';
import type {QueryListParams} from '../../services/API';
import useBLRateEditModal from '../../modals/account/useBLRateEditModal';

const searchForm = new AccountBLRateSearchFormManager();

const AccountBLRate = () => {
  const {grid, onSearch, onAdd, editModal} = useAccountLBRate();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: -12}}>
          <PrimaryButton label={'ADD'} onClick={onAdd} />
        </div>
        {grid.render()}
      </div>
      {editModal.render()}
    </div>
  );
};

function useAccountLBRate() {
  const grid = useAccountBLRateGridView((action, data) => {
    if (action === 'edit') {
      // console.log(data);
      editModal.open('edit', data);
    }
  });
  const onSave = (data) => {
    if(!data.blNo) {
      util.showWarning('Please enter a valid B/L No.');
      return;
    }
    const newJItems = editGrid.rows.map(i => {
      return {
        ...i,
        rate: util.toFloat(i['rate']),
        volume: util.toFloat(i['volume']),
      };
    });
    const dataToSend = {...data, jitems: newJItems};
    const res = apiAccount.setBLRate(dataToSend);
    if (res) {
      util.showSuccess('B/L rate has been saved successfully!');
      editModal.close();
    }
  };
  const onDelete = (data) => {
    if(data) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = apiAccount.deleteAccountBLRate(data.id);
        if(res) {
          util.showSuccess('B/L rate has been deleted successfully!');
          editModal.close();
        }
      })
    }
  };
  const {grid: editGrid, ...editModal} = useBLRateEditModal(async (mode, data) => {
    if(mode === 'delete') {
      await onDelete(data);
    } else {
      await onSave(data);
    }
  });
  const onSearch = () => {
    const searchValues = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      qryText: '',
      page: 1,
      ...searchValues
    };
    grid.query(listParam);
  };
  const onAdd = () => {
    editModal.open('add', {});
  };
  searchForm.onSearch = onSearch;
  React.useEffect(() => {
    const {blNo} = util.getQS();
    if (blNo) {
      searchForm.setValue('blNo', blNo);
      setTimeout(() => {
        onSearch();
      }, 0);
    }
  }, []);
  React.useEffect(() => {
    onSearch();
  }, [editModal.isOpen]);
  return {
    grid,
    onSearch, onAdd,
    editModal,
  };
}

export default AccountBLRate;
