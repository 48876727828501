/**
 * @flow
 */
import React, {Fragment} from 'react';
import {Formik, Form} from 'formik';
import FormManager from '../../../../lib/FormManager';
import {api, apiAccount, util} from '../../../../services/service';
import {FD} from "../../../field-defs/newCoship";
import {useUser} from "../../../../redux/reducers/userReducer";
import {Button} from "reactstrap";
import Icon from "mdi-react/SearchIcon";
import TracePartnerSearchModal from "../../../../components/TradePartnerSearchModal";
import {apiCourier} from "../../../../services/newCoship/service";

class CourierHouseBLAccountFormManager extends FormManager {
  dataList;
  itemModal;
  constructor() {
    super({
      prefix: `courier-house-account-ar-form`,
      fields: [FD.INV_ID, FD.PARTNER_ID, FD.PARTNER_NAME, FD.BRANCH_ID, FD.INV_DATE, FD.BANK_INFO, FD.MEMO],
      formProps: {
        // horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  setData = (data) => {
    this.dataList = data;
  };
  onRender = () => {
    const [state, setState] = React.useState(0);
    const [branchId, setBranchId] = React.useState(0);
    const [isOpenPartner, setIsOpenPartner] = React.useState(false);
    const [partnerFieldId, setPartnerFieldId] = React.useState(0);
    const [inputData, setInputData] = React.useState([{inv_id: undefined, partner_id: undefined, partner_name: undefined, branch_id: undefined, inv_date: undefined, bank_info: undefined, memo: undefined}]);
    const [queryText, setQueryText] = React.useState('');
    const user = useUser();
    const bankInfoOptions = apiAccount.useBankList(user.branch_id);
    const branchOptions = api.useAreaOptions(true);

    const handleClick = (index) => {
      const updatedState = this.dataList[index];
      if (updatedState.child) {
        delete updatedState.child;
        setState((pre) => pre + 1);
      } else {
        updatedState.child = {index: index};
        setState((pre) => pre + 1);
      }
    };

    const onSave = (values, index) => {
      const payload = {...inputData[index]};
      apiCourier.setAccountInfo(payload).then(res => {
        if (res) {
          util.showSuccess('Account has been saved successfully!');
        }
      });
    };

    const onItems = (row) => {
      this.itemModal.open(row.inv_id);
    };

    const onSearch = (e, id) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpenPartner(true);
      setPartnerFieldId(id);
      setQueryText(e.target.value);
    };

    function onOK({id, value}) {
      const index = partnerFieldId.split('_')[2];
      const newData = inputData.map((data, idx) => {
        if (idx === util.toInt(index)) {
          return ({...data, partner_id: id, partner_name: value});
        } else {
          return data;
        }
      });
      setInputData(newData);
    }

    const onChange = (e, id, index) => {
      let value = e.target.value;
      if (id === 'branch_id') value = util.toInt(value);
      const newData = inputData.map((data, idx) => {
        if (idx === index) {
          return ({...data, [id]: value});
        } else {
          return data;
        }
      });
      setInputData(newData);
    };

    React.useEffect(() => {
      setInputData(this.dataList?.map(data => (
        {
          inv_id: data.inv_id,
          partner_id: data.partner_id,
          partner_name: data.partner_name,
          branch_id: data.branch_id,
          inv_date: data.inv_date,
          bank_info: data.bank_info,
          memo: data.memo,
        }
      )));
    }, [this.dataList]);

    React.useEffect(() => {
      setBranchId(user.branch_id);
    }, [user]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'pl-8 pr-20 pt-12 pb-1'}>
          <Formik initialValues={{}} onSubmit={() => {}}>
            {props => (
              this.dataList?.map((row, index) => (
                <Fragment key={`${index}${row.inv_no}`}>
                  <div className={'mt-4'} style={{padding: 20, border: '1px solid black', borderRadius: 5, cursor: 'pointer'}} onClick={() => handleClick(index)}>
                    <div>{`${row.inv_no} (${row.currency} ${util.formatCurrency(row.amount)})`}</div>
                  </div>
                  {row.child ? (
                    <Form onSubmit={props.handleSubmit}>
                      <div className={'w-full'} style={{padding: 20}}>
                        <div className={'flex'}>
                          <input type={'text'} id={`inv_id_${index}`} name={`inv_id_${index}`} value={inputData[index].inv_no} hidden/>
                          <div className={'flex flex-1 mr-4'}>
                            <div style={{marginTop: 5, width: 60}}>Bill To</div>
                            <div className={'flex'}>
                              <input type={'text'} id={`partner_id_${index}`} name={`partner_id_${index}`} value={inputData[index].partner_id} hidden/>
                              <input type={'text'} id={`partner_name_${index}`} name={`partner_name_${index}`} value={inputData[index].partner_name} onKeyDown={(e) => onSearch(e, `partner_id_${index}`)}/>
                              <div className={'mt-1 ml-8'}>
                                <a href={'/#search'} onClick={(e) => onSearch(e, `partner_id_${index}`)}><Icon size={20}/></a>
                              </div>
                            </div>
                          </div>
                          <div className={'flex flex-1 mr-4'}>
                            <div style={{marginTop: 5, width: 60}}>Branch</div>
                            <div className={'flex-1'}>
                              <select id={`branch_id_${index}`} name={`branch_id_${index}`} value={inputData[index].branch_id} onChange={(e) => onChange(e, 'branch_id', index)}>
                                {branchOptions.map(data => <option value={data.value}>{data.label}</option>)}
                              </select>
                            </div>
                          </div>
                          <div className={'flex flex-1 mr-4'}>
                            <div style={{marginTop: 5, width: 60}}>Inv. Date</div>
                            <div className={'flex-1'}>
                              <input type={'date'} id={`inv_date_${index}`} name={`inv_date_${index}`} value={inputData[index].inv_date} onChange={(e) => onChange(e, 'inv_date', index)}/>
                            </div>
                          </div>
                          <div className={'flex flex-1'}>
                            <div style={{marginTop: 5, width: 60}}>Bank Info</div>
                            <div className={'flex-1'}>
                              <select id={`bank_info_${index}`} name={`bank_info_${index}`} value={inputData[index].bank_info} onChange={(e) => onChange(e, 'bank_info', index)}>
                                {bankInfoOptions.map(data => <option value={data.value}>{data.label}</option>)}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className={'flex mt-2'}>
                          <div style={{marginTop: 5, width: 60}}>Memo</div>
                          <div className={'flex-1'}>
                            <input type={'text'} id={`memo_${index}`} name={`memo_${index}`} value={inputData[index].memo} onChange={(e) => onChange(e, 'memo', index)}/>
                          </div>
                        </div>
                        <div className={'mt-4'} style={{textAlign: 'center'}}>
                          <Button color={'success'} size={'sm'} onClick={() => onItems(row)}>{'Items'}</Button>
                          <Button color={'primary'} size={'sm'} onClick={() => onSave(props.values, index)}>{'SAVE'}</Button>
                        </div>
                      </div>
                    </Form>
                  ) : null}
                </Fragment>
              ))
            )}
          </Formik>
        </div>
        <TracePartnerSearchModal
          openModal={isOpenPartner}
          setOpenModal={setIsOpenPartner}
          title={'TRADE PARTNER'}
          action={'TradePartner'}
          queryText={queryText}
          onOK={onOK}
        />
      </div>
    );
  };
  onValidate = (values) => {
    const {partner_id} = values;
    if(!partner_id) {
      util.showWarning('Please enter Bill To');
      return undefined;
    }
    return values;
  };
}

export default CourierHouseBLAccountFormManager;
