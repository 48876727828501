/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import koreaPostLogo from '../media/Korea_Post_Logo-700x331.png';
import coshipLogo from '../media/logo@2x.png';
import {util} from "../services/service";

class ManifestPrint extends React.Component {
  render() {
    const {data = []} = this.props;
    if (data.length <= 0) {
      return null;
    }
    return (
      <div>{data.map((item, index) => this.renderItem(item, index)).flat()}</div>
    );
  }
  renderItem(item, index) {
    let {
      bl_no, mbl_no, order_no, korea_post, out_date, memo, ctns,
      shipper, shipper_address, shipper_phone,
      consignee, consignee_address, consignee_phone1, consignee_phone2, consignee_postal, items,
      weight, cal_weight,
    } = item;
    const itemsOfItem = [];
    for (let i = 0; i < parseInt(ctns); ++i) itemsOfItem.push(i);
    const itemsCount = items.length;
    let itemsToDisplay = items.filter((i, index) => index < 3).map(i => i.item_qty > 1 ? `${i.item}(${i.item_qty})` : i.item);
    const MAX_DESC_LEN = 50;
    if (itemsToDisplay.length > MAX_DESC_LEN) {
      itemsToDisplay = itemsToDisplay.substr(0, MAX_DESC_LEN) + '...';
    }
    const description = itemsToDisplay + (itemsCount > 3 ? `외 ${itemsCount - 3}건` : '') + ` / ${weight}KG`;
    let postal = consignee_postal?.replaceAll(`"`, '').replaceAll('=', '');
    bl_no = bl_no?.replaceAll(`"`, '').replaceAll('=', '');
    shipper_phone = shipper_phone?.replaceAll(`"`, '').replaceAll('=', '');
    consignee_phone1 = consignee_phone1?.replaceAll(`"`, '').replaceAll('=', '');
    consignee_phone2 = consignee_phone2?.replaceAll(`"`, '').replaceAll('=', '');
    // consignee = util.replaceAt(consignee, consignee.length < 5 ? 1 : consignee.length-3, consignee.length < 4 ? '*': '**');
    const style = {
      // width: '105mm',
      // height: '148mm',
      margin: '0 1cm',
      color: 'black',
      fontSize: 30,
    };
    const isSmall = cal_weight ? util.toFloat(cal_weight) <= 1 : weight ? util.toFloat(weight) <= 1 : false;
    return itemsOfItem.map((ctnsIndex) => {
      return (
        <div className={'flex-column page-break air-manifest-print-container'} style={style} key={`${index}-${ctnsIndex}`}>
          <div className={'flex mb-1'} style={{position: 'relative'}}>
            <div style={{flex: 0.6}}>
              <div className={'flex middle mb-1'}>
                <img src={koreaPostLogo} style={{width: '2.5cm'}} alt={'Korea Post'}/>
                <b className={'ml-4 fb-40'} style={{color: 'black'}}>우체국택배</b>
              </div>
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-1 '}>MAWB NO.</div>*/}
              {/*  <div className={'flex-2 '}>{mbl_no}</div>*/}
              {/*</div>*/}
              {/*<div className={'flex'}>*/}
              {/*  <div className={'flex-1 '}>DATE</div>*/}
              {/*  <div className={'flex-2 '}>{out_date}</div>*/}
              {/*</div>*/}
            </div>
            <div className={'flex-1'}>
              <div className={'mt-1'}>접수국: 국제우편물류센터</div>
            </div>
            <div style={{position: 'absolute', right: 0, top: 0}}>
              <img src={coshipLogo} style={{width: '5.5cm'}} alt={'COSHIP'} />
            </div>
          </div>
          <div className={'flex'}>
            <div className={'flex-1 '}>MAWB NO.</div>
            <div className={'flex-3 '}>{mbl_no}</div>
            <div className={'flex-2 '} />
          </div>
          <div className={'flex mb-1'}>
            <div className={'flex-1 '}>
              SHPR<br />(보내는 분)
            </div>
            <div className={'flex-3'} style={{fontSize: 35}}>
              <div>{shipper}</div>
              <div>{shipper_address}</div>
              <div>{shipper_phone}</div>
            </div>
            <div className={'flex-2'} style={{textAlign: 'center'}}>
              <Barcode value={postal} displayValue={false} height={150} width={4} />
              <div>우편번호 : {postal}</div>
            </div>
          </div>
          <div className={'flex mb-1'}>
            <div className={'flex-1 '}>
              CNNE<br />(받는 분)
            </div>
            <div style={{flex: 5, fontSize: 35}}>
              <div className={'fb-40'}>{consignee}</div>
              <div>{consignee_address}</div>
              <div>{consignee_phone1}{consignee_phone2 ? `/${consignee_phone2}`:''}</div>
            </div>
            {/*<div className={'flex-2'} style={{textAlign: 'center'}}>*/}
            {/*  /!*<Barcode value={postal} displayValue={false} height={150} width={4} />*!/*/}
            {/*  /!*<div>우편번호 : {postal}</div>*!/*/}
            {/*</div>*/}
          </div>
          <div className={'flex between middle mt-20 mb-20'}>
            <QRCode
              value={bl_no}
              size={120}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
            <div className={'flex-1 flex right middle'}>
              <div className={'fb-80'}>{korea_post?.[0]}</div>
              <div className={'fb-30 ml-3'} style={{paddingTop: 30}}>{korea_post?.[1]}</div>
              <div className={'fb-80 ml-3'}>{korea_post?.[2]}</div>
              <div className={'fb-30 ml-3'} style={{paddingTop: 30}}>{korea_post?.[3]}</div>
              {!isSmall && (
                <>
                  <div className={'fb-80 ml-3'}>{korea_post?.[4]}</div>
                  <div className={'fb-30 ml-2'} style={{paddingTop: 30}}>{korea_post?.[6] ? `- ${korea_post?.[6]} -` : ''}</div>
                  <div className={'fb-80 ml-2'}>{korea_post?.[5]}</div>
                </>
              )}
              {isSmall && (
                <div className={'ml-3'}>
                  <div className={'flex'} style={{backgroundColor: 'black', width: 150, height: 50, justifyContent: 'center', alignItems: 'center'}}>
                    <div className={'fb-60'} style={{color: 'white'}}>{korea_post?.[4]}</div>
                    <div className={'fb-60 ml-2'} style={{color: 'white'}}>{korea_post?.[5]}</div>
                  </div>
                  <div className={'fb-30'} style={{marginTop: -3, textAlign: 'center'}}>{korea_post?.[6] ? `- ${korea_post?.[6]} -` : ''}</div>
                </div>
              )}
            </div>
          </div>
          <div className={'mb-20 p-2'} style={{border: '2px solid black', minHeight: '4cm'}}>
            <div>배송메시지 :</div>
            <div>{memo}</div>
          </div>
          <div className={'flex-column center mb-20'}>
            <Barcode value={bl_no} displayValue={false} height={150} width={4} />
            <div>Tracking NO : {bl_no}</div>
          </div>
          <div className={'mb-1'}>
            <div>DESCRIPTION</div>
            <div>{description}</div>
          </div>
          {/*<div className={'flex'}>*/}
          {/*  <div className={'flex-1 '}>LOADING NO</div>*/}
          {/*  <div className={'flex-5 '}>HDSH2004071349</div>*/}
          {/*</div>*/}
          <div className={'flex mb-40'}>
            <div className={'flex-1 '}>주문번호</div>
            <div className={'flex-5 '}>{order_no}</div>
          </div>
          <div className={'flex-column center'}>
            <div>{ctnsIndex + 1} - {ctns}</div>
            <div>자가사용 목적 통관물품 상용판매시 처벌 받을 수 있음.</div>
          </div>
        </div>
      );
    });
  }
}

export function useManifestPreviewModal() {
  const [data, setData] = React.useState([]);
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'Air Manifest',
    width: 1024,
    children: <ManifestPrint ref={printRef} data={data} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      print();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setData(data);
      modal.open();
    },
    print,
  };
}
