/**
 * @flow
 */
import * as React from 'react';
import {api, util} from "../../../../services/service";
import {PrimaryButton} from "../../../../components/buttons";
import FilterPane from "../../../../components/FilterPane";
import ForwardingQuoteSearchFormManager from "../../../../forms/newCoship/forwarding/quote/ForwardingQuoteSearchFormManager";
import useForwardingQuoteGridView from "../../../../grids/newCoship/forwarding/quote/useForwardingQuoteGridView";
import {useForwardingQuoteModal} from "../../../../modals/newCoship/forwarding/useForwardingQuoteModal";
import {useForwardingQuotationPreviewModal} from "../../../../print-layouts/ForwardingQuotationPrint";
import {useTopbar} from "../../../../redux/reducers/topbarReducer";

const searchForm = new ForwardingQuoteSearchFormManager();

const ForwardingQuote = () => {
  useTopbar({label: 'Forwarding'}, {label: 'Quotation'});
  const {onSearch, quoteGrid, quoteModal, quotationPrintModal} = useForwardingQuote();
  searchForm.onSearch = onSearch;

  const onAdd = () => {
    quoteModal.open({});
  };

  const onClear = () => {
    searchForm.clearValues();
  };

  return (
    <div className={'flex'}>
      <div>
        <FilterPane>
          {searchForm.renderForm()}
          <PrimaryButton label={'APPLY'} onClick={onSearch} noMargin className={'w-full mt-3'} />
          <PrimaryButton label={'CLEAR'} onClick={onClear} noMargin className={'w-full mt-2'} />
        </FilterPane>
      </div>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        <div style={{marginTop: -10}}>
          <div style={{textAlign: 'right'}}>
            <PrimaryButton label={'ADD'} onClick={onAdd}/>
          </div>
          <div style={{marginTop: -65}}>
            {quoteGrid.render()}
          </div>
        </div>
      </div>
      {quoteModal.render()}
      {quotationPrintModal.render()}
    </div>
  );
};

function useForwardingQuote() {
  const onSearch = () => {
    const gridInfo = util.getGridInfo(quoteGrid.props.name);
    const values = searchForm.getValues();
    const fromDate = values.fromDate ? util.toStartTS(values.fromDate) : undefined;
    const toDate = values.toDate ? util.toEndTS(values.toDate) : undefined;
    const flag = values?.flag?.length > 0;
    const payload = {...api.getQueryListData(gridInfo), ...values, fromDate, toDate, flag};
    return quoteGrid.query(payload);
  };
  const quotationPrintModal = useForwardingQuotationPreviewModal();
  const quoteModal = useForwardingQuoteModal(onSearch, quotationPrintModal);
  const quoteGrid = useForwardingQuoteGridView((action, data) => {
    if (action === 'edit') {
      quoteModal.open(data);
    } else if (action === 'blMake') {
      util.openTab(`/admin/forwarding/house/ar?qid=${data.id}`);
    }
  });
  return {onSearch, quoteGrid, quoteModal, quotationPrintModal};
}

export default ForwardingQuote;
