/**
 * @flow
 */
import React from 'react';

export interface LinearIconProps {
  name: string;
}

const LinearIcon = (props: LinearIconProps) => {
  return <span className={`lnr lnr-${props.name}`} />;
};

export default LinearIcon;
