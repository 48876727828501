/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';
import {Card, CardBody, Modal} from 'reactstrap';
import {DefaultButton} from '../../components/buttons';

interface DashboardMessageListState {
  openModal: boolean;
  data: Object;
}

const DashboardMessageList = () => {
  const [state, setState] = React.useState<DashboardMessageListState>({openModal: false});
  return (
    <>
      <DataGridView
        name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
        addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
        actions={ACTIONS} actionWidth={ACTION_WIDTH}
        showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
        columns={COLUMNS}
        onAction={async (action, data) => onAction(action, data, state, setState)}
        onQuery={async (gridInfo) => onQuery(gridInfo)}
        useExtendedColDef
      />
      <MessageViewModal state={state} setState={setState} />
    </>
  );
};

const NAME = 'messageList';
const LABEL = 'Messages';
const ADD_LABEL = '';
const EDIT_LABEL = '';
const CATEGORY_LABEL = 'Dashboard';
const MENU_LABEL = 'Message';
const SORT_COL = 'cdate';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 0;
const SHOW_DATE_SEARCH = true;
const ACTIONS = ['detail'];
const ACTION_WIDTH = 70;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'id', required: false,
    field: 'id', hide: true},
  { name: 'message', required: false,
    field: 'message', hide: true},
  { name: 'cdate', required: false,
    field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 1},
  { name: 'uname', required: false,
    field: 'uname', headerName: 'Created by', flex: 1},
  { name: 'title', required: false,
    field: 'title', headerName: 'Title', flex: 2},
  { name: 'area_name', required: false,
    field: 'area_name', headerName: 'Branch', flex: 1},
  { name: 'udate', required: false,
    field: 'udate', headerName: 'Checked', valueFormatter: util.dateTimeFormatter, flex: 1},
];

async function onAction(action, data, state, setState) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
  switch (action) {
    case 'detail':
      setState({...state, data, openModal: true});
      return;
  }
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.messageList(gridInfo);
}

interface MessageViewModalProps {
  state: DashboardMessageListState;
  setState: (state: DashboardMessageListState) => void;
}

const MessageViewModal = (props: MessageViewModalProps) => {
  React.useEffect(() => {
    if (props.state.openModal === true) {
      api.setReadMessage(props.state.data.id).catch()
    }
  }, [props.state.openModal]);
  return (
    <Modal isOpen={props.state.openModal} className={'modal-dialog--form'} centered toggle={() => props.setState({...props.state, openModal: !props.state.openModal})}>
      <Card style={{paddingBottom: 0}}>
        <CardBody>
          <div className={'card__title mb-12'}>
            <h5 className={'bold-text mb-2'}>{props.state?.data?.title}</h5>
          </div>
          <p className={'mb-12'}>Created by {props.state?.data?.uname} ({util.formatDate(props.state?.data?.cdate)})</p>
          {props.state?.data?.message?.split('\n').map((line, index) => <div key={index}>{line}</div>)}
          <div className={'flex right mt-12'}>
            <DefaultButton label={'Close'} onClick={() => props.setState({...props.state, openModal: false})} noMargin />
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DashboardMessageList;
