/**
 * @flow
 */
import React from 'react';
import {api, util} from '../../services/service';
import useListAllGridView from '../../grids/useListAllGridView';
import SearchPane from '../../components/SearchPane';
import {PrimaryButton} from '../../components/buttons';
import BLSearchFormManager from '../../forms/bl/BLSearchForm';
import type {QueryListParams} from '../../services/API';
import {useUser} from "../../redux/reducers/userReducer";
import useCSVUploadModal from "../../modals/bl/useCSVUploadModal";

const searchForm = new BLSearchFormManager();

const BLList = () => {
  const {grid, search, clear, uploadModal, currentKind} = useBLList();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={() => search()} className={'w-full'} />
        <PrimaryButton label={'Clear'} onClick={() => clear()} className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {grid.render()}
        {currentKind === 'C' && (
          <div style={{position: 'absolute', left: 0, top: -12}}>
            <PrimaryButton label={'CSV/EXCEL UPLOAD'} onClick={() => uploadModal.open()} />
          </div>
        )}
      </div>
      {uploadModal.render()}
    </div>
  );
};

function useBLList() {
  const user = useUser();
  const [currentKind, setCurrentKind] = React.useState();
  const onRowClick = (data) => {
    if (data['h_m'] === 'M') {
      util.openTab(`/admin/bl/master/${data.id}`);
    } else {
      // if (user.auth === 30 && (data.kind === 'M' || data.kind === 'V' || data.kind === 'E')) {
      //   util.openTab(`/admin/moving/house/customer?id=${data.id}`);
      // } else {
      //   util.openTab(`/admin/bl/house/${data.id}`);
      // }
      util.openTab(`/admin/bl/house/${data.id}`);
    }
  };
  const grid = useListAllGridView('BL', undefined, undefined, undefined, onRowClick);
  const uploadModal = useCSVUploadModal((res) => {
    const message = res?.data?.[0]?.msg;
    if (message) {
      util.showSuccess(message);
      // const kind = searchForm.getValue('kind');
      // searchForm.clearValues();
      // searchForm.setValue('kind', kind);
      setTimeout(() => {
        search(); // 파일 업로드가 완료 되었으므로 목록을 재검색함!
      }, 0);
      uploadModal.close();
    }
  });
  const search = (qryText) => {
    const {kind, carrierType, bound, hm: HM, branchId, searchName, searchVal, partnerId, shipper: shipperConsignee, trackingNo, onlyWaitList, notLinkedAccount, flag: flagCheck, spaceId} = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const flag = flagCheck?.[0] === 'on';
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      kind, carrierType, bound, HM, branchId, searchName, searchVal, partnerId, shipperConsignee, trackingNo, onlyWaitList, notLinkedAccount, flag, spaceId: parseInt(spaceId)
    };
    if (qryText) {
      grid.query({...listParam, qryText});
    } else {
      grid.query(listParam);
    }
    setCurrentKind(searchForm.getValue('kind'));
  };
  const clear = () => {
    const gridInfo = util.getGridInfo(grid.props.name);
    gridInfo.qryText = '';
    searchForm.setValue('searchVal', '');
    document.getElementById('ListAllBL-search').value = '';
  };
  searchForm.onSearch = () => search();
  return {grid, search, clear, uploadModal, currentKind};
}

export default BLList;
