/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import Form, {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';
import {Card, CardBody, Modal} from 'reactstrap';
import {AgGridReact} from 'ag-grid-react';
import {ActionCell, defaultColDef, queryGridData} from '../../components/DataGrid';
import {DefaultButton, PrimaryButton} from '../../components/buttons';
import {useState} from '../../hooks/useState';
import CloseIcon from 'mdi-react/CloseIcon';
import {containerFormInfo} from '../../forms/settings/containerForm';
import type {DataGridActionType} from '../../components/DataGrid';
import {PortSearchInput} from '../../components/SearchInput';

interface AirVesselListState {
  openModal: boolean;
  selectedRow: Object;
  childRows: Object[];
  containerMode: 'add' | 'edit';
}

let currentState;
let currentSetState;

const AirVesselList = () => {
  const [state, setState] = useState<AirVesselListState>({openModal: false, childRows: [], containerMode: 'add'});
  currentState = state;
  currentSetState = setState;
  React.useEffect(() => {
    if (state.openModal === true) {
      loadContainerList();
    }
  }, [state.openModal]);
  async function onAction(action, data) {
    process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
    switch (action) {
      case 'add':
        return api.airVesselAdd(data);
      case 'edit':
        return api.airVesselEdit(data);
      case 'delete':
        const {id} = data;
        return api.airVesselDel({id});
      case 'addChild':
        if (!data.isair) {
          setState({openModal: true, selectedRow: data});
        }
        return;
    }
  }
  function loadContainerList() {
    const {id} = currentState.selectedRow;
    api.containerList(id).then(res => {
      const {data} = res;
      currentSetState({childRows: data.map(row => ({...row, pid: id}))});
      queryGridData(NAME, undefined, onQuery).catch();
    });
  }
  function onSaveContainer() {
    const values = containerFormInfo.getFieldValues();
    const data = {...values, craftId: currentState.selectedRow.craft_id, pId: currentState.selectedRow.id};
    if (currentState.containerMode === 'edit') {
      api.containerEdit(data).then(res => {
        loadContainerList();
        containerFormInfo.clearFieldValues();
        currentSetState({containerMode: 'add'});
      });
    } else {
      api.containerAdd(data).then(res => {
        loadContainerList();
        containerFormInfo.clearFieldValues();
      });
    }
  }
  function onContainerEditClick(data) {
    containerFormInfo.setFieldValues(data);
    currentSetState({containerMode: 'edit'});
  }
  function onContainerDeleteClick(data) {
    util.showConfirm('Are you sure to delete?', () => {
      api.containerDel({id: data.id, pId: currentState.selectedRow.id}).then(res => {
        loadContainerList();
        containerFormInfo.clearFieldValues();
      });
    });
  }
  function onContainerExitEdit() {
    containerFormInfo.clearFieldValues();
    currentSetState({containerMode: 'add'});
  }
  function getActionColumn(): ColDef {
    return {
      headerName: 'Actions',
      width: 80,
      minWidth: 80,
      cellRendererFramework: ActionCell,
      cellRendererParams: {
        onEditClick: onContainerEditClick,
        onDeleteClick: onContainerDeleteClick,
      }
    }
  }
  return (
    <>
      <DataGridView
        name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
        addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
        actions={ACTIONS} actionWidth={ACTION_WIDTH}
        showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
        columns={COLUMNS}
        onAction={async (action, data) => onAction(action, data)}
        onQuery={async (gridInfo) => onQuery(gridInfo)}
        renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
        useExtendedColDef
        isActionDisabled={isActionDisabled}
      />
      <Modal isOpen={state.openModal} className={'modal-dialog--form'} centered style={{width: 800}}>
        <Card style={{paddingBottom: 0}}>
          <CardBody>
            <div className={'card__title mb-12 flex between'}>
              <h5 className={'bold-text mb-2'}>CONTAINERS ({state.selectedRow?.craft_name})</h5>
              <a href={'/#close'} onClick={e => {e.preventDefault(); setState({openModal: false})}}>
                <CloseIcon color={'#606060'} />
              </a>
            </div>
            <div className={`ag-theme-balham`} style={{width: 'calc(100% - 4px)', height: 250}}>
              <AgGridReact
                columnDefs={[...containerFormInfo.columns, getActionColumn()]}
                rowData={state.childRows}
                defaultColDef={defaultColDef}
                onCellClicked={param => {
                  if (param.column.getColDef().headerName === 'Actions') {
                    return;
                  }
                  onContainerEditClick(param.data);
                }}
              />
            </div>
            <Form
              onFormik={fmk => containerFormInfo.formik = fmk}
              horizontal
              doNotUseButtons
              doNotUseCard
              fields={containerFormInfo.fields}
              values={{}}
              render={(formik, fields, errors) => containerFormInfo.render(formik, fields, errors)}
            />
            <div className={'flex center'}>
              <PrimaryButton label={state.containerMode === 'add' ? 'Add' : 'Edit'} onClick={onSaveContainer} noMargin className={'mr-1'} />
              {state.containerMode === 'edit' && <DefaultButton label={'Clear'} onClick={onContainerExitEdit} noMargin />}
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

const NAME = 'airVesselList';
const LABEL = 'Air/Vessel';
const ADD_LABEL = 'ADD AIR/VESSEL';
const EDIT_LABEL = 'EDIT AIR/VESSEL';
const CATEGORY_LABEL = 'Settings';
const MENU_LABEL = 'Air/Vessel';
const SORT_COL = 'craft_name';
const IS_DESC = false;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 540;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit', 'delete', 'addChild'];
const ACTION_WIDTH = 100;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'id', required: false,
    field: 'id', hide: true},
  { name: 'pol', required: false, label: 'POL',
    field: 'pol', hide: true},
  { name: 'pod', required: false, label: 'POD',
    field: 'pod', hide: true},
  { name: 'isAir', required: false, type: 'checkbox',
    field: 'isair', headerName: 'Air', valueFormatter: util.yesNoFormatter, flex: 0.5},
  { name: 'craftCode', required: true,
    field: 'craft_code', headerName: 'MASTER No.', flex: 1},
  { name: 'craftName', required: true,
    field: 'craft_name', headerName: 'Name', flex: 1},
  { name: 'etdDate', required: false, type: 'date', mask: util.MASK_DATE,
    field: 'etd_date', headerName: 'ETD', valueFormatter: util.dateFormatter, flex: 0.5},
  { name: 'etaDate', required: false, type: 'date', mask: util.MASK_DATE,
    field: 'eta_date', headerName: 'ETA', valueFormatter: util.dateFormatter, flex: 0.5},
  { name: 'polName', required: false,
    field: 'pol_name', headerName: 'POL', flex: 0.5},
  { name: 'podName', required: false,
    field: 'pod_name', headerName: 'POD', flex: 0.5},
  { name: 'ccount', required: false,
    field: 'ccount', headerName: 'Containers', flex: 0.5},
  { name: 'footer', required: false,
    field: 'footer', hide: true},
];

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  // const {data: rows, totalCount} = await api.airVesselList(gridInfo);
  // return {data: rows.map(row => ({...row, db_craft_id: row.craft_id})), totalCount};
  return api.airVesselList(gridInfo);
}

function renderForm(formik, fields, errors) {
  const render = (name) => renderField(formik, name, fields, errors);
  return (
    <>
      {render('craftCode')}
      {render('craftName')}
      {render('etdDate')}
      {render('etaDate')}
      <PortSearchInput
        displayField={'polName'}
        idField={'pol'}
        formik={formik} fields={fields} errors={errors}
      />
      <PortSearchInput
        displayField={'podName'}
        idField={'pod'}
        formik={formik} fields={fields} errors={errors}
      />
      {render('isAir')}
    </>
  );
}

function isActionDisabled(type: DataGridActionType, data: Object) {
  if (type === 'addChild') return data['isair'] === true;
}

export default AirVesselList;
