/**
 * @flow
 */
import React from 'react';
import {useTradePartnerGridView} from '../../grids/useTradePartnerGridView';
import {PrimaryButton} from '../../components/buttons';
import {useCUDGridModal, useSimpleEditModal} from '../../components/SimpleModal';
import TradePartnerFormManager from '../../forms/settings/TradePartnerFormManager';
import PartnerListSearchFormManager from '../../forms/partner/PartnerListSearchFormManager';
import ContactInfoFormManager from '../../forms/settings/ContactInfoFormManager';
import BankInfoFormManager from '../../forms/settings/BankInfoFormManager';
import {api, util} from '../../services/service';
import SearchPane from "../../components/SearchPane";
import type {QueryListParams} from "../../services/API";
import usePartnerHistoryModal from "../../modals/usePartnerHistoryModal";

const searchForm = new PartnerListSearchFormManager();

const TradingPartnerView = () => {
  const qs = util.getQS();
  const {search} = qs;
  const {grid, modal, contactModal, bankModal, partnerHistoryModal, onSearch} = useTradingPartnerView(search);
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {grid.render()}
        <div style={{position: 'absolute', left: 0, top: -12}}>
          <PrimaryButton label={'ADD TRADE PARTNER'} onClick={() => modal.open()} />
        </div>
      </div>
      {modal.render()}
      {contactModal.render()}
      {bankModal.render()}
      {partnerHistoryModal.render()}
    </div>  );
};

function useTradingPartnerView(searchText) {
  const grid = useTradePartnerGridView(async (action, data) => {
    switch (action) {
      case 'edit':
        const {pickup_day, ...editData} = data;
        editData['sunCheck'] = pickup_day.find(day => day === 'Sun') !== undefined;
        editData['monCheck'] = pickup_day.find(day => day === 'Mon') !== undefined;
        editData['tueCheck'] = pickup_day.find(day => day === 'Tue') !== undefined;
        editData['wedCheck'] = pickup_day.find(day => day === 'Wed') !== undefined;
        editData['thuCheck'] = pickup_day.find(day => day === 'Thu') !== undefined;
        editData['friCheck'] = pickup_day.find(day => day === 'Fri') !== undefined;
        editData['satCheck'] = pickup_day.find(day => day === 'Sat') !== undefined;
        modal.open('edit', editData);
        break;
      case 'detail':
        contactModal.open(data);
        break;
      case 'commission':
        bankModal.open(data);
        break;
      case 'partnerHistory':
        partnerHistoryModal.open(data);
        break;
      default:
        // DO NOTHING!
        break;
    }
  }, searchText);
  const modal = useTradePartnerEditModal(async (mode, data) => {
    if (data) {
      const isEdit = mode === 'edit';
      const isChecked = (value) => value === true || value?.[0] === 'on';
      let {isActive, sunCheck, monCheck, tueCheck, wedCheck, thuCheck, friCheck, satCheck, labelPrint, ...others} = data;
      isActive = isChecked(isActive);
      sunCheck = isChecked(sunCheck);
      monCheck = isChecked(monCheck);
      tueCheck = isChecked(tueCheck);
      wedCheck = isChecked(wedCheck);
      thuCheck = isChecked(thuCheck);
      friCheck = isChecked(friCheck);
      satCheck = isChecked(satCheck);
      labelPrint = isChecked(labelPrint);
      const pickupDay = [];
      sunCheck && pickupDay.push('Sun');
      monCheck && pickupDay.push('Mon');
      tueCheck && pickupDay.push('Tue');
      wedCheck && pickupDay.push('Wed');
      thuCheck && pickupDay.push('Thu');
      friCheck && pickupDay.push('Fri');
      satCheck && pickupDay.push('Sat');
      let res;
      if(mode === 'edit') {
        res = await (api.forwardTradePartnerEdit({...others, isActive, pickupDay, labelPrint}));
      } else if(mode === 'delete') {
        console.log('hello partner delete', data);
      } else {
        res = await (api.forwardTradePartnerAdd({...others, isActive, pickupDay, labelPrint}));
      }
      // const res = await (
      //   isEdit
      //     ? api.forwardTradePartnerEdit({...others, isActive, pickupDay})
      //     : api.forwardTradePartnerAdd({...others, isActive, pickupDay})
      // );
      if (res) {
        util.showSuccess('Trade partner has been saved successfully.');
        modal.close();
      }
    }
  });

  const onSearch = () => {
    const searchValues = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      ...searchValues
    };
    grid.query(listParam);
  };

  const contactModal = useContactInfoModal();
  const bankModal = useBankInfoModal();
  const partnerHistoryModal = usePartnerHistoryModal();
  React.useEffect(() => {
    if (modal.isOpen === false) {
      grid.query();
    }
  }, [modal.isOpen]);
  React.useEffect(() => {
    const qs = util.getQS();
    if (qs.add === 'true') {
      modal.open();
    } else if (qs.edit > 0) {
      modal.openWithId(qs.edit);
      const gridInfo = util.getGridInfo(grid.props.name);
      const listParam: QueryListParams = {
        ...api.getQueryListData(gridInfo),
        page: 1,
        qryText: qs.sk,
      };
      grid.query(listParam);
    }
  }, []);
  searchForm.onSearch = onSearch;
  return {grid, modal, contactModal, bankModal, partnerHistoryModal, onSearch};
}

const tradePartnerForm = new TradePartnerFormManager();

function useTradePartnerEditModal(onSave) {
  const modal = useSimpleEditModal({
    title: 'TRADE PARTNER',
    width: 1200,
    form: tradePartnerForm,
    onSave,
  });
  return {
    ...modal,
    openWithId: (id) => {
      api.forwardTradePartnerView(id).then(({data}) => {
        modal.open('edit', data[0]);
      });
    },
  };
}

const contactInfoForm = new ContactInfoFormManager();
const bankInfoForm = new BankInfoFormManager();

function useContactInfoModal() {
  async function onQuery(queryData: Object) {
    return api.forwardTradePartnerContactList(queryData.id);
  }
  async function onCUD(mode, data) {
    const id = modal.queryData.id;
    if (mode === 'delete') {
      const jobId = data.job_id;
      return api.forwardTradePartnerContactDel({id, jobId});
    } else {
      const isrep = data.isrep === true || data.isrep?.[0] === 'on';
      return api.forwardTradePartnerContactAdd({...data, id, isrep});
    }
  }
  const {SN} = ContactInfoFormManager;
  const modal = useCUDGridModal({
    title: 'CONTACT INFO',
    centered: true,
    width: 900,
    gridProps: {
      columns: [
        {field: SN.DIVISION, headerName: 'Division', width: 100},
        {field: SN.NAME, headerName: 'Name', flex: 1},
        {field: SN.EMAIL, headerName: 'Email', flex: 1},
        {field: SN.PHONE, headerName: 'Phone', width: 100},
        {field: SN.CELL, headerName: 'Cell', width: 100},
        {field: SN.FAX, headerName: 'Fax', width: 100},
        {field: SN.REP, headerName: 'Rep.', valueFormatter: util.yesNoFormatter, width: 50, minWidth: 50},
        {field: SN.REMARK, headerName: 'Remark', flex: 1},
      ],
      rows: [],
      height: 250,
      className: 'mb-20',
    },
  }, contactInfoForm, onQuery, onCUD);
  return modal;
}

function useBankInfoModal() {
  async function onQuery(queryData) {
    return api.forwardTradePartnerBankList(queryData.id);
  }
  async function onCUD(mode, data) {
    const id = modal.queryData.id;
    if (mode === 'delete') {
      const jobId = data.job_id;
      return api.forwardTradePartnerBankDel({id, jobId});
    } else {
      return api.forwardTradePartnerBankAdd({...data, id});
    }
  }
  const {SN} = BankInfoFormManager;
  const modal = useCUDGridModal({
    title: 'BANK INFO',
    centered: true,
    width: 800,
    gridProps: {
      columns: [
        {field: SN.NAME, headerName: 'Name', flex: 1},
        {field: SN.ACCOUNT_NO, headerName: 'Account No.', flex: 1},
        {field: SN.HOLDER, headerName: 'Holder', flex: 1},
        {field: SN.REMARK, headerName: 'Remark', flex: 1},
        {field: SN.MEMO, headerName: 'Memo', flex: 1},
      ],
      rows: [],
      height: 250,
      className: 'mb-20',
    },
  }, bankInfoForm, onQuery, onCUD);
  return modal;
}

export default TradingPartnerView;
