/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import AccountInvoiceAPFormManager, {getAccountEntryData, getAccountFormData} from '../../forms/account/AccountInvoiceAPFormManager';
import AccountTopSearchFormManager from '../../forms/account/AccountTopSearchFormManager';
import AccountInvoiceAddItemFormManager from '../../forms/account/AccountInvoiceAddItemFormManager';
import {DangerButton, DefaultButton} from '../../components/buttons';
import {apiAccount, util} from '../../services/service';
import {useUser} from "../../redux/reducers/userReducer";
import {INVOICE} from "../../forms/field-defs/account";
import {getBranchName, onChcekBranch} from "./AccountInvoiceAR";

const type = INVOICE.TYPE_AP;
const title = `Invoice ${INVOICE.TYPE_AP_LABEL}`;

const accountForm = new AccountInvoiceAPFormManager();
const accountSearchForm = new AccountTopSearchFormManager(type);
const accountInvoiceAddItem = new AccountInvoiceAddItemFormManager(type);

const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const AccountInvoiceAP = () => {
  useTopbar({label: 'Account'}, {label: title});
  const [pId, setPId] = React.useState(0);
  const [mode, setMode] = React.useState('add');
  const [editTime, setEditTime] = React.useState();
  const [isBranch, setIsBranch] = React.useState(false);
  const [branchId, setBranchId] = React.useState();
  const invoiceDateRef = React.useRef();
  const qs = util.getQS();
  const {id, blNo} = qs;
  const user = useUser();
  const userBranchId = user.branch_id;
  const userBranchIdRef = React.useRef();
  const isBranchRef = React.useRef(false);
  const isAccountManager = user.isManager && user.team_main === 'A';
  const isManagerRef = React.useRef();
  const isAccountRef = React.useRef();
  const isShowSaveRef = React.useRef(false);
  const isShowDeleteRef = React.useRef(false);
  const isBeforeOctRef = React.useRef();
  const isAccountDirector = user.isDirector || user.team_main === 'A';
  userBranchIdRef.current = user.branch_id;
  isBranchRef.current = onChcekBranch(branchId, userBranchIdRef.current, accountForm);
  isBeforeOctRef.current = util.toEndDateTS(util.getSpecificDate('2022-09-30')) >= invoiceDateRef.current;
  isManagerRef.current = user.isManager;
  isAccountRef.current = isAccountDirector && id;
  // isAccountRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? true : (user.isDirector || isAccountManager) && id;
  // isSaveDisabledRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? isBeforeOctRef.current ? !isManagerRef.current : !(isBranch && isManagerRef.current) : !isManagerRef.current;
  // isDeleteDisabledRef.current = getBranchName(userBranchId, accountForm) === 'TORONTO' ? isBeforeOctRef.current ? !isAccountRef.current : !(isBranch && isAccountRef.current) : !isAccountRef.current;
  isShowSaveRef.current = getBranchName(userBranchIdRef.current, accountForm) === 'TORONTO' ? isBranchRef.current : id;
  isShowDeleteRef.current = getBranchName(userBranchIdRef.current, accountForm) === 'TORONTO' ? false : isAccountRef.current;
  accountSearchForm.mode = mode;
  document.title = `${title} ${mode}`;

  const onSave = async () => {
    // if(!util.isValidPermitForAccount(user, mode)) {
    //   return util.showError('Sorry, you can not edit Account Info.');
    // }
    const values = accountForm.getValues(undefined, true);
    if (!values) {
      return;
    }
    const gridData = accountInvoiceAddItem.getGridData();
    const data = getAccountEntryData(values, gridData);
    const res = await apiAccount.accountSet(data);
    if (res) {
      util.showSuccess('Account Entry has been saved successfully.');
      // 추가로 할일 있으면 여기서 하면 됩니다.
      setMode('edit');
      accountSearchForm.mode = 'edit';
      setPId(res.id);
      document.title = `${title} Edit`;
      if(id) {
        onSearch();
      } else {
        util.nav(`/admin/account/list/ap?id=${res.id}`);
      }
    }
  };

  const onDelete = async () => {
    if(id) {
      util.showConfirm('Are you sure to delete?', async () => {
        const res = await apiAccount.deleteAccountData(parseInt(id));
        if(res) {
          util.showSuccess('Account has been deleted successfully!');
          util.nav('/admin/account/list');
        }
      })
    }
  };

  const renderButtons = () => {
    if(id) {
      return (
        <>
          {/*<DefaultButton disabled={isBeforeOctRef.current ? !isManagerRef.current : !(isBranch && isManagerRef.current)} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />*/}
          {/*<DangerButton disabled={isBeforeOctRef.current ? !isAccountRef.current : !(isBranch && isAccountRef.current)} label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} />*/}
          <DefaultButton disabled={!isShowSaveRef.current} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
          <DangerButton disabled={!isShowDeleteRef.current} label={'Delete'} onClick={onDelete} noMargin /><div className={'w-8'} />
        </>
      );
    } else {
      return (
        <>
        <DefaultButton label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
        </>
      );
    }
  };

  const renderBottomButtons = () => {
    if(id) {
      return (
        <>
        {/*<DefaultButton disabled={isBeforeOctRef.current ? !isManagerRef.current : !(isBranch && isManagerRef.current)} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />*/}
        <DefaultButton disabled={!isShowSaveRef.current} label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
        </>
      );
    } else {
      return (
        <>
        <DefaultButton label={'Save'} onClick={onSave} noMargin /><div className={'w-8'} />
        </>
      );
    }
  };

  accountSearchForm.onEnter = async () => {
    const res = await apiAccount.accountTradeBLData(accountSearchForm.getValues());
    const {data} = res;
    if (Array.isArray(data) && data.length === 1) {
      const {formData} = getAccountFormData(data[0]);
      formData.id = '';
      formData.invoiceNo = ''
      let branchId = formData.branchId;
      accountForm.setValuesFast({...formData, branchId: branchId ?? user.branch_id});
    } else {
      // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
    }
  };

  const onSearch = () => {
    const res = apiAccount.accountTradeBLData({id: id}).then(({data}) => {
      if (Array.isArray(data) && data.length === 1) {
        const {formData, gridData} = getAccountFormData(data[0]);
        const [{bl_no, inv_no, jextra: {financialOffice, invoiceDate}, jcommon: {filingNo, branchId}}] = data;
        accountSearchForm.setValues({bl_no, inv_no, filingNo});
        accountForm.setValuesFast({...formData, bl_no, invoiceNo: inv_no, branchId: user.branch_id ?? userBranchId});
        let gridRowsData = gridData.map((row, index) => row = {...row, id: index});
        invoiceDateRef.current = invoiceDate;
        setTimeout(() => {
          accountInvoiceAddItem.setGridData(gridRowsData);
          accountInvoiceAddItem.setAccountId(id);
        }, 0);
        setEditTime(formData.footer.edit[0]);
        setBranchId(financialOffice);
      } else {
        // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
      }
    });
  };

  const onSearchWithBlNo = () => {
    accountSearchForm.setValue('blNo', blNo);
    apiAccount.accountTradeBLData({blNo: blNo }).then((res) => {
      const {data} = res;
      if (Array.isArray(data) && data.length === 1) {
        const {formData} = getAccountFormData(data[0]);
        formData.id = '';
        formData.invoiceNo = ''
        let branchId = formData.branchId;
        accountForm.setValuesFast({...formData, branchId: branchId ?? user.branch_id});
      } else {
        // ON ERROR - 아무것도 하지 않아도 됩니다! 자동으로 팝업이 뜹니다.
      }
    });
  };

  React.useEffect(() => {
    if(id) {
      setMode('edit');
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      onSearch();
    } else if (blNo) {
      onSearchWithBlNo();
    }
  }, [id, blNo]);

  // React.useEffect(() => {
  //   if (branchId && userBranchId) {
  //     setIsBranch(onChcekBranch(branchId, userBranchId, accountForm));
  //   }
  // }, [branchId, userBranchId]);
  return (
    <>
      <Card style={cardStyle}>
        <CardBody className={'pl-8 pr-20 pt-12 pb-1'} style={{position: 'relative'}}>
          <div className={'flex between w-full mb-12'}>
            <b className={'ml-12'}>{title}</b>
            <div className={'flex right'}>
              {renderButtons()}
            </div>
          </div>
          {accountSearchForm.renderForm()}
          {!!id && accountForm.formik && util.renderFooter(accountForm.getValue('footer'), 'house-footer')}
        </CardBody>
      </Card>
      {accountForm.renderForm()}
      {accountInvoiceAddItem.renderForm()}
      <div className={'flex center'} style={{marginTop: '-20px', paddingBottom: '20px'}}>
        {renderBottomButtons()}
      </div>
    </>
  );
};

export default AccountInvoiceAP;
