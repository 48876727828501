/**
 * @flow
 */
import React from 'react';
import {ColDef, GridApi} from 'ag-grid-community';
import {apiBL, util} from '../../services/service';
import SearchPane from '../../components/SearchPane';
import {DefaultButton, PrimaryButton} from '../../components/buttons';
import BLManifestSearchForm from '../../forms/bl/BLManifestSearchForm';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {useManifestPreviewModal} from '../../print-layouts/ManifestPrint';
import {useManifestPreviewModal2} from "../../print-layouts/ManifestPrint2";
import {useManifestPreviewModal3} from "../../print-layouts/ManifestPrint3";
import {useAsyncStatusModal} from '../../components/AsyncStatusModal';
import {useUser} from "../../redux/reducers/userReducer";
import {labelType} from "../../forms/helper";

const searchForm = new BLManifestSearchForm(false);

const BLManifest = () => {
  const {grid, search, download, print, manifestPreviewModal, manifestPreviewModal2, manifestPreviewModal3, asyncStatusModal, totalWeight, totalCalWeight, totalCtns} = useBLManifest();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={search} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 flex-column ml-24'}>
        <div className={'flex mb-8'}>
          <DefaultButton label={'Export Excel'} onClick={() => download()} noMargin />
          <div className={'mr-8'}/>
          <DefaultButton label={'Print'} onClick={() => print()} noMargin />
        </div>
        {grid.render()}
        {manifestPreviewModal.render()}
        {manifestPreviewModal2.render()}
        {manifestPreviewModal3.render()}
        {asyncStatusModal.render()}
        <div className={'flex'} style={{justifyContent: 'flex-end', paddingTop: 10, paddingRight: 5}}>
          <div style={{paddingRight: 30}}>Total Weight: {totalWeight}</div>
          <div style={{paddingRight: 30}}>Total Volume: {totalCalWeight}</div>
          <div>Total Ctns: {totalCtns}</div>
        </div>
      </div>
    </div>
  );
};

function useBLManifest() {
  const user = useUser();
  useTopbar({label: 'B/L'}, {label: 'Manifest'});
  const grid = useSimpleGrid({
    columns: getColumns(),
    height: '100%',
    className: '',
    actions: ['print'],
    actionWidth: 50,
    agGridProps: {
      defaultColDef: {
        width: 150,
        resizable: true,
      },
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
    },
    onAction: (action, data) => {
      if (action === 'print') {
        if(user.label_type === labelType.LABEL3) {
          manifestPreviewModal3.open([data], data.ctns > 1 ? true : false);
        } else if(user.label_type === labelType.LABEL2) {
          manifestPreviewModal2.open([data], data.ctns > 1 ? true : false);
        } else {
          manifestPreviewModal.open([data]);
        }
        apiBL.setBLPrint([data['bl_no'].replaceAll('"', '').replaceAll('=', '')]).then(() => {
          if(user.label_type === labelType.LABEL3) {
            manifestPreviewModal3.print();
          } else if(user.label_type === labelType.LABEL2) {
            manifestPreviewModal2.print();
          } else {
            manifestPreviewModal.print();
          }
          search();
        });
        // setTimeout(() => {
        //   manifestPreviewModal.print();
        // }, 500);
      }
    },
  });
  const manifestPreviewModal = useManifestPreviewModal();
  const manifestPreviewModal2 = useManifestPreviewModal2();
  const manifestPreviewModal3 = useManifestPreviewModal3();
  const asyncStatusModal = useAsyncStatusModal('Searching... please wait...');
  const [totalWeight, setTotalWeight] = React.useState('0.0');
  const [totalCalWeight, setTotalCalWeight] = React.useState('0.0');
  const [totalCtns, setTotalCtns] = React.useState(0);
  const search = async () => {
    const {branchId, filingNo, blNo, flightDate, print, shipper, consignee} = searchForm.getValues();
    if (branchId || filingNo || blNo) {
      const flightDateTS = util.toTS(flightDate ? flightDate : new Date());
      const branchName = getBranchName(branchId);
      let printOption = undefined;
      if (print === 'Y') {
        printOption = true;
      } else if (print === 'N') {
        printOption = false;
      }
      asyncStatusModal.open();
      const {data} = await apiBL.getBLManifestList({
        branchId: util.toInt(branchId), branchName, filingNo, blNo, flightDate: flightDateTS,
        print: printOption, shipper, consignee
      });
      asyncStatusModal.close();
      if (!data) {
        return;
      }
      let tWeight = 0, tCalWeight = 0, tCtns = 0;
      for (const {weight = 0, cal_weight = 0, ctns = 0} of data) {
        tWeight += parseFloat(util.formatWeight({value: weight}));
        tCalWeight += parseFloat(util.formatWeight({value: cal_weight}));
        tCtns += parseInt(ctns);
      }
      setTotalWeight(util.formatWeight({value: tWeight}));
      setTotalCalWeight(util.formatWeight({value: tCalWeight}));
      setTotalCtns(tCtns);
      const isSeoul = getBranchName(branchId) === 'SEOUL';
      const rows = buildRows(data, isSeoul);
      grid.setRows(rows);
    }
  };
  const download = () => {
    if (grid.rows?.length > 0) {
      const api: GridApi = grid.apiRef.current;
      const {flightDate} = searchForm.getValues();
      checkValidation(grid.rows);
      const columns = getColumns();
      api.exportDataAsCsv({
        fileName: `manifest-${flightDate}.csv`,
        columnKeys: columns.filter((c, index) => index > 0).map(c => c.field),
      });
    }
  };
  const checkValidation = (rows) => {
    let weight_message, price_message, info_message, err_message = '';
    let tPrice = 0;
    rows.map((row) => {
      const {bl_no, cal_weight, shipper, consignee, consignee_phone1, consignee_address, items, import_no} = row;
      tPrice = items.reduce((acc, cur) => acc + (cur.unit_price * cur.item_qty), 0);
      if(cal_weight >= 30) {
        weight_message = 'over 30kg, ';
      }
      if(tPrice > 150) {
        price_message = 'over $150, ';
      }
      if(!(cal_weight && shipper && consignee && consignee_phone1 && consignee_address && import_no)) {
        info_message = 'Missing data!';
      }
      if(weight_message || price_message || info_message) {
        err_message += `${bl_no.replaceAll('"', '').replaceAll('=', '')} : ${weight_message??''} ${price_message??''} ${info_message??''}\n`;
      }
      weight_message = '';
      price_message = '';
      info_message = '';
      tPrice = 0;
    });
    if(err_message) {
      util.showWarning(err_message);
    }
  };
  const print = () => {
    const rows = grid.apiRef.current.getSelectedRows();
    if (rows?.length > 0) {
      if (user.label_type === labelType.LABEL3) {
        manifestPreviewModal3.open(rows, rows.length > 1 ? true : (rows.length === 1 && rows[0].ctns > 1));
      } else if(user.label_type === labelType.LABEL2) {
        manifestPreviewModal2.open(rows, rows.length > 1 ? true : (rows.length === 1 && rows[0].ctns > 1));
      } else {
        manifestPreviewModal.open(rows);
      }
      apiBL.setBLPrint(rows.map(r => r['bl_no'].replaceAll('"', '').replaceAll('=', ''))).then(() => search());
    }
    // if (grid.rows?.length > 0) {
    //   manifestPreviewModal.open(grid.rows);
    //   // setTimeout(() => {
    //   //   manifestPreviewModal.print();
    //   // }, 500);
    // }
  };
  searchForm.onSearch = () => {
    search().catch();
  };
  return {grid, search, download, print, manifestPreviewModal, manifestPreviewModal2, manifestPreviewModal3, asyncStatusModal, totalWeight, totalCalWeight, totalCtns};
}

function getColumns(): ColDef[] {
  if (!searchForm.formik) {
    return [];
  }
  const branchId = searchForm.getValue('branchId');
  const isSeoul = getBranchName(branchId) === 'SEOUL';
  if (isSeoul) {
    return [
      {field: 'bl_no', headerName: 'Consignment Number'},
      {field: 'ref_no', headerName: 'Reference'},
      {field: 'bar_code', headerName: 'Barcodes'},
      {field: 'account_no', headerName: 'Internal Account Number'},
      {field: 'shipper', headerName: 'Shipper'},
      {field: 'shipper_address1', headerName: 'Shipper Address 1'},
      {field: 'shipper_address2', headerName: 'Shipper Address 2'},
      {field: 'shipper_address3', headerName: 'Shipper Address 3'},
      {field: 'shipper_city', headerName: 'Shipper City'},
      {field: 'shipper_state', headerName: 'Shipper County/State'},
      {field: 'shipper_postal', headerName: 'Shipper Zip'},
      {field: 'shipper_country', headerName: 'Shipper Country Code'},
      {field: 'consignee', headerName: 'Consignee'},
      {field: 'consignee_address1', headerName: 'Address1'},
      {field: 'consignee_address2', headerName: 'Address2'},
      {field: 'consignee_address3', headerName: 'Address3'},
      {field: 'consignee_city', headerName: 'City'},
      {field: 'consignee_province', headerName: 'Province'},
      {field: 'consignee_provincecode', headerName: 'Province Code'},
      {field: 'consignee_postal', headerName: 'Zip'},
      {field: 'consignee_country', headerName: 'Country Code'},
      {field: 'consignee_email', headerName: 'Email'},
      {field: 'consignee_phone', headerName: 'Phone'},
      {field: 'pieces', headerName: 'Pieces'},
      {field: 'total_weight', headerName: 'Total Weight'},
      {field: 'weight_uom', headerName: 'Weight UOM'},
      {field: 'total_value', headerName: 'Total Value'},
      {field: 'currency', headerName: 'Currency'},
      {field: 'incoterms', headerName: 'Incoterms'},
      {field: 'item', headerName: 'Item Description'},
      {field: 'hs_code', headerName: 'Item HS Code'},
      {field: 'item_qty', headerName: 'Item Quantity'},
      {field: 'item_value', headerName: 'Item Value'},
      {field: 'country_origin', headerName: 'Country Of Origin'},
    ];
  } else {
    const columns: ColDef[] = [
      {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
      {field: 'order_no', headerName: 'NO', width: 100},
      {field: 'bl_no', headerName: 'HAWB', valueFormatter: postalTextFormatter, cellRendererFramework: RenderHBL, width: 120},
      {field: 'out_date', headerName: 'DATE', width: 100},
      {field: 'shipper', headerName: 'SHIPPER', width: 120},
      {field: 'shipper_phone', headerName: 'PHONE', valueFormatter: postalTextFormatter, width: 100},
      {field: 'shipper_postal', headerName: 'POSTAL', valueFormatter: postalTextFormatter, width: 80, minWidth: 80},
      {field: 'shipper_address', headerName: 'ADDRESS'},
      {field: 'consignee', headerName: 'RECEIVER', width: 100},
      {field: 'consignee_phone1', headerName: 'PHONE', valueFormatter: postalTextFormatter, width: 100},
      {field: 'consignee_phone2', headerName: 'PHONE 2', valueFormatter: postalTextFormatter, width: 100},
      {field: 'consignee_postal', headerName: 'POSTAL', valueFormatter: postalTextFormatter, width: 80, minWidth: 80},
      {field: 'consignee_address', headerName: 'ADDRESS'},
      {field: 'import_no', headerName: 'ID', width: 100},
      {field: 'remark', headerName: 'REMARK'},
      {field: 'memo', headerName: 'MEMO'},
      {field: 'weight', headerName: 'WEIGHT', width: 70, minWidth: 70},
      {field: 'cal_weight', headerName: 'VOLUME', width: 70, minWidth: 70},
      {field: 'ctns', headerName: 'CTNS', width: 60, minWidth: 60},
      // {field: 'hs_code', headerName: 'HS CODE', editable: true},
      // {field: 'item_no', headerName: 'Item No'},
      // {field: 'item', headerName: 'Item'},
      // {field: 'item_qty', headerName: 'Qty'},
      // {field: 'unit_price', headerName: 'Unit Price'},
      // {field: 'brand', headerName: 'Brand'},
      // {field: 'url', headerName: 'URL'},
    ];
    for (let i = 0; i < 20; ++i) {
      columns.push({field: `item${i}`, headerName: `ITEM NAME ${i + 1}`, width: 100});
      columns.push({field: `item_qty${i}`, headerName: 'QUANTITY', width: 100});
      columns.push({field: `unit_price${i}`, headerName: 'PRICE', width: 100});
      columns.push({field: `brand${i}`, headerName: '', width: 100});
      columns.push({field: `url${i}`, headerName: 'PRODUCT URL', width: 100});
      columns.push({field: `hs_code${i === 0 ? '' : i}`, headerName: 'HS CODE', width: 100});
    }
    return columns;
  }
}

function RenderHBL(params) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
      e.preventDefault();
      // util.nav(`/admin/bl/house/${params.data.bl_id}`);
      util.openTab(`/admin/bl/house/${params.data.bl_id}`);
    }}>
      {params.value?.replaceAll('"', '').replaceAll('=', '')}
    </a>
  );
}

function postalTextFormatter(p) {
  return p.value.toString().replaceAll('"', '').replace('=', '');
}

function getBranchName(branchId) {
  let branchOptions;
  try {
    branchOptions = searchForm.getField('branchId').options;
  } catch (error) {
    branchOptions = [];
  }
  return branchOptions.find(i => i.value === parseInt(branchId))?.label;
}

function buildRows(data: Object[], isSeoul: boolean) {
  if (isSeoul) {
    const totalValues = {};
    for (const {bl_no} of data) totalValues[bl_no] = 0;
    for (const {bl_no, item_qty, item_value} of data) totalValues[bl_no] += item_qty * item_value;
    return data.map((row) => ({
      ...row,
      item_value: util.formatCurrency(row['item_value']),
      total_value: util.formatCurrency(totalValues[row['bl_no']]),
      shipper_address1: row['shipper_address1']?.replaceAll('\n', ' '),
      shipper_address2: row['shipper_address2']?.replaceAll('\n', ' '),
      shipper_address3: row['shipper_address3']?.replaceAll('\n', ' '),
      consignee_address1: row['consignee_address1']?.replaceAll('\n', ' '),
      consignee_address2: row['consignee_address2']?.replaceAll('\n', ' '),
      consignee_address3: row['consignee_address3']?.replaceAll('\n', ' '),
      shipper_postal: row['shipper_postal'] ? `="${row['shipper_postal']}"` : '',
      consignee_postal: row['consignee_postal'] ? `="${row['consignee_postal']}"` : '',
    }));
  } else {
    const customerRows = data.filter(row => row['partner_type'] === 'CS' || row['partner_type'] === 'BC');
    const vendorRows = data.filter(row => row['partner_type'] === 'VD');
    const dropZoneRows = data.filter(row => row['partner_type'] === 'DZ');
    const otherRows = data.filter(row => row['partner_type'] !== 'CS' && row['partner_type'] !== 'BC' && row['partner_type'] !== 'VD' && row['partner_type'] !== 'DZ');
    const sortedCustomerRows = customerRows.sort((lhs, rhs) => {
      // const {ctns: ctnsL, weight: weightL} = lhs;
      // const {ctns: ctnsR, weight: weightR} = rhs;
      // return ctnsL === ctnsR ? weightR - weightL : ctnsR - ctnsL;
      const {ctns: ctnsL} = lhs;
      const {ctns: ctnsR} = rhs;
      return ctnsR - ctnsL;
    });
    const finalRows = [...sortedCustomerRows, ...vendorRows, ...dropZoneRows, ...otherRows];
    // let prevBlNo = '';
    // let order_no = '';
    // let orderNo = 0;
    // let item_no = 0;
    return finalRows.map((row, index) => {
      // if (row.bl_no !== prevBlNo) {
      //   const {post_date} = row;
      //   prevBlNo = row.bl_no;
      //   orderNo++;
      //   item_no = 0;
      //   order_no = util.formatDate(post_date, 'YYYYMMDD') + '-' + orderNo;
      // }
      const {
        bl_no, shipper_phone, consignee_phone1, consignee_phone2,
        out_date, weight, cal_weight,
        shipper_address, shipper_postal, consignee_address, consignee_postal,
        items = []
      } = row;
      const itemsObject = {};
      for (let i = 0; i < items.length; ++i) {
        const {item, item_qty, unit_price, brand, url} = items[i];
        itemsObject[`item${i}`] = item;
        itemsObject[`item_qty${i}`] = item_qty;
        itemsObject[`unit_price${i}`] = unit_price;
        itemsObject[`brand${i}`] = brand;
        itemsObject[`url${i}`] = url;
      }
      // ++item_no;
      return {
        ...row,
        out_date: util.formatD(out_date),
        // order_no: index + 1,
        order_no: util.formatDate(out_date, 'YYYYMMDD') + '-' + (index + 1),
        // item_no,
        weight: util.formatWeight({value: weight}),
        cal_weight: util.formatWeight({value: cal_weight}),
        // unit_price: util.formatCurrency(unit_price),
        shipper_address: shipper_address?.replaceAll('\n', ' '),
        consignee_address: consignee_address?.replaceAll('\n', ' '),
        bl_no: bl_no ? `="${bl_no}"` : '',
        shipper_phone: shipper_phone ? `="${shipper_phone}"` : '',
        shipper_postal: shipper_postal ? `="${shipper_postal}"` : '',
        consignee_postal: consignee_postal ? `="${consignee_postal}"` : '',
        // consignee_phone1: consignee_phone1 ? `="${consignee_phone1}"` : '',
        // consignee_phone2: consignee_phone2 ? `="${consignee_phone2}"` : '',
        ...itemsObject
      };
    });
  }
}

export default BLManifest;
