/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../components/SimpleModal';
import {useAsyncStatusModal} from "../../components/AsyncStatusModal";
import {apiBL, util} from "../../services/service";
import {useDropzone} from "react-dropzone";
import UploadIcon from "mdi-react/UploadIcon";

export default function useCSVUploadModal(onUpload) {
  const VendorUpload = ({onUpload}) => {
    const asyncStatusModal = useAsyncStatusModal('Uploading... please wait...');
    const onDrop = React.useCallback((acceptedFiles: FileList) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          // console.log('hello', file);
          asyncStatusModal.open();
          apiBL.updateCSV(reader.result, file.name).then((res) => onUpload(res)).finally(() => asyncStatusModal.close());
        };
        reader.readAsDataURL(file);
      }
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop,
      accept: '.csv, .xlsx',
      maxFiles: 1,
      onDropRejected: () => {
        util.showWarning('Please select valid CSV/Excel file!');
      },
    });
    return (
      <div className={'flex center middle mb-20'} {...getRootProps()} style={{height: 350, border: '1px solid #d0d0d0'}}>
        <input {...getInputProps()} />
        <div style={{textAlign: 'center'}}>
          <UploadIcon size={56} color={'#c0c0c0'} />
          {isDragActive ? <p>Drop the CSV/Excel file here ...</p> : <p>Drag & drop CSV/Excel file here, or click to select file</p>}
        </div>
        {asyncStatusModal.render()}
      </div>
    );
  };

  return useSimpleModal({
    title: 'CSV/Excel Upload',
    width: 720,
    centered: true,
    buttons: [
      // {label: 'Close', onClick: () => modal.close()}
    ],
    children: <VendorUpload onUpload={onUpload} />,
  });
}
