/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from '../../components/DataGridView';
import {api, util} from '../../services/service';

export default function useStaffHolidayGridView(onActionCb, editStaffModal) {
  function staffLinkFormatter(param) {
    const uid = param.data['uid'];
    if(uid) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
          e.preventDefault();
          api.staffData(uid).then((res) => {
            if (res) {
              editStaffModal.open('edit', res.data[0]);
            }
          });
        }}>
          {param.value}
        </a>
      );
    } else {
      return param.value;
    }
  }
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return api.staffHolidayList(gridInfo);
    },
    name: 'staffHoliday',
    label: 'Holiday',
    addLabel: '',
    editLabel: 'EDIT HOLIDAY',
    categoryLabel: {label: 'Staff'},
    menuLabel: {label: 'Holiday'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'detail'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    showCopyButton: true,
    initialSearchDateNone: true,
    columns: [
      {name: 'id', required: false, field: 'id', hide: true},
      {name: 'userId', required: false, field: 'uid', hide: true},
      {name: 'login_id', required: false, field: 'login_id', headerName: 'LoginID', flex: 1, cellRendererFramework: staffLinkFormatter},
      {name: 'uname2', required: false, field: 'uname2', headerName: 'Name', flex: 1},
      // {name: 'startDate', type: 'datePicker', required: true, field: 'start_date', headerName: 'Start Date', valueFormatter: util.dateFormatter, flex: 1},
      // {name: 'endDate', type: 'datePicker', required: true, field: 'end_date', headerName: 'End Date', valueFormatter: util.dateFormatter, flex: 1},
      {name: 'startDate', type: 'datePicker', required: true, field: 'start_date', headerName: 'Start Date', valueFormatter: util.dateFormatterString, flex: 1},
      {name: 'endDate', type: 'datePicker', required: true, field: 'end_date', headerName: 'End Date', valueFormatter: util.dateFormatterString, flex: 1},
      {name: 'totalDays', required: true, field: 'total_days', headerName: 'Total Days', flex: 1},
      {name: 'usedDays', required: true, field: 'used_days', headerName: 'Used Days', flex: 1},
      {name: 'used_call_sick', required: true, field: 'used_call_sick', headerName: 'Call Sick', flex: 1},
      {name: 'remark', type: 'textarea', required: false, field: 'remark', headerName: 'Remark', flex: 1},
    ],
  });
}
