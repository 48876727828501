/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {api, util} from '../../services/service';
import {renderField} from '../../components/Form';
import {ColDef} from 'ag-grid-community';
import type {FormField} from '../../components/Form';

const AreaList = () => {
  return (
    <DataGridView
      name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
      addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
      actions={ACTIONS} actionWidth={ACTION_WIDTH}
      showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
      columns={COLUMNS}
      onAction={async (action, data) => onAction(action, data)}
      onQuery={async (gridInfo) => onQuery(gridInfo)}
      renderForm={(formik, fields, errors) => renderForm(formik, fields, errors)}
      useExtendedColDef
    />
  );
};

const NAME = 'areaList';
const LABEL = 'Area';
const ADD_LABEL = 'ADD AREA';
const EDIT_LABEL = 'EDIT AREA';
const CATEGORY_LABEL = 'Settings';
const MENU_LABEL = 'Area';
const SORT_COL = 'area_name';
const IS_DESC = false;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 540;
const SHOW_DATE_SEARCH = false;
const ACTIONS = ['edit'];
const ACTION_WIDTH = 70;
const COLUMNS: (ColDef | FormField)[] = [
  { name: 'areaId', required: false,
    field: 'id', headerName: 'ID', flex: 0.5},
  { name: 'cdate', required: false,
    field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 1},
  { name: 'areaName', required: true,
    field: 'area_name', headerName: 'Name', flex: 1},
  { name: 'startDate', type: 'date', mask: util.MASK_DATE, required: true,
    field: 'start_date', headerName: 'Start Date', valueFormatter: util.dateFormatter, flex: 1},
  { name: 'isBranch', type: 'checkbox', required: false,
    field: 'isbranch', headerName: 'Branch', valueFormatter: util.yesNoFormatter, flex: 1},
  { name: 'isActive', type: 'checkbox', required: false,
    field: 'isactive', headerName: 'Activated', valueFormatter: util.yesNoFormatter, flex: 1},
  { name: 'footer', required: false,
    field: 'footer', hide: true},
];

async function onAction(action, data) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
  switch (action) {
    case 'add':
      return api.areaAdd(data);
    case 'edit':
      return api.areaEdit(data);
    // case 'delete':
    //   return;
    // case 'detail':
    //   return;
    // case 'addOpen':
    //   return;
    // case 'editOpen':
    //   return;
  }
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.areaList(gridInfo);
}

function renderForm(formik, fields, errors) {
  const render = (name) => renderField(formik, name, fields, errors);
  return (
    <>
      {render('areaName')}
      {render('startDate')}
      {render('isBranch')}
      {render('isActive')}
    </>
  );
}

export default AreaList;
