import React from 'react';
import {Container, Row} from 'reactstrap';
import TotalProfit from './TotalProfit';
import UnpaidAccountList from './UnpaidAccountList';
import GrossSales from './GrossSales';
import UnlinkedBLList from './UnlinkedBLList';
import {useTopbar} from '../../../redux/reducers/topbarReducer';
import {useUser} from '../../../redux/reducers/userReducer';
import SummarySearch, {searchDateForm} from './SummarySearch';
import {apiDashboard, util} from '../../../services/service';

const DashboardSummary = () => {
  useTopbar({label: 'Dashboard'}, {label: 'Summary'});
  const user = useUser();
  const [data, setData] = React.useState(null);
  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  searchDateForm.onChange = () => {
    setFromDate(searchDateForm.getValue('fromDate'));
    setToDate(searchDateForm.getValue('toDate'));
  };
  React.useEffect(() => {
    if (fromDate && toDate) {
      const data = {fromDate: util.toTS(fromDate), toDate: util.toTS(toDate)};
      apiDashboard.getAccountSummary(data).then(res => {
        if(res) {
          setData(res?.data?.[0] ?? null);
        }
      });
    }
  }, [fromDate, toDate]);
  if (user.auth === undefined) {
    return null;
  }
  return (
    <Container className="dashboard">
      <Row>
        <SummarySearch />
        <TotalProfit currency={'CAD'} data={data} />
        <TotalProfit currency={'USD'} data={data} />
        <TotalProfit currency={'KRW'} data={data} />
      </Row>
      <Row>
        <UnpaidAccountList data={data} />
        {/*<WeeklyStat />*/}
        <GrossSales data={data} />
        <UnlinkedBLList data={data} />
      </Row>
    </Container>
  );
};

export default DashboardSummary;
