/**
 * @flow
 */
import React from 'react';
import {useDataGridView} from '../../components/DataGridView';
import {apiAccount, util} from '../../services/service';
import {blLinkFormatter} from './useAccountGridView';

export default function useAccountRequestGridView(onActionCb, onGridReady, onQueryData, onSelectionChanged) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      const grid = {...gridInfo, isRequest: true};
      return apiAccount.accountList(grid).then(res => {
        const {data, totalCount} = res;
        const dataInfo = data.map((value) => (
          {...value, vendorInv: value?.jextra?.vendorInv, paidDate: value?.jextra?.paidDate}
        ));
        onQueryData(dataInfo);
        return {data: dataInfo, totalCount};
      });
    },
    name: `ListRequest`,
    label: 'Request List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'Request List'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    onSelectionChanged: onSelectionChanged,
    doNotPerformInitialQuery: true,
    onGridReady,
    columns: [
      {field: 'id', hide: true},
      {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
      {field: 'branch_name', headerName: 'Branch', flex: 1},
      // {field: 'acc_type', headerName: 'Type', width: 100},
      {field: 'inv_no', headerName: 'Inv. No.', flex: 1},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: blLinkFormatter, flex: 1},
      {field: 'bill_to', headerName: 'Partner', flex: 1},
      {field: 'vendorInv', headerName: 'Vendor Inv.', flex: 1},
      {field: 'inv_date', headerName: 'Inv. Date', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'currency', headerName: 'Currency', flex: 1},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'paidDate', headerName: 'Paid Date', valueFormatter: util.dateFormatter, flex: 1},
    ],
  });
}
