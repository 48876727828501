/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import AEMTC from '../../../print-layouts/AEMTC';
import {usePrintModal} from './usePrintModal';
import {getBLEntryData} from "../../field-defs/bl";

const FN = {
  SHIPPER: 'shipper',
  SHIPPER_ADDRESS: 'shipperAddress',
  REGULATED_ENTITY: 'regulatedEntity',
  AIR_WAYBILL: 'airWaybill',
  CONSIGNMENT: 'consignment',
  CONSOLIDATION: 'consolidation',
  TRANSFER: 'transfer',
  SECURITY_STATUS: 'securityStatus',
  REASON_FOR_ISSUING: 'reasonForIssuing',
  RECEIVED_FROM: 'receivedFrom',
  SCREENING_METHOD: 'screeningMethod',
  OTHER_SCREENING_METHOD: 'otherScreeningMethod',
  SECURITY_STATUS_ISSUED_BY: 'securityStatusIssuedBy',
  SECURITY_STATUS_ISSUED_ON: 'securityStatusIssuedOn',
  ADDITIONAL_SECURITY_INFORMATION: 'additionalSecurityInformation',

};

export class AEMTCPrintForm extends FormManager {
  static FN = FN;
  constructor() {
    super({
      prefix: 'print-aem',
      fields: [
        {name: FN.SHIPPER, serverName: FN.SHIPPER, label: ''},
        {name: FN.SHIPPER_ADDRESS, serverName: FN.SHIPPER_ADDRESS, label: '', type: 'textarea'},
        {name: FN.REGULATED_ENTITY, serverName: FN.REGULATED_ENTITY, label: ''},
        {name: FN.AIR_WAYBILL, serverName: FN.AIR_WAYBILL, label: '', disabled: true},
        {name: FN.CONSIGNMENT, serverName: FN.CONSIGNMENT, label: ''},
        {name: FN.CONSOLIDATION, serverName: FN.CONSOLIDATION, label: 'Consolidation', type: 'checkbox'},
        {name: FN.TRANSFER, serverName: FN.TRANSFER, label: ''},
        {name: FN.SECURITY_STATUS, serverName: FN.SECURITY_STATUS, label: ''},
        {name: FN.REASON_FOR_ISSUING, serverName: FN.REASON_FOR_ISSUING, label: ''},
        {name: FN.RECEIVED_FROM, serverName: FN.RECEIVED_FROM, label: ''},
        {name: FN.SCREENING_METHOD, serverName: FN.SCREENING_METHOD, label: ''},
        {name: FN.OTHER_SCREENING_METHOD, serverName: FN.OTHER_SCREENING_METHOD, label: ''},
        {name: FN.SECURITY_STATUS_ISSUED_BY, serverName: FN.SECURITY_STATUS_ISSUED_BY, label: ''},
        {name: FN.SECURITY_STATUS_ISSUED_ON, serverName: FN.SECURITY_STATUS_ISSUED_ON, label: ''},
        {name: FN.ADDITIONAL_SECURITY_INFORMATION, serverName: FN.ADDITIONAL_SECURITY_INFORMATION, label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;

    return (
      <div className={'w-full'}>
        <div>Air waybill or similar control document:</div>
        <div>{_r(FN.AIR_WAYBILL)}</div>
        <div>Shipper:</div>
        <div>{_r(FN.SHIPPER)}</div>
        <div>{_r(FN.SHIPPER_ADDRESS)}</div>
        <div className={'flex'}>
          <div className={'flex-1'}>
            <div>Contents of consignment:</div>
            <div style={{marginLeft: '-80px'}}>{_r(FN.CONSOLIDATION)}</div>
          </div>
          <div className={'flex-2'}>{_r(FN.CONSIGNMENT)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-8'}>
            <div>Program number of the regulated entity:</div>
            <div>{_r(FN.REGULATED_ENTITY)}</div>
          </div>
          <div className={'flex-1'}>
            <div>Transfer/Transit points (if known):</div>
            <div>{_r(FN.TRANSFER)}</div>
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-8'}>
            <div>Security Status:</div>
            <div>{_r(FN.SECURITY_STATUS)}</div>
          </div>
          <div className={'flex-1'}>
            <div>Reasons for issuing security status:</div>
            <div>{_r(FN.REASON_FOR_ISSUING)}</div>
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-8'}>
            <div>Received from (code)</div>
            <div>{_r(FN.RECEIVED_FROM)}</div>
          </div>
          <div className={'flex-1'}>
            <div>Secreening method</div>
            <div>{_r(FN.SCREENING_METHOD)}</div>
          </div>
        </div>
        <div>Other screening method(s) (if applicable):</div>
        <div>{_r(FN.OTHER_SCREENING_METHOD)}</div>
        <div className={'flex'}>
          <div className={'flex-1 mr-8'}>
            <div>Security status issued by (name of ACR):</div>
            <div>{_r(FN.SECURITY_STATUS_ISSUED_BY)}</div>
          </div>
          <div className={'flex-1'}>
            <div>Security status issued on (YYYY-MM-DD):</div>
            <div>{_r(FN.SECURITY_STATUS_ISSUED_ON)}</div>
          </div>
        </div>
        <div>Additional security information:</div>
        <div>{_r(FN.ADDITIONAL_SECURITY_INFORMATION)}</div>
      </div>
    );
  };
}

const blPrintForm = new AEMTCPrintForm();

export function useAEMTCPrintModal(blForm, houseData) {
  const onOpen = () => {
    const values = blForm.getValues();
    const blData = getBLEntryData(true, values);
    blPrintForm.setValue(FN.SHIPPER, blData.jcustomer.shipper);
    blPrintForm.setValue(FN.SHIPPER_ADDRESS, blData.jcustomer.shipperAddr);
    blPrintForm.setValue(FN.AIR_WAYBILL, blData.blNo);
  };
  return usePrintModal(true, 'TC Print', 1024, blForm, blPrintForm, AEMTC, onOpen);
}
