import React from 'react';
import {apiAccount, apiBL, util} from '../../services/service';
import {useSimpleModal} from '../../components/SimpleModal';
import {useDataGridView} from "../../components/DataGridView";
import {queryGridData} from "../../components/DataGrid";
import {INVOICE} from "../../forms/field-defs/account";
import {useUser} from "../../redux/reducers/userReducer";

export default function useVendorDetailModal() {
  const [mode, setMode] = React.useState();
  const [data, setData] = React.useState(); // 그리드에서 선택한 행의 데이터를 보관함
  const gridInfoRef = React.useRef();
  const user = useUser();
  const isManagerRef = React.useRef();
  isManagerRef.current = user.isManager;
  async function onQuery(gridInfo) {
    if (modal.isOpen) {
      const {partnerId} = data;
      const dataInfo = {...gridInfo, ...data, isAll: true};
      gridInfoRef.current = gridInfo;
      if (partnerId) {
        return await apiAccount.vendorHistoryDetail(dataInfo);
      }
    }
  }

  const grid = useDataGridView({
    async onAction(action, data) {
      if (action === 'delete') {
        await apiBL.delBLEntry(data.bl_no).then(res => {
          if (res) {
            search();
          }
        });
      }
    },
    onQuery,
    onCellValueChange(e) {
      const accountId = e.data.account_id;
      const price = e.data.price;
      apiAccount.changeVendorPrice({accountId: util.toInt(accountId), amount: util.toFloat(price)}).then(res => {});
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
    },
    name: `Vendor Detail`,
    label: '',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'Vendor'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['delete'],
    actionWidth: 70,
    isActionDisabled: (action, data) => {
      return (!isManagerRef.current || data.done === 'Yes');
    },
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    useModalDataGrid: true,
    modalDataGridHeight: 500,
    showRowSearch: false,
    columns: getColumn(),
  });

  const modal = useSimpleModal({
    title: getTitle(),
    centered: true,
    width: 1024,
    buttons: getButtons(),
    children: grid.render()
  });

  function getColumn() {
    switch (mode) {
      case INVOICE.TYPE_VD:
        return [
          {field: 'account_id', hide: true},
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
          {field: 'inv_no', headerName: 'Inv No.', width: 120},
          {field: 'bl_no', headerName: 'B/L No.', width: 120},
          {field: 'customer_name', headerName: 'Customer', flex: 1},
          {field: 'currency', headerName: 'Currency', width: 100},
          {field: 'price', headerName: 'Price', valueFormatter: util.currencyFormatter, editable: true, width: 100},
          {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 100},
        ];
      default:
        return [
          {field: 'account_id', hide: true},
          {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, width: 100},
          {field: 'inv_no', headerName: 'Inv No.', width: 120},
          {field: 'bl_no', headerName: 'B/L No.', width: 120},
          {field: 'customer_name', headerName: 'Customer', flex: 1},
          {field: 'currency', headerName: 'Currency', width: 100},
          {field: 'price', headerName: 'Price', valueFormatter: util.currencyFormatter, width: 100},
          {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 100},
          {field: 'done', headerName: 'Done', width: 100},
        ];
    }
  }

  function getTitle() {
    let title = `VENDOR DETAIL (${util.formatD(data?.fromDate)} ~ ${util.formatD(data?.toDate)})`;
    if(mode === INVOICE.TYPE_VD) {
      title = `VENDOR DETAIL (${data.filingNo})`;
    }
    return title;
  }

  function getButtons() {
    let button = [{label: 'Make Invoice', color: 'primary', onClick: onSave}];
    if(mode === INVOICE.TYPE_VD) {
      button = [];
    }
    return button;
  }

  async function onSave() {
    const res = await apiAccount.setVendorInvoice(data);
    if(res) {
      modal.close();
      util.openTab('/admin/account/list/vd?id=' + res.id);
    }
  }

  const search = () => {
    const {partnerId} = data;
    if (partnerId) {
      queryGridData('Vendor Detail', gridInfoRef.current, onQuery).then(() => console.log('hello, grid query called!'));
    }
  };

  React.useEffect(() => {
    if (modal.isOpen === true) {
      search();
    }
  }, [modal.isOpen]);

  const open = (data: Object, mode: string) => {
    setData(data);
    setMode(mode);
    modal.open();
  };
  return {...modal, open};
}
