/**
 * @flow
 */
import React from "react";
import FormManager from "../../../../lib/FormManager";

export const FILING_NO = 'filingNo';
export const BL_NO = 'blNo';
export const CONTAINER_NO = 'containerNo';

export default class CourierCLPSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  clickedIndex;
  constructor() {
    super({
      prefix: 'courier-clp-search',
      fields: [
        {name: FILING_NO, serverName: FILING_NO, label: 'Filing No.', smallMargin: true},
        {name: BL_NO, serverName: BL_NO, label: 'MBL No.', smallMargin: true},
        {name: CONTAINER_NO, serverName: CONTAINER_NO, label: 'Container No.', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    _f(FILING_NO).onEnter = _ => this.onSearch();
    _f(BL_NO).onEnter = _ => this.onSearch();
    _f(CONTAINER_NO).onEnter = _ => this.onSearch();

    return (
      <>
        {_r(FILING_NO)}
        {_r(BL_NO)}
        {_r(CONTAINER_NO)}
      </>
    );
  };
}
