/**
 * @flow
 */
import React from 'react';
import {Button} from 'reactstrap';
import _c from 'classnames';

export interface ButtonProps {
  id?: string;
  label: string;
  onClick: () => void;
  className?: string;
  noMargin?: boolean;
  size?: string;
  disabled?: boolean;
  style?: any;
}

const buttonStyle = {fontSize: 13, whiteSpace: 'nowrap', padding: '.25em .75em'};

export const DefaultButton = (props: ButtonProps) => {
  const className = _c({'no-margin': props.noMargin}, props.className);
  return <Button style={{...buttonStyle, ...props.style}} id={props.id} size={props.size ?? 'sm'} onClick={props.onClick} className={className} disabled={props.disabled}>{props.label}</Button>
};

export const PrimaryButton = (props: ButtonProps) => {
  const className = _c({'no-margin': props.noMargin}, props.className);
  return <Button style={{...buttonStyle, ...props.style}} id={props.id} size={props.size ?? 'sm'} color={'primary'} onClick={props.onClick} className={className} disabled={props.disabled}>{props.label}</Button>
};

export const SuccessButton = (props: ButtonProps) => {
  const className = _c({'no-margin': props.noMargin}, props.className);
  return <Button id={props.id} size={props.size ?? 'sm'} color={'success'} onClick={props.onClick} className={className} disabled={props.disabled}>{props.label}</Button>
};

export const WarningButton = (props: ButtonProps) => {
  const className = _c({'no-margin': props.noMargin}, props.className);
  return <Button id={props.id} size={props.size ?? 'sm'} color={'warning'} onClick={props.onClick} className={className} disabled={props.disabled}>{props.label}</Button>
};

export const DangerButton = (props: ButtonProps) => {
  const className = _c({'no-margin': props.noMargin}, props.className);
  return <Button style={buttonStyle} id={props.id} size={props.size ?? 'sm'} color={'danger'} onClick={props.onClick} className={className} disabled={props.disabled}>{props.label}</Button>
};
