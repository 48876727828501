/**
 * @flow
 */
import React from 'react';
import DataGridView from '../../components/DataGridView';
import {util, api} from '../../services/service';
import {ColDef} from 'ag-grid-community';
import {Card, CardBody, Modal} from 'reactstrap';
import {AgGridReact} from 'ag-grid-react';
import {defaultColDef} from '../../components/DataGrid';
import {DefaultButton} from '../../components/buttons';

interface ActivityLogListState {
  openModal: boolean;
  logs: string;
}

const ActivityLogList = () => {
  const [state, setState] = React.useState<ActivityLogListState>({openModal: false});
  return (
    <>
      <DataGridView
        name={NAME} label={LABEL} sortCol={SORT_COL} sortDesc={IS_DESC} pageRows={PAGE_ROWS}
        addLabel={ADD_LABEL} editLabel={EDIT_LABEL} categoryLabel={{label: CATEGORY_LABEL}} menuLabel={{label: MENU_LABEL}}
        actions={ACTIONS} actionWidth={ACTION_WIDTH}
        showDateSearch={SHOW_DATE_SEARCH} modalWidth={MODAL_WIDTH}
        columns={COLUMNS}
        onAction={async (action, data) => onAction(action, data, state, setState)}
        onQuery={async (gridInfo) => onQuery(gridInfo)}
      />
      <LogViewModal state={state} setState={setState} />
    </>
  );
};

const NAME = 'activityLogList';
const LABEL = 'Activity';
const ADD_LABEL = '';
const EDIT_LABEL = '';
const CATEGORY_LABEL = 'Log';
const MENU_LABEL = 'Activity';
const SORT_COL = 'cdate';
const IS_DESC = true;
const PAGE_ROWS = 20;
const MODAL_WIDTH = 0;
const SHOW_DATE_SEARCH = true;
const ACTIONS = ['detail'];
const ACTION_WIDTH = 70;
const COLUMNS: ColDef[] = [
  {field: 'uid', hide: true},
  {field: 'logs', hide: true},
  {field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, flex: 1},
  {field: 'login_id', headerName: 'LoginID', flex: 1},
  {field: 'uname', headerName: 'Staff Name', flex: 1},
  {field: 'job', headerName: 'Doing', flex: 1},
  {field: 'log_type', headerName: 'Type', flex: 1},
];

async function onAction(action, data, state, setState) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} action`, action, data);
  switch (action) {
    case 'detail':
      setState({...state, logs: data.logs, openModal: true});
      return;
  }
}

async function onQuery(gridInfo) {
  process.env.NODE_ENV !== 'production' && console.log(`[DataGridView] ${LABEL} query`, gridInfo);
  return api.activityLogList(gridInfo);
}

interface LogViewModalProps {
  state: ActivityLogListState;
  setState: (state: ActivityLogListState) => void;
}

const logColumns: ColDef[] = [
  {field: 'name', headerName: 'Name'},
  {field: 'value', headerName: 'Value'},
];

const LogViewModal = (props: LogViewModalProps) => {
  let logs;
  try {
    logs = JSON.parse(props.state.logs);
    logs = Object.entries(logs).map(([name, value]) => ({name, value}));
  } catch (error) {
    logs = [];
  }
  return (
    <Modal isOpen={props.state.openModal} className={'modal-dialog--form'} centered toggle={() => props.setState({...props.state, openModal: !props.state.openModal})}>
      <Card style={{paddingBottom: 0}}>
        <CardBody>
          <div className={'card__title mb-12'}>
            <h5 className={'bold-text mb-2'}>Activity Log</h5>
          </div>
          <div className={`ag-theme-balham`} style={{width: 'calc(100% - 4px)', height: 200}}>
            <AgGridReact columnDefs={logColumns} rowData={logs} defaultColDef={defaultColDef} />
          </div>
          <div className={'flex right mt-12'}>
            <DefaultButton label={'Close'} onClick={() => props.setState({...props.state, openModal: false})} noMargin />
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default ActivityLogList;
