/**
 * @flow
 */
import API from "./API";
import {util} from "./service";
import type {QueryListParams} from "./API";
import React from "react";

class APIBL extends API {
  async workOrderList(data: QueryListParams) {
    return this.sendList('bl/ListData', {action: 'ListWorkOrder', data});
  }
  async workOrderData({id, blNo}) {
    return this.sendList('bl/ViewData', {action: 'WorkOrderData', data: {id, blNo}});
  }
  async setWorkOrder(data) {
    const {
      id, kind, blNo, branchId, workType, workDate, workDateStr, workTime,
      pic, phone, refNo, exportRef, workplaceId, workplace, city, address,
      jparty, jshipitem, jremark,
    } = data;
    const reqData = {
      id, kind, blNo, branchId: util.toInt(branchId), workType, workDate, workDateStr, workTime,
      pic, phone, refNo, exportRef, workplaceId: util.toInt(workplaceId), workplace, city, address,
      jparty, jshipitem, jremark,
    };
    return this.sendCUD('bl/CUD', {action: 'SetWorkOrder', data: reqData});
  }
  async getHouseListBulk(data) {
    const {carrierType, bound, branchId, qryText = '', kind} = data;
    const reqData = {carrierType, bound, branchId, qryText, kind};
    return this.sendList('bl/ListData', {action: 'ListBulkHouse', data: reqData});
  }
  async getHouseListBulkAll(data) {
    const {page, rowCount, filingNo, qryText = '', orderBy, isDesc} = data;
    const reqData = {page, rowCount, filingNo, qryText, orderBy, isDesc};
    return this.sendList('bl/ListData', {action: 'ListHouseBL', data: reqData});
  }
  async delHouseListBulk(blId) {
    const reqData = {blId};
    return this.sendCUD('bl/CUD', {action: 'DelHouseBL', data: reqData});
  }
  async assignHouseListToMaster({id, house}) {
    return this.sendCUD('bl/CUD', {action: 'SetBulkHouse', data: {id, house}});
  }
  async getMasterList(data) {
    const {carrierType, bound, branchId, kind} = data;
    return this.sendList('api/GetData', {action: 'CommingMBL', data: {carrierType, bound, branchId, kind}});
  }
  // useMasterOptions(data) {
  //   const [options, setOptions] = React.useState([]);
  //   React.useState(() => {
  //     this.getMasterList(data).then((res) => {
  //       const {data} = res;
  //     });
  //   }, []);
  //   return options;
  // }
  async calendarList(data: QueryListParams) {
    const {fromDate, toDate, branchId, kind, kindArr, qryText} = data;
    const reqData = {fromDate, toDate, branchId, kind, kindArr, qryText};
    return this.sendList('bl/ListData', {action: 'ListCalendar', data: reqData});
  }
  async listDaySchedule(data) {
    return this.sendList('bl/ListData', {action: 'ListDaySchedule', data});
  }
  async workAssignList(data: QueryListParams) {
    return this.sendList('bl/ListData', {action: 'ListWorkAssigned', data});
  }
  async workForAssignList(data) {
    return this.sendList('bl/ListData', {action: 'ListWorkForAssign', data});
  }
  async setDailyAssign(data) {
    return this.sendCUD('bl/CUD', {action: 'SetDailyAssign', data});
  }
  async getDailyAssign(data) {
    return this.sendList('bl/ViewData', {action: 'GetDailyAssign', data});
  }
  async setWorkAssign(data) {
    const {id, assignList, remark, workers, vehicleId, workDate, workTime} = data;
    const reqData = {
      id, assignList, remark, workTime,
      workers,
      // staffId: util.toInt(staffId),
      vehicleId: util.toInt(vehicleId),
      workDate: util.toTS(workDate),
    };
    return this.sendCUD('bl/CUD', {action: 'AddWorkAssign', data: reqData});
  }
  async delWorkAssign(id) {
    return this.sendCUD('bl/CUD', {action: 'DelWorkAssign', data: {id}});
  }
  async getBLBoxList(blId, filingNo) {
    return this.sendList('bl/ListData', {action: 'ListBLBox', data: {blId, filingNo}});
  }
  async getBLBoxItemList(blId, boxNo) {
    return this.sendList('bl/ListData', {action: 'ListBLBoxItem', data: {blId, boxNo}});
  }
  async setBLBox(data) {
    const {blId, boxNo, calWeight, length, width, height, boxCBM, pcs} = data;
    const reqData = {
      blId,
      boxNo: util.toInt(boxNo),
      calWeight: util.toFloat(calWeight),
      length: util.toFloat(length),
      width: util.toFloat(width),
      height: util.toFloat(height),
      boxCBM: util.toFloat(boxCBM),
      pcs: util.toFloat(pcs),
    };
    return this.sendCUD('bl/CUD', {action: 'SetBLBox', data: reqData});
  }
  async delBLBox(blId, boxNo) {
    const reqData = {
      blId,
      boxNo: util.toInt(boxNo),
    };
    return this.sendCUD('bl/CUD', {action: 'DelBLBox', data: reqData});
  }
  async setBLBoxItem(data) {
    const {blId, boxNo, itemNo, itemQty, unitPrice, itemTitle, hsCode, jcommerce} = data;
    const reqData = {
      blId,
      boxNo: util.toInt(boxNo),
      itemNo: util.toInt(itemNo),
      itemQty: util.toInt(itemQty),
      unitPrice: util.toFloat(unitPrice),
      hsCode,
      itemTitle, jcommerce
    };
    return this.sendCUD('bl/CUD', {action: 'SetBLBoxItem', data: reqData});
  }
  async setBLBoxItemBulk(blId, boxNo, items) {
    return this.sendCUD('bl/CUD', {action: 'SetBLBoxItemBulk', data: {blId, boxNo, items}});
  }
  async delBLBoxItem(blId, boxNo, itemNo) {
    const reqData = {
      blId,
      boxNo: util.toInt(boxNo),
      itemNo: util.toInt(itemNo),
    };
    return this.sendCUD('bl/CUD', {action: 'DelBLBoxItem', data: reqData});
  }
  async getBLManifestList(data) {
    return this.sendList('bl/ListData', {action: 'ListManifest', data});
  }
  async setMasterBLBox(blId, filingNo) {
    return this.sendList('bl/ListData', {action: 'SetMasterBLBox', data: {blId, filingNo}});
  }
  async getHSCode(itemTitle) {
    return this.sendList('bl/GetData', {action: 'GetHsCode', data: {itemTitle}});
  }
  async getCraftInfo(blNo) {
    return this.sendList('craft/GetData', {action: 'GetCraftInfo', data: {blNo}});
  }
  async updateCSV(data, fileName) {
    const parts = data.split(',');
    return this.sendBase64('file/vendorBL', parts[1], fileName);
  }
  async updateCSVPayAmt(data, fileName) {
    const parts = data.split(',');
    return this.sendBase64('file/UpPayAmt', parts[1], fileName);
  }
  async getLabelPrintData(data) {
    return this.sendList('bl/PrintData', {action: 'BLLabel', data});
  }
  async getContainers(blId) {
    return this.sendList('craft/GetData', {action: 'GetContainers', data: {blId}});
  }
  async getBlRatePrintData(blNo) {
    return this.sendList('bl/PrintData', {action: 'GetBLRateData', data: {blNo}});
  }
  async getBLPrintData(blNo) {
    return this.sendList('bl/PrintData', {action: 'GetBLPrintData', data: {blNo}});
  }
  async getManifestPrintData(data) {
    return this.sendList('bl/PrintData', {action: 'GetManifestPrintData', data});
  }
  async getArrivalNoticeData(blId, printGrossKG) {
    return this.sendList('bl/PrintData', {action: 'GetArrivalNotice', data: {blId, printGrossKG}});
  }
  async deleteWorkOrder(id) {
    return this.sendCUD('bl/CUD', {action: 'DelWorkOrder', data: {id}})
  }
  async setBLPrint(blNoList) {
    return this.sendCUD('bl/CUD', {action: 'SetPrintFlag', data: {blNos: blNoList}});
  }
  async resetMbl(blId) {
    return this.sendCUD('bl/CUD', {action: 'ResetMBLinHouse', data: {blId}});
  }
  async setFlag(blId, flag) {
    return this.sendCUD('bl/CUD', {action: 'SetFlag', data: {blId, flag}});
  }
  async getSumHouseShipData(filingNo) {
    return this.sendList('bl/GetData', {action: 'GetSumHouseShipData', data: {filingNo}});
  }
  async viewBLTrackingData(data) {
    return this.sendList('bl/ViewData', {action: 'ViewBLTrackingData', data});
  }
  async setTrackingRemark(data) {
    return this.sendCUD('bl/CUD', {action: 'SetTrackingRemark', data});
  }
  async vViewMBLId(filingNo) {
    return this.sendList('bl/ViewData', {action: 'ViewMBLId', data: {filingNo}});
  }
  async delBLEntry(blNo) {
    return this.sendCUD('bl/CUD', {action: 'DelBLEntry', data: {blNo}});
  }
  async getBLUpsList(data: QueryListParams) {
    return this.sendList('bl/ListData', {action: 'ListKorUPS', data});
  }
  async delBLUpsData(upsId) {
    return this.sendCUD('bl/CUD', {action: 'DelKorUPS', data: {upsId}});
  }
  async uploadBLUpsCSV(data, fileName) {
    const parts = data.split(',');
    return this.sendBase64('file/cKorUPS', parts[1], fileName);
  }
  async uploadBLUpsVendorCSV(data, fileName) {
    const parts = data.split(',');
    return this.sendBase64('file/vKorUPS', parts[1], fileName);
  }
  async setKorUPSStatus(upsId, statusNo, alimTalk) {
    return this.sendCUD('bl/CUD', {action: 'SetKorUPSStatus', data: {upsId, statusNo, alimTalk}});
  }
  async delBLBulk(workorderId, blIds) {
    return this.sendCUD('bl/CUD', {action: 'DelBLBulk', data: {workorderId, blIds}});
  }
  async listInvHistory(data) {
    return this.sendList('bl/ListData', {action: 'ListInvHistory', data});
  }
}

export default APIBL;
