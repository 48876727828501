/**
 * @flow
 */
import React from 'react';
import {api, util} from '../../services/service';
import SearchPane from '../../components/SearchPane';
import {PrimaryButton} from '../../components/buttons';
import BLReportSearchFormManager from '../../forms/bl/BLReportSearchForm';
import type {QueryListParams} from '../../services/API';
import useReportListGridView from "../../grids/useReportListGridView";
import {useTopbar} from "../../redux/reducers/topbarReducer";

const searchForm = new BLReportSearchFormManager();

const BLReport = () => {
  useTopbar({label: 'B/L'}, {label: 'Report'});
  const {grid, search, onDownload, totalAmount} = useBLReport();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={() => search()} className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {grid.render()}
        <div style={{position: 'absolute', left: 0, top: -12}}>
          <PrimaryButton label={'EXPORT CSV'} onClick={onDownload} />
        </div>
        <div className={'flex'} style={{position: 'absolute', bottom: 0, width: 350}}>
          <div className={'flex-1'}>Total Amount: {util.formatCurrency(totalAmount)}</div>
        </div>
      </div>
    </div>
  );
};

function useBLReport() {
  const [totalAmount, setTotalAmount] = React.useState(0);
  const apiRef = React.useRef();

  const onQueryData = (tAmount) => {
    setTotalAmount(tAmount);
  };

  const grid = useReportListGridView((params) => {apiRef.current = params.api}, onQueryData);

  const onDownload = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv({
        fileName: `bl-report-list-${util.formatD(Date.now())}.csv`,
        columnKeys: grid?.props?.columns?.filter((c, index) => index > 0).map(c => c.field),
      });
    }
  };

  const search = (qryText) => {
    const {kind, carrierType, branchId, partnerId} = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      kind, carrierType, branchId, partnerId,
    };
    if (qryText) {
      grid.query({...listParam, qryText});
    } else {
      grid.query(listParam);
    }
  };

  searchForm.onSearch = () => search();

  return {grid, search, onDownload, totalAmount};
}

export default BLReport;
