/**
 * @flow
 */
import {useSimpleModal} from '../../../components/SimpleModal';
import MovingHouseBLAccountFormManager from '../../../forms/newCoship/moving/house/MovingHouseBLAccountFormManager';

const editForm = new MovingHouseBLAccountFormManager();

export default function(itemModal, accType) {
  const modal = useSimpleModal({
    title: `Moving ${accType}`,
    centered: true,
    width: 900,
    height: 700,
    children: editForm.renderForm(),
  });
  const open = (data) => {
    editForm.setData(data);
    editForm.itemModal = itemModal;
    modal.open();
  };
  return {...modal, open};
};
