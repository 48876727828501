/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {usePrintModal} from './usePrintModal';
import OEMLabel from '../../../print-layouts/OEMLabel';
import {getBLEntryData} from '../../field-defs/bl';
import {apiBL} from '../../../services/service';
import {useSelector} from "react-redux";

const FN = {
  PIECES: 'pieces',
  NUMBER1: 'number1',
  NUMBER2: 'number2',
  MBL_NO: 'mblNo',
  HBL_NO: 'hblNo',
  DESTINATION: 'destination',
  TO: 'to',
  TO_ID: 'toId',
  TO_ADDR: 'toAddr',
  ORIGIN: 'origin',
  LABELS: 'labels',
};

export class OEHLabelPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'label-oeh',
      fields: [
        {name: FN.PIECES, serverName: FN.PIECES, label: 'Total Pieces'},
        {name: FN.NUMBER1, serverName: FN.NUMBER1, label: 'Number'},
        {name: FN.NUMBER2, serverName: FN.NUMBER2, label: ''},
        {name: FN.MBL_NO, serverName: FN.MBL_NO, label: 'MB/L No.'},
        {name: FN.HBL_NO, serverName: FN.HBL_NO, label: 'HB/L No.'},
        {name: FN.DESTINATION, serverName: FN.DESTINATION, label: 'Destination'},
        {name: FN.TO, serverName: FN.TO, label: 'To'},
        {name: FN.TO_ADDR, serverName: FN.TO_ADDR, label: 'Address', type: 'textarea'},
        {name: FN.ORIGIN, serverName: FN.ORIGIN},
        {name: FN.LABELS, serverName: FN.LABELS},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.PIECES)}
        <div className={'flex middle'}>
          <div className={'flex-2'}>{_r(FN.NUMBER1)}</div>
          <div className={'mx-1 mb-20'}>~</div>
          <div className={'flex-1'}>{_r(FN.NUMBER2)}</div>
        </div>
        {_r(FN.MBL_NO)}
        {_r(FN.DESTINATION)}
        {_r(FN.TO)}
        {_r(FN.TO_ADDR)}
      </div>
    );
  };
}

const labelPrintForm = new OEHLabelPrintForm();

// export function useOEHLabelPrintModal(blForm) {
//   const onOpen = async () => {
//     const values = blForm.getValues();
//     const blData = getBLEntryData(true, values);
//     const {blNo, filingNo, jcommon} = blData;
//     const {hblNo, mblNo} = jcommon;
//     const {data} = await apiBL.getLabelPrintData({blNo: blNo ?? hblNo, filingNo, isAir: false});
//     if (data?.length === 1) {
//       const {mblNo, totalmaster: pieces, destination, origin, labels} = data[0];
//       labelPrintForm.setValuesFast({
//         pieces, number1: 1, number2: pieces, mblNo, hblNo, destination, origin, labels,
//       });
//     }
//   };
//   return usePrintModal(false,'PACKAGE LABEL', 720, blForm, labelPrintForm, OEMLabel, onOpen);
// }

export function useOEHLabelPrintModal(blForm, isNewCoship, customerForm, workDetailsForm) {
  const {filingNo: fNo} = useSelector(state => state['newCoship']);
  let blNo, hblNo, filingNo;
  const onOpen = async () => {
    if (isNewCoship) {
      const blValues = blForm.getValues();
      const customerValues = customerForm.getValues();
      const workDetailsValues = workDetailsForm.getValues();
      const values = {...blValues, ...customerValues, ...workDetailsValues};
      blNo = values?.mblNo;
      hblNo = values?.hblNo;
      filingNo = fNo;
    } else {
      const values = blForm.getValues();
      const blData = getBLEntryData(true, values);
      blNo = blData.blNo;
      filingNo = blData.filingNo;
      blNo = blData.jcommon.blNo;
      hblNo = blData.jcommon.hblNo;
    }
    const res = await apiBL.getLabelPrintData({blNo: blNo ?? hblNo, filingNo, isAir: false});
    if (res.data?.length === 1) {
      const {mblNo, totalmaster: pieces, destination, origin, labels} = res.data[0];
      labelPrintForm.setValuesFast({
        pieces, number1: 1, number2: pieces, mblNo, hblNo, destination, origin, labels,
      });
    }
  };
  return usePrintModal(false,'PACKAGE LABEL', 720, blForm, labelPrintForm, OEMLabel, onOpen);
}
