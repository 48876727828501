/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';

class ContainerLoadPlanPrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (!printData) {
      return null;
    }
    const {containerData} = printData;
    return (
      <div>
        <table id='table-to-xls' className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td colSpan={11} className={'center no-border-all center'}>
              <table className={'print-table'} style={{width: '100%'}}>
                <tbody>
                <tr style={{height: 50}}>
                  <td colSpan={4} style={{textAlign: 'center', alignContent: 'center'}}><h4>CONTAINER LOAD PLAN</h4></td>
                </tr>
                {this.renderHeader('MASTER BL NO.', containerData.mblNo, 'VSL NAME', containerData.vessle)}
                {this.renderHeader('CNTR NO.', containerData.cntrNo, 'C.DATE', containerData.etd)}
                </tbody>
              </table>
              <table className={'print-table'} style={{width: '100%'}}>
                <tbody>
                <tr>
                  <td width={'5%'} className={'center'}><div className={'label'}>{''}</div></td>
                  <td width={'25%'} className={'center'}><div className={'label'}>SHPR</div></td>
                  <td width={'10%'} className={'center'}><div className={'label'}>CBM</div></td>
                  <td width={'10%'} className={'center'}><div className={'label'}>PKGS</div></td>
                  <td width={'10%'} className={'center'}><div className={'label'}>KG</div></td>
                  <td width={'10%'} className={'center'}><div className={'label'}>TAPE</div></td>
                  <td width={'30%'} className={'center'}><div className={'label'}>REMARKS</div></td>
                </tr>
                {this.renderItems(containerData.items)}
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderHeader(label1, value1, label2, value2) {
    return (
      <tr>
        <td width={'20%'} className={'no-border-b'}><div className={'label'}>{label1}</div></td>
        <td width={'30%'} className={'no-border-b'}><div className={'label'}>{value1}</div></td>
        <td width={'20%'} className={'no-border-b'}><div className={'label'}>{label2}</div></td>
        <td width={'30%'} className={'no-border-b'}><div className={'label'}>{value2}</div></td>
      </tr>
    );
  }
  renderItems(items) {
    return items?.map((item, index) => {
      return (
        <tr key={index}>
          <td className={'center'}><div className={'label'}>{item.seqNo}</div></td>
          <td><div className={'label'}>{item.localName}/{item.engName}</div></td>
          <td><div className={'label'} style={{textAlign: 'right'}}>{item.cbm}</div></td>
          <td><div className={'label'} style={{textAlign: 'right'}}>{item.packages}</div></td>
          <td><div className={'label'} style={{textAlign: 'right'}}>{item.kg}</div></td>
          <td><div className={'label'} style={{textAlign: 'center'}}>{item.tape}</div></td>
          <td><div className={'label'}>{item.remark}</div></td>
        </tr>
      );
    });
  }
}

export function useContainerLoadPlanPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const containerFormRef = React.useRef();
  const containerModeRef = React.useRef();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: 'COSHIP Container Load Plan',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1280,
    children: <ContainerLoadPlanPrint ref={printRef} printData={printData} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        containerData: containerFormRef.current,
        containerMode: containerModeRef.current,
      };
      setPrintData(newPrintData);
      if(containerModeRef.current === 'print') {
        setTimeout(() => {
          print();
        }, 100);
      }

    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (containerData, mode) => {
      containerFormRef.current = containerData;
      containerModeRef.current = mode;
      modal.open();
    },
    print,
  };
}

export default ContainerLoadPlanPrint;
