/**
 * @flow
 */
import React from 'react';
import {api, apiBL, util} from "../../services/service";
import {useSimpleGridModal} from "../../components/SimpleModal";
import {RowClickedEvent} from "ag-grid-community";


export default function useBLTrackingModal() {
  const blIdRef = React.useRef();

  async function onQuery(data) {
    const res = await apiBL.viewBLTrackingData(data);
    modal.setRows(res.data);
  }

  async function onSave() {
    // let {fullPay, jobId, payDate: pay_date = util.getCurrentDate(), currency = user.currency, rate, payAmount: pay_amount, payType: pay_type = 'CHEQUE', remark} = accountPaymentForm.getValues();
    // let isFullPay = false;
    // if (fullPay !== undefined && fullPay[0] === 'on')
    //   isFullPay = true;
    //
    // if(!rate || isNaN(rate)) {
    //   util.showWarning('Please enter a valid Rate data!');
    //   return;
    // }
    // if(!pay_amount || isNaN(pay_amount)) {
    //   util.showWarning('Please enter a valid Amount data!');
    //   return;
    // }
    // // save data to db
    // const params = {isFullPay, accountId: accountId, jobId, payDate: util.toTS(pay_date), currency, rate, payAmount: pay_amount, payType: pay_type, remark};
    // let res = await apiAccount.payHistorySave(params);
    // if (res) {
    //   await onQuery({accountId});
    //   accountPaymentForm.clearValues();
    //   modal.close();
    // }
  }

  const modal = useSimpleGridModal({
    title: 'B/L Tracking',
    centered: true,
    width: 920,
    buttons: [],
    gridProps: {
      columns: [
        {field: 'status_code', hide: true},
        {field: 'track_date', headerName: 'Date', valueFormatter: util.dateFormatter, width: 120},
        {field: 'track_status', headerName: 'Staus', width: 100},
        {field: 'staff_name', headerName: 'Staff', width: 100},
        {field: 'remark', headerName: 'Remark', editable: true, flex: 1},
      ],
      actions: [],
      actionWidth: 70,
      onAction: (action, data) => {},
      rows: [],
      height: 250,
      className: 'mb-20',
      agGridProps: {
        onCellValueChanged(e) {
          const blId = blIdRef.current;
          const statusCode = e.data.status_code;
          const remark = e.data.remark;
          const data = {blId, statusCode, remark}
          if(remark?.length >= 200) {
            util.showWarning('Please enter less than 200 characters.');
            return;
          }
          apiBL.setTrackingRemark(data).then();
        }
      }
    },
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      if (blIdRef.current) {
        onQuery({blId: blIdRef.current}).catch();
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (blId) => {
      blIdRef.current = blId;
      modal.open();
    },
  };
}
