/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {PortSearchInput, TradePartnerSearchInput} from '../../components/SearchInput';

const FN = {
  ID: 'id',
  FILING_NO: 'filingNo',
  CARRIER_ID: 'carrierId',
  CARRIER: 'carrier',
  CRAFT_NO: 'craftNo',
  ETD_DATE: 'etdDate',
  ETD_TIME: 'etdTime',
  ETA_DATE: 'etaDate',
  ETA_TIME: 'etaTime',
  DEPARTURE: 'departure',
  DESTINATION: 'destination',
  BILLING_CARRIER: 'billingCarrier',
  C_COUNT: 'ccount',
  VSL_NAME: 'vslName',
  ONBOARD: 'onboard',
};

const SN = {
  ID: 'id',
  FILING_NO: 'filing_no',
  CARRIER_ID: 'carrier_id',
  CARRIER: 'carrier_name',
  CRAFT_NO: 'craft_no',
  ETD_DATE: 'etd_date',
  ETD_TIME: 'etd_time',
  ETA_DATE: 'eta_date',
  ETA_TIME: 'eta_time',
  DEPARTURE: 'departure',
  DESTINATION: 'destination',
  BILLING_CARRIER: 'billingCarrier',
  C_COUNT: 'ccount',
  VSL_NAME: 'vsl_name',
  ONBOARD: 'onboard',
};

class VesselFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'vessel-form',
      fields: [
        { name: FN.ID, serverName: SN.ID, label: '', required: false, },
        { name: FN.CARRIER_ID, serverName: SN.CARRIER_ID, label: '', required: false, },
        { name: FN.CARRIER, serverName: SN.CARRIER, label: 'Carrier', required: true, },
        { name: FN.CRAFT_NO, serverName: SN.CRAFT_NO, label: '', required: true, },
        { name: FN.ETD_DATE, serverName: SN.ETD_DATE, label: 'ETD', required: false, type: 'date' },
        { name: FN.ETD_TIME, serverName: SN.ETD_TIME, label: '', required: false, },
        { name: FN.ETA_DATE, serverName: SN.ETA_DATE, label: 'ETA', required: false, type: 'date'},
        { name: FN.ETA_TIME, serverName: SN.ETA_TIME, label: '', required: false, },
        { name: FN.DEPARTURE, serverName: SN.DEPARTURE, label: 'POL', required: true, },
        { name: FN.DESTINATION, serverName: SN.DESTINATION, label: 'POD', required: true, },
        { name: FN.BILLING_CARRIER, serverName: SN.BILLING_CARRIER, label: 'Billing Carrier', required: false, },
        { name: FN.VSL_NAME, serverName: SN.VSL_NAME, label: 'VSL/VOY', required: true, },
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const _tp = (name) => <TradePartnerSearchInput idField={_n(`${name}Id`)} displayField={_n(name)} formik={formik} fields={fields} errors={errors} />;
    const _pt = (name) => <PortSearchInput idField={_n(`${name}Id`)} displayField={_n(name)} formik={formik} fields={fields} errors={errors} />
    return (
      <div className={'flex mr-4'}>
        <div className={'flex-1'}>
          <div className={'flex-4'}>{_tp(FN.CARRIER)}</div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.VSL_NAME)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.CRAFT_NO)}</div>
          </div>
          <div className={'flex-4'}>{_pt(FN.DEPARTURE)}</div>
          <div className={'flex-4'}>{_pt(FN.DESTINATION)}</div>
        </div>
        <div className={'flex-1'}>
          <div className={'flex-4'}>{_tp(FN.BILLING_CARRIER)}</div>
          <div className={'flex-4'} style={{paddingTop: '16px', paddingBottom: '15px'}}>{'\u00a0'}</div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.ETD_DATE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.ETD_TIME)}</div>
          </div>
          <div className={'flex'}>
            <div className={'flex-3'}>{_r(FN.ETA_DATE)}</div>
            <div className={'flex-1 ml-8'}>{_r(FN.ETA_TIME)}</div>
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => values;
}

export default VesselFormManager;
