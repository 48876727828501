/**
 * @flow
 */
import React from 'react';
import BLFormManager from '../../forms/BLFormManager';
import BLBoxFormManager from '../../forms/bl/BLBoxFormManager';
import {useSimpleGrid} from '../../components/SimpleGrid';
import {apiBL, util} from '../../services/service';
import {FN} from '../../forms/field-defs/bl';
import {DefaultButton} from '../../components/buttons';
import {loadBoxList} from './useBLBoxModal';
import {useSimpleModal} from '../../components/SimpleModal';

const blBoxForm = new BLBoxFormManager();

export default function useBLMasterBoxModal(blForm: BLFormManager) {
  async function onAddBox() {
    const values = blBoxForm.getValues();
    const blId = blForm.getValue(FN.ID);
    const {boxNo} = values;
    if (!boxNo) {
      util.showWarning('Please enter box no.');
      return;
    }
    const res = await apiBL.setBLBox({...values, blId});
    if (res) {
      await loadBoxList(blId, boxGrid);
      blBoxForm.clearValues();
    }
  }
  async function onDeleteBox(data) {
    const blId = blForm.getValue(FN.ID);
    const {box_no: boxNo} = data;
    const res = await apiBL.delBLBox(blId, boxNo);
    if (res) {
      await loadBoxList(blId, boxGrid);
    }
  }
  function onSelectBox(data) {
    blBoxForm.setValues(data);
  }
  const boxGrid = useBoxGrid(blForm, onDeleteBox, onSelectBox);
  function renderChildren() {
    return (
      <div className={'flex-column'}>
        <span>CTNS</span>
        {boxGrid.render()}
        {blBoxForm.renderForm()}
        <div className={'w-full flex center'}>
          <DefaultButton label={'Save CTN'} onClick={onAddBox} noMargin />
        </div>
      </div>
    );
  }
  const modal = useSimpleModal({
    title: 'MANAGE CTNS',
    width: 720,
    children: renderChildren(),
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const blId = blForm.getValue(FN.ID);
      if (blId) {
        loadBoxList(blId, boxGrid).catch();
      }
    } else if (modal.isOpen === false) {
      boxGrid.setRows([]);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: () => {
      modal.open();
    },
  };
}

let boxGridCallbacks = {};
function useBoxGrid(blForm: BLFormManager, onDelete, onSelectRow) {
  boxGridCallbacks = {
    onDelete, onSelectRow
  };
  return useSimpleGrid({
    columns: [
      {field: 'box_no', headerName: 'No.', width: 50, minWidth: 50},
      {field: 'length', headerName: 'Length', valueFormatter: util.formatWeight, width: 85, minWidth: 85},
      {field: 'width', headerName: 'Width', valueFormatter: util.formatWeight, width: 85, minWidth: 85},
      {field: 'height', headerName: 'Height', valueFormatter: util.formatWeight, width: 85, minWidth: 85},
      {field: 'pcs', headerName: 'PCS', width: 85, minWidth: 85},
      {field: 'cal_weight', headerName: 'V.Weight', valueFormatter: util.formatWeight, flex: 1, minWidth: 85},
      {field: 'box_cbm', headerName: 'CBM', valueFormatter: util.formatCBM, flex: 1, minWidth: 85},
    ],
    height: 450,
    actions: ['delete'],
    actionWidth: 100,
    onAction: (action, data) => {
      if (action === 'delete') {
        util.showConfirm('Are you sure to delete?', () => {
          boxGridCallbacks.onDelete(data);
        });
      }
    },
    agGridProps: {
      rowSelection: 'single',
      onCellClicked(e) {
        if (e.colDef.headerName !== 'Actions') {
          boxGridCallbacks.onSelectRow(e.data);
        }
      }
    }
  });
}
