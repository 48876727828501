import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {util} from "../../../services/service";

const  TotalProfit = (props) => {
  const data = props.data;
  const currency = props.currency;
  const gross = data && currency === 'CAD' ? data.currency.CAD.gross : data && currency === 'USD' ? data.currency.USD.gross : data && currency === 'KRW' ? data.currency.KRW.gross : 0;
  const revenue = data && currency === 'CAD' ? data.currency.CAD.revenue : data && currency === 'USD' ? data.currency.USD.revenue : data && currency === 'KRW' ? data.currency.KRW.revenue : 0;
  const symbol = currency === 'CAD' || currency === 'USD' ? '$' : '₩';
  const total = (revenue && gross) ? `${symbol} ${revenue} / ${gross}` : 0;
  const value = (revenue && gross) ? revenue / gross * 100 : 0;
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
              {currency} {util.currencyFormat(revenue)}
            </h5>
          </div>
          <h5 className="dashboard__booking-total-description">{util.currencyFormat(gross)}</h5>
          <div className="progress-wrap progress-wrap--small progress-wrap--blue-gradient progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">{parseInt(value)}%</p>
            <Progress value={parseInt(value)}/>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TotalProfit;
