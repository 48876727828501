/**
 * @flow
 */
import * as React from 'react';
import {apiBL, apiSettings, util} from "../../../../services/service";
import {TabButton} from "../../CommonStyle";
import CourierHouseBLFormManager from "../../../../forms/newCoship/courier/house/CourierHouseBLFormManager";
import {DangerButton, PrimaryButton} from "../../../../components/buttons";
import CourierHouseBLCustomerFormManager from "../../../../forms/newCoship/courier/house/CourierHouseBLCustomerFormManager";
import CourierHouseBLWorkDetailsFormManager from "../../../../forms/newCoship/courier/house/CourierHouseBLWorkDetailsFormManager";
import CourierHouseBLWorkOrdersFormManager from "../../../../forms/newCoship/courier/house/CourierHouseBLWorkOrdersFormManager";
import {getBLData, pickupTypeOptions} from "../../../../forms/field-defs/newCoship";
import {apiCourier} from '../../../../services/newCoship/service';
import CourierHouseBLAccountItemFormManager from "../../../../forms/newCoship/courier/house/CourierHouseBLAccountItemFormManager";
import ReactLoading from "react-loading";
import {LoadingContainer} from "../../CommonStyle";
import CourierHouseBLAccountList from "./CourierHouseBLAccountList";
import {useSelector} from "react-redux";
import {
  setBound,
  setCarrierType, setCBM,
  setFilingNo,
  setHouseAccountARData, setHouseAccountDeposit,
  setHouseAccountList,
  setHouseAPTotal,
  setHouseARTotal,
  setHouseBalanceTotal, setKind, setMawbNo, setPackDate, setPKG
} from "../../../../redux/reducers/newCoshipReducer";
import useEmailModal from "../../../../modals/email/useEmailModal";
import {useSendEmailModal} from "../../../../forms/email/SendEmailFormManager";
import {useOEHBLPrintModal} from "../../../../forms/print/v2/OEHBLPrintForm";
import {useUser} from "../../../../redux/reducers/userReducer";
import {useTopbar} from "../../../../redux/reducers/topbarReducer";
import toInt from "validator/es/lib/toInt";
import toFloat from "validator/es/lib/toFloat";
import {labelType} from "../../../../forms/helper";
import {useOEHLabelPrintModal} from "../../../../forms/print/v2/OEHLabelPrintForm";
import {useManifestPreviewModal} from "../../../../print-layouts/ManifestPrint";
import {useManifestPreviewModal2} from "../../../../print-layouts/ManifestPrint2";
import {useManifestPreviewModal3} from "../../../../print-layouts/ManifestPrint3";
import {useCourierOceanPreviewModal} from "../../../../print-layouts/CourierOceanPrint";

const houseBLForm = new CourierHouseBLFormManager();
const customerForm = new CourierHouseBLCustomerFormManager();
const workDetailsForm = new CourierHouseBLWorkDetailsFormManager();
const workOrdersForm = new CourierHouseBLWorkOrdersFormManager();
const accountARItemForm = new CourierHouseBLAccountItemFormManager('AR');
const accountAPItemForm = new CourierHouseBLAccountItemFormManager('AP');

const TABS = [
  {index: 1, path: '/admin/courier/house/customer'},
  {index: 2, path: '/admin/courier/house/work'},
  {index: 3, path: '/admin/courier/house/account'},
  {index: 4, path: '/admin/courier/house/accountlist'}
];

const CourierHouseBL = () => {
  useTopbar({label: 'Courier'}, {label: 'House BL'});
  const qs = util.getQS();
  const {id} = qs;
  const user = useUser();
  const [isEdit, setIsEdit] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState<number>(1);
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [isShowAccountList, setIsShowAccountList] = React.useState<boolean>(false);
  const [isFlag, setIsFlag] = React.useState<boolean>(false);
  const {filingNo, carrierType, kind, bound} = useSelector(state => state['newCoship']);
  const pathname = window.location.pathname;

  const onSendEmail = (mode, data) => {
    const {pic_email, pic_name, toEmail, template, title, message, selectedFile, blId, partnerId} = data;
    if(!template && mode === 'send') {
      return util.showWarning('Please select template!');
    }
    if(!pic_email) {
      return util.showWarning('Please enter a valid email!');
    }
    if(!message) {
      return util.showWarning('Please enter a valid message!');
    }
    const formData = new FormData();
    formData.append('blId', blId);
    formData.append('partnerId', partnerId);
    formData.append('from', pic_email);
    formData.append('fromName', pic_name);
    formData.append('to', toEmail);
    formData.append('subject', title);
    formData.append('message', message);
    formData.append('houses', blId);
    formData.append('file', selectedFile);
    const res = apiSettings.sendEmail(formData);
    if(res) {
      util.showSuccess('Email has been sent successfully.');
      sendEmailModal.close();
      emailModal.onSearch().catch();
    }
  };

  const onSearch = async () => {
    if (id) {
      apiCourier.getBLData({id: util.toInt(id)}).then((res) => {
        if (res) {
          const blData = {id, bl_status: res.data.bl_status, ...res.data.jcommon, ...res.data.jcustomer, ...res.data.jshipment, ...res.data.jroute, ...res.data.jvessel};
          houseBLForm.setValues(blData);
          customerForm.setValues(blData);
          customerForm.setData(res.data.pack_list);
          workDetailsForm.setValues(blData);
          workOrdersForm.setData(res.data.work_order);
          res.data.account_tab?.ar_list?.length > 0 && accountARItemForm.setData(res.data.account_tab);
          res.data.account_tab?.ap_list?.length > 0 && accountAPItemForm.setData(res.data.account_tab);
          setIsEdit(true);
          setIsFlag(res.data.flag);
          setKind(util.dispatch, res.data.kind);
          setCarrierType(util.dispatch, res.data.carrier_type);
          setBound(util.dispatch, res.data.bound);
          setFilingNo(util.dispatch, res.data.filing_no);
          setMawbNo(util.dispatch, res.data.jcommon?.mawbNo);
          setHouseARTotal(util.dispatch, res.data.account_tab?.ar_amount);
          setHouseAPTotal(util.dispatch, res.data.account_tab?.ap_amount);
          setHouseBalanceTotal(util.dispatch, res.data.account_tab?.balance_amount);
          setHouseAccountList(util.dispatch, res.data.ar_ap_list);
          setHouseAccountARData(util.dispatch, res.data.account.ar_data);
          setHouseAccountDeposit(util.dispatch, res.data.account.deposit);
          setPackDate(util.dispatch, res.data.jshipment.pack_date);
          setCBM(util.dispatch, res.data.jshipment.cbm);
          setPKG(util.dispatch, res.data.jshipment.package);
        } else {
          setIsEdit(false);
        }
      });
    } else {
      houseBLForm.clearValues();
      customerForm.clearValues();
      customerForm.setData([]);
      workDetailsForm.clearValues();
      workOrdersForm.setData([]);
      accountARItemForm.setData([]);
      accountAPItemForm.setData([]);
      setIsEdit(false);
      setIsFlag(false);
      setFilingNo(util.dispatch, undefined);
      setKind(util.dispatch, 'C');
      setCarrierType(util.dispatch, 'A');
      setBound(util.dispatch, 'E');
      setHouseARTotal(util.dispatch, '');
      setHouseAPTotal(util.dispatch, '');
      setHouseBalanceTotal(util.dispatch, '');
      setHouseAccountList(util.dispatch, []);
      setHouseAccountARData(util.dispatch, []);
      setHouseAccountDeposit(util.dispatch, []);
      setPackDate(util.dispatch, '');
      setCBM(util.dispatch, '');
      setPKG(util.dispatch, '');
      // default setting
      houseBLForm.setValue('branchId', user.branch_id);
    }
  };

  const onClickTab = (tabIndex) => {
    setClickedIndex(tabIndex);
    customerForm.isShowForm = tabIndex === TABS[0].index;
    workDetailsForm.isShowForm = tabIndex === TABS[1].index;
    workOrdersForm.isShowForm = tabIndex === TABS[1].index;
    accountARItemForm.isShowForm = tabIndex === TABS[2].index;
    accountAPItemForm.isShowForm = tabIndex === TABS[2].index;
    util.nav(id ? `${TABS[tabIndex-1].path}?id=${id}` : TABS[tabIndex-1].path);
  };

  const onEmail = () => {
    const blId = id ? util.toInt(id) : 0;
    const partnerId = bound === 'E' ? customerForm.getValue('shipperId') : customerForm.getValue('consigneeId');
    const customerEmail = customerForm.getValue('shipperEmail');
    emailModal.open(blId, partnerId, customerEmail, sendEmailModal);
  };

  const onFlag = () => {
    apiCourier.setBLFlag({bl_id: util.toInt(id), flag: !isFlag}).then((res) => {
      if (res) {
        setIsFlag(!isFlag);
        return util.showSuccess('Flag has been updated successfully!');
      }
    });
  };

  const onPrint = () => {
    oehBLPrintModal.open();
  };

  const oehLabelPrintModal = useOEHLabelPrintModal(houseBLForm, true, customerForm, workDetailsForm);
  const manifestPreviewModal = useManifestPreviewModal();
  const manifestPreviewModal2 = useManifestPreviewModal2();
  const manifestPreviewModal3 = useManifestPreviewModal3();
  const courierOceanModal = useCourierOceanPreviewModal();

  const onLabel = () => {
    if (carrierType === 'A') {
      if (kind === 'C') {
        const masterNo = houseBLForm.getValue('mawbNo');
        const chargeableWeightKg = customerForm.getValue('chargeableWeightKg');
        const shipper = customerForm.getValue('shipper');
        const consignee = customerForm.getValue('consignee');
        const consigneePhone = customerForm.getValue('consigneePhone1');
        const consigneeAddrLocal = customerForm.getValue('consigneeAddrLocal');
        const packages = typeof customerForm.getValue('package') === 'string' ? toInt(customerForm.getValue('package')) : customerForm.getValue('package');
        // const gridData = customerForm.getGridData();
        // if (!masterNo) {
        //   util.showWarning('MAWB No. is not set!');
        //   return;
        // }
        // if (!toFloat(chargeableWeightKg)) {
        //   util.showWarning('Please enter chargeable weight!');
        //   return;
        // }
        // if (gridData.length <= 0) {
        //   util.showWarning('Please enter shipment item!');
        //   return;
        // }
        if (!shipper) {
          util.showWarning('Please enter shipper!');
          return;
        }
        if (!consignee) {
          util.showWarning('Please enter consignee!');
          return;
        }
        if (!consigneePhone) {
          util.showWarning('Please enter consignee phone number!');
          return;
        }
        if (!consigneeAddrLocal) {
          util.showWarning('Please enter consignee address!');
          return;
        }
        if (!packages) {
          util.showWarning('Please enter package!');
          return;
        }
        const blNo = carrierType === 'A' ? houseBLForm.getValue('hawbNo') : houseBLForm.getValue('hblNo');
        const branchId = houseBLForm.getValue('branchId');
        const branchName = houseBLForm.getField('branchId').options.find(i => i.value === (typeof branchId === 'number' ? branchId : Number(branchId))).label;
        apiBL.getBLManifestList({blNo, branchId, branchName}).then(({data}) => {
          if (data?.length > 0) {
            if (user.label_type === labelType.LABEL3) {  // CJ 대한통운
              manifestPreviewModal3.open(data, data[0].ctns > 1);
            } else if (user.label_type === labelType.LABEL2) { // 우체국
              manifestPreviewModal2.open(data, data[0].ctns > 1);
            } else {  // LETTER
              manifestPreviewModal.open(data);
            }
            apiBL.setBLPrint(data.map(r => r['bl_no'])).catch();
          } else {
            return util.showWarning('There is no data.');
          }
        });
      }
    } else {
      const consignee = customerForm.getValue('consigneeLocal');
      const totalPackage = customerForm.getValue('package');
      const data = [{name: consignee, totalPackage}];
      courierOceanModal.open(data);
    }
  };

  const onReceipt = () => {

  };

  const onSave = async () => {
    const houseBLFormValues = houseBLForm.getValues();
    const customerFormValues = customerForm.getValues();
    const workDetailsFormValues = workDetailsForm.getValues();
    const values = {
      ...houseBLFormValues,
      ...customerFormValues,
      ...workDetailsFormValues,
      id: id ? util.toInt(id) : undefined,
      portType: houseBLFormValues.portType ?? 'A',
      kind: houseBLFormValues.kind ?? 'C',
      pickupType: customerFormValues.pickupType ?? pickupTypeOptions[0].value,
      consigneeId: customerFormValues.consigneeId ?? 0,
      package: customerFormValues.package ?? 1,
    };
    const data = getBLData(false, values);
    let payload = {...data, filingNo}
    if (!(payload.jcustomer.shipperId && (payload.jcustomer.consignee || payload.jcustomer.consigneeLocal))) {
        return util.showWarning('Please enter a Shipper or Consignee data!');
      }
    setShowLoading(true);
    await apiCourier.setCourierBL(payload).then(res => {
      if (res) {
        util.nav(`${pathname}?id=${res.data.id}`);
        util.showSuccess('House BL has been saved successfully!');
        setShowLoading(false);
        onSearch().catch();
      }
    });
  };

  const renderButton = () => {
    const isShowSave = clickedIndex === 1 || clickedIndex === 2;
    return (
      <div className={'flex'} style={{justifyContent: 'center', marginTop: 30}}>
        {/*<div className={'mr-2'}><PrimaryButton label={'RECEIPT'} disabled={!isEdit} onClick={onReceipt}/></div>*/}
        <div className={'mr-2'}><PrimaryButton label={'LABEL'} disabled={!isEdit} onClick={onLabel}/></div>
        {/*{carrierType === 'O' && <div className={'mr-2'}><PrimaryButton label={'P/L'} disabled={!isEdit} onClick={onPackingList}/></div>}*/}
        <div className={'mr-2'}><PrimaryButton label={'EMAIL'} disabled={!isEdit} onClick={onEmail}/></div>
        <div className={'mr-2'}>
          {isFlag ? <DangerButton label={'UNFLAG'} disabled={!isEdit} onClick={onFlag}/> : <PrimaryButton label={'FLAG'} disabled={!isEdit} onClick={onFlag}/>}
        </div>
        {bound === 'E' && <div className={'mr-2'}><PrimaryButton label={'B/L PRINT'} disabled={!isEdit} onClick={onPrint}/></div>}
        {isShowSave && <div><PrimaryButton label={'SAVE'} onClick={onSave} noMargin/></div>}
      </div>
    );
  };

  const emailModal = useEmailModal();
  const sendEmailModal = useSendEmailModal(onSendEmail, id, houseBLForm);
  const oehBLPrintModal = useOEHBLPrintModal(true, houseBLForm, customerForm, workDetailsForm);

  React.useEffect(() => {
    onSearch().catch();
  }, [id]);

  React.useEffect(() => {
    if (pathname === TABS[1].path) {
      setClickedIndex(TABS[1].index);
      customerForm.isShowForm = false;
      workDetailsForm.isShowForm = true;
      workOrdersForm.isShowForm = true;
      accountARItemForm.isShowForm = false;
      accountAPItemForm.isShowForm = false;
      workOrdersForm.blId = id;
      setIsShowAccountList(false);
    } else if (pathname === TABS[2].path) {
      setClickedIndex(TABS[2].index);
      customerForm.isShowForm = false;
      workDetailsForm.isShowForm = false;
      workOrdersForm.isShowForm = false;
      accountARItemForm.isShowForm = true;
      accountAPItemForm.isShowForm = true;
      accountARItemForm.blId = id;
      accountAPItemForm.blId = id;
      setIsShowAccountList(false);
    } else if (pathname === TABS[3].path) {
      setClickedIndex(TABS[3].index);
      setIsShowAccountList(true);
    } else {
      setClickedIndex(TABS[0].index);
      customerForm.isShowForm = true;
      workDetailsForm.isShowForm = false;
      workOrdersForm.isShowForm = false;
      accountARItemForm.isShowForm = false;
      accountAPItemForm.isShowForm = false;
      customerForm.houseBLForm = houseBLForm;
      customerForm.customerForm = customerForm;
      customerForm.workDetailsForm = workDetailsForm;
      setIsShowAccountList(false);
    }
    util.nav(id ? `${pathname}?id=${id}` : pathname);
  }, [id, pathname]);

  return (
    <>
      <div style={{marginLeft: -20}}>
        {houseBLForm.renderForm()}
      </div>
      <hr/>
      <div style={{marginTop: 20}}>
        {showLoading && <LoadingContainer><ReactLoading color={'grey'} type={'spin'} height={100} width={100}/></LoadingContainer>}
        <div className={'flex'} style={{marginLeft: -2}}>
          <TabButton style={{backgroundColor: clickedIndex === TABS[0].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[0].index)}>
            <div>CUSTOMER</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[1].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[1].index)}>
            <div>WORK DETAILS</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[2].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[2].index)}>
            <div>ACCOUNTING</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[3].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[3].index)}>
            <div>AR / AP LIST</div>
          </TabButton>
        </div>
        <div style={{backgroundColor: 'white', minHeight: clickedIndex === TABS[3].index ? 200 : 580, border: clickedIndex === TABS[3].index ? '' : '1px solid'}}>
          {customerForm.renderForm()}
          {workDetailsForm.renderForm()}
          {clickedIndex === TABS[1].index && <hr className={'ml-3 mr-3'} style={{marginBottom: 0}}/>}
          {workOrdersForm.renderForm()}
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>
                <div className={'mr-1'}>{accountARItemForm.renderForm()}</div>
              </div>
              <div className={'flex-1'}>
                <div className={'mr-1'}>{accountAPItemForm.renderForm()}</div>
              </div>
            </div>
          </div>
          <CourierHouseBLAccountList isShowList={isShowAccountList} onSearch={onSearch}/>
        </div>
        {clickedIndex !== TABS[3].index && renderButton()}
        {emailModal.render()}
        {sendEmailModal.render()}
        {oehBLPrintModal.render()}
        {oehLabelPrintModal.render()}
        {manifestPreviewModal.render()}
        {manifestPreviewModal2.render()}
        {manifestPreviewModal3.render()}
        {courierOceanModal.render()}
      </div>
    </>
  );
};

export default CourierHouseBL;
