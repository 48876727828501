/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import {currencyOptions} from '../helper';
import React from "react";

class AccountBLRateEditFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'account-bl-rate-edit',
      fields: [
        {name: 'id', serverName: 'id'},
        {name: 'blNo', serverName: 'bl_no', label: 'B/L No.', required: true},
        {name: 'currency', serverName: 'currency', label: 'Currency', options: currencyOptions, noDefOption: true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
        useDeleteButton: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    const currency = _v('currency');
    React.useEffect(() => {
      if(!currency) {
        _c('currency', 'CAD');
      }
    }, [currency]);
    return (
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-12'}>{_r('blNo')}</div>
        <div className={'flex-1'}>{_r('currency')}</div>
      </div>
    );

  };
  onValidate = (values) => values;
}

export default AccountBLRateEditFormManager;
