/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';

const FN = {
  ID: 'id',
  JOB_ID: 'jobId',
  NAME: 'bname',
  ACCOUNT_NO: 'accountNo',
  HOLDER: 'holder',
  REMARK: 'remark',
  MEMO: 'memo',
};

const SN = {
  ID: 'id',
  JOB_ID: 'job_id',
  NAME: 'bname',
  ACCOUNT_NO: 'account_no',
  HOLDER: 'holder',
  REMARK: 'remark',
  MEMO: 'memo',
};

class BankInfoFormManager extends FormManager {
  static FN = FN;
  static SN = SN;
  constructor() {
    super({
      prefix: 'bank-info-form',
      fields: [
        {name: FN.ID, serverName: SN.ID, label: ''},
        {name: FN.JOB_ID, serverName: SN.JOB_ID, label: ''},
        {name: FN.NAME, serverName: SN.NAME, label: ''},
        {name: FN.ACCOUNT_NO, serverName: SN.ACCOUNT_NO, label: ''},
        {name: FN.HOLDER, serverName: SN.HOLDER, label: ''},
        {name: FN.REMARK, serverName: SN.REMARK, label: ''},
        {name: FN.MEMO, serverName: SN.MEMO, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    //const [formik, _, fields, errors] = this.renderParams;
    return (
      <>
        <div className={'flex w-full ml-8'}>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Name</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Account No.</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Holder</span></div>
          <div className={'flex-1 mr-8'}><span className={'f-11 pl-8'}>Remark</span></div>
        </div>
        <div className={'flex w-full ml-8'}>
          <div className={'flex-1 mr-8'}>{_r(FN.NAME)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.ACCOUNT_NO)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.HOLDER)}</div>
          <div className={'flex-1 mr-8'}>{_r(FN.REMARK)}</div>
        </div>
        <div className={'flex w-full ml-8'}>
          <span className={'f-11 pl-8'}>Memo</span>
        </div>
        <div className={'flex w-full ml-8'}>
          <div className={'flex-1 mr-8'}>{_r(FN.MEMO)}</div>
        </div>
      </>
    );
  };
  onValidate = (values) => {
    // TODO
    return values;
  };
}

export default BankInfoFormManager;
