/**
 * @flow
 */
import {useDataGridView} from '../components/DataGridView';
import {api, util} from '../services/service';
import {courierOptions, eCommerceOptions} from '../forms/helper';
import {CARGO_COURIER, CARGO_ECOMMERCE, CARGO_FORWARDING, CARGO_MOVING, TAB_TRACKING} from '../modal-tabs/types';
import {kindFormatter} from '../forms/field-defs/bl';
import React from 'react';

export default function useListAllGridView(cargoType, cargoModal, printBLModal, printInvModal, onRowClick) {
  return useDataGridView({
    async onAction(action, data) {
      if (action === 'edit') {
        onRowClick(data);
      } else if (action === 'print') {
        printBLModal && printBLModal.open();
      } else if (action === 'invoice') {
        //printInvModal && printInvModal.open();
        cargoModal.open(data.hbl, TAB_TRACKING);
      } else if (action === 'commission') {
        util.openTab(`/admin/account/list?blNo=${data['bl_no']}`);
      }
    },
    async onQuery(gridInfo) {
      switch (cargoType) {
        case CARGO_MOVING:
          return api.cargoMovingListAll(gridInfo);
        case CARGO_COURIER:
          return api.cargoCourierListAll(gridInfo);
        case CARGO_ECOMMERCE:
          return api.cargoListAll({...gridInfo, cargoType}); // TODO:
        case CARGO_FORWARDING:
        case 'BL':
          return api.cargoForwardListAll(gridInfo);
        default:
          throw new Error();
      }
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
      if (headerName !== 'Actions') {
        if (cargoModal) {
          cargoModal.open(data.hbl);
        } else {
          // if(headerName === 'B/L No.') {
          //   onRowClick(data);
          // }
        }
      }
    },
    name: `ListAll${cargoType}`,
    label: 'List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: cargoType === 'BL' ? 'B/L' : cargoType},
    menuLabel: {label: 'List'},
    sortCol: cargoType === CARGO_FORWARDING ? 'post_date' : 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'commission'], //['print', 'invoice'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    columns: () => {
      if (cargoType === CARGO_MOVING) {
        return [
          {field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, width: 140},
          {field: 'hbl', headerName: 'HBL', width: 100},
          {field: 'byOA', headerName: 'By', width: 100},
          {field: 'customer_name', headerName: 'Customer', width: 100},
          {field: 'departure', headerName: 'Departure', width: 120},
          {field: 'partner_name', headerName: 'Partner', width: 120},
          {field: 'vin_info', headerName: 'Vin #', width: 120},
          {field: 'extra_info', headerName: 'Remark', flex: 1},
        ];
      } else if (cargoType === CARGO_COURIER) {
        return [
          {field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, width: 140},
          {field: 'hbl', headerName: 'HBL', width: 100},
          {field: 'byOA', headerName: 'By', width: 100},
          {field: 'sub_type', headerName: 'Type', width: 150, valueFormatter: subTypeFormatter},
          {field: 'customer_name', headerName: 'Customer', width: 100},
          {field: 'departure', headerName: 'Departure', width: 120},
          {field: 'partner_name', headerName: 'Dropzone', width: 120},
          {field: 'extra_info', headerName: 'Remark', flex: 1},
        ];
      } else if (cargoType === CARGO_ECOMMERCE) {
        return [
          {field: 'cdate', headerName: 'Created', valueFormatter: util.dateTimeFormatter, width: 140},
          {field: 'hbl', headerName: 'HBL', width: 100},
          {field: 'byOA', headerName: 'By', width: 100},
          {field: 'sub_type', headerName: 'Type', width: 150, valueFormatter: eCommerceTypeFormatter},
          {field: 'customer_name', headerName: 'Customer', width: 100},
          {field: 'departure', headerName: 'Departure', width: 120},
          {field: 'partner_name', headerName: 'Partner', width: 120},
          {field: 'extra_info', headerName: 'Remark', flex: 1},
        ];
      } else if (cargoType === CARGO_FORWARDING || cargoType === 'BL') {
        // TODO
        return [
          {field: 'kind', headerName: 'Service', valueFormatter: kindFormatter, width: 80},
          {field: 'carrier_type', headerName: 'Type', valueFormatter: oceanAirFormatter, width: 80},
          {field: 'bound', headerName: 'Bound', valueFormatter: boundFormatter, width: 80},
          {field: 'h_m', headerName: 'H/M', valueFormatter: houseMasterFormatter, width: 80},
          {field: 'filing_no', headerName: 'Filing No.', cellRendererFramework: blLinkFormatter, flex: 1},
          {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: blLinkFormatter, width: 120},
          {field: 'etd', headerName: 'ETD', valueFormatter: util.dateFormatter, width: 100},
          {field: 'eta', headerName: 'ETA', valueFormatter: util.dateFormatter, width: 100},
          {field: 'shipper', headerName: 'Shipper', width: 140},
          {field: 'consignee', headerName: 'Consignee', width: 120},
          {field: 'totalar', headerName: 'A/R', valueFormatter: util.currencyFormatter, cellRendererFramework: accountLinkFormatter, width: 100},
          {field: 'remark_d', headerName: 'Remark(D)', width: 140},
          {field: 'cdate', headerName: 'C.Date', valueFormatter: util.dateTimeFormatter, width: 120},
          {field: 'branch_name', headerName: 'Branch', width: 120},
        ];
      } else {
        throw new Error();
      }
    },
  });
}

function subTypeFormatter(p) {
  const courier = courierOptions.find(i => i.value === p.value);
  return courier?.label ?? '';
}

function eCommerceTypeFormatter(p) {
  const type = eCommerceOptions.find(i => i.value === p.value);
  return type?.label ?? '';
}

function oceanAirFormatter(p) {
  return p.value === 'O' ? 'Ocean' : p.value === 'A' ? 'Air' : 'Ground';
}

function boundFormatter(p) {
  return p.value === 'E' ? 'Export' : 'Import';
}

function houseMasterFormatter(p) {
  return p.value === 'H' ? 'House' : 'Master';
}

function blLinkFormatter(param) {
  if(param.data['h_m']) {
    let hm = 'master';
    if(param.data['h_m'] === 'H') {
      hm = 'house';
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        // util.nav(`/admin/bl/${hm}/${param.data['id']}`);
        util.openTab(`/admin/bl/${hm}/${param.data['id']}`);
      }}>
        {param.value}
      </a>
    );
  } else {
    return param.value;
  }
}

function accountLinkFormatter(param) {
  if(param.data['bl_no']) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
        e.preventDefault();
        util.openTab(`/admin/account/list?blNo=${param.data['bl_no']}`);
      }}>
        {param.value}
      </a>
    );
  } else {
    return param.value;
  }
}
