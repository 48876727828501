/**
 * @flow
 */
import {apiAccount, util} from "../../services/service";
import {useDataGridView} from "../../components/DataGridView";

export default function useAccountBLRateGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiAccount.blRateList(gridInfo);
    },
    name: `ListBLRate`,
    label: 'B/L Rate List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Account'},
    menuLabel: {label: 'B/L Rate List'},
    sortCol: 'id',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 80,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    columns: [
      {field: 'id', hide: true},
      {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'bl_no', headerName: 'B/L No.', flex: 1},
      {field: 'currency', headerName: 'Currency', flex: 1},
      {field: 'amount_pp', headerName: 'Amount PP', valueFormatter: util.currencyFormatter, flex: 1},
      {field: 'amount_cc', headerName: 'Amount CC', valueFormatter: util.currencyFormatter, flex: 1},
    ],
  });
}
