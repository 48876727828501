/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from "../media/coship_logo.png";

class InvoiceDCPrint extends React.Component {
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountData, accountInvoiceAddItemData, branchListData, bankInfoData} = printData;
    const grossWeight = accountData.grossWeightKg ?
      `${parseFloat(accountData.grossWeightKg).toFixed(2)} KGS / ${(parseFloat(accountData.grossWeightKg)*2.205).toFixed(2)} LBS` : '';
    const finalDest = accountData.fDestEta ? `${accountData.finalDestLabel} / ${accountData.fDestEta}` : accountData.finalDestLabel;
    const packages = accountData.package ? `${accountData.package} ${accountData.packageType}` : '';
    const total = accountInvoiceAddItemData.totalAmount ? util.toFloat(accountInvoiceAddItemData.totalAmount) : 0.00;
    const title = total >= 0 ? 'DEBIT NOTE' : 'CREDIT NOTE';
    return (
      <div style={{color: 'black'}}>
        {this.renderHeader(accountData, accountInvoiceAddItemData, user, title)}
        <hr />
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('OUR FILING NO.', accountData.filingNo)}
            {this.renderInvoiceInfoLine('POR', '')}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('AGENT FILING NO.', accountData.agentRefNo)}
            {this.renderInvoiceInfoLine('POL / ETD', util.displayWithMark(accountData.polLabel, accountData.polEtd, '/'))}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('MASTER B/L NO.', accountData.mblNo)}
            {this.renderInvoiceInfoLine('POD / ETA', util.displayWithMark(accountData.podLabel, accountData.podEta, '/'))}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('HOUSE B/L NO.', accountData.hblNo)}
            {this.renderInvoiceInfoLine('F.DEST.', finalDest)}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('VESSEL/FLT NO.', accountData.vfNo)}
            {this.renderInvoiceInfoLine('NO. OF PKGS', packages)}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('CARRIER BKG. NO.', accountData.bkgNo)}
            {this.renderInvoiceInfoLine('KGS / LBS(G)', grossWeight)}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('SHIPPER', accountData.shipper)}
            {this.renderInvoiceInfoLine('CBM / CFT', accountData.cbm ? `${accountData.cbm} CBM` : '')}
          </tr>
          <tr className={'flex'}>
            {this.renderInvoiceInfoLine('COMMODITY', accountData.commodity)}
          </tr>
          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
          <tr style={{height: 25}}>
            <td className={'no-border-lr center invoice_label fb-15'} width={'5%'}>M/H</td>
            <td className={'no-border-lr left invoice_label fb-15'} width={'10%'}>B/L NO</td>
            <td className={'no-border-lr left invoice_label fb-15'} width={'25%'}>DESCRIPTION</td>
            <td className={'no-border-lr left invoice_label fb-15'} width={'14%'}>REMARK</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'5%'}>UNIT</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'10%'}>RATE</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'7%'}>QTY</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'12%'}>DEBIT(+)</td>
            <td className={'no-border-lr right invoice_label fb-15'} width={'12%'}>CREDIT(-)</td>
          </tr>
          <tr><td colSpan={9} className={'no-border-all'} height={8}>{''}</td></tr>
          {this.renderInvoiceItems(printData.accountGridData, accountData.hblNo)}
          {this.renderEmptyItems(printData.accountGridData, accountData)}
          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%', marginTop: '10px', marginBottom: '10px'}}>
          <tbody>
          <tr>
            <td className={'no-border-lr no-border-b left invoice_label'} width={'50%'} height={'40px'}>{'\u00a0'}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'10%'} height={'40px'}>TOTAL</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'10%'} height={'40px'}>{'\u00a0'}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'15%'} height={'40px'}>{util.formatCurrency(util.toFloat(accountInvoiceAddItemData.debitAmount))}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'15%'} height={'40px'}>{util.formatCurrency(util.toFloat(accountInvoiceAddItemData.creditAmount))}</td>
          </tr>
          <tr>
            <td className={'no-border-lr no-border-b left invoice_label'} width={'50%'} height={'40px'} style={{paddingTop: '9px'}}>GRAND TOTAL BALANCE DUE TO {user.print.company}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'10%'} height={'40px'}>{'\u00a0'}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'10%'} height={'40px'} style={{fontSize: '1.2em'}}>{accountData.currency}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'15%'} height={'40px'}>{'\u00a0'}</td>
            <td className={'no-border-lr no-border-b right invoice_label'} width={'15%'} height={'40px'} style={{fontSize: '1.2em'}}>{util.formatCurrency(total)}</td>
          </tr>

          </tbody>
        </table>
        <table className={'print-table mt-20'} style={{width: '100%', marginTop: '30px'}}>
          <tbody>
          {this.renderInvoiceExtraInfo('MEMO', accountData.memo)}
          {this.renderInvoiceExtraInfo('BANK INFO', bankInfoData?.memo)}
          </tbody>
        </table>
        <hr className={'line'} />
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
          <tr className={'flex'}>
            {this.renderFootInfo(branchListData)}
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderHeader(accountData, accountInvoiceAddItemData, user, title) {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all right invoice_label'}><h1>{title}</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_right'}>
                  <h3 className={'invoice_label'}>D/C No. : {accountData.dcNo}</h3>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'} style={{marginTop: '15px'}}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>AGENT :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{accountData.partnerAddr}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>D/C DATE</td>
                <td className={'left'}>{accountData.invoiceDate}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>TERMS</td>
                <td className={'left'}>{accountData.terms ? accountData.terms : 0} days</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>DUE DATE</td>
                <td className={'left'}>{accountData.dueDate ? accountData.dueDate : accountData.invoiceDate}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>PROFIT SHARE</td>
                <td className={'left'}>{accountData.profitShare?? 0} %</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>CURRENCY</td>
                <td className={'left'}>{accountData.currency}</td>
              </tr>
              <tr style={{height: 25}}>
                <td className={'invoice_label left fb-15'}>TOTAL AMOUNT</td>
                <td className={'left'}>{util.formatCurrency(util.toFloat(accountInvoiceAddItemData.totalAmount))}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceInfoLine(label, value) {
    return (
      <td className={'no-border-all flex-1'}>
        <div className={'flex'}>
          <div className={'w-152'}>{label}</div>
          <div className={'mx-4'}>:</div>
          <div style={{whiteSpace: 'pre-line'}}>{value}</div>
        </div>
      </td>
    );
  }
  renderInvoiceItems(items, hblNo) {
    return items?.map((item, index) => {
      console.log('hello item', item);
      if(item['jextra']) {
        const jextra = item['jextra']['profitTxt'];
        return (
          <>
          <tr key={index}>
            <td className={'no-border-all center'} width={'5%'}>{'H'}</td>
            <td className={'no-border-all left'} width={'10%'}>{hblNo}</td>
            <td className={'no-border-all left'} width={'25%'}>{item['accountType']}</td>
            <td className={'no-border-all left'} width={'14%'}>{item['remark']}</td>
            <td className={'no-border-all right'} width={'5%'}>{item['unit']}</td>
            <td className={'no-border-all right'} width={'10%'}>{util.formatCurrency(item['rate'])}</td>
            <td className={'no-border-all right'} width={'7%'}>{item['volume']}</td>
            <td className={'no-border-all right'} width={'12%'}>{util.formatCurrency(item['dcType'] === 'DEBIT' ? util.toFloat(item['price']) : 0)}</td>
            <td className={'no-border-all right'} width={'12%'}>{util.formatCurrency(item['dcType'] === 'CREDIT' ? util.toFloat(item['price']) : 0)}</td>
          </tr>
            {
              jextra?.map((value) => {
                return (
                  <tr>
                    <td className={'no-border-all center'} width={'5%'}>{'\u00a0'}</td>
                    <td className={'no-border-all'} width={'10%'}>{'\u00a0'}</td>
                    <td className={'no-border-all jextra'} width={'25%'}>{value}</td>
                    <td className={'no-border-all'} width={'14%'}>{'\u00a0'}</td>
                    <td className={'no-border-all right'} width={'5%'}>{'\u00a0'}</td>
                    <td className={'no-border-all right'} width={'10%'}>{'\u00a0'}</td>
                    <td className={'no-border-all right'} width={'7%'}>{'\u00a0'}</td>
                    <td className={'no-border-all right'} width={'12%'}>{'\u00a0'}</td>
                    <td className={'no-border-all right'} width={'12%'}>{'\u00a0'}</td>
                  </tr>
                );
              })
            }
          </>
        );
      } else {
        return (
          <tr key={index}>
            <td className={'no-border-all center'} width={'5%'}>{item['bl_no']}</td>
            <td className={'no-border-all'} width={'10%'}>{item['bl_no']}</td>
            <td className={'no-border-all'} width={'30%'}>{item['accountType']}</td>
            <td className={'no-border-all right'} width={'10%'}>{item['unit']}</td>
            <td className={'no-border-all right'} width={'10%'}>{item['rate']}</td>
            <td className={'no-border-all right'} width={'7%'}>{item['volume']}</td>
            <td className={'no-border-all right'} width={'14%'}>{util.formatCurrency(item['dcType'] === 'DEBIT' ? util.toFloat(item['price']) : 0)}</td>
            <td className={'no-border-all right'} width={'14%'}>{util.formatCurrency(item['dcType'] === 'CREDIT' ? util.toFloat(item['price']) : 0)}</td>
          </tr>
        );
      }
    });
  }
  renderEmptyItems(items, accountData) {
    const empty = [];
    const numberOfcomm = ((accountData.commodity?.match(/\n/g)||[]).length ?? 0) + 1;
    let jextraLength = 0;
    const itemMap = items?.map(item => {
      if(item['jextra'] && item['jextra']['profitTxt']) {
        jextraLength += item['jextra']['profitTxt'].length;
      }
    });
    let length = 25 - items.length - jextraLength - numberOfcomm;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={8} className={'no-border-all'}>{'\u00a0'}</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceExtraInfo(label, value) {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-line', minHeight: '130px', fontSize: '0.8em', paddingTop: '3px', paddingBottom: '3px'}}>{value}</div></td>
      </tr>
    );
  }
  renderFootInfo(branchLists) {
    return branchLists?.map((item, idx) => {
      return (
        <td key={idx} className={'no-border-all flex-1 f-15'}>
          {item.pic}<br/>
          {item.address}<br/>
          TEL: {item.phone} {item.fax && `FAX: ${item.fax}`}<br/>
          EMAIL: {item.email}
        </td>
      );
    });
  }
}

export function useInvoiceDCPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountFormRef = React.useRef();
  const accountSearchFormRef = React.useRef();
  const accountInvoiceAddItemRef = React.useRef();
  const branchListRef = React.useRef();
  const bankInfoRef = React.useRef();
  const printRef = React.useRef();
  const dcNoRef = React.useRef();
  const isPreviewRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: dcNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceDCPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountFormRef.current.getValues(),
        accountSearchData: accountSearchFormRef.current.getValues(),
        accountInvoiceAddItemData: accountInvoiceAddItemRef.current.getValues(),
        accountGridData: accountInvoiceAddItemRef.current.getGridData(),
        branchListData: branchListRef.current,
        bankInfoData: bankInfoRef.current,
      };
      setPrintData(newPrintData);
      if(!isPreviewRef.current) {
        setTimeout(() => {
          print();
        }, 500);
      }

    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (accountForm, accountSearchForm, accountInvoiceAddItem, branchLists, bankInfo, isPreview) => {
      accountFormRef.current = accountForm;
      accountSearchFormRef.current = accountSearchForm;
      accountInvoiceAddItemRef.current = accountInvoiceAddItem;
      branchListRef.current = branchLists;
      bankInfoRef.current = bankInfo;
      dcNoRef.current = accountForm.getValue('dcNo');
      isPreviewRef.current = isPreview;
      modal.open();
    },
    print,
  };
}

export default InvoiceDCPrint;
