/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";
import {api, util} from "../../services/service";
import {teamMainOptions} from "../helper";

class StaffListEditFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'staff',
      fields: [
        { name: 'userId', serverName: 'id', field: 'id', required: false, hide: true},
        { name: 'areaId', serverName: 'area_id', field: 'area_id', label: 'Branch', options: [], required: true, hide: true},
        { name: 'auth', serverName: 'auth', field: 'auth', label: 'Auth', options: [], required: true, hide: true},
        { name: 'jobTitle', serverName: 'title_id', field: 'title_id', label: 'Title', required: true, options: [], hide: true},
        { name: 'password', serverName: 'password', field: 'password', label: 'Password', required: false, hide: true, useSameAsInput: true},
        { name: 'cdate', serverName: 'cdate', field: 'cdate', headerName: 'Created', required: false, valueFormatter: util.dateFormatter, flex: 1},
        { name: 'loginId', serverName: 'login_id', field: 'login_id', headerName: 'LoginID', required: true, flex: 1},
        { name: 'userName', serverName: 'uname', field: 'uname', headerName: 'Eng.Name', required: true, flex: 1},
        { name: 'userName2', serverName: 'uname2', field: 'uname2', headerName: 'Local Name', required: true, flex: 1},
        { name: 'jtitle', serverName: 'jtitle', field: 'jtitle', headerName: 'Title', required: false, flex: 1},
        { name: 'teamMain', serverName: 'team_main', field: 'team_main', label: 'Main Team', headerName: 'Main Team', options: teamMainOptions, defaultValue: 'N', noDefOption: true, flex: 1},
        { name: 'email', serverName: 'email', field: 'email', headerName: 'Email', required: true, flex: 1, hide: true},
        { name: 'cphone', serverName: 'cphone', field: 'cphone', headerName: 'Cell #', required: true, flex: 1},
        { name: 'hphone', serverName: 'hphone', field: 'hphone', headerName: 'Home #', required: false, flex: 1, hide: true},
        { name: 'area_name', serverName: 'area_name', field: 'area_name', headerName: 'Branch', required: false, flex: 1},
        { name: 'isActive', serverName: 'isactive', field: 'isactive', headerName: 'Activated', type: 'checkbox', required: false, valueFormatter: util.yesNoFormatter, flex: 1},
        { name: 'empDate', serverName: 'empdate', field: 'empdate', headerName: 'Work Start', type: 'date', mask: util.MASK_DATE, required: false, valueFormatter: util.dateFormatter, flex: 1},
        { name: 'workEnd', serverName: 'work_end', field: 'work_end', headerName: 'Work End', type: 'date', mask: util.MASK_DATE, required: false, valueFormatter: util.dateFormatter, flex: 1, hide: true},
        { name: 'address', serverName: 'address', field: 'address', headerName: 'Address', required: false, flex: 1, hide: true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    _f('areaId').options = api.useAreaOptions(true);
    _f('auth').options = api.usePermissionOptions();
    _f('jobTitle').options = api.useJobTitleOptions();
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('loginId')}</div>
          <div className={'flex-1 mr-2'}>{_r('areaId')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('userName')}</div>
          <div className={'flex-1 mr-2'}>{_r('jobTitle')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('userName2')}</div>
          <div className={'flex-1 mr-2'}>{_r('auth')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('password')}</div>
          <div className={'flex-1 mr-2'}>{_r('cphone')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('email')}</div>
          <div className={'flex-1 mr-2'}>{_r('hphone')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('teamMain')}</div>
          <div className={'flex-1 mr-2'}>{_r('empDate')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('address')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-2'}>{_r('isActive')}</div>
          <div className={'flex-1 mr-2'}>{_r('workEnd')}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => values;
}

export default StaffListEditFormManager;
