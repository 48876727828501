/**
 * @flow
 */
import React from 'react';
import {Card, CardBody} from 'reactstrap';

interface SearchPaneProps {
  title?: string;
  children?: React$Node;
}

const SearchPane = (props: SearchPaneProps) => {
  return (
    <Card className={'search-pane'}>
      <CardBody className={'p-16'}>
        <div className={'card__title mb-12 bold-text'}>{props.title ?? 'SEARCH OPTIONS'}</div>
        {props.children}
      </CardBody>
    </Card>
  );
};

export default SearchPane;
