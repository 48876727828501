/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import MarkFormManager from './MarkFormManager';
import {apiBL, util} from '../../services/service';

const FN = {
  SAID: 'said',
  KG: 'kg',
  LB: 'lb',
  CBM: 'cbm',
  CFT: 'cft',
  SHOW_AS: 'showAs',
  MARK: 'mark',
  DESC_OPT: 'descOption',
  DESCRIPTION: 'desc',
  REMARK: 'remark',
  PO_NO: 'poNo',
  ITEM_NO: 'itemNo',
  DESC_OF_GOODS: 'descOfGoods',
};

const LB = {
  SAID: 'Said',
  KG: '',
  LB: '',
  CBM: '',
  CFT: '',
  SHOW_AS: 'Show as',
  MARK: 'Mark',
  DESC_OPT: 'descOption',
  DESCRIPTION: 'Description',
  REMARK: 'Remark',
  PO_NO: 'P/O No.',
  ITEM_NO: 'Item No.',
};

class MarkOEMFormManager extends MarkFormManager {
  static FN = FN;
  static SN = FN;
  static LB = LB;
  blForm;
  constructor() {
    super({
      prefix: 'mark-OEM',
      fields: [
        {name: FN.SAID, serverName: FN.SAID, label: '', type: 'textarea'},
        {name: FN.KG, serverName: FN.KG, label: ''},
        {name: FN.LB, serverName: FN.LB, label: ''},
        {name: FN.CBM, serverName: FN.CBM, label: ''},
        {name: FN.CFT, serverName: FN.CFT, label: ''},
        {name: FN.SHOW_AS, serverName: FN.SHOW_AS, label: '', options: showAsOptions, noDefOption: true},
        {name: FN.MARK, serverName: FN.MARK, label: '', type: 'textarea'},
        {name: FN.DESC_OPT, serverName: FN.DESC_OPT, label: '', options: saidOptions, noDefOption: true, smallMargin: true},
        {name: FN.DESCRIPTION, serverName: FN.DESCRIPTION, label: '', type: 'textarea'},
        {name: FN.REMARK, serverName: FN.REMARK, label: '', type: 'textarea'},
        {name: FN.PO_NO, serverName: FN.PO_NO, label: '', type: 'textarea'},
        {name: FN.ITEM_NO, serverName: FN.ITEM_NO, label: '', type: 'textarea'},
        {name: FN.DESC_OF_GOODS, serverName: FN.DESC_OF_GOODS, label: '', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    //const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {renderField: _r, _l} = this;
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'pt-8'}><b>Show Weight on B/L as</b></div>
          <div className={'w-96 ml-8'}>{_r(FN.SHOW_AS)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Said')}
            {_r(FN.SAID)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Description of Goods')}
            {_r(FN.DESC_OF_GOODS)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('Mark')}
            {_r(FN.MARK)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Description')}
            {_r(FN.DESC_OPT)}
            {_r(FN.DESCRIPTION)}
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-column flex-2 mr-8'}>
            {_l('Remark')}
            {_r(FN.REMARK)}
          </div>
          <div className={'flex-column flex-1 mr-8'}>
            {_l('P/O No.')}
            {_r(FN.PO_NO)}
          </div>
          <div className={'flex-column flex-1'}>
            {_l('Item No.')}
            {_r(FN.ITEM_NO)}
          </div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values; // TODO
  };
}

const showAsOptions = [
  {value: 'KG', label: 'KG'},
  {value: 'LB', label: 'LB'},
];

const saidOptions = [
  {value: 'SAID TO CONTAIN', label: 'SAID TO CONTAIN'},
  {value: `SHIPPER'S LOAD AND COUNT`, label: `SHIPPER'S LOAD AND COUNT`},
  {value: ' ', label: 'BLANK'},
];

const form = new MarkOEMFormManager();

export function useMarkOEMModal(onSave, blForm) {
  form.blForm = blForm;
  const modal = useSimpleEditModal({
    title: 'OEM Mark & Desc.',
    width: 1024,
    form,
    onSave,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      setTimeout(() => { // NOTE: 이미 modal.isOpen 훅이 작동을 했으므로 다음 프레임에서 처리를 해주어야 폼에 값이 제대로 설정됨 (무슨 말이지???)
        // if (!form.getValue(FN.DESCRIPTION)) {
        //   form.setValue(FN.DESCRIPTION, blForm.getValue('commodity'));
        // }
        // if (!form.getValue(FN.MARK)) {
        //   // mark 가 설정되어 있지 않은 경우 컨테이너 정보를 쿼리하여 설정해줌
        //   apiBL.getContainers(blForm.getValue('id')).then(({data}) => {
        //     if (data?.length > 0) {
        //       const info = [];
        //       for (const {container_no, seal_no} of data) {
        //         if(container_no || seal_no) {
        //           info.push(`${container_no}/${seal_no}`);
        //         }
        //       }
        //       form.setValue(FN.MARK, info.join('\n'));
        //     }
        //   });
        // }
        let info = '';
        let tpSize = '';
        const packages =  blForm.getValue('package') ? `${blForm.getValue('package')} ${blForm.getValue('packageType')}` : '';
        apiBL.getContainers(blForm.getValue('id')).then(({data}) => {
          if (data?.length > 0) {
            for (const {container_no, seal_no, tp_size} of data) {
              if(container_no || seal_no) {
                info += `${container_no} / ${seal_no}\n`;
                tpSize += tp_size ? `${tp_size} x 1\n` : '';
              }
            }
          }
          const said = packages ? `${packages}\n${tpSize}` : tpSize;
          if (!form.getValue(FN.DESCRIPTION)) {
            form.setValue(FN.DESCRIPTION, blForm.getValue('commodity'));
          }
          if (!form.getValue(FN.MARK)) {
            form.setValue(FN.MARK, info);
          }
          if (!form.getValue(FN.SAID)) {
            // form.setValue(FN.SAID, said);
          }
        });
      }, 0);
    }
  }, [modal.isOpen]);
  return modal;
}

export default MarkOEMFormManager;
