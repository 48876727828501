/**
 * @flow
 */
import React from 'react';
import {api, apiBL, util} from "../../services/service";
import {useSimpleGridModal} from "../../components/SimpleModal";

export default function useBLListModal() {
  const [id, setId] = React.useState();
  const [selectedblIds, setSelectedblIds] = React.useState([]);

  async function onQuery() {
    const res = await api.workOrderBLListData(id);
    modal.setRows(res.data);
  }

  async function onDelete(blIds) {
    if (blIds.length <= 0) {
      return util.showWarning('Please select data!');
    }
    util.showConfirm('Are you sure to delete?', async () => {
      const res = await apiBL.delBLBulk(parseInt(id), blIds);
      if(res) {
        util.showSuccess('B/L Bulk has been deleted successfully!');
        await onQuery();
        setSelectedblIds([]);
      }
    });
  }

  const onSelectionChanged = (e) => {
    const rows = e.api.getSelectedRows();
    setSelectedblIds(rows?.map(row => row.bl_id));
  };

  function getButtons() {
    return [
      {label: 'Delete', color: 'danger', onClick: () => onDelete(selectedblIds)},
    ];
  }

  function getTitle() {
    return 'B/L List';
  }

  const modal = useSimpleGridModal({
    title: getTitle(),
    centered: true,
    width: 920,
    height: 520,
    buttons: getButtons(),
    gridProps: {
      columns: [
        {field: 'bl_id', hide: true},
        {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
        {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: (param) => util.blHouseLinkFormatter(param, true), width: 90, minWidth: 90},
        {field: 'customer', headerName: 'Customer', flex: 1, minWidth: 85},
        {field: 'consignee', headerName: 'Consignee', flex: 1, minWidth: 90},
        {field: 'carrier_type', headerName: 'Carrier', width: 70, minWidth: 70},
        {field: 'package', headerName: 'Packages', width: 80, minWidth: 70},
        {field: 'weight', headerName: 'Weight', valueFormatter: util.formatWeight, width: 70, minWidth: 70},
        {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, width: 70, minWidth: 70},
        {field: 'total_price', headerName: 'Total', width: 80, minWidth: 80, valueFormatter: util.currencyFormatter},
        {field: 'paid_by', headerName: 'Paid', width: 80, minWidth: 80},
      ],
      actions: ['delete'],
      actionWidth: 70,
      onAction: (action, data) => {
        if (action === 'delete') {
          onDelete([data.bl_id]).catch();
        }
      },
      agGridProps: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        onSelectionChanged: onSelectionChanged,
        suppressMenuHide: true,
      },
      rows: [],
      height: 450,
      className: 'mb-20',
    },
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      onQuery().catch();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (id) => {
      setId(id);
      modal.open();
    },
  };
}
