/**
 * @flow
 */
import React from 'react';
import {Link} from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import {useSelector} from 'react-redux';

const Topbar = (props) => {
  const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;
  const topbar = useSelector(state => state['topbar']);
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link className="topbar__logo" to="/" />
          <div className={'flex middle ml-48'}>
            {topbar?.category?.label && <h5>{topbar.category?.label}</h5>}
            {topbar?.menu?.label && <h5 className={'mx-2'}>/</h5>}
            {topbar?.menu?.label && <h5>{topbar.menu.label}</h5>}
          </div>
        </div>
        <div className="topbar__right">
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
