/**
 * @flow
 */
import React from 'react';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {useUser} from '../../../redux/reducers/userReducer';
import {useLocation} from 'react-router-dom';
import * as md5 from 'md5';
import * as path from 'path';
import {api, util} from '../../../services/service';

const menuListWithTS = [
  '/bl/list',
  '/bl/manifest',
  '/schedule/schedule',
  '/schedule/workorder',
];
const SidebarContent = () => {
  const user = useUser();
  const {pathname} = useLocation();
  React.useEffect(() => {
    if (user.id > 0) {
      const menus = user.menu.map(i => i[2]).flat();
      const currentMenu = menus.find(([label, path]) => pathname.startsWith(`/admin${path}`));
      // 로그인한 사용자가 접근할수 없는 페이지에 접근을 시도하면 강제로 로그아웃함!
      if (!currentMenu) {
        api.logout().then(() => util.nav('/admin/login'));
      }
    }
  }, [user.id]);
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {(user?.menu ?? []).map(([categoryTitle, icon, subMenus]) => {
          const found = subMenus.find(([, route]) => pathname.startsWith(`/admin${route}`));
          return (
            <SidebarCategory key={categoryTitle} title={categoryTitle} icon={icon} collapse={found !== undefined}>
              {subMenus.map(([title, route]) => {
                const foundIndex = menuListWithTS.findIndex(i => i === route);
                if (foundIndex >= 0) {
                  return (
                    <SidebarLink key={title} title={title} route={`/admin${route}/${md5(Date.now().toString())}`}/>
                  );
                } else {
                  return (
                    <SidebarLink key={title} title={title} route={`/admin${route}`}/>
                  );
                }
              })}
            </SidebarCategory>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarContent;
