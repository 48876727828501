/**
 * @flow
 */
import API from './API';
import APIMoving from "./APIMoving";
import APIForwarding from "./APIForwarding";
import APICourier from "./APICourier";

export const api = new API();
export const apiMoving = new APIMoving();
export const apiForwarding = new APIForwarding();
export const apiCourier = new APICourier();
