/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';

export const serviceTypeOptions = [
  {value: '', label: 'All'},
  {value: '해외이사', label: '해외이사'},
  {value: '차량운송', label: '차량운송'},
  {value: '국제택배', label: '국제택배'},
  {value: '배송대행', label: '배송대행'},
  {value: '상업화물', label: '상업화물'},
  {value: 'E-COMMERCE SOLUTION', label: 'E-COMMERCE SOLUTION'},
  {value: 'Contact Us', label: 'Contact Us'},
];

export const responseStatusOptions = [
  {value: '', label: 'All'},
  {value: 'New', label: 'New'},
  {value: 'Called', label: 'Called'},
  {value: 'Contracted', label: 'Contracted'},
  {value: 'Thinking', label: 'Thinking'},
  {value: 'NORESPONSE', label: 'No Response'},
];

class DashboardRequestSearchFormManager extends FormManager {
  onSearch;
  constructor() {
    super({
      prefix: 'dashboard-request-search',
      fields: [
        {name: 'type', serverName: 'type', label: 'Type', options: serviceTypeOptions, noDefOption: true},
        {name: 'status', serverName: 'status', label: 'Status', options: responseStatusOptions, noDefOption: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      }
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const type = _v('type');
    const status = _v('status');

    React.useEffect(() => {
      if(type) {
        _c('type', type);
      }
      this.onSearch();
    }, [type]);
    React.useEffect(() => {
      if(status) {
        _c('status', status);
      }
      this.onSearch();
    }, [status]);

    return (
      <>
        {_r('type')}
        {_r('status')}
      </>
    );
  }
}

export default DashboardRequestSearchFormManager;
