/**
 * @flow
 */
import * as React from 'react';
import {api, util} from "../../../../services/service";
import {LoadingContainer, TabButton} from "../../CommonStyle";
import ForwardingMasterBLHbl from "./ForwardingMasterBLHbl";
import ForwardingMasterBLFormManager from "../../../../forms/newCoship/forwarding/master/ForwardingMasterBLFormManager";
import {PrimaryButton} from "../../../../components/buttons";
import ForwardingMasterBLMblFormManager from "../../../../forms/newCoship/forwarding/master/ForwardingMasterBLMblFormManager";
import useForwardingMasterBLAPModal from "../../../../modals/newCoship/forwarding/useForwardingMasterBLAPModal";
import {getBLData} from "../../../../forms/field-defs/newCoship";
import {apiForwarding} from "../../../../services/newCoship/service";
import {setFilingNo, setHouseAPTotal} from "../../../../redux/reducers/newCoshipReducer";
import ReactLoading from "react-loading";
import {useSelector} from "react-redux";
import {useContainerDetailPreviewModal} from "../../../../print-layouts/ContainerDetailPrint";
import {useUser} from "../../../../redux/reducers/userReducer";
import ForwardingMasterBLContainer from "./ForwardingMasterBLContainer";
import {useTopbar} from "../../../../redux/reducers/topbarReducer";
import {useContainerLoadPlanPreviewModal} from "../../../../print-layouts/ContainerLoadPlanPrint";

const masterBLForm = new ForwardingMasterBLFormManager();
const mblForm = new ForwardingMasterBLMblFormManager();

const TABS = [
  {index: 1, path: '/admin/forwarding/master/mbl'},
  {index: 2, path: '/admin/forwarding/master/hbl'},
  {index: 3, path: '/admin/forwarding/master/container'},
];

const ForwardingMasterBL = () => {
  useTopbar({label: 'Forwarding'}, {label: 'Master BL'});
  const qs = util.getQS();
  const {id} = qs;
  const pathname = window.location.pathname;
  const user = useUser();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [clickedIndex, setClickedIndex] = React.useState<number>(1);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isShowHblList, setIsShowHblList] = React.useState<boolean>(false);
  const [isShowContainerList, setIsShowContainerList] = React.useState<boolean>(false);
  const [hblList, setHblList] = React.useState([]);
  const [containerList, setContainerList] = React.useState([]);
  const [containerId, setContainerId] = React.useState(0);
  const {filingNo, carrierType} = useSelector(state => state['newCoship']);
  const style1 = {backgroundColor: clickedIndex === TABS[0].index ? '#b4c7e7' : 'white'};
  const style2 = {backgroundColor: clickedIndex === TABS[1].index ? '#b4c7e7' : 'white'};
  const style3 = {backgroundColor: clickedIndex === TABS[2].index ? '#b4c7e7' : 'white'};

  const onClickTab = (tabIndex) => {
    setClickedIndex(tabIndex);
    util.nav(id ? `${TABS[tabIndex-1].path}?id=${id}` : TABS[tabIndex-1].path);
  };

  const {...apModal} = useForwardingMasterBLAPModal(async (mode, data) => {
    if(mode === 'print') {
      await onAPPrint(data);
    } else {
      await onSaveAP(data);
    }
  });

  const onSearch = async () => {
    if (id) {
      apiForwarding.getBLData({id: util.toInt(id)}).then((res) => {
        if (res) {
          const blData = {id, ...res.data.jcommon, ...res.data.jcustomer, ...res.data.jshipment, ...res.data.jroute, ...res.data.jvessel, ...res.data.containers?.[0]};
          masterBLForm.setValues(blData);
          mblForm.setValues(blData);
          setHblList(res.data.houses);
          setIsEdit(true);
          setContainerId(res.data.containers?.[0]?.container_id);
          setContainerList(res.data.containers);
          setFilingNo(util.dispatch, res.data.filing_no);
          setHouseAPTotal(util.dispatch, res.data.account_tab?.ap_amount);
        } else {
          setIsEdit(false);
        }
      });
    } else {
      masterBLForm.clearValues();
      mblForm.clearValues();
      setHblList([]);
      setFilingNo(util.dispatch, '');
      setHouseAPTotal(util.dispatch, '');
      // default setting
      masterBLForm.setValue('branchId', user.branch_id);
    }
  };

  const onSave = async () => {
    const masterBLFormValues = masterBLForm.getValues();
    const mblFormValues = mblForm.getValues();
    const values = {...masterBLFormValues, ...mblFormValues, filing_no: filingNo, id: id ? util.toInt(id) : undefined};
    const payload = getBLData(true, values);
    setShowLoading(true);
    await apiForwarding.setForwardBL(payload).then(res => {
      if (res) {
        util.nav(`${pathname}?id=${res.data.id}`);
        util.showSuccess('Master BL has been saved successfully!');
        setShowLoading(false);
      }
    });
  };

  const onSaveAP = (data) => {
    console.log('onSaveAP: ', data);
  };

  const onNewHouse = () => {
    util.openTab(`/admin/forwarding/house/customer?mblId=${id}`);
  };

  const onCLPPrint = () => {
    if (!containerId) {
      return util.showWarning('There is no container');
    }
    api.craftContainerItemList({id: containerId}).then((res) => {
      if (res.data) {
        containerLoadPlanPrintModal.open(res.data[0], 'print');
      }
    });
  };

  const onSDPrint = (data, mode) => {
    if (!containerId) {
      return util.showWarning('There is no container');
    }
    api.craftContainerItemList({id: containerId}).then((res) => {
      if (res.data) {
        containerDetailPrintModal.open(res.data[0], mode);
      }
    });
  };

  const onAPPrint = () => {
    console.log('onAPPrint');
  };

  const containerDetailPrintModal = useContainerDetailPreviewModal(onSDPrint);
  const containerLoadPlanPrintModal = useContainerLoadPlanPreviewModal();

  const renderButton = () => {
    return (
      <div className={'flex'} style={{justifyContent: 'center', marginTop: 30}}>
        <div className={'mr-2'}><PrimaryButton label={'NEW HOUSE'} disabled={!isEdit} onClick={onNewHouse}/></div>
        <div className={'mr-2'}><PrimaryButton label={'CLP PRINT'} disabled={!isEdit} onClick={onCLPPrint}/></div>
        <div className={'mr-2'}><PrimaryButton label={'SD PRINT'} disabled={!isEdit} onClick={onSDPrint}/></div>
        {clickedIndex === 1 && <div><PrimaryButton label={'SAVE'} onClick={onSave} noMargin/></div>}
      </div>
    );
  };

  React.useEffect(() => {
    onSearch().catch();
  }, [id]);

  React.useEffect(() => {
    if (pathname === TABS[1].path) {
      setClickedIndex(TABS[1].index);
      mblForm.isShowForm = false;
      setIsShowHblList(true);
      setIsShowContainerList(false);
    } else if (pathname === TABS[2].path) {
      setClickedIndex(TABS[2].index);
      mblForm.isShowForm = false;
      setIsShowHblList(false);
      setIsShowContainerList(true);
    } else {
      setClickedIndex(TABS[0].index);
      mblForm.isShowForm = true;
      setIsShowHblList(false);
      setIsShowContainerList(false);
    }
    util.nav(id ? `${pathname}?id=${id}` : pathname);
  }, [id, pathname]);

  return (
    <>
      <div style={{marginLeft: -20}}>
        {masterBLForm.renderForm()}
      </div>
      <hr/>
      <div style={{marginTop: 20}}>
        {showLoading && <LoadingContainer><ReactLoading color={'grey'} type={'spin'} height={100} width={100}/></LoadingContainer>}
        <div className={'flex'} style={{marginLeft: -2}}>
          <TabButton style={style1} onClick={() => onClickTab(TABS[0].index)}><div>MBL INFO</div></TabButton>
          {isEdit ?
            <TabButton style={style2} onClick={() => onClickTab(TABS[1].index)}><div>HBL LIST</div></TabButton> :
            <TabButton style={{cursor: 'not-allowed'}}><div>HBL LIST</div></TabButton>
          }
          {isEdit ?
            carrierType !== 'A' && <TabButton style={style3} onClick={() => onClickTab(TABS[2].index)}><div>CONTAINER</div></TabButton> :
            carrierType !== 'A' && <TabButton style={{cursor: 'not-allowed'}}><div>CONTAINER</div></TabButton>
          }
        </div>
        <div style={{backgroundColor: 'white', minHeight: 600, border: '1px solid'}}>
          {mblForm.renderForm()}
          <ForwardingMasterBLHbl isShowList={isShowHblList} hblList={hblList}/>
          <ForwardingMasterBLContainer isShowList={isShowContainerList} containerList={containerList}/>
        </div>
        {renderButton()}
        {apModal.render()}
        {containerDetailPrintModal.render()}
        {containerLoadPlanPrintModal.render()}
      </div>
    </>
  );
};

export default ForwardingMasterBL;
