/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {portTypeOptions} from '../helper';
import {util} from '../../services/service';

class PortFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'port-form',
      fields: [
        {name: 'id', serverName: 'id', label: 'ID'},
        {name: 'portName', serverName: 'portName', label: 'Name', required: true},
        {name: 'fullName', serverName: 'fullName', label: 'Full Name', required: true},
        {name: 'portType', serverName: 'portType', label: 'Type', required: true, options: portTypeOptions},
        {name: 'country', serverName: 'country', label: 'Country'}
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    // const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    // const [formik, _, fields, errors] = this.renderParams;
    const {renderField: _r} = this;
    return (
      <div className={'w-full mr-24'}>
        {_r('portName')}
        {_r('fullName')}
        {_r('portType')}
        {_r('country')}
      </div>
    );
  };
  onValidate = (values) => {
    const {portName, portType} = values;
    if (!portName) {
      util.showWarning('Please enter the port name');
      return;
    }
    if (!portType) {
      util.showWarning('Please select the port type.');
      return;
    }
    return values;
  };
}

export default PortFormManager;
