/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {currencyOptions, paidOptions} from '../../../forms/helper';
import {api} from '../../../services/service';
import {AirVesselSearchInput, CustomerSearchInput, PartnerSearchInput} from '../../../components/SearchInput';

export const SK_CRAFT_ID = 'craftId';
export const SK_CRAFT_NAME = 'craftName';
export const SK_POL_POD = 'poName';
export const SK_BRANCH = 'branchId';

class AirVesselSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor() {
    super({
      prefix: 'craft-search',
      fields: [
        {name: SK_CRAFT_ID, label: 'craftId', smallMargin: true, smallLabel: true},
        {name: SK_CRAFT_NAME, label: 'Air/Vessel', smallMargin: true, smallLabel: true},
        {name: SK_BRANCH, label: 'Branch', options: [], noDefOption: true, smallMargin: true, smallLabel: true},
        {name: SK_POL_POD, label: 'POL/POD', smallLabel: true},
      ],
      formProps: {
        //horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    _f(SK_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SK_POL_POD).onEnter = () => this.onSearch();
    _f(SK_CRAFT_NAME).onChange = () => _c(SK_CRAFT_ID, ''); // 아이디를 검색하는 경우 디스플레이 필드가 변경되면 아이디를 삭제함
    const valuesToWatch = this.getValues([ // 다음 필드는 변경이 발생하면 검색을 수행 (사이드 이펙트 훅 이용하여 업데이트된 값을 얻어 옴)
      SK_CRAFT_ID, SK_BRANCH,
    ]);
    React.useEffect(() => {valuesToWatch[SK_CRAFT_ID] !== undefined && this.onSearch()}, [valuesToWatch[SK_CRAFT_ID]]);
    React.useEffect(() => {valuesToWatch[SK_BRANCH] !== undefined && this.onSearch()}, [valuesToWatch[SK_BRANCH]]);
    React.useEffect(() => {this.setFocus(SK_CRAFT_NAME)}, []); // 시작 시 HBL 에 포커스를 줌
    return (
      <>
        <AirVesselSearchInput
          displayField={_n(SK_CRAFT_NAME)}
          idField={_n(SK_CRAFT_ID)}
          vertical
          forSmallLabel // 작은 크기의 마진을 사용하는 세로 폼의 경우 검색 아이콘의 위치를 적당하게 맞추어줌 (forSmallMargin 이라는 이름이 맞음!)
          {...{formik, fields, errors}}
        />
        {_r(SK_BRANCH)}
        {_r(SK_POL_POD)}
      </>
    );
  };
}

export default AirVesselSearchFormManager;
