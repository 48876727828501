/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import coshipLogo from '../media/coship_logo.png';

class WorkAssignedPrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (!printData) {
      return null;
    }
    const {workAssignedGridData, selectedDate} = printData;
    return (
      <div style={{color: 'black', fontSize: 16, pageBreakAfter: 'always', marginTop: '1cm'}}>
        {<style type="text/css">
          {"@media print{@page {size: landscape}}"}
        </style>}
        <div className={'flex between'} style={{marginBottom: '10px'}}>
          <div className={'flex'} style={{fontSize: 24, fontWeight: 'bold'}}>
            <div className={'flex-2'}><img src={coshipLogo} /></div>
            <div className={'flex-5 ml-12 mt-40'}>CANADA ORIENT SHIPPING</div>
          </div>
          <div style={{fontSize: 24, fontWeight: 'bold', marginTop: '40px'}}>ASSIGN VEHICLE ({selectedDate})</div>
        </div>
        <table className={'print-table mt-20'} style={{width: '100%'}}>
          <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'} width={'3%'}>S.Type</td>
              <td className={'no-border-lr invoice_label'} width={'7%'}>Customer</td>
              <td className={'no-border-lr invoice_label'} width={'27%'}>Address</td>
              <td className={'no-border-lr right invoice_label'} width={'3%'}>PKG</td>
              <td className={'no-border-lr invoice_label'} width={'17%'}>Phone</td>
              <td className={'no-border-lr invoice_label'} width={'40%'}>Remark</td>
              <td className={'no-border-lr invoice_label'} width={'3%'}>CBM/Tape</td>
            </tr>
            {this.renderInvoiceItems(workAssignedGridData)}
          </tbody>
        </table>
      </div>
    );
  }
  renderInvoiceItems(items) {
    return items?.map((item, index) => {
      return (
        <tr key={index}>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px'}}>{item['kind']}</td>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px'}}>{item['customer_name']}</td>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px'}}>{item['address']}</td>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px', textAlign: 'right'}}>{item['pack_count']}</td>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px'}}>{item['phone']}</td>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px', whiteSpace: 'pre-line'}}>{item['remark']}</td>
          <td className={'no-border-t no-border-lr'} style={{paddingTop: '10px'}}>{item['cbm_tape']}</td>
        </tr>
      );
    });
  }
}

export function useWorkAssignedPrintModal() {
  const [printData, setPrintData] = React.useState();
  const workAssignedVehicleGirdRef = React.useRef();
  const selectedDateRef = React.useRef();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: `Work Assigned Vehicle - ${selectedDateRef.current}`,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <WorkAssignedPrint ref={printRef} printData={printData} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        workAssignedGridData: workAssignedVehicleGirdRef.current,
        selectedDate: selectedDateRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 0);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (workAssignedVehicleGird, selectedDate) => {
      workAssignedVehicleGirdRef.current = workAssignedVehicleGird;
      selectedDateRef.current = selectedDate;
      modal.open();
    },
    print,
  };
}

export default WorkAssignedPrint;
