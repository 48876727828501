/**
 * @flow
 */
import React from 'react';
import useAccountProfitGridView from '../../grids/account/useAccountProfitGridView';
import SearchPane from '../../components/SearchPane';
import {PrimaryButton} from '../../components/buttons';
import AccountProfitSearchFormManager from '../../forms/account/AccountProfitSearchFormManager';
import {api, util} from '../../services/service';
import useProfitDetailModal from '../../modals/account/useProfitDetailModal';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import type {QueryListParams} from '../../services/API';

const searchForm = new AccountProfitSearchFormManager();

const AccountProfit = () => {
  useTopbar({label: 'Account'}, {label: 'Profit'});
  const {grid, onSearch, detailModal} = useAccountProfit();
  return (
    <div className={'flex'}>
      <SearchPane>
        {searchForm.renderForm()}
        <PrimaryButton label={'Search'} onClick={onSearch} noMargin className={'w-full'} />
      </SearchPane>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        {grid.render()}
      </div>
      {detailModal.render()}
    </div>
  );
};

function useAccountProfit() {
  const grid = useAccountProfitGridView((action, data) => {
    if (action === 'detail') {
      detailModal.open(data['filing_no']);
    }
  });
  const detailModal = useProfitDetailModal();
  const onSearch = () => {
    const searchValues = searchForm.getValues();
    const gridInfo = util.getGridInfo(grid.props.name);
    const listParam: QueryListParams = {
      ...api.getQueryListData(gridInfo),
      page: 1,
      ...searchValues
    };
    grid.query(listParam);
  };
  searchForm.onSearch = onSearch;
  return {
    grid,
    onSearch,
    detailModal
  };
}

export default AccountProfit;
