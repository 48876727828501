/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {portTypeOptions} from '../helper';
import {util} from '../../services/service';

class CommissionFormManager extends FormManager {
  constructor() {
    super({
      prefix: 'commission-form',
      // TODO:
      fields: [
        {name: 'customerId', serverName: 'customer_id', label: 'ID'},
        {name: 'air', serverName: 'air', label: 'Air'},
        {name: 'ocean', serverName: 'ocean', label: 'Ocean'},
        {name: 'ups', serverName: 'ups', label: 'UPS'},
        {name: 'capost', serverName: 'capost', label: 'Canada Post'},
        {name: 'desk', serverName: 'desk', label: 'Desk'},
        {name: 'remark', serverName: 'remark', label: 'Remark', type: 'textarea'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    return (
      <div className={'w-full mr-24'}>
        {_r('air')}
        {_r('ocean')}
        {_r('ups')}
        {_r('capost')}
        {_r('desk')}
        {_r('remark')}
      </div>
    );
  };
  onValidate = (values) => {
    // TODO
    return values;
  };
}

export default CommissionFormManager;
