/**
 * @flow
 */
import React from 'react';
import {useSimpleEditModal} from '../../components/SimpleModal';
import FormManager from "../../lib/FormManager";
import { Editor } from '@tinymce/tinymce-react';
import {apiSettings, util} from '../../services/service';
import toInt from "validator/es/lib/toInt";

const FN = {
  BL_ID: 'blId',
  PARTNER_ID: 'partnerId',
  MODE: 'mode',
  TEMPLATE: 'template',
  TITLE: 'title',
  FROM_EMAIL: 'pic_email',
  FROM_NAME: 'pic_name',
  TO_EMAIL: 'toEmail',
  MESSAGE: 'message',
  SELECTED_FILE: 'selectedFile',
};

const templateOptions = [];

class SendEmailFormManager extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'send-email',
      fields: [
        {name: FN.BL_ID, serverName: FN.BL_ID, label: '', hide: true},
        {name: FN.PARTNER_ID, serverName: FN.PARTNER_ID, label: '', hide: true},
        {name: FN.MODE, serverName: FN.MODE, label: '', hide: true},
        {name: FN.TEMPLATE, serverName: FN.TEMPLATE, label: 'Template', options: templateOptions, noDefOption: false},
        {name: FN.TITLE, serverName: FN.TITLE, label: 'Title', placeholder: 'Title'},
        {name: FN.FROM_EMAIL, serverName: FN.FROM_EMAIL, label: 'Sender', placeholder: 'E-mail'},
        {name: FN.FROM_NAME, serverName: FN.FROM_NAME, label: 'Sender Name', placeholder: 'Name'},
        {name: FN.TO_EMAIL, serverName: FN.TO_EMAIL, label: 'Receiver', placeholder: 'E-mail'},
        {name: FN.MESSAGE, serverName: FN.MESSAGE, label: ''},
        {name: FN.SELECTED_FILE, serverName: FN.SELECTED_FILE, label: ''},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const [message, setMessage] = React.useState();
    const templateId = _v(FN.TEMPLATE);
    const blId = _v(FN.BL_ID);
    const mode = _v(FN.MODE);

    const onFileChange = event => {
      _c(FN.SELECTED_FILE, event.target.files[0]);
    };

    const handleEditorChange = (message, editor) => {
      _c(FN.MESSAGE, message);
    }

    _f(FN.TEMPLATE).options = apiSettings.useEmailSubjectList();

    React.useEffect(() => {
      if(templateId) {
        apiSettings.getEmailTemplate({id: toInt(templateId), houses: blId}).then(({data}) => {
          _c(FN.TITLE, data?.[0]?.subject);
          _c(FN.FROM_EMAIL, data?.[0]?.pic_email);
          _c(FN.FROM_NAME, data?.[0]?.pic_name);
          _c(FN.MESSAGE, data?.[0]?.message);
          setMessage(data?.[0]?.message);
        });
      }
    }, [templateId]);

    return (
      <div className={'w-full'} style={{paddingBottom: '10px'}}>
        {mode === 'new' && (
          <div className={'flex'}>
            <div className={'flex-1'}>{_r(FN.TEMPLATE)}</div>
          </div>
        )}
        <div className={'flex'}>
          <div className={'flex-1'}>{_r(FN.TITLE)}</div>
          <div className={'flex-1'}>{_r(FN.TO_EMAIL)}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1'}>{_r(FN.FROM_EMAIL)}</div>
          <div className={'flex-1'}>{_r(FN.FROM_NAME)}</div>
        </div>
        <div style={{paddingBottom: '10px'}}>
          <Editor
            initialValue=''
            init={{
              width: 900,
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | code | help'
            }}
            onEditorChange={handleEditorChange}
            value={_v(FN.MESSAGE)}
          />
        </div>
        <div style={{borderTop: 'none'}}>
          <input type={'file'} name={'file'} onChange={onFileChange} />
        </div>
      </div>
    );
  };
  onValidate = (values) => {
    return values;
  };
}

const form = new SendEmailFormManager();

export function useSendEmailModal(onSave, id, blForm) {
  const modal = useSimpleEditModal({
    title: 'Customer e-mail send',
    width: 935,
    form,
    onSave,
  });
  React.useEffect(() => {
    if(modal.isOpen) {
      let blId = id, toEmail;
      if (blForm) {
        blId = blForm ? blForm.getValue('id') : id;
        // toEmail = blForm ? blForm.getValue('customerEmail') : '';
      }
      form.setValue(FN.BL_ID, util.toInt(blId));
      // form.setValue(FN.TO_EMAIL, toEmail);
    }
  }, [modal.isOpen]);
  return modal;
}

export default SendEmailFormManager;
