/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from "../media/coship_logo.png";

class InvoiceCMPrint extends React.Component {
  render() {
    const {printData, user} = this.props;
    if (!printData) {
      return null;
    }
    const {accountData, accountGridData, accountInvoiceAddItemData, accountCMDataDetailData} = printData;
    return (
      <div style={{color: 'black'}}>
        <div className={'page-break'}>
          {this.renderHeader(accountData, user)}
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'} width={'20%'}>TYPE</td>
              <td className={'no-border-lr invoice_label right'} width={'20%'}>PACKAGES</td>
              <td className={'no-border-lr invoice_label right'} width={'20%'}>AMOUNT</td>
              <td className={'no-border-lr invoice_label right'} width={'20%'}>RATE</td>
              <td className={'no-border-lr invoice_label right'} width={'20%'}>COMMISSION</td>
            </tr>
            {this.renderInvoiceItems(accountGridData)}
            {this.renderEmptyItems(accountGridData)}
            </tbody>
          </table>
          <hr />
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            {this.renderInvoiceItemsTotal('TOTAL PACKAGES', '', accountInvoiceAddItemData.totPackages)}
            {this.renderInvoiceItemsTotal('TOTAL AMOUNT', '', util.formatCurrency(accountInvoiceAddItemData.totAmount))}
            {this.renderInvoiceItemsTotal('TOTAL COMMISSION', '', util.formatCurrency(accountInvoiceAddItemData.totCommission))}
            {this.renderInvoiceItemsTotal('NET AMOUNT', 'CAD', util.formatCurrency(accountInvoiceAddItemData.totAmount - accountInvoiceAddItemData.totCommission))}
            </tbody>
          </table>
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            {this.renderInvoiceExtraInfo('MEMO', accountData.memo)}
            {this.renderInvoiceExtraInfo('BANK INFO', accountData.bankInfo)}
            </tbody>
          </table>
          <hr className={'line'} />
        </div>
        <div className={'page-break'} style={{marginTop: '2cm'}}>
          <table className={'print-table mt-20'} style={{width: '100%'}}>
            <tbody>
            <tr>
              <td className={'no-border-lr invoice_label'} width={'15%'}>DATE</td>
              <td className={'no-border-lr invoice_label'} width={'15%'}>B/L NO.</td>
              <td className={'no-border-lr invoice_label'} width={'20%'}>SHIPPER</td>
              <td className={'no-border-lr invoice_label'} width={'20%'}>CONSIGNEE</td>
              <td className={'no-border-lr invoice_label'} width={'10%'}>TYPE</td>
              <td className={'no-border-lr right invoice_label'} width={'10%'}>AMOUNT</td>
              <td className={'no-border-lr right invoice_label'} width={'10%'}>COMMISSION</td>
            </tr>
            {this.renderCustomerDetailItems(accountCMDataDetailData)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  renderHeader(accountData, user) {
    return (
      <>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all flex mb-1'}>
                  <div className={'flex-1'}><img src={coshipLogo} /></div>
                  <div className={'flex-5 ml-12 mt-24 invoice_label'}><h3>{user.print.company}</h3></div>
                </td>
              </tr>
              <tr>
                <td className={'no-border-all'}>
                  {user.print.address}<br/>
                  TEL: {user.print.phone} {'\u00a0'} FAX: {user.print.fax}<br/>
                  EMAIL: {user.print.email}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%', height: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all right invoice_label'}><h1>COMMISSION</h1></td>
              </tr>
              <tr>
                <td className={'no-border-all bottom_right'}>
                  <h3 className={'invoice_label'}>INVOICE No. : {accountData.settlementInvoiceNo}</h3>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={'flex'}>
          <div className={'flex-3'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'no-border-all w-100 invoice_label'}>BILL TO :</td>
                <td className={'no-border-all'} style={{whiteSpace: 'pre-line'}}>{accountData.billTo}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className={'flex-2'}>
            <table className={'print-table'} style={{width: '100%'}}>
              <tbody>
              <tr>
                <td className={'invoice_label'}>PERIOD</td>
                <td>{accountData.period}</td>
              </tr>
              <tr>
                <td className={'invoice_label'}>PRINT DATE</td>
                <td>{util.getCurrentDate()}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  renderInvoiceItems(items) {
    return items?.map((item, index) => {
      return (
        <tr key={index}>
          <td className={'no-border-all'} width={'20%'}>{item['Type']}</td>
          <td className={'no-border-all right'} width={'20%'}>{item['Packages']}</td>
          <td className={'no-border-all right'} width={'20%'}>{util.formatCurrency(item['Price'])}</td>
          <td className={'no-border-all right'} width={'20%'}>{item['Rate']}</td>
          <td className={'no-border-all right'} width={'20%'}>{util.formatCurrency(item['Commission'])}</td>
        </tr>
      );
    });
  }
  renderEmptyItems(items) {
    const empty = [];
    let length = 30 - items.length;
    for(let i=0; i<length; i++) {
      empty.push(
        <tr key={i}>
          <td colSpan={6} className={'no-border-all'}>&nbsp;</td>
        </tr>
      );
    }
    return empty;
  }
  renderInvoiceItemsTotal(label, value1, value2) {
    return (
      <tr>
        <td className={'no-border-all right invoice_label'}>{label}</td>
        <td className={'no-border-all right invoice_label'}>{value1}</td>
        <td className={'no-border-all right invoice_label'}>{value2}</td>
      </tr>
    );
  }
  renderInvoiceExtraInfo(label, value) {
    return (
      <tr>
        <td className={'center w-128 invoice_label'}>{label}</td>
        <td><div style={{whiteSpace: 'pre-line', minHeight: '150px'}}>{value}</div></td>
      </tr>
    );
  }
  renderCustomerDetailItems(items) {
    return items?.map((item) => {
      return (
        <tr>
          <td className={'no-border-lr'} width={'15%'}>{util.formatD(item.cdate)}</td>
          <td className={'no-border-lr'} width={'15%'}>{item.bl_no}</td>
          <td className={'no-border-lr'} width={'20%'}>{item.customer}</td>
          <td className={'no-border-lr'} width={'20%'}>{item.consignee}</td>
          <td className={'no-border-lr'} width={'10%'}>{item.ctype}</td>
          <td className={'no-border-lr right'} width={'10%'}>{util.formatCurrency(item.amount)}</td>
          <td className={'no-border-lr right'} width={'10%'}>{util.formatCurrency(item.commission)}</td>
        </tr>
      )
    });
  }
}

export function useInvoiceCMPreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const accountFormRef = React.useRef();
  const accountSearchFormRef = React.useRef();
  const accountInvoiceAddItemRef = React.useRef();
  const accountCMDataDetailRef = React.useRef();
  const printRef = React.useRef();
  const invoiceNoRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: invoiceNoRef.current,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    children: <InvoiceCMPrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        accountData: accountFormRef.current.getValues(),
        accountSearchData: accountSearchFormRef.current.getValues(),
        accountInvoiceAddItemData: accountInvoiceAddItemRef.current.getValues(),
        accountGridData: accountInvoiceAddItemRef.current.getGridData(),
        accountCMDataDetailData: accountCMDataDetailRef.current,
      };
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 0);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (accountForm, accountSearchForm, accountInvoiceAddItem, accountCMDataDetail) => {
      accountFormRef.current = accountForm;
      accountSearchFormRef.current = accountSearchForm;
      accountInvoiceAddItemRef.current = accountInvoiceAddItem;
      accountCMDataDetailRef.current = accountCMDataDetail;
      invoiceNoRef.current = accountForm.getValue('settlementInvoiceNo');
      modal.open();
    },
    print,
  };
}

export default InvoiceCMPrint;
