/**
 * @flow
 */
import React from 'react';
import {PrimaryButton} from "../../../../components/buttons";
import {util} from "../../../../services/service";
import useMovingSettingAccountTypeListGridView from "../../../../grids/newCoship/moving/setting/useMovingSettingAccountTypelListGridView";
import {useMovingSettingAccountTypeListModal} from "../../../../modals/newCoship/moving/useMovingSettingAccountTypeListModal";
import {apiMoving} from "../../../../services/newCoship/service";
import MovingSettingSearchFormManager from "../../../../forms/newCoship/moving/setting/MovingSettingSearchFormManager";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {useTopbar} from "../../../../redux/reducers/topbarReducer";

const searchForm = new MovingSettingSearchFormManager();

const MovingSettingAccountTypeList = () => {
  useTopbar({label: 'Moving'}, {label: 'Preset(AR/AP)'});
  const [qryText, setQueryText] = React.useState(undefined);

  const onSearch = () => {
    const values = searchForm.getValues();
    const payload = {
      kind: values.kind ? values.kind : undefined,
      search_word: qryText,
    };
    apiMoving.getARAPItemList(payload).then((res) => {
      if (res) {
        grid.setRows(res.data);
      }
    });
  };

  const {grid, accountTypeListModal} = useMovingSettingAccountType(onSearch);

  const onAdd = () => {
    accountTypeListModal.open({id: 0});
  };

  const onEnter = async () => {
    await onSearch();
  };

  React.useEffect(() => {
    searchForm.onSearch = onSearch;
  }, []);

  return (
    <div>
      <div style={{display: 'flex'}}>
        <PrimaryButton label={'ADD'} onClick={onAdd}/>
        {searchForm.renderForm()}
        <div className={'inbox__emails-control-search flex ml-8'}>
          <input
            id={`account-type-search`}
            placeholder={'Search...'}
            value={qryText ?? ''}
            onChange={({target: {value}}) => setQueryText(value)}
            onKeyDown={({target: {value}, key}) => {
              if (key === 'Enter') {
                onEnter(value).catch();
              }
            }}
            style={{width: 200}}
          />
          <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
            e.preventDefault();
            onEnter(document.querySelector('#account-type-search').value).catch();
          }}>
            <MagnifyIcon/>
          </a>
        </div>
      </div>
      <div style={{marginTop: -5}}>
        {grid.render()}
      </div>
      {accountTypeListModal.render()}
    </div>
  );
};

function useMovingSettingAccountType(onSearch) {
  const onAction = (action, data) => {
    if (action === 'edit') {
      accountTypeListModal.open(data);
    }
  };
  const grid = useMovingSettingAccountTypeListGridView(onAction);
  const accountTypeListModal = useMovingSettingAccountTypeListModal(onSearch);
  return {grid, accountTypeListModal};
}

export default MovingSettingAccountTypeList;
