/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import {useUser} from "../redux/reducers/userReducer";
import coshipLogo from '../media/coship_logo.png';

class DaySchedulePrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (!printData) {
      return null;
    }
    return <SchedulePrint printData={printData}/>;
  }
}

const SchedulePrint = ({printData}) => {
  if (!printData) {
    return null;
  }
  const {scheduleData, selectedDay} = printData;
  const render = () => {
    return (
      <div style={{color: 'black', fontSize: 16, pageBreakAfter: 'always', marginTop: '1cm'}}>
        {<style type="text/css">
          {"@media print{@page {size: landscape}}"}
        </style>}
        <div style={{fontSize: 25, fontWeight: 'bold'}}>{`${util.formatD(selectedDay)} 일별 스케쥴`}</div>
        <table className={'print-table mt-20'} style={{width: '100%', fontSize: 15, whiteSpace: 'pre-wrap'}}>
          <tbody>
          <tr style={{backgroundColor: '#dfdfdf', fontWeight: 'bold'}}>
            <td className={'center'} width={'4%'}>{'CUSTOMER'}</td>
            <td className={'center'} width={'24%'}>{'ADD / TEL'}</td>
            <td className={'center'} width={'5%'}>{'CITY'}</td>
            <td className={'center'} width={'3%'}>{'TIME'}</td>
            <td className={'center'} width={'2%'}>{'PKG'}</td>
            <td className={'center'} width={'5%'}>{'CBM'}</td>
            <td className={'center'} width={'5%'}>{'TAPE/\nW.TYPE'}</td>
            <td className={'center'} width={'40%'}>{'REMARK'}</td>
            <td className={'center'} width={'12%'}>&nbsp;</td>
          </tr>
          {scheduleData?.map((data) => {
            const customer = data.workplace ?? '';
            const addTel = `${data.address ?? ''}\n${data.phone ?? ''}`;
            const city = data.city ?? '';
            const time = data.work_time ?? '';
            const pkg = data.pack_count ?? '';
            const cbmOffer = data.cbm_offer ? `${data.cbm_offer}` : '';
            const tapeWorkType = `${data.tape ?? ''}/${data.work_type ?? ''}`;
            const remark = `${data.bl_no ?? ''} (${data.paid_cbm ?? ''})\n${data.remark ?? ''}`;
            return (
              <tr>
                <td className={'center'}>{customer}</td>
                <td className={'center'}>{addTel}</td>
                <td className={'center'}>{city}</td>
                <td className={'center'}>{time}</td>
                <td className={'center'}>{pkg}</td>
                <td className={'center'}>{cbmOffer}</td>
                <td className={'center'}>{tapeWorkType}</td>
                <td>{remark}</td>
                <td>&nbsp;</td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    )
  };

  return (
    render()
  );
}

export function useDaySchedulePreviewModal() {
  const user = useUser();
  const [printData, setPrintData] = React.useState();
  const [scheduleData, setScheduleData] = React.useState([]);
  const [selectedDay, setSelectedDay] = React.useState('');
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: `Day schedule (${util.formatD(selectedDay)})`,
  });
  const modal = useSimpleModal({
    title: '',
    width: 1024,
    height: 630,
    children: <DaySchedulePrint ref={printRef} printData={printData} user={user} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {scheduleData, selectedDay};
      setPrintData(newPrintData);
      setTimeout(() => {
        print();
      }, 500);
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data, day) => {
      setScheduleData(data);
      setSelectedDay(day);
      modal.open();
    },
    print,
  };
}

export default DaySchedulePrint;
