import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import SummarySearchFormManager from "../../../forms/dashboard/SummarySearchFormManager";

export const searchDateForm = new SummarySearchFormManager();

const SummarySearch = () => {
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            {searchDateForm.renderForm()}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SummarySearch;
