/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";

const FN = {
  WORK_TYPE: 'work_type',
  WORKPLACE: 'workplace',
  CITY: 'city',
  WORK_TIME: 'work_time',
  PHONE: 'phone',
  PACK_COUNT: 'pack_count',
  ADDRESS: 'address',
  REMARK: 'remark',
};

export const workTypeOptions = [
  {value: 'PU', label: 'Pickup'},
  {value: 'DL', label: 'Delivery'},
];

class DayScheduleAddFormManager extends FormManager {
  static FN = FN;
  constructor() {
    super({
      prefix: 'day-schedule-add-form',
      fields: [
        {name: FN.WORK_TYPE, serverName: FN.WORK_TYPE, label: 'W.Type', options: workTypeOptions, noDefOption: false, required: true},
        {name: FN.WORKPLACE, serverName: FN.WORKPLACE, label: 'Customer', required: true},
        {name: FN.CITY, serverName: FN.CITY, label: 'City', required: true},
        {name: FN.WORK_TIME, serverName: FN.WORK_TIME, label: 'Time'},
        {name: FN.PHONE, serverName: FN.PHONE, label: 'Phone'},
        {name: FN.PACK_COUNT, serverName: FN.PACK_COUNT, label: 'Package'},
        {name: FN.ADDRESS, serverName: FN.ADDRESS, label: 'Address'},
        {name: FN.REMARK, serverName: FN.REMARK, label: 'Remark'},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        useDeleteButton: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return (
      <>
      <div className={'flex w-full'}>
        <div className={'flex-1'} style={{marginRight: 5}}>{_r(FN.WORK_TYPE)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.WORKPLACE)}</div>
        <div className={'flex-1'}>{_r(FN.CITY)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.WORK_TIME)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.PHONE)}</div>
        <div className={'flex-1'}>{_r(FN.PACK_COUNT)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.ADDRESS)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.REMARK)}</div>
      </div>
      </>
    );

  };
  onValidate = (values) => values;
}

export default DayScheduleAddFormManager;
