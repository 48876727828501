/**
 * @flow
 */
import React from 'react';
import {useAssignVehicleModal} from '../../modals/useAssignVehicleModal';
import {useWorkAssignedGridView} from '../../grids/useWorkAssignedGridView';
import {PrimaryButton} from '../../components/buttons';

const WorkAssignList = () => {
  const {gridView, assignModal} = useWorkAssignList();
  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        {gridView.render()}
        <div style={{position: 'absolute', left: 0, top: -12}}>
          <PrimaryButton label={'ASSIGN VEHICLE'} onClick={() => assignModal.open()} />
        </div>
      </div>
      {assignModal.render()}
    </div>
  );
};

function useWorkAssignList() {
  const [queryInfo, setQueryInfo] = React.useState({});
  const assignModal = useAssignVehicleModal('', queryInfo);
  const gridView = useWorkAssignedGridView('', assignModal, (gridInfo) => setQueryInfo(gridInfo));
  React.useEffect(() => {
    if (assignModal.isOpen === false) {
      gridView.query();
    }
  }, [assignModal.isOpen]);
  return {gridView, assignModal};
}

export default WorkAssignList;
