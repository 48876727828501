/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import {util} from "../services/service";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ContainerDetailPrint extends React.Component {
  render() {
    const {printData} = this.props;
    if (!printData) {
      return null;
    }
    const {containerData} = printData;
    return (
      <div>
        {printData.containerMode === 'excel' && (
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-primary"
            table="table-to-xls"
            filename="COSHIP - SHIPPING MANAGEMENT SYSTEM"
            sheet="SHIPPER DETAILS"
            buttonText="Export Excel"/>
        )}
        {/*{<style type="text/css">*/}
        {/*  {"@media print{@page {size: landscape}}"}*/}
        {/*</style>}*/}
        <table id='table-to-xls' className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr>
            <td colSpan={11} className={'center no-border-all center'}>
              <div><h4>COSHIP - SHIPPING MANAGEMENT SYSTEM</h4></div>
              <div style={{textAlign: 'left'}}><h5>SHIPPER DETAILS [{util.getCurrentDate()}]</h5></div>
              <table className={'print-table'} style={{width: '100%'}}>
                <tbody>
                {this.renderHeader('VESSEL NAME', containerData.vessle, 'CNTR.NO.', containerData.cntrNo)}
                {this.renderHeader('MASTER B/L NO.', containerData.mblNo, 'SEAL NO.', containerData.sealNo)}
                {this.renderHeader('POD.', containerData.pod, 'WEIGHT', containerData.kg)}
                {this.renderHeader('POL', containerData.pol, 'O/FRT', containerData.oFrt)}
                {this.renderHeader(`ETD: ${util.formatD(containerData.etd)}`, `ETA: ${util.formatD(containerData.eta)}`, `CBM: ${containerData.cbm}`, `PKGS: ${containerData.packages}`)}
                </tbody>
              </table>
              <table className={'print-table'} style={{width: '100%'}}>
                <tbody>
                <tr>
                  <td className={'center'} rowSpan={2}><div className={'label'}>NO</div></td>
                  <td className={'center'}><div className={'label'}>SHPR</div></td>
                  <td className={'center'}><div className={'label'}>STATUS</div></td>
                  <td className={'center'}><div className={'label'}>INSURANCE</div></td>
                  <td className={'center'}><div className={'label'}>HBL</div></td>
                  <td className={'center'}><div className={'label'}>ENTDATE</div></td>
                  <td className={'center'}><div className={'label'}>TAPE</div></td>
                  <td className={'center'}><div className={'label'}>CBM</div></td>
                  <td className={'center'}><div className={'label'}>PKGS/KG</div></td>
                  <td className={'center'}><div className={'label'}>SVC</div></td>
                  <td className={'center'}><div className={'label'}>BALANCE</div></td>
                </tr>
                <tr>
                  <td className={'center'} colSpan={2}><div className={'label'}>ADDRESS</div></td>
                  <td className={'center'}><div className={'label'}>PHONE</div></td>
                  <td className={'center'}><div className={'label'}>EMAIL</div></td>
                  <td className={'center'} colSpan={6}><div className={'label'}>REMARK</div></td>
                </tr>
                {this.renderItems(containerData.items)}
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  renderHeader(label1, value1, label2, value2) {
    return (
      <tr>
        <td colSpan={3} width={'25%'} className={'no-border-b'}><div className={'label'}>{label1}</div></td>
        <td colSpan={3} width={'25%'} className={'no-border-b'}><div className={'value'}>{value1}</div></td>
        <td colSpan={2} width={'25%'} className={'no-border-b'}><div className={'label'}>{label2}</div></td>
        <td colSpan={3} width={'25%'} className={'no-border-b'}><div className={'value'}>{value2}</div></td>
      </tr>
    );
  }
  renderItems(items) {
    return items.map((item, index) => {
      return (
        <>
        <tr key={index}>
          <td className={'center'} rowSpan={2}><div className={'value'}>{item.seqNo}</div></td>
          <td><div className={'value'}>{item.localName}/{item.engName}</div></td>
          <td><div className={'value'}>{item.visaType}</div></td>
          <td><div className={'value'}>{item.insurance !== '' ? util.formatCurrency(util.toFloat(item.insurance)) : ''}</div></td>
          <td><div className={'value'}>{item.blNo}</div></td>
          <td><div className={'value'}>{item.entDate}</div></td>
          <td><div className={'value'}>{item.tape}</div></td>
          <td><div className={'value'}>{item.cbm}</div></td>
          <td><div className={'value'}>{item.packages}/{item.kg}</div></td>
          <td><div className={'value'}>{item.svc}</div></td>
          <td><div className={'value'}>{util.formatCurrency(item.balance)}</div></td>
        </tr>
        <tr>
          <td colSpan={2}><div className={'value'}>{item.address}</div></td>
          <td colSpan={1}><div className={'value'}>{item.phone}</div></td>
          <td colSpan={1}><div className={'value'}>{item.email}</div></td>
          <td colSpan={6}><div className={'value'}>{item.remark}</div></td>
        </tr>
        </>
      );
    });
  }
}

export function useContainerDetailPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const containerFormRef = React.useRef();
  const containerModeRef = React.useRef();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
    documentTitle: 'COSHIP Container detail',
  });
  const modal = useSimpleModal({
    title: '',
    width: 1280,
    children: <ContainerDetailPrint ref={printRef} printData={printData} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      const newPrintData = {
        containerData: containerFormRef.current,
        containerMode: containerModeRef.current,
      };
      setPrintData(newPrintData);
      if(containerModeRef.current === 'print') {
        setTimeout(() => {
          print();
        }, 100);
      }

    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (containerData, mode) => {
      containerFormRef.current = containerData;
      containerModeRef.current = mode;
      modal.open();
    },
    print,
  };
}

export default ContainerDetailPrint;
