/**
 * @flow
 */
import {util} from '../../../../services/service';
import {useSimpleGrid} from "../../../../components/SimpleGrid";
import React from "react";

export default function useMovingMasterBLHblListGridView(onActionCb) {
  return useSimpleGrid({
    columns: [
      // {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
      {field: 'seq_no', headerName: 'SEQ', width: 80, rowDrag: true},
      {field: 'customer', headerName: 'Customer', flex: 2},
      {field: 'kind', headerName: 'SVC Type', flex: 2},
      {field: 'bl_no', headerName: 'BL#', cellRendererFramework: blLinkFormatter, flex: 2},
      {field: 'cbm', headerName: 'CBM', flex: 1, valueFormatter: util.currencyFormatter},
      {field: 'packages', headerName: 'PKG', flex: 1},
      {field: 'weight_kg', headerName: 'Weight', flex: 1},
    ],
    height: 530,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: onActionCb,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      // onCellClicked(e) {
      //   if(e.colDef.headerName !== 'Actions') {
      //     onCellClicked(e);
      //   }
      // }
    },
  });
}

export function blLinkFormatter(param) {
  console.log(param);
  const onClick = (e) => {
    e.preventDefault();
    util.nav(`/admin/moving/house/customer?id=${param.data.bl_id}`);
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={onClick}>
      {param.value}
    </a>
  );
}
