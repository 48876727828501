/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {usePrintModal} from './usePrintModal';
import {apiBL} from '../../../services/service';
import AIHArrNoti from '../../../print-layouts/AIHArrNoti';

const FN = {
  BL_NO: 'blNo',
  CUSTOMER_REF: 'customerRef',
  TITLE: 'title',
  TITLE_ETC: 'titleEtc',
  SHOW_INVOICE: 'showInvoice',
  SHOW_FREIGHT_TERM: 'showFreightTerm',
};

class AIHANPrintForm extends FormManager {
  static FN = FN;
  static SN = FN;
  constructor() {
    super({
      prefix: 'an-aih',
      fields: [
        {name: FN.BL_NO, serverName: FN.BL_NO, label: 'HAWB No.', readonly: true},
        {name: FN.CUSTOMER_REF, serverName: FN.CUSTOMER_REF, label: 'Customer Ref. No.'},
        {name: FN.TITLE, serverName: FN.TITLE, label: 'Title', options: titleOptions, noDefOption: true, defaultValue: 'ARRIVAL NOTICE / FREIGHT INVOICE'},
        {name: FN.TITLE_ETC, serverName: FN.TITLE_ETC, label: ''},
        {name: FN.SHOW_INVOICE, serverName: FN.SHOW_INVOICE, label: '', type: 'checkbox'},
        {name: FN.SHOW_FREIGHT_TERM, serverName: FN.SHOW_FREIGHT_TERM, label: '', type: 'checkbox'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getValue: _v, setValue: _c} = this;
    const title = _v(FN.TITLE) ?? 'ARRIVAL NOTICE / FREIGHT INVOICE';
    React.useEffect(() => {
      if(!_v(FN.TITLE)) {
        _c(FN.TITLE, defaultANTitle);
      }
    }, [_v(FN.TITLE)]);
    return (
      <div className={'w-full mr-12 mb-24'}>
        {_r(FN.BL_NO)}
        {_r(FN.CUSTOMER_REF)}
        {_r(FN.TITLE)}
        {!title && (
          <div className={'flex'}>
            <div style={{width: 160}} />
            {_r(FN.TITLE_ETC)}
          </div>
        )}
        <div className={'flex mb-12'}>
          <div className={'ml-120'}>{_r(FN.SHOW_INVOICE)}</div>
          <div>Show Invoice</div>
        </div>
        <div className={'flex mb-12'}>
          <div className={'ml-120'}>{_r(FN.SHOW_FREIGHT_TERM)}</div>
          <div>Show Freight Term</div>
        </div>
      </div>
    );
  };
}

export const defaultANTitle = 'ARRIVAL NOTICE / FREIGHT INVOICE';

const titleOptions = [
  {value: defaultANTitle, label: defaultANTitle},
  {value: 'PRE-ARRIVAL NOTICE', label: 'PRE-ARRIVAL NOTICE'},
  {value: 'EXAM HOLD NOTICE', label: 'EXAM HOLD NOTICE'},
  {value: 'EXAM HOLD RELEASE NOTICE', label: 'EXAM HOLD RELEASE NOTICE'},
  {value: '', label: 'OTHER'}
];

const printForm = new AIHANPrintForm();

export function useAIHANPrintModal(blForm) {
  const onOpen = () => {
    const blNo = blForm.getValue('hawbNo');
    const customerRef = blForm.getValue('customerRefNo');
    printForm.setValue(FN.BL_NO, blNo);
    printForm.setValue(FN.CUSTOMER_REF, customerRef);
  };
  const onLoadPrintData = async (blData) => {
    const {data} = await apiBL.getBLPrintData(blData.blNo);
    const {data: anData} = await apiBL.getArrivalNoticeData(blData.id, true);
    const {containers = [], invoice = {}} = anData?.[0] ?? {};
    return {serverData: data?.[0] ?? {}, containers, invoice};
  };
  return usePrintModal(false, 'ARRIVAL NOTICE', 1024, blForm, printForm, AIHArrNoti, onOpen, onLoadPrintData, undefined, false);
}
