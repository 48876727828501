/**
 * @flow
 */
import FormManager from '../../lib/FormManager';
import React from "react";
import {api} from "../../services/service";
import {useUser} from "../../redux/reducers/userReducer";

class MasterInventoryModalFormManager extends FormManager {
  blNo;
  constructor() {
    super({
      prefix: 'account-courier-price-edit',
      fields: [
        {name: 'id', serverName: 'id', label: '', hide: true},
        {name: 'branchId', serverName: 'branch_id', label: 'Branch', noDefOption: true},
        {name: 'spaceName', serverName: 'space_name', label: 'Name'},
        {name: 'isReset', serverName: 'is_reset', label: 'Reset HB/L', type: 'checkbox'},
        {name: 'isActive', serverName: 'isactive', label: 'Activated', type: 'checkbox'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const user = useUser();
    const userBranchId = user.branch_id;
    const isResetField = _f('isReset');
    const branchOptions = api.useAreaOptions(true);
    const branchId = _v('branchId');
    _f('branchId').options = branchOptions;
    React.useEffect(() => {
      _c('isReset', false);
      _c('isActive', true);
      _c('branchId', userBranchId);
    }, []);
    React.useEffect(() => {
      isResetField.disabled = !this.blNo;
    }, [this.blNo]);
    React.useEffect(() => {
      const branch = branchOptions?.find(x => x.label === branchId)?.value;
      if (branch) {
        _c('branchId', parseInt(branch));
      }
    }, [branchOptions]);
    return (
      <div className={'w-full'}>
        <div className={'flex'}>
          <div className={'flex-1 mr-8 ml-8'}>{_r('branchId')}</div>
          <div className={'flex-1 mr-8 ml-8'}>{_r('spaceName')}</div>
        </div>
        <div className={'flex'}>
          <div className={'flex-1 mr-8 ml-8'}>{_r('isReset')}</div>
          <div className={'flex-1 mr-8 ml-8'}>{_r('isActive')}</div>
        </div>
      </div>
    );
  };
}

export default MasterInventoryModalFormManager;
