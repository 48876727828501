/**
 * @flow
 */
import React from "react";
import FormManager from "../../lib/FormManager";
import {api, apiAccount} from "../../services/service";
import {TradePartnerSearchInput} from "../../components/SearchInput";
import {currencyOptions, paymentOptions} from "../helper";
import {INVOICE} from "../field-defs/account";

export const SK_BRANCH = 'branchId';
export const SK_CARGO = 'cargoType';
export const SK_CURRENCY = 'currency';
export const SK_INV_TYPE = 'invType';
export const SK_BANK_INFO = 'bankInfo';
export const SK_PAY_TYPE = 'payType';
export const SK_INV_NO = 'invNo';
export const SK_AMOUNT = 'amount';
export const SK_BILL_TO = 'billTo';
export const SK_BILL_TO_ID = 'billToId';
export const SK_REMARK = 'remark';

const cargoTypeOptions = [
  {value: '', label: 'ALL'},
  {value: 'M', label: 'Moving'},
  {value: 'C', label: 'Courier'},
  {value: 'E', label: 'Ecommerce'},
  {value: 'F', label: 'Forwarding'},
];

const invoiceTypeOptions = [
  {value: '', label: 'ALL'},
  { value: INVOICE.TYPE_AR, label: INVOICE.TYPE_AR_LABEL},
  { value: INVOICE.TYPE_CM, label: INVOICE.TYPE_CM_LABEL},
  { value: INVOICE.TYPE_VD, label: INVOICE.TYPE_VD_LABEL},
];

export default class AccountListARPaidSearchFormManager extends FormManager {
  onSearch: () => void;
  constructor() {
    super({
      prefix: 'account-list-search',
      fields: [
        {name: SK_BRANCH, serverName: SK_BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: SK_CARGO, serverName: SK_CARGO, label: 'Service', options: cargoTypeOptions, noDefOption: true, smallMargin: true},
        {name: SK_CURRENCY, serverName: SK_CURRENCY, label: 'Currency', options: [{value: '', label: 'ALL'}, ...currencyOptions], noDefOption: true, smallMargin: true},
        {name: SK_INV_TYPE, serverName: SK_INV_TYPE, label: 'Type', options: invoiceTypeOptions, noDefOption: true, smallMargin: true},
        {name: SK_BANK_INFO, serverName: SK_BANK_INFO, label: 'Bank Info', noDefOption: true, smallMargin: true},
        {name: SK_PAY_TYPE, serverName: SK_PAY_TYPE, label: 'Pay Type', options: [{value: '', label: 'ALL'}, ...paymentOptions], noDefOption: true, smallMargin: true},
        {name: SK_INV_NO, serverName: SK_INV_NO, label: 'Invoice No.', smallMargin: true},
        {name: SK_AMOUNT, serverName: SK_AMOUNT, label: 'Amount', smallMargin: true},
        {name: SK_BILL_TO, serverName: SK_BILL_TO, label: 'Bill To', smallMargin: true},
        {name: SK_BILL_TO_ID, serverName: SK_BILL_TO_ID, label: '', smallMargin: true},
        {name: SK_REMARK, serverName: SK_REMARK, label: 'Remark', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    // const bankInfo = apiAccount.useBankList();
    const bankInfo = apiAccount.useBranchBankName();
    bankInfo.shift();
    const [ready, setReady] = React.useState(false);
    _f(SK_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SK_BANK_INFO).options = [{value: '', label: 'ALL', key: 0}, ...bankInfo];
    _f(SK_INV_NO).onEnter = _ => this.onSearch();
    _f(SK_AMOUNT).onEnter = _ => this.onSearch();
    _f(SK_REMARK).onEnter = _ => this.onSearch();

    // bill to search from
    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      vertical forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;

    const branchId = _v(SK_BRANCH);
    const kind = _v(SK_CARGO);
    const currency = _v(SK_CURRENCY);
    const type = _v(SK_INV_TYPE);
    const bank = _v(SK_BANK_INFO);
    const payType = _v(SK_PAY_TYPE);
    const billToId = _v(SK_BILL_TO_ID);

    React.useEffect(() => {
      ready && setTimeout(() => this.onSearch(), 0);
      setTimeout(() => setReady(true), 1000);
    }, [branchId]);
    React.useEffect(() => {
      // eslint-disable-next-line max-len
      if (ready && !(kind === undefined && currency === undefined && type === undefined && bank === undefined && payType === undefined && billToId === undefined)) {
        this.onSearch();
      }
    }, [kind, currency, type, bank, payType, billToId]);
    React.useEffect(() => {
      this.setFocus(SK_INV_NO);
    }, []);
    React.useEffect(() => {
      if(!type) {
        _c(SK_INV_TYPE, '');
      }
    }, []);

    return (
      <>
        {_r(SK_BRANCH)}
        {/*{_r(SK_CARGO)}*/}
        {_r(SK_CURRENCY)}
        {_r(SK_INV_TYPE)}
        {_r(SK_BANK_INFO)}
        {_r(SK_PAY_TYPE)}
        {_r(SK_INV_NO)}
        {_r(SK_AMOUNT)}
        {_tp(SK_BILL_TO)}
        {_r(SK_REMARK)}
      </>
    );
  };
}
