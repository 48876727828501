/**
 * @flow
 */
import React from 'react';
import FormManager from '../../lib/FormManager';
import {INVOICE} from "../field-defs/account";


const cardStyle = {
  marginLeft: -8, marginTop: -12, width: 'calc(100% + 8px)'
};

const INVOICE_TYPE_NAME = 'invoiceType';

const invoiceTypeOptions = [
  { value: INVOICE.TYPE_AR, label: INVOICE.TYPE_AR_LABEL},
  { value: INVOICE.TYPE_AP, label: INVOICE.TYPE_AP_LABEL},
  { value: INVOICE.TYPE_DC, label: INVOICE.TYPE_DC_LABEL},
];

class AccountListInvoiceTypeFormManager extends FormManager {
  constructor() {
    super({
      prefix: `account-list-account-type-form`,
      fields: [
        {name: INVOICE_TYPE_NAME, serverName: 'acc_type', label: 'Type', options: invoiceTypeOptions, noDefOption: true, smallMargin: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  getGridData() {
    let grid = this.grid;
    const rows = [];
    if (grid.apiRef && grid.apiRef.current) {
      grid.apiRef.current.forEachNode(node => rows.push(node.data));
    }
    return rows;
  }
  setGridData(gridData) {
    this.grid.setRows(gridData);
  }

  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };

  onRender = () => {
    const {_r, _f, _v, _n, _c, formik, fields, errors} = this.getRenderProps();

    return (
        <>
         <div className={'flex-1'}>{_r(INVOICE_TYPE_NAME)}</div>
        </>
    );
  };
}

export default AccountListInvoiceTypeFormManager;
