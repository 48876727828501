/**
 * @flow
 */
import React from 'react';
import WorkOrderFormManager from '../../forms/work-order/WorkOrderFormManager';
import {useTopbar} from '../../redux/reducers/topbarReducer';
import {api, apiBL, util} from '../../services/service';

const woForm = new WorkOrderFormManager();

const SetWorkOrder = () => {
  const [woId, setWoId] = React.useState();
  const {id, blNo} = util.getQS();
  async function loadBLEntry(key, value) {
    const res = await api.forwardViewBLEntry({[key]: value, container: true, house: false});
    if (res?.data?.length > 0) {
      woForm.setFromBLData(res.data[0]);
      return true;
    } else {
      util.showWarning('B/L not found!');
      return false;
    }
  }
  async function loadBLListData(id) {
    const res = await api.workOrderBLListData(id);
    if(res) {
      woForm.blGrid.setRows(res.data);
    }
  }
  woForm.onSave = async (values) => {
    if(!values.workplaceId) {
      util.showWarning('Please enter a valid Workplace data!');
      return;
    }
    // if(!values.workDate) {
    //   util.showWarning('Please enter a valid EST. Time data!');
    //   return;
    // }
    const res = await apiBL.setWorkOrder(values);
    if (res) {
      util.showSuccess('Work Order has been saved successfully!');
      const savedId = res.id;
      woForm.setValue('id', savedId);
      woForm.woId = savedId;
      setWoId(savedId);
    }
  };
  woForm.onSearch = async (key, value) => {
    return loadBLEntry(key, value);
  };
  woForm.onSearchBLList = async () => {
    return loadBLListData(parseInt(id)).then(res => {});;
  };
  React.useEffect(() => {
    if (id) {
      loadBLEntry('blNo', blNo).then((res) => {
        if (res) {
          apiBL.workOrderData({id}).then((res) => {
            if (res?.data?.[0]) {
              woForm.setFromWOData(res.data[0]);
              woForm.loadBoxList().catch();
            }
          });
          setWoId(parseInt(id));
        }
      });
      loadBLListData(parseInt(id)).then(res => {});
    } else if(!id && blNo) {
      loadBLEntry('blNo', blNo).then(res => {});
    }
  }, []);
  const title = `${woId ? 'Edit' : 'New'} Work Order`;
  useTopbar({label: 'Schedule'}, {label: title})
  document.title = title;
  woForm.woId = woId;
  return woForm.renderForm();
};

export default SetWorkOrder;
