/**
 * @flow
 */
import React from 'react';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions} from '../forms/field-defs/bl';
import {util} from '../services/service';
import {oemPrintTitleOptions} from '../forms/print/v2/OEMBLPrintForm';
import seaWayBillImage from '../media/seawaybill.png';
import coshipLogo from "../media/logo_coship.jpg";

class OEMPrint extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return null;
    }
    const {
      titleLabel, shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr,
      bkgNo, filingNo, b13No,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, porLabel,
      said, desc, mark, descOption, remark, unit,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packCount, packType, grossWeightKg, cbm,
      useWatermark, containers,
      blankLines, blankLinesRemark,
      company, address, email, fax, phone, pic
    } = printData;
    return (
      <div style={{color: 'black', position: 'relative', fontSize: 16}}>
        {useWatermark && (
          <div className={'flex center middle w-full'} style={{position: 'absolute', zIndex: 1000, height: '100%'}}>
            <img src={seaWayBillImage} style={{width: '70%'}} alt={'Sea Way Bill'} />
          </div>
        )}
        <div className={'flex-column center'} style={{fontSize: 24, fontWeight: 'bold'}}>{company}</div>
        <div className={'flex'}>
          <div className={'flex-1'}><img src={coshipLogo} /></div>
          <div className={'flex-7 flex-column center'} style={{marginLeft: '-130px'}}>
            <div>{address}</div>
            <div>Prepared by {pic} {util.formatDT(Date.now())}</div>
            <div>TEL : {phone} FAX : {fax} EMAIL : {email}</div>
          </div>
        </div>
        <div className={'flex-column center'} style={{fontSize: 36, fontWeight: 'bold', marginTop: '30px'}}>{titleLabel}</div>
        <table className={'print-table'} style={{width: '100%', fontSize: 16}}>
          <tbody>
          <tr>
            <td rowSpan={2} width={'50%'}>
              <div className={'label'}>Shipper / Exporter</div>
              <div className={'value'} style={{whiteSpace: 'pre-line'}}>{shipperAddr}</div>
            </td>
            <td width={'50%'}>
              <div className={'label'}>Booking No.</div>
              <div className={'value'}>{bkgNo}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={'label'}>Export Filing No.</div>
              <div className={'value'}>{filingNo}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'label'}>Consignee</div>
              <div className={'value'} style={{whiteSpace: 'pre-line'}}>{consigneeAddr}</div>
            </td>
            <td width={'50%'}>
              <div className={'label'}>Forwarding Agent</div>
              <div className={'value'} style={{whiteSpace: 'pre-line'}}>{forwardingAgentAddr}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'} rowSpan={2}>
              <div className={'label'}>Notify Party</div>
              <div className={'value'} style={{whiteSpace: 'pre-line'}}>{notifyAddr}</div>
            </td>
            <td width={'50%'}>
              <div className={'label'}>POINT AND COUNTRY OF ORIGIN OF GOODS</div>
              <div className={'value'}>{''}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'label'}>Domestic Routing / Export Instructions</div>
              <div className={'value'}>{''}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'label'}>Exporting Carrier</div>
              <div className={'value'}>{carrier}</div>
            </td>
            <td width={'50%'}>
              <div className={'flex between'}>
                <div>
                  <div className={'label'}>Vessel / Voyage No.</div>
                  <div className={'value'}>{vslVoy}</div>
                </div>
                <div>
                  <div className={'label pl-12'} style={{borderLeft: '1px solid #000'}}>Onboard</div>
                  <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{onboard ?? '\u00a0'}</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'flex between'}>
                <div>
                  <div className={'label'}>Port of Loading</div>
                  <div className={'value'}>{polLabel}</div>
                </div>
                <div>
                  <div className={'label pl-12'} style={{borderLeft: '1px solid #000'}}>ETD</div>
                  <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{util.formatD(etd)}</div>
                </div>
              </div>
            </td>
            <td width={'50%'}>
              <div className={'label'}>Place of Receipt</div>
              <div className={'value'}>{porLabel}</div>
            </td>
          </tr>
          <tr>
            <td width={'50%'}>
              <div className={'flex between'}>
                <div>
                  <div className={'label'}>Port of Discharge</div>
                  <div className={'value'}>{podLabel}</div>
                </div>
                <div>
                  <div className={'label pl-12'} style={{borderLeft: '1px solid #000'}}>ETA</div>
                  <div className={'value pl-12'} style={{borderLeft: '1px solid #000'}}>{util.formatD(eta)}</div>
                </div>
              </div>
            </td>
            <td width={'50%'}>
              <div className={'label'}>Place of Delivery</div>
              <div className={'value'}>{podLabel}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%', fontSize: '0.9em'}}>
          <tbody>
          <tr>
            <td className={'no-border-t center'} width={'25%'}><div className={'label f-12'}>Container No. / Seal No.<br/>Marks and Numbers</div></td>
            <td className={'no-border-t center'} width={'13%'}><div className={'label f-12'}>No. of Cont.<br/>or Other Pkgs.</div></td>
            <td className={'no-border-t center'} width={'42%'}><div className={'label f-12'}>Description of Packages and Goods</div></td>
            <td className={'no-border-t center'} width={'10%'}><div className={'label f-12'}>Gross Weight</div></td>
            <td className={'no-border-t center'} width={'10%'}><div className={'label f-12'}>Measurement</div></td>
          </tr>
          <tr>
            <td className={'no-border-b'} style={{paddingTop: 10}}><div className={'value'}>{mark}</div></td>
            <td className={'no-border-b'} style={{paddingTop: 10}}><div className={'value'}>{said}</div></td>
            <td className={'no-border-b'} style={{paddingTop: 10, paddingLeft: 20}}><div className={'value'} style={{lineHeight: 1.3, letterSpacing: 0.8}}>{descOption}<br/><br/>{desc}</div></td>
            <td className={'no-border-b'} align={'right'} style={{paddingTop: 10}}><div className={'value'}>{util.weightForPrint(grossWeightKg, unit)}</div></td>
            <td className={'no-border-b'} align={'right'} style={{paddingTop: 10}}><div className={'value'}>{util.volumeForPrint(cbm, unit)}</div></td>
          </tr>
          {blankLines.map((_, i) => {
            return (
              <tr key={i}>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
              </tr>
            );
          })}
          <tr>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'} align={'right'}><div className={'value'}>{`"FREIGHT ${freightLabel}"`}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
          </tr>
          <tr>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'} align={'right'}><div className={'value'}>{`"${orgBlTypeLabel}"`}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
          </tr>
          <tr>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'} align={'right'}>
              <div className={'value'}>{`Move Type: ${svcTerm1Label} / ${svcTerm2Label}  ${b13No ? `Export No. : ${b13No}`: ''}`}</div>
            </td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
            <td className={'no-border-b no-border-t'}><div className={'value'}>{' '}</div></td>
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%', fontSize: 16}}>
          <tbody>
          <tr>
            <td className={'no-border-b'} width={100} align={'center'}>Remark</td>
            <td className={'no-border-b'} style={{whiteSpace: 'pre-line'}}>{remark}</td>
          </tr>
          {blankLinesRemark.map((_, i) => {
            return (
              <tr key={i}>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
                <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
              </tr>
            );
          })}
          <tr>
            <td className={'no-border-t'} width={100}>{'\u00a0'}</td>
            <td className={'no-border-t'} >{'\u00a0'}</td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers} = blData;
    const {title, watermark} = printFormData;
    const titleLabel = util.labelFromOptions(title, oemPrintTitleOptions);
    const {shipperAddr = '', consigneeAddr = '', forwardingAgentAddr = '', notifyAddr = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = ''} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta} = jvessel;
    const {polLabel, podLabel, porLabel} = jroute;
    let {freight, orgBlType, svcTerm1, svcTerm2, package: packCount, packageType: packType, grossWeightKg, cbm} = jshipment;
    const {said, desc, mark, descOption = 'SAID TO CONTAIN :', remark, showAs: unit = 'KG'} = jmark ?? {};
    const freightLabel = util.labelFromOptions(freight, freightTypeOptions);
    const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.labelFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.labelFromOptions(svcTerm2, svcTypeOptions);
    const useWatermark = watermark?.[0] === 'on';
    const numberOfContainers = containers?.length ?? 0;
    const numberOfDesc = ((desc?.match(/\n/g)||[]).length ?? 0) + 1;
    const numberOfRemark = ((remark?.match(/\n/g)||[]).length ?? 0) + 1;
    const blankLines = [];
    const blankCount = 23 - numberOfDesc;
    for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const blankLinesRemark = [];
    let blankRemarkCount = 6 - numberOfRemark;
    if(blankRemarkCount < 0) blankRemarkCount = 0;
    for (let i = 0; i < blankRemarkCount; ++i) blankLinesRemark.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    if (containers.length <= 0) {
      containers.push({
        container_no: '',
        seal_no: '',
        // pack_count,
        // pack_unit,
        // weight_kg: jshipment.grossWeightKg,
        // cbm: jshipment.cbm,
      });
    }
    return {
      titleLabel, shipperAddr, consigneeAddr, forwardingAgentAddr, notifyAddr,
      bkgNo, filingNo, b13No,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, porLabel,
      said, desc, mark, descOption, remark, unit,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packCount, packType, grossWeightKg, cbm,
      useWatermark, containers,
      blankLines, blankLinesRemark,
      company, address, email, fax, phone, pic
    };
  }
}

export default OEMPrint;
