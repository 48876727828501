import React from 'react';
import {useDispatch} from 'react-redux';

export const TOPBAR_UPDATE = 'TOPBAR_UPDATE';
export const TOPBAR_RESET = 'TOPBAR_RESET';

export function updateTopbar(dispatch, category, menu) {
  dispatch({type: TOPBAR_UPDATE, category, menu});
}

export function resetTopbar(dispatch) {
  dispatch({type: TOPBAR_RESET});
}

export interface PathInfo {
  path?: string;
  label: string;
}

export interface TopbarState {
  category: PathInfo;
  menu: PathInfo;
}

export function useTopbar(category: PathInfo, menu: PathInfo): TopbarState {
  document.title = 'COSHIP Admin';
  const dispatch = useDispatch();
  React.useEffect(() => {
    updateTopbar(dispatch, category, menu);
  }, [category, menu]);
}

export default function(state: TopbarState = {}, action) {
  const {type, category, menu} = action;
  switch (type) {
    case TOPBAR_UPDATE:
      return {category, menu};
    case TOPBAR_RESET:
      return {};
    default:
      return state;
  }
}
