/**
 * @flow
 */
import React from 'react';
import {ColDef} from 'ag-grid-community';
import {AgGridReact, AgGridReactProps} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import type {DataGridActionType} from './DataGrid';
import {useGridActionColumn} from './GridActionColumn';

export interface SimpleGridProps {
  columns: ColDef[] | () => ColDef[];
  height: number | string;
  width?: number | string;
  rows?: Array; // just defines initial rows
  actions?: DataGridActionType[] | () => DataGridActionType[];
  actionWidth?: number;
  isActionDisabled?: (type: DataGridActionType, data: Object) => boolean;
  onAction?: (type: DataGridActionType, data: Object) => void;
  className?: string;
  style?: Object;
  agGridProps?: AgGridReactProps;
  apiRef: any;
  isScrollToBottom: Boolean;
}

export const defaultColDef: ColDef = {
  resizable: true,
  minWidth: 100,
};

const SimpleGrid = (props: SimpleGridProps): React$Node => {
  const actionColumn = useGridActionColumn({
    actions: props.actions,
    onAction: props.onAction,
    onDisable: props.isActionDisabled,
    actionWidth: props.actionWidth,
  });
  const columns: ColDef[] = actionColumn ? [actionColumn, ...props.columns] : props.columns;
  const {width, height} = props;
  const handleRowDataChanged = (event) => {
    if(props.isScrollToBottom) {
      const index = props?.rows?.length ? props?.rows?.length - 1 : 0;
      event.api.ensureIndexVisible(index, 'bottom');
    }
  }
  return (
    <div className={`ag-theme-balham ${props.className}`} style={{...props.style, ...{width, height}}}>
      <AgGridReact
        columnDefs={columns}
        rowData={props.rows ?? []}
        defaultColDef={defaultColDef}
        enableCellTextSelection={true}
        {...props.agGridProps}
        onGridReady={e => {
          props.apiRef.current = e.api;
        }}
        onRowDataChanged={e => {handleRowDataChanged(e)}}
      />
    </div>
  );
};

export function useSimpleGrid(props: SimpleGridProps) {
  const [rows, setRows] = React.useState<Object[]>(props.rows);
  const apiRef = React.useRef();
  return {
    rows,
    setRows,
    render: () => <SimpleGrid {...props} rows={rows} apiRef={apiRef} />,
    apiRef,
  };
}

export default SimpleGrid;
