/**
 * @flow
 */
import FormManager from "../../lib/FormManager";
import React from "react";
import {util} from "../../services/service";

class SummarySearchFormManager extends FormManager {
  onChange;
  constructor() {
    super({
      prefix: 'summary-search',
      fields: [
        {name: 'fromDate', serverName: 'fromDate', label: '', type: 'date'},
        {name: 'toDate', serverName: 'toDate', label: '', type: 'date'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {_r, _v, _c} = this.getRenderProps();
    const fromDate = _v('fromDate');
    const toDate = _v('toDate');
    React.useEffect(() => {
      if(!fromDate || !toDate) {
        _c('fromDate', util.getStartDateMonth());
        _c('toDate', util.getCurrentDate());
      }
      this.onChange();
    }, [fromDate, toDate]);
    return (
      <>
      <form>
        <div className="form-row align-items-center">
          <div><h3>Date</h3></div>
          <div className="flex col-auto my-1" style={{marginLeft: '-6px'}}>
            <div className={'flex-1'}>
              {_r('fromDate')}
            </div>
            <div style={{marginLeft: '10px'}}>~</div>
            <div className={'flex-1 ml-12'}>
              {_r('toDate')}
            </div>
          </div>
        </div>
      </form>
      </>
    );
  }
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

export default SummarySearchFormManager;
