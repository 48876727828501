/**
 * @flow
 */
import React from 'react';
import {apiBL, util} from "../../services/service";
import {useSimpleGridModal} from "../../components/SimpleModal";


export default function useBLInventoryHistoryModal() {
  const blIdRef = React.useRef();

  async function onQuery(data) {
    const res = await apiBL.listInvHistory(data);
    modal.setRows(res.data);
  }

  const modal = useSimpleGridModal({
    title: 'Inventory history',
    centered: true,
    width: 600,
    buttons: [],
    gridProps: {
      columns: [
        {field: 'invoice_id', hide: true},
        {field: 'cdate', headerName: 'Date', valueFormatter: util.dateFormatter, flex: 1},
        {field: 'space_name', headerName: 'Inv.Name', flex: 1},
        {field: 'staff_name', headerName: 'Occupied by', flex: 1},
      ],
      actionWidth: 70,
      rows: [],
      height: 300,
      className: 'mb-20',
    },
  });

  React.useEffect(() => {
    if (modal.isOpen === true) {
      if (blIdRef.current) {
        onQuery({blId: blIdRef.current}).catch();
      }
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (blId = undefined) => {
      blIdRef.current = blId;
      modal.open();
    },
  };
}
