/**
 * @flow
 */
import React from 'react';
import {TAB_ACCOUNT} from '../../modal-tabs/types';
import useAccountDcnoteGridView from '../../grids/account/useAccountDcnoteGridView';
import {useTopbar} from "../../redux/reducers/topbarReducer";
import useDCNoteDetailModal from "../../modals/account/useDCNoteDetailModal";

const AccountDCNote = () => {
  useTopbar({label: 'Account'}, {label: 'D/C'});
  const detailModal = useDCNoteDetailModal();
  const grid = useAccountDcnoteGridView(TAB_ACCOUNT, detailModal);

  return (
    <div className={'flex'}>
      <div className={'flex-1'} style={{position: 'relative'}}>
        {grid.render()}
      </div>
      {detailModal.render()}
    </div>
  );
};

export default AccountDCNote;
