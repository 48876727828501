/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import Barcode from 'react-barcode';
import coshipLogo from '../media/coship_logo.png';
import {util} from "../services/service";

class ManifestPrint2 extends React.Component {
  render() {
    const {data = [], isMultiRows} = this.props;
    if (data.length <= 0) {
      return null;
    }
    return (
      <div>{data.map((item, index) => this.renderItem(item, index, isMultiRows)).flat()}</div>
    );
  }
  renderItem(item, index, isMultiRows) {
    let {
      bl_no, mbl_no, order_no, korea_post, out_date, memo, ctns,
      shipper, shipper_address, shipper_phone,
      consignee, consignee_address, consignee_phone1, consignee_phone2, consignee_postal, items,
      weight, cal_weight,
    } = item;
    const itemsOfItem = [];
    for (let i = 0; i < parseInt(ctns); ++i) itemsOfItem.push(i);
    const itemsCount = items?.length;
    let itemsToDisplay = items?.filter((i, index) => index < 10).map(i => i.item_qty > 1 ? ` ${i.item}(${i.item_qty})` : ` ${i.item}`);
    const MAX_DESC_LEN = 32;
    if (itemsToDisplay?.length > MAX_DESC_LEN) {
      itemsToDisplay = itemsToDisplay.substr(0, MAX_DESC_LEN) + '...';
    }
    const description = (itemsToDisplay + (itemsCount > 9 ? `외 ${itemsCount - 9}건` : '')).split(',').join(' / ') + ` / ${weight}KG`;
    const descSub = description?.length > MAX_DESC_LEN ? description.substr(0, MAX_DESC_LEN) + '...' : description;
    let postal = consignee_postal?.replaceAll(`"`, '').replaceAll('=', '');
    bl_no = bl_no?.replaceAll(`"`, '').replaceAll('=', '');
    shipper_phone = shipper_phone?.replaceAll(`"`, '').replaceAll('=', '');
    consignee_phone1 = consignee_phone1?.replaceAll(`"`, '').replaceAll('=', '');
    consignee_phone2 = consignee_phone2?.replaceAll(`"`, '').replaceAll('=', '');
    // consignee = util.replaceAt(consignee, consignee.length < 5 ? 1 : consignee.length-3, consignee.length < 4 ? '*': '**');
    const isSmall = cal_weight ? util.toFloat(cal_weight) <= 1 : weight ? util.toFloat(cal_weight) <= 1 : false;
    const style = {
      margin: '0 1cm',
      color: 'black',
      fontSize: 30,
      width: 930,
      height: 550,
    };
    const rot1SingleStyle = {
      marginTop: 120, marginLeft: -125, width: 280, fontSize: 12
    };
    const rot2SingleStyle = {
      marginTop: 205, marginLeft: -80, width: 250, fontSize: 11
    };
    const rot1MultiStyle = {
      marginTop: 120, marginLeft: -120, width: 280, fontSize: 12
    };
    const rot2MultiStyle = {
      marginTop: 205, marginLeft: -73, width: 250, fontSize: 11
    };
    return itemsOfItem.map((ctnsIndex) => {
      return (
        <div className={'flex-column page-break air-manifest-print-container'} style={style} key={`${index}-${ctnsIndex}`}>
          <style type="text/css">
            {"@media print{@page {size: landscape}}"}
          </style>
          <div className={'flex'} style={{borderTop: 'dotted'}}>
            <div style={{width: 300}}>
              <div className={'flex fb-24 left-bottom'} style={{height: 90, fontSize: 12}}>
                <div className={'flex-2'}>접수국: 국제우편물류센터</div>
                <div className={'flex-1 fb-24'}>{ctnsIndex + 1} of {ctns}</div>
              </div>
              <div style={{height: 100, paddingTop: 10}}><img src={coshipLogo} style={{width: '5.5cm'}} alt={'COSHIP'} width={'150px'} height={'82'} /></div>
              <div className={'flex'} style={{height: 130}}>
                <div className={'flex-4'} style={{paddingTop: 8, textAlign: 'center'}}>
                  <Barcode value={postal} displayValue={true} height={80} width={3} />
                </div>
                <div className={'flex-1'} style={{fontSize: 14}}>{'\u00a0'}</div>
              </div>
              <div style={{height: 160, fontSize: 17, lineHeight: 1.4}}>{description}</div>
              <div className={'fb-13'} style={{height: 20, fontSize: 15, lineHeight: 1.4}}>배송메시지:</div>
              <div style={{height: 40, fontSize: 15, lineHeight: 1.4}}>{memo}</div>
            </div>
            <div style={{width: 490}}>
              <div className={'flex'} style={{height:90, paddingTop: 10, paddingLeft: 10}}>
                <div className={'fb-60'} style={{fontSize: 45}}>{korea_post[0]}</div>
                <div className={'fb-24'} style={{fontSize: 17, paddingTop: 30, paddingLeft: 10, paddingRight: 10}}>{korea_post[1]}</div>
                <div className={'fb-60'} style={{fontSize: 45}}>{korea_post[2]}</div>
                <div className={'fb-24'} style={{fontSize: 17, paddingTop: 30, paddingLeft: 10, paddingRight: 10}}>{korea_post[3]}</div>
                {!isSmall && (
                  <div className={'flex'}>
                    <div className={'fb-60'} style={{fontSize: 45}}>{korea_post[4]}</div>
                    <div className={'fb-24'} style={{fontSize: 16, paddingTop: 35, paddingLeft: 10, paddingRight: 10}}>{korea_post[6] ? `- ${korea_post[6]} -` : ''}</div>
                    <div className={'fb-60'} style={{fontSize: 45}}>{korea_post[5]}</div>
                  </div>
                )}
                {isSmall && (
                  <div style={{width: 100, height: 50}}>
                    <div className={'flex'} style={{backgroundColor: 'black', width: 100, height: 35, justifyContent: 'center', alignItems: 'center'}}>
                      <div className={'fb-40'} style={{fontSize: 32, color: 'white', paddingRight: 10}}>{korea_post[4]}</div>
                      <div className={'fb-40'} style={{fontSize: 32, color: 'white'}}>{korea_post[5]}</div>
                    </div>
                    <div className={'fb-24'} style={{fontSize: 16, marginTop: -3, textAlign: 'center'}}>{korea_post[6] ? `- ${korea_post[6]} -` : ''}</div>
                  </div>
                )}
              </div>
              <div style={{height: 100, width: 470, paddingLeft: 40, paddingTop: 10}}>
                <div className={'flex'} style={{height: 47}}>
                  <div className={'flex-8 fb-15'} style={{fontSize: 18, lineHeight: 1}}>{shipper_address}</div>
                </div>
                <div className={'flex'}>
                  <div className={'flex-5 fb-24'} style={{fontSize: 18}}>{shipper}</div>
                  <div className={'flex-3 fb-15'} style={{fontSize: 15, paddingRight: 30}}>M: {shipper_phone}</div>
                </div>
              </div>
              <div className={'fb-24'} style={{height: 220, width: 470, paddingLeft: 40, paddingTop: 20}}>
                <div className={'fb-24'} style={{fontSize: 25, lineHeight: 1}}>{consignee_address}</div>
                <div className={'fb-24'} style={{paddingTop: 25}}>{consignee}</div>
                <div className={'fb-18'}>T: {consignee_phone1}{consignee_phone2 ? ` / ${consignee_phone2}`:''}</div>
                <div className={'fb-18'}>등기번호: <span className={'fb-24'}>{bl_no}</span></div>
              </div>
              <div className={'flex'} style={{height: 130, paddingTop: 6, paddingLeft: 10}}>
                <div className={'flex-3'}><Barcode value={bl_no} displayValue={false} height={100} width={3} /></div>
                <div className={'flex-2'}>{'\u00a0'}</div>
              </div>
            </div>
            <div style={{width: 70}}>
              <div className={'rot1'} style={isMultiRows ? rot1MultiStyle : rot1SingleStyle}>
                <div><Barcode value={bl_no} displayValue={true} height={30} width={2} /></div>
              </div>
              <div className={'rot2'} style={isMultiRows ? rot2MultiStyle : rot2SingleStyle}>
                <div>{consignee_address}</div>
                <div className={'flex'}>
                  <div>{consignee}</div>
                  <div style={{paddingLeft: 40}}>{consignee_phone1}{consignee_phone2 ? ` / ${consignee_phone2}`:''}</div>
                </div>
                <div className={''}>내용물: {descSub}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
}

export function useManifestPreviewModal2() {
  const [data, setData] = React.useState([]);
  const [isMultiRows, setIsMultiRows] = React.useState(false);
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'Air Manifest',
    width: 1024,
    children: <ManifestPrint2 ref={printRef} data={data} isMultiRows={isMultiRows} />,
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      print();
    }
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data, isMultiRows) => {
      setData(data);
      setIsMultiRows(isMultiRows);
      modal.open();
    },
    print,
  };
}
