/**
 * @flow
 */
import {useDataGridView} from '../components/DataGridView';
import {apiBL, util} from '../services/service';
import type {CargoType} from '../modal-tabs/types';
import type {QueryListParams} from '../services/API';

export function useWorkAssignedGridView(cargoType: CargoType, assignModal, queryCb: (gridInfo: QueryListParams) => void) {
  const grid = useDataGridView({
    async onAction(action, data) {
      if (action === 'edit') {
        assignModal.open(data);
      } else if (action === 'delete') {
        const res = await apiBL.delWorkAssign(data.id);
        if (res) {
          await grid.query();
        }
      }
    },
    async onQuery(gridInfo) {
      queryCb && queryCb(gridInfo);
      let {fromDate, toDate} = gridInfo;
      if (fromDate === undefined && toDate === undefined) {
        fromDate = Date.now();
        toDate = fromDate;
      } else {
        // TODO
      }
      //return api.cargoWorkAssignedList({...gridInfo, fromDate, toDate, cargoType});
      return apiBL.workAssignList({...gridInfo, fromDate, toDate});
    },
    onCellClick(e) {
      if (e.colDef.headerName !== 'Actions') {
        assignModal.open(e.data);
      }
    },
    onCellValueChange(e) {
      //console.log('hello cellValueChange', e);
    },
    name: `workAssignList_${cargoType}`,
    label: 'Work Assign List',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Schedule'},
    menuLabel: {label: 'Work Assigned'},
    sortCol: 'work_date',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit', 'delete'],
    actionWidth: 100,
    showDateSearch: true,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    initialSearchToday: true,
    columns: () => {
      return [
        {field: 'id', hide: true},
        {field: 'work_date', headerName: 'Date', valueFormatter: dateFormatter, width: 150},
        {field: 'vehicle_name', headerName: 'Vehicle', width: 150},
        {field: 'workers', headerName: 'Workers', width: 250},
        {field: 'remark', headerName: 'Remark', flex: 1},
      ];
    },
  });
  return grid;
}

function dateFormatter(p) {
  const date = util.formatD(p.value);
  const time = p.data['work_time'];
  return time ? `${date} ${time}` : date;
}
